import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';

const columnWidth = 120;

export default class CustomerDistributionUsersTable extends React.Component {

  constructor(props) {
    super(props);

    this.renderCell = this.renderCell.bind(this);
    this.renderCheckboxCell = this.renderCheckboxCell.bind(this);
    this.renderCellButton = this.renderCellButton.bind(this);

    this.state = {
      selectedList: undefined
    }
  }

  renderCell(data) {
    const { items } = this.props;
    const item = items[data.rowIndex];

    return (
      <Cell>{item[data.columnKey]}</Cell>
    );
  }

  renderCheckboxCell(data) {
    const { items } = this.props;
    const user = items[data.rowIndex];
    return (
      <input
        type="checkbox"
        checked={user[data.columnKey]}
        onChange={(event) => {
          this.props.onUserAttrChange(user, data.columnKey, event.target.checked);
        }}
      />
    );
  }

  renderCellButton(data) {
    return (
      <button onClick={() => {
        const { items } = this.props;
        const user = items[data.rowIndex];
        this.props.onUserClick(user, 'remove');
      }}>-</button>
    )
  }


  render() {
    const { items } = this.props;

    return (
      <Table
        maxHeight={500}
        width={columnWidth * 4.2}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
      >
        <Column
          columnKey="FirstName"
          width={columnWidth}
          header={<Cell>{this.context.t('First Name')}</Cell>}
          cell={this.renderCell}/>

        <Column
          columnKey="LastName"
          width={columnWidth}
          header={<Cell>{this.context.t('Last Name')}</Cell>}
          cell={this.renderCell}/>
        <Column
          columnKey="RegisterMail"
          width={columnWidth * 0.8}
          header={<Cell>{this.context.t('Mail')}</Cell>}
          cell={this.renderCheckboxCell}/>
        <Column
          columnKey="RegisterSMS"
          width={columnWidth * 0.8}
          header={<Cell>{this.context.t('SMS')}</Cell>}
          cell={this.renderCheckboxCell}/>
        <Column
          width={columnWidth / 2}
          cell={this.renderCellButton}/>
      </Table>
    );
  }
}

CustomerDistributionUsersTable.contextTypes = {
  t: PropTypes.func.isRequired
}
