import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

require('./Loading.scss');

export default class Loading extends React.Component {

  render() {
    return (
      <div className='loading-page'>
        <div className="loading-screen">
          <LinearProgress />
          <Typography variant="h1" component="h2" align='center'>
            Loading...
          </Typography>
        </div>
      </div>
    );
  }

}
