import React from 'react';
import { zIndexs, featuresTypes } from './Options';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');


// @TODO: CHECK WHERE CAN WE LOCATE THIS DEFINATION
const SOP_ICON_SCALE = 0.35;

// SOP style
const style = new Style({
  image: new Icon({
    src: require('../../../../images/map/sop/default/ic_sop_undefined.png'),
    anchor: [0.5, 0.5],
    size: [64, 64],
    scale: SOP_ICON_SCALE,
  })
});

const source = new VectorSource({
  features: []
});

// SOP layer
export const layer = new VectorLayer({
  name: 'layerSOPs',
  style: style,
  source: source,
  zIndex: zIndexs.sops,
});

// set the SOP source
export const setSource = (nextIconFeatures, props) => {
  let iconFeatures = [];
  Object.keys(nextIconFeatures).forEach((key) => {
    const sop = nextIconFeatures[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          nextIconFeatures[key].position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.SOP,
      id: sop.id,
      status: sop.StateMeaning,
      sopType: sop.meaning,
      isUnderGround: sop.isUnderGround,
      avizar: sop.avizar,
      address: sop.address,
      assetID: sop.assetID,
      stolen: sop.stolen,
      comment: sop.comment,
      // @TODO: comment: nextIconFeatures[key].Comment,
    });
    iconFeature.setStyle(getStyle(iconFeature));
    iconFeatures.push(iconFeature);
  });
  layer.getSource().clear();
  layer.getSource().addFeatures(iconFeatures);
};

export const getStyle = (feature, selected) => {
  let sopStyle = false;
  let iconType = false;

  const attr = feature.getProperties();
  const selectedDir = selected ? 'Selected/' : '';
  const ground = (attr.isUnderGround === 'above') ? '' : 'underground/';
  let statusDir = 'default';
  let sopType = 'undefined';

  switch (attr.status) {
    case 'Install Candidate - Acc':
      statusDir = 'Candidate/Acc';
      break;
    case 'Install Candidate - Hydro':
      statusDir = 'Candidate/Hydro';
      break;
    case 'Uninstall Candidate':
      statusDir = 'Candidate/Uninstall';
      break;
    case 'Ok':
      statusDir = 'good';
      break;
    case 'Vegetation':
    case 'Short':
    case 'No Communication':
    case 'Concrete / Wall':
    case 'Unusable':
    case 'Stolen':
      statusDir = 'bad';
      break;
    case 'Coordination Needed':
    case 'Other':
    case 'Not Scanned':
    default:
      statusDir = 'default';
      break;
  }
  if (statusDir == 'default' && attr.stolen) {
    statusDir = 'bad';
  }

  switch (attr.avizar) {
    case 101:
    case 102:
    case 201:
    case 202:
      sopType = 'hydrant';
      break;
    case 203:
    case 204:
    case 206:
      sopType = 'valve';
      break;
    case 205:
      sopType = 'service_valve';
      break;
    case 207:
      sopType = 'gate_valve';
      break;
    case 208:
      sopType = 'control_valve';
      break;
    case 209:
      sopType = 'manhole';
      break;
    case 210:
      sopType = 'fitting';
      break;
    case 211:
      sopType = 'air_valve';
      break;
    case 212:
      sopType = 'prv';
      break;
    case 213:
      sopType = 'pump';
      break;
    default:
      sopType = 'undefined';
      break;
  }

  iconType = selectedDir + statusDir + '/' + ground + 'ic_sop_' + sopType + '.png';

  let size;
  if (selected) {
    size = [110, 110];
  } else {
    size = (statusDir == 'default') ? [64, 64] : [80, 80]; // icon dimentions
  }

  sopStyle = new Style({
    image: new Icon({
      src: require('../../../../images/map/sop/' + iconType),
      anchor: [0.5, 0.5],
      size: size,
      scale: SOP_ICON_SCALE,
    })
  });

  return sopStyle;
};

export const getLayers = () => {
  return [layer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    type: attr.type,
    id: attr.id,
    assetID: attr.assetID || false,
    sopType: attr.sopType,
    status: attr.status,
    address: attr.address,
    comment: attr.comment,
    stolen: attr.stolen,
  };
  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
  return (
    <span className="tooltip">
      <div className="tooltip-row">{context.t('SOP ID: ')} {itemInfo.id}</div>
      <div className="tooltip-row">{context.t('Type: ')} {itemInfo.sopType}</div>
      <div className="tooltip-row">{context.t('Status: ')} {itemInfo.status}</div>
      {
        itemInfo.address &&
        <div className="tooltip-row">{context.t('Address: ')} {itemInfo.address}</div>
      }
      {
        itemInfo.comment &&
        <div className="tooltip-row">{context.t('Comment: ')} {itemInfo.comment}</div>
      }
      {
        itemInfo.assetID && itemInfo.assetID !== false &&
        <div className="tooltip-row">{context.t('Asset ID: ')} {itemInfo.assetID}</div>
      }
      {
        itemInfo.stolen !== undefined && itemInfo.stolen > 0 &&
        <div className="tooltip-row"><h3>{context.t('Stolen')}</h3></div>
      }
    </span>
  );
};
