import { connect } from 'react-redux';

import * as actionsInspect from '../../actions/InspectActions';
import PipeAssessmentsAnalysisTabs from '../../components/Inspect/PipeAssessmentsAnalysisTabs';

const groupBy = require('json-groupby');
const alertsFields = ['PipeDiameterInch', 'Event Year', 'Material', 'PlacementYear', 'MaterialDiameter', 'YearMaterialDiameter', 'CalcLeakProbPS'];
const pipesFields =  ['PipeDiameterInch', 'PreasureArea' ,'Material', 'PlacementYear', 'MaterialDiameter', 'YearMaterialDiameter', 'CalcLeakProbPS', 'Length'];

const mapStateToProps = (state, ownProps) => {
   const leaksList = state.leaksList.inspect.alerts.data;
   let pipesList = state.leaksList.inspect.pipes.data;
   const isFetchingPipes = state.leaksList.inspect.pipes.isFetching;
   let scoreSelectedGroupData = state.leaksList.inspect.scoreSelected.data;
   const scoreSelectedGroupCategory = state.leaksList.inspect.scoreSelected.category;
   const leaksDataForAnalysis = [];
   let pipesDataForAnalysis = [];
   const { units, factor } = state.local;
   const { materialsByPlacementYear, diameterByPlacementYear, materialAndDiameterByPlacementYear, pcaScoreData } = state.leaksList.inspect;

   //dilute the items fields:
   leaksList.forEach((leak) => {
      let analysisItem = {};
      alertsFields.forEach((fieldName) => {
         analysisItem[fieldName] = leak[fieldName] || 0;
         if (fieldName == 'CalcLeakProbPS' && leak[fieldName] != null) {
           const val = parseFloat(leak.CalcLeakProbPS) || 0;
          analysisItem.CalcLeakProbPS_Round = val.toFixed(1);
         }
      });
      leaksDataForAnalysis.push(analysisItem);
   });

   pipesList.forEach((pipe) => {
    let analysisItem = {};
    pipesFields.forEach((fieldName) => {
       if (fieldName == 'CalcLeakProbPS') {
         if (pipe.CalcLeakProbPS != null) {
          const val = parseFloat(pipe.CalcLeakProbPS);
          analysisItem.CalcLeakProbPS_Round = val.toFixed(1);
         }
       } else {
        analysisItem[fieldName] = pipe[fieldName] || 0;
       }
    });
    pipesDataForAnalysis.push(analysisItem);
   });

   pipesDataForAnalysis = pipesDataForAnalysis.filter((pipe) => pipe.PlacementYear != null && pipe.PlacementYear != undefined && pipe.PlacementYear != 'undefined' && pipe.PlacementYear > 0);

   const valuesSets = state.leaksList.inspect.alerts.valuesSets;
   const pipesValuesSets = state.leaksList.inspect.pipes.valuesSets;

   if (valuesSets.PlacementYear != null) {
     valuesSets.PlacementYear = valuesSets.PlacementYear.filter((valuesSet) => {
      return (
        valuesSet != -1 &&
        valuesSet != null &&
        valuesSet != undefined &&
        valuesSet != 'undefined' &&
        valuesSet.toString().includes('-1') == false
      );
    });
   }

   if (pipesValuesSets.PlacementYear != null) {
    pipesValuesSets.PlacementYear = pipesValuesSets.PlacementYear.filter((valuesSet) => {
      return (
        valuesSet != -1 &&
        valuesSet != null &&
        valuesSet != undefined &&
        valuesSet != 'undefined' &&
        valuesSet.toString().includes('-1') == false
      );
   });
  }

  // set score selected attr: 'Leaks per km \ mile:
  const minPipeForCalculation = actionsInspect.MIN_PIPE_LENGTH_FOR_ANALYSIS_CALCULATION * factor[units]
  scoreSelectedGroupData.forEach((itrGroup) => {
    const pipeByMeters = itrGroup.PipeLength;
    const pipeLengthByUnit = pipeByMeters * factor[units];
    const calculatedPipeLength = parseFloat(Math.max(pipeLengthByUnit, minPipeForCalculation));
    const pipeLengthByBigUnit =  calculatedPipeLength / factor.bigUnitValue[units].value
    itrGroup.pipeLengthByBigUnit = Math.round((itrGroup.alertsCount / pipeLengthByBigUnit) * 4) / 4;
  });

  scoreSelectedGroupData = scoreSelectedGroupData
    .filter(item => item.PlacementYear > 0)
    .sort((a, b) => b.alertsCount - a.alertsCount);

   return Object.assign({}, ownProps, {
      rawLeaksData: leaksDataForAnalysis,
      valuesSets,
      pipesList: pipesDataForAnalysis,
      pipesValuesSets,
      isFetchingPipes,
      materialsByPlacementYear,
      diameterByPlacementYear,
      materialAndDiameterByPlacementYear,
      pcaScoreData,
      scoreSelectedGroupData,
      scoreSelectedGroupCategory,
      units,
      factor
      //plotData: plotDataTest[0],
      //heatMapData: plotDataTest[1],
   });
};

const mapDispatchToProps = (dispatch, ownProps) => {
 return {
   onSelectGroup: (filters) => {
      dispatch(actionsInspect.setVisibleAlerts(filters));
   },
   onSelectScoreGroup: (group) => {
     // dispatch(actionsInspect.setSelectedGrop(group.score));
   },
   selectAlertsByFilters: (filters) => {
      dispatch(actionsInspect.setVisibleAlerts(filters));
  },

 };
};

const getLeaksForMaterialToPlacementYear = (leaksDataForAnalysis, firstFilter, secondFilter) => {
   const group = groupBy(leaksDataForAnalysis, [firstFilter]);
   const categoriesGroup = groupBy(leaksDataForAnalysis, [secondFilter]);
   const dataYears = Object.keys(group);

   const dataCategories = Object.keys(categoriesGroup);
   let dataMap = {};
   let plotBarsData = [];
   const heatmapValues = [];

   dataYears.forEach((yearKey) => {
      const year = yearKey.toString();
      const heatmapYearData = [];
      // for each year - have to count the leaks for each category (like: 'Material'):
      dataCategories.forEach((category) => {
         let relatedItems = leaksDataForAnalysis.filter((item) => {
            return (item[firstFilter] == year && item[secondFilter] == category);
         });
         if (dataMap[category] == null) {
            dataMap[category] = [];
         }
         dataMap[category].push(relatedItems.length);

         heatmapYearData.push(relatedItems.length);
      });
      heatmapValues.push(Object.assign([],heatmapYearData));
   });

   Object.keys(dataMap).forEach((categoryKey) => {
      let trace = {
         type: 'bar',
         name: categoryKey,
         x: dataYears,
         y: dataMap[categoryKey],
      };

      plotBarsData.push(trace);
   });

   //dataYears[0] = Math.max(dataYears[0], 1900);
   // for (let index = 0; index < dataYears.length; index++) {
   //    dataYears[index] = `Year: ${dataYears[index]}`;
   // }

   const heatmapData = [{
      type: 'heatmap',
      ytype: 'array',
      text: heatmapValues,
      z: heatmapValues,
      x: dataCategories,
      y: dataYears,
   }];

   return [plotBarsData, heatmapData];
};

const CPipeAssessmentsAnalysisTabs = connect(
   mapStateToProps,
   mapDispatchToProps
)(PipeAssessmentsAnalysisTabs);

export default CPipeAssessmentsAnalysisTabs;
