import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import CorrelationGraph from './CorrelationGraph';

export default function AvGraph(props, context) {

  const xAxisLabels = new Array(props.avData.contrast_reduced.length).fill('');
  props.avData.locs_in_reduce_samples.forEach((loc, i) => {
    xAxisLabels[loc] = props.avData.distance_m[i];
  });
  return (
    <AppModal
      closeIcon
      onTop
      open={props.open}
      onClose={props.closeAvGraph}
      content={
        <>
          <p>avData</p>
          <CorrelationGraph
            // title={context.t('Title')}
            data={[props.avData.contrast_reduced || []]}
            // xTitle={context.t('Distance')}
            // yTitle={context.t('Contrast')}
            factor={props.factor}
            xAxisLabels={xAxisLabels}
            leaksMarkers={props.avData.locs_in_reduce_samples}
            large
          />
          <p>inst_corr_compressed</p>
          <CorrelationGraph
            // title={context.t('Title')}
            data={[props.data.inst_corr_compressed || []]}
            // xTitle={context.t('Distance')}
            // yTitle={context.t('Contrast')}
            factor={props.factor}
            xAxisLabels={xAxisLabels}
            // leaksMarkers={props.avData.locs_in_reduce_samples}
            large
          />
          <p>inst_corr_sum_compressed</p>
          <CorrelationGraph
            // title={context.t('Title')}
            data={[props.data.inst_corr_sum_compressed || []]}
            // xTitle={context.t('Distance')}
            // yTitle={context.t('Contrast')}
            factor={props.factor}
            xAxisLabels={xAxisLabels}
            // leaksMarkers={props.avData.locs_in_reduce_samples}
            large
          />
        </>
      }
    />
  );
}

AvGraph.propTypes = {
  avData: PropTypes.array,
  closeAvGraph: PropTypes.func,
  factor: PropTypes.number,
  open: PropTypes.bool,
};

AvGraph.contextTypes = {
  t: PropTypes.func.isRequired
};
