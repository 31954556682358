import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Table, Column, Cell } from 'fixed-data-table-2';
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';
import Pagination from 'react-js-pagination';
import { getProjectConfigurationsLogs } from '../../actions/DeviceConfigurationAction';
import { useDispatch } from 'react-redux';

const ConfigurationLogsModal = (props, context) => {

  const [currentPage, setCurrentPage] = React.useState(1);
  const [uiInfo, setUiInfo] = React.useState({
    height: 0,
    width: 0
  });

  const dispatch = useDispatch();

  React.useEffect(() => {
    const screen = window.screen;
    const uiInfo = {
      height: screen.height,
      width: screen.width
    }
    setUiInfo(uiInfo);
  }, []);

  React.useEffect(() => {
    if (props.open) {
      dispatch(getProjectConfigurationsLogs(currentPage - 1));
    }
    return () => {
      if (!props.open) {
        setCurrentPage(1);
      }
    }
  }, [props.open, currentPage]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
  }

  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth={false}
      open={props.open}
      onClose={() => {
        setCurrentPage(1);
        props.closeLogsModal();
      }}
      title='Device Configuration Messages Logs'
      content={(
        <>
          <Table
            keyboardScrollEnabled
            keyboardPageEnabled
            maxHeight={uiInfo.height * 0.6}
            width={uiInfo.width * 0.9}
            rowsCount={props.data.length}
            rowHeight={100}
            headerHeight={30}
          >
            <Column columnKey="#" width={5}
              align='center'
              header={<Cell>#</Cell>} flexGrow={0.3}
              cell={(col) => <Cell>{col.rowIndex + 1}</Cell>} />

            <Column columnKey="Timestamp" width={30}
              header={<Cell>Time</Cell>} flexGrow={1}
              cell={(col) => {
                const val = props.data[col.rowIndex]['Timestamp'];
                const time = UIDateFormater(val, DateType.DATE_AND_TIME, props.timeZone)
                return (
                  <Cell>{time}</Cell>
                )
              }} />

            <Column columnKey="DeviceID" width={30}
              header={<Cell>Device ID</Cell>} flexGrow={1}
              cell={(col) => <Cell>{props.data[col.rowIndex]['DeviceID']}</Cell>} />

            <Column columnKey="MessageSource" width={30}
              header={<Cell>Source</Cell>} flexGrow={1}
              cell={(col) => <Cell>{props.data[col.rowIndex]['MessageSource']}</Cell>} />

            <Column columnKey="MessageCodeText" width={30}
              header={<Cell>Code</Cell>} flexGrow={1}
              cell={(col) => <Cell>{props.data[col.rowIndex]['MessageCodeText']}</Cell>} />

            <Column columnKey="MessagePayload" width={30}
              header={<Cell>Payload</Cell>} flexGrow={4} align='left'
              cell={(col) => <Cell title={props.data[col.rowIndex]['MessagePayload']}>{props.data[col.rowIndex]['MessagePayload']}</Cell>} />

            <Column columnKey="UserName" width={30}
              header={<Cell>User</Cell>} flexGrow={1} align='left'
              cell={(col) => <Cell title={props.data[col.rowIndex]['UserName']}>{props.data[col.rowIndex]['UserName']}</Cell>} />

            {/* <Column columnKey='Timestamp' headerLabel={context.t('Time')} renderFunc={(value) => UIDateFormater(value, DateType.DATE_AND_TIME, props.timeZone)} />
            <Column columnKey='DeviceID' headerLabel={context.t('Device ID')} />
            <Column columnKey='MessageSource' headerLabel={context.t('Source')} />
            <Column columnKey='MessageCodeText' headerLabel={context.t('Code')} />
            <Column columnKey='MessagePayload' headerLabel={context.t('Payload')} /> */}
          </Table>
          <Pagination
            prevPageText={context.t('prev')}
            nextPageText={context.t('next')}
            firstPageText={context.t('first')}
            lastPageText={context.t('last')}
            activePage={currentPage}
            itemsCountPerPage={250}
            totalItemsCount={props?.data[0]?.Total || 0}
            pageRangeDisplayed={5}
            onChange={handlePageClick}
          />
        </>
      )}
    />
  )
}

ConfigurationLogsModal.contextTypes = {
  t: PropTypes.func.isRequired
}

export default ConfigurationLogsModal;
