import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';

const _ = require('lodash');

const SelectCell = (props, context) => {
  const { rowIndex, field, data, options, user } = props;

  const getOptionValue = (value, field) => {
    const options = props.options;
    const valueLabel = options.find((item) => {
      if (item.value == value) {
        return item;
      }
    });
    return valueLabel.label;
  }

  const onChange = (value) => {
    switch (field) {
      case 'user_sample_classify':
      case 'user_sop_classify':
      case 'sample_state': {
        props.updateSampleState(data[rowIndex].sample_uid, { [field]: value });
        break;
      }
      case 'AlertState': {
        const item = props.data[rowIndex];
        const id = item.id;
        const prevState = getOptionValue(item[field], field);
        const nextState = getOptionValue(value, field);
        const stateName = context.t('status');
        if (value == 6) {
          props.openPCADialog(true);
        } else {
          props.openStatusDialog(
            true,
            id,
            stateName,
            value,
            prevState,
            nextState,
            rowIndex,
            field
          );
        }
        break;
      }
      case 'AlertType': {
        props.openAlertType(value);
        break;
      }
    }
  }

  if (options == null) {
    return null;
  } else {
    return (
      <Cell className="input-cell">
        <select
          onChange={(e) => onChange(e.target.value)}
          disabled={!user.editMode}
          value={data[rowIndex][field] || 0}
        >
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.DisplayName || option.label}
            </option>
          ))}
        </select>
      </Cell>
    );
  }
}

SelectCell.contextTypes = {
  t: PropTypes.func.isRequired,
};

// const selectCell = reduxForm({
//   form: 'change-status2', // a unique name for this form
//   enableReinitialize: true
// })(SelectCell);

export default SelectCell;
