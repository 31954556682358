import * as React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
import { useDispatch } from 'react-redux';
import { sortG5Devices, storeG5DevicesAction } from '../../../actions/DeviceConfigurationAction';
import SortableHeaderCell from '../../SortableHeaderCell/SortableHeaderCell';

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomeCell = (props) => (
  <Cell>{props.items[props.rowIndex][props.columnKey]}</Cell>
)

const ServerFieldCell = (props) => {
  const val = props.items[props.rowIndex][props.columnKey];
  const valDisplay = val ? val.split('.')[0] : '--';
  return (<Cell>{valDisplay}</Cell>);
}

function ConfigsTable(props, context) {
  const { items, sort, local, uiInfo } = props;
  const dispatch = useDispatch();

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(-1);

  const [preFilterItems, setPreFilterItems] = React.useState([]);
  const lastSelectedIndex = React.useRef();

  const filterCheckboxRender = (item, index) => (
    <Cell>
      <input
        type='checkbox'
        checked={preFilterItems.indexOf(item.DeviceID) !== -1}
        onChange={(event) => {
          const { checked } = event.target;
          const { shiftKey } = event.nativeEvent;
          let preFiltersArr = [...preFilterItems];
          if (checked) {
            if (!shiftKey) {
              preFiltersArr.push(item.DeviceID);
            } else {
              const start = Math.min(index, lastSelectedIndex.current);
              const end = Math.max(index, lastSelectedIndex.current);
              const multiSelect = items.filter((_item, itemIndex) => itemIndex >= start && itemIndex <= end).map((item) => item.DeviceID);
              preFiltersArr.push(...multiSelect)
            }
          } else {
            if (!shiftKey) {
              // unchecked selected
              preFiltersArr = preFiltersArr.filter((itemDeviceId) => itemDeviceId !== item.DeviceID)
            } else {
              const start = Math.min(index, lastSelectedIndex.current);
              const end = Math.max(index, lastSelectedIndex.current);
              const multiSelect = items.filter((_item, itemIndex) => itemIndex >= start && itemIndex <= end).map((item) => item.DeviceID);
              preFiltersArr = preFiltersArr.filter((itemDeviceId) => !multiSelect.includes(itemDeviceId));
            }
          }
          setPreFilterItems(preFiltersArr);
          lastSelectedIndex.current = index;
        }}
      />
    </Cell>
  )

  const handleRowClick = (event, rowIndex) => {
    setSelectedItemIndex(rowIndex);
  }

  const rowClassNameGetter = (rowIndex) => {
    return rowIndex === selectedItemIndex ? 'active-row' : null;
  }

  // const handleItemClick = (item, _index) => {
  //   setSelectedItem(item);
  // }
  const sortTable = {
    sortDir: sort.direction,
    sortBy: sort.field,
    sort: (columnKey) => {
      dispatch(sortG5Devices(columnKey))
      setSelectedItemIndex(-1);
    },
  }

  return (
    <section>
      <Table
        rowsCount={items.length}
        width={uiInfo.pageWidth / 1.1635}
        maxHeight={uiInfo.pageHeight / 1.0909}
        headerHeight={50}
        rowHeight={40}
        onRowClick={handleRowClick}
        rowClassNameGetter={rowClassNameGetter}
      >
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey=''
          width={60}
          header={<Cell><button onClick={() => {
            props.handleSelection(preFilterItems);
            setPreFilterItems([])
          }}>Filter</button></Cell>}
          cell={(props) => filterCheckboxRender(items[props.rowIndex], props.rowIndex)}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='#'
          width={40}
          header={<Cell>#</Cell>}
          cell={(props) => <Cell>{props.rowIndex + 1}</Cell>}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='ID'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='DeviceID'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='LastStatusReportTime'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = items[props.rowIndex][props.columnKey]
            const time = UIDateFormater(value, DateType.DATE_AND_TIME, local);
            return <Cell>{time}</Cell>
          }
          }
        />
        <Column
          align='center'
          columnKey='SchedulerRefTime'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const { SchedulerRefTime, SchedulerRefTime5SecResolution } = items[props.rowIndex];
            const secondsValue = SchedulerRefTime5SecResolution || 0;
            const timeValue = (SchedulerRefTime * 10 * 60 * 1000) + (secondsValue * 5 * 1000); // value is 10 minut interval: (1000 millis * 60 sec * 10 minuts) * value
            return (<Cell>{UIDateFormater(timeValue || 1, DateType.TIME, 'gmt')}</Cell>);
          }}
        />
        <Column
          align='center'
          columnKey='ConfigTimeZone'
          width={160}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerCorrInterval'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerCorrNumSamples'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerNoiseInterval'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerNoiseNumSamples'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerNoiseTxTime'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerWakeupBefor'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='SchedulerRandomDelay'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='AudioRecordingDurationSec'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='CorrelationAudioRecordingDurationSec'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='CorrelationRadioRecordingDurationSec'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='AudioReportEnabled'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = items[props.rowIndex][props.columnKey];
            switch (value) {
              case 0: return <Cell>&#x2718;</Cell>;
              case 1: return <Cell>&#x2714;</Cell>;
              case 2: return <Cell>&#x2714; &#x2714;</Cell>;
              default: return null;
            }

          }}
        />
        <Column
          align='center'
          columnKey='CorrelationAudioSamplingFrequency'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='FmReceiverChannel_10k'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='LTEMode'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='APN'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='Server'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<ServerFieldCell items={items} />}
        />
        <Column
          align='center'
          columnKey='FallbackServer'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<ServerFieldCell items={items} />}
        />

        <Column
          align='center'
          columnKey='catm_packet_size'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='catm_rx_timeout'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='catm_tx_timeout'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='catm_flags'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='nbiot_packet_size'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='nbiot_rx_timeout'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='nbiot_tx_timeout'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />

        <Column
          align='center'
          columnKey='nbiot_flags'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='random_backoff_connect'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
      </Table>

      <footer style={{ backgroundColor: 'white' }}>
        <IconButton onClick={props.reloadDevices}>
          <RefreshIcon fontSize='small'/>
        </IconButton>

        <p style={{ float: 'right', margin: '5px 10px' }}>
        <span>
          Selected: {selectedItemIndex + 1}.
        </span>
        &emsp;
        <span>
          {items.length} Items
        </span>
        </p>
      </footer>

    </section>
    // <Table
    //   items={items}
    //   order={sort.direction}
    //   orderBy={sort.field}
    //   reloadDevices={props.reloadDevices}
    //   selectedKey='ID'
    //   // onItemClick={handleItemClick}
    //   setSortItems={(field) => dispatch(sortG5Devices(field))}
    // >
    //   <Column columnKey='' headerLabel={filterCheckboxHeadRender} renderFunc={(value, item, index) => filterCheckboxRender(item, index)} disableSort />
    //   <Column columnKey='ID' headerLabel={context.t('Sensor ID')} />
    //   <Column columnKey='DeviceID' headerLabel={context.t('Device ID')} />
    //   <Column columnKey='LastConfigReportTime' headerLabel={context.t('Last Updated')} renderFunc={(value) => UIDateFormater(value, DateType.DATE_AND_TIME, local)} />
    //   <Column columnKey='SchedulerRefTime5SecResolution' headerLabel={''} hidden />
    //   <Column columnKey='SchedulerRefTime' headerLabel={context.t('Sample Time')} renderFunc={(value, item) => {
    //     if (value == null) return null;
    //     const secondsValue = item.SchedulerRefTime5SecResolution || 0;
    //     const timeValue = (value * 10 * 60 * 1000) + (secondsValue * 5 * 1000); // value is 10 minut interval: (1000 millis * 60 sec * 10 minuts) * value
    //     console.log({
    //       // item,
    //       ref: item.SchedulerRefTime,
    //       sec: item.SchedulerRefTime5SecResolution,
    //       final: new Date(timeValue).toLocaleTimeString(),
    //     });
    //     return UIDateFormater(timeValue || 1, DateType.TIME, 'gmt');
    //   }} />
    //   <Column columnKey='ConfigTimeZone' headerLabel={context.t('Time Zone')} />
    //   <Column columnKey='SchedulerCorrInterval' headerLabel={context.t('Corr Interval')} />
    //   <Column columnKey='SchedulerCorrNumSamples' headerLabel={context.t('Corr Num Samples')} />
    //   <Column columnKey='SchedulerNoiseInterval' headerLabel={context.t('Noise Interval')} />
    //   <Column columnKey='SchedulerNoiseNumSamples' headerLabel={context.t('Noise Num Samples')} />
    //   <Column columnKey='SchedulerNoiseTxTime' headerLabel={context.t('Noise Tx Time')} />
    //   <Column columnKey='SchedulerWakeupBefor' headerLabel={context.t('Wakeup Befor')} />
    //   <Column columnKey='SchedulerRandomDelay' headerLabel={context.t('Random Delay')} />
    //   <Column columnKey='AudioRecordingDurationSec' headerLabel={context.t('Noise Audio Duration')} />
    //   <Column columnKey='CorrelationAudioRecordingDurationSec' headerLabel={context.t('Corr Audio Duration')} />
    //   <Column columnKey='CorrelationRadioRecordingDurationSec' headerLabel={context.t('Corr Radio Duration')} />
    //   <Column columnKey='AudioReportEnabled' headerLabel={context.t('Audio Report Enabled')} renderFunc={(value) => {
    //     switch (value) {
    //       case 0: return <span>&#x2718;</span>;
    //       case 1: return <span>&#x2714;</span>;
    //       default: return null;
    //     }
    //   }} />
    //   <Column columnKey='CorrelationAudioSamplingFrequency' headerLabel={context.t('Corr Sample Freq')} renderFunc={(value) => value && value * 100}/>
    //   <Column columnKey='FmReceiverChannel_10k' headerLabel={context.t('FM Channel')} renderFunc={(value) => value && (value / 100).toFixed(2)} />
    //   <Column columnKey='LTEMode' headerLabel={context.t('LTE Mode')} />
    //   <Column columnKey='APN' headerLabel={context.t('APN')} />
    //   <Column columnKey='Server' headerLabel={context.t('Server')} renderFunc={(value) => value && value.split('.')[0]} />
    //   <Column columnKey='FallbackServer' headerLabel={context.t('Secondary')} />
    // </Table>
  );
}

ConfigsTable.contextTypes = {
  t: PropTypes.func.isRequired
};

ConfigsTable.propTypes = {
  items: PropTypes.array.isRequired,
  local: PropTypes.string,
  reloadDevices: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  workingMode: PropTypes.string.isRequired,
};

export default ConfigsTable;
