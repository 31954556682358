import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import WorkOrderForm from '../../components/WorkOrderModal/WorkOrderForm';

const mapStateToProps = (state, ownProps) => {
  const units = state.local.units;
  const distanceFactor = state.local.factor[units];
  const mainState = state.leaksList;
  const options = mainState.optionList.options;
  const projectId = mainState.selectedProject;
  const selectedProject = getSelectedProject(projectId, mainState.projectsList);
  const selectedAlertId = mainState.leaksByProject.selectedFeature;
  const selectedAlert = getSelectedAlert(selectedAlertId, mainState.leaksByProject[projectId]);
  const selectedAlertType = getOption(selectedAlert, options, 'AlertType');
  const selectedAlertStatus = getOption(selectedAlert, options, 'AlertState');
  const initialValues = Object.assign({}, selectedAlert, {
    projectName: selectedProject.ShortName,
    alertTypeName: selectedAlertType,
    alertStateName: selectedAlertStatus,
    serviceProviderOptions: options.ContractorID,
  });

  return { initialValues, units, distanceFactor };
};

//const mapDispatchToProps = (dispatch, ownProps) => {
//  return {};
//};

const getSelectedProject = (id, projects) => {
  const map = projects.projectIndexMap;
  const project = projects.items[map[id]];
  return (project);
}

const getSelectedAlert = (id, projectState) => {
  const map = projectState.indexMap;
  const alert = projectState.items[map[id]];
  //const alert = projectState.details[id].data;
  return Object.assign({}, alert, projectState.details[id].data);
}

const getOption = (item, options, optionName) => {
  let opt = '';
  const optionsList = options[optionName];
  for (let i = 0; i < optionsList.length; i++) {
    if (item[optionName] == optionsList[i].value) {
      opt = optionsList[i].label;
      break;
    }
  }
  return (opt);
}

const CWorkOrderForm = connect(
  mapStateToProps,
  null
)(WorkOrderForm);

export default CWorkOrderForm
