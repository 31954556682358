/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import Alert from '@mui/lab/Alert';

import InputField from './InputField';

export default function SignIn(props, context) {
  const [error, setError] = React.useState(null);
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');

  function handleInputChange(setter) {
    return (event) => {
      setter(event.target.value);
    };
  }

  function doSignIn() {
    setError(null);
    props.setLoading(true);
    props.signInFunc(email, password, (data) => {
      props.setLoading(false);

      if (data instanceof Error) {
        setError(data);
      } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
        props.resetPassword('newPasswordRequired', { email, password });
      }
    });
  }

  return (
    <div style={{ margin: '50px 30px' }}>
      { error && <Alert style={{position: 'absolute', top: '-35%', fontSize: 'large'}} severity="error">{error.message}</Alert>}
      <InputField
        label={context.t('Email')}
        name='email'
        type='email'
        value={email}
        onChange={handleInputChange(setEmail)}
        disabled={props.loading}
      />
      <InputField
        label={context.t('Password')}
        name='password'
        type='password'
        value={password}
        onChange={handleInputChange(setPassword)}
        disabled={props.loading}
      />

      <a
        className="forgot-link"
        onClick={() => props.resetPassword('resetPassword')}
        href="#">
          {context.t('Forgot password')}
      </a>

      <div className="form-group form-actions">
        <div className="col-xs-6 col-xs-offset-3 login-field">
          <button
            className="login-submit btn btn-success btn-block"
            type="submit"
            onClick={doSignIn}>
              {context.t('LOGIN')}
            </button>
        </div>
      </div>
    </div>
  );
}

SignIn.contextTypes = {
  t: PropTypes.func.isRequired
};

SignIn.propTypes = {
  loading: PropTypes.bool,
  resetPassword: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  signInFunc: PropTypes.func.isRequired
};
