import React from 'react';
import PropTypes from 'prop-types';


const classNames = require('classnames');

let titles;
let sensorsStatuses;
let icons = [];
const statuses = ['installed', 'ready', 'inactive', 'uninstalled'];
const grounds = ['above', 'under'];
const types = ['accmtr', 'hdrpn'];
const syncs = ['gps', 'rad'];


class SensorsLegend extends React.Component {

    constructor(props) {
        super(props);

        this.bodyRender = this.bodyRender.bind(this);
    }

    bodyRender(status, index) {
        const icon = icons[status][index];
        return icon;
    }

    componentWillMount() {
        titles = [
            this.context.t('Overground Acc GPS'),
            this.context.t('Overground Acc RF'),
            this.context.t('Overground Hyd GPS'),
            this.context.t('Overground Hyd RF'),
            this.context.t('Underground Acc GPS'),
            this.context.t('Underground Acc RF'),
            this.context.t('Underground Hyd GPS'),
            this.context.t('Underground Hyd RF'),
        ];

        sensorsStatuses = [
            this.context.t('Active'),
            this.context.t('Ready'),
            this.context.t('Not Active'),
            this.context.t('Not Installed'),
        ];

        if (icons === null || icons.length === 0) {
            sensorsStatuses.forEach((status, index) => {
                icons[status] = [];
                grounds.forEach(ground => {
                    types.forEach(type => {
                        syncs.forEach(sync => {
                            const className = 'sensor ' + statuses[index] + ' ' + ground + ' ' + type + ' ' + sync;
                            icons[status].push(className);
                        });
                    });
                });
            });
        }
    }

    render() {
        return (
            <div>
                <table className="legend-table" style={{float: 'left'}}>
                    <thead>
                        <tr>
                            <th></th>
                            {
                                titles && titles.map((value, key) => {
                                    return (
                                        <th key={key}>{value}</th>
                                    );
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            sensorsStatuses && sensorsStatuses.map((status, index) => {
                                return (
                                    <tr className="table-row" key={index}>
                                        <th>{sensorsStatuses[index]}</th>
                                        {
                                            titles && titles.map((title, index) => {
                                                const icon = this.bodyRender(status, index);
                                                return (
                                                    <td className={icon} key={index}>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                    )
                            })
                        }

                        <tr className="table-row">
                            <th>{this.context.t('Relative sensor')}</th>
                            <td className="leak-couples-sensor first"></td>
                        </tr>
                        <tr className="table-row">
                            <th>{this.context.t('Second sensor')}</th>
                            <td className="leak-couples-sensor second"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

SensorsLegend.contextTypes = {
    t: PropTypes.func.isRequired
}

export default SensorsLegend;
