import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import { BarLoader } from 'react-spinners';
import { Select } from '../../components';

require('./ModalsStyle.scss');

class ManageDistributorProjectsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.onClose = this.onClose.bind(this);
  }

  onClose() {
    this.props.onClose();
  }

  render() {
    const { open } = this.state;
    const { distributorDetails, projectsOptions, isOpen } = this.props;

    return (
      <form className="form-horizontal sticky-actions" onSubmit={this.props.handleSubmit}>
        <AppModal
          open={isOpen}
          onClose={this.onClose}
          title={this.context.t('Edit {distributorName} Projects:', { distributorName: distributorDetails.UserName })}
          content={(
            <div className="form-group">
              <div style={{width: '100%', position: 'relative'}}>
                <Field name="projectsOptions"
                  component={(props) =>
                    (<Select {...props}
                      key={"ID"}
                      placeholderTitle={this.context.t('Select...')}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={projectsOptions}
                      styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                      menuPortalTarget={document.body}
                    />)
                  }
                />
              </div>
            </div>
          )}
          actions={(
            <div>
              <div style={{
              position: 'absolute',
              top: '18%',
              left: 0,
              width: '100%'
            }}>
              <BarLoader
                color="#4cae4c"
                width="110%"
                loading={false}
              />
            </div>
              <button
                className='btn btn-success btn-submit'
                disabled={false}
                onClick={this.props.handleSubmit}
                type="submit">
                {this.context.t('Save')}
              </button>
              <button
                className='btn btn-primary'
                onClick={this.onClose}>
                {this.context.t('Cancel')}
              </button>
            </div>
          )}
        />
      </form>
    );
  }
}

ManageDistributorProjectsModal.contextTypes = {
  t: PropTypes.func.isRequired
};

const manageDistributorProjectsModal = reduxForm({
  form: 'manageDistributorProjectsForm', // a unique name for this form
  enableReinitialize: true,
})(ManageDistributorProjectsModal);

export default manageDistributorProjectsModal;
