import React from 'react';
import PropTypes from 'prop-types';
import TabsContainer from '../Custom/Tabs/TabsContainer';

import AdvancedGraphs from './AdvancedGraphs';
import PreviousGraps from './PreviousGraps';
import OutputInfo from './OutputInfo';
import AnalysisDetails from './AnalysisDetails';
import Signals from './Signals';

export default function SampleAdvancedInfoTabs(props, context) {

  const advancedGraphsClass = props.show ? 'advanced-graphs show' : 'advanced-graphs';

  return (
    <div className={advancedGraphsClass}>
      {props.show &&
        <TabsContainer
          tabs={[
            {
              style: { height: 'inherit' },
              label: context.t('Previous Samples'),
              component: (
                <PreviousGraps
                  displayType={props.displayType}
                  bias={props.bias}
                  historyData={props.historyData}
                  axisDistances={props.axisDistances}
                  graphParams={props.graphParams}
                  graphsDistancesForAxis={props.graphsDistancesForAxis}
                  historyNoiseData={props.historyNoiseData}
                  timeZone={props.timeZone}
                  correlationAve={props.correlationAve}
                  histogramAve={props.histogramAve}
                />
              )
            },
            {
              style: { height: '46vh', overflowY: 'auto' },
              label: context.t('Advanced'),
              component: (
                <AdvancedGraphs
                  maxProbabilityGraphs={props.maxProbabilityGraphs}
                  graphParams={props.graphParams}
                  distancesForAxis={props.graphsDistancesForAxis}
                  maxProbabilityPositions={props.maxProbabilityPositions}
                  sensorsPositions={props.sensorsPositions}
                  metaData={props.rawData}
                />
              )
            },
            {
              style: { height: 'inherit' },
              label: context.t('Signals'),
              component: (
                <Signals
                  radio={props.rawData.radio_cor_sliced_data}
                  dual={
                    {
                      a: props.rawData.sliced_intensity_sig1_raw,
                      b: props.rawData.sliced_intensity_sig2_raw,
                    }
                  }
                  correlationInstance={props.correlationInstance}
                  histogramInstance={props.histogramInstance}
                  metaData={props.rawData}
                  graphParams={props.graphParams}
                  distancesForAxis={props.graphsDistancesForAxis}
                  maxProbabilityPositions={props.maxProbabilityPositions}
                  sensorsPositions={props.sensorsPositions}
                />
              )
            },
            {
              style: { height: 'inherit' },
              label: context.t('Details'),
              component: (
                <OutputInfo
                {...props.rawData}
                tensor={props.tensor}
                audioData={props.audioData}
                selectedSample={props.selectedSample}
                probabilityData={props.probabilityData}
              />
              )
            },
            {
              style: { height: 'inherit' },
              label: context.t('Tensors'),
              component: (
                <AnalysisDetails
                  analysisData={props.analysisData}
                  distanceFactor={props.distanceFactor}
                />
              )
            }
          ]}
        />
      }
    </div>
  );
}

SampleAdvancedInfoTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
