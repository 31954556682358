/* eslint-disable react/no-deprecated */
import { MAPS_END_POINT } from '../../constants/EndPoints';
import { CREATE_COUPLE_STEPS } from '../../reducers/planning';
import React from 'react';
import PropTypes from 'prop-types';
import html2canvas from 'html2canvas';

import Switch from '@mui/material/Switch';
import CLayersManager from '../../containers/CLayersManager/CLayersManager';
import CPipeAssessmentReportModal from '../../containers/CModals/CPipeAssessmentReportModal';
import CDmaHistoryModal from '../../containers/CModals/CDmaHistoryModal';
import CActualLeakLocationWindow from '../../containers/CActualLeakLocationWindow/CActualLeakLocationWindow';
import CGraphs from '../../containers/CGraphs/CGraphs';
import { DRAW_TYPES, BING_MAPS_KEY } from '../../constants/Misc';
import {
  LegendTabsContainer,
  Scale,
  SensorInfo,
  SensorLogInfo,
  SensorBitInfo,
  G5MessageStrip,
  G5NoiseStrip,
  TransientAlertInfo,
} from '../../components';

import { featuresTypes, zIndexs } from './Layers/Options';

// import all layers
import * as sensorsLayer from './Layers/Sensors';
import * as sopsLayer from './Layers/SOPs';
import * as alertsLayer from './Layers/Alerts';
import * as alertsPressureLayer from './Layers/AlertsPressure';
import * as noiseAlertsLayer from './Layers/NoiseAlert';
import * as waterMeterLayer from './Layers/WaterMeter';
import * as assessmentLayer from './Layers/Assessment';
import * as noiseSampleLayer from './Layers/NoiseSample';
import * as interferencesLayer from './Layers/Interferences';
import * as iQuariusSamplesLayer from './Layers/iQuariusSamples';
import * as mobileSamplesLayer from './Layers/MobileSamples';
import * as couplesCoverageLayer from './Layers/CouplesCoverage';
import * as coupleSensors from './Layers/CoupleSensors';
import * as markedCouplesLayer from './Layers/MarkedCouplePath';
import * as projectsCoverageLayer from './Layers/projectsCoverage';
import * as pipesLayer from './Layers/Pipes';
import * as dmasLayer from './Layers/DMAs';
import * as alertInspectLayer from './Layers/AlertsInspect';
import * as pipesConditionAssessments from './Layers/PipesConditionAssessments';

// OPEN LAYERS DEFINATIONS

import olMap from 'ol/Map';
import View from 'ol/View';
import { Style, Stroke, Fill } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource, OSM, BingMaps } from 'ol/source';
import { Point, LineString, Polygon, Circle } from 'ol/geom';
import XYZ from 'ol/source/XYZ.js';
import TileLayer from 'ol/layer/Tile.js';
import { Draw, Modify, Snap } from 'ol/interaction';
import { FullScreen, Zoom, Rotate, ScaleLine, defaults as defaultControls } from 'ol/control';
//import Sphere from 'ol/sphere';
import * as Sphere from 'ol/sphere';
import Collection from 'ol/Collection';
import * as condition from 'ol/events/condition';
import Select from 'ol/interaction/Select.js';
import { toLonLat } from 'ol/proj';

import { distanceTo } from 'geolocation-utils';
import MobileSampleDetailsStrip from '../Mobile/MobileSampleDetailsStrip';
import CropSelect from './Controllers/CropSelect';

const proj = require('ol/proj');

const classNames = require('classnames');
const _ = require('lodash');

require('./ol.scss');
require('./Map.scss');

//const test = require('ol');

const WITH_OL_SELECT = false; // Yair
const WIDTH_PIPE_STROKE = 4;

const WIDTH_GENERATED_COUPLE = 6;

const ANIMATION_DURATION_MILLIS = 750;

const newGenerationCouplePathStyle = new Style({
  stroke: new Stroke({
    color: [255, 0, 255, 0.8], // Blue
    width: WIDTH_GENERATED_COUPLE,
  }),
});

const scaleControl = new ScaleLine({ units: 'metric' });

export default class Map extends React.Component {
  constructor(props) {
    super(props);

    let nPipesSourceFactor;
    switch (props.pipesSource) {
      default:
      case 'materials':
        nPipesSourceFactor = 0;
        break;
      case 'presure':
        nPipesSourceFactor = 1;
        break;
      case 'diameter':
        nPipesSourceFactor = 2;
        break;
      case 'year':
        nPipesSourceFactor = 3;
        break;
      case 'pca':
        nPipesSourceFactor = 4;
        break;
    }

    const projectNdxId = this.props.projectObj
      ? this.props.projectObj.ndx * 5
      : '00';

    pipesLayer.setSource(
      new XYZ({
        url: `${MAPS_END_POINT}${
          projectNdxId + nPipesSourceFactor
        }/{z}/{x}/{y}.png`,
        crossOrigin: null,
      })
    );

    //self.cureCords = [];
    //self.addSopCords = [];

    // selected features layer:
    this.selectedFeaturesSource = new VectorSource({
      features: [],
    });

    this.newGenerationInterferencePolygonLayer = new VectorLayer({
      source: new VectorSource(),
    });

    this.DEBUG_POINTS = new VectorSource({
      features: [], //add an array of features
    });
    //this.DEBUG_POINTS_LAYER = new VectorLayer({
    //  style: icons.noiseSampleStyle,
    //  source: this.DEBUG_POINTS,
    //  zIndex: 999,
    //});

    // New generation couple points:
    this.newGenerationCouplePathPoints = new VectorSource({
      features: [], //add an array of features
    });
    this.newGenerationCouplePathPointsLayer = new VectorLayer({
      source: this.newGenerationCouplePathPoints,
      zIndex: 95,
    });
    // New Generation Couple path:
    this.newGenerationCouplePath = new VectorLayer({
      style: newGenerationCouplePathStyle,
      source: new VectorSource({
        features: [],
      }),
      zIndex: 90,
    });

    // satellite layer
    this.satelliteLayer = new TileLayer({
      source: new BingMaps({
        key: BING_MAPS_KEY,
        imagerySet: 'AerialWithLabels',
      }),
      name: 'satelliteLayer',
      zIndex: zIndexs.map,
    });
    // this.satelliteLayer = new olgm.layer.Google({
    //   name: 'satelliteLayer',
    //   mapTypeId: google.maps.MapTypeId.SATELLITE,
    //   zIndex: zIndexs.map,
    // });

    // Scale line layer
    this.scaleLineLayer = new VectorLayer({
      style: new Style({
        stroke: new Stroke({
          color: [255, 0, 0, 1], // Red
          width: WIDTH_PIPE_STROKE,
        }),
      }),
      source: new VectorSource({
        features: [],
      }),
      zIndex: zIndexs.scale,
    });
    let defLayer = {
      [featuresTypes.MOBILE_SAMPLES]: true
    };

    // set default layer by Selected Menu
    switch (props.path) {
      case 'alerts':
        defLayer[featuresTypes.ALERT] = true;
        break;

      case 'sensors':
      case 'couples':
        defLayer[featuresTypes.SENSOR] = true;
        break;

      case 'install':
        defLayer[featuresTypes.SOP] = true;
        defLayer[featuresTypes.SENSOR] = true;
        defLayer[featuresTypes.INTERFERENCE] = true;
        break;

      case 'manage':
        defLayer[featuresTypes.PROJECTS_COVERAGE] = true;
        break;

      case 'inspect':
        defLayer[featuresTypes.INSPECT_ALERTS] = true;
        defLayer[featuresTypes.PIPES_CONDITION_ASSESSMENTS] = true;
        break;

      default:
        break;
    }

    this.state = {
      featInfoStyle: {
        top: 0,
        left: 0,
        display: 'none',
      },
      featInfoTooltip: {
        id: '',
        intensity: '',
      },
      drawStyle: {
        cursor: 'default',
      },
      layers: defLayer,
      scaleMode: false,
      lastScalePoint: [],
      scaleDistance: 0,
      searchBoxValue: null,
      mount: false,
      map: false,
      polygonSelectMode: false,
      polygonSelectedSensors: [],
      polygonPoints: [],
    };

    this.mount = false;
    this.needUpdate = false;
    this.needCenter = true;

    this.selectFeature = this.selectFeature.bind(this);
    this.setFeatureInfo = this.setFeatureInfo.bind(this);
    this.changeLayers = this.changeLayers.bind(this);
    this.layersHandler = this.layersHandler.bind(this);
    this.setIsScaleOpen = this.setIsScaleOpen.bind(this);
    this.addScalePoint = this.addScalePoint.bind(this);
    this.addCouplePathPoint = this.addCouplePathPoint.bind(this);
    this.clearScalePoints = this.clearScalePoints.bind(this);
    this.removeLastScalePoint = this.removeLastScalePoint.bind(this);
    this.handleSearchBoxChange = this.handleSearchBoxChange.bind(this);
    this.handleSearchBoxKeyPress = this.handleSearchBoxKeyPress.bind(this);
    this.captureImage = this.captureImage.bind(this);
    this.handleResize = this.handleResize.bind(this);

    //this.convertCoordinateToLatLng = this.convertCoordinateToLatLng.bind(this);
  }

  // Invoked once after the first render
  componentDidMount() {
    var self = this;

    // Wait for styles to apply
    this.initTimer = setTimeout(function () {
      self.mount = true;
      self.props.updateState();

      self.setState({
        mount: true,
      });
    }, 500);
    this.props.getMapCapture(this.captureImage); // allow the Page to invoke this method.
    this.props.setMapLayersDisplay(this.layersHandler);

    this.initMap();
    window.addEventListener('resize', this.handleResize);
  }

  // Invoked whenever there is a prop change
  // Called BEFORE render
  componentWillReceiveProps(nextProps) {
    this.props.updateMapFeatures(nextProps, this);
  }

  // Determines if the render method should run in the subsequent step
  // Called BEFORE a render
  // Not called for the initial render
  shouldComponentUpdate(nextProps, nextState) {
    return true;
    // if (
    //     this.props.uiInfo.pageHeight !== nextProps.uiInfo.pageHeight ||
    //     this.state.featInfoStyle.display !== nextState.featInfoStyle.display ||
    //     this.state.featInfoTooltip.id !== nextState.featInfoTooltip.id ||
    //     _.isEqual(this.state.layers, nextState.layers)
    // ) {
    //     return true;
    // }
    // return false;
  }

  componentWillUnmount() {
    clearTimeout(this.initTimer);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize() {
    if (this.state.map) {
      const self = this;
      setTimeout(() => {
        self.state.map.updateSize();
      }, 200);
    }
  }

  captureImage() {
    const self = this;
    return new Promise((resolve, reject) => {
      const targetElement = self.state.map.getTargetElement();
      html2canvas(targetElement, {
        allowTaint: true,
        useCORS: true,
        removeContainer: false,
        backgroundColor: 'green',
      })
        .then((canvas) => {
          var imagePng = canvas.toDataURL('image/png');
          //console.log(imagePng);
          resolve(imagePng);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getLayerByType(layerName) {
    let layer;
    switch (layerName) {
      // @TODO: Change 'leaksLayer' to 'alertsLayer'
      case featuresTypes.ALERT:
        layer = alertsLayer;
        break;
      case featuresTypes.NOISE_ALERT:
        layer = noiseAlertsLayer;
        break;

      case featuresTypes.PRESSURE_ALERT:
        layer = alertsPressureLayer;
        break;
      case featuresTypes.ASSESSMENT:
        layer = assessmentLayer;
        break;
      case featuresTypes.SENSOR:
        layer = sensorsLayer;
        break;
      case featuresTypes.WATER_METER:
        layer = waterMeterLayer;
        break;
      case featuresTypes.SOP:
        layer = sopsLayer;
        break;
      case featuresTypes.NOISE:
        layer = noiseSampleLayer;
        break;
      case featuresTypes.IQUARIUS_PUBLISHED_SAMPLES:
        layer = iQuariusSamplesLayer;
        break;
      case featuresTypes.MOBILE_SAMPLES:
        layer = mobileSamplesLayer;
        break;
      case featuresTypes.INTERFERENCE:
        layer = interferencesLayer;
        break;
      case featuresTypes.COUPLES_COVERAGE:
        layer = couplesCoverageLayer;
        break;
      case featuresTypes.COUPLE_PATH:
        layer = markedCouplesLayer;
        break;
      case featuresTypes.SENSOR_ON_COUPLE:
        layer = coupleSensors;
        break;
      case featuresTypes.PROJECTS_COVERAGE:
        layer = projectsCoverageLayer;
        break;
      case featuresTypes.PIPES:
        layer = pipesLayer;
        break;
      case featuresTypes.DMAS:
        layer = dmasLayer;
        break;
      case featuresTypes.INSPECT_ALERTS:
        layer = alertInspectLayer;
        break;
      case featuresTypes.PIPES_CONDITION_ASSESSMENTS:
        layer = pipesConditionAssessments;
        break;
      default:
        //console.log("default case in getLayerByType: " + layerName);
        break;
    }
    return layer;
  }

  changeLayers(layerName, visibility = false) {
    const layers = this.props.mapState.layers;
    //if (layers[layerName] != visibility) {
    if (this.state.map) {
      // this check if initialized.
      if (layerName == 'satelliteLayer') {
        // the satellite layer is not write in separate file:
        if (visibility) {
          this.state.map.getLayers().push(this[layerName]);
        } else {
          this.state.map.getLayers().remove(this[layerName]);
        }
      } else {
        const cLayer = this.getLayerByType(layerName);
        const layers = cLayer.getLayers();
        if (visibility) {
          layers.forEach((itrLayer) => {
            this.state.map.getLayers().push(itrLayer);
          });
        } else {
          layers.forEach((itrLayer) => {
            this.state.map.getLayers().remove(itrLayer);
          });
        }
      }
    }
    //}
  }

  layersHandler(evt, layer) {
    //console.log('layers start');
    let self = this;
    const { layers } = this.state;
    // layers[layer] = layers[layer] == true ? false : true;

    if (layers[layer]) {
      layers[layer] = false;
    } else {
      layers[layer] = true;
    }

    setTimeout(function () {
      if (self.state.map == null) {
        debugger;
      }
      if (self.state.map.getProperties().name === 'OSM') {
        if (layer == 'satelliteLayer') {
          const osmMap = self.state.map
            .getLayers()
            .getArray()
            .find((layer) => {
              return layer.getProperties().name == 'OSM';
            });
          if (self.state.layers[layer]) {
            osmMap.setVisible(false);
            // show satellites:
            //self.state.map.getLayers().remove(self.OsmMapLayer);
            //self.state.map.getLayers().push(self.googleMapLayer);
          } else {
            osmMap.setVisible(true);
            //self.state.map.getLayers().remove(self.googleMapLayer);
            //self.state.map.getLayers().push(self.OsmMapLayer);
          }
        }
      }

      if (layers[layer]) {
        if (self[layer] != null) {
          // TODO: remove after clean Map.js
          self.state.map.getLayers().push(self[layer]);
        } else {
          const cLayer = self.getLayerByType(layer);
          const layers = cLayer.getLayers();
          layers.forEach((itrLayer) => {
            self.state.map.getLayers().push(itrLayer);
          });
        }
      } else {
        if (self[layer] != null) {
          // TODO: remove after clean Map.js
          self.state.map.getLayers().remove(self[layer]);
        } else {
          const cLayer = self.getLayerByType(layer);
          const layers = cLayer.getLayers();
          layers.forEach((itrLayer) => {
            self.state.map.getLayers().remove(itrLayer);
          });
        }
      }

      self.setState({ layers: layers });
    }, 100);

    // console.log('layers end');
  }

  getSelectedFeature() {
    let selectedFeatures = [];

    selectedFeatures = WITH_OL_SELECT
      ? this.select.getFeatures()
      : this.tempFeaturesLayer.getSource().getFeatures();

    return selectedFeatures;
  }

  addSelectedFeature(feature) {
    if (WITH_OL_SELECT) {
      this.getSelectedFeature().push(feature);
    } else {
      this.tempFeaturesLayer.getSource().addFeature(feature);
    }
  }

  selectFeature(feature) {
    const { selectedFeature } = this.props;
    const featProps = feature.getProperties();
    const featureType = feature.getProperties().type;
    const ignoredClickItems = ['waterMeterAlerts']; //"sop",
    if (ignoredClickItems.indexOf(featureType) == -1) {
      // if not in ignored types
      const changed = selectedFeature != featProps.id ? true : false;

      //if (coupleSensors.hasFeatures()) {
      //    if (featureType !== 'alertCoupleSensor' && featureType !== 'couplePathLine') {
      //        coupleSensors.clear();
      //    }
      //}

      if (changed) {
        if (featProps.id) {
          //this.props.handleFeatureClick(featProps.id, featProps, 'Map');
          this.props.onFeatureClick(featProps, this.props);
        }
        return true;
      }
    }
    return false;
  }

  clearSelectedFeaturesByType(type) {
    const selectedFeatures = this.getSelectedFeature();
    selectedFeatures.forEach(function (feature) {
      if (feature != null) {
        const featureType = feature.getProperties().type;
        if (featureType == type) {
          feature.setStyle(false);
          selectedFeatures.remove(feature);

          if (type == featuresTypes.ALERT) {
            coupleSensors.clear();
            markedCouplesLayer.clear();
          }
        }
      }
    });
  }

  clearSelectedFeatures() {
    const selectedFeatures = this.getSelectedFeature();
    if (WITH_OL_SELECT) {
      selectedFeatures.forEach(function (feature) {
        feature.setStyle(false);
        selectedFeatures.remove(feature);
      });
      if (selectedFeatures) {
        selectedFeatures.clear();
      }
    } else {
      if (selectedFeatures) {
        this.tempFeaturesLayer.getSource().clear()
        // selectedFeatures.splice(0, selectedFeatures.length);
      }
    }

    coupleSensors.clear();
    markedCouplesLayer.clear();
  }

  setIsScaleOpen(isOpen) {
    if (this.state.scaleMode !== isOpen) {
      this.setState({
        scaleMode: isOpen,
        drawStyle: {
          cusrsor: isOpen ? 'pointer' : 'default',
        },
      });
    }

    if (isOpen) {
      this.state.map.getLayers().push(this.scaleLineLayer);
    } else {
      this.state.map.getLayers().remove(this.scaleLineLayer);
      this.clearScalePoints();
    }
  }

  addScalePoint(coordinates) {
    let prevPoint = this.state.lastScalePoint;
    if (prevPoint.length == 0) {
      prevPoint = coordinates;
    }
    const sourceLength = this.scaleLineLayer.getSource().getFeatures().length;
    const lineString = new LineString([prevPoint, coordinates], 'XY');
    const length = this.calculateLengthOfLineString(lineString);
    const lineStringFeature = new Feature({
      key: 'ScalePoint' + sourceLength,
      type: 'ScalePoint',
      geometry: lineString,
    });
    this.scaleLineLayer.getSource().addFeature(lineStringFeature);
    //console.log(coordinates);
    this.setState({
      lastScalePoint: coordinates,
      scaleDistance: this.state.scaleDistance + length,
    });
  }

  addCouplePathPoint(feature, coordinate) {
    let latLngPoint;
    let indexForInsert = null;
    let featureProperties = null;
    if (feature == null) {
      latLngPoint = this.convertCoordinateToLatLng(coordinate);
    } else {
      featureProperties = feature.getProperties();
      switch (featureProperties.type) {
        case 'generationCouplePath':
          indexForInsert = featureProperties.id + 1;
          latLngPoint = this.convertCoordinateToLatLng(coordinate);
          break;
        default:
          const featureCoordinate = feature.getGeometry().getCoordinates();
          latLngPoint = this.convertCoordinateToLatLng(featureCoordinate);
          break;
      }
    }
    this.props.addCouplePathPoint(
      latLngPoint,
      indexForInsert,
      featureProperties
    );
  }

  calculateLengthOfLineString(lineString) {
    // get points of lineString (for simplicity assume 2 points)
    const coordinates = lineString.getCoordinates();

    // transform points from map projection (usually spherical mercator) to WGS84
    //const mapProjection = this.state.map.getView().getProjection();
    const t1 = this.convertCoordinateToLatLng(coordinates[0]); //proj.transform(coordinates[0], mapProjection, 'EPSG:4326');
    const t2 = this.convertCoordinateToLatLng(coordinates[1]); //proj.transform(coordinates[1], mapProjection, 'EPSG:4326');

    // create sphere to measure on
    // const wgs84sphere = new Sphere(6378137); // one of WGS84 earth radius'

    // get distance on sphere
    // const dist = wgs84sphere.haversineDistance(t1, t2);
    const dist = distanceTo(t1, t2);

    return dist;
  }

  clearScalePoints() {
    this.scaleLineLayer.getSource().clear();
    this.setState({
      lastScalePoint: [],
      scaleDistance: 0,
    });
  }

  removeLastScalePoint() {}

  handleSearchBoxChange(event) {
    this.setState({
      searchBoxValue: event.target.value,
    });
  }

  handleSearchBoxKeyPress(event) {
    if (event.key == 'Enter') {
      this.props.onSearchBoxKeyPress(
        this.state.searchBoxValue,
        (addressCoordinates) => {
          this.setCenter(addressCoordinates, 18);
        }
      );
    }
  }

  convertCoordinateToLatLng(coordinate) {
    const mapProjection = this.state.map.getView().getProjection();
    const latLng = proj.transform(coordinate, mapProjection, 'EPSG:4326');
    return latLng;
  }

  // return the leaksLayer (= alertsLayer) from 'Alerts.js'
  getAlertsLayer() {
    return alertsLayer.layer;
  }

  getNoiseAlertsLayer() {
    return noiseAlertsLayer.layer;
  }

  getPressureAlertsLayer() {
    return alertsPressureLayer.layer;
  }

  // return the sensorsLayer from 'Sensors.js'
  getSensorsLayer() {
    return sensorsLayer.layer;
  }

  getInterferencesLayer() {
    return interferencesLayer.layer;
  }

  getMobileSamplesLayer() {
    return mobileSamplesLayer.layer;
  }

  // return the sensorsLayer from 'Sensors.js'
  getSOPsLayer() {
    return sopsLayer.layer;
  }

  getAssessmentLayer() {
    return assessmentLayer.layer;
  }

  refreshLayer(layerName) {
    if (this.state.layers[layerName]) {
      const cLayer = this.getLayerByType(layerName);
      const layers = cLayer.getLayers();
      layers.forEach((itrLayer) => {
        this.state.map.getLayers().remove(itrLayer);
        this.state.map.getLayers().push(itrLayer);
      });
    }
  }

  setLayerSource(layerTypeName, features, props, setCenter = false) {
    const cLayer = this.getLayerByType(layerTypeName);
    if (cLayer != null) {
      // if (layerTypeName == featuresTypes.COUPLES_COVERAGE) {
      //   cLayer.getLayers().forEach((itrLayer) => {
      //     this.state.map.getLayers().remove(itrLayer);
      //     this.state.map.getLayers().push(itrLayer);
      //   });
      // }
      const icons = cLayer.setSource(features, props);
      if (setCenter && icons && icons.length) {
        this.setCenterCoordinat(icons[0].getGeometry().getCoordinates());
      }
    }
  }

  hasLayerFeatures(layerTypeName) {
    const layer = this.getLayerByType(layerTypeName);
    return layer.hasFeatures();
  }

  clearLayer(layerTypeName) {
    const layer = this.getLayerByType(layerTypeName);
    layer.clear();
  }

  drawPolygon(points) {
    const layerSource = this.newGenerationInterferencePolygonLayer.getSource();
    layerSource.clear();

    const polygone = new Feature({
      geometry: new Polygon([points]),
      type: 'interference',
    });
    polygone.setStyle(
      new Style({
        fill: new Fill({
          color: 'rgba(0,0,0,1)',
        }),
        stroke: new Stroke({
          color: '#ffff00',
          width: 1.5,
        }),
      })
    );
    layerSource.addFeature(polygone);
  }

  getFeatureCoordinate(feature) {
    const geometry = feature.getGeometry();
    const type = geometry.getType();
    let coordinate;

    switch (type) {
      case DRAW_TYPES.POINT:
        coordinate = [geometry.getCoordinates()];
        break;
      case DRAW_TYPES.POLYGON:
      case DRAW_TYPES.LINE:
        coordinate = geometry.getCoordinates()[0];
        break;
      case DRAW_TYPES.CIRCLE:
        coordinate = [
          geometry.getFirstCoordinate(),
          geometry.getLastCoordinate(),
        ];
        break;
      default:
        break;
    }

    return coordinate;
  }

  transformCoordinatesToLngLat(coordinates) {
    // transforming x, y to lng, lat
    const featureLngLatPoints = coordinates.map((coordinate) => {
      const point = proj.transform(
        [coordinate[0], coordinate[1]],
        'EPSG:3857',
        'EPSG:4326'
      );
      return point;
    });

    return featureLngLatPoints;
  }

  setDrawMode(command, drawType, points) {
    switch (command) {
      case 'on':
        this.turnOnDrawMode(drawType, points);
        break;

      case 'off':
        this.turnOffDrawMode();
        break;

      case 'change':
        this.changeDrawMode(drawType);
        break;

      default:
        break;
    }
  }

  // Clear the drawned feature
  clearDrawnFeatures() {
    this.drawModeVector.getSource().clear();
  }

  turnOnDrawMode(drawType, points) {
    const self = this;
    const mapLayers = this.state.map.getLayers();

    this.interactionDraw = new Draw({
      source: this.drawModeSource,
      type: drawType,
      zIndex: zIndexs.raster,
    });

    if (points == null) {
      this.state.map.addInteraction(this.interactionDraw);
      this.state.map.addInteraction(this.interactionSnap);
      this.state.map.addInteraction(this.interactionModify);
    } else {
      var pointsArray = [];
      let geometry = null;
      points.forEach((itrPoint) => {
        const tranformedPoint = proj.transform(
          [itrPoint[0], itrPoint[1]],
          'EPSG:4326',
          'EPSG:3857'
        );
        pointsArray.push(tranformedPoint);
      });

      if (drawType == 'Polygon') {
        geometry = new Polygon([pointsArray]);
      } else if (drawType == 'Circle') {
        geometry = new Circle(
          pointsArray[0],
          pointsArray[1][0] - pointsArray[0][0]
        );
      }

      // let geometry = new Polygon([pointsArray]);

      const style = new Style({
        fill: new Fill({
          color: 'rgba(255, 5, 255, 0.3)',
        }),
        stroke: new Stroke({
          color: '#a3a3a3',
          width: 1.5,
        }),
      });

      const feature = new Feature({
        geometry: geometry,
      });

      // feature.setStyle(style);
      // type: featuresTypes.INTERFERENCE,
      // id: itrFeature.id,
      // interferenceType: itrFeature.Type

      // this.drawModeSource = new VectorSource({
      //   features: [feature],
      //   style: style
      // });
      this.drawModeSource.addFeatures([feature]);

      this.interactionModify = new Modify({
        source: this.drawModeSource,
      });

      this.state.map.addInteraction(this.interactionSnap);
      this.state.map.addInteraction(this.interactionModify);
    }

    mapLayers.push(this.drawModeRaster);
    mapLayers.push(this.drawModeVector);

    this.interactionDraw.on('drawend', (event) => {
      // [feature, target, type]
      const feature = event.feature;
      if (feature != null) {
        this.onDrawEndOrModify(feature);
      }
      return 'interactionDrawEnd'; // Unique key for the listener
    });

    this.interactionModify.on('modifyend', (event) => {
      const feature = event.features.getArray()[0];
      if (feature != null) {
        this.onDrawEndOrModify(feature);
      }
      return 'interactionModifyEnd'; // Unique key for the listener
    });
  }

  onDrawEndOrModify(drawnFeature) {
    const geometry = drawnFeature.getGeometry();
    // stop drawing:
    // this.interactionDraw.finishDrawing(); -- @TODO: check how to work with this method..
    if (geometry != null && geometry.getType() != 'Point') {
      this.state.map.removeInteraction(this.interactionDraw);
    }

    let featureLngLatPoints = null; //this.transformFeatureCoordinatesToLngLat(drawnFeature);
    const drawnFeatureCoordinate = this.getFeatureCoordinate(drawnFeature);
    const pixel = this.state.map.getPixelFromCoordinate(drawnFeatureCoordinate);

    let overDrawnFeature = null;
    this.state.map.forEachFeatureAtPixel(pixel, (feature, layer) => {
      if (feature && layer && overDrawnFeature == null) {
        overDrawnFeature = feature;
      }
    });

    if (overDrawnFeature != null) {
      const overDrawnFeatureGeometry = overDrawnFeature.getGeometry();
      featureLngLatPoints = overDrawnFeatureGeometry.getClosestPoint(
        drawnFeatureCoordinate
      );
    } else {
      featureLngLatPoints = drawnFeatureCoordinate;
    }

    const finalFeatureLngLatPoints =
      this.transformCoordinatesToLngLat(featureLngLatPoints);

    this.props.onFeatureDrawned(finalFeatureLngLatPoints);

    if (this.state.polygonSelectMode) {
      const points = geometry.getCoordinates()[0].map((coordinate, index) => {
        const lonLat = toLonLat(coordinate);
        return `${lonLat[0]} ${lonLat[1]}`;
      })
      const selectedSensors = this.props.sensors.sensors.filter((sensor) => {
        const sensorPoint = proj.transform(
          [sensor.Longitude, sensor.Latitude],
          'EPSG:4326',
          'EPSG:3857'
        );
        // const sensorPoint = [sensor.Longitude, sensor.Latitude];
        return (geometry.intersectsExtent(sensorPoint));
      });

      this.setState({
        polygonSelectedSensors: selectedSensors,
        polygonPoints: points
      });
    }
  }

  turnOffDrawMode() {
    const mapLayers = this.state.map.getLayers();

    const mapInteractionsArray = this.state.map.getInteractions().getArray();

    // remove interactions: (we check if the 'interactionDraw' is exist. maybe it already removed when 'drawend' was called)
    this.state.map.removeInteraction(this.interactionModify);
    this.state.map.removeInteraction(this.interactionSnap);
    if (mapInteractionsArray.indexOf(this.interactionDraw) != -1) {
      this.state.map.removeInteraction(this.interactionDraw);
    }

    // clear the drawned feature.
    this.clearDrawnFeatures();

    // remove drow-mode layers:
    mapLayers.remove(this.drawModeRaster);
    mapLayers.remove(this.drawModeVector);
  }

  changeDrawMode(drawType) {
    const self = this;

    this.state.map.removeInteraction(this.interactionDraw);
    this.state.map.removeInteraction(this.interactionSnap);
    this.state.map.removeInteraction(this.interactionModify);

    this.interactionDraw = new Draw({
      source: this.drawModeSource,
      type: drawType,
      zIndex: zIndexs.raster,
    });

    this.state.map.addInteraction(this.interactionDraw);
    this.state.map.addInteraction(this.interactionSnap);
    this.state.map.addInteraction(this.interactionModify);

    // clear the drawned feature.
    this.clearDrawnFeatures();

    this.interactionDraw.on('drawend', (event) => {
      // [feature, target, type]
      const feature = event.feature;
      if (feature != null) {
        this.onDrawEndOrModify(feature);
      }

      return 'interactionDrawEnd'; // Unique key for the listener
    });
    this.interactionModify.on('modifyend', (event) => {
      const feature = event.features.getArray()[0];
      if (feature != null) {
        this.onDrawEndOrModify(feature);
      }

      return 'interactionModifyEnd'; // Unique key for the listener
    });
  }

  drawNewCouplePath(path) {
    const self = this;
    let iconFeatures = [];
    let sectionsLength = 0;
    this.newGenerationCouplePath.getSource().clear();
    const couplePoints = this.newGenerationCouplePathPointsLayer
      .getSource()
      .getFeatures();
    if (couplePoints.length > 0) {
      couplePoints.forEach((pointFeature) => {
        this.newGenerationCouplePathPointsLayer
          .getSource()
          .removeFeature(pointFeature);
      });
    }

    if (path && path.length > 0) {
      path.forEach((point, index, allPoints) => {
        // add marker on point.
        const pointMarker = new Feature({
          geometry: new Point(
            proj.transform(
              [point.Longitude, point.Latitude],
              'EPSG:4326',
              'EPSG:3857'
            )
          ),
          type: 'PointMarker',
          index: point.index,
        });
        pointMarker.on(
          'change',
          function () {
            //console.log('Feature Moved To:' + this.getGeometry().getCoordinates());
            const edittedPoint = this;
            const attr = edittedPoint.getProperties();
            const coordinates = self.convertCoordinateToLatLng(
              edittedPoint.getGeometry().getCoordinates()
            );
            self.props.setCouplePathPointPosition(attr.index, coordinates);
          },
          pointMarker
        );
        iconFeatures.push(pointMarker);

        this.newGenerationCouplePathPointsLayer
          .getSource()
          .addFeature(pointMarker);

        if (point.index > 0) {
          const prevPoint = allPoints.find((element) => {
            return element.index == point.index - 1;
          });
          if (prevPoint != null) {
            const coordinates = [
              proj.transform(
                [prevPoint.Longitude, prevPoint.Latitude],
                'EPSG:4326',
                'EPSG:3857'
              ),
              proj.transform(
                [point.Longitude, point.Latitude],
                'EPSG:4326',
                'EPSG:3857'
              ),
            ];
            const lineFeature = new Feature({
              geometry: new LineString(coordinates, 'XY'),
              name: 'Line',
              id: prevPoint.index,
              type: 'generationCouplePath',
            });
            lineFeature.setStyle(newGenerationCouplePathStyle);
            self.newGenerationCouplePath.getSource().addFeature(lineFeature);

            const length = this.calculateLengthOfLineString(
              lineFeature.getGeometry()
            );
            sectionsLength += length;
          }
        }
      });
      const modify = new Modify({
        features: new Collection(iconFeatures),
      });
      this.state.map.addInteraction(modify);
    }

    return sectionsLength;
  }

  getFeatureStyleByPath(path, feature, selected, props) {
    let featureStyle = false;
    const type = feature.getProperties().type;
    const cLayer = this.getLayerByType(type);

    if (cLayer != null) {
      featureStyle = cLayer.getStyle(feature, selected, props);
    }

    return featureStyle;
  }
  getZoom() {
    let zoom = 10;

    if (this.state.map) {
      zoom = this.state.map.getView().getZoom();
    }

    return zoom;
  }

  getCenter() {
    return this.state.map.getCenter();
  }

  setCenter(feature, zoom) {
    if (feature.length) {
      const center = proj.transform(feature, 'EPSG:4326', 'EPSG:3857');
      this.setCenterCoordinat(center, zoom);
    }
  }

  setCenterCoordinat(coordinate, zoom) {
    if (this.state.map) {
      const mapView = this.state.map.getView();
      if (zoom != null) {
        // mapView.setZoom(zoom);
        mapView.animate({
          zoom,
          duration: ANIMATION_DURATION_MILLIS
        });

      }
      if (coordinate.length) {
        // mapView.setCenter(coordinate);
        mapView.animate({
          center: coordinate,
          duration: ANIMATION_DURATION_MILLIS
        });
      }
    }
  }

  updateTempFeature(position) {
    this.tempFeaturesLayer.getSource().clear();
    if (position != null) {
      this.setCenter(position);
      const feature = new Feature({
        geometry: new Point(proj.transform(position, 'EPSG:4326', 'EPSG:3857')),
      });
      const style = this.getFeatureStyleByPath(
        'alerts',
        feature,
        true,
        this.props
      );
      feature.setStyle(style);
      this.tempFeaturesLayer.getSource().addFeatures([feature]);
    }
  }

  setFeatureInfo(feature, pixel) {
    let infoToolTip = '';
    const attr = feature.getProperties();
    const layer = this.getLayerByType(attr.type);
    if (attr.id != null) {
      const cLayer = this.getLayerByType(attr.type);
      if (cLayer != null) {
        infoToolTip = layer.getInfoTooltip(feature);
      }
      this.setState({
        featInfoStyle: {
          top: pixel[1] - 15,
          left: pixel[0],
          display: 'block',
        },
        featInfoTooltip: infoToolTip,
      });
    }
  }

  initMap() {
    var self = this;

    const { center } = this.props;

    // Map centering
    let pCenter;
    if (center && center.lat && center.lng) {
      pCenter = proj.transform(
        [center.lng, center.lat],
        'EPSG:4326',
        'EPSG:3857'
      );
      this.needCenter = false;
    } else {
      pCenter = [0, 0];
    }

    // Selected features layer
    this.tempFeaturesLayer = new VectorLayer({
      source: this.selectedFeaturesSource,
    });

    // Google Maps layer
    // this.googleMapLayer = new olgm.layer.Google({
    //   name: 'Google',
    //   styles: [
    //     {
    //       elementType: 'geometry',
    //       stylers: [
    //         { saturation: 0 },
    //         { lightness: 0 }
    //       ]
    //     },
    //     {
    //       featureType: "transit", // Apply the rule to station
    //       stylers: [{ visibility: "off" }]
    //     },
    //     {
    //       featureType: "road", // Apply the rule signs on roads
    //       elementType: "labels.icon",
    //       stylers: [{ visibility: "off" }]
    //     },
    //     {
    //       featureType: "administrative.country", // Apply the rule - No "Hakav Hayarok"
    //       elementType: "geometry",
    //       stylers: [{ visibility: "off" }]
    //     },
    //     {
    //       featureType: "poi", // Apply the rule to poi ( Point Of Intrest ).
    //       stylers: [{ visibility: "off" }]
    //     }
    //   ],
    // });

    this.OsmMapLayer = new TileLayer({
      name: 'OSM',
      visible: true,
      source: new OSM(),
    });

    // Draw Mode Layers
    this.drawModeSource = new VectorSource();
    this.drawModeRaster = new TileLayer({
      name: 'Raster',
      source: this.OsmMapLayer.getSource(),
    });
    this.drawModeVector = new VectorLayer({
      name: 'DrawVector',
      source: this.drawModeSource,
      // style: new Style({
      //   fill: new Fill({
      //     color: 'rgba(255, 255, 255, 0.2)'
      //   }),
      //   stroke: new Stroke({
      //     color: '#ff5a31',
      //     width: 2
      //   }),
      //   image: new Circle({
      //     radius: 7,
      //     fill: new Fill({
      //       color: '#ffcc33'
      //     })
      //   })
      // })
    });

    // Draw Mode Interactions
    this.interactionModify = new Modify({
      source: this.drawModeSource,
    });
    this.interactionDraw = new Draw({
      source: this.drawModeSource,
      // type: Point / LineString / Polygon / Circle
      type: 'Circle',
      zIndex: zIndexs.raster,
    });
    this.interactionSnap = new Snap({
      source: this.drawModeSource,
    });

    const mainMapLayer = this.OsmMapLayer;
    // Interactions
    this.select = new Select({
      condition: condition.never,
    });
    this.tempFeaturesLayer.setZIndex(100);

    const defLayers = [
      mainMapLayer,
      this.tempFeaturesLayer,
      ...coupleSensors.getLayers(),
      ...markedCouplesLayer.getLayers(),
      ...mobileSamplesLayer.getLayers(),
      //...alertsPressureLayer.getLayers(),
    ]; //Set the default layers

    switch (this.props.path) {
      default:
      case 'alerts':
        defLayers.push(...alertsLayer.getLayers());
        break;
      case 'sensors':
      case 'couples':
        defLayers.push(...sensorsLayer.getLayers());
        break;
      case 'install':
        defLayers.push(...sopsLayer.getLayers());
        defLayers.push(...sensorsLayer.getLayers());
        defLayers.push(...interferencesLayer.getLayers());
        break;
      case 'manage':
        defLayers.push(...projectsCoverageLayer.getLayers());
        break;
      case 'inspect':
        defLayers.push(...alertInspectLayer.getLayers());
        defLayers.push(...pipesConditionAssessments.getLayers());
        break;
      // case 'mobile':
      //   defLayers.push(...mobileSamplesLayer.getLayers());
      //   break;
    }

    const map = new olMap({
      // use OL3-Google-Maps recommended default interactions
      controls: [
        new FullScreen(),
        new Zoom(),
        scaleControl,
        /*new ScaleLine({minWidth: 350}),*/ new Rotate(),
      ],
      layers: defLayers,
      target: 'map',
      view: new View({
        center: pCenter,
        zoom: 13,
        maxZoom: 22,
      }),
      moveTolerance: 10.0,
      zIndex: zIndexs.map,
    });

    //////////////////////////// RONI's CHANGES ////////////////////////////

    // allways display couple path (on sensor click).

    // Couple generation:
    //map.getLayers().push(self['DEBUG_POINTS_LAYER']);
    map.getLayers().push(self['newGenerationCouplePathPointsLayer']);
    map.getLayers().push(self['newGenerationCouplePath']);

    map.getLayers().push(self.newGenerationInterferencePolygonLayer);

    //self.state.map.getLayers().push(self['couplesLayer']);
    //self.state.map.getLayers().push(self['couplesCoverageLayer']);

    map.updateSize();

    if (this.props.mapRef) {
      debugger;
    }
    // Catching the event of single click on map
    map.on('singleclick', function (e) {
      //console.log('singleclickclick on map: ' + (e.toString()));
      let features = [];
      //let scalePoint = false;
      let selectedFeature;

      self.state.map.forEachFeatureAtPixel(e.pixel, function (feature, layer) {
        if (feature && layer) {
          features.push(feature);
        }
      });

      if (features.length) {
        selectedFeature = features[0];
      } else {
        if (
          self.props.installMode.newInstallItem != null &&
          self.props.installMode.installContext == 'SOPs'
        ) {
          const latLng = self.convertCoordinateToLatLng(e.coordinate);
          //self.addSopCords = e.coordinate;
          self.props.addSopTemp(latLng);
        }
      }

      // Checking if we are in scale mode
      if (self.state.scaleMode) {
        let scalePoint = false;

        if (!selectedFeature) {
          scalePoint = e.coordinate;
        } else {
          const type = selectedFeature.getProperties().type;
          switch (type) {
            case 'ScalePoint':
            case 'couplePathLine':
            case 'coveragePathLine':
            case 'couplePath':
            case 'couplesCoverage':
            case featuresTypes.DMAS:
            case featuresTypes.PROJECTS_COVERAGE:
              scalePoint = e.coordinate;
              break;
            default:
              scalePoint = selectedFeature.getGeometry().getCoordinates();
              break;
          }
        }

        self.addScalePoint(scalePoint);
        // When we click on '+' button the value of newInstallItem will be not null. (id: -1, etc.)
      } else if (
        self.props.planningState.isActive ||
        self.props.planningState.editMode
      ) {
        let featureProperties =
          selectedFeature == null ? null : selectedFeature.getProperties();
        let latLngPoint;

        if (selectedFeature == null) {
          featureProperties = null;
          // Converting the e.coordinate (x,y) to lang,lat
          latLngPoint = self.convertCoordinateToLatLng(e.coordinate);
        } else {
          featureProperties = selectedFeature.getProperties();
          if (featureProperties.name == 'Line') {
            latLngPoint = self.convertCoordinateToLatLng(e.coordinate);
          } else {
            latLngPoint = self.convertCoordinateToLatLng(
              selectedFeature.getGeometry().getCoordinates()
            );
          }
        }
        // planningState refers to couple generation
        if (
          self.props.planningState.isActive ||
          self.props.planningState.editMode
        ) {
          self.props.handleClickOnPlanningMode(latLngPoint, featureProperties);
        }
      } else if (
        self.props.path === 'alerts' &&
        self.props.isOnAddingNewManualNoiseAlert != null
      ) {
        const details = selectedFeature.getProperties();
        if (details.type === 'sensor') {
          self.props.selectSensorForNoiseAlert(details);
        } else {
          self.props.notify(
            self.context.t('Have to select only Sensor Item'),
            'info'
          );
        }
      }
      //console.log('add point: ' + e.coordinate);
      //self.addCouplePathPoint(selectedFeature, e.coordinate);
      else {
        if (selectedFeature != null) {
          self.selectFeature(selectedFeature);
        } else {
          const { bIsWaitingForMapClick } = self.props;
          if (bIsWaitingForMapClick) {
            const latLngPoint = self.convertCoordinateToLatLng(e.coordinate);
            self.props.onMapClick(e, latLngPoint);
          }
        }
      }
    });

    // Hover handler
    map.on('pointermove', function (evt) {
      if (evt.dragging) {
        return;
      }
      let features = [];
      let feature = null;
      const pixel = evt.pixel;
      self.state.map.forEachFeatureAtPixel(pixel, function (feature, layer) {
        if (feature && layer) {
          const featureAttr = feature.getProperties();
          if (featureAttr.type != null) {
            features.push(feature);
          }
        }
      });
      if (features.length) {
        feature = features[0];
      }
      if (feature) {
        self.setFeatureInfo(feature, pixel);

        //Set the cursure to pointer on over marker
        //self.state.map.getViewport().style.cursor = 'pointer';
      } else if (self.state.featInfoStyle.display !== 'none') {
        //Set the cursure to regular not on over marker
        //self.state.map.getViewport().style.cursor = '';

        self.setState({
          featInfoStyle: {
            display: 'none',
          },
          featInfoTooltip: {
            id: null,
          },
        });
      } else {
        //Set the cursure to regular not on over marker
        //self.state.map.getViewport().style.cursor = '';
      }
    });

    map.on('moveend', (evt) => {
      // event actions
      const mapView = evt.map.getView();
      const mapCenter = mapView.getCenter();
      //const mapProjection = mapView.getProjection();
      //const latLng = proj.transform(mapCenter, mapProjection, 'EPSG:4326');
      const latLng = this.convertCoordinateToLatLng(mapCenter);
      if (self.props.getMapCenterLatLng) {
        self.props.getMapCenterLatLng(latLng);
      }
    });

    map.addInteraction(this.select);


    this.setState({
      map: map,
    });
  }

  bitOrLogInfoRender() {
    const { selectedSensorBit, timeZone } = this.props;
    if (selectedSensorBit == null) {
      return null;
    } else {
      if (selectedSensorBit.IsLog) {
        const { selectSensorLog, downloadFile } = this.props;
        return (
          <SensorLogInfo
            selectedSensorLog={selectedSensorBit.data}
            selectSensorLog={selectSensorLog}
            timeZone={timeZone}
            downloadFile={downloadFile}
          />
        );
      } else {
        return (
          <SensorBitInfo
            selectedSensorBit={selectedSensorBit}
            selectSensorBit={this.props.selectSensorBit}
            timeZone={timeZone}
          />
        );
      }
    }
  }

  handlePolygonModeChange = (isChecked) => {
    this.setState({ polygonSelectMode: isChecked })
    if (isChecked) {
      this.turnOnDrawMode('Polygon')
    } else {
      this.setState({
        polygonSelectedSensors: [],
        polygonPoints: []
      })
      this.turnOffDrawMode();
    }
  }

  render() {
    const { drawStyle, featInfoStyle, featInfoTooltip, layers } = this.state;
    const {
      selectedSample,
      selectSample,
      selectCoupleSample,
      downloadFile,
      uiInfo,
      couplingMode,
      planningState,
      user,
      item,
      viewWaterMeterLayer,
      pipesSource,
      pipesSourceChange,
      coverageSourceChange,
      isOnAddingNewManualAlert,
      distanceFactor,
      selectedSensorLog,
      selectedSensorBit,
      selectSensorBit,
      selectSensorLog,
      g5SensorSelectedMessage,
      installMode,
      units,
      actualLeakPositionState,
    } = this.props;

    let panelStyle = { height: 866 };
    if (uiInfo) {
      panelStyle = { height: uiInfo.pageHeight };
    }

    let message;

    if (this.props.path == 'alerts' && isOnAddingNewManualAlert) {
      message = this.context.t('Click on Map to set coordinate');
    } else if (this.props.path == 'install') {
      if (this.props.installMode.installContext == 'SOPs') {
        message = this.context.t('Choose location for new Installation Point');
      } else if (this.props.installMode.installContext == 'Interferences') {
        message = this.context.t('Select draw mode and draw Area Of Interest');
      }
    }

    if (
      this.props.path === 'alerts' &&
      this.props.isOnAddingNewManualNoiseAlert != null
    ) {
      if (this.props.isOnAddingNewManualNoiseAlert.sensorId != null) {
        message = this.context.t('Selected Sensor: {id}', {
          id: this.props.isOnAddingNewManualNoiseAlert.sensorId,
        });
      } else {
        message = this.context.t('Select Sensor');
      }
    }

    let tooltip = null;
    if (featInfoTooltip != null) {
      const layer = this.getLayerByType(featInfoTooltip.type);
      if (layer != null) {
        tooltip = layer.tooltipRender(
          featInfoTooltip,
          this.context,
          this.props
        );
      }
    }

    const streetViewClasses = classNames('street-map', {
      disabled: item == null,
    });
    const streetViewHref =
      item == null
        ? ''
        : `https://maps.google.com/maps?q=&layer=c&cbll=${item.Latitude},${item.Longitude}&cbp=11,0,0,0,0`;

    return (
      <div className="panel-map" style={panelStyle}>
        {/* StreetMap Icon will available only when feature selected */}

        <a
          className={streetViewClasses}
          disabled={item == null}
          href={streetViewHref}
          target="_blank"
          title={this.context.t('Launch Street View for selected item')}
        />

        {this.state.mount && this.props.showSearchBox && (
          <input
            className="pac-input search-box"
            type="text"
            placeholder={this.context.t('Search Box')}
            onKeyPress={this.handleSearchBoxKeyPress}
            onChange={this.handleSearchBoxChange}
          />
        )}
        <div className="map-wrapper" style={drawStyle}>
          <div id={'map'} className="map" ref="olmap" />
          <div className="feature-info" style={featInfoStyle}>
            {tooltip}
          </div>
        </div>

        {actualLeakPositionState.isOpen && (
          <CActualLeakLocationWindow
            offsetParent={document.getElementById('map')}
          />
        )}

        <CPipeAssessmentReportModal getMapCapture={this.captureImage} />
        {this.props.dmaModalDisplay && <CDmaHistoryModal uiInfo={uiInfo} />}

        {planningState.step > 0 && (
          <div
            style={{
              position: 'absolute',
              top: '50px',
              width: '100%',
            }}
          >
            <h2
              style={{
                textAlign: 'center',
                backgroundColor: 'aquamarine',
                margin: '0 10%',
                padding: '10px',
                border: 'solid 2px orange',
                borderRadius: '20px',
                opacity: '0.8',
              }}
            >
              {CREATE_COUPLE_STEPS[planningState.step]}
            </h2>
          </div>
        )}

        {((this.props.path == 'install' &&
          this.props.installMode.newInstallItem != null) ||
          isOnAddingNewManualAlert ||
          this.props.isOnAddingNewManualNoiseAlert != null) &&
          message != null && (
            <div
              style={{
                position: 'absolute',
                top: '50px',
                width: '100%',
              }}
            >
              <h2
                style={{
                  textAlign: 'center',
                  backgroundColor: 'aquamarine',
                  margin: '0 10%',
                  padding: '10px',
                  border: 'solid 2px orange',
                  borderRadius: '20px',
                  opacity: '0.8',
                }}
              >
                {message}
              </h2>
            </div>
          )}

        {this.props.showLegend && (
          <Scale
            isOpen={this.setIsScaleOpen}
            clear={this.clearScalePoints}
            undo={this.removeLastScalePoint}
            distance={this.state.scaleDistance}
            distanceFactor={distanceFactor}
            units={units}
          />
        )}

        {/* {this.props.path == 'sensors' && this.props.user.isAQS && (
          <div className='polygon-select'>
            <span>SELECT</span>
            <Switch onChange={this.handlePolygonModeChange}/>
            { this.state.polygonSelectedSensors.length > 0 && <button
              onClick={() => {
                this.props.setSelectedPolygonSensors(this.state.polygonSelectedSensors);
              }}
            >go to g5</button>}
            { this.state.polygonSelectedSensors.length > 0 && <button
              onClick={() => {
                this.props.generateSensorsReportFromSelections(this.state.polygonSelectedSensors);
              }}
            >Report</button>}

          </div>
        )} */}

        {this.props.path == 'sensors' && (
          <CropSelect
            user={this.props.user}
            setActive={this.handlePolygonModeChange}
            polygonPoints={this.state.polygonPoints}
            selectedSensors={this.state.polygonSelectedSensors}
            setSelectedPolygonSensors={this.props.setSelectedPolygonSensors}
            getPipesInPolygon={this.props.getPipesInPolygon}
            generateSensorsReportFromSelections={this.props.generateSensorsReportFromSelections}
            clear={() => {
              // restart draw mode for clear and draw new polygon
              this.handlePolygonModeChange(false);
              this.handlePolygonModeChange(true);
            }}
          />
        )}

        {this.props.path == 'sensors' && (
          <div
            style={{
              backgroundColor: this.props.sensorsGraphsDisplay
                ? '#5cb85c6b'
                : '',
            }}
            onClick={this.props.sensorsGraphesToggle}
            className="sensor-graphs-toggle"
          >
            <i></i>
          </div>
        )}

        {this.props.showLayerManager && (
          <CLayersManager
            layers={layers}
            layersHandler={this.layersHandler}
            pipesSource={pipesSource}
            pipesSourceChange={pipesSourceChange}
            coverageSourceChange={coverageSourceChange}
            viewWaterMeterLayer={viewWaterMeterLayer}
          />
        )}

        {this.props.showLegend && <LegendTabsContainer />}

        {!_.isEmpty(selectedSample) && (
          <CGraphs
            type={this.props.path === 'alerts' ? 'avg' : 'instance'}
            graphsUnits={this.props.graphsUnits}
            selectedSample={selectedSample}
            selectSample={selectSample}
            downloadFile={downloadFile}
            user={user}
            timeZone={this.props.timeZone}
          />
        )}
        {((this.props.path === 'sensors' &&
          this.props.selectedFeatureType === 'sensor' &&
          this.props.sensorsGraphsDisplay && // the green toggle
          ((!_.isEmpty(selectedSensorLog) &&
            !_.isEmpty(selectedSensorBit) &&
            !_.isEmpty(this.props.sensorG5SelectedNoiseMsg)) ||
            !_.isEmpty(this.props.selectedSensorSample))) ||
          (this.props.path === 'alerts' &&
            this.props.alertsContext === 'noise' &&
            !_.isEmpty(this.props.selectedSensorSample))) && (
          <SensorInfo
            project={this.props.selectedProject}
            selectedFeature={this.props.selectedFeature}
            sensorDetails={this.props.sensorDetails}
            noiseAlertCount={this.props.noiseAlertCount}
            timeZone={this.props.timeZone}
            reloadNoiseSamples={this.props.reloadNoiseSamples}
            closeNoise={this.props.closeNoise}
            user={user}
            sensorPrs={this.props.sensorPrs}
            sensorTran={this.props.sensorTran}
            sampleAudio={
              this.props.sensorSampleAudioData &&
              this.props.sensorSampleAudioData.sampleUrl
            }
            radioAudio={
              this.props.sensorSampleAudioData &&
              this.props.sensorSampleAudioData.radioUrl
            }
            downloadFile={this.props.downloadFileFromURl}
            handleClosePannel={this.props.closePannel}
          />
        )}
        {!_.isEmpty(this.props.sensorG5SelectedNoiseMsg) && (
          <G5NoiseStrip
            item={this.props.sensorG5SelectedNoiseMsg}
            handleClosePannel={this.props.closePannel}
            downloadFile={this.props.downloadFileFromURl}
            timeZone={this.props.timeZone}
            graphData={this.props.g5NoiseGraphData}
          />
        )}
        {!_.isEmpty(selectedSensorLog) &&
          this.props.selectedFeatureType === 'sensor' && (
            <SensorLogInfo
              selectedSensorLog={selectedSensorLog}
              selectSensorLog={selectSensorLog}
              timeZone={this.props.timeZone}
              downloadFile={downloadFile}
            />
          )}
        {!_.isEmpty(selectedSensorBit) &&
          this.props.selectedFeatureType === 'sensor' &&
          this.bitOrLogInfoRender()}

        {!_.isEmpty(g5SensorSelectedMessage) && (
          <G5MessageStrip
            item={g5SensorSelectedMessage}
            handleClosePannel={this.props.closePannel}
            timeZone={this.props.timeZone}
          />
        )}

        {!_.isEmpty(this.props.selectedPressureTransientAlert) && (
          <TransientAlertInfo
            selectSampleFunction={this.props.selectTransientSampleFunction}
            selectedSample={this.props.selectedPressureTransientAlert}
            uiInfo={uiInfo}
            timeZone={this.props.timeZone}
          />
        )}

        {this.props.path === 'mobile' && this.props.selectedMobileSample && (
          <MobileSampleDetailsStrip
            sample={item}
            selectMobileSample={this.props.selectMobileSample}
            local={this.props.timeZone}
            downloadFile={this.props.getSignedUrlForFile}
            notify={this.props.notify}
            options={this.props.options}
          />
        )}
      </div>
    );
  }
}

Map.contextTypes = {
  t: PropTypes.func.isRequired,
};

Map.propTypes = {
  handleFeatureClick: PropTypes.func,
  path: PropTypes.string.isRequired,
  selectedProject: PropTypes.string.isRequired,
  showLayerManager: PropTypes.bool.isRequired,
  showLegend: PropTypes.bool.isRequired,
  showSearchBox: PropTypes.bool.isRequired,
  uiInfo: PropTypes.object.isRequired,
  updateMapFeatures: PropTypes.func.isRequired,
};
