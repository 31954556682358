import React from 'react';
import PropTypes from 'prop-types';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

require('../SensorInfo/SensorInfo.scss');

export default class SensorLogInfo extends React.Component {

    constructor(props) {
        super(props);
        this.closePanel = this.closePanel.bind(this);
    }

    closePanel() {
        const { selectSensorLog } = this.props;
        selectSensorLog({});
    }

    render() {
      const { selectedSensorLog, timeZone, downloadFile } = this.props;
        return (
          <div className='sample-info'>
            <i className="icon-close" onClick={this.closePanel} />
            <h2 style={{ marginLeft: '20px' }}>{this.context.t('Log Information. Device: {deviceId}', {deviceId: selectedSensorLog.DeviceID})}</h2>
            <div className="sample-graphs">
              <table>
                <tbody>
                  <tr><th>{this.context.t('Battery Voltage Pre')}</th><td>{selectedSensorLog.BatteryVoltagePreRec}</td></tr>
                  <tr><th>{this.context.t('Battery Voltage Post')}</th><td>{selectedSensorLog.BatteryVoltage}</td></tr>
                  <tr><th>{this.context.t('Sim Number')}</th><td>{selectedSensorLog.SimNumber}</td></tr>
                  <tr><th>{this.context.t('BIT Type')}</th><td>{selectedSensorLog.Meaning}</td></tr>
                  <tr><th>{this.context.t('Hardware Version')}</th><td>{selectedSensorLog.HardVersion}</td></tr>
                  <tr><th>{this.context.t('Firmware Version')}</th><td>{selectedSensorLog.FirmwareVersion}</td></tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr><th>{this.context.t('RecordingTime 1')}</th><td>{UIDateFormater(selectedSensorLog.RecordingTime1, DateType.TIME, timeZone)}</td></tr>
                  <tr><th>{this.context.t('RecordingTime 2')}</th><td>{UIDateFormater(selectedSensorLog.RecordingTime2, DateType.TIME, timeZone)}</td></tr>
                  <tr><th>{this.context.t('RecordingTime 3')}</th><td>{UIDateFormater(selectedSensorLog.RecordingTime3, DateType.TIME, timeZone)}</td></tr>
                  <tr><th>{this.context.t('End Record Time')}</th><td>{UIDateFormater(selectedSensorLog.EndRecordTime, DateType.TIME, timeZone)}</td></tr>
                  <tr><th>{this.context.t('Config Record Duration')}</th><td>{selectedSensorLog.ConfigRecordingTime}</td></tr>
                  <tr><th>{this.context.t('FileSize')}</th><td>{selectedSensorLog.FTPFileSize}</td></tr>
                  <tr><th>{this.context.t('Modem Usage')}</th><td>{selectedSensorLog.ModemUsage}</td></tr>
                </tbody>
              </table>

              <table>
                <tbody>
                  <tr><th>{this.context.t('Is Send File Ok')}</th><td>{selectedSensorLog.IsFtpOk ? <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} /> : <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} />}</td></tr>
                  <tr><th>{this.context.t('Is Modem Closed Ok')}</th><td>{selectedSensorLog.IsModemClosedOk ? <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} /> : <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} />}</td></tr>
                  <tr><th>{this.context.t('Is Csq Ok')}</th><td>{selectedSensorLog.IsCsqOk ? <img className="yesNoBtn" src={require('../../../../images/ic_v.png')} /> : <img className="yesNoBtn" src={require('../../../../images/ic_x.png')} />}</td></tr>
                  <tr><th>{this.context.t('Modem Atenna Signal Strength')}</th><td>{selectedSensorLog.ModemAtennaSignalStrength}</td></tr>
                  {/* <tr><th>{this.context.t('FTPStartSendTime')}</th><td>{UIDateFormater(selectedSensorLog.FTPStartSendTime, DateType.TIME_WITH_SECONDS, timeZone)}</td></tr>
                  <tr><th>{this.context.t('FTPEndSendTime')}</th><td>{UIDateFormater(selectedSensorLog.FTPEndSendTime, DateType.TIME_WITH_SECONDS, timeZone)}</td></tr>
                  <tr><th>{this.context.t('FTPSendDurationSec')}</th><td>{selectedSensorLog.FTPSendDurationSec}</td></tr> */}
                </tbody>
              </table>
            </div>
          </div>
        );
    }
}

SensorLogInfo.contextTypes = {
    t: PropTypes.func.isRequired
}
