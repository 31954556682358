import React from 'react';
import { zIndexs, featuresTypes } from './Options';

import { Style, Stroke, Fill, Text } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Polygon from 'ol/geom/Polygon';
const proj = require('ol/proj');

const source = new VectorSource();

export const layer = new VectorLayer({
    name: 'projectsCoverage',
    source: source,
    zIndex: zIndexs.projectsCoverage,
  });

export const setSource = (projectsList, props) => {
  let iconFeatures = [];

  projectsList.forEach((project) => {
    if (project.Bounding != null) {
        var points = [];

        for (var b = 0; b < project.Bounding.length; b++) {
          points.push(proj.transform([project.Bounding[b][0], project.Bounding[b][1]],
            'EPSG:4326',
            'EPSG:3857'
          ));
        }
        const iconFeature = new Feature({
          geometry: new Polygon([points]),
          type: featuresTypes.PROJECTS_COVERAGE,
          id: project.ID,
          name: project.FullName,
        });
        const style = getStyle(project);
        iconFeature.setStyle(style);
        iconFeatures.push(iconFeature);
      }
  });
  source.addFeatures(iconFeatures);
};

export const getLayers = () => {
    return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected) => {
    let alertStyle = new Style({
        fill: new Fill({
          color: 'rgba(255,255,255,0.4)'
        }),
        stroke: new Stroke({
          color: '#3399CC',
          width: 1.25
        }),
        text: new Text({
          font: '14px Helvetica, sans-serif',
          fill: new Fill({
            color: '#000'
          }),
          stroke: new Stroke({
            color: '#fff',
            width: 2
          }),
          text: feature.FullName
        })
      });

    return alertStyle;
};

export const getInfoTooltip = (feature) => {
    const attr = feature.getProperties();
    let infoTooltip = {
        type: attr.type,
        id: attr.id,
        name: attr.name,
    };

    return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
    return (
        <span className="tooltip">
                <div className="tooltip-row">{context.t('Name: ')} {itemInfo.name}</div>
        </span>
    );
};
