import { connect } from 'react-redux';

import ManageDistributorProjectsModal from '../../components/Modals/ManageDistributorProjectsModal';

import * as actionsManage from '../../actions/ManageActions';

const mapStateToProps = (state, ownProps) => {
  const isOpen = state.manage.isManageDistributorProjects;
  const distributorDetails = state.manage.selectedManageItem.item;
  //const distributorProjects = state.manage.selectedManageItem.item.distributorProjects;
  const projects = state.leaksList.projectsList.items;
  const projectsOptions = projects.map(project => {
    return ({ label: project.ShortName, value: project.ID });
  });
  const initialValues = {
    projectsOptions: distributorDetails.distributorProjects
  }

  return Object.assign({}, {
    initialValues,
    isOpen,
    projectsOptions,
    distributorDetails,
  }, ownProps);
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const projectsIds = values.projectsOptions;
      if (projectsIds != null) {
        dispatch(actionsManage.setProjectForDistributor(projectsIds));
      }
    },
    onClose: () => {
      dispatch(actionsManage.setOpenDistributorProjects(false));
    }
  };
}

const CManageDistributorProjects= connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageDistributorProjectsModal)

export default CManageDistributorProjects
