import { connect } from 'react-redux';

import * as actionsAlerts from '../../actions/AlertsActions';
import { setMapDrawMode } from '../../actions/MapActionsHandler';

import ActualLeakLocationWindow from '../../components/Custom/ActualLeakLocationWindow';

const Coordinates = require('coordinate-parser');

const mapStateToProps = (state, ownProps) => {
    const actualLeakPositionState = state.temp.actualLeakPosition;

    return Object.assign({}, {
        actualLeakPositionState
    }, ownProps);
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: (values, dispatch) => {
            const coordinate = new Coordinates(values.Coordinate);
            const data = [
                {
                    field: 'FixLatitude',
                    value: coordinate.getLatitude(),
                    type: 'Float'
                },
                {
                    field: 'FixLongitude',
                    value: coordinate.getLongitude(),
                    type: 'Float'
                }
            ];
            
            dispatch(actionsAlerts.setActualLeakPosition(data));
            dispatch(actionsAlerts.openActualLeakLocation(false));
            dispatch(setMapDrawMode(false));
        },

        close: () => {
            dispatch(actionsAlerts.openActualLeakLocation(false));
            dispatch(setMapDrawMode(false));
        }
    };
};

const cActualLeakLocationWindow = connect(
    mapStateToProps,
    mapDispatchToProps
)(ActualLeakLocationWindow);

export default cActualLeakLocationWindow;
