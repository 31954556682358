import React from 'react';
import PropTypes from 'prop-types';

import { Rnd } from 'react-rnd';

import CPipeAssessmentsAnalysis from '../../containers/CPipeAssessmentsAnalysis/CPipeAssessmentsAnalysis';

const classNames = require('classnames');

const resizeEnable = {
   bottom: false,
   bottomLeft: false,
   bottomRight: false,
   left: false,
   right: false,
   top: false,
   topLeft: false,
   topRight: false,
};

export default class FloatingDataComponent extends React.Component {

   static get propTypes() {
      return {
         onClose: PropTypes.func,
      };
   }

   constructor(props) {
      super(props);

      this.collapse = this.collapse.bind(this);
      this.expand = this.expand.bind(this);

      this.state = {
         x: 150,
         y: -50,
         divClassName: 'expand',
         pivotState: {
            rendererName: 'Table Heatmap',
            rows: ['Material'],
            cols: ['PlacementYear'],
         }
      };
   }

   collapse() {
      this.setState({
         divClassName: 'collapse'
      });
   }

   expand() {
      this.setState({
         divClassName: 'expand'
      });
   }

   render() {
      const { onClose } = this.props;

      const classes = classNames(
         'flot-drag',
         this.state.divClassName
      );

      const contentClasses = classNames(
        'dragContent',
        this.state.divClassName == 'expand' ? 'visible' : 'hidden'
      )

      const layoutVisibility = this.state.divClassName == 'expand' ? 'visible' : 'hidden';

      return (
         <Rnd
            className={classes}
            dragHandleClassName='dragHandler'
            position={{ x: this.state.x, y: this.state.y }}
            onDragStop={(e, d) => {
               this.setState({ x: d.x, y: d.y });
            }}
            enableResizing={resizeEnable}
            bounds="window"
            // onResize={(e, direction, ref, delta, position) => {
            //    this.setState({
            //       width: ref.style.width,
            //       height: ref.style.height,
            //       ...position,
            //    });
            // }}
            >

            <div className='dragHandler'>
              <p>{this.context.t('Pipe Condition Analyzer')}</p>
              <div className='size-buttons'>
                <button onClick={this.expand}>{this.context.t('+')}</button>
                <button onClick={this.collapse}>{this.context.t('-')}</button>
                <button onClick={onClose}>{this.context.t('x')}</button>
              </div>

            </div>

            <div className={contentClasses}>
               <div style={{visibility: layoutVisibility}}>
                  <CPipeAssessmentsAnalysis
                     layoutVisibility={layoutVisibility}
                  />
               </div>
            </div>

         </Rnd>
       );
   }
}

FloatingDataComponent.contextTypes = {
   t: PropTypes.func.isRequired
};
