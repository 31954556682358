import * as types from "../constants/ActionTypes";

const initialState = {
  dmasList: [],
  selectedDmaId: null,
  selectedDmaData: null,
  isFetchDmaData: false,
  isDataVisible: false,
};

export const dmas = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_DMAS:
      return Object.assign({}, state, {
        dmasList: []
      });
    case types.RECEIVE_DMAS:
      return Object.assign({}, state, {
        dmasList: action.dmas
      });
    case types.SET_DMA_DATA_DISPLAY:
      return Object.assign({}, state, {
        isDataVisible: action.isShow
      });
    case types.REQUEST_DMA_DATA:
      return Object.assign({}, state, {
        isFetchDmaData: true
      });
    case types.RECEIVE_DMA_DATA:
      return Object.assign({}, state, {
        isFetchDmaData: false,
        selectedDmaId: action.dmaId,
        selectedDmaData: action.data,
        selectedDmaCustomer: action.customerID
      });

    default:
      return state;
  }
};

export default dmas;