import { reset } from 'redux-form';
import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import * as actionsGlobals from './GlobalsActions';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
import { invalidateProject } from './ProjectsActions';
import * as actionsDetails from './AlertsDetailsActions';
import * as actionsCouples from './CouplesActions';
import * as actionsSamples from './SamplesActions';
import * as actionsFiles from './FilesActions';
import * as actionsHistory from './AlertsDetailsActions';
import * as actionsMapHandler from './MapActionsHandler';
import * as actionsInspect from './InspectActions';

import { selectFeature } from './GlobalsActions';

export const selectAlert = (alert) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    let selectedAlertId;

    if (alert == null) {
      selectedAlertId = '';
    } else {
      selectedAlertId = alert.id;
      const relatedCouple = alert.CoupleID;

      dispatch(actionsDetails.fetchDetailsIfNeeded(selectedProject, selectedAlertId));
      dispatch(actionsHistory.fetchHistoryIfNeeded(selectedProject, selectedAlertId));
      dispatch(actionsHistory.fetchLeakGrowth(selectedProject, selectedAlertId));
      dispatch(actionsFiles.fetchFilesIfNeeded(selectedProject, selectedAlertId));
      dispatch(actionsFiles.fetchAlertImages(selectedProject, selectedAlertId));

      if (alert.AlertState == 6) {
        // fetch PCA for Fixed alerts:
        dispatch(getPcaForAlert(selectedAlertId));
      }

      if (relatedCouple != null && relatedCouple != '') {
        dispatch(actionsCouples.fetchLeakCoupleIfNeeded(selectedProject, selectedAlertId, relatedCouple));
        dispatch(actionsCouples.fetchCouplePathIdNeeded(selectedProject, relatedCouple));
        // dispatch(actionsSamples.fetchSamplesIfNeeded(selectedProject, selectedAlertId, relatedCouple, 0));
        // dispatch(actionsCouples.fetchCoupleSamplesIfNeeded(selectedProject, relatedCouple, selectedAlertId, 0));
        dispatch(actionsCouples.fetchCoupleSamplesIfNeeded(selectedProject, relatedCouple, 0));
      }
    }

    dispatch(actionsGlobals.selectFeature(selectedProject, selectedAlertId, 'alert'));
  };
};

export function sortLeaks(field, dir) {
  return async (dispatch, getState) => {
    const state = getState();
    const defFilters = state.leaksList.filters.defFilters;
    const selectedProject = state.leaksList.selectedProject;

    dispatch(setSortDetails(field, dir));
    dispatch(setDefFilters(Object.assign({}, { sortBy: field, sortByDir: dir }, defFilters)));
    if (field !== 'Burst') {
      dispatch(invalidateProject(selectedProject));
      dispatch(fetchLeaksIfNeeded(selectedProject));
    } else {
      // sort localy:
      const alerts = state.leaksList.leaksByProject[selectedProject].items;
      const sortedAlerts = alerts.sort((a, b) => {
        let nRc = 0;

        const firstItem = (dir == 'asc') ? a : b;
        const secondItem = (dir == 'asc') ? b : a;
        // if (
        //   (firstItem.Burst && secondItem.Burst == false) ||
        //   (
        //     (!firstItem.Burst && firstItem.isSuspicious) && (!secondItem.Burst && !secondItem.isSuspicious)
        //   )
        // ) {
        //   nRc = 1;
        // } else {
        //   nRc = -1;
        // }

        if (firstItem.Burst > secondItem.Burst) {
          nRc = 1;
        } else if (secondItem.Burst > firstItem.Burst) {
          nRc = -1;
        } else {
          nRc = 0;
        }

        return nRc;
      });

      const indexMap = setIndexes(sortedAlerts, 'id');
      const iconsFeatures = getIconFeatures('leaks', sortedAlerts);
      await dispatch(receiveLeaks(selectedProject, [], {}, {}));
      dispatch(receiveLeaks(selectedProject, sortedAlerts, iconsFeatures, indexMap));
    }
  }
}

const setSortDetails = (field, dir) => {
  return {
    type: types.SORT_LEAKS,
    field,
    dir
  }
}

function receiveLeaks(project, leaks, iconFeatures, indexMap) {
    return {
        type: types.RECEIVE_LEAKS,
        project,
        leaks: leaks,
        iconFeatures,
        receivedAt: Date.now(),
        indexMap
    }
}

function receiveLeaksIds(project, leaksIds) {
  return {
    type: types.RECEIVE_LEAKS_IDS,
    project,
    leaksIds,
    receivedAt: Date.now()
  }
}

function requestLeaks(project, filters, sort) {
    return {
        type: types.REQUEST_LEAKS,
        project,
        filters,
        sort
    };
}

function fetchLeaks(project, filters, sort) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "?filters=" + JSON.stringify(filters) + "&sort=" + JSON.stringify(sort);

  return (dispatch, getState) => {
    //@TODO: Check errors.
    dispatch(requestLeaks(project, filters, sort));
    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        const iconsFeatures = getIconFeatures('leaks', json.data);
        const indexMap = setIndexes(json.data, 'id');

        dispatch(actionsInspect.fetchSuspiciousAlerts(project, 'alerts'));

        return [json.data, iconsFeatures, indexMap];
      } else {
        return [[], {}, {}];
      }
    }).then((args) => {
      const state = getState();
      const workingPath = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'alerts';
      if (workingPath == 'alerts') {
        if (!checkForSelectedFeature(...args, getState().leaksList.leaksByProject.selectedFeature)) {
          dispatch(selectFeature(project, '', ''));
        }
      }
      return (args);
    }).then((args) => {
      dispatch(receiveLeaks(project, ...args));
      dispatch(fetchGrowingLeaks(project, filters));
      return (args);
    });
  };
}

function fetchGrowingLeaks(project, filters) {
  return (dispatch, getState) => {
    let path = `${endPoints.ALERTS_ENDPOINT}/growingLeaks/${project}?filters=${JSON.stringify(filters)}`;
    return fetchMiddleware(path, {}, getState)
    .then(json => {
      if (json.status) {
        dispatch(receiveGrowingLeaksData(project, json.data));
      }
    })
  }
}

function receiveGrowingLeaksData(project, data) {
  return {
    type: types.RECEIVE_ALERTS_GROWING_DATA,
    project,
    data
  }
}

function fetchLeaksIds(project) {
  var path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/leaksID";

  return (dispatch, getState) => {
    //@TODO: Check errors.
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        dispatch(receiveLeaksIds(project, json.data));
      });
  }
}

function getLeakIndexById(leaks, id) {
    return leaks.findIndex(function (v) {
        return v.id == id; // Filter out the appropriate one
    }); // Get result and access the foo property.
}

export const createNewAlert = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const project = state.leaksList.selectedProject;
    const insertedCoordinate = state.mapState.drawMode.data;
    if (insertedCoordinate != null) {
      data.Coordinate = insertedCoordinate[0];
    }

    // if created from noise alert - we save the noise alert id
    const noiseAlertId = state.temp.noiseIdForNewAlert;

    const path = endPoints.ALERTS_ENDPOINT + '/' + project;
    return fetchMiddleware(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data, noise: noiseAlertId })
    }, getState).then((json) => {
      if (json.status == false) {

      } else {
        // close the manual alert form:
        dispatch(setAddingNewManualAlertMode(false));
        // exit map from draw mode:
        dispatch(actionsMapHandler.setMapDrawMode(false));
        // reset the form:
        dispatch(reset('add-alert'));
        // reload alerts from server:
        return dispatch(fetchLeaksIfNeeded(project, true));
      }
    });
  }
}

export function updateLeak(data, count = 0) {
  return (dispatch, getState) => {
    //@TODO: Check errors.
    let addressData = null;
    const state = getState();
    const project = state.leaksList.selectedProject;
    const alertId = state.leaksList.leaksByProject.selectedFeature;
    const alertsIndexMap = state.leaksList.leaksByProject[project].indexMap;
    const alertsIndexList = state.leaksList.leaksByProject[project].items;
    const alertObj = alertsIndexList[alertsIndexMap[alertId]];
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "/" + alertId;
    const tempData = state.temp;
    if (tempData.featureId == alertId) {
      addressData = state.temp.address;
    }

    dispatch(updateLeakReq(project, alertId, data));
    return fetchMiddleware(path, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data, addressData })
    }, getState).then(json => {
      if (json.status == true) {
        dispatch(selectAlert(null));
        dispatch(updateLeakRes(project, alertId));
        dispatch(invalidateProject(project));
        dispatch(fetchLeaksIfNeeded(project)).then(() => {
          dispatch(selectAlert(alertObj));
        });
        dispatch(actionsDetails.fetchDetails(project, alertId));
        dispatch(actionsDetails.fetchHistory(project, alertId));
        // data.forEach((val) => {
        //   dispatch(updateLeakData(project, getLeakIndexById(state.leaksList.leaksByProject[project].items, alertId), val.field, val.value))
        // })
      }
      else {
        if (count < 2) {
          dispatch(updateLeak(data, count + 1));
        }
        else {
          dispatch(updateLeakRes(project, alertId));
          dispatch(invalidateProject(project));
          dispatch(fetchLeaksIfNeeded(project));
          dispatch(actionsDetails.fetchDetails(project, alertId));
          dispatch(actionsDetails.fetchHistory(project, alertId));
        }
      }
    });
  };
}

function shouldFetchLeaks(state, project) {
    const leaks = state.leaksList.leaksByProject[project]
    if (!leaks) {
        return true;
    }
    if (leaks.isFetching) {
        return false;
    }
    return leaks.didInvalidate
}

function shouldFetchLeaksIds(state, project) {
  /*const leaks = state.leaksList.leaksByProject[project]
  if (!leaks) {
    return true
  }
  if (leaks.isFetching) {
    return false
  }
  return leaks.didInvalidate*/ return true;
}

export function fetchLeaksIfNeeded(project, force = false) {
  return (dispatch, getState) => {
    const state = getState();
    if (force || shouldFetchLeaks(state, project)) {
      const leaksFilters = state.leaksList.filters;
      return dispatch(fetchLeaks(project, leaksFilters.filters, leaksFilters.sort))
    }
  };
}

export function fetchLeaksIdsIfNeeded(project) {
  return (dispatch, getState) => {
    const state = getState();
    if (shouldFetchLeaksIds(state, project)) {
      return dispatch(fetchLeaksIds(project));
    }
  }
}

export function setDefFilters(filters) {
    return {
        type: types.SET_DEF_FILTERS,
        filters
    };
}

function updateLeakReq(project, leak, data) {
    return {
        type: types.UPDATE_LEAK_REQ,
        project,
        leak,
        data
    };

}

function updateLeakRes(project, leak) {
    return {
        type: types.UPDATE_LEAK_RES,
        project,
        leak
    }
}

export function setFilters(filters) {
    return {
        type: types.SET_FILTERS,
        filters
    };
}

function checkForSelectedFeature(leaks, iconFeatures, indexMap, selectedFeature) {
    return selectedFeature in indexMap;
}

export function addLeakOption(type, value) {
    return (dispatch, getState) => {
        const state = getState();
        let isNewValue = true;
        let options;
        switch (type) {
            case 'WorkArea':
                options = state.leaksList.optionList.options.WorkAreas;
                break;
            case 'ContractorID':
                options = state.leaksList.optionList.options.ContractorID;
                break;
            case 'RepairEssence':
                options = state.leaksList.optionList.options.RepairEssence;
                break;
            default:
                break;
        }

        for (let i = 1; i < options.length; i++) { // start from 1. 0 = the new option
            if (options[i].label == value) {
                isNewValue = false;
                break;
            }
        }

        if (isNewValue) {
            const selectedProjectId = state.leaksList.selectedProject;
            const projectsList = state.leaksList.projectsList;
            const customerIdOfProject = projectsList.items[projectsList.projectIndexMap[selectedProjectId]].CustomerID;
            const path = endPoints.END_POINT + "/type/" + type + "/customer/" + customerIdOfProject + "/value/" + value;
            dispatch(addLeakOptionRequest())
            return fetchMiddleware(path, {
                method: 'PUT'
            }, getState).then(json => {
              if (json.status) {
                dispatch(addLeakOptionReceive(json.data));
              }
            });
        }
    };
}

function addLeakOptionRequest() {
    return {
        type: types.ADD_OPTION_REQUEST,
        isFetching: true,
    };
}

function addLeakOptionReceive(options) {
    return {
        type: types.ADD_OPTION_RECEIVE,
        isFetching: false,
        options
    };
}

export const setAddingNewManualAlertMode = (bIsInMode, noiseId) => {
  return {
    type: types.SET_ADDING_MANUAL_ALERTS_MODE,
    bIsInMode,
    noiseId
  };
};

export const getPcaForAlert = (alertId) => {
  return (dispatch, getState) => {
    const path = endPoints.ALERTS_ENDPOINT + "/pca/" + alertId;
    dispatch(receivePcaDataForAlert(alertId, null));
    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        if (json.data.length > 0) {
          const dataObject = json.data[0];
          let diameterInch = (dataObject.PipeDiameterInch <= 0) ? 1 : dataObject.PipeDiameterInch;
          diameterInch = (Math.round(diameterInch * 4) / 4); // round in steps of 0.25

          dataObject.PipeDiameterInch = diameterInch;

          dispatch(receivePcaDataForAlert(alertId, dataObject));
        }
      }
    });
  };
};

const clearTempPCA = () => {
  return {
    type: types.CLEAR_TEMP_PCA
  };
}

const clearTempStatus = () => {
  return {
    type: types.CLEAR_TEMP_STATUS
  };
}

const receivePcaDataForAlert = (alertId, data) => {
  return {
    type: types.RECEIVE_PCA_DATA_FOR_ALERT,
    alertId,
    data,
  };
};

export const setOpenPCA = (bIsOpen, bIsSetAsFixed) => {
  const action = {
    type: types.SET_PCA_OPEN,
    bIsOpen,
    bIsSetAsFixed,
  };

  if (bIsOpen) {
    return action;
  }
  else {
    return (dispatch, getState) => {
      dispatch(action);
      dispatch(clearTempPCA());
    };
  }
};

export const setOpenPcaLeakGrowth = (alert, bIsOpen) => {
  const action = {
    type: types.SET_PCA_LEAK_GROWTH_OPEN,
    bIsOpen,
    id: (alert == null) ? null : alert.id
  };

  if (!bIsOpen) {
    return action;
  }
  else if (alert != null) {
    return (dispatch, getState) => {
      const state = getState();
      const projectId = state.leaksList.selectedProject;
      dispatch(actionsHistory.fetchLeakGrowth(projectId, alert.id));
      dispatch(action);
    };
  }
};

export const setOpenStatus = (bIsOpen, id, stateName, value, prevState, nextState, rowIndex, field) => {
  const action = {
    type: types.SET_STATUS_OPEN,
    bIsOpen,
    id,
    stateName,
    value,
    prevState,
    nextState,
    rowIndex,
    field
  };
  if (bIsOpen) {
    return action;
  }
  else {
    return (dispatch, getState) => {
      dispatch(action);
      dispatch(clearTempStatus());
    };
  }
};

// Update Alert State (Interference or Alert)
export const setAlertType = (value) => {
  const action = {
    type: types.SET_ALERT_TYPE,
    value
  };
  return (dispatch, getState) => {
    dispatch(updateLeak([{field: 'AlertType', value: action.value}]));
  };
};

export const savePCA = (values, alertId, isSetStatusAsFixed, callback) => {
  return (dispatch, getState) => {
    const path = endPoints.ALERTS_ENDPOINT + "/pca/" + alertId;
    const requestMethod = isSetStatusAsFixed ? 'POST' : 'PUT';
    const options = {
      method: requestMethod,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        data: values
      })
    };

    return fetchMiddleware(path, options, getState).then((json) => {
      callback(json.status);

      if (json.status) {
        const state = getState();
        const alertId = state.leaksList.leaksByProject.selectedFeature;
        const selectedProjectId = state.leaksList.selectedProject;
        const pagePath = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'alerts';
        // const projectBundle = state.leaksList.leaksByProject[selectedProjectId];
        // const selectedAlert = projectBundle.items[projectBundle.indexMap[alertId]];
        // dispatch(selectAlert(selectedAlert));
        dispatch(updateLeakRes(selectedProjectId, alertId));
        dispatch(invalidateProject(selectedProjectId));
        dispatch(fetchLeaksIfNeeded(selectedProjectId, true));
        dispatch(actionsDetails.fetchDetails(selectedProjectId, alertId));
        dispatch(actionsDetails.fetchHistory(selectedProjectId, alertId));

        if (pagePath == 'inspect') {
          dispatch(actionsInspect.investigateAlerts(selectedProjectId, 'json', true));
        }
      }
    });
  };
};

export const openActualLeakLocation = (bIsOpen) => {
  return {
    type: types.SET_ACTUAL_LEAK_POSITION_MODE,
    isOpen: bIsOpen,
  };
};

export const setActualLeakPosition = (data) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const alertId = state.leaksList.leaksByProject.selectedFeature;

    const path = endPoints.ALERTS_ENDPOINT + `/position/${projectId}/${alertId}`;
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({data: data}),
    };
    return fetchMiddleware(path, options, getState).then((json) => {
      if (json.status) {
        const projectBundle = state.leaksList.leaksByProject[projectId];
        const selectedAlert = projectBundle.items[projectBundle.indexMap[alertId]];

        // clear the selection:
        dispatch(selectAlert(null));
        // reload leaks:
        dispatch(fetchLeaksIfNeeded(projectId, true)).then(() => {
          // reselect the alert:
          dispatch(selectAlert(selectedAlert));
        });

        dispatch(actionsDetails.fetchHistory(projectId, alertId));
      } else {
        console.error(json);
      }
    });
  };
};

export const notify = (distributionListId, mapImageBase64, cb) => {
  return async (dispatch, getState) => {
    const state = getState();
    let workOrderValues = {};
    let img;
    const originalHeight = 816;
    const originalWidth = 1137;
    const resizeFactor = 0.7;

    delete workOrderValues.serviceProviderOptions;
debugger;
    if (mapImageBase64) {
      let maxWidth = Math.round(originalWidth * resizeFactor);
      let maxHeight = Math.round(originalHeight * resizeFactor);
      img = await resizeBase64Image(mapImageBase64, maxWidth, maxHeight);
    }

    workOrderValues.mapImageBase64 = img;

    workOrderValues = Object.assign(workOrderValues, state.form.workorder.values);
    const selectedServiceProvider = workOrderValues.serviceProviderOptions.find((sp) => sp.value === workOrderValues.serviceProvider);

    if (selectedServiceProvider != null) {
      workOrderValues.serviceProviderName = selectedServiceProvider.label;
    }

    delete workOrderValues.serviceProviderOptions;

    const alertId = state.leaksList.leaksByProject.selectedFeature;
    const path = `${endPoints.ALERTS_ENDPOINT}/notify/${alertId}/${distributionListId}`;
    const options = {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...workOrderValues }),
    };
    return fetchMiddleware(path, options, getState).then((json) => {
      if (json.status) {
        cb(null);
      } else {
        cb(json.data);
      }
    });
  }
}

function resizeBase64Image(base64, maxW, maxH) {
  return new Promise((resolve) => {
    var canvas = document.createElement("canvas");
    var ctx = canvas.getContext("2d");
    // var input = document.getElementById('input');
    // var output = document.getElementById('file_output');
    // input.addEventListener('change', handleFiles);

    var img = new Image;
    img.onload = function () {
      var iw = img.width;
      var ih = img.height;
      var scale = Math.min((maxW / iw), (maxH / ih));
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
      const value = canvas.toDataURL("image/jpeg", 0.5);

      resolve(value);
    };
    img.src = base64;
  });
}
