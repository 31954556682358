import { MAPS_END_POINT } from '../../constants/EndPoints';
import { browserHistory } from 'react-router';

import { connect } from 'react-redux';
import * as actionsGlobals from '../../actions/GlobalsActions';
import * as actionsMapHandler from '../../actions/MapActionsHandler';
import * as actionsPlanning from '../../actions/PlanningActions';
import * as installActions from '../../actions/InstallActions';
import * as sensorsActions from '../../actions/SensorsActions';
import * as noiseAlertsActions from '../../actions/NoiseAlertsActions';
import * as pressureTransientActions from '../../actions/PrsTransientAlertsActions';
import * as mobileSamplesActions from '../../actions/MobileSamplesActions';
import * as deviceConfigurationActions from '../../actions/DeviceConfigurationAction';
import * as reportsActions from '../../actions/ReportsActions';
import * as pipesActions from '../../actions/PipesActions';
import { featuresTypes } from '../../components/Map/Layers/Options';

import XYZ from 'ol/source/XYZ.js';
import Map from '../../components/Map/Map';

const _ = require('lodash');

const defaultZoom = 17;
let hasProjectsCoverage = false;

const findFeatureById = (features, id) => {
  return features.findIndex(function (v) {
    const exist = v.get('id') == id; // Filter out the appropriate one
    return exist;
  }); // Get result and access the foo property
};

function updateMapFeatures(nextProps, mapComponent) {
  // Map initialization
  initMapIfNeeded(mapComponent);

  if (
    mapComponent.mount &&
    nextProps.selectedProject !== mapComponent.props.selectedProject
  ) {
    mapComponent.needUpdate = true;
    mapComponent.needCenter = true;
    mapComponent.needSensorsUpdate = true;
    mapComponent.needSopsUpdate = true;
    mapComponent.needNoiseSamplesUpdate = true;
    mapComponent.neediQuariusSamplesUpdate = true;
    mapComponent.needMobileSamplesUpdate = true;
    mapComponent.needWaterMeterUpdate = true;
    mapComponent.needCouplesCoverageUpdate = true;
    mapComponent.needUpdateCouplePath = true;
    mapComponent.needUpdatePressureAlerts = true;

    updatePipesLayer(
      mapComponent,
      nextProps.pipesSource,
      nextProps.selectedProject,
      nextProps
    );

    if (mapComponent.state.layers.projectsCoverageLayer) {
      mapComponent.layersHandler(null, 'projectsCoverageLayer');
    }
  } else if (
    !_.isEqual(mapComponent.props.filters.filters, nextProps.filters.filters) ||
    !_.isEqual(mapComponent.props.iconFeatures, nextProps.iconFeatures)
  ) {
    mapComponent.needUpdate = true;
  } else if (
    !_.isEqual(mapComponent.props.pipesSource, nextProps.pipesSource)
  ) {
    updatePipesLayer(
      mapComponent,
      nextProps.pipesSource,
      nextProps.selectedProject,
      nextProps
    );
  } else {
    if (
      !_.isEqual(
        mapComponent.props.sensors.sensors,
        nextProps.sensors.sensors
      ) ||
      (mapComponent.props.sensors.isFetchingActualTransient == true &&
        nextProps.sensors.isFetchingActualTransient == false)
    ) {
      mapComponent.needSensorsUpdate = true;
    }
    if (
      !_.isEqual(
        mapComponent.props.filters.sopsFilters,
        nextProps.filters.sopsFilters
      )
    ) {
      mapComponent.needSopsUpdate = true;
    } else if (!_.isEqual(mapComponent.props.sops.sops, nextProps.sops.sops)) {
      mapComponent.needSopsUpdate = true;
    }
  }

  if (
    mapComponent.props.selectedProject != nextProps.selectedProject ||
    (mapComponent.props.projectObj === undefined &&
      nextProps.projectObj !== undefined)
  ) {
    updatePipesLayer(
      mapComponent,
      nextProps.pipesSource,
      nextProps.selectedProject,
      nextProps
    );
  }

  if (
    !_.isEqual(mapComponent.props.couplePath, nextProps.couplePath) &&
    nextProps.path === 'couples'
  ) {
    mapComponent.needUpdateCouplePath = true;
  }

  if (!_.isEqual(nextProps.alertsPressure, mapComponent.props.alertsPressure)) {
    mapComponent.needUpdatePressureAlerts = true;
  }

  if (
    mapComponent.props.noiseSamples &&
    !_.isEqual(
      mapComponent.props.noiseSamples.isFetching,
      nextProps.noiseSamples.isFetching
    )
  ) {
    mapComponent.needNoiseSamplesUpdate = true;
  }

  if (
    mapComponent.props.mobileSamplesState.items &&
    !_.isEqual(
      mapComponent.props.mobileSamplesState.items,
      nextProps.mobileSamplesState.items
    )
  ) {
    mapComponent.needMobileSamplesUpdate = true;
  }

  if (
    mapComponent.props.coverageSource &&
    mapComponent.props.coverageSource !== nextProps.coverageSource
  ) {
    mapComponent.needCouplesCoverageUpdate = true;
  }

  if (
    mapComponent.props.noiseSamplesColorAttr !== nextProps.noiseSamplesColorAttr
  ) {
    mapComponent.needNoiseSamplesUpdate = true;
  }
  if (
    mapComponent.props.mobileNoiseSamplesColorAttr !==
    nextProps.mobileNoiseSamplesColorAttr
  ) {
    mapComponent.needMobileSamplesUpdate = true;
  }

  // Update map configurations:
  handleMapUpdates(mapComponent, nextProps);

  // Map Update
  updateSelectedFeature(mapComponent, nextProps);

  updateSelectedMobileSample(mapComponent, nextProps);

  // Alerts UPdate
  updateAlerts(mapComponent, nextProps);

  // Interference Update
  updateInterferences(mapComponent, nextProps);

  updateNoiseAlerts(mapComponent, nextProps);

  // Sensors Update
  updateSensors(mapComponent, nextProps);

  // SOP Update
  updateSOPs(mapComponent, nextProps);

  // Noise Sample Update
  updateNoiseLayer(mapComponent, nextProps);

  // iQuarius Sample Update
  updateMobileSamples(mapComponent, nextProps);

  // Water Meter Update
  updateWaterMeterAlerts(mapComponent, nextProps);

  // Couples Coverage Update
  updateCouplesCoverage(mapComponent, nextProps);

  updateInspectData(mapComponent, nextProps);

  updatePressureAlertsData(mapComponent, nextProps);
  // Sensors
  if (
    nextProps.sensors.isFetching == false &&
    mapComponent.props.sensors.isFetching == true
  ) {
    mapComponent.setLayerSource(
      featuresTypes.SENSOR,
      nextProps.sensors.sensorsIcons,
      nextProps
    );
  }
  // SOPS
  if (
    nextProps.sops.isFetching == false &&
    mapComponent.props.sops.isFetching == true
  ) {
    mapComponent.setLayerSource(
      featuresTypes.SOP,
      nextProps.sops.sopsIcons,
      nextProps
    );
  }
  // Noise Samples
  if (
    nextProps.noiseSamples &&
    nextProps.noiseSamples.isFetching === false &&
    mapComponent.props.noiseSamples.isFetching === true
  ) {
    mapComponent.setLayerSource(
      featuresTypes.NOISE,
      nextProps.noiseSamples.noiseSamplesIcons,
      nextProps
    );
  }
  //Project Coverage

  if (
    nextProps.projectsList.items != null &&
    nextProps.projectsList.items.length > 0
  ) {
    if (!hasProjectsCoverage) {
      mapComponent.setLayerSource(
        featuresTypes.PROJECTS_COVERAGE,
        nextProps.projectsList.items,
        nextProps
      );
      hasProjectsCoverage = true;
    }
  }

  if (!_.isEqual(nextProps.dmaItmes, mapComponent.props.dmaItmes)) {
    mapComponent.setLayerSource(
      featuresTypes.DMAS,
      nextProps.dmaItmes,
      nextProps
    );
  }

  // iQuarius Samples
  if (
    nextProps.iQuariusPublishedSamples &&
    nextProps.iQuariusPublishedSamples.isFetching === false &&
    mapComponent.props.iQuariusPublishedSamples.isFetching === true
  ) {
    mapComponent.setLayerSource(
      featuresTypes.IQUARIUS_PUBLISHED_SAMPLES,
      nextProps.iQuariusPublishedSamples.iQuariusSamplesIcons,
      nextProps
    );
  }
  // Water Meter Alerts
  if (
    nextProps.waterMeterLayer &&
    nextProps.waterMeterLayer.isFetching === false &&
    mapComponent.props.waterMeterLayer.isFetching === true
  ) {
    mapComponent.setLayerSource(
      featuresTypes.WATER_METER,
      nextProps.waterMeterLayer.meterIcons,
      nextProps
    );
  }
  // Linked Sensors Paths
  if (
    nextProps.linkedSensorsPaths &&
    nextProps.linkedSensorsPaths.isFetching === false &&
    mapComponent.props.linkedSensorsPaths.isFetching === true
  ) {
    mapComponent.setLayerSource(
      featuresTypes.COUPLE_PATH,
      nextProps.linkedSensorsPaths.paths,
      nextProps
    );
  }
  // Couple Path
  if (
    nextProps.couplePath &&
    (mapComponent.needUpdateCouplePath ||
      (nextProps.couplePath &&
        nextProps.couplePath.isFetching === false &&
        mapComponent.props.couplePath.isFetching === true))
  ) {
    const path = nextProps.couplePath.path;
    if (path && path.length > 0) {
      const centerPoint = path[Math.floor(path.length / 2)];
      if (nextProps.path === 'couples' && centerPoint != null) {
        const point = [centerPoint.Longitude, centerPoint.Latitude];
        const zoom =
          mapComponent.getZoom() < defaultZoom
            ? defaultZoom
            : mapComponent.getZoom();
        mapComponent.setCenter(point, zoom);
      }
      mapComponent.setLayerSource(featuresTypes.COUPLE_PATH, path, nextProps);
      mapComponent.needUpdateCouplePath = false;
    }

    // const path = nextProps.couplePath.path;
    // if (path && path.length > 0) {
    //   const centerPoint = path[0].pathPoints[Math.floor(path[0].pathPoints.length / 2)];
    //   if (nextProps.path == 'couples' && centerPoint != null) {
    //     const zoom = (mapComponent.getZoom() < defaultZoom) ? defaultZoom : mapComponent.getZoom();
    //     mapComponent.setCenter(centerPoint, zoom);
    //   }
    //   mapComponent.setLayerSource(featuresTypes.COUPLE_PATH, path, nextProps);
    //   mapComponent.needUpdateCouplePath = false;
    // }
  }
  // Couples Coverage Paths
  if (
    nextProps.couplesCoveragePaths &&
    nextProps.couplesCoveragePaths.isFetching === false &&
    mapComponent.props.couplesCoveragePaths.isFetching === true
  ) {
    const projectsBundle = nextProps.projectsList;
    const selectedProject =
      projectsBundle.items[
        projectsBundle.projectIndexMap[nextProps.selectedProjectId]
      ];

    mapComponent.setLayerSource(
      featuresTypes.COUPLES_COVERAGE,
      nextProps.couplesCoveragePaths.paths,
      nextProps
    );
    mapComponent.refreshLayer(featuresTypes.COUPLES_COVERAGE);
  }

  // Couples
  let coupleIdOfSensors = '';
  if (nextProps.selectedFeature != null && nextProps.selectedFeature !== '') {
    if (nextProps.path === 'alerts') {
      const alertDetails =
        nextProps.alertDetails == null
          ? null
          : nextProps.alertDetails[nextProps.selectedFeature];
      if (alertDetails != null && !alertDetails.isFetching) {
        coupleIdOfSensors = alertDetails.data.CoupleID;
      }
      //coupleIdOfSensors = (nextProps.relatedItem) ? nextProps.relatedItem.item : '';
    } else if (nextProps.path === 'couples') {
      coupleIdOfSensors = nextProps.selectedFeature;
    }
  }
  if (
    nextProps.leakCouple &&
    nextProps.leakCouple[coupleIdOfSensors]
    // &&
    // nextProps.leakCouple[coupleIdOfSensors].isFetching == false &&
    // mapComponent.props.leakCouple[coupleIdOfSensors].isFetching == true
  ) {
    // Render the new couples features.
    const nextCouplesData = nextProps.leakCouple[coupleIdOfSensors].data[0];
    if (nextCouplesData != null) {
      const nextCouplesFeatures = [
        {
          sensorIndex: 0,
          sensorID: nextCouplesData.Sensor1,
          position: [nextCouplesData.Longitude1, nextCouplesData.Latitude1],
        },
        {
          sensorIndex: 1,
          sensorID: nextCouplesData.Sensor2,
          position: [nextCouplesData.Longitude2, nextCouplesData.Latitude2],
        },
      ];
      mapComponent.setLayerSource(
        featuresTypes.SENSOR_ON_COUPLE,
        nextCouplesFeatures,
        nextProps
      );
    }
  }
  handleAssessments(mapComponent, nextProps);
  handleGenerationCouple(mapComponent, nextProps);

  if (
    nextProps.installMode.installContext == 'Interferences' &&
    nextProps.installMode.newInstallItem != null
  ) {
    handleGenerationInterference(mapComponent, nextProps);
  }

  const { layers } = mapComponent.state;

  if (
    nextProps.path !== mapComponent.props.path ||
    nextProps.installMode.installContext !=
      mapComponent.props.installMode.installContext
  ) {
    if (
      (nextProps.selectedFeature == '' &&
        mapComponent.props.selectedFeature != '') ||
      (['alert', 'couple'].indexOf(nextProps.selectedFeatureType) == -1 &&
        ['alert', 'couple'].indexOf(mapComponent.props.selectedFeatureType) !=
          -1)
    ) {
      mapComponent.clearSelectedFeatures();
      mapComponent.clearLayer(featuresTypes.SENSOR_ON_COUPLE);
      mapComponent.clearLayer(featuresTypes.COUPLE_PATH);
    }

    switch (nextProps.path) {
      default:
      case 'alerts':
        if (!layers[featuresTypes.ALERT]) {
          mapComponent.layersHandler(null, featuresTypes.ALERT);
        }

        removePipeConditionAssessmentsLayers(mapComponent);
        break;
      case 'sensors':
      case 'couples':
        if (!layers[featuresTypes.SENSOR]) {
          mapComponent.layersHandler(null, featuresTypes.SENSOR);
        }
        // dont hide alerts on change path.
        //if (layers.leaksLayer) {
        //    mapComponent.layersHandler(null, 'leaksLayer');
        //}

        // remove inspectAlerts icons when not in 'inspect' mode:
        removePipeConditionAssessmentsLayers(mapComponent);
        break;
      case 'install':
        if (!layers[featuresTypes.SOP]) {
          mapComponent.layersHandler(null, featuresTypes.SOP);
        }
        if (!layers[featuresTypes.SENSOR]) {
          mapComponent.layersHandler(null, featuresTypes.SENSOR);
        }
        if (!layers[featuresTypes.INTERFERENCE]) {
          mapComponent.layersHandler(null, featuresTypes.INTERFERENCE);
        }

        removePipeConditionAssessmentsLayers(mapComponent);
        break;

      case 'manage':
        // if(!layers[featuresTypes.PROJECTS_COVERAGE])
        //   mapComponent.layersHandler(null, featuresTypes.PROJECTS_COVERAGE);

        removePipeConditionAssessmentsLayers(mapComponent);
        break;

      case 'inspect':
        addPipeConditionAssessmentsLayers(mapComponent);
        break;
    }
  }
}

const addPipeConditionAssessmentsLayers = (mapComponent) => {
  const { layers } = mapComponent.state;

  if (!layers[featuresTypes.INSPECT_ALERTS]) {
    mapComponent.layersHandler(null, featuresTypes.INSPECT_ALERTS);
  }
  if (!layers[featuresTypes.PIPES_CONDITION_ASSESSMENTS]) {
    mapComponent.layersHandler(null, featuresTypes.PIPES_CONDITION_ASSESSMENTS);
  }
  if (layers[featuresTypes.ALERT]) {
    mapComponent.layersHandler(null, featuresTypes.ALERT);
  }

  updatePipesConditionAssessmentsLayer(mapComponent);
};

const removePipeConditionAssessmentsLayers = (mapComponent) => {
  const { layers } = mapComponent.state;

  if (layers[featuresTypes.INSPECT_ALERTS]) {
    mapComponent.layersHandler(null, featuresTypes.INSPECT_ALERTS);
  }
  if (layers[featuresTypes.PIPES_CONDITION_ASSESSMENTS]) {
    mapComponent.layersHandler(null, featuresTypes.PIPES_CONDITION_ASSESSMENTS);
  }
};

const handleMapUpdates = (mapComponent, nextProps) => {
  const { drawMode } = nextProps.mapState;
  if (!_.isEqual(drawMode, mapComponent.props.mapState.drawMode)) {
    let command;
    if (!drawMode.isActive) {
      command = 'off';
    } else {
      if (mapComponent.props.mapState.drawMode.isActive) {
        command = 'change';
      } else {
        command = 'on';
      }
    }

    mapComponent.setDrawMode(command, drawMode.type, drawMode.data);
  }
};

const updateSelectedFeature = (mapComponent, nextProps) => {
  // if (!mapComponent.needUpdate) {
    if (
      nextProps.selectedFeature != mapComponent.props.selectedFeature ||
      nextProps.selectedFeatureType !== mapComponent.props.selectedFeatureType
    ) {
      //var features = mapComponent.getSelectedFeature();
      let layerFeatures;
      switch (nextProps.path) {
        default:
        case 'alerts':
          switch (nextProps.alertsContext) {
            case 'alerts':
              layerFeatures = mapComponent
                .getAlertsLayer()
                .getSource()
                .getFeatures();
              break;

            case 'prsAlerts':
              layerFeatures = mapComponent
                .getPressureAlertsLayer()
                .getSource()
                .getFeatures();
              break;

            case 'noise':
              layerFeatures = mapComponent
                .getNoiseAlertsLayer()
                .getSource()
                .getFeatures();
              break;

            default:
              break;
          }
          break;
        case 'sensors':
          // @TODO: CHECK IF ITS CORRECT TO USE "SENSORS.sensorsLayer.getSource().getFeatures();"
          layerFeatures = mapComponent
            .getSensorsLayer()
            .getSource()
            .getFeatures();
          break;
        case 'couples':
          layerFeatures = null;
          break;
        case 'inspect':
          layerFeatures = mapComponent
            .getLayerByType(featuresTypes.INSPECT_ALERTS)
            .layer.getSource()
            .getFeatures();
          break;
        case 'install':
          switch (nextProps.installMode.installContext) {
            case 'SOPs':
              layerFeatures = mapComponent
                .getSOPsLayer()
                .getSource()
                .getFeatures();
              break;
            case 'Sensors':
              layerFeatures = mapComponent
                .getSensorsLayer()
                .getSource()
                .getFeatures();
              break;
            case 'Interferences':
              layerFeatures = mapComponent
                .getInterferencesLayer()
                .getSource()
                .getFeatures();
              break;
            default:
              break;
          }
          break;
      }
      if (
        nextProps.selectedFeature === '' &&
        mapComponent.props.selectedFeature !== ''
      ) {
        //Clear selected feature.
        mapComponent.clearSelectedFeatures();
      } else if (
        // (nextProps.selectedFeature !== '') &&
        (nextProps.selectedFeature !== mapComponent.props.selectedFeature ||
          nextProps.selectedFeatureType !==
            mapComponent.props.selectedFeatureType)
      ) {
        // Clear selected feature + select the new feature
        //mapComponent.clearSelectedFeaturesByType(nextProps.selectedFeatureType);
        mapComponent.clearSelectedFeatures();

        // now you have an ol.Collection of features that you can add features to
        if (layerFeatures != null) {
          let featureOriginal =
            layerFeatures[
              findFeatureById(layerFeatures, nextProps.selectedFeature)
            ];
          if (featureOriginal) {
            const feature = featureOriginal.clone();
            const style = mapComponent.getFeatureStyleByPath(
              nextProps.path,
              feature,
              true,
              nextProps
            );
            feature.setStyle(style);
            //features.push(feature);
            mapComponent.addSelectedFeature(feature);

            const zoom =
              mapComponent.getZoom() < defaultZoom
                ? defaultZoom
                : mapComponent.getZoom();
            let centerCoordinate;
            const featureGeometry = feature.getProperties().geometry;

            if (featureGeometry.getType() == 'Polygon') {
              centerCoordinate = featureGeometry.getFirstCoordinate();
            } else {
              centerCoordinate = featureGeometry.getFirstCoordinate();
            }

            mapComponent.setCenterCoordinat(centerCoordinate, zoom);
          }
        }
      }
    }
  // }
};

const updateSelectedMobileSample = (mapComponent, nextProps) => {
  if (!mapComponent.needUpdate) {
    if (
      nextProps.mobileSamplesState.selectedSampleId != mapComponent.props.mobileSamplesState.selectedSampleId
    ) {
      mapComponent.clearSelectedFeatures();
      if (nextProps.mobileSamplesState.selectedSampleId) {
        const { items, indexMap, selectedSampleId } = nextProps.mobileSamplesState;
        const sample = items[indexMap[selectedSampleId]];

        const mobileSampleLayer = mapComponent
          .getMobileSamplesLayer()
          .getSource()
          .getFeatures();

        let featureOriginal = mobileSampleLayer[findFeatureById(mobileSampleLayer, selectedSampleId)];

        if (featureOriginal) {
          // console.log('featureOriginal', featureOriginal);
          const feature = featureOriginal.clone();

          const style = mapComponent.getFeatureStyleByPath(
            nextProps.path,
            feature,
            true,
            nextProps
          );
          feature.setStyle(style);
          //features.push(feature);
          mapComponent.addSelectedFeature(feature);

          const point = [sample.longitude, sample.latitude];
          const zoom =
          mapComponent.getZoom() < defaultZoom
            ? defaultZoom
            : mapComponent.getZoom();
          mapComponent.setCenter(point, zoom);
        }
      }
    }
  }
}

const initMapIfNeeded = (mapComponent) => {
  // if (mapComponent.mount && !mapComponent.init) {
  //   mapComponent.initMap();
  //   mapComponent.needUpdate = true;
  //   mapComponent.init = true;
  // }
};

const updateAlerts = (mapComponent, nextProps) => {
  if (mapComponent.needUpdate) {
    mapComponent.clearLayer(featuresTypes.SENSOR_ON_COUPLE);

    mapComponent.clearLayer(featuresTypes.ALERT);
    // if (mapComponent.leaksSource.getFeatures().length) {
    //   mapComponent.leaksSource.clear();
    // }
    if (mapComponent.needCenter) {
      mapComponent.needCenter = false;
      //mapComponent.setCenter([nextProps.center.lng || 0, nextProps.center.lat || 0]);
      const point = [nextProps.center.lng || 0, nextProps.center.lat || 0];
      const zoom =
        mapComponent.getZoom() < defaultZoom
          ? defaultZoom
          : mapComponent.getZoom();
      mapComponent.setCenter(point, zoom);
    }
    if (
      Object.keys(nextProps.iconFeatures).length ||
      nextProps.isFetching == false
    ) {
      // mapComponent.clearSelectedFeaturesByType(featuresTypes.ALERT);
      mapComponent.setLayerSource(
        featuresTypes.ALERT,
        nextProps.iconFeatures,
        nextProps
      );
    }
    mapComponent.needUpdate = false;
  }
};

const updateInterferences = (mapComponent, nextProps) => {
  // if (mapComponent.needInterferencesUpdate) {
  //   if (mapComponent.getInterferencesLayer().getSource().getFeatures().length) {
  //     mapComponent.getInterferencesLayer().getSource().clear();
  //   }
  //   //const selectedFeatures = mapComponent.getSelectedFeature();
  //   mapComponent.clearSelectedFeaturesByType(featuresTypes.INTERFERENCE);
  //   //selectedFeatures.clear();
  //   // mapComponent.couplesSource.clear();

  //   if (Object.keys(nextProps.interferences.interferencesIcons).length || nextProps.interferences.isFetching == false) {
  //     mapComponent.setInterferencesSource(nextProps.inteferences.interferencesIcons);
  //     mapComponent.needInterferencesUpdate = false;
  //   }
  // }
  if (nextProps.interferences != null) {
    if (
      !_.isEqual(
        nextProps.interferences.interferencesIcons,
        mapComponent.props.interferences.interferencesIcons
      )
    ) {
      mapComponent.setLayerSource(
        featuresTypes.INTERFERENCE,
        nextProps.interferences.interferencesIcons,
        nextProps
      );
    }
  }
};

function updateNoiseAlerts(mapComponent, nextProps) {
  if (!_.isEqual(mapComponent.props.noiseAlerts, nextProps.noiseAlerts)) {
    if (mapComponent.hasLayerFeatures(featuresTypes.NOISE_ALERT)) {
      mapComponent.clearLayer(featuresTypes.NOISE_ALERT);
    }
    if (nextProps.noiseAlerts.length > 0) {
      mapComponent.setLayerSource(
        featuresTypes.NOISE_ALERT,
        nextProps.noiseAlerts,
        nextProps
      );
    }
  }
}

const updateSensors = (mapComponent, nextProps) => {
  if (mapComponent.needSensorsUpdate) {
    if (mapComponent.hasLayerFeatures(featuresTypes.SENSOR)) {
      mapComponent.clearLayer(featuresTypes.SENSOR);
    }
    //const selectedFeatures = mapComponent.getSelectedFeature();
    // mapComponent.clearSelectedFeaturesByType('sensor');
    //selectedFeatures.clear();
    mapComponent.clearLayer(featuresTypes.SENSOR_ON_COUPLE);

    if (
      nextProps.sensors.sensorsIcons &&
      (Object.keys(nextProps.sensors.sensorsIcons).length ||
        nextProps.sensors.isFetching === false)
    ) {
      mapComponent.setLayerSource(
        featuresTypes.SENSOR,
        nextProps.sensors.sensorsIcons,
        nextProps
      );
      mapComponent.needSensorsUpdate = false;
    }
  }
};

const updateSOPs = (mapComponent, nextProps) => {
  if (mapComponent.needSopsUpdate) {
    if (mapComponent.hasLayerFeatures(featuresTypes.SOP)) {
      mapComponent.clearLayer(featuresTypes.SOP);
    }

    if (
      nextProps.sops.sopsIcons &&
      (Object.keys(nextProps.sops.sopsIcons).length ||
        nextProps.sops.isFetching === false)
    ) {
      mapComponent.setLayerSource(
        featuresTypes.SOP,
        nextProps.sops.sopsIcons,
        nextProps
      );
      mapComponent.needSopsUpdate = false;
    }
  }

  // handle create new SOP:
  const installState = mapComponent.props.installMode;
  const nextInstallState = nextProps.installMode;

  const prevInstallPosition =
    installState.newInstallItem == null
      ? null
      : installState.newInstallItem.position;
  const nextInstallPosition =
    nextInstallState.newInstallItem == null
      ? null
      : nextInstallState.newInstallItem.position;
  if (prevInstallPosition !== nextInstallPosition) {
    mapComponent.updateTempFeature(nextInstallPosition);
  }
};

const updateNoiseLayer = (mapComponent, nextProps) => {
  if (mapComponent.needNoiseSamplesUpdate) {
    if (mapComponent.hasLayerFeatures(featuresTypes.NOISE)) {
      mapComponent.clearLayer(featuresTypes.NOISE);
    }

    if (
      nextProps.noiseSamples &&
      (Object.keys(nextProps.noiseSamples.noiseSamplesIcons).length ||
        nextProps.noiseSamples.isFetching === false)
    ) {
      mapComponent.setLayerSource(
        featuresTypes.NOISE,
        nextProps.noiseSamples.noiseSamplesIcons,
        nextProps
      );
      mapComponent.needNoiseSamplesUpdate = false;
    }
  }
};

// const updateMobileSamples = (mapComponent, nextProps) => {
//   if (mapComponent.neediQuariusSamplesUpdate) {
//     if (mapComponent.hasLayerFeatures(featuresTypes.IQUARIUS_PUBLISHED_SAMPLES)) {
//       mapComponent.clearLayer(featuresTypes.IQUARIUS_PUBLISHED_SAMPLES);
//     }

//     if (Object.keys(nextProps.iQuariusPublishedSamples.iQuariusSamplesIcons).length || nextProps.iQuariusPublishedSamples.isFetching == false) {
//       mapComponent.setLayerSource(featuresTypes.IQUARIUS_PUBLISHED_SAMPLES, nextProps.iQuariusPublishedSamples.iQuariusSamplesIcons, nextProps);
//       mapComponent.neediQuariusSamplesUpdate = false;
//     }
//   }
// }

const updateMobileSamples = (mapComponent, nextProps) => {
  if (mapComponent.needMobileSamplesUpdate) {
    if (mapComponent.hasLayerFeatures(featuresTypes.MOBILE_SAMPLES)) {
      mapComponent.clearLayer(featuresTypes.MOBILE_SAMPLES);
    }

    if (
      nextProps.mobileSamplesState.items &&
      nextProps.mobileSamplesState.items.length > 0
    ) {
      mapComponent.setLayerSource(
        featuresTypes.MOBILE_SAMPLES,
        nextProps.mobileSamplesState.items,
        nextProps,
        true
      );

      mapComponent.needMobileSamplesUpdate = false;
    }
  }
};

const updateWaterMeterAlerts = (mapComponent, nextProps) => {
  if (mapComponent.needWaterMeterUpdate) {
    if (mapComponent.hasLayerFeatures(featuresTypes.WATER_METER)) {
      mapComponent.clearLayer(featuresTypes.WATER_METER);
    }

    if (
      nextProps.waterMeterLayer &&
      nextProps.waterMeterLayer.meterIcons &&
      (Object.keys(nextProps.waterMeterLayer.meterIcons).length ||
        nextProps.waterMeterLayer.isFetching === false)
    ) {
      mapComponent.setLayerSource(
        featuresTypes.WATER_METER,
        nextProps.waterMeterLayer.meterIcons,
        nextProps
      );
      mapComponent.needWaterMeterUpdate = false;
    }
  }
};

const updateCouplesCoverage = (mapComponent, nextProps) => {
  if (mapComponent.needCouplesCoverageUpdate) {
    if (mapComponent.hasLayerFeatures(featuresTypes.COUPLES_COVERAGE)) {
      mapComponent.clearLayer(featuresTypes.COUPLES_COVERAGE);
    }

    if (nextProps?.couplesCoveragePaths?.isFetching === false) {
      mapComponent.refreshLayer(featuresTypes.COUPLES_COVERAGE);
      mapComponent.setLayerSource(
        featuresTypes.COUPLES_COVERAGE,
        nextProps.couplesCoveragePaths.paths,
        nextProps
      );
      mapComponent.needCouplesCoverageUpdate = false;
    }
  }
};

const handleAssessments = (mapComponent, nextProps) => {
  const isFetch =
    nextProps.assessments != null && nextProps.assessments.isFetching;
  const isDisplay =
    nextProps.displayAssessments != null && nextProps.displayAssessments;
  const stateLayers = mapComponent.state.layers;

  const assessmentsLayerObject = mapComponent.getLayerByType(
    featuresTypes.ASSESSMENT
  );
  const assessmentsLayerOfMap = assessmentsLayerObject.getLayers()[0];

  let isStateChange = false;
  if (isFetch == false) {
    let icons;
    if (nextProps.assessments != null) {
      icons = nextProps.assessments.icons;
    }
    mapComponent.clearLayer(featuresTypes.ASSESSMENT);
    if (icons != null) {
      mapComponent.setLayerSource(featuresTypes.ASSESSMENT, icons, nextProps);
    }
  }
  if (isDisplay) {
    if (!stateLayers['assessmentsLayer']) {
      stateLayers['assessmentsLayer'] = true;
      mapComponent.state.map.getLayers().push(assessmentsLayerOfMap);
      isStateChange = true;
    }
  } else {
    if (stateLayers['assessmentsLayer']) {
      stateLayers['assessmentsLayer'] = false;
      mapComponent.state.map.getLayers().remove(assessmentsLayerOfMap);
      isStateChange = true;
    }
  }

  // handle select \ unselect feature:
  if (nextProps.selectedAssessment != mapComponent.props.selectedAssessment) {
    let features = mapComponent.getSelectedFeature();
    const assessmentsLayer = mapComponent.getLayerByType(
      featuresTypes.ASSESSMENT
    );
    let layerFeatures = assessmentsLayer
      .getLayers()[0]
      .getSource()
      .getFeatures();
    if (
      nextProps.selectedAssessment === '' &&
      mapComponent.props.selectedAssessment !== ''
    ) {
      //Clear selected feature.
      mapComponent.clearSelectedFeaturesByType('assessment');
    } else if (
      nextProps.selectedFeature !== '' &&
      mapComponent.props.selectedFeature !== ''
    ) {
      // Clear selected feature + select the new feature
      mapComponent.clearSelectedFeaturesByType('assessment');
      let feature =
        layerFeatures[
          findFeatureById(layerFeatures, nextProps.selectedAssessment)
        ];
      if (feature) {
        const assessmentsLayer = mapComponent.getLayerByType(
          featuresTypes.ASSESSMENT
        );
        const style = assessmentsLayer.getStyle(feature, true);
        feature.setStyle(style);
        features.push(feature);
        const zoom =
          mapComponent.getZoom() < defaultZoom
            ? defaultZoom
            : mapComponent.getZoom();
        mapComponent.setCenterCoordinat(
          feature.getGeometry().getCoordinates(),
          zoom
        );
      }
    }
  }

  if (isStateChange) {
    mapComponent.setState({
      layers: stateLayers,
    });
  }
};

// @TODO: Imlement handleGenerationInterference
const handleGenerationInterference = (mapComponent, nextProps) => {
  // if (!mapComponent.drawMode){
  //   mapComponent.drawMode = true;
  //   mapComponent.setDrawMode(true);
  // }
  // const prevInterference = mapComponent.props.installMode.newInstallItem;
  // const nextInterference = nextProps.installMode.newInstallItem;
  // if (nextInterference.item.polygon != null) {
  //   if (!_.isEqual(prevInterference.item.polygon, nextInterference.item.polygon)) {
  //     const polygonPoints = nextInterference.item.polygon;
  //     mapComponent.drawPolygon(polygonPoints);
  //   }
  // }
};

const handleGenerationCouple = (mapComponent, nextProps) => {
  const planningPrev = mapComponent.props.planningState;
  const planningNext = nextProps.planningState;

  //const isInEditMode = planningNext.edittingMode == 'manual';
  const newCouple = planningNext.newCouple;

  //if (planningPrev.isEdittingPath != isInEditMode) {
  //  mapComponent.showGeneratedCouplePoints(isInEditMode);
  //}

  if (!_.isEqual(planningNext.couplePath, planningPrev.couplePath)) {
    const path = planningNext.couplePath;
    const length = mapComponent.drawNewCouplePath(path);
    if (length > 0) {
      nextProps.setCoupleLength(length);
    }
  }

  //if (!_.isEmpty(newCouple)) {
  //  const prevNewCouple = planningPrev.newCouple;
  //  const sensors = [{ key: 'Sensor1', id: 0 }, { key: 'Sensor2', id: 1 }];
  //  sensors.forEach(item => {
  //    if (newCouple[item.key] != prevNewCouple[item.key]) {
  //      let sensor = null;
  //      if (newCouple[item.key] != null) {
  //        const sensorsData = nextProps.sensors;
  //        sensor = sensorsData.sensors[sensorsData.indexMap[newCouple[item.key]]];
  //      }

  //      //const nextCouplesFeatures = [
  //      //  {
  //      //    sensorIndex: item.id,
  //      //    sensorID: newCouple[item.key],
  //      //    position: [sensor.Longitude, sensor.Latitude],
  //      //  }
  //      //]
  //      //mapComponent.setCouplesSource(nextCouplesFeatures);

  //      mapComponent.setNewCoupleSensor(item.key, sensor);
  //    }
  //  });
  //}
};

const updatePipesLayer = (
  mapComponent,
  pipesSource,
  selectedProject,
  nextProps
) => {
  let nPipesSourceFactor;
  switch (pipesSource) {
    default:
    case 'materials':
      nPipesSourceFactor = 0;
      break;
    case 'presure':
      nPipesSourceFactor = 1;
      break;
    case 'diameter':
      nPipesSourceFactor = 2;
      break;
    case 'year':
      nPipesSourceFactor = 3;
      break;
    case 'pca':
      nPipesSourceFactor = 4;
      break;
  }
  const projectNdxId = nextProps.projectObj
    ? nextProps.projectObj.ndx * 5
    : '00';

  const source = new XYZ({
    url:
      MAPS_END_POINT + (projectNdxId + nPipesSourceFactor) + '/{z}/{x}/{y}.png',
  });
  mapComponent.setLayerSource(featuresTypes.PIPES, source);
  if (mapComponent.state.layers[featuresTypes.PIPES]) {
    mapComponent.refreshLayer(featuresTypes.PIPES);
    // mapComponent.map.getLayers().remove(mapComponent[featuresTypes.PIPES]);
    // mapComponent.map.getLayers().push(mapComponent[featuresTypes.PIPES]);
  }
};

const updatePipesConditionAssessmentsLayer = (mapComponent) => {
  //const selectedProject = mapComponent.props.selectedProject;
  const projectNdxId = mapComponent.props.projectObj
    ? mapComponent.props.projectObj.ndx * 5 + 4
    : '00';
  const source = new XYZ({
    url: MAPS_END_POINT + projectNdxId + '/{z}/{x}/{y}.png',
  });
  mapComponent.setLayerSource(
    featuresTypes.PIPES_CONDITION_ASSESSMENTS,
    source
  );
  if (mapComponent.state.layers[featuresTypes.PIPES]) {
    mapComponent.refreshLayer(featuresTypes.PIPES);
    // mapComponent.map.getLayers().remove(mapComponent[featuresTypes.PIPES]);
    // mapComponent.map.getLayers().push(mapComponent[featuresTypes.PIPES]);
  }
};

const updateInspectData = (mapComponent, nextProps) => {
  if (
    nextProps.inspectSelectedAlerts == null ||
    nextProps.inspectSelectedAlerts.length == 0
  ) {
    if (mapComponent.hasLayerFeatures(featuresTypes.INSPECT_ALERTS)) {
      mapComponent.clearLayer(featuresTypes.INSPECT_ALERTS);
    }
  } else if (
    _.isEqual(
      mapComponent.props.inspectSelectedAlerts,
      nextProps.inspectSelectedAlerts
    ) == false
  ) {
    mapComponent.clearLayer(featuresTypes.INSPECT_ALERTS);
    mapComponent.setLayerSource(
      featuresTypes.INSPECT_ALERTS,
      nextProps.inspectSelectedAlerts,
      nextProps
    );
    if (nextProps.inspectAlertsCenter != null) {
      const center = [
        nextProps.inspectAlertsCenter.longitude,
        nextProps.inspectAlertsCenter.latitude,
      ];
      mapComponent.setCenter(center);
    }

    updatePipesConditionAssessmentsLayer(mapComponent);
  }

  // if (mapComponent.props.path != nextProps.path) {
  //   mapComponent.changeLayers(featuresTypes.INSPECT_ALERTS, nextProps.path == 'inspect');
  // }
};

const updatePressureAlertsData = (mapComponent, nextProps) => {
  // if (nextProps.path !== 'alerts') {
  //   // clear and remove pressure alerts layer when not in 'Alert' mode:
  //   if (mapComponent.state.layers[featuresTypes.PRESSURE_ALERT]) {
  //     mapComponent.layersHandler(null, featuresTypes.PRESSURE_ALERT);
  //   }
  // } else {
  //   // remove layer of pressure alerts:
  //   if (nextProps.alertsContext !== 'prsAlerts') {
  //     if (mapComponent.state.layers[featuresTypes.PRESSURE_ALERT]) {
  //       mapComponent.layersHandler(null, featuresTypes.PRESSURE_ALERT);
  //     }
  //   } else {
  //     if (!mapComponent.state.layers[featuresTypes.PRESSURE_ALERT]) {
  //       mapComponent.layersHandler(null, featuresTypes.PRESSURE_ALERT);
  //     }
  //   }
  // }
  if (mapComponent.needUpdatePressureAlerts) {
    mapComponent.clearLayer(featuresTypes.PRESSURE_ALERT);
    if (!_.isEmpty(nextProps.alertsPressure)) {
      mapComponent.setLayerSource(
        featuresTypes.PRESSURE_ALERT,
        nextProps.alertsPressure
      );
    }
  }
};

const mapStateToProps = (state, ownProps) => {
  const selectedProject = state.leaksList.selectedProject;

  const {
    selectedFeatureType,
    selectedSensorSample,
    displayAssessmentsOnlyRealLeaks,
  } = state.leaksList.leaksByProject;

  const projectsList = state.leaksList.projectsList;
  const projectObj =
    projectsList.items[projectsList.projectIndexMap[selectedProject]];

  const alertsContext = state.leaksList.pressure.context;
  let selectedFeature = state.leaksList.leaksByProject.selectedFeature;
  if (ownProps.path === 'alerts' && alertsContext === 'noise') {
    selectedFeature = state.noiseAlerts.selected.ID;
  }

  const selectedSample = state.samples.selectedSample;

  const projectState = state.leaksList.leaksByProject[selectedProject] || {};
  const interferences = projectState.interferences || {};

  const planningState = state.planning;
  const installMode = state.install;
  const mapState = state.mapState;
  const bIsWaitingForMapClick = state.temp.newManualAlert != null;
  const isOnAddingNewManualAlert = state.temp.newManualAlert;
  const isOnAddingNewManualNoiseAlert = state.temp.newManualNoiseAlert;
  const options = state.leaksList.optionList.options;

  const actualLeakPositionState = state.temp.actualLeakPosition;
  const dmaItmes = state.leaksList.dmas.dmasList;
  const dmaModalDisplay = state.leaksList.dmas.isDataVisible;

  const inspectSelectedAlerts = state.leaksList.inspect.selectedAlerts;
  const inspectAlertsCenter = state.leaksList.inspect.center;

  const noiseSamplesColorAttr = state.local.noiseSamplesColorAttr;
  const mobileNoiseSamplesColorAttr = state.local.mobileNoiseSamplesColorAttr;

  const distanceUnits = state.local.units;
  const distanceFactor = state.local.factor[distanceUnits];

  const alertsPressure = state.leaksList.pressure.transientAlerts.alerts;
  const noiseAlerts = state.noiseAlerts.items;
  const selectedNoisAlert = state.noiseAlerts.selected;

  const alertStateOptions = state.leaksList.optionList.options.AlertState;
  const mobileAlgParams = state.mobile.samples.algParams;

  let sensorG5SelectedMessage = [];
  let sensorG5SelectedNoiseMsg = [];
  let sensorSampleAudioData = [];

  if (projectState.sensors) {
    sensorG5SelectedMessage = projectState.sensors.g5SelectedBitMsg;
    sensorG5SelectedNoiseMsg = projectState.sensors.g5SelectedNoiseMsg;
    sensorSampleAudioData = projectState.sensors.details.audio;
  }
  const selectedPressureTransientAlert =
    state.leaksList.pressure.transientAlerts.selectedAlertSample;

  let item = null;

  // Extracting the selected feature details (item)
  if (selectedProject) {
    const leaks = state.leaksList.leaksByProject[selectedProject];
    if (selectedFeature && leaks) {
      switch (selectedFeatureType) {
        case 'alert': {
          const projectAlerts =
            state.leaksList.leaksByProject[selectedProject].items;
          const projectAlertsIndexMap =
            state.leaksList.leaksByProject[selectedProject].indexMap;
          item = projectAlerts[projectAlertsIndexMap[selectedFeature]];
          break;
        }
        case 'noise': {
          item = state.noiseAlerts.selected;
          break;
        }
        case 'sensor': {
          const projectSenosors =
            state.leaksList.leaksByProject[selectedProject].sensors.sensors;
          const projectSensorsIndexMap =
            state.leaksList.leaksByProject[selectedProject].sensors.indexMap;
          item = projectSenosors[projectSensorsIndexMap[selectedFeature]];
          break;
        }
        case 'sop': {
          const projectSOPs =
            state.leaksList.leaksByProject[selectedProject].sops.sops;
          const projectSOPsIndexMap =
            state.leaksList.leaksByProject[selectedProject].sops.indexMap;
          item = projectSOPs[projectSOPsIndexMap[selectedFeature]]; // @TODO: projectSOPsIndexMap was null on update data..
          break;
        }

        case featuresTypes.INSPECT_ALERTS: {
          const projectInspectAlerts = state.leaksList.inspect.alerts.data;
          const projectInspectAlertsIndexMap =
            state.leaksList.inspect.alerts.indexMap;
          item =
            projectInspectAlerts[projectInspectAlertsIndexMap[selectedFeature]];
          break;
        }
        // case 'couple':
        //   const projectCouples = state.leaksList.leaksByProject[selectedProjectId].couples.couples;
        //   const projectCouplesIndexMap = state.leaksList.leaksByProject[selectedProjectId].couples.indexMap;
        //   item = projectCouples[projectCouplesIndexMap[selectedFeature]];
        // Here we need decide how to get the location of COUPLE between 2 sensors

        // case 'interference':
        // const projectInterference = state.leaksList.leaksByProject[selectedProjectId].interferences.interferences;
        // const projectInterferenceIndexMap = state.leaksList.leaksByProject[selectedProjectId].interferences.indexMap;
        // item = projectInterference[projectInterferenceIndexMap[selectedFeature]];
        // Here we need decide how to get the location of INTERFERENCE

        default:
          break;
      }
    }
  }
  let selectedMobileSample = undefined;

  if (ownProps.path === 'mobile') {
    const mobileState = state.mobile.samples;
    selectedFeature = mobileState.selectedSampleId;
    item = mobileState.items[mobileState.indexMap[selectedFeature]];
    selectedMobileSample = item;
  }

  const mobileSamplesState = state.mobile.samples;

  return Object.assign(
    {},
    {
      alertStateOptions,
      updateMapFeatures,
      selectedProject,
      interferences,
      planningState,
      installMode,
      projectsList,
      noiseAlerts,
      mapState,
      bIsWaitingForMapClick,
      item,
      isOnAddingNewManualAlert,
      isOnAddingNewManualNoiseAlert,
      actualLeakPositionState,
      dmaItmes,
      dmaModalDisplay,
      inspectSelectedAlerts,
      inspectAlertsCenter,
      distanceUnits,
      distanceFactor,
      displayAssessmentsOnlyRealLeaks,
      noiseSamplesColorAttr,
      mobileNoiseSamplesColorAttr,
      options,
      alertsPressure,
      alertsContext,
      selectedSample,
      g5SensorSelectedMessage: sensorG5SelectedMessage,
      sensorG5SelectedNoiseMsg,
      sensorSampleAudioData,
      selectedPressureTransientAlert,
      selectedSensorSample,
      selectedNoisAlert,
      selectedMobileSample,
      mobileSamplesState,
      mobileAlgParams,
      projectObj,
      g5NoiseGraphData: projectState.sensors.g5NoiseGraphData
    },
    ownProps
  );
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  updateState: () => {
    //dispatch(updateState(Date.now()));
  },
  handleClickOnPlanningMode: (LatLng, feature) => {
    dispatch(actionsPlanning.handleMapClick(LatLng, feature));
  },
  onFeatureClick: (item, props) => {
    if (props.mapState.drawMode.isActive == false) {
      dispatch(actionsMapHandler.handleFeatureClick(item));
    }
  },
  onMapClick: (event, latLng) => {
    dispatch(actionsMapHandler.handleMapClick(event, latLng));
  },
  addSopTemp: (LatLng) => {
    dispatch(installActions.setNewSopPosition(LatLng));
    //idanfrost - add new sop icon on the map until user clicks on add button.
  },
  //addCouplePathPoint: (coordinate, index, feature) => {
  //  dispatch(actionsPlanning.addCouplePathPoint(coordinate, index, feature));
  //},
  setCouplePathPointPosition: (index, newCoordinate) => {
    dispatch(actionsPlanning.setCouplePathPointPosition(index, newCoordinate));
  },
  setCoupleLength: (length) => {
    dispatch(actionsPlanning.setCoupleLength(length.toFixed()));
  },
  onSearchBoxKeyPress: (addressToSearch, callback) => {
    dispatch(
      actionsGlobals.searchAddress(addressToSearch, (resultPosition) => {
        if (resultPosition != null) {
          callback([resultPosition.lng, resultPosition.lat]);
        }
      })
    );
    //  .then(result => {
    //  if (result != null) {
    //    if (result[0].lat && result[0].lon) {
    //      callback([parseFloat(result[0].lon), parseFloat(result[0].lat)]);
    //    }
    //  }
    //});
  },
  onFeatureDrawned: (featureLngLatPoints) => {
    dispatch(actionsMapHandler.onFeatureDrawned(featureLngLatPoints));
  },
  closePannel: (type) => {
    switch (type) {
      case 'g5msg':
        dispatch(sensorsActions.selectG5Message(ownProps.selectedProject, {}));
        break;

      case 'g5noise':
        dispatch(
          sensorsActions.selectSensorNoiseMsg(ownProps.selectedProject, {})
        );
        break;

      case 'snesorSample':
        dispatch(
          sensorsActions.selectSensorSample(ownProps.selectedProject, {})
        );
        break;

      default:
        break;
    }
  },
  selectTransientSampleFunction: (sample) => {
    dispatch(pressureTransientActions.selectTransientAlertSample(sample));
  },
  selectSensorForNoiseAlert: (sensor) => {
    dispatch(noiseAlertsActions.selectSensorForNoiseAlert(sensor));
    console.log(sensor);
  },
  selectMobileSample: (sample) => {
    dispatch(mobileSamplesActions.setSelectedSample(sample));
  },
  getSignedUrlForFile: (bucket, key, cb) => {
    return dispatch(actionsGlobals.getSignedUrlForFile(bucket, key, cb));
  },
  setSelectedPolygonSensors: (sensors) => {
    const g5Sensors = sensors.filter((sensor) => sensor.DeviceID && sensor.DeviceID.startsWith('000')); // G5 sensros starts with '000......'
    const g5DeviceIds = g5Sensors.map((sensor) => sensor.DeviceID);
    dispatch(deviceConfigurationActions.selectDevices(g5DeviceIds));
    browserHistory.push({ pathname: 'g5Devices' });
  },
  generateSensorsReportFromSelections: (sensors) => {
    dispatch(reportsActions.downloadSensorsReportFromPolygon(sensors));
  },
  getPipesInPolygon: (polygon) => {
    return dispatch(pipesActions.fetchPipesData(polygon));
  }
});

const CMainMap = connect(mapStateToProps, mapDispatchToProps)(Map);

export { CMainMap };
