import React from 'react';

import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../components';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
import { ALERT_SAMPLES_COUNT_FOR_PAGE } from '../../actions/SamplesActions';
import Pagination from 'react-js-pagination';
import CheckBoxCell from '../CheckBoxCell/CheckBoxCell';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';

import { useDispatch, useSelector } from 'react-redux';
import { convertIntensityToDb, getSamplesAction } from '../../actions/MobileSamplesActions';
import { setIndexes } from '../../actions/MiddlewareActions';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

require('fixed-data-table-2/dist/fixed-data-table.css');
require('../AlertsTabs/SamplesTab/SamplesTab.scss');

const xlsColumns = [
  {title: "Sample Time", width: {wch: 22}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//pixels width
  {title: "User", width: {wch: 14}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//pixels width
  {title: "Device ID", width: {wch: 11}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//char width
  {title: "Sample State", width: {wch: 14}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//char width
  {title: "Intensity", width: {wch: 11}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  // {title: "Intensity (engine)", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Quality", width: {wch: 9}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Clarity", width: {wch: 10}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Address", width: {wch: 16}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Comment", width: {wch: 16}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Access Point Classify", width: {wch: 22}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Sample Classify", width: {wch: 16}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Duration", width: {wch: 10}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Frequency", width: {wch: 10}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Longitude", width: {wch: 16}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Latitude", width: {wch: 16}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
];

const getXlsRow = (item, options, accessPointsOptions, mobileAlgParams) => {
  let status = '--'
  let sopClassify = accessPointsOptions[0].DisplayName;
  let audioClassify = options.AudioClassificationEnum[0].label;
  let mobileIntensity = '--';
  let mobileQuality = '--';
  // let engineIntensity = '--';

  if (item.sample_state !== undefined) {
    const stateOption = options.SampleState.find((opt) => opt.value === item.sample_state);
    if (stateOption) {
      status = stateOption.label;
    }
  }
  if (item.user_sop_classify !== undefined) {
    const sopClassifyOption = accessPointsOptions.find((opt) => opt.value === item.user_sop_classify);
    if (sopClassifyOption) {
      sopClassify = sopClassifyOption.DisplayName;
    }
  }
  if (item.user_sample_classify !== undefined) {
    const audioClassifyOption = options.AudioClassificationEnum.find((opt) => opt.value === item.user_sample_classify);
    if (audioClassifyOption) {
      audioClassify = audioClassifyOption.label;
    }
  }
  if (item.mobile_intensity) {
    mobileIntensity = convertIntensityToDb(item.mobile_intensity, mobileAlgParams.noise_ref_delta_db, mobileAlgParams.engine_calibration)
  }
  if (item.mobile_quality) {
    mobileQuality = Math.max(item.mobile_quality, 0.2) * 100;
  }
  // if (item.engine_intensity) {
  //   engineIntensity = convertIntensityToDb(item.engine_intensity, mobileAlgParams.noise_ref_delta_db, false);
  // }

  return [
    { value: new Date(item.sample_time).toLocaleString(), style: {font: {sz: "12"}} },
    { value: item.user_name || '--', style: { font: {sz: "12"} } },
    { value: item.device_id_hex || '', style: { font: {sz: "12"} } },
    { value: status, style: { font: {sz: "12"} } },
    { value: mobileIntensity, style: { font: {sz: "12"}, numFmt: '1'} },
    // { value: engineIntensity, style: { font: {sz: "12"}, numFmt: '1'} },
    { value: mobileQuality, style: { font: {sz: "12"}, numFmt: '1'} },
    { value: item.engine_quality || '', style: { font: {sz: "12"}, numFmt: '1'} },
    { value: item.address || '', style: { font: {sz: "12"} } },
    { value: item.user_comment || '', style: { font: {sz: "12"} } },
    { value: sopClassify, style: { font: {sz: "12"} } },
    { value: audioClassify, style: { font: {sz: "12"} } },
    { value: item.audio_duration, style: { font: {sz: "12"} } },
    { value: (item.audio_frequency / 8), style: { font: {sz: "12"} } },
    { value: item.location.points[0].y, style: { font: {sz: "12"} } },
    { value: item.location.points[0].x, style: { font: {sz: "12"} } },
  ]
};
const MobileSamplesTable = (props, context) => {
  const dispatch = useDispatch();

  const mobileAlgParams = useSelector((state) => state.mobile.samples.algParams);
  const imagesOfSamples = useSelector((state) => state.mobile.samples.imagesOfItems);
  const tasksObj = useSelector((state) => state.mobile.tasks);
  const selectedTask = tasksObj.selectedTask.map((taskId) => tasksObj.items[tasksObj.indexMap[taskId]]['task_name']).join('_');

  const { setCheckedSamples, checkedSamples, uiInfo: { pageHeight, pageWidth } } = props;

  const pWidth = 0.385 * pageWidth;
  const pHeight = 0.375 * pageHeight;

  // const [currentPage, setCurrentPage] = React.useState(1);
  const [scrollToIndex, setScrollToIndex] = React.useState();
  const [sortField, setSortField] = React.useState('sample_time');
  const [sortDir, setSortDir] = React.useState('desc');

  const [accessPointsOptions, setAccessPointsOptions] = React.useState([]);

  const [xlsData, setXlsData] = React.useState({});

  React.useEffect(() => {
    if (props.options.AqsCode) {
      const options = props.options.AqsCode.filter((opt) => opt.isMobile === 1)
      setAccessPointsOptions(options);
    }
  }, [props.options.AqsCode]);

  // React.useEffect(() => {
  //   setCurrentPage(1);
  // }, [props.selectedFeature]);

  React.useEffect(() => {
    if (props.selectedSample) {
      const selectedRow = props.samplesIndexMap[props.selectedSample];
      setScrollToIndex(selectedRow);
    }
  }, [props.selectedSample]);

  // const handlePageClick = (pageNumber) => {
  //   setCurrentPage(pageNumber);
  //   dispatch(getSamples(props.selectedFeature, (pageNumber - 1), ALERT_SAMPLES_COUNT_FOR_PAGE));
  // };

  React.useEffect(() => {
    if (props.options && accessPointsOptions.length > 0) {
      const xlsRows = props.samples.map((item) => getXlsRow(item, props.options, accessPointsOptions, mobileAlgParams));
      const xlsData = [{
        columns: xlsColumns,
        data: xlsRows
      }]
      // console.log({ xlsData });
      setXlsData(xlsData);
    }
  }, [props.samples, props.options, accessPointsOptions, mobileAlgParams])

  const sortSamples = (field, dir) => {
    setSortField(field);
    setSortDir(dir);

    const sorted = props.samples.sort((a, b) => {
      let sortResult = 0;
      const fieldA = a[field] || 0;
      const fieldB = b[field] || 0;
      if (fieldA > fieldB) {
        sortResult = dir === 'asc' ? 1 : -1;
      } else if (fieldA < fieldB) {
        sortResult = dir === 'asc' ? -1 : 1;
      }

      console.log({
        a: a[field],
        b: b[field],
        res1: (a[field] > b[field]),
        res2: (a[field] < b[field]),
        result: sortResult
      });

      return sortResult;
    });

    const indexMap = setIndexes(sorted, 'sample_uid');
    dispatch(getSamplesAction(true, sorted, indexMap));

    // setUiSamples(sorted);
  };

  const sortProps = () => ({
    sort: sortSamples,
    sortBy: sortField,
    sortDir: sortDir,
  })

  const handleRowClick = (_event, rowIndex) => {
    const selectedSample = props.samples[rowIndex];
    props.onSelectMobileSample(selectedSample);
  };

  const handleRowClassNameGetter = (index) => {
    return props.samples[index]?.sample_uid === props.selectedSample
      ? 'active-row'
      : '';
  };
  const isChecked = (value) => checkedSamples.includes(value.sample_uid);

  const toggleSampleChecked = (value, checked) => {
    console.log('toggleSampleChecked');
    if (!checked) {
      setCheckedSamples([...checkedSamples, value.sample_uid]);
    } else {
      setCheckedSamples(checkedSamples.filter((it) => it !== value.sample_uid));
    }
  };

  const myHandleRowClick = (event, rowIndex) => {
    if (event.target.type === undefined || event.target.type !== 'checkbox') {
      handleRowClick(event, rowIndex);
    }
  }

  return (
    <div className="samples-table">
      <Table
        rowsCount={props.samples.length}
        width={pWidth}
        height={pHeight}
        headerHeight={30}
        onRowClick={myHandleRowClick}
        rowClassNameGetter={handleRowClassNameGetter}
        scrollToRow={scrollToIndex}
        rowHeight={30}
      >
        <Column
          columnKey="Selected"
          header={<Cell>{}</Cell>}
          cell={
            <CheckBoxCell
              data={props.samples}
              onChange={toggleSampleChecked}
              isChecked={isChecked}
            />
          }
          width={30}
          flexGrow={1}
        />
        <Column
          columnKey="sample_images"
          header={<SortableHeaderCell title='Images' sortTable={sortProps()}/>}
          cell={<TextCell data={props.samples} field="sample_images" />}
          width={50}
          flexGrow={1}
        />
        <Column
          columnKey="sample_time"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={
            <TextCell
              data={props.samples}
              field="sample_time"
              additional={props.timeZone}
              dateType={DateType.DATE_AND_TIME}
            />
          }
          width={150}
          flexGrow={5}
        />
        <Column
          columnKey="device_id"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={<TextCell data={props.samples} field="device_id_hex" />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey="sample_state"
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={
            <CSelectCell
              data={props.samples}
              options={props.options.SampleState}
              field="sample_state"
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey="mobile_intensity"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={<TextCell data={props.samples} field="mobile_intensity" additional={mobileAlgParams} />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey="mobile_quality"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={<TextCell data={props.samples} field="mobile_quality" />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey="engine_quality"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={<TextCell data={props.samples} field="engine_quality" />}
          width={100}
          flexGrow={1}
        />
        <Column
          columnKey="user_sop_classify"
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={
            <CSelectCell
              data={props.samples}
              options={accessPointsOptions}
              field="user_sop_classify"
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
        <Column
          columnKey="user_sample_classify"
          width={95}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={
            <CSelectCell
              data={props.samples}
              options={props.options.AudioClassificationEnum}
              field="user_sample_classify"
              notify={props.notify}
              sortField={sortField}
              sortDir={sortDir}
            />
          }
        />
      </Table>

      {/* <div className="center">
        <Pagination
          prevPageText={context.t('prev')}
          nextPageText={context.t('next')}
          firstPageText={context.t('first')}
          lastPageText={context.t('last')}
          activePage={currentPage}
          itemsCountPerPage={ALERT_SAMPLES_COUNT_FOR_PAGE}
          totalItemsCount={props.samplesCount}
          pageRangeDisplayed={5}
          onChange={handlePageClick}
        />
      </div> */}
      <div>
      {(checkedSamples.length > 0) && (
        <button
          type="button"
          className="btn btn-success btn-assessment create-item"
          onClick={() => {
            props.setIsAddMobileAlertOpen(true);
          }}
        >
          <i title={context.t('create alert')} />
        </button>
        )}
        <ExcelFile
          filename={selectedTask}
          element={
            <Button
              variant="contained"
              color="primary"
              size="small"
              startIcon={<SaveIcon />}
            >{context.t('Save as Excel')}</Button>
          }>
          <ExcelSheet dataSet={xlsData} name={'Mobile Samples'} />
        </ExcelFile>

      </div>
    </div>
  );
};

MobileSamplesTable.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileSamplesTable;
