import React from "react";

import { Table, Column } from "fixed-data-table-2";
import { SortableHeaderCell, TextCell, TableMixin } from "../../components";
import CTableActions from "../../containers/CTableActions/CTableActions";
// import CSensorsSettings from "../../containers/CSensorsSettings/CSensorsSettings";
import { DateType } from "../../containers/UIhelper/UIDateFormater";

const MobileAlertsTable = (props) => {

  const { items, indexMap, selectedFeature, pHeight, pWidth, sortProp, rowClickEvent, rowClassNameGetter, goToRow, t } = props;

  const totalItemsStyle = {
    float: "right",
    padding: "2px 8px",
  };

  return (
    <div>
      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
        scrollToRow={goToRow}
      >
        <Column
          columnKey="ID"
          width={120}
          flexGrow={2}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="alert_id" />}
        />
        <Column
          columnKey="CreationTime"
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={
            <TextCell
              data={items}
              field="create_date"
              dateType={DateType.DATE_AND_TIME}
            />
          }
        />
        <Column
          columnKey="Probability"
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="probability" />}
        />
      </Table>

      {items && items.length > 0 && <CTableActions type="mobile" />}
      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t("Selected: {selectedIndex}. Total: {total}", {
            selectedIndex: indexMap[selectedFeature] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}
    </div>
  );
}

export default TableMixin(MobileAlertsTable, null);;
