import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell } from '../../components';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

const G5BitMessages = (props, context) => {
  const { data, timeZone, downloadFile, uiInfo: { pageHeight, pageWidth } } = props;

  let pWidth = 0.395 * (pageWidth - 10);
  let pHeight = 0.494 * pageHeight;

  const rowClassNameGetter = (rowIndex) => {
    const { selectedMessage, data } = props;
    return (selectedMessage.ID == data[rowIndex].ID) ? 'active-row' : '';
  }

  const handleRowClick = (_event, rowIndex) => {
    const selectedItem = data[rowIndex];
    props.handleSelectMessage(selectedItem);
  }

  return (
    <Table
      rowsCount={data.length}
      width={pWidth}
      onRowClick={handleRowClick}
      rowClassNameGetter={rowClassNameGetter}
      maxHeight={pHeight - 117}
      headerHeight={46}
      rowHeight={30}>

      <Column
        columnKey="TimeStamp"
        header={<Cell>{context.t('Date')}</Cell>}
        cell={<TextCell data={data} field="TimeStamp" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="NoiseIntensityAmplitude"
        header={<Cell>{context.t('Amplitude')}</Cell>}
        cell={<TextCell data={data} field="NoiseIntensityAmplitude" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="NoiseIntensityAmplitudeRMS"
        header={<Cell>{context.t('Gain')}</Cell>}
        cell={<TextCell data={data} field="NoiseIntensityAmplitudeRMS" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="NoiseIntensityMinRMS"
        header={<Cell>{context.t('Min RMS')}</Cell>}
        cell={<TextCell data={data} field="NoiseIntensityMinRMS" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="File"
        header={<Cell>{context.t('File')}</Cell>}
        width={40}
        flexGrow={1}
        cell={(props) =>
          <Cell {...props} >
            <a
              key='File'
              className="btn-wav-download"
              title={data[props.rowIndex].AudioDataS3Key}
              onClick={() => {
                const item = data[props.rowIndex];
                downloadFile(item.AudioDataS3Key, 'file');
              }}
              href='javascript:void(0);'>
                {context.t('Download')}
            </a>
          </Cell>
        }
      />
    </Table>
  )
}

G5BitMessages.contextTypes = {
  t: PropTypes.func.isRequired
}

export default G5BitMessages;
