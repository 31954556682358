import React from 'react';
import PropTypes from 'prop-types';

let alertsIntensityTypes = [];
let alertsOtherTypes = [];
const intensityLevels = ['A', 'B', 'C'];

class AlertsLegend extends React.Component {

    componentWillMount() {
        alertsIntensityTypes = [
            {
                lable: this.context.t('Open'),
                class: 'open',
            },
            {
                lable: this.context.t('Close'),
                class: 'close-group',
            },
        ];

        alertsOtherTypes = [
            {
                lable: this.context.t('Interference'),
                class: 'interference',
            },
            {
                lable: this.context.t('Shadow'),
                class: 'shadow',
            },
            {
                lable: this.context.t('False Path'),
                class: 'false-path',
            },
            {
                lable: this.context.t('Consumption'),
                class: 'irrigation',
            },
        ];
    }

    render() {
        const p = this.props;
        return (
            <div>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {
                                intensityLevels && intensityLevels.map((title, index) => {
                                    return (
                                        <th>{title}</th>
                                        );
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {
                            alertsIntensityTypes && alertsIntensityTypes.map((type, index) => {
                                return (
                                    <tr className="table-row">
                                        <th>{type.lable}</th>
                                        <td className={'alert ' + type.class + ' a'} ></td>
                                        <td className={'alert ' + type.class + ' b'} ></td>
                                        <td className={'alert ' + type.class + ' c'} ></td>
                                    </tr>
                                    );
                            })

                        }

                        {
                            alertsOtherTypes && alertsOtherTypes.map((otherType, otherIndex) => {
                                return (
                                    <tr className="table-row">
                                        <th>{otherType.lable}</th>
                                        <td className={'alert ' + otherType.class} ></td>
                                    </tr>
                                    );
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

AlertsLegend.contextTypes = {
    t: PropTypes.func.isRequired
}

export default AlertsLegend;
