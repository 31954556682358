import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

const classNames = require('classnames');
require('./Scale.scss');

const CropSelect = ({
  clear,
  setActive,
  polygonPoints,
  selectedSensors,
  getPipesInPolygon,
  setSelectedPolygonSensors,
  generateSensorsReportFromSelections,
  user
}, context) => {
  const [isShow, setIsShow] = React.useState(false);
  const [loadingPipes, setLoadingPipes] = React.useState(false);
  const [pipesLength, setPipesLength] = React.useState();

  const classes = useStyles();

  const panelClasses = classNames(
    'crop-select',
    { 'show': isShow }
  );

  const toggleClick = () => {
    const visible = !isShow;
    setIsShow(visible);
    setActive(visible);
  }

  return (
    <div className={panelClasses}>
      <div className="actions">
        <i className="icon-toggle" onClick={toggleClick} />
      </div>
      <h1>{context.t('DRAW SENSORS SELECTION AREA')}</h1>
      <div className="distance-data">
        {selectedSensors.length > 0 &&
        <p>{context.t('{num} Sensors Selected', {num: selectedSensors.length})}</p>
        }
        {selectedSensors.length > 0 &&
          <div className='crop-select-buttons'>
            { user.g5Operator &&
              <Button
                color='primary'
                variant='contained'
                onClick={() => setSelectedPolygonSensors(selectedSensors)}>
                  {context.t('Show In G5')}
              </Button>
            }
            <Button
              color='primary'
              variant='contained'
              onClick={() => generateSensorsReportFromSelections(selectedSensors)}>
                {context.t('Report')}
            </Button>
            <IconButton
              color='secondary'
              variant='contained'
              onClick={clear}>
              <DeleteIcon />
            </IconButton>
          </div>
        }
        { user.isAQS && polygonPoints.length > 0 &&
          <div>
            <Button
              color='primary'
              variant='contained'
              onClick={() => {
                setLoadingPipes(true);
                setPipesLength(undefined);
                getPipesInPolygon(polygonPoints)
                .then((data) => {
                  setLoadingPipes(false);
                  if (data && data.length > 0) {
                    const totalLength = data.reduce((total, itr) => total + itr.sectionLength, 0);
                    setPipesLength(totalLength);
                  }
                })
                .catch((err) => {
                  setLoadingPipes(false);
                  console.log(err);
                });
              }}>
              {context.t('Pipe Length')}
            </Button>
            {loadingPipes && <Typography variant='inherit' component={'strong'}>Loading...</Typography>}
            {pipesLength &&
              <Typography
                variant='inherit'
                component={'strong'}
                classes={{ root: classes.lengthTitle }}
              >{Math.round(pipesLength)} [m]</Typography>}
          </div>
        }
      </div>
    </div>
  )
}

const useStyles = makeStyles({
  lengthTitle: {
    margin: 10
  }
});

CropSelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default CropSelect;
