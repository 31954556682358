import React from 'react';
import { zIndexs, featuresTypes } from './Options';

import { Style, Stroke, Fill } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import { Circle, Polygon } from 'ol/geom';
const proj = require('ol/proj');

const classNames = require('classnames');
const OPACITY = 0.3

// Generating new style when Interference 'selected' and when 'unselected'
export const getStyle = (featureType, isSelected, props) => {
  const { options } = props;
  let strokeWidth = 1.5;
  let strokeColor = '#a3a3a3';
  let fillColor;

  const interferenceOption = options.InterferenceTypes.find((opt) => opt.label === featureType);
  fillColor = (interferenceOption) ? `rgba(${interferenceOption.Color})` : `rgba(0, 0, 0, 0.3)`;

  // switch (featureType) {
  //   case 'PRV':
  //   case 'Pump':
  //     fillColor = `rgba(255, 5, 255, ${OPACITY})`;
  //     break;

  //   case 'Electricity':
  //     fillColor = `rgba(255, 255, 0, ${OPACITY})`;
  //     break;

  //   case 'Irrigation':
  //     fillColor = `rgba(0, 255, 0, ${OPACITY})`;
  //     break;

  //   case 'Gas':
  //     fillColor = `rgba(66, 185, 255, ${OPACITY})`;
  //     break;

  //   case 'Planning':
  //     fillColor = `rgba(255, 119, 0, ${OPACITY})`;
  //     break;

  //   default:
  //     fillColor = `rgba(255, 5, 255, ${OPACITY})`;
  //     break;
  // }

  if (isSelected) {
    strokeWidth = 3;
    strokeColor = '#ffc800';
    fillColor = `rgba(0, 0, 0, ${OPACITY})`;
  }

  const style = new Style({
    fill: new Fill({
      color: fillColor
    }),
    stroke: new Stroke({
      color: strokeColor,
      width: strokeWidth
    }),
  });

  return (style);
};

const source = new VectorSource({
    features: [],
});

export const layer = new VectorLayer({
    name: 'layerInterferences',
    // style: this.getStyle(null, false),
    source: source,
    zIndex: zIndexs.interference,
});

export const setSource = (nextIconFeatures, props) => {
    let iconFeatures = [];

    Object.keys(nextIconFeatures).forEach((key) => {
        const itrFeature = nextIconFeatures[key];
        let geometry;
        const polygonPoints = itrFeature.Boundary;
        var points = [];

        polygonPoints.forEach((itrPoint) => {
            const tranformedPoint = proj.transform([itrPoint[0], itrPoint[1]],
                'EPSG:4326',
                'EPSG:3857'
            );
            points.push(tranformedPoint);
        });


        if(itrFeature.GeometryType == "LINESTRING"){
            geometry = new Circle(points[0], points[1][0] - points[0][0]);
        }

        else if(itrFeature.GeometryType == "POLYGON"){
            geometry = new Polygon([points]);
        }

        const iconFeature = new Feature({
            geometry: geometry,
            type: featuresTypes.INTERFERENCE,
            id: itrFeature.id,
            interferenceType: itrFeature.Type
        });
        iconFeature.setStyle(getStyle(itrFeature.Type, false, props));
        iconFeatures.push(iconFeature);
    });

    source.clear();
    source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
    const attr = feature.getProperties();
    let infoTooltip = {
        type: featuresTypes.INTERFERENCE,
        id: attr.id,
        interferenceType: attr.interferenceType
    };
    return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
    const tooltipClasses = classNames(
        'tooltip',
        'priority-' + itemInfo.priority
    );

    return (
        <span className={tooltipClasses}>
            <div className="tooltip-row">{context.t('Area Of Interest ID: {id}', {id: itemInfo.id})}</div>
            <div className="tooltip-row">{context.t('Type: {type}', {type: itemInfo.interferenceType})}</div>
        </span>
    );
};

