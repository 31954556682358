/* eslint-disable no-unused-vars */
import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';

const requestCustomers = () => (
  {
    type: types.REQUEST_CUSTOMERS,
  }
);

const receiveCustomers = (items, indexMap, optionsList) => (
  {
    type: types.RECEIVE_CUSTOMERS,
    items,
    indexMap,
    optionsList,
  }
);

export const featchCustomers = () => (dispatch, getState) => {
  const path = endPoints.CUSTOMERS_ENDPOINT;
  dispatch(requestCustomers());
  return fetchMiddleware(path, {}, getState).then((json) => {
    const items = json.data;
    const indexMap = setIndexes(items, 'ID');
    const optionsList = [];
    items.forEach((customer) => {
      optionsList.push({ value: customer.ID, label: customer.Name});
    });
    return dispatch(receiveCustomers(items, indexMap, optionsList));
  });
};

export const sortCustomers = (field, dir) => (dispatch, getState) => {
  const state = getState();
  const customersList = Object.assign([], state.leaksList.customers.items);
  customersList.sort((a, b) => {
    let nRc = 0;

    const firstObj = (dir === 'asc') ? a : b;
    const secondObj = (dir === 'asc') ? b : a;

    if (firstObj[field] > secondObj[field] || secondObj[field] == null) nRc = 1;
    if (firstObj[field] < secondObj[field] || firstObj[field] == null) nRc = -1;

    return (nRc);
  });

  const newIndexMap = setIndexes(customersList, 'ID');

  dispatch(sortLocaly(customersList, newIndexMap, field, dir));
};

const sortLocaly = (customers, indexMap, field, dir) => (
  {
    type: types.SORT_CUSTOMERS_LOCALY,
    customers,
    indexMap,
    field,
    dir
  }
);

export const selectCustomer = (customerId) => (dispatch, getState) => {
  const customers = getState().leaksList.customers;
  const customer = customers.items[customers.indexMap[customerId]];
  return dispatch({
    type: types.SELECT_CUSTOMER,
    customer,
  });
};

export const createNewCustomer = (values, cb) => (dispatch, getState) => {
  const path = endPoints.CUSTOMERS_ENDPOINT;
  return fetchMiddleware(path, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ data: values })
  }, getState).then((json) => {
    cb(json);
    if (json.status) {
      dispatch(featchCustomers()).then(() => {
        dispatch(selectCustomer(json.data[0].ID));
      });
    }
  });
};

export const updateCustomerDetails = (values, cb) => (dispatch, getState) => {
  const state = getState();
  const customerId = state.manage.selectedManageItem.id;
  const path = `${endPoints.CUSTOMERS_ENDPOINT}/${customerId}`;
  return fetchMiddleware(path, {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({ values })
  }, getState).then((json) => {
    cb(json);
    if (json.status) {
      dispatch(featchCustomers()).then(() => {
        dispatch(selectCustomer(customerId));
      });
    }
  });
}

export function loadDistributionListsForProject(customerId) {
  return (dispatch, getState) => {
    const path = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}/${customerId}`;

    dispatch({ type: types.REQUEST_DISTRIBUTION_LIST_FOR_PROJECT });

    return fetchMiddleware(path, {}, getState)
    .then((json) => {
      if (json.status) {
        dispatch({ type: types.RECEIVE_DISTRIBUTION_LIST_FOR_PROJECT, payload: json.data });
      }
    });
  };
}

export const loadDistributionLists = (customerId) => (dispatch, getState) => {
  // const path = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}`;
  const path = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}/${customerId}`;
  // dispatch(requestDistributionLists());
  return fetchMiddleware(path, {}, getState)
    .then((json) => {
      if (json.status) {
        dispatch(receiveDistributionLists(json.data));
      }
    });
};

// const requestDistributionLists = () => {
//   return {
//     type: types.REQUEST_DISTRIBUTION_LISTS,
//   };
// };

const receiveDistributionLists = (lists) => (
  {
    type: types.RECEIVE_DISTRIBUTION_LISTS,
    lists
  }
);

export function loadUsersOfCustomer(customerId) {
  return (dispatch, getState) => {
    const url = `${endPoints.END_POINT}/users/customer/${customerId}`;

    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        dispatch(receiveUsersOfCustomer(customerId, json.data));
      }
    });
  };
}

function receiveUsersOfCustomer(customerId, users) {
  return {
    type: types.DISTRIBUTION_LISTS_RECEIVE_USERS_OF_CUSTOMER,
    payload: {
      customerId,
      users
    }
  };
}

export const selectList = (list) => (dispatch) => {
  dispatch(selectListAction(list));
  if (list.ID) {
    dispatch(fetchListUsers(list.ID));
  } else {
    // clear the display of lists users:
    dispatch(receiveListUsers('', []));
  }
};

const selectListAction = (list) => (
  {
    type: types.DISTRIBUTION_LIST_SELECT_LIST,
    list,
  }
);

export const postNewDistributionList = (customerId, name) => (dispatch, getState) => {
  const path = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}/${customerId}`;
  const options = {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({name})
  };
  return fetchMiddleware(path, options, getState)
    .then((json) => {
      if (json.status == false) {
        console.log(json);
      } else {
        const state = getState();
        const selectedCustomer = state.manage.selectedManageItem;
        dispatch(loadDistributionLists(selectedCustomer.id));
      }
    });
};

export const fetchListUsers = (listId) => (dispatch, getState) => {
  const path = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}/users/${listId}`;
  return fetchMiddleware(path, {}, getState)
    .then((json) => {
      if (json.status) {
        dispatch(receiveListUsers(listId, json.data));
      }
    });
};

const receiveListUsers = (listId, users) => (
  {
    type: types.RECEIVE_DISTRIBUTION_LIST_USERS,
    listId,
    users
  }
);

export const addUserToList = (userId) => ({
  type: types.DISTRIBUTION_LIST_ADD_USER,
  userId
});

export const removeUserFromList = (userId) => ({
  type: types.DISTRIBUTION_LIST_REMOVE_USER,
  userId
});

export const setUserAttribute = (userId, attrName, value) => ({
  type: types.DISTRIBUTION_LIST_SET_USER_ATTRIBUTE,
  userId,
  attrName,
  value
});

export const updateUsersList = (callback) => (dispatch, getState) => {
  const state = getState();
  const { selectedList, usersInEdit } = state.distributionLists;
  const usersData = usersInEdit.map((userItr) => ({
    id: userItr.UserID,
    isMail: userItr.RegisterMail,
    isSMS: userItr.RegisterSMS
  }));

  const path = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}/users/${selectedList.ID}`;

  const options = {
    method: 'PUT',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(usersData)
  };
  return fetchMiddleware(path, options, getState)
    .then((json) => {
      if (callback) {
        callback(json.status);
      }
    });
};

export function setListAnnouncesStatusChange(customerId, listId, isAnnounce, callback) {
  return (dispatch, getState) => {
    const url = `${endPoints.DISTRIBUTION_LISTS_ENDPOINT}/announcesStatusChange/${listId}`;
    const options = {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ customerId, isAnnounce })
    };

    return fetchMiddleware(url, options, getState).then((json) => {
      if (json.status) {
        dispatch(onSetListAnnouncesStatusChange(listId, isAnnounce));
      }

      callback(json.status);
    });
  };
}

function onSetListAnnouncesStatusChange(listId, isAnnounce) {
  return {
    type: types.DISTRIBUTION_LIST_SET_LIST_ANNOUNCES_STATUS_CHANGE,
    payload: {
      listId,
      isAnnounce
    }
  };
}
