import React from 'react';
import PropTypes from 'prop-types';

export default class ButtonCell extends React.Component {
  static get propTypes() {
    return {
      rowIndex: PropTypes.number.isRequired,
      data: PropTypes.array.isRequired,
      additional: PropTypes.string,
    };
  }

  constructor(props, context) {
    super(props, context);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(event) {
    event.preventDefault();
    event.stopPropagation();
    const func = this.props.onClick;
    if (func) {
      const { rowIndex, data } = this.props;
      const value = data[rowIndex];
      func(value);
    }
  }

  render() {
    const { title } = this.props;
    const { t } = this.context;

    return <button onClick={this.handleClick}>{t(title)}</button>;
  }
}
ButtonCell.contextTypes = {
  t: PropTypes.func.isRequired,
};
