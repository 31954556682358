import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../../components';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';
import { COUPLE_SAMPLES_COUNT_FOR_PAGE } from '../../../constants/Misc';
import Pagination from 'react-js-pagination';

const _ = require('lodash');

require('fixed-data-table-2/dist/fixed-data-table.css');
require('./CoupleOutputTab.scss');

const classNames = require('classnames');

class CoupleOutputTab extends React.Component {
    constructor(props) {
        super(props);
        let totalItemsCount = 0;
        let page = 1;

        this.onRowClick = this.onRowClick.bind(this);
        this.rowClassNameGetter = this.rowClassNameGetter.bind(this);
        this.onAlertLinkClick = this.onAlertLinkClick.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);

        if (props.coupleSamples != null) {
            totalItemsCount = props.coupleSamples.totalRows || 0;
            page = parseInt(props.coupleSamples.page) || 1;
        }
        this.state = {
            currentPage: page,
            totalItemsCount: totalItemsCount,
            clickOnAlertLink: false,
        }
    }

    onRowClick(event, rowIndex) {
        // implement on setState callback to check the fresh state of 'clickOnAlertLink':
        this.setState({}, () => {
            if (this.state.clickOnAlertLink === false) {
                this.props.selectCoupleSample(this.props.coupleSamples.rows[rowIndex]);
            }
        });
    }

    onAlertLinkClick(alertId) {
        if (alertId != null) {
            const { navigateToFeature } = this.props;
            const selectedAlert = {
                type: 'alert',
                id: alertId,
            }
            this.setState({
                clickOnAlertLink: true,
            });
            navigateToFeature(alertId, selectedAlert);
          console.log(alertId);
        }
    }

    rowClassNameGetter(rowIndex) {
        const { selectedSample, coupleSamples } = this.props;
        let className = '';
        if (selectedSample != null) {
          className = (selectedSample.ID == coupleSamples.rows[rowIndex].ID) ? 'active-row' : '';
        }
        return (className);
    }

    handlePageClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        })
        this.props.onPageIndexChange(pageNumber - 1);
    }

    componentDidMount() {
        const { coupleSamples } = this.props;


    }

    componentWillReceiveProps(nextProps) {
        const { coupleSamples, selectedFeature } = nextProps;

        let page = this.state.currentPage;
        let itemsCount = this.state.totalItemsCount;

        if (selectedFeature == this.props.selectedFeature) {
            if (coupleSamples != null) {
                if (coupleSamples.page != null) {
                    page = (parseInt(nextProps.coupleSamples.page) + 1) || 1;
                }
                if (coupleSamples.totalRows != null && coupleSamples.totalRows > 0) {
                    itemsCount = coupleSamples.totalRows;
                }
            }
        }

        if (page != this.state.currentPage || itemsCount != this.state.totalItemsCount) {
            this.setState({
                currentPage: page,
                totalItemsCount: itemsCount,
            });
        }
    }

    render() {
        const { coupleSamples, uiInfo, sortCoupleSamples, local, distanceFactor, user, goToRow } = this.props;
        const { totalItemsCount } = this.state;
        let pWidth = 0.395 * (uiInfo.pageWidth - 10);
        let pHeight = 0.494 * uiInfo.pageHeight;

        const rows = coupleSamples.rows || [];

        return (
            <div className="couples-table">
                <Table
                    rowsCount={rows.length}
                    width={pWidth}
                    maxHeight={pHeight - 117}
                    headerHeight={46}
                    scrollToRow={goToRow}
                    onRowClick={this.onRowClick}
                    rowClassNameGetter={this.rowClassNameGetter}
                    rowHeight={30}>
                    {user.isAQS &&
                        <Column
                            columnKey="RM"
                            header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                            cell={<TextCell data={rows} field="RM" />}
                            width={20}
                            flexGrow={0.5}
                        />
                    }
                    <Column
                        columnKey="SampleDateAndTime"
                        header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                        cell={<TextCell data={rows} field="SampleDateAndTime" additional={local} dateType={DateType.DATE_AND_TIME} />}
                        width={100}
                        flexGrow={1}
                    />
                    <Column
                        columnKey="LeakID"
                        header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                        cell={<TextCell data={rows} field="LeakID" onClick={this.onAlertLinkClick} />}
                        width={30}
                        flexGrow={1}
                    />
                    <Column
                      columnKey="IsLeak"
                      header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                      cell={<TextCell data={rows} field="IsLeak" />}
                      width={30}
                      flexGrow={1}
                    />
                    <Column
                        columnKey="IntensityA"
                        header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                        cell={<TextCell data={rows} field="IntensityA" />}
                        width={60}
                        flexGrow={1}
                    />
                    <Column
                        columnKey="IntensityI"
                        header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                        cell={<TextCell data={rows} field="IntensityI" />}
                        width={60}
                        flexGrow={1}
                    />
                    <Column
                        columnKey="DistanceAM"
                        header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                        cell={<TextCell data={rows} field="DistanceAM" distanceFactor={distanceFactor} />}
                        width={60}
                        flexGrow={1}
                    />
                    <Column
                        columnKey="DistanceIM"
                        header={<SortableHeaderCell sortTable={sortCoupleSamples} />}
                        cell={<TextCell data={rows} field="DistanceIM" distanceFactor={distanceFactor} />}
                        width={70}
                        flexGrow={1}
                    />
                </Table>

                <div className="center">
                    <Pagination
                        prevPageText={this.context.t('prev')}
                        nextPageText={this.context.t('next')}
                        firstPageText={this.context.t('first')}
                        lastPageText={this.context.t('last')}
                        activePage={this.state.currentPage}
                        itemsCountPerPage={COUPLE_SAMPLES_COUNT_FOR_PAGE}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageClick}
                    />
                </div>
            </div>
        );
    }
}

CoupleOutputTab.contextTypes = {
    t: PropTypes.func.isRequired
}

export default CoupleOutputTab;
