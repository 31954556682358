/**
 * Created by Yair Caspi on 11/06/2017.
 */
import React from 'react';

import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
require('./TermsAndConditions.scss');

class TermsAndConditions extends React.Component {

  render() {
    const content = {
      trimble: (
        <div className="terms">
          <h1 align="center">
            Trimble Unity Online Service
          <br />
            Terms of Service &amp; License Agreement
      </h1>
          <p align="justify">
            These are the general terms and conditions and end user license agreement
      (collectively, the <strong><em>“Terms of Service”</em></strong> or    <strong><em>“TOS”</em></strong>) that apply to the software based services
          accessible via the Internet (the <strong><em>“Services”</em></strong>)
      proprietary to Trimble Inc. and its affiliates (<strong><em>“Trimble”</em></strong>, <strong><em>“we”</em></strong> or    <strong><em>“us”</em></strong>) and offered and made available through
          Trimble portals (the <strong><em>“Sites”</em></strong>) to businesses and
          professionals.
      </p>
          <p align="justify">
            You, an individual or a single company or legal entity and the personnel
      associated with your business (collectively,    <strong><em>“you”</em></strong> or <strong><em>“Customer”</em></strong>),
          will be granted access to use the <strong><em>“Service”</em></strong> for
          your internal business purposes, conditioned upon your purchase of one or
      more Service subscription(s) (each a    <strong><em>“Service Subscription”</em></strong>), which may allow a user
          to access the Service and/or a Device (as defined below) to connect to the
          Service, and your acceptance of these TOS. If you are agreeing to these TOS
          on behalf of a company or other legal entity, you represent that you have
          the authority to contractually bind such entity and its personnel.
      </p>
          <p align="justify">
            Whether you purchase or obtain your Service Subscription(s) directly from
          Trimble through one of our Sites, or indirectly from an authorized vendor
          of the Services,
          <strong>
              BY SIGNING AN AGREEMENT REFERENCING THIS TOS AND/OR CLICKING ON “OK,”
              “ACCEPT,” “AGREE,” “SUBMIT” OR ANY BUTTON INDICATING ACCEPTANCE OF THE
              TOS AND/OR BY ACCESSING OR USING THE SERVICE OR THE SITE, YOU REPRESENT
              THAT YOU ARE AUTHORIZED TO USE SERVICE AND THAT YOU AGREE TO BE BOUND
              BY THE CURRENTLY POSTED TOS
          </strong>
            . FURTHER, SUBJECT TO SECTION 8.1 BELOW, YOUR CONTINUED USE OF THE SERVICE
          FOLLOWING THE POSTING OF ANY CHANGES TO THE TOS AND WILL CONFIRM YOUR
          ACCEPTANCE OF THOSE CHANGES. These Terms of Service constitute a binding,
          legal agreement between you and Trimble and are intended to have the same
          force and affect as if you had signed them.
      </p>
          <p align="justify">
            <strong>
              USE OF THE SERVICE AND THE SITE ARE OFFERED TO YOU CONDITIONED UPON
              YOUR ACCEPTANCE OF THE TOS WITHOUT ANY MODIFICATIONS. THE TOS CONTAINS
              DISCLAIMERS OF WARRANTIES AND LIABILITY (See Sections
          </strong>
            <strong>5.4, 10, 11, 12 and 14.1</strong>
            <strong>
              below). THESE PROVISIONS FORM AN ESSENTIAL PART OF OUR BARGAIN.
          </strong>
            <strong></strong>
          </p>
          <p align="justify">
            <strong></strong>
          </p>
          <p align="justify">
            A current copy of the TOS can be accessed and viewed at any time from the
          home page of the Site. We recommend that you download or print and retain
          copies of the TOS for your records. If you do not agree to the TOS, you
          agree not to use or access the Service or the Site nor are you authorized
          to do so.
      </p>
          <h1 align="center">
            <strong>Terms of Service &amp; License Agreement</strong>
            <strong></strong>
          </h1>
          <h1>
            <em></em>
          </h1>
          <h5>
            <strong>1. </strong>
            <strong> </strong>
            <strong>Application Services</strong>
            <strong></strong>
          </h5>
          <p align="justify">
            1.1 <em><u>General Description</u></em>. The Service is an application
          software-based service. The Service application software is designed to
          gather and process data and information (<strong><em>“Data”</em></strong>)
          that you may actively import to the Service and/or that may be
          automatically transferred from mobile devices, such as from mobile phones,
      tablet computers, telematics sensors, modules and equipment, etc., (    <strong><em>“Devices”</em></strong>) that you cause to be activated to be
          connected to the Service. After activation of a Service Subscription, the
          Service will be provided to you and your Authorized Users and the Data will
          be accessible to you and your Authorized Users (defined in Section 3) with
          content, functionality, reports and services, as may be changed from time
          to time. Service Subscriptions require Service activation. To have the
          Service activated you must (i) accept the payment terms and conditions
          applicable to your Service Subscription, (ii) accept these TOS, and (iii)
          consent to the transmission and use of the Data generated from your Devices
          as further described in Section 7.1 below.
      </p>
          <p align="justify">
            The Service may be offered under a variety of Service Subscription plans (    <strong><em>“Service Plans”</em></strong>) and, therefore, may be subject
          to additional terms and conditions either posted at our Trimble Store for
          the Site or contained in other ordering documentation.
      </p>
          <p align="justify">
            The availability of the Service may be affected, inter alia, by planned and
          unplanned maintenance periods, technology upgrades and as otherwise set
          forth in these TOS or any description of the Service.
      </p>
          <p align="justify">
            1.2 <em><u>Third Party Communications Systems</u></em>
          </p>
          <p align="justify">
            Some features of the Service may require use of a third party
          communications system, such as that of Internet access, wireless or a
      satellite-based communication system (each, a <strong>“</strong>    <strong><em>Communications Service”</em></strong>). UNLESS THE
          COMMUNICATION SERVICE IS EXPLICITLY MADE PART OF A SERVICE PLAN, CUSTOMER
          SHALL PURCHASE THESE COMMUNICATION SERVICES. TRIMBLE HAS NO RESPONSIBILITY
          FOR THE AVAILABILITY, QUALITY OR PERFORMANCE OF WIRELESS OR SATELLITE-BASED
          COMMUNICATIONS SERVICES OR EQUIPMENT FURNISHED BY THE COMMUNICATION
          CARRIERS. THE COMMUNICATIONS CARRIERS ARE EXCLUSIVELY RESPONSIBLE FOR SUCH
          SERVICES AND EQUIPMENT.
      </p>
          <p align="justify">
            1.3 <em><u>Data Exchange and Third Party Interfaces</u></em>
          </p>
          <p align="justify">
            The Service may permit the communication, transfer and exchange of data
          between the Service and certain third-party manufactured devices or
          systems. Trimble does not exercise control over the form or quality of data
          generated or transmitted by or to third-party manufactured assets, devices
          or other third-party developed solutions such as custom reports or
          interfaces (collectively, <strong><em>“Third-Party Data”</em></strong>).
          Therefore, if your Services Subscription permits use of the Service data
          exchange component you understand and agree to the following:
      </p>
          <ul>
            <li align="justify">
              Data may only be exchanged between the Service application and
              third-party manufactured assets, devices or systems approved for use
              with the Service by Trimble;
          </li>
            <li align="justify">
              Even though Trimble may have approved such use, Third Party Data may
              not be in a format that may be processed in the Service, and Data may
              not be in the format to be processed on the third party assets,
              devices, or systems, and usability or visualization of the data may be
              impaired.
          </li>
            <li align="justify">
              Trimble is not responsible for the quality or accuracy of, or the
              ability to receive, access or use Third-Party Data that may be reported
              into the Services or Data exported to or through third party
              manufactured devices or systems or other third party solutions (e.g.,
              custom reports or interfaces), whether a deficiency is due to
              third-party caused service outages, third-party software interface
              incompatibilities or failures, or otherwise;
          </li>
            <li align="justify">
              Trimble may reasonably screen all Third Party Data transmitted to and
              from the Service for viruses and other threats and abuse, and may stop
              or block any Third Party Data that Trimble believes may adversely
              affect performance of the Service.
          </li>
          </ul>
          <h4>
            <strong>2. </strong>
            <strong> </strong>
            <strong>Nature of Application &amp; Hosting</strong>
            <strong></strong>
          </h4>
          <p align="justify">
            The Service is hosted by or on behalf of Trimble and includes the Service
          application software the use and access to which is licensed under Section
          5 below, and third party software or services (to the extent permitted by
      the third party suppliers) (    <strong><em>“Third Party Software/Services”</em></strong>). The Service
          also may interact with software, which may include firmware, programs or
      apps run on your Devices <strong>(</strong>    <strong><em>“Software”</em></strong><strong>)</strong>. Via the Site, we
      may make available software or data services of third party suppliers (    <strong><em>“Third Party Suppliers”</em></strong>). Additional terms of
          Third Party Suppliers are set forth in Section 22, and you agree they apply
          to and govern your use of the Third Party Software/Services. Trimble will
          use reasonable endeavors to inform you on any changes to these flow down
          terms in electronic means by posting any updated terms from Third Party
          Suppliers in the FAQ section of the Service. You acknowledge that the Data
          and the Service may be transmitted through networks and signals that are
          not specifically protected or encrypted and where third party interception
          may be possible. You consent to such transmission and waive any claims that
          you may have against us with respect to such transmission. Subject to the
          specifics of the Services Subscription and the Service Plan you may make
          the Service available to Authorized Users on the basis of several factors
          including, without limitation, Web access, computer use, assets, operating
          system, Devices activated and maintained by you, username, password and/or
          other factors. The Service is subject to modification from time to time as
          further described in Section 8 below, and by accessing or using the Service
          and the Site, You consent to our adding, changing or removing any services
          made available in conjunction with or through them.
      </p>
          <h4 align="justify">
            <strong>3. </strong>
            <strong> </strong>
            <strong> </strong>
            <strong>Authorized Users and Use; Limitations</strong>
            .
      </h4>
          <h4 align="justify">
            3.1 <em><u>Authorized Users</u></em>. You agree that the Service will be
          accessed and used only by you. For that purpose you may designate
      authorized users whom you authorize to access and use the Service (    <strong><em>“Authorized User”</em></strong>), always provided that you have
          purchased a sufficient number of Services Subscriptions for the Authorized
          Users or Devices connecting to the Services. You agree that the access
          credential of one Authorized User can only be used by that Authorized User
          and you have to assure that the access credentials of one Authorized User
          only be used by this Authorized User and no other person. Point of delivery
          for the Service is the Site and, unless Service Plan explicitly entails
          connection to the Site, connecting to and accessing the Service through
          Devices and computers is within your sole responsibility, regardless of the
          nature of such connection. We are not responsible for the network
          connection or for issues, problems or conditions arising from or related to
          the network connection, including but not limited to bandwidth issues,
          network outages, firewalls and/or other conditions that are caused by the
          Web and/or network connection.
      </h4>
          <p align="justify">
            3.2 <em><u>Limitations on Use</u></em>.<strong> </strong>Transmission of
          information using a Device may be subject to legal requirements that may
          vary from location to location, including radiofrequency use authorization.
          You must limit use of any Device to those locations where all legal
          requirements for the use of the Device and the Service communication
          network have been satisfied. In the event that a Device is used at a
          location where (i) legal requirements are not satisfied or (ii)
          transmitting or processing of such information across multiple locations
          would not be legal, we disclaim any and all liability related to such
          failure to comply and Trimble may discontinue the transmission of
          information from that Asset.
          <strong>
              You also understand and acknowledge that the Service is not intended to
              be the sole method for notification or providing information about any
              emergency, mission critical, safety-related or other ultrahazardous
              activities and you shall not use the Service in that manner.
          </strong>
          </p>
          <h4 align="justify">
            <strong>4. </strong>
            <strong> </strong>
            <strong> </strong>
            <strong>Registration; Payment</strong>
            <strong></strong>
          </h4>
          <p align="justify">
            4.1 <em><u>Customer Account</u></em>. In order to log on to the Site and
          use the Service, an account for you will be created and activated and you
          may create access credentials or connections for you and Authorized Users
          and Devices, always subject to you purchasing the sufficient number of
          Service Subscriptions. In the process of creating the account and access
          credentials, you will be asked to provide Trimble with true, accurate,
          current and complete registration information in relation to you and your
          Authorized Users and to update such information as necessary. .Without
          prejudice to the other rights and remedies as provided by law, we have the
          right to suspend or terminate your or the Authorized User’s right to access
          and use the Service if we have reasonable grounds to suspect that any
          information provided in relation to you or an Authorized User is
          inaccurate, incomplete or untrue.
      </p>
          <p align="justify">
            4.2 <em><u>Site Access &amp; Password; Security</u></em>. You are
          responsible for maintaining, and you shall cause all Authorized Users to
          maintain, the confidentiality of your and your Authorized Users’ usernames
          and passwords and are fully responsible for all activities that occur on
          the Site under your designated Authorized Users’ usernames and passwords.
          We may require you to change your designated Authorized Users’ usernames in
          the event we determine, in our sole discretion, that any username is
          offensive or for any other reason. You agree to immediately notify Trimble
          of any unauthorized use of any Authorized User’s username, password, or any
          other breach of security, and to ensure that Authorized Users logout at the
          end of each session. You are responsible for disabling the accounts of any
          Authorized User no longer employed by you or requesting that such accounts
          be disabled by Trimble. You acknowledge that Trimble is not responsible for
          tracking your Authorized User’s use of the Service, and that Trimble cannot
          and will not be liable for any loss or damage arising from your failure to
          comply with this Section 4.
      </p>
          <p align="justify">
            4.3 <em><u>Payment</u></em>. You agree to pay all charges and fees in
          accordance with the payment terms applicable to the Service Subscriptions
          that you acquire. If data transmission is included in a Service Plan, such
          transmission may be subject to certain limits. You agree to reimburse
          Trimble for any charges for data transmission in excess of these limits as
          well as any activation and de-activation charges that may be imposed by the
          transmission service provider because of your conduct in accordance with
          the service provider’s terms.
      </p>
          <h4 align="justify">
            <strong>5. </strong>
            <strong> </strong>
            <strong> </strong>
            <strong>Subscriptions and Licenses; Restrictions</strong>
            <strong></strong>
          </h4>
          <h4 align="justify">
            5.1 <em>Service Subscription</em>. Subject to all of the terms and
          conditions of this TOS, including payment of applicable Service
          Subscription fees, (i) Trimble shall provide and you may access and use the
          Service(s), solely for the your internal business operations, and (ii)
          Trimble hereby grants you and you hereby accept a non-transferable,
          non-sublicensable, non-exclusive, revocable license to use Content and any
          documentation accompanying the Service Subscription, solely for your
          internal business operations; each for the term of the Service
          Subscription, but each only in accordance with this TOS and documentation.
      </h4>
          <h4 align="justify">
            5.2 <em>License</em>. If the Services require or permit you to use
          Software, and subject to all of the terms and conditions of this TOS,
          including the payment of applicable fees, Trimble hereby grants to you a
          non-transferable, non-sublicensable, non-exclusive, perpetual license to
          download, install and use the Software, in machine-readable form only,
          solely for your internal business needs and in accordance with (a) any
          documentation, (b) this TOS and (c) any accompanying end-user license
          agreement.
      </h4>
          <p align="justify">
            5.3 <em><u>Restrictions - No Misuse of Services</u></em>. Your use of the
          Service and the Software is limited to those uses expressly permitted under
          your Service Subscription and these TOS. As a condition of your use of the
          Service you agree that you will not use it or the Software for any purpose
          that is unlawful or prohibited by the TOS. We may restrict or cancel the
          Service to you if there is a reasonable suspicion of, or any actual misuse
          or fraudulent use by you. You will be responsible for any costs incurred by
          us or any other party (including attorney’s fees) as a result of such
          misuse or fraudulent use. You may not: (i) reproduce, modify, publish,
          distribute, publicly display, adapt, alter, translate, or create derivative
          works from the Service, Software or any Third Party Software/Services; (ii)
          merge the Service, Software or Third Party Software/Services with other
          software; (iii) sublicense, lease, sell, rent, loan, or otherwise transfer
          the Service or associated Software or Third Party Software/Services to any
          third party; (iv) reverse engineer, decompile, disassemble, or otherwise
          attempt to derive the source code for the software underlying the Service,
          Software, Third Party Software/Services or Site; (v) otherwise use or copy
          the Service, Software or Third Party Software/Services except as expressly
          allowed under the TOS, including this Section 5; (vi) use the Service,
          Software or Third Party Software/Services in a “service bureau” or similar
          structure whereby third parties obtain use of the Service, Software or
          Third Party Software/Services through you; (vii) remove, obscure, or alter
          any copyright, trademark, or other proprietary notices embedded in, affixed
          to or accessed in conjunction with the Service, Software, and/or the Site;
          (viii) damage, disable, overburden, interfere with, disrupt or impair the
          Service or Site, or servers or networks connected to them, in any manner;
          (ix) interfere with any other party’s access, use or enjoyment of the
          Service or the Site in any manner; (x) intentionally or unintentionally
          violate any applicable local, state, national or international law,
          including, but not limited to, laws and regulations related to export; or
          (xi) impersonate any person or entity or misrepresent your affiliation with
          a person or entity.
      </p>
          <p align="justify">
            5.4 <em><u>Third Party Software/Services</u></em>. You hereby acknowledge
          and agree that any Third Party Software/Services that may be bundled with
          the Service (e.g., maps) is/are being provided to you by Third Party
          Suppliers and not by Trimble. You hereby acknowledge and agree that your
          access or use of any such Third Party Software/Services is governed by such
          Third Party Suppliers’ Terms of Service, end user license agreement or
          other like agreement, and in absence of such terms or agreement, by these
          TOS unless it is clarified in Section 23 that these Third Party
          Software/Services are provided by Trimble by way of sub-license. Trimble
          will use good faith and reasonable efforts to notify you of events
          affecting the Third Party Software/Services (e.g., down time or
          maintenance) that may impact your use of the Site. HOWEVER, TRIMBLE CANNOT
          BE RESPONSIBLE FOR, AND EXPRESSLY DISCLAIMS ANY LIABILITY IN CONNECTION
          WITH THE THIRD PARTY SOFTWARE/SERVICES OR YOUR USE (OR ANY AUTHORIZIED
          USER’S USE) THEREOF.
      </p>
          <p align="justify">
            5.5 <em><u>Time Limited Service Subscription</u></em>. In the event that
          use of the Service provided to you is time limited (such as that provided
          on a trial basis or to beta testers, resellers and distributors for sales
          demonstration purposes or to prospective end user customers for evaluation
          purposes) (the <strong><em>"Time Limited Service"</em></strong>), then you
          may use the Time Limited Service in accordance with the TOS solely for the
          purpose for which it is provided to you, and only for the limited period of
          time specified by Trimble. Trimble may revoke your access to and
          authorization to use Time Limited Service at any time. In the event that
          you subsequently acquire a paid Service Subscription, then your continued
          use of the Service will continue to be governed by the TOS and associated
          Service Subscription terms and conditions. Resale, transfer or other
          distribution of the Time Limited Service is prohibited.
      </p>
          <h5 align="justify">
            <strong>6. </strong>
            <strong> </strong>
            <strong>Processing of Personal Data</strong>
            <strong></strong>
          </h5>
          <p align="justify">
            6.1 <em>Data Privacy</em>. To understand Trimble’s general data privacy
          practices, please review Trimble’s
          <a href="http://www.trimble.com/Corporate/Privacy.aspx">
              <u>Privacy Statement</u>
            </a>
            , which can be found at any time at
          <a href="http://www.trimble.com/Corporate/Privacy.aspx">
              <u>http://www.trimble.com/Corporate/Privacy.aspx</u>
            </a>
            . You acknowledge that some Data processed by the Service may be
          retraceable to individuals (<strong><em>“Personal Data”</em></strong>).
          Trimble will process Personal Data that you or an Authorized User transmits
          to Trimble for processing on your behalf in connection with the Service as
          necessary perform Trimble’s obligations under this TOS. Trimble, for
          example, may match the username to other personally identifiable
          information in order to provide the Authorized User with Services that the
          Authorized User is entitled to use and to provide relevant Data and
          information to Authorized Users. Additionally, the Service may permit
          collection of location-based data in connection with your Devices and the
          Services provided (<strong><em>“Location Data”</em></strong>) so that the
          geographic location of the Authorized User may be identifiable. Trimble
          will maintain an adequate level of protection for Personal Data by
          implementing and maintaining appropriate technical and organizational
          security measures.
      </p>
          <p align="justify">
            6.2 <em><u>Compliance with laws</u></em><em>. </em>You are responsible for
          the evaluation of the admissibility of the processing of the Personal Data
          contained in any data and information transmitted to Trimble and/or through
          the Service and for ensuring the rights of the data subjects concerned. In
          this respect, you warrant that the Personal Data that is processed through
          the provision of Services is obtained in accordance with the applicable
          data protection laws and that you have the authority to disclose such
          information. You are responsible for compliance with and agree to comply
          with all applicable data protection laws. If EU Data Directive 95/46 or
          similar data protection laws are applicable, you acknowledge that Trimble
          is only acting as a data processor on your behalf, and that you are the
          “data controller” pursuant to EU Directive 95/46 and applicable data
          protection laws, as may be amended from time to time. You agree to respect
          all obligations resulting from applicable data protection laws, notably
          with respect to data subjects, competent data protection authorities, us as
          data processor, and any other third parties. Without limiting the
          generality of the foregoing, you must ensure that data subjects receive
          notices or grant consent as legally required. You agree to indemnify,
          defend and hold Trimble harmless from and against all claims, demands,
          actions or causes of action arising out of your breach of the foregoing
          obligations.
      </p>
          <h5 align="justify">
            6.3 <em><u>Contact Details</u></em>. We will process your contact details
          that you provided to us upon conclusion of the Service Subscription and
          will be deemed controller of any Personal Data contained in your contact
          details. We will process your contact details in accordance with Our
          Privacy Statement and for the purpose of providing the Services,
          newsletters and marketing e-mails. Our Privacy Statement is available at
          <a href="http://www.trimble.com/privacy.aspx">
              <u>http://www.trimble.com/privacy.aspx</u>
            </a>
            .<strong></strong>
          </h5>
          <h5 align="justify">
            <strong>7.</strong>
            <strong> </strong>
            <strong>
              Consent to Transmission and Use of Data; License and Aggregate Data
          </strong>
            <strong></strong>
          </h5>
          <p align="justify">
            7.1 <em><u>Consent to</u></em><u> </u>    <em><u>Data Transmission and Use</u></em>. Once your Service Subscription
          is activated you may transmit or cause your Devices to gather and transmit
          Data to the Service. The Data transmitted may include, without limitation,
          such information as is described in the applicable Service description in
          addition to Device ID, software and hardware version numbers. If you use
          the Service to manage and monitor infrastructure, vehicles, fixtures and
          equipment (<strong><em>“Assets”</em></strong>) the Data transmitted may
          include, without limitation, the ID, Location Data, usage and diagnostics,
          repairs information of and for those Assets.
      </p>
          <p align="justify">
            Data is processed on your behalf. If you elect to do so you may cause the
          Service to transfer Data to Authorized Users, third parties or to Trimble.
          Trimble shall be entitled to use the Data for the following purposes: (a)
          providing the Services to you and your Authorized Users, (b) evaluating or
          improving the Service and/or other products and services; (c) performing
          market analysis and research; (d) offering you new products and services;
          and (e) complying with legal requirements and valid court orders, as
          applicable.
      </p>
          <p align="justify">
            Trimble may also combine the Data imported from your Devices or Assets with
      that of other users of the Service to create aggregated data (    <strong><em>“Aggregated Data”</em></strong>) for the principal purposes of
          tracking market trends and developing new or improved service offerings. We
          will not, however, link your Data with personally identifying information.
          Aggregated Data will, therefore, be anonymized and cannot be used to
          identify you or any other user of the Service.
      </p>
          <p align="justify">
            Trimble may also transmit and share some or all of the collected Data with
          our dealer(s), our affiliates and their dealers, the manufacturer of your
          Assets and its dealers, and our business partners worldwide who provide the
          Services, Assets or related products and services to you.
      </p>
          <table>
            <tbody>
              <tr>
                <td width="951" valign="top">
                  <p align="justify">
                    <strong>
                      <u>
                        By authorizing activation of and using our Service,
                                  you hereby grant Trimble the right and consent to
                                  Trimble’s processing transmission, use and transfer
                                  of Data, including Personal Data, for the purposes
                                  and in the manners described
                              </u>
                    </strong>
                    <strong><u>in these TOS</u></strong>
                    <strong><u>. </u></strong>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <p align="justify">
            7.2 <em><u>Data License</u></em>. To the extent that you have any ownership
          or other interests in the Data, you hereby grant to Trimble, its dealers,
          its affiliates and their dealers, a non-exclusive, perpetual, worldwide,
          royalty free, and irrevocable license to use, process, manipulate and/or
          modify, copy, perform, compile and create derivative works from the Data,
          including using the Data for the business purposes described in Section
          7.1. You hereby acknowledge and agree that Trimble and its affiliates may
          disclose to third parties Data (other than Personal Data) derived, compiled
          or otherwise drawn from the Data.
      </p>
          <p align="justify">
            <u>7.3 </u>
            <u> </u>
            <em><u>Automatic Synchronization</u></em>
            . For optimum use<em>, </em>the Service may require, and Trimble may
          provide to Customer from time to time, in a number of formats (feeds,
          definition files etc.), content that is automatically synchronized or
      updated from time to time with Trimble’s servers or systems (“    <strong><em>Conten</em></strong>t”). You acknowledge and agree that the
          Service will automatically contact Trimble to receive Content from time to
          time, including when any of the following events occur: (i) the Service is
          successfully installed by Customer; (ii) Customer fails to install the
          Service successfully; (iii) the Service has been successfully configured
          and / or (iv) the Service is uninstalled. You may be required to download
          Software and the Services may automatically update the software installed
          on any Device when a new version is available.
      </p>
          <p align="justify">
            7.4 <em><u>Deletion of Data</u></em><em>. </em>You acknowledge and agree
          that you will no longer be able to access the Data following the deletion
          of the Data by us in accordance with Section<strong> </strong>15.
      </p>
          <h4>
            <strong>8.</strong>
            <strong> </strong>
            <strong>Modification of TOS</strong>
          </h4>
          <p>
            8.1 Trimble may modify and update the TOS if circumstances under which the
          TOS is entered into change following purchase of your Service Subscription,
          and Trimble will provide you with reasonable notice of any such changes.
          Your continued use of the Service following the posting of any such changes
          will constitute confirmation of your acceptance of the updated TOS, unless
          you notify Trimble in writing within 30 days of the notice that you do not
          accept them. In that case the TOS in effect at the time of your purchase or
          last renewal of the Service Subscription (whichever is later) will remain
          in effect through the remainder of your then current subscription term,
          unless Trimble, at its option, exercises its right to terminate your
          Service Subscription(s) in accordance with Section 14.2 below.
      </p>
          <p>
            <strong>9. </strong>
            <strong> </strong>
            <strong>Network Coverage, GNSS Satellites, Interruption of Service</strong>
            <strong></strong>
          </p>
          <p align="justify">
            9.1 You acknowledge that the Services and network access may be subject to
          transmission limitations caused by a variety of factors such as atmospheric
          conditions, topographical obstructions, limitations or lack of coverage of
          the underlying carrier service and other natural or manmade conditions. To
          the extent that Devices or other hardware units receive signals from Global
          Navigation Satellite System (<strong><em>“GNSS”</em></strong>) you further
          acknowledge that Trimble is not responsible for the operation or failure of
          operation of any GNSS satellites or the availability of GNSS satellite
          signals.
      </p>
          <p>
            9.2 Trimble will not be liable to you or any third party for any loss or
          damage arising from the Service, or its interruption, transmission errors
          (including Location Data inaccuracies), downtime of the Service due to
          maintenance or defects or any other cause, including, but not limited to,
          interruption caused by the underlying communications system carrier.
          Trimble does not assume and will not have any liability arising from events
          beyond Trimble’s control or the control of Trimble’s subcontractors,
          licensors or business partners, including events such as acts of God, acts
          of any governmental entity, acts of public enemy, strikes or weather
          conditions.
      </p>
          <h6>
            <strong>10. </strong>
            <strong> </strong>
            <strong>
              No Warranties; Customer Responsibility for Devices, Risk of Loss and
              “Alert” Feature Configuration.
          </strong>
            <strong></strong>
          </h6>
          <p align="justify">
            TRIMBLE, ITS DIRECTORS, OFFICERS, EMPLOYEES, LICENSORS, SUPPLIERS, DEALERS,
          AFFILIATES AND AGENTS (EACH A <strong><em>“TRIMBLE PARTY”</em></strong> AND
          COLLECTIVELY THE <strong><em>“TRIMBLE PARTIES”</em></strong>) DISCLAIM ANY
          RESPONSIBILITY FOR ANY HARM RESULTING FROM YOUR USE OF THE SERVICE AND
          SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (a) THE APPLICATION, SITE
          AND SERVICES ARE PROVIDED ON AN “AS IS”, “WITH ALL FAULTS” AND “AS
          AVAILABLE” BASIS AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY,
          PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU; (b) TO THE FULLEST EXTENT
          PERMITTED BY APPLICABLE LAW, THE TRIMBLE PARTIES MAKE NO REPRESENTATIONS,
          WARRANTIES OR CONDITIONS, EXPRESS, IMPLIED, STATUTORY OR OTHERWISE,
          INCLUDING WITHOUT LIMITATION, (i) WARRANTIES OF TITLE, MERCHANTABILITY,
          FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE EFFORT, ACCURACY, QUIET
          ENJOYMENT, NO ENCUMBRANCES, NO LIENS AND NON-INFRINGEMENT, (ii) WARRANTIES
          ARISING THROUGH COURSE OF DEALINGS OR USAGE OF TRADE, (iii) WARRANTIES
          REGARDING THE SECURITY, RELIABILITY, TIMELINESS, AND PERFORMANCE OF THE
          SERVICE APPLICATION, SITE AND SERVICES, AND (iv) WARRANTIES THAT ACCESS TO
          OR USE OF THE SERVICE AND SITE WILL MEET YOUR REQUIREMENTS, BE
          UNINTERRUPTED OR ERROR-FREE; AND (c) THAT YOU WILL ACCESS THE SITE AND USE
          THE SERVICE AT YOUR OWN DISCRETION AND RISK AND THAT YOU WILL BE SOLELY
          RESPONSIBLE FOR ANY DAMAGES TO YOUR COMPUTER SYSTEM(S) OR LOSS OF DATA THAT
          RESULTS FROM SUCH ACCESS AND USE.
      </p>
          <p align="justify">
            YOU FURTHER AGREE THAT, IF THE SERVICES ARE USED TO TRACK THE LOCATION;
          PERFORMANCE; HEALTH AND STATUS OF YOUR ASSETS AND/OR DEVICES YOU ARE SOLELY
          RESPONSIBLE FOR THE PROPER MAINTENANCE, OPERATION AND SUPPORT OF SUCH
          ASSETS AND DEVICES. NOTWITHSTANDING YOUR USE OF THE SERVICE AND REGARDLESS
          OF ANY INFORMATION PROVIDED THROUGH IT (WHETHER ACCURATE OR INACCURATE)
          REGARDING THE OPERATION, MAINTENANCE OR PERFORMANCE STATUS OF THE ASSETS
          AND/OR DEVICES, YOU ASSUME ALL RISK RELATED TO THEIR PROPER OPERATION,
          SUPPORT AND MAINTENANCE. <strong>ACCORDINGLY,</strong>
            <strong>
              YOU ARE RESPONSIBLE FOR ANY RISK OF LOSS OF ANY ASSETS OR DEVICES FROM
              ANY CAUSE, INCLUDING, WITHOUT LIMITATION, VEHICLE AND EQUIPMENT
              FAILURE, THEFT, FIRE, COLLISION, TAMPERING, AND VANDALISM.
          </strong>
            <strong></strong>
          </p>
          <p align="justify">
            <strong>
              THERE ARE NO WARRANTIES THAT EXTEND BEYOND THOSE EXPRESSLY GRANTED IN
              THE TOS
          </strong>
            .
      </p>
          <h6>
            11. <strong>Limitation of Liability</strong><strong></strong>
          </h6>
          <h6 align="justify">
            UNDER NO CIRCUMSTANCES, INCLUDING NEGLIGENCE, WILL THE TRIMBLE PARTIES BE
          LIABLE TO YOU FOR ANY DAMAGES, INCLUDING, WITHOUT LIMITATION, DIRECT,
          INDIRECT, INCIDENTAL, SPECIAL, EXEMPLARY, PUNITIVE, THIRD PARTY OR
          CONSEQUENTIAL (INCLUDING DAMAGES FOR LOSS OF BUSINESS PROFITS, BUSINESS
          INTERRUPTION, LOSS OF DATA, LOSS OF BUSINESS INFORMATION, VIRUS INFECTIONS,
          SYSTEM OUTAGES AND THE LIKE) ARISING OUT OF, BASED ON OR RESULTING FROM THE
          TOS OR YOUR ACCESS TO, USE OF, MISUSE OF OR INABILITY TO USE THE SERVICE OR
          THE SITE, EVEN IF TRIMBLE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
          DAMAGES (INCLUDING DAMAGES INCURRED BY THIRD PARTIES). THE EXCLUSION OF
          DAMAGES UNDER THIS SECTION 11 IS INDEPENDENT OF ANY REMEDY PROVIDED UNDER
          THE TOS AND SURVIVES IN THE EVENT SUCH REMEDY FAILS OF ITS ESSENTIAL
          PURPOSE OR IS OTHERWISE DEEMED UNENFORCEABLE. THESE LIMITATIONS AND
          EXCLUSIONS APPLY WITHOUT REGARD TO WHETHER DAMAGES ARISE FROM BREACH OF
          CONTRACT OR WARRANTY, NEGLIGENCE OR ANY OTHER CAUSE OF ACTION. TO THE
          EXTENT THAT APPLICABLE LAW DOES NOT PROHIBIT SUCH EXCLUSIONS AND
          LIMITATIONS, IN NO EVENT WILL TRIMBLE’S TOTAL LIABILITY TO YOU FOR ALL
          DAMAGES, LOSSES AND CAUSES OF ACTION, WHETHER IN CONTRACT, STRICT
          LIABILITY, TORT (INCLUDING NEGLIGENCE) OR OTHERWISE IN AGGREGATE EXCEED THE
          AMOUNTS PAID BY YOU TO TRIMBLE FOR YOUR ACCESS OR USE OF THE SERVICE
          HEREUNDER.
      </h6>
          <p align="justify">
            THE PRECEDING LIMITATIONS OF LIABILITY DO NOT APPLY TO LIABILITIES THAT
          CANNOT BE EXCLUDED OR LIMITED BY APPLICABLE LAWS, SUCH AS IN THE EVENT OF
          STATUTORILY MANDATED LIABILITY (INCLUDING LIABILITY UNDER APPLICABLE
          PRODUCT LIABILITYLAW) OR IN THE EVENT OF PERSONAL INJURY ARISING SOLELY
          FROM A TRIMBLE PARTY’S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.
      </p>
          <p align="justify">
            <strong>12.</strong>
            <strong> </strong>
            <strong>Customer Indemnification </strong>
            <strong></strong>
          </p>
          <p align="justify">
            <strong> </strong>
            UPON OUR REQUEST<strong> </strong>YOU AGREE TO INDEMNIFY, DEFEND AND HOLD
          HARMLESS EACH OF THE TRIMBLE PARTIES FROM AND AGAINST ANY AND ALL CLAIMS,
          LAWSUITS, DEMANDS, ACTIONS OR OTHER PROCEEDINGS BROUGHT AGAINST IT BY ANY
          THIRD PARTY DUE TO, ARISING OUT OF OR RELATED TO YOUR (A) USE OF THE
          SERVICE OR THE SITE, (B) VIOLATION OF THE TOS, OR (C) YOUR VIOLATION OF ANY
          LAW, REGULATION OR THIRD PARTY RIGHTS. You shall pay any and all costs,
          damages and expenses, including, without limitation, reasonable attorneys’
          fees and costs awarded against or otherwise incurred by Trimble in
          connection with or arising from any such claim, lawsuit, action, demand or
          other proceeding.
          <strong>
              Trimble may, at its own expense, assume the exclusive defense and
              control of any matter otherwise subject to indemnification by you, in
              which event you agree to cooperate with Trimble in asserting any
              available defenses.
          </strong>
          </p>
          <h6>
            <strong>13.</strong>
            <strong> </strong>
            <strong>Intellectual Property Rights</strong>
            <strong></strong>
          </h6>
          <p align="justify">
            13.1 You hereby agree and acknowledge that Trimble and its licensors (and
          as applicable, Third Party Suppliers) own all right, title and interest in
          and to all Intellectual Property Rights (defined below) in connection with
      or relating to the Service and the Site.    <strong><em>“Intellectual Property Rights”</em></strong> means any and all
          rights under patent law, copyright law, trade secret law, trademark law,
          and any and all other proprietary or moral rights, and any and all
          applications, renewals, extensions and restorations thereof, now or
          hereafter in force and effect worldwide. You will have no right, license,
          or interest therein, expressly or impliedly, except for any license
          expressly granted herein. Trimble or its licensors, as the case may be,
          will own all right, title and interest in and to any replacements,
          improvements, updates, enhancements, derivative works and other
          modifications (including, without limitation, the incorporation of any
          ideas, methods or processes provided by or through you) to Intellectual
          Property Rights made by any person, even if paid for by you and regardless
          of whether or not they are similar to any of your Intellectual Property
          Rights. You will undertake any and all action necessary to vest such
          ownership in Trimble, including without limitation assigning (and you
          hereby assign) to Trimble all rights in and to such Intellectual Property
          Rights, including, without limitation, patent applications, patents, moral
          rights and copyrights arising from or relating to the Service or the Site.
          Notwithstanding the foregoing, Trimble’s Intellectual Property Rights as
          they pertain to the Data, include only the rights granted to Trimble under
          Section 5 above.
      </p>
          <h4 align="justify">
            13.2 You acknowledge that all trademarks, service marks, and logos
          (collectively, <strong><em>“Marks”</em></strong>) that appear throughout
          the Service or the Site belong to Trimble or the respective owners of such
          Marks, and are protected by U.S. and international trademark and copyright
          laws. Any use of any of those Marks without the express written consent of
          Trimble or the owner of the Mark, as applicable, is strictly prohibited.
          Trimble may, in appropriate circumstances and at its sole discretion,
          disable and/or terminate the accounts of Authorized Users who may be
          infringing the Intellectual Property Rights of others.
      </h4>
          <p>
            <strong>14. </strong>
            <strong> </strong>
            <strong>
              Suspension, Discontinuation or Cancellation of Service to Customer;
              Cancellation Fee
          </strong>
            <strong></strong>
          </p>
          <p align="justify">
            14.1 Without prejudice to Trimble's right to suspend the provision of the
          Service, Trimble has the right to terminate your right to access and use
          the Services with immediate effect and without liability or prior notice,
          if
      </p>
          <p align="justify">
            (i) you violate the TOS, or breach the terms of your Service
          Subscription(s) or any other valid agreement with Trimble for use of the
          Service;
      </p>
          <p align="justify">
            (ii) fail to pay Service Subscription fees or other amounts owed when due,
          (whether due to Trimble directly or to the dealer or distributor through
          whom you acquired your Service Subscription);
      </p>
          <p align="justify">
            (iii) the Communications Carrier through which you are able to access and
          use the Service terminates your Communications Service; or
      </p>
          <p align="justify">
            (iv) Trimble has reason to believe that you, your Authorized Users, any of
          your agents or any third party is abusing the Service or using it
          fraudulently or unlawfully
      </p>
          <p align="justify">
            (v) you or any third party file(s) an application, or threat(s) to file an
          application for administration, receivership, bankruptcy or any similar
          proceeding involving your assets under the laws applicable to such assets.
      </p>
          <p align="justify">
            <strong>
              If you pay Service Subscription fees to the dealer or distributor from
              whom you acquired your Service Subscription (rather than to Trimble
              directly) you acknowledge and agree that Trimble may rely in good faith
              upon the dealer’s or distributor’s notice to Trimble of your
              non-payment of Service Subscription fees and as a result may suspend
              your access to the Service and terminate your license to use it without
              liability
          </strong>
            . In addition, subject to Section 14.2 below, Trimble may discontinue the
          Service in whole or in part, and/or cancel your Service account with or
          without notice for any reason at any time. YOU AGREE THAT NEITHER TRIMBLE
          NOR ITS AFFILIATES NOR DEALERS, NOR ANY OTHER INDIVIDUAL OR ENTITY FROM
          WHOM YOU PURCHASED OR OTHERWISE ACQUIRED YOUR SERVICE SUBSCRIPTION WILL BE
          LIABLE TO YOU OR TO ANY THIRD PARTY FOR ITS CANCELLATION OR TERMINATION FOR
          ANY OF THE ABOVE-CITED REASONS EXCEPT AS EXPRESSLY PROVIDED HEREIN. IF YOUR
          ACCOUNT IS CANCELLED FOR ANY REASON, YOU AGREE NOT TO RE-REGISTER FOR AN
          ACCOUNT TO WITHOUT WRITTEN PERMISSION FROM TRIMBLE.
      </p>
          <p align="justify">
            14.2 Trimble may discontinue the Service in whole or in part, and/or cancel
          your Service account with or without notice for any reason at any time. If
          your Services Subscription and Service account are terminated for Trimble’s
          convenience and no fault on your part, then you will have no obligation to
          pay Service Subscription fees for any period following the date of
          termination, and you will be reimbursed a proportion of the subscription
          fees received by Trimble based upon the un-lapsed term of the currently
          active Service Subscription as of the date of termination. You will,
          however, remain obligated to pay Service Subscription fees for the term of
          Service Subscription until the date of termination.
      </p>
          <p align="justify">
            14.3 Should you cancel your account and/or the Service Subscriptions in
          whole or in part, or if we cancel or terminate your account and Service
          Subscription(s) due to your violation of the TOS, breach of the terms of
          your Service Subscription or any other valid agreement with Trimble, or
          your insolvency, bankruptcy (or similar action or proceeding), or your
          ceasing to do business in the ordinary course, you will be charged a
          cancellation fee equal to the amount of the subscription fee charges
          payable or already paid through the remaining term of your then currently
          active Service Subscription(s). Any such cancellation charges will become
          immediately due and payable, if not already paid.
      </p>
          <h4>
            <strong>15. </strong>
            <strong> </strong>
            <strong> Deletion of Customer Data</strong>
          </h4>
          <p align="justify">
            Trimble may permanently delete any and all information, Data and content
          maintained in or under your account from its servers upon any termination
          of the Service or your Service Subscription, or at any earlier time at
          Trimble’s discretion. Trimble accepts no responsibility for such deleted
          information, Data or content.
      </p>
          <h6>
            <strong>16.</strong>
            <strong> </strong>
            <strong>Notices and Electronic Communications</strong>
            <strong></strong>
          </h6>
          <p align="justify">
            ￸ When you visit the Site or send e-mail to us, you are communicating with
          us electronically. You consent to receive communications from us
          electronically. We will communicate with you by e-mail or by posting
          notices on this Site. You agree that all agreements, notices, disclosures
          and other communications that we provide to you electronically satisfy any
          legal requirement that such communications be in writing. You may update
          your e-mail address by visiting the location on the Site where you have
          provided contact information. If you do not provide Trimble with accurate
          information, Trimble cannot be held liable if it fails to notify you. You
          may have the right to request that Trimble provide such notices to you in
          paper format, and may do so by contacting Trimble Inc., Attention: Trimble
          Water, Attn: Important Notice, 3501 Jamboree Road, Newport Beach,
          California 92660, Email:
          <a href="mailto:trimblewater_support@Trimble.com">
              <u>trimblewater_support@Trimble.com</u>
            </a>
            . Any other communications should also be sent to that address.<em></em>
          </p>
          <p align="justify">
            Legal notices must be provided to the above address, with a copy to:
          Trimble Inc., Attention: General Counsel - Important Notice, 935 Stewart
          Drive, Sunnyvale, CA 94085.
      </p>
          <h6>
            <strong>17.</strong>
            <strong> </strong>
            <strong>U.S. Government Restricted Rights</strong>
            [Applies only to U.S. Government Licensees]<strong></strong>
          </h6>
          <p>
            The Service application, Site and the Services, their content and other
          materials, are deemed “commercial computer software” and “commercial
          computer software documentation” pursuant to DFAR Section 227.7202 and FAR
          Section 12.212 (and any successor sections). Use of the Service and the
          Site including, but not limited to, its reproduction and display, by the
          United States of America and/or any of its instrumentalities, regardless of
          form, is governed by the TOS.
      </p>
          <p>
            <strong>18. </strong>
            <strong> </strong>
            <strong>Choice of Law and Forum </strong>
            <strong></strong>
          </p>
          <p>
            The TOS is/are governed by and construed in accordance with the laws of the
          State of California and applicable United States federal law, without
          reference to “conflicts of laws” provisions or principles. You hereby
          consent and agree to the exclusive jurisdiction of, and venue in, the state
          and federal courts located in the County of Santa Clara, California.
          Notwithstanding the foregoing Trimble may choose to file a complaint
          against you or take any other legal action (including, without limitation,
          requesting injunctions or immediate relief in summary proceedings) against
          you before any competent court in your jurisdiction for any claim or action
          arising out of or relating to the TOS or your use of the Service.
      </p>
          <p>
            <strong>19.</strong>
            <strong> </strong>
            <strong>Export</strong>
            <strong></strong>
          </p>
          <p align="justify">
            Use of the Service is subject to the U.S. Export Administration
          Regulations. You agree to the following: (a) you are not a citizen,
          national or resident of, and am not under the control of, the government of
          Cuba, Iran, North Korea, Syria, Sudan nor any other country to which the
          United States has prohibited export; (b) you will not export or re-export
          materials from the Site, directly or indirectly, neither to the above
          mentioned countries nor to citizens, nationals or residents of those
          countries; (c) you are not listed on the United States Department of
          Treasury lists of Specially Designated Nationals, Specially Designated
          Terrorists, and Specially Designated Narcotic Traffickers, nor are you
          listed on the United States Department of Commerce Table of Denial Orders;
          (d) you will not export or re-export Site materials, directly, or
          indirectly, to persons on the above mentioned lists; and (e) you will not
          use the Site and Site materials for, and will not allow the Site and Site
          materials to be used for, any purposes prohibited by United States law,
          including, without limitation, for the development, design, manufacture or
          production of nuclear, chemical or biological weapons of mass destruction.
      </p>
          <p>
            <strong>20.</strong>
            <strong> </strong>
            <strong>General Provisions </strong>
            <strong></strong>
          </p>
          <p>
            The TOS, the terms of your Service Subscription and/or any other valid
          agreement between you and Trimble for use of the Service, constitute the
          entire agreement between you and Trimble and govern your use of the Service
          and Site, superseding any and all prior agreements, negotiations and
          communications (whether written, oral or electronic) between you and
          Trimble with respect to the subject matter hereof. No change, modification,
          or wavier of the TOS, will be binding on Trimble unless made in writing,
          with Trimble’s approval. Any rights not otherwise expressly granted under
          the TOS are reserved by Trimble and its licensors. The failure of Trimble
          to exercise or enforce any right or provision of the TOS shall not
          constitute a waiver of such right or provision. If any part of the TOS is
          held invalid or unenforceable, by a court of competent jurisdiction, that
          portion shall be construed in a manner consistent with applicable law to
          reflect, as nearly as possible, the original intentions of Trimble, and the
          remaining portions shall remain in full force and effect. You agree that
          regardless of any statute or law to the contrary, any claim or cause of
          action arising out of or related to use of the Service and/or your Service
          Subscription must be filed within one (1) year after such claim or cause of
          action arose or be forever barred.
      </p>
          <p>
            <strong>21.</strong>
            <strong> </strong>
            <strong>Official Language</strong>
            <strong></strong>
          </p>
          <p>
            The official language of the TOS is English. For purposes of
          interpretation, or in the event of a conflict between English and versions
          of TOS in any other language, the English language version shall be
          controlling.
      </p>
          <p>
            <strong>22.</strong>
            <strong> </strong>
            <strong>Third Party Terms</strong>
            <strong> </strong>
            <strong></strong>
          </p>
          <p>
            22.1<strong> </strong>
            <strong>
              Additional Terms and Conditions for the Use of ESRI ArcGIS Server
              Enterprise and associated GeoRest Web Services API’s, Version 10.x,
              ArcGIS for Windows Mobile, ArcGIS Runtime for Android, ArcGIS Runtime
              for iOS, ArcGIS Runtime for WPF and ArcGIS JavaScript API.
          </strong>
          </p>
          <p>
            You hereby accept the following additional Terms of Service in relation to
      any data, material, or software proprietary to ESRI and its licensors (    <strong><em>“Hosted Solutions”</em></strong>) access to which is provided
          as part of the Services (as defined in Trimble’s Terms of Service):
      </p>
          <p>
            a. ESRI and its licensors are not your contractual partner and shall, to
          the extent permitted by applicable law, not be liable for any damages or
          loss of any kind, whether direct, special, indirect, incidental, or
          consequential, arising from the use of the Hosted Solution(s) including,
          but not limited to, liability for use of Hosted Solution(s) in high-risk
          activities. THE HOSTED SOLUTION(S) IS/ARE PROVIDED "AS IS" WITHOUT WARRANTY
          OF ANY KIND. Without limiting the generality of the preceding sentence,
          ESRI and its licensors do not warrant Data, Web Services, and the Hosted
          Solution(s) will meet the Hosted Solution End User's needs or expectations,
          that the use of Data, Web Services, and Hosted Solution(s) will be
          uninterrupted, or that all nonconformities can or will be corrected. ESRI
          and its licensors are not inviting reliance on Data, Web Services, and
          Hosted Solution(s), and Hosted Solution End User should always verify
          actual Data, Web Services and Hosted Solutions(s).
      </p>
          <p>
            b. Upon termination or expiration of any applicable end user agreement or
          Subscription Plan, you are no longer entitled to access and use any of the
          Hosted Solution(s).
      </p>
          <p>
            c. You may not remove or obscure any copyright, trademark notice, or
          restrictive legend of ESRI or its licensors.
      </p>
          <p>
            d. Should you have to click-through any of ESRI’s License Agreement (E204
          and E300) included with ESRI Licensed Material, such License Agreements
          shall not become binding between you and ESRI, they maintain part of the
          Hosted Solution for convenience only.
      </p>
          <p>
            e. You may not share its login or password with any other third party or
          other Hosted Solution End User.
      </p>
          <p>
            22.2
          <strong>
              Additional Terms and Conditions for the Use of Trimble Unity
              LeakManager, Trimble Unity LeakLocator, and/or Software or Services
              Provided by or Including Software, Products or Services of Aquarius
              Spectrum Ltd.
          </strong>
            <strong></strong>
          </p>
          <p>
            <strong></strong>
          </p>
          <p>
            THE SERVICES, WHICH ARE PROVIDED FOR USE SOLELY IN CONJUNCTION WITH THE
          DESIGNATED AQUARIUS SPECTRUM LTD. HARDWARE, ALONG WITH ALL HARDWARE,
          SOFTWARE, PRODUCTS AND SERVICES PROVIDED THEREWITH (COLLECTIVELY, THE
          “SYSTEM”) IS PROVIDED TO CUSTOMER ON AN “AS IS” AND “AS AVAILABLE” BASIS.
          NEITHER TRIMBLE, NOR ITS DISTRIBUTORS, RESELLERS, AGENTS, SUB-CONTRACTORS,
          NOR ITS SUPPLIER, AQUARIUS SPECTRUM LTD. (SEPARATELY AND COLLECTIVELY
          “SUPPLIER”) MAKE ANY WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE
          CORRECTNESS, QUALITY, ACCURACY, SECURITY, COMPLETENESS, RELIABILITY,
          PERFORMANCE, TIMELINESS OR CONTINUED AVAILABILITY OF THE SYSTEM OR THE
          ABILITY OF ANY CONNECTION OR COMMUNICATION SERVICE TO PROVIDE OR MAINTAIN
          ACCESS TO THE SYSTEM. EXCEPT FOR THE WARRANTY PROVIDED TO CUSTOMER FOR THE
          HARDWARE, SUPPLIER HEREBY DISCLAIMS AND CUSTOMER HEREBY WAIVES ANY AND ALL
          WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE,
          ANY WARRANTY OF NONINFRINGEMENT OF THIRD PARTIES' RIGHTS OR THAT THE SYSTEM
          WILL BE PROVIDED ERROR FREE OR WITHOUT INTERRUPTION OR THAT THE SYSTEM WILL
          MEET CUSTOMER’S REQUIREMENTS OR EXPECTATIONS. SUPPLIER SPECIFICALLY
          DISCLAIMS ANY AND ALL WARRANTIES AND REPRESENTATIONS WITH RESPECT TO
          ACCURACY. NO THIRD PARTY, DISTRIBUTOR, RESELLER, AGENT, OR EMPLOYEE IS
          AUTHORIZED TO MAKE ANY MODIFICATIONS, EXTENSIONS, OR ADDITIONS TO THIS
          WARRANTY.
      </p>
          <p>
            WITHOUT DEROGATING FROM THE GENERALITY OF THE FOREGOING, IT IS EXPRESSLY
          CLARIFIED THAT THE SYSTEM IS A SUPPORTIVE TOOL WITH RESPECT TO THE
          DETECTION AND MANAGEMENT OF LEAKS IN WATER SYSTEMS, AND SHOULD NOT BE
          RELIED UPON EXCLUSIVELY. THE MANNER BY WHICH ANY SPECIFIC LEAK SHOULD BE
          HANDLED AND/OR REPAIRED IS DEPENDENT ON NUMEROUS FACTORS WHICH ARE OUTSIDE
          OF THE SCOPE OF THE SYSTEM'S CAPACITY AND WHICH SHOULD BE SEPARATELY
          ADDRESSED AND CONSIDERED BY CUSTOMER, AT CUSTOMER’S SOLE RESPONSIBILITY.
          UNDER NO CIRCUMSTANCES WILL SUPPLIER, ITS SHAREHOLDERS OR ANY OTHER PARTY
          ACTING ON SUPPLIER’S BEHALF BE LIABLE FOR ANY DAMAGE, LOSS OR EXPENSE
          (INCLUDING PHYSICAL INJURIES) WHICH MAY BE CAUSED TO CUSTOMER, ANYONE
          ACTING ON CUSTOMER’S BEHALF, OR ANY THIRD PARTY IN CONNECTION WITH THE USE
          OF OR RELIANCE ON THE SYSTEM, INCLUDING WITHOUT LIMITATION ANY DAMAGE,
          EXPENSE OR LOSS CAUSED AS A RESULT OF WATER LEAKS OR BURSTS, DAMAGES TO
          PIPING SYSTEMS AND/OR THE LIKE. IN ADDITION, SUPPLIER MAKES NO WARRANTY,
          EXPRESS OR IMPLIED, WITH RESPECT TO FAULT-FREE OPERATION OF THE SYSTEM AND
          SHALL NOT BEAR ANY LIABILITY OR RESPONSIBILITY WHATSOEVER FOR DAMAGES OR
          LOSSES CAUSED BY OR ATTRIBUTABLE TO INACCURATE OR ERRONEOUS MEASUREMENTS,
          INCLUDING WITHOUT LIMITATION WHEN THIS RESULTS FROM AN ERROR OF THE SYSTEM.
      </p>
          <p>
            UNDER NO CIRCUMSTANCES WHATSOEVER WILL SUPPLIER BE LIABLE IN ANY WAY FOR
          ANY DAMAGE OR LIABILITY ARISING FROM (I) ANY CONTENT (WHETHER PROVIDED BY
          SUPPLIER OR UPLOADED BY CUSTOMER), INCLUDING, WITHOUT LIMITATION, FOR ANY
          ERRORS OR OMISSIONS IN ANY CONTENT, OR FOR ANY INFRINGEMENT OF THIRD
          PARTY'S RIGHT, LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE
          OF THE CONTENT AND/OR THE SYSTEM; (II) SYSTEM DEFECTS RESULTING FROM THE
          USE OF THIRD PARTY PRODUCTS OR SERVICES; (III) FAILURE OF COMMUNICATION BY
          A GSM PROVIDER OR ANY OTHER COMMUNICATIONS PROVIDER; OR (IV) THE
          UNAUTHORIZED OR EXCESSIVE USE OF THE SYSTEM OR USE OF THE SYSTEM IN A
          MANNER INCONSISTENT WITH THESE TERMS.
      </p>
          <p>
            LIMITATION OF LIABILITY
      </p>
          <p>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, SUPPLIER WILL NOT BE
          LIABLE TO CUSTOMER FOR ANY INDIRECT, SPECIAL, INCIDENTAL, PUNITIVE OR
          CONSEQUENTIAL DAMAGES OF ANY KIND (INCLUDING LOST PROFITS), REGARDLESS OF
          THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE),
          STRICT LIABILITY OR OTHERWISE, EVEN IF INFORMED OF THE POSSIBILITY OF SUCH
          DAMAGES IN ADVANCE. NOTWITHSTANDING ANY OTHER PROVISION OF THESE TERMS,
          SUPPLIER’S LIABILITY TO CUSTOMER, WHETHER IN CONTRACT, TORT OR OTHERWISE,
          WILL NOT EXCEED THE LIMITATIONS OF LIABILITY SET OUT IN SECTION 11.
      </p>
          <p>
            Customer / end-user agrees that the limitations specified in this Section
          22.2 will survive and apply even if any limited remedy specified in these
          Terms is found to have failed of its essential purpose.
      </p>
        </div>),

      aqs: (
        <div className="terms">
          <p align="center">
            <strong><u>TERMS AND CONDITIONS</u></strong>
          </p>
          <p>
            PLEASE READ THESE TERMS AND CONDITIONS (THE “<strong>TERMS</strong>”)
                CAREFULLY BEFORE SIGNING UP TO THE SERVICE/S AND USING THE SYSTEM (AS
                DEFINED BELOW). BY ACCESSING OR USING THE SERVICE YOU (THE "    <strong>END USER</strong>" OR “<strong>YOU</strong>”) ARE ACCEPTING AND
                AGREEING TO BE BOUND BY ALL THE TERMS SET FORTH HEREIN. THESE TERMS ARE
                BETWEEN YOU AND AQUARIUS SPECTRUM LTD ("<strong>AQUARIUS</strong>") ONLY.
                IF YOU DO NOT AGREE TO THESE TERMS DO NOT ACCESS OR USE THE SERVICE IN ANY
                MANNER WHATSOEVER.
            </p>
          <p>
            These Terms have three sections:
            </p>
          <p>
            <strong><u>Section I</u></strong>
            applies to all grants of rights.
            </p>
          <p>
            <strong><u>Section II</u></strong>
            applies if You are an end user who has purchased the System directly from
                Aquarius.
            </p>
          <p>
            <strong><u>Section III</u></strong>
            applies if You are an end user who has purchased the System from an
                authorized distributor of Aquarius (“    <strong>Authorized Distributor</strong>”).
            </p>
          <p>
            <strong><u></u></strong>
          </p>
          <p>
            <strong><u>SECTION I</u></strong>
            <strong>-- TERMS APPLICABLE TO ALL GRANTS OF RIGHTS </strong>
          </p>
          <p>
            1. <strong><u>General</u></strong>. Aquarius has developed an integrated
                system for monitoring water distribution networks enabling acoustic leakage
                detection, comprised of fixed vibration correlating sensors (the “    <strong>Products</strong>”), and cloud-based automatic signal processing
                proprietary software (the “<strong>Service/s</strong>”) (the “    <strong>Products</strong> ” and the “<strong>Service</strong>”,
                collectively, the “<strong>System</strong>”). You have purchased, either
                from Aquarius or through an Authorized Distributor, the Products, which
                have been installed in accordance with Aquarius’s instructions and
                guidelines, and now require access to the Service which may be accessed at
                the following designated website:
                <a href="https://www.aqs-sys.com">
              https://www.aqs-sys.com
                </a>
            (the <strong>"Website"</strong>). The Services are provided to You on a
                software as a service basis (SaaS). Aquarius may provide You with certain
                updates and upgrades to the Service as they become generally commercially
                available. These Terms apply to the Service. You agree and acknowledge that
                your use of the Products is permitted solely in conjunction with the
                Service and neither the Service or the Products may be used with any other
                product or service. <strong></strong>
          </p>
          <p>
            2. <strong><u>Fees</u></strong><strong>.</strong> The fees and payment
                terms for the Service shall be determined in accordance with Your agreement
                with Aquarius or, if You purchased a right to access the Service through an
                Authorized Distributor, as determined in the agreement by and between You
                and such Authorized Distributor (each an “<strong>Agreement</strong>”), or
                if not covered in the Agreement, in accordance with the then applicable
                pricelist of Aquarius (the “<strong>Fees</strong>”).
            </p>
          <p>
            <a name="_Ref412985585">
              Any failure to make payment of the Fees to Aquarius, by the End User or
                    by an Authorized Distributor on behalf of such End User, as applicable,
                    or breach of the payment terms shall entitle Aquarius to immediately
                    terminate or suspend access to the Service (either temporarily or
                    indefinitely) or, if applicable, directly engage with the End User for
                    the purpose of allowing the End User to continue to receive the
                    Services directly from Aquarius.
                </a>
          </p>
          <p>
            3. <strong><u>Limitations</u></strong>. Other than the rights explicitly
                granted in these Terms, You shall have no other rights, express or implied,
                in or to the Service. Without limiting the generality of the foregoing, You
                agree and undertake not to, and to not permit any third party to; (i) sell,
                lease or distribute the Service, or any part thereof, or otherwise transfer
                any right to the Service; (ii) reverse engineer, decompile, disassemble, or
                otherwise reduce to human-perceivable form the Service’s source code and or
                any third party software provided by Aquarius; (iii) modify, revise,
                enhance, or alter the Service; (iv) copy or allow copies of the Service to
                be made; (v) place the Service onto a server accessible for use by any
                third party; (vi) use the Service on any device that You do not own or
                control, and You may not distribute or make the Service available over a
                network where it could be used by multiple devices at the same time except
                as required for its proper use and operation; (vii) represent that You
                possess any proprietary interest in the Service; or (viii) use the Service
                in any illegal manner or for unlawful purposes.
            </p>
          <p>
            4. <strong><u>Consent to Use of Data</u></strong>. The Service enables You
                to add, create or use certain content which You provide or which is
                generated through your use of the Service, including but not limited to
                maps, photos, flags about leaks, accessories installed, and reports (the “    <strong>Content</strong>”). AQUARIUS IS NOT RESPONSIBLE FOR SUCH CONTENT.
                ANY USE OR RELIANCE ON ANY CONTENT POSTED THROUGH THE SERVICE OR OBTAINED
                BY YOU THROUGH THE SERVICE IS AT YOUR OWN RISK. By using the Service, You
                acknowledge that You have reviewed and agreed to Aquarius’s Privacy Policy
                available on our Website.
            </p>
          <p>
            Any and all data and information generated by the System due to your
                Content shall be made available to You, subject to your compliance with
                these Terms and the Agreement, including but not limited to the payment of
                the Fees.
            </p>
          <p>
            YOU AGREE THAT AQUARIUS MAY COLLECT AND USE TECHNICAL DATA AND RELATED
                INFORMATION, including but not limited to technical information about your
                device, system and application software, and peripherals, that is gathered
                periodically to facilitate the provision of software updates, product
                support and other services to You (if any) related to the Service. Aquarius
                may also collect data related or generated from your use of the Service for
                statistic analysis and usage patterns analysis. Aquarius may use or share
                this information, as long as it is in a form that does not personally
                identify You, to improve its products or to provide services or
                technologies to You.
            </p>
          <p>
            5. <strong><u>Disclaimer</u></strong><strong>. </strong>THE SERVICE IS
                PROVIDED TO YOU ON A “AS IS” BASIS, AND AQUARIUS DISCLAIMS ANY AND ALL
                OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE AND ANY WARRANTY OF NONINFRINGEMENT OF THIRD PARTIES'
                RIGHTS OR THAT IT WILL BE PROVIDED ERROR FREE, WITHOUT INTERRUPTION OR THAT
                THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; AQUARIUS
                SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND REPRESENTATIONS WITH
                RESPECT TO ACCURACY. NO THIRD PARTY, DISTRIBUTOR, RESELLER, AGENT, OR
                EMPLOYEE IS AUTHORIZED TO MAKE ANY MODIFICATIONS, EXTENSIONS, OR ADDITIONS
                TO THIS WARRANTY.<strong><u></u></strong>
          </p>
          <p>
            WITHOUT DEROGATING FROM THE GENERALITY OF THE FOREGOING, IT IS EXPRESSLY
                CLARIFIED THAT THE PRODUCTS, IN CONJUNCTION WITH SERVICES, IS A SUPPORTIVE
                TOOL WITH RESPECT TO THE DETECTION AND MANAGEMENT OF LEAKS IN WATER
                SYSTEMS, AND SHOULD NOT BE RELIED UPON EXCLUSIVELY. THE MANNER BY WHICH ANY
                SPECIFIC LEAK SHOULD BE HANDLED AND/OR REPAIRED IS DEPENDENT ON NUMEROUS
                FACTORS WHICH ARE OUTSIDE OF THE SCOPE OF THE SYSTEM'S CAPACITY AND WHICH
                SHOULD BE SEPARATELY ADDRESSED AND CONSIDERED BY YOU, AT YOUR SOLE
                RESPONSIBILITY. UNDER NO CIRCUMSTANCES WILL AQUARIUS, ITS SHAREHOLDERS OR
                ANY OTHER PARTY ON ITS BEHALF BE LIABLE FOR ANY DAMAGE, LOSS OR EXPENSE
                (INCLUDING PHYSICAL INJURIES) WHICH MAY BE CAUSED TO YOU ANY ONE ACTING ON
                YOUR BEHALF OR TO ANY THIRD PARTY IN CONNECTION WITH THE USE OF OR RELIANCE
                ON THE PRODUCTS OR SERVICES, AS THE CASE MAY BE, INCLUDING WITHOUT
                LIMITATION ANY DAMAGE, EXPENSE OR LOSS CAUSED AS A RESULT OF WATER LEAKS OR
                BURSTS, DAMAGES TO PIPING SYSTEMS AND THE LIKE. IN ADDITION, AQUARIUS MAKES
                NO WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO FAULT-FREE OPERATION OF
                THE SYSTEM AND SHALL NOT BEAR ANY LIABILITY OR RESPONSIBILITY WHATSOEVER
                FOR DAMAGES OR LOSSES CAUSED BY OR ATTRIBUTABLE TO INACCURATE OR ERRONEOUS
                MEASUREMENTS, INCLUDING WITHOUT LIMITATION WHEN THIS RESULTS FROM AN ERROR
                OF THE SYSTEM.
            </p>
          <p>
            UNDER NO CIRCUMSTANCES WHATSOEVER WILL AQUARIUS BE LIABLE IN ANY WAY FOR
                ANY DAMAGE OR LIABILITY ARISING FROM (I) ANY CONTENT (WHETHER PROVIDED BY
                AQUARIUS OR UPLOADED BY YOU), INCLUDING, WITHOUT LIMITATION, FOR ANY ERRORS
                OR OMISSIONS IN ANY CONTENT, OR FOR ANY INFRINGEMENT OF THIRD PARTY'S
                RIGHT, LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
                CONTENT AND/OR THE SERVICE; (II) SYSTEM DEFECTS RESULTING FROM THE USE OF
                THIRD PARTY PRODUCTS OR SERVICES; (III) FAILURE OF COMMUNICATION BY A GSM
                PROVIDER OR ANY OTHER COMMUNICATIONS PROVIDER; OR (IV) THE UNAUTHORIZED OR
                EXCESSIVE USE OF THE SYSTEM OR USE OF THE SYSTEM IN A MANNER INCONSISTENT
                WITH THESE TERMS OR THE AGREEMENT.
            </p>
          <p>
            6. <strong><u>Indemnity</u></strong>. You shall indemnify, defend, and hold
                harmless Aquarius, and its directors, officers, and employees from and
                against all finally awarded costs, damages, losses and expenses, including
                reasonable attorneys’ fees and other legal expenses, due to a third-party
                claim; (i) arising from Your use of the Service; or (ii) alleging that your
                Content infringes any right or interest of such third party. Aquarius shall
                give You prompt notice of any such claim made against it and shall grant
                You sole control of the defense of any such claim, suit or proceeding.    <strong><u> </u></strong><u></u>
          </p>
          <p>
            7. <strong><u>Title &amp; Ownership.</u></strong> The Service is protected
                under copyright law. It may also contain valuable trade secret of Aquarius.
                Any disclosure or unauthorized use thereof will cause irreparable harm and
                loss to Aquarius. All right, title and interest in and to the Service and
                any derivatives thereof and modifications thereto, including associated
                intellectual property rights, evidenced by or embodied in and/or
                attached/connected/related to the Service, are and will remain with
                Aquarius. These Terms and any Agreement do not convey to You an interest in
                or to the Service, but only a limited right of use in accordance with the
                terms herein. Nothing in these Terms or any Agreement constitutes a waiver
                of Aquarius's intellectual property rights under any law.
            </p>
          <p>
            8. <strong><u>Limitation of Liability</u></strong>. IN NO EVENT SHALL
                AQUARIUS’S LIABILITY, UNDER, ARISING OUT OF OR RELATING TO THESE TERMS, THE
                AGREEMENT, THE SERVICES OR THE SYSTEM (EVEN IF AQUARIUS HAS BEEN INFORMED
                OF THE POSSIBILITY OF SUCH DAMAGES), OR FOR ANY CLAIM BY ANY OTHER PARTY,
                EXCEED THE AMOUNTS PAID BY YOU OR THE AUTHORIZED DISTRIBUTOR, AS
                APPLICABLE, TO AQUARIUS DURING THE SIX (6) MONTHS PRECEDING THE DATE OF THE
                OCCURRENCE WHICH GAVE RISE TO THE CLAIM. IN NO EVENT WILL AQUARIUS BE
                LIABLE FOR LOST PROFITS, LOSS OF USE, LOSS OF CONTENT OR DATA, COST OF
                PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER SPECIAL,
                INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, AND ON ANY
                THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING
                NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, WHETHER OR NOT AQUARIUS HAS
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
          <p>
            9. <strong><u>Third Party Software; Open Source</u></strong>.    <strong> </strong>The Services may contain software provided by third
                parties, and such third parties' software is provided “As Is” without any
                warranty of any kind, and subject to the license terms attached to such
                third party software. <strong><u></u></strong>
          </p>
          <p>
            10. <strong><u>Term and Termination</u></strong>. Subject to these Terms,
                the Agreement, and the receipt by Aquarius of the Fees, Aquarius shall
                provide You access to the Services for a period commencing on the date You
                first access the Service and continuing for the term specified in the
                Agreement. Without prejudice to any other rights, these Terms and your
                right to use the Service automatically terminates if You fail to comply
                with any provision of these Terms or if Your Agreement is terminated or
                expires, for any reason whatsoever. All Fees paid are non-refundable in any
                case, except as required by applicable law. If and to the extent
                applicable, upon termination of these Terms for any reason You shall be
                required to complete payment of all amounts or Fees due.    <strong><u> </u></strong>
          </p>
          <p>
            11. <strong><u>General</u></strong>.<strong> </strong>These Terms along
                with Aquarius’ Privacy Policy and the Agreement constitutes the entire
                agreement between the parties and may not be modified except by a written
                agreement signed by Aquarius. If a court of competent jurisdiction finds
                any provision of these Terms to be unenforceable, that provision shall be
                enforced to the maximum extent permissible so as to affect the intent of
                the parties, and the remainder of these Terms shall continue in full force
                and effect. You may not assign these Terms without Aquarius’s prior written
                approval. Aquarius shall be entitled to assign these Terms at its
                discretion. No waiver of any breach shall constitute a waiver of any prior,
                concurrent or subsequent breach of the same or any other provision, and no
                waiver shall be effective unless made in writing and signed by an
                authorized representative of the waiving party. These Terms shall be
                governed by and construed in accordance with the laws of Israel and only
                the competent courts in Tel Aviv, Israel shall have jurisdiction over any
                dispute arising from these Terms. The application of the United Nations
                Convention of Contracts for the International Sale of Goods is expressly
                excluded. Sections I (4)(5)(6)(7)(8)(9)(10)(11), II (3) and III(3) shall
                survive termination of these Terms and Agreement.
            </p>
          <p>
            <strong><u>SECTION II</u></strong>
            <strong>
              -- TERMS APPLICABLE TO END USERS WHO PURCHASE THE SERVICES DIRECTLY
                    FROM AQUARIUS
                </strong>
          </p>
          <p>
            1. <strong><u>Account</u></strong>. In order to use the Service, You
                received log-in details from Aquarius which will allow You to access the
                Service showing only such information applicable to You. You are
                responsible for maintaining the security of your log in information which
                You may amend or change through the Service from time to time. You are
                entirely responsible for all activities that occur under your account/s.
                You agree to notify Aquarius immediately of any unauthorized use of your
                account/s or any other breach of security. Aquarius will not be liable for
                any loss that You may incur as a result of someone else using your password
                or account, either with or without your knowledge.
            </p>
          <p>
            2. <strong><u>Grant of Rights</u></strong>. Subject to the terms and
                conditions herein, including but not limited to the payment of the Fees,
                Aquarius hereby grants to End User, and End User accepts a limited right to
                access the Services and use it in order to identify water leaks in water
                pipes, and to observe, review, analyze and generate reports based on the
                data collected solely for its own business purposes.<strong></strong>
          </p>
          <p>
            3. <strong><u>Indemnification</u></strong>. Aquarius shall defend a third
                party claim brought against a End User that use of the Service in
                accordance with these Terms and the Agreement infringes any duly registered
                third party patent or copyright right ("<strong>Claims</strong>") and shall
                indemnify End User against all damages and liabilities finally awarded
                arising out of such Claims. Aquarius's foregoing defense and indemnity
                obligation is subject to the limitation of liability set forth herein, and
                shall not extend to claims based on (i) unauthorized use, modification, or
                repairs of the Service made by any third party other than Aquarius; (ii)
                the combination of the Service with items not supplied by Aquarius; (iii)
                open source software components; or (iv) Your use of the Service not in
                accordance with Aquarius's instructions or specifications. As a condition
                to Aquarius’s indemnity obligation End User shall give Aquarius prompt
                notice of any such claim, grant Aquarius sole control of the defense and/or
                settlement of any such claim and provide reasonable assistance as requested
                by Aquarius. If the Service or part thereof becomes, or in Aquarius's
                opinion may become, subject to a Claim or End User’s use thereof may be
                otherwise enjoined, Aquarius may, at its option, either: (i) procure for
                End User the right to continue using the Service; (ii) replace or modify
                the Service, so that it is non-infringing; or (iii) if neither of the
                foregoing alternatives is reasonably practical, terminate these Terms and
                the Agreement.
            </p>
          <p>
            4. <strong><u>Support Services</u></strong>. Subject to your full adherence
                to your obligations under your Agreement and these Terms, Aquarius shall
                provide You technical support services in accordance with the terms and
                conditions set forth in the Agreement (the    <strong>"Support Services"</strong>). In the event Your Agreement does not
                make reference to the Support Services, You will not be entitled to receive
                Support Services from Aquarius.
            </p>
          <p>
            <strong><u></u></strong>
          </p>
          <p>
            <strong><u>SECTION III</u></strong>
            <strong>
              -- TERMS APPLICABLE TO AN END USER WHO PURCHASED THE SERVICE THROUGH AN
                    AUTHORIZED DISTRIBUTOR
                </strong>
          </p>
          <p>
            1. <strong><u>Account</u></strong>. In order to use the Service, You
                received log-in details from the Authorized Distributor which will allow
                You to access the Service showing only such information applicable to You.
                You are responsible for maintaining the confidentiality of your password
                and account. You are entirely responsible for all activities that occur
                under your account/s. You agree to notify Aquarius or the Authorized
                Distributor immediately of any unauthorized use of your account/s or any
                other breach of security. Aquarius will not be liable for any loss that You
                may incur as a result of someone else using your password or account,
                either with or without your knowledge.
            </p>
          <p>
            2. <strong><u>Grant of Rights</u></strong>. Subject to the terms and
                conditions herein, including but not limited to the receipt by Aquarius of
                the Fees, Aquarius hereby grants to End User, and End User accepts, limited
                right to access the Service, and use it in order to identify water leaks in
                water pipes, and to observe, review, analyze and generate reports based on
                the data collected solely for its own business purposes. <strong></strong>
          </p>
          <p>
            3. <strong><u>Disclaimer</u></strong>. You acknowledge that in the event
                You purchased the Product or Service from an Authorized Distributor,
                Aquarius shall have no responsibility to actually deliver or otherwise
                provide You with the Product or Services and that any and all liability,
                rights or obligations other than those set out herein shall be solely and
                exclusively between You and the Authorized Distributor. Aquarius shall have
                no liability with regard to any terms other than those set out herein.
            </p>
          <p>
            4. <strong><u>Support Services</u></strong>. Aquarius is under no
                obligation to provide the End User with Support Services. Any and all
                obligations with respect to Support Services shall be by and between the
                End User and such Authorized Distributor. Support Services are subject at
                all times solely to the warranties and disclaimers set forth herein as well
                as the product warranty accompanying the Products purchased for use with
                the Services.
            </p>
          <p>
            5. <strong><u>Relationship with Authorized Distributor</u></strong>. In the
                event the Authorized Distributor is no longer an Authorized Distributor of
                Aquarius, for any reason whatsoever, Aquarius shall be entitled, at its
                sole discretion, to continue to provide You access to the Service, provided
                that You pay Aquarius directly or any third party so designated by Aquarius
                the applicable Fees for the Services in accordance with Aquarius’s then
                applicable pricelist, commencing as of the date on which such Authorized
                Distributor is no longer an Authorized Distributor of Aquarius. For the
                avoidance of doubt, Aquarius shall not be liable or responsible for any
                claim, action, damage or loss incurred by the End User with respect to
                access to the Services in the event that the Authorized Distributor is no
                longer an Authorized Distributor of Aquarius prior to the date of
                Aquarius’s written acceptance of the provision of access to the Services to
                the End User.
            </p>
          <p>
            <strong>
              I HAVE READ AND UNDERSTOOD THESE TERMS AND AGREE TO BE BOUND BY THEM.
                </strong>
          </p>
        </div>),

      singapore: (
        <div className="terms">
          <p align="center">
            <strong><u>TERMS AND CONDITIONS</u></strong>
          </p>
          <p>
            PLEASE READ THESE TERMS AND CONDITIONS (THE “<strong>TERMS</strong>”)
                CAREFULLY BEFORE SIGNING UP TO THE SERVICE/S AND USING THE SYSTEM (AS
                DEFINED BELOW). BY ACCESSING OR USING THE SERVICE YOU (THE "    <strong>END USER</strong>" OR “<strong>YOU</strong>”) ARE ACCEPTING AND
                AGREEING TO BE BOUND BY ALL THE TERMS SET FORTH HEREIN. THESE TERMS ARE
                BETWEEN YOU AND AQUARIUS SPECTRUM LTD ("<strong>AQUARIUS</strong>") ONLY.
                IF YOU DO NOT AGREE TO THESE TERMS DO NOT ACCESS OR USE THE SERVICE IN ANY
                MANNER WHATSOEVER.
            </p>
          <p>
            These Terms have three sections:
            </p>
          <p>
            <strong><u>Section I</u></strong>
            applies to all grants of rights.
            </p>
          <p>
            <strong><u>Section II</u></strong>
            applies if You are an end user who has purchased the System directly from
                Aquarius.
            </p>
          <p>
            <strong><u>Section III</u></strong>
            applies if You are an end user who has purchased the System from an
                authorized distributor of Aquarius (“    <strong>Authorized Distributor</strong>”).
            </p>
          <p>
            <strong><u></u></strong>
          </p>
          <p>
            <strong><u>SECTION I</u></strong>
            <strong>-- TERMS APPLICABLE TO ALL GRANTS OF RIGHTS </strong>
          </p>
          <p>
            1. <strong><u>General</u></strong>. Aquarius has developed an integrated
                system for monitoring water distribution networks enabling acoustic leakage
                detection, comprised of fixed vibration correlating sensors (the “    <strong>Products</strong>”), and cloud-based automatic signal processing
                proprietary software (the “<strong>Service/s</strong>”) (the “    <strong>Products</strong> ” and the “<strong>Service</strong>”,
                collectively, the “<strong>System</strong>”). You have purchased, either
                from Aquarius or through an Authorized Distributor, the Products, which
                have been installed in accordance with Aquarius’s instructions and
                guidelines, and now require access to the Service which may be accessed at
                the following designated website:
                <a href="https://web.sg.aqs-sys.com">https://web.sg.aqs-sys.com</a>
            (the <strong>"Website"</strong>). The Services are provided to You on a
                software as a service basis (SaaS). Aquarius may provide You with certain
                updates and upgrades to the Service as they become generally commercially
                available. These Terms apply to the Service. You agree and acknowledge that
                your use of the Products is permitted solely in conjunction with the
                Service and neither the Service or the Products may be used with any other
                product or service. <strong></strong>
          </p>
          <p>
            2. <strong><u>Fees</u></strong><strong>.</strong> The fees and payment
                terms for the Service shall be determined in accordance with Your agreement
                with Aquarius or, if You purchased a right to access the Service through an
                Authorized Distributor, as determined in the agreement by and between You
                and such Authorized Distributor (each an “<strong>Agreement</strong>”), or
                if not covered in the Agreement, in accordance with the then applicable
                pricelist of Aquarius (the “<strong>Fees</strong>”).
            </p>
          <p>
            <a name="_Ref412985585">
              Any failure to make payment of the Fees to Aquarius, by the End User or
                    by an Authorized Distributor on behalf of such End User, as applicable,
                    or breach of the payment terms shall entitle Aquarius to immediately
                    terminate or suspend access to the Service (either temporarily or
                    indefinitely) or, if applicable, directly engage with the End User for
                    the purpose of allowing the End User to continue to receive the
                    Services directly from Aquarius.
                </a>
          </p>
          <p>
            3. <strong><u>Limitations</u></strong>. Other than the rights explicitly
                granted in these Terms, You shall have no other rights, express or implied,
                in or to the Service. Without limiting the generality of the foregoing, You
                agree and undertake not to, and to not permit any third party to; (i) sell,
                lease or distribute the Service, or any part thereof, or otherwise transfer
                any right to the Service; (ii) reverse engineer, decompile, disassemble, or
                otherwise reduce to human-perceivable form the Service’s source code and or
                any third party software provided by Aquarius; (iii) modify, revise,
                enhance, or alter the Service; (iv) copy or allow copies of the Service to
                be made; (v) place the Service onto a server accessible for use by any
                third party; (vi) use the Service on any device that You do not own or
                control, and You may not distribute or make the Service available over a
                network where it could be used by multiple devices at the same time except
                as required for its proper use and operation; (vii) represent that You
                possess any proprietary interest in the Service; or (viii) use the Service
                in any illegal manner or for unlawful purposes.
            </p>
          <p>
            4. <strong><u>Consent to Use of Data</u></strong>. The Service enables You
                to add, create or use certain content which You provide or which is
                generated through your use of the Service, including but not limited to
                maps, photos, flags about leaks, accessories installed, and reports (the “    <strong>Content</strong>”). AQUARIUS IS NOT RESPONSIBLE FOR SUCH CONTENT.
                ANY USE OR RELIANCE ON ANY CONTENT POSTED THROUGH THE SERVICE OR OBTAINED
                BY YOU THROUGH THE SERVICE IS AT YOUR OWN RISK. By using the Service, You
                acknowledge that You have reviewed and agreed to Aquarius’s Privacy Policy
                available on our Website.
            </p>
          <p>
            Any and all data and information generated by the System due to your
                Content shall be made available to You, subject to your compliance with
                these Terms and the Agreement, including but not limited to the payment of
                the Fees.
            </p>
          <p>
            YOU AGREE THAT AQUARIUS MAY COLLECT AND USE TECHNICAL DATA AND RELATED
                INFORMATION, including but not limited to technical information about your
                device, system and application software, and peripherals, that is gathered
                periodically to facilitate the provision of software updates, product
                support and other services to You (if any) related to the Service. Aquarius
                may also collect data related or generated from your use of the Service for
                statistic analysis and usage patterns analysis. Aquarius may use or share
                this information, as long as it is in a form that does not personally
                identify You, to improve its products or to provide services or
                technologies to You.
            </p>
          <p>
            5. <strong><u>Disclaimer</u></strong><strong>. </strong>THE SERVICE IS
                PROVIDED TO YOU ON A “AS IS” BASIS, AND AQUARIUS DISCLAIMS ANY AND ALL
                OTHER WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT
                LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE AND ANY WARRANTY OF NONINFRINGEMENT OF THIRD PARTIES'
                RIGHTS OR THAT IT WILL BE PROVIDED ERROR FREE, WITHOUT INTERRUPTION OR THAT
                THE SERVICES WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS; AQUARIUS
                SPECIFICALLY DISCLAIMS ANY AND ALL WARRANTIES AND REPRESENTATIONS WITH
                RESPECT TO ACCURACY. NO THIRD PARTY, DISTRIBUTOR, RESELLER, AGENT, OR
                EMPLOYEE IS AUTHORIZED TO MAKE ANY MODIFICATIONS, EXTENSIONS, OR ADDITIONS
                TO THIS WARRANTY.<strong><u></u></strong>
          </p>
          <p>
            WITHOUT DEROGATING FROM THE GENERALITY OF THE FOREGOING, IT IS EXPRESSLY
                CLARIFIED THAT THE PRODUCTS, IN CONJUNCTION WITH SERVICES, IS A SUPPORTIVE
                TOOL WITH RESPECT TO THE DETECTION AND MANAGEMENT OF LEAKS IN WATER
                SYSTEMS, AND SHOULD NOT BE RELIED UPON EXCLUSIVELY. THE MANNER BY WHICH ANY
                SPECIFIC LEAK SHOULD BE HANDLED AND/OR REPAIRED IS DEPENDENT ON NUMEROUS
                FACTORS WHICH ARE OUTSIDE OF THE SCOPE OF THE SYSTEM'S CAPACITY AND WHICH
                SHOULD BE SEPARATELY ADDRESSED AND CONSIDERED BY YOU, AT YOUR SOLE
                RESPONSIBILITY. UNDER NO CIRCUMSTANCES WILL AQUARIUS, ITS SHAREHOLDERS OR
                ANY OTHER PARTY ON ITS BEHALF BE LIABLE FOR ANY DAMAGE, LOSS OR EXPENSE
                (INCLUDING PHYSICAL INJURIES) WHICH MAY BE CAUSED TO YOU ANY ONE ACTING ON
                YOUR BEHALF OR TO ANY THIRD PARTY IN CONNECTION WITH THE USE OF OR RELIANCE
                ON THE PRODUCTS OR SERVICES, AS THE CASE MAY BE, INCLUDING WITHOUT
                LIMITATION ANY DAMAGE, EXPENSE OR LOSS CAUSED AS A RESULT OF WATER LEAKS OR
                BURSTS, DAMAGES TO PIPING SYSTEMS AND THE LIKE. IN ADDITION, AQUARIUS MAKES
                NO WARRANTY, EXPRESS OR IMPLIED, WITH RESPECT TO FAULT-FREE OPERATION OF
                THE SYSTEM AND SHALL NOT BEAR ANY LIABILITY OR RESPONSIBILITY WHATSOEVER
                FOR DAMAGES OR LOSSES CAUSED BY OR ATTRIBUTABLE TO INACCURATE OR ERRONEOUS
                MEASUREMENTS, INCLUDING WITHOUT LIMITATION WHEN THIS RESULTS FROM AN ERROR
                OF THE SYSTEM.
            </p>
          <p>
            UNDER NO CIRCUMSTANCES WHATSOEVER WILL AQUARIUS BE LIABLE IN ANY WAY FOR
                ANY DAMAGE OR LIABILITY ARISING FROM (I) ANY CONTENT (WHETHER PROVIDED BY
                AQUARIUS OR UPLOADED BY YOU), INCLUDING, WITHOUT LIMITATION, FOR ANY ERRORS
                OR OMISSIONS IN ANY CONTENT, OR FOR ANY INFRINGEMENT OF THIRD PARTY'S
                RIGHT, LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE
                CONTENT AND/OR THE SERVICE; (II) SYSTEM DEFECTS RESULTING FROM THE USE OF
                THIRD PARTY PRODUCTS OR SERVICES; (III) FAILURE OF COMMUNICATION BY A GSM
                PROVIDER OR ANY OTHER COMMUNICATIONS PROVIDER; OR (IV) THE UNAUTHORIZED OR
                EXCESSIVE USE OF THE SYSTEM OR USE OF THE SYSTEM IN A MANNER INCONSISTENT
                WITH THESE TERMS OR THE AGREEMENT.
            </p>
          <p>
            6. <strong><u>Indemnity</u></strong>. You shall indemnify, defend, and hold
                harmless Aquarius, and its directors, officers, and employees from and
                against all finally awarded costs, damages, losses and expenses, including
                reasonable attorneys’ fees and other legal expenses, due to a third-party
                claim; (i) arising from Your use of the Service; or (ii) alleging that your
                Content infringes any right or interest of such third party. Aquarius shall
                give You prompt notice of any such claim made against it and shall grant
                You sole control of the defense of any such claim, suit or proceeding.    <strong><u> </u></strong><u></u>
          </p>
          <p>
            7. <strong><u>Title &amp; Ownership.</u></strong> The Service is protected
                under copyright law. It may also contain valuable trade secret of Aquarius.
                Any disclosure or unauthorized use thereof will cause irreparable harm and
                loss to Aquarius. All right, title and interest in and to the Service and
                any derivatives thereof and modifications thereto, including associated
                intellectual property rights, evidenced by or embodied in and/or
                attached/connected/related to the Service, are and will remain with
                Aquarius. These Terms and any Agreement do not convey to You an interest in
                or to the Service, but only a limited right of use in accordance with the
                terms herein. Nothing in these Terms or any Agreement constitutes a waiver
                of Aquarius's intellectual property rights under any law.
            </p>
          <p>
            8. <strong><u>Limitation of Liability</u></strong>. IN NO EVENT SHALL
                AQUARIUS’S LIABILITY, UNDER, ARISING OUT OF OR RELATING TO THESE TERMS, THE
                AGREEMENT, THE SERVICES OR THE SYSTEM (EVEN IF AQUARIUS HAS BEEN INFORMED
                OF THE POSSIBILITY OF SUCH DAMAGES), OR FOR ANY CLAIM BY ANY OTHER PARTY,
                EXCEED THE AMOUNTS PAID BY YOU OR THE AUTHORIZED DISTRIBUTOR, AS
                APPLICABLE, TO AQUARIUS DURING THE SIX (6) MONTHS PRECEDING THE DATE OF THE
                OCCURRENCE WHICH GAVE RISE TO THE CLAIM. IN NO EVENT WILL AQUARIUS BE
                LIABLE FOR LOST PROFITS, LOSS OF USE, LOSS OF CONTENT OR DATA, COST OF
                PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR ANY OTHER SPECIAL,
                INCIDENTAL, INDIRECT, OR CONSEQUENTIAL DAMAGES, HOWEVER CAUSED, AND ON ANY
                THEORY OF LIABILITY, WHETHER FOR BREACH OF CONTRACT, TORT (INCLUDING
                NEGLIGENCE AND STRICT LIABILITY), OR OTHERWISE, WHETHER OR NOT AQUARIUS HAS
                BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </p>
          <p>
            9. <strong><u>Third Party Software; Open Source</u></strong>.    <strong> </strong>The Services may contain software provided by third
                parties, and such third parties' software is provided “As Is” without any
                warranty of any kind, and subject to the license terms attached to such
                third party software. <strong><u></u></strong>
          </p>
          <p>
            10. <strong><u>Term and Termination</u></strong>. Subject to these Terms,
                the Agreement, and the receipt by Aquarius of the Fees, Aquarius shall
                provide You access to the Services for a period commencing on the date You
                first access the Service and continuing for the term specified in the
                Agreement. Without prejudice to any other rights, these Terms and your
                right to use the Service automatically terminates if You fail to comply
                with any provision of these Terms or if Your Agreement is terminated or
                expires, for any reason whatsoever. All Fees paid are non-refundable in any
                case, except as required by applicable law. If and to the extent
                applicable, upon termination of these Terms for any reason You shall be
                required to complete payment of all amounts or Fees due.    <strong><u> </u></strong>
          </p>
          <p>
            11. <strong><u>General</u></strong>.<strong> </strong>These Terms along
                with Aquarius’ Privacy Policy and the Agreement constitutes the entire
                agreement between the parties and may not be modified except by a written
                agreement signed by Aquarius. If a court of competent jurisdiction finds
                any provision of these Terms to be unenforceable, that provision shall be
                enforced to the maximum extent permissible so as to affect the intent of
                the parties, and the remainder of these Terms shall continue in full force
                and effect. You may not assign these Terms without Aquarius’s prior written
                approval. Aquarius shall be entitled to assign these Terms at its
                discretion. No waiver of any breach shall constitute a waiver of any prior,
                concurrent or subsequent breach of the same or any other provision, and no
                waiver shall be effective unless made in writing and signed by an
                authorized representative of the waiving party. These Terms shall be
                governed by and construed in accordance with the laws of Israel and only
                the competent courts in Tel Aviv, Israel shall have jurisdiction over any
                dispute arising from these Terms. The application of the United Nations
                Convention of Contracts for the International Sale of Goods is expressly
                excluded. Sections I (4)(5)(6)(7)(8)(9)(10)(11), II (3) and III(3) shall
                survive termination of these Terms and Agreement.
            </p>
          <p>
            <strong><u>SECTION II</u></strong>
            <strong>
              -- TERMS APPLICABLE TO END USERS WHO PURCHASE THE SERVICES DIRECTLY
                    FROM AQUARIUS
                </strong>
          </p>
          <p>
            1. <strong><u>Account</u></strong>. In order to use the Service, You
                received log-in details from Aquarius which will allow You to access the
                Service showing only such information applicable to You. You are
                responsible for maintaining the security of your log in information which
                You may amend or change through the Service from time to time. You are
                entirely responsible for all activities that occur under your account/s.
                You agree to notify Aquarius immediately of any unauthorized use of your
                account/s or any other breach of security. Aquarius will not be liable for
                any loss that You may incur as a result of someone else using your password
                or account, either with or without your knowledge.
            </p>
          <p>
            2. <strong><u>Grant of Rights</u></strong>. Subject to the terms and
                conditions herein, including but not limited to the payment of the Fees,
                Aquarius hereby grants to End User, and End User accepts a limited right to
                access the Services and use it in order to identify water leaks in water
                pipes, and to observe, review, analyze and generate reports based on the
                data collected solely for its own business purposes.<strong></strong>
          </p>
          <p>
            3. <strong><u>Indemnification</u></strong>. Aquarius shall defend a third
                party claim brought against a End User that use of the Service in
                accordance with these Terms and the Agreement infringes any duly registered
                third party patent or copyright right ("<strong>Claims</strong>") and shall
                indemnify End User against all damages and liabilities finally awarded
                arising out of such Claims. Aquarius's foregoing defense and indemnity
                obligation is subject to the limitation of liability set forth herein, and
                shall not extend to claims based on (i) unauthorized use, modification, or
                repairs of the Service made by any third party other than Aquarius; (ii)
                the combination of the Service with items not supplied by Aquarius; (iii)
                open source software components; or (iv) Your use of the Service not in
                accordance with Aquarius's instructions or specifications. As a condition
                to Aquarius’s indemnity obligation End User shall give Aquarius prompt
                notice of any such claim, grant Aquarius sole control of the defense and/or
                settlement of any such claim and provide reasonable assistance as requested
                by Aquarius. If the Service or part thereof becomes, or in Aquarius's
                opinion may become, subject to a Claim or End User’s use thereof may be
                otherwise enjoined, Aquarius may, at its option, either: (i) procure for
                End User the right to continue using the Service; (ii) replace or modify
                the Service, so that it is non-infringing; or (iii) if neither of the
                foregoing alternatives is reasonably practical, terminate these Terms and
                the Agreement.
            </p>
          <p>
            4. <strong><u>Support Services</u></strong>. Subject to your full adherence
                to your obligations under your Agreement and these Terms, Aquarius shall
                provide You technical support services in accordance with the terms and
                conditions set forth in the Agreement (the    <strong>"Support Services"</strong>). In the event Your Agreement does not
                make reference to the Support Services, You will not be entitled to receive
                Support Services from Aquarius.
            </p>
          <p>
            <strong><u></u></strong>
          </p>
          <p>
            <strong><u>SECTION III</u></strong>
            <strong>
              -- TERMS APPLICABLE TO AN END USER WHO PURCHASED THE SERVICE THROUGH AN
                    AUTHORIZED DISTRIBUTOR
                </strong>
          </p>
          <p>
            1. <strong><u>Account</u></strong>. In order to use the Service, You
                received log-in details from the Authorized Distributor which will allow
                You to access the Service showing only such information applicable to You.
                You are responsible for maintaining the confidentiality of your password
                and account. You are entirely responsible for all activities that occur
                under your account/s. You agree to notify Aquarius or the Authorized
                Distributor immediately of any unauthorized use of your account/s or any
                other breach of security. Aquarius will not be liable for any loss that You
                may incur as a result of someone else using your password or account,
                either with or without your knowledge.
            </p>
          <p>
            2. <strong><u>Grant of Rights</u></strong>. Subject to the terms and
                conditions herein, including but not limited to the receipt by Aquarius of
                the Fees, Aquarius hereby grants to End User, and End User accepts, limited
                right to access the Service, and use it in order to identify water leaks in
                water pipes, and to observe, review, analyze and generate reports based on
                the data collected solely for its own business purposes. <strong></strong>
          </p>
          <p>
            3. <strong><u>Disclaimer</u></strong>. You acknowledge that in the event
                You purchased the Product or Service from an Authorized Distributor,
                Aquarius shall have no responsibility to actually deliver or otherwise
                provide You with the Product or Services and that any and all liability,
                rights or obligations other than those set out herein shall be solely and
                exclusively between You and the Authorized Distributor. Aquarius shall have
                no liability with regard to any terms other than those set out herein.
            </p>
          <p>
            4. <strong><u>Support Services</u></strong>. Aquarius is under no
                obligation to provide the End User with Support Services. Any and all
                obligations with respect to Support Services shall be by and between the
                End User and such Authorized Distributor. Support Services are subject at
                all times solely to the warranties and disclaimers set forth herein as well
                as the product warranty accompanying the Products purchased for use with
                the Services.
            </p>
          <p>
            5. <strong><u>Relationship with Authorized Distributor</u></strong>. In the
                event the Authorized Distributor is no longer an Authorized Distributor of
                Aquarius, for any reason whatsoever, Aquarius shall be entitled, at its
                sole discretion, to continue to provide You access to the Service, provided
                that You pay Aquarius directly or any third party so designated by Aquarius
                the applicable Fees for the Services in accordance with Aquarius’s then
                applicable pricelist, commencing as of the date on which such Authorized
                Distributor is no longer an Authorized Distributor of Aquarius. For the
                avoidance of doubt, Aquarius shall not be liable or responsible for any
                claim, action, damage or loss incurred by the End User with respect to
                access to the Services in the event that the Authorized Distributor is no
                longer an Authorized Distributor of Aquarius prior to the date of
                Aquarius’s written acceptance of the provision of access to the Services to
                the End User.
            </p>
          <p>
            <strong>
              I HAVE READ AND UNDERSTOOD THESE TERMS AND AGREE TO BE BOUND BY THEM.
                </strong>
          </p>
        </div>),
    };

    return content[SYSTEM_RESOURCE];
  }
}

export default TermsAndConditions;
