import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import { Select, Input } from '../../components';
const classNames = require('classnames');

require('./SensorsSettings.scss');

class SensorsSettings extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, uiInfo, optionList, toggleSettingsDisplay } = this.props;
    const panelClasses = classNames(
      'sensors-settings',
      'alerts-settings',
      { 'show': this.props.showSettings }
    );

    const panelStyle = { height: uiInfo.pageHeight, width: 0.395 * (uiInfo.pageWidth - 10) };

    // const normalizeRatio = (value, previousValue, allValues) => value >= 0 && value <= 1 ? value : previousValue

    const statusOptions = [
      // ToDo: get list from DB
      { value: 'Not Installed', label: this.context.t('Uninstalled') },
      { value: 'Ready', label: this.context.t('Ready') },
      { value: 'Active', label: this.context.t('Active') },
      { value: 'Non Active', label: this.context.t('Inactive') },
    ];

    // if (this.props.user.advancedMapIndications) {
    //   statusOptions.push({ value: 'Fragmented', label: this.context.t('Fragmented File') })
    //   statusOptions.push({ value: 'Resets', label: this.context.t('Resets') })
    // }

    return (
      <div className={panelClasses} style={panelStyle}>
        <div className="sensors-header alerts-header">
          <div className="actions">
            <i className="icon-close" onClick={toggleSettingsDisplay} />
          </div>
          <h2>{this.context.t('Sensors Options')}</h2>
        </div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <fieldset>
            <legend>{this.context.t('Sort by')}</legend>
            <div className="form-group">
              <div className="col-xs-8">
                <Field name="Sensors.sortBy"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={false}
                      options={[
                        { value: 'id', label: this.context.t('Sensor id (default)') },
                        { value: 'SensorStatus', label: this.context.t('Status (default)') },
                        { value: 'DaysInactive', label: this.context.t('Days Inactive') },
                        { value: 'DeviceID', label: this.context.t('Device ID') },
                      ]}
                    />)
                  }
                />
              </div>
              <div className="col-xs-4">
                <Field name="Sensors.sortByDir"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={false}
                      backspaceRemoves={false}
                      options={[
                        { value: 'asc', label: this.context.t('ASC') },
                        { value: 'desc', label: this.context.t('DESC') },
                      ]}
                    />)
                  }
                />
              </div>
            </div>
            <legend>{this.context.t('Sensors Filters')}</legend>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Status')}</label>
              <div className="col-xs-8">
                <Field name="Sensors.SensorStatus"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={statusOptions}
                    />)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Sensor Type')}</label>
              <div className="col-xs-8">
                <Field name="Sensors.SensorTypeValue"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={optionList.SensorType}
                    />)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Above / Under')}</label>
              <div className="col-xs-8">
                <Field name="Sensors.isUnderground"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={[
                        { value: '0', label: this.context.t('Above') },
                        { value: '1', label: this.context.t('Under') },
                      ]}
                    />)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Sync Method')}</label>
              <div className="col-xs-8">
                <Field name="Sensors.SyncMethodValue"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={optionList.DeviceTypeSyncMethod}
                    />)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Device Generation')}</label>
              <div className="col-xs-8">
                <Field name="Sensors.DeviceGenerationValue"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={optionList.DeviceGenerationType}
                    />)
                  }
                />
              </div>
            </div>
            { this.props.user.advancedMapIndications &&
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('Last Uninstall Reason')}</label>
                <div className="col-xs-8">
                  <Field name="Sensors.LastUninstallReason"
                    component={props =>
                      (<Select {...props}
                        placeholderTitle={this.context.t('Select...')}
                        clearable
                        multi
                        closeMenuOnSelect={false}
                        options={optionList.DeviceMaintenance}
                      />)
                    }
                  />
                </div>
              </div>
            }
            { this.props.user.advancedMapIndications &&
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('Fragmented File')}</label>
                <div className="col-xs-8">
                  <Field
                    name="Sensors.Fragmented"
                    component={Input}
                    type='checkbox'
                  />
                </div>
              </div>
            }
            { this.props.user.advancedMapIndications &&
              <div className="form-group">
                <label className="col-xs-4 control-label">{this.context.t('Resets')}</label>
                <div className="col-xs-8">
                  <Field name="Sensors.Resets"
                    component={Input}
                    type='checkbox'
                  />
                </div>
              </div>
            }

            <legend>{this.context.t('SOPs Filters')}</legend>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('SOP Type')}</label>
              <div className="col-xs-8">
                <Field name="SOPs.AvizarCode"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={optionList.AqsCode}
                    />)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('SOP Status')}</label>
              <div className="col-xs-8">
                <Field name="SOPs.State"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={optionList.SopStatuses}
                    />)
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Above / Under')}</label>
              <div className="col-xs-8">
                <Field name="SOPs.isUnderGround"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable
                      multi
                      closeMenuOnSelect={false}
                      options={[
                        { value: '0', label: this.context.t('Above') },
                        { value: '1', label: this.context.t('Under') },
                      ]}
                    />)
                  }
                />
              </div>
            </div>

            {/*
         <div className="form-group">
         <label className="col-xs-4 control-label">{this.context.t('Decive Type')}</label>
         <div className="col-xs-8">
         <Field name="DeviceType"
         component={props =>
         <Select {...props}
         clearable={true}
         multi={true}
         options={optionList.DeviceType}
         />
         }
         />
         </div>
         </div>

            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Days Inactive')}</label>
              <div className="col-xs-2 control-numeric-opr">
                <Field name="daysInactiveOpr" component={OprSelect} />
              </div>
              <div className="col-xs-6">
                <Field name="daysInactive" component="input" type="number" className="form-control"/>
              </div>
            </div>

            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Comm Ratio')}</label>
              <div className="col-xs-2 control-numeric-opr">
                <Field name="CommunicationOKratioOpr" component={OprSelect} />
              </div>
              <div className="col-xs-6">
                <Field name="CommunicationOKratio" component="input" type="number" className="form-control"/>
              </div>
            </div>

            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('GPS Ratio')}</label>
              <div className="col-xs-2 control-numeric-opr">
                <Field name="gPSstatusRatioOpr" component={OprSelect} />
              </div>
              <div className="col-xs-6">
                <Field name="gPSstatusRatio" component="input" type="number" className="form-control"/>
              </div>
            </div>

            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Device Installation')}</label>
              <div className="col-xs-3 control-date-form">
                <Field name="SensorInstallationDate-From" component="input" type="date" className="form-control"/>
              </div>
              <div className="col-xs-1 control-label"> {this.context.t('To')}</div>
              <div className="col-xs-3 control-date-to">
                <Field name="SensorInstallationDate-To" component="input" type="date" className="form-control"/>
              </div>
            </div>
      */}

            <div className="text-right">
              <button className="btn btn-success" type="submit" disabled={pristine || submitting} onClick={toggleSettingsDisplay} >{this.context.t('Save & Apply')}</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

SensorsSettings.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const sensorsSettings = reduxForm({
  form: 'sensors-settings', // a unique name for this form
  enableReinitialize: true
})(SensorsSettings);

export default sensorsSettings;
