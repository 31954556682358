import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';

require('./CommentField.scss');

export default class CommentField extends React.Component {

  constructor(props) {
    super(props);

    this.changeTextDir = this.changeTextDir.bind(this);
    this.editCommentClick = this.editCommentClick.bind(this);
    this.copyCommentClick = this.copyCommentClick.bind(this);

    this.state = {
      textDir: "ltr",
      editComment: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.featureID !== nextProps.featureID) {
      this.setState({
        editComment: false,
      })
    }
  }

  changeTextDir(dir) {
    this.setState({
      textDir: dir,
    });
  }

  editCommentClick() {
    this.setState({
      editComment: true,
    });
    this.props.onEdit();
  }

  copyCommentClick() {
    this.props.onCopy();
  }

  render() {
    const { fieldName, placeholder, disabled } = this.props;
    return (
      <div className="comment-area">
        <a
          className="btn btn-info"
          onClick={this.editCommentClick}>
          <i />
        </a>
        <div className="col-xs-12 text-dir-button">
            <Field
              name={fieldName}
              dir={this.state.textDir}
              component="textarea"
              placeholder={placeholder}
              maxLength={225}
              className="form-control"
              disabled={disabled || !this.state.editComment} />
        </div>
        <div className="text-dir-button">
          <img src={require('./../../../images/ltr_icon.png')} className="direction-button" onClick={() => { this.changeTextDir("ltr") }} />
          <img src={require('./../../../images/rtl_icon.png')} className="direction-button" onClick={() => { this.changeTextDir("rtl") }} />
        </div>
        <div className="text-dir-button">
          <img src={require('./../../../images/copy_icon.png')} className="copy-button" onClick={ this.copyCommentClick } />
          <img src={require('./../../../images/blank_icon_20px.png')} className="blank-button-20px" />
        </div>
      </div>
    );
  }
}
