import React from 'react';
import PropTypes from 'prop-types';
import Favicon from 'react-favicon';

import * as misc from '../../constants/Misc';
import { SYSTEM_RESOURCE } from '../../constants/EndPoints';

import { connect } from 'react-redux';

import auth from '../../services/appAuth';

import LinearProgress from '@mui/material/LinearProgress';

import SignIn from '../../components/Login/SignIn';
import VerifyNewPassword from '../../components/Login/VerifyNewPassword';
import ResetPasswrd from '../../components/Login/ResetPasswrd';

import { doLoginIfNeeded } from '../../actions/LoginActions';
import './Login.scss';

function CLogin(props, context) {
  const [mode, setMode] = React.useState('signIn');
  const [additionalData, setAdditionalData] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);

  const sys = SYSTEM_RESOURCE;

  function changeMode(newMode, additional) {
    setMode(newMode);
    if (additional) {
      setAdditionalData(additional);
    }
  }

  return (
    <section className="page login-page">
      <Favicon url={require(`./../../../images/system/${sys}/favicon.ico`)} />

      <div className="main">
        <div className='login-form form-horizontal'>
          <header>
            <div className="logo">{misc.system.companyName}</div>
            <div className="version">Ver. <strong>{misc.versionNumber}</strong></div>
          </header>

          { isLoading && <LinearProgress style={{ position: 'sticky' }}/> }

          { mode === 'signIn' &&
            <SignIn
              signInFunc={props.signIn}
              resetPassword={changeMode}
              loading={isLoading}
              setLoading={setLoading}
            />
          }

          { mode === 'resetPassword' &&
            <ResetPasswrd
              backTo={changeMode}
              loading={isLoading}
              setLoading={setLoading}
              signInFunc={props.signIn}
            />
          }

          { mode === 'newPasswordRequired' &&
            <VerifyNewPassword
              loginData={additionalData}
              changeNewPassword={props.changeNewPassword}
              setLoading={setLoading}
            />
          }

          <footer className="form-group">
            <div className="control-label" style={{ textAlign: 'center' }}>
              <span>
                {context.t('By clicking "Login", you agree to our')}
              </span>
              <a
                style={{ marginLeft: '5px', marginRight: '5px' }}
                href={`${process.env.PUBLIC_URL}/certificates/Terms_and_Conditons.pdf`}
                target="_blank" rel="noopener noreferrer">
                  {context.t('Terms And Conditions')}
              </a>
              <span>
                {context.t('and our')}
              </span>
              <a
                style={{ marginLeft: '5px' }}
                href={`${process.env.PUBLIC_URL}/certificates/Privacy_Policy.pdf`}
                target="_blank" rel="noopener noreferrer">
                  {context.t('Privacy Policy')}
              </a>
            </div>
          </footer>
        </div>

      </div>
    </section>
  );
}

CLogin.contextTypes = {
  t: PropTypes.func.isRequired
};

CLogin.propTypes = {
  changeNewPassword: PropTypes.func.isRequired,
  signIn: PropTypes.func.isRequired,
};

/*
 *
 */
function mapDispatchToProps(dispatch, props) {
  return {
    ...props,
    signIn: (email, password, callback) => {
      dispatch(doLoginIfNeeded(email, password, (err) => {
        auth.signInWithEmailAndPassword(email, password)
        .then((data) => {
          if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            callback(data);
          }
        })
        .catch((error) => {
          const err = new Error(error.message);
          callback(err);
        });
      }));
    },

    changeNewPassword: (email, password, newPassword, callback) => {
      auth.loginSetNewPassword(email, password, newPassword)
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        const err = new Error(error.message);
        callback(err);
      });
    }
  };
}

const cLogin = connect(null, mapDispatchToProps)(CLogin);

export default cLogin;
