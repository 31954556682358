import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
import { selectSensor, fetchSensorsIfNeeded } from "./SensorsActions";
import { selectFeature } from './GlobalsActions';
// import fetch from 'isomorphic-fetch';//--------------------------------

export const selectSOP = (sop) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    const sopID = sop.id;

    dispatch(fetchSensorDetailsBySOP(sopID));
    dispatch(selectFeature(selectedProject, sopID, 'sop'));
  };
};

// Receive & Request
//--------------------------------

function receiveSops(project, sops, sopsIcons, indexMap) {
    return {
        type: types.RECEIVE_SOPS,
        project,
        sops,
        sopsIcons,
        indexMap
    };
}

function requestSops(project) {
    return {
        type: types.REQUEST_SOPS,
        project
    };
}

//--------------------------------
// Filters & Sort
//--------------------------------

export function setSopsFilters(filters) {
    return {
        type: types.SET_SOPS_FILTERS,
        filters
    };
}

export function setSopsDefFilters(filters) {
    return {
        type: types.SET_SOPS_DEF_FILTERS,
        filters
    };
}

//--------------------------------
// Fetch
//--------------------------------

export function sortSOPs(field, dir) {
  return (dispatch, getState) => {
    const state = getState();
    const defFilters = state.leaksList.filters.sops.filters;
    const selectedProject = state.leaksList.selectedProject;

    dispatch(setSOPsSortDetails(field, dir));
    const sort = { field, dir };
    //dispatch(setSopsFilters(Object.assign({}, { Sensors: { sortBy: field, sortByDir: dir, SensorStatus: ['Ready', 'Active', 'Non Active'] } }, defFilters)));
    dispatch(fetchSOPs(selectedProject, defFilters, sort));
  }
}

const setSOPsSortDetails = (field, dir) => {
  return {
    type: types.SORT_SOPS,
    field,
    dir
  }
}

export function fetchSOPsIfNeeded(project) {
  return (dispatch, getState) => {
    if (shouldFetchSOPs(getState(), project)) {
      const state = getState();
      const filters = state.leaksList.filters;
      const sort = filters.sops.sort;
      const sopsFilters = filters.sops.filters;

      return dispatch(fetchSOPs(project, sopsFilters, sort));
    }
  };
}

function shouldFetchSOPs(state, project) {
    const sops = state.leaksList.leaksByProject[project].sops
    if (!sops) {
        return true
    }
    return !sops.isFetching;

}

function fetchSOPs(project, filters, sort) {
  const sortQuery = (sort != null) ? JSON.stringify(sort) : '';
  let filtersQuery = '';
  if (filters) {
    const formatedFilters = filters.map((filter) => ({
      field: filter.field,
      value: filter.value
    }));
    filtersQuery = JSON.stringify(formatedFilters);
  }

  let queryParams = '';
  if (filtersQuery != null || sortQuery != null) {
    let queryChar = ''; // set to '&' if filters not null.
    queryParams = '?';
    if (filtersQuery != '') {
      queryParams += 'filters=' + filtersQuery;
      queryChar = '&';
    }
    if (sortQuery != '') {
      queryParams += queryChar + 'sort=' + sortQuery;
    }
  }

    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.SOPS_ENDPOINT + queryParams;

    return (dispatch, getState) => {
        dispatch(requestSops(project));
        return fetchMiddleware(path, {}, getState).then((json) => {
          if (!json.status) {
            return [ [], {}, [], {}];
          } else {
            return [json.data, getIconFeatures('sops', json.data, getState().leaksList.optionList.options.SopStatuses), setIndexes(json.data, 'id')];
          }
        }).then((args) => dispatch(receiveSops(project, ...args)));
    };
}

export const createSOP = (values, cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const project = state.leaksList.selectedProject;

    const path = endPoints.INSTALL_ENDPOINT + "/sop/" + project;
    dispatch(saveSopData(path, 'POST', values, cb));
  }
}

export const updateSOP = (values, cb) => {
  return (dispatch, getState) => {
    const state = getState();
    const project = state.leaksList.selectedProject;
    const selectedFeatureType = state.leaksList.leaksByProject.selectedFeatureType;
    const selectedFeatureId = state.leaksList.leaksByProject.selectedFeature;
    let sopId;
    if (selectedFeatureType == 'sop') {
      sopId = selectedFeatureId;
    } else if (selectedFeatureType == 'sensor') {
      const sensors = state.leaksList.leaksByProject[project].sensors;
      const sensorItem = sensors.sensors[sensors.indexMap[selectedFeatureId]];
      sopId = sensorItem.SOPointID;
    } else {
      sopId = 0;
    }

    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/id/" + sopId + "/sop";
    dispatch(saveSopData(path, 'PUT', values, cb)).then(() => {
      // if state has changed - reload sensors too (for stolen icon to be shown or hiden)
      const isStateChanged = values.find(val => val.field === 'State');
      if (isStateChanged) {
        dispatch(fetchSensorsIfNeeded(project, true));
      }
    });
  };
};

const saveSopData = (path, method, values, cb) => {
  return (dispatch, getState) => {
    //@TODO: Check errors.
    const state = getState();
    const project = state.leaksList.selectedProject;
    const selectedFeatureType = state.leaksList.leaksByProject.selectedFeatureType;
    const selectedFeatureId = state.leaksList.leaksByProject.selectedFeature;
    let sopId;
    if (selectedFeatureType == 'sop') {
      sopId = selectedFeatureId;
    } else if (selectedFeatureType == 'sensor') {
      const sensors = state.leaksList.leaksByProject[project].sensors;
      const sensorItem = sensors.sensors[sensors.indexMap[selectedFeatureId]];
      sopId = sensorItem.SOPointID;
    } else {
      sopId = 0;
    }

    dispatch(updateSopReq(project, sopId, values));
    dispatch(selectFeature(project, '', ''));

    // set defaults for simplify the creation process:
    if (values.State != null) {
      dispatch(setDefaulFroNewSop('State', values.State));
    }
    if (values.AvizarCode != null) {
      dispatch(setDefaulFroNewSop('AvizarCode', values.AvizarCode));
    }
    return fetchMiddleware(path, {
      method: method,
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: values })
    }, getState).then((json) => {
      if (cb != null) {
        cb(json);
      }
      if (json.status == true) {
        const defFilters = state.leaksList.filters.sops.filters;
        const sort = state.leaksList.filters.sops.sort;

        if (json.data != null && json.data[0] != null) {
          // for create new SOP..
          sopId = json.data[0].ID;
        }

        dispatch(updateSopRes(project, sopId));
        return dispatch(fetchSOPs(project, defFilters, sort));
      }
      else {
        //@TODO: Error handle
      }
    }).then((args) => {
      if (sopId != null) {
        dispatch(fetchSensorDetailsBySOP(sopId));
      }
      if (selectedFeatureType == 'sop') {
        const dummySopItem = {id: sopId};
        return (dispatch(selectSOP(dummySopItem)));
      } else if (selectedFeatureType == 'sensor') {
        const sensors = state.leaksList.leaksByProject[project].sensors;
        const sensorItem = sensors.sensors[sensors.indexMap[selectedFeatureId]];
        return (dispatch(selectSensor(sensorItem)));
      }
      return (dispatch(selectFeature(project, selectedFeatureId, selectedFeatureType)));
    });
  }
}

const updateSopReq = (project, sop, values) => {
  return {
    type: types.UPDATE_SOP_REQUEST,
    project,
    sop,
    values,
  };
}

const updateSopRes = (project, sop) => {
  return {
    type: types.UPDATE_SOP_RESPONSE,
    project,
    sop,
  };
}


//Updated
export const fetchSensorDetailsBySOP = (sopID) => {
  return (dispatch, getState) => {
    const path = endPoints.INSTALL_ENDPOINT + "/sop/" + sopID;
    dispatch(requestSensorDetailsBySopID(sopID));
    return fetchMiddleware(path, {}, getState).then(json => {
      let details = null;
      if (json.status && json.data != null) {
        // const state = getState();
        details = json.data[0];
      }
      dispatch(receiveSensorDetailsBySopID(sopID, details));
    });
  }
}

const setSelectedSOP = (selectedSOP) => {
  return {
    type: types.SELECT_SOP,
    selectedSOP,
  }
}

const requestSOPResources = (project, sop, index, selectedSOP) => {
  return {
    type: types.REQUEST_SOP_RESOURCES,
    project,
    sop,
    selectedSOP,
    index,
  };
};

const receiveSOPResources = (project, sop, index, selectedSOP, resources) => {
  return {
    type: types.RECEIVE_SOP_RESOURCES,
    project,
    sop,
    selectedSOP,
    index,
    resources,
  };
};

const requestSensorDetailsBySopID = (sopID) => {
  return {
    type: types.REQUEST_SONSOR_ON_SOP_DETAILS,
    sopID,
  };
};

const receiveSensorDetailsBySopID = (sopID, data) => {
  return {
    type: types.RECEIVE_SONSOR_ON_SOP_DETAILS,
    sopID,
    data,
  };
};

export const setDefaulFroNewSop = (name, value) => {
  return {
    type: types.SET_SEFAULT_FOR_NEW_SOP,
    name,
    value,
  };
};
