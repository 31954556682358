import React from 'react';
import PropTypes from 'prop-types';

const classNames = require('classnames');

require('./style.scss');

export default class PagesBrowser extends React.Component {

   static propTypes() {
      return {
         children: PropTypes.Component,
         nextDisabled: PropTypes.bool,
         onBrowsing: PropTypes.func
      };
   }

   constructor(props) {
      super(props);

      this.goPrev = this.goPrev.bind(this);
      this.goNext = this.goNext.bind(this);
   }

   goPrev() {
      this.props.onBrowsing('prev');
   }

   goNext() {
      this.props.onBrowsing('next');
   }

   render() {
      const { children, nextDisabled } = this.props;
      const nextClasses = classNames(
         'pages-browser',
         'navigate',
         'next',
         {'disabled': nextDisabled}
      );
      return (
         <div style={{height: '100%'}}>
            <div 
               className='pages-browser navigate prev'
               onClick={this.goPrev}>
               <i className="icon-back"/>
            </div>
            <div
               className='pages-browser content'
               style={{width: '100%'}}>
               { children }
            </div>
            <div 
               disabled={nextDisabled}
               className={nextClasses}
               onClick={!nextDisabled && this.goNext}>
               <i className={"icon-next" + (!nextDisabled ? '' : ' disabled')}/>
            </div>
         </div>
      );
   }
}
