import React from 'react';
import PropTypes from 'prop-types';

require('./SearchItem.scss');

function generateselect(value, label) {
  return { value, label };
}
const SearchItem = ({ path, managementContext, installContext, user, onSearchItem, options }, context) => {

  const [input, setInput] = React.useState('');
  const [type, setType] = React.useState(undefined);
  const [placeholder, setPlaceholder] = React.useState(null);
  const [searchOptions, setSearchOptions] = React.useState([]);

  React.useEffect(() => {
    let type = 'ID';
    let placeholder;
    let searchOptions = [];

    switch (path) {
      case 'iq100b-mobile-modal':
        searchOptions = options;
        placeholder = context.t('Search Users');
        break;
      case 'alerts':
        searchOptions = [generateselect('ID', context.t('Alert ID'))];
        placeholder = context.t('Search Alert');
        break;
      case 'sensors':
        placeholder = context.t('Search Sensor');
        searchOptions = [
          generateselect('ID', context.t('Sensor ID')),
          generateselect('DeviceID', context.t('Device ID')),
          generateselect('AssetID', context.t('Asset ID')),
          generateselect('Model', context.t('Model')),
        ];
        if (user.technicianAdvanced) {
          searchOptions.push(
            generateselect('IEMI', context.t('IEMI')),
            generateselect('SIMnumber', context.t('ICCID')),
          );
        }
        break;
      case 'g5Devices':
        type = 'DeviceID';
        placeholder = context.t('Search Sensor');
        searchOptions = [
          generateselect('DeviceID', context.t('Device ID')),
          generateselect('AssetID', context.t('Asset ID')),
          generateselect('Model', context.t('Model')),
        ];
        if (user.technicianAdvanced) {
          searchOptions.push(
            generateselect('IEMI', context.t('IEMI')),
            generateselect('SIMnumber', context.t('ICCID')),
          );
        }
        break;
      case 'couples':
        placeholder = context.t('Search Couple');
        searchOptions = [
          generateselect('ID', context.t('Couple ID')),
          generateselect('SensorsID', context.t('Sensor ID')),
        ];
        break;
      case 'mobile':
        type = 'TaskName';
        searchOptions = [generateselect('TaskName', context.t('Task Name'))];
        placeholder = context.t('Search Task');
        break;
      case 'manage':
        switch (managementContext) {
          case 'Users':
            placeholder = context.t('Search User');
            type = 'UserName';
            break;
          case 'Projects':
            placeholder = context.t('Search Project');
            break;
          case 'Customers':
            placeholder = context.t('Search Customer');
            break;
        }
        break;
      case 'install':
        switch (installContext) {
          case 'SOPs':
            placeholder = context.t('Search Installation Point');
            searchOptions = [
              generateselect('ID', context.t('Installation Point ID')),
              generateselect('AssetID', context.t('Asset ID')),
            ];
            break;
          case 'Sensors':
            placeholder = context.t('Search Sensor');
            searchOptions = [
              generateselect('ID', context.t('Sensor ID')),
              generateselect('DeviceID', context.t('Device ID')),
              generateselect('AssetID', context.t('Asset ID')),
            ];
            if (user.technicianAdvanced) {
              searchOptions.push(generateselect('IEMI', context.t('IEMI')));
            }
            break;
          case 'Interferences':
            placeholder = context.t('Search Interference');
            searchOptions = [generateselect('ID', context.t('Interference ID'))];
            break;
        }
        break;
    }
    setInput('');
    setType(type);
    setSearchOptions(searchOptions);
    setPlaceholder(placeholder)
  }, [path, managementContext]);

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    if (input) {
      onSearchItem(input.trim(), type);
    }
  }

  const onChange = (e) => {
    setInput(e.target.value);
  }

  const onTypeChange = (option) => {
    setType(option.target.value)
  }

  return (
    <form onSubmit={handleSubmit}>
      <fieldset>
        <div className="search-item">
          {path !== 'manage' &&
            <select onChange={onTypeChange} value={type}>
              {searchOptions.map((option) => <option key={option.value} value={option.value}>{option.label}</option>)}
            </select>
          }
          <input
            className="input-field"
            type='text'
            placeholder={placeholder}
            onChange={onChange}
            value={input} />
          <button
            className="search"
            disabled={input.length === 0}
            type="submit" />
        </div>
      </fieldset>
    </form>
  );
}


SearchItem.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SearchItem;
