// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/compat/app';


// Add the Firebase products that you want to use
import 'firebase/compat/auth';

const config = {
  apiKey: 'AIzaSyAhjn5s8p7kAgzGm2K7BLAVi87yTGfS6Y8',
  authDomain: 'aqs-fb.firebaseapp.com',
  databaseURL: 'https://aqs-fb.firebaseio.com',
  storageBucket: 'aqs-fb.appspot.com',
  messagingSenderId: '363428987704',
};

let onAuthStateChangeListener = null;

firebase.initializeApp(config);
firebase.auth().onAuthStateChanged(function (user) {
  console.log('onAuthStateChanged', user);
  if (onAuthStateChangeListener != null) {
    onAuthStateChangeListener.onAuthStateChanged(user);

    if (!user) {
      onAuthStateChangeListener.logout();
    } else {
      onAuthStateChangeListener.loggingIn(user);
    }
  }
});

export function setListener(listener) {
  onAuthStateChangeListener = listener;

  const auth = firebase.auth();
  const user = auth.currentUser;
  if (!user) {
    onAuthStateChangeListener.logout();
  } else {
    onAuthStateChangeListener.loggingIn(user);
  }
}

export function getCurrentUser() {
  const auth = firebase.auth();
  const user = auth.currentUser;
  return user;
}

export function signInWithEmailAndPassword(email, password) {
  return new Promise((resolve, reject) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        resolve(userCredential.user);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function signOut() {
  return firebase.auth().signOut();
}

export function sendPasswordResetEmail(email) {
  return firebase.auth().sendPasswordResetEmail(email);
}
