import React from 'react';
import PropTypes from 'prop-types';

import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const PhoneInputComp = (props) => {

    const { disabled, input: { value, onChange, name }, className, meta: { touched, error } } = props

    return (
      <div className="tooltip-cont">
        <PhoneInput
          inputClass={className}
          disabled={disabled || false}
          enableSearch={true}
          inputProps={{value: value}}
          {...props.input}
        />

        {touched && error && <span className="tooltip">{error}</span>}


      </div>
    );
}

export default PhoneInputComp;
