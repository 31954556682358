
/**************************************************************************
 * File: Dijkstra.java
 * Author: Keith Schwarz (htiek@cs.stanford.edu)
 *
 * An implementation of Dijkstra's single-source shortest path algorithm.
 * The algorithm takes as input a directed graph with non-negative edge
 * costs and a source node, then computes the shortest path from that node
 * to each other node in the graph.
 *
 * The algorithm works by maintaining a priority queue of nodes whose
 * priorities are the lengths of some path from the source node to the
 * node in question.  At each step, the algortihm dequeues a node from
 * this priority queue, records that node as being at the indicated
 * distance from the source, and then updates the priorities of all nodes
 * in the graph by considering all outgoing edges from the recently-
 * dequeued node to those nodes.
 *
 * In the course of this algorithm, the code makes up to |E| calls to
 * decrease-key on the heap (since in the worst case every edge from every
 * node will yield a shorter path to some node than before) and |V| calls
 * to dequeue-min (since each node is removed from the prioritiy queue
 * at most once).  Using a Fibonacci heap, this gives a very good runtime
 * guarantee of O(|E| + |V| lg |V|).
 *
 * This implementation relies on the existence of a FibonacciHeap class, also
 * from the Archive of Interesting Code.  You can find it online at
 *
 *         http://keithschwarz.com/interesting/code/?dir=fibonacci-heap
 */

import { FibonacciHeap } from './fibonacciHeap';
import { getDistanceFromLatLonInMeter } from './geoFunctions';
import { 
  toLatLon, toLatitudeLongitude, headingDistanceTo, moveTo, insidePolygon 
} from 'geolocation-utils'
    /**
     * Given a directed, weighted graph G and a source node s, produces the
     * distances from s to each other node in the graph.  If any nodes in
     * the graph are unreachable from s, they will be reported at distance
     * +infinity.
     *
     * @param graph The graph upon which to run Dijkstra's algorithm.
     * @param source The source node in the graph.
     * @return A map from nodes in the graph to their distances from the source.
     */
function shortestPaths(graph, source, end) {
    /* Create a Fibonacci heap storing the distances of unvisited nodes
     * from the source node.
     */
    var pq = new FibonacciHeap();

    /* The Fibonacci heap uses an internal representation that hands back
     * Entry objects for every stored element.  This map associates each
     * node in the graph with its corresponding Entry.
     */
    var entries = [];

    /* Maintain a map from nodes to their distances.  Whenever we expand a
     * node for the first time, we'll put it in here.
     */
    var result = [];

    /* Add each node to the Fibonacci heap at distance +infinity since
     * initially all nodes are unreachable.
     */
    for (var i = 0; i < graph.m_acLocation.length; i++) {
        var node = graph.m_acLocation[i];
        entries[node] = pq.enqueue(node, Number.MAX_VALUE);
    }

        /* Update the source so that it's at distance 0.0 from itself; after
         * all, we can get there with a path of length zero!
         */
    pq.decreaseKey(entries[source], 0.0, null);

        /* Keep processing the queue until no nodes remain. */
    while (!pq.isEmpty()) 
    {
        /* Grab the current node.  The algorithm guarantees that we now
         * have the shortest distance to it.
         */
        var curr = pq.dequeueMin();

        /* Store this in the result table. */
        result[curr.mElem] = { m_cPrev: curr.mPrevElem, m_cDis: curr.mPriority };

        if (curr.mElem == end)
        {
            return result;
        }

        var edgesTo = graph.edgesFrom(curr.mElem);
        /* Update the priorities of all of its edges. */
        for (var i = 0; i < edgesTo.length; i++) {
            var arc = edgesTo[i];
            /* If we already know the shortest path from the source to
             * this node, don'Map.Entry<Integer, Location> add the edge.
             */
            if (result[arc[0]] != null) continue;

            /* Compute the cost of the path from the source to this node,
             * which is the cost of this node plus the cost of this edge.
             */
            var pathCost = curr.mPriority + arc[1];

            /* If the length of the best-known path from the source to
             * this node is longer than this potential path cost, update
             * the cost of the shortest path.
             */
            var dest = entries[arc[0]];

            if (pathCost < dest.mPriority) {

                var endPoint = entries[end];

                /*double nDisFromEnd = Location.distFrom(endPoint.getValue().getValue().m_nLatitude, endPoint.getValue().getValue().m_nLongitude,
                        dest.getValue().getValue().m_nLatitude, dest.getValue().getValue().m_nLongitude);*/

                if (pathCost /*+ nDisFromEnd*/ < endPoint.mPriority) {
                    pq.decreaseKey(dest, pathCost, curr.mElem);
                }
            }
        }
    }

    /* Finally, report the distances we've found. */
    return result;
}

export function shortestPathsWithPath(cPipeGraph, cStart, cEnd) {
    var cRes = shortestPaths(cPipeGraph, cStart, cEnd);

    var points = [];
    var cCurrPoint = cEnd;

    var xLength = 0.0;
    while (cCurrPoint != cStart) {
      var cPointLoc = cCurrPoint[1];

      var cNext = cRes[cCurrPoint].m_cPrev;

      const point = {
        lng: cCurrPoint[1][1],
        lat: cCurrPoint[1][0],
        distance: cRes[cCurrPoint].m_cDis,
        LengthM: null,
        Material: cCurrPoint[3]
      };

      const lastPointsIndex = points.length - 1;
      const lastInsertedPoint = points[lastPointsIndex];
      if (lastInsertedPoint == null || (lastInsertedPoint.lat != point.lat || lastInsertedPoint.lng != point.lng)) {
        // let ditance = getDistanceFromLatLonInMeter(point.lng, point.lat, cNext[1][0], cNext[1][1]);
        let distance = 0.0;

        if (points.length > 0) {
          const lastIndex = points.length - 1;
          const p1 = {lat: point.lat, lon: point.lng};
          const p2 = {lat: points[lastIndex].lat, lon: points[lastIndex].lng};
          distance = headingDistanceTo(p1, p2).distance;
          // console.log(`[${p1.lat}, ${p1.lon}] [${p2.lat}, ${p2.lon}], ${distance}`);
        }
        // if(lastPointsIndex == -1){
        //   const p1 = {lat: point.lat, lon: point.lng};
        //   const p2 = {lat: cNext[1][0], lon: cNext[1][1]};
        //   distance = headingDistanceTo(p1, p2).distance;
        // }
        point.LengthM = distance;
        xLength += distance;
        points.push(point);
      }
      else{
        console.log("I AM HERE");
      }

      cCurrPoint = cNext;
    }

    const startPoint = {
      lng: cStart[1][1],
      lat: cStart[1][0],
      distance: cRes[cStart].m_cDis,
      LengthM: null,
      Material: cStart[3]
    };
    
    let lastPoint = points[points.length-1];
    if(lastPoint.lat != startPoint.lat && lastPoint.lng != startPoint.lng){
      const p1 = {lat: startPoint.lat, lon: startPoint.lng};
      const p2 = {lat: lastPoint.lat, lon: lastPoint.lng};
      let distance = headingDistanceTo(p1, p2).distance;
      startPoint.LengthM = distance;
      points.push(startPoint);
    }

    // if (points.indexOf(startPoint) == -1) {
    //   points.push(startPoint);
    // }

    return [points, xLength];
}

function shortestPathsWith2Path(cPipeGraph, cStart, cMid, cEnd) {
            
        var cRes1 = shortestPathsWithPath(cPipeGraph, cStart, cMid);
            
        var cRes2 = shortestPathsWithPath(cPipeGraph, cMid, cEnd);

        var xLength = cRes1[1] + cRes2[1];

        cRes2[0].push(cMid);
        cRes2[0].concat(cRes1);

        return [cRes2, xLength];
}
