import React from 'react';
import PropTypes from 'prop-types';

import { useForm } from 'react-hook-form';
import './mobile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { updateSampleState } from '../../actions/MobileSamplesActions';
import AddressInput from '../Custom/AddressInput';
import useGeocoder from '../../hooks/useGeocoder';

const MobileSampleForm = ({ sample, notify }, context) => {
  const { handleSubmit, register, setValue } = useForm();
  // const options = useSelector((state) => state.leaksList.optionList.options.AqsCode.filter((opt) => opt.isMobile === 1));
  const language = useSelector((state) => state.i18nState.lang);
  const { fetchAddress } = useGeocoder(language);
  const dispatch = useDispatch();

  React.useEffect(() => {
    setValue('address', sample['address']);
    setValue('user_comment', sample['user_comment']);
    setValue('user_sop_classify', sample['user_sop_classify']);
  }, [sample]);

  const onSubmit = (values, _event) => {
    dispatch(updateSampleState(sample.sample_uid ,values, (isSuccess) => {
      if (isSuccess) {
        notify('Saved Success', 'success')
      } else {
        notify('Failed', 'error')
      }
    }));
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <fieldset className='mobile-sample-form'>
        <label htmlFor="sample_address">{context.t('Address')}</label>
        <AddressInput id="sample_address" name="address" inputRef={register}>
          <img
            src={require('./../../../images/getAddress.png')}
            className="getAddress-button"
            onClick={() => {
              const { latitude, longitude } = sample;
              fetchAddress(latitude, longitude).then((address) => {
                console.log(address);
                if (address?.FormattedAddress) {
                  setValue('address', address.FormattedAddress);
                }
              });
            }}
            // onClick={this.getAddressClick}
            // disabled={this.props.disabled || !user.editMode}
          />
        </AddressInput>
        {/* <input id="sample_address" name="address" ref={register} /> */}

        <label htmlFor="sample_comment">{context.t('Comment')}</label>
        <input id="sample_comment" name="user_comment" ref={register} />

        {/* <label htmlFor='sample_avizar_code'>{context.t('Access Point')}</label>
        <select id='sample_avizar_code' name='user_sop_classify' ref={register}>
          {options.map((option) => (
            <option key={option.value} value={option.value}>{option.label}</option>
          ))}
        </select> */}
      </fieldset>

      <input type='submit' value='Submit' />
    </form>
  )
}

MobileSampleForm.contextTypes = {
  t: PropTypes.func.isRequired
};

export default MobileSampleForm;
