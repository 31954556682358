import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column } from 'fixed-data-table-2';
import { SortableHeaderCell, TextCell, TableMixin } from '../../components';
import CTableActions from '../../containers/CTableActions/CTableActions';
import CSensorsSettings from '../../containers/CSensorsSettings/CSensorsSettings';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

class iQuariusTable extends React.Component {

  render() {

    const { items, indexMap, selectedFeature, pHeight, pWidth, sortProp, localSortProp, rowClickEvent, rowClassNameGetter, goToRow, user, t } = this.props;
    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          rowClassNameGetter={rowClassNameGetter}
          scrollToRow={goToRow}
          {...this.props}
        >
          <Column
            columnKey="TaskName"
            width={120}
            flexGrow={2}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="task_name" />} />
          <Column
            columnKey="CreationTime"
            width={80}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="creation_date" dateType={DateType.DATE} />} />
          <Column
            columnKey="SamplesCount"
            width={115}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="samples_count" />} />
        </Table>

        {items && items.length > 0 &&
          <CTableActions
            type="iQuarius"
          />
        }
        {items && items.length > 0 &&
          <span style={totalItemsStyle}>{t('Selected: {selectedIndex}. Total: {total}', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
        }

      </div>
    );
  }
}

iQuariusTable = TableMixin(iQuariusTable, CSensorsSettings);
export default iQuariusTable;
