import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  unsetOverflowY: {
    overflowY: 'unset'
  },
  dialog: {
    position: 'absolute',
    top: 0
  }
});

export default function AppModal(props) {

  const classes = useStyles();

  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <Dialog
      fullWidth={props.fullWidth}
      maxWidth={props.maxWidth}
      open={props.open}
      onClose={handleClose}
      aria-labelledby="app-dialog"
      classes={{
        paper: props.onTop ? classes.dialog : classes.unsetOverflowY
      }}
      // keepMounted='true'
    >
      <DialogTitle id="app-dialog">
        <Grid container wrap="nowrap" spacing={2}>

          <Grid item xs={12}>
            <Typography variant='h3'>
              {props.title}
            </Typography>
          </Grid>

          {props.closeIcon &&
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          }

        </Grid>
      </DialogTitle>
      <DialogContent classes={{ root: classes.unsetOverflowY}}>

        {props.content}

      </DialogContent>
      <DialogActions>
        { props.actions &&
          props.actions
        }
      </DialogActions>
    </Dialog>
  );
}

AppModal.propTypes = {
  actions: PropTypes.node,
  closeIcon: PropTypes.bool,
  content: PropTypes.node,
  fullWidth: PropTypes.bool,
  maxWidth: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  onClose: PropTypes.func,
  onTop: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

AppModal.defaultProps = {
  fullWidth: false,
  maxWidth: 'lg',
  open: false,
};
