import React from 'react';
import PropTypes from 'prop-types';
import { SYSTEM_RESOURCE } from '../../constants/EndPoints';
import { Menu, UserBlock } from '../../components';

const classNames = require('classnames');
require('./Header.scss');

let misc = require('../../constants/Misc');
const endPoints = require('../../constants/EndPoints');

function Header(props, context) {
  const { user, selectedProject, local, downloadFile, logo, onPathChange, signOutFunc } = props;
  //const defaultPath = './../../images/system/' + SYSTEM_RESOURCE + '/logo.png'
  const defaultLogo = require("../../../images/system/" + endPoints.SYSTEM_RESOURCE + "/logo.png");
  const panelClasses = classNames(
    'header',
    { 'custom': logo != null }
  );
  return (
    <header className={panelClasses}>

      <div className="logo">
        <img src={logo || defaultLogo} width="189" height="53" />
        <div className="version">{context.t('Version')} {misc.versionNumber}</div>
      </div>

      <UserBlock
        user={user}
        signOutFunc={signOutFunc}
        local={local}
        downloadFile={downloadFile} />

      <Menu
        selectedProject={selectedProject}
        onPathChange={onPathChange}
        user={user} />

    </header>
  );
}

//   extends React.Component {
//   render() {
//     const { user, selectedProject, local, downloadFile, logo, onPathChange } = this.props;
//     //const defaultPath = './../../images/system/' + SYSTEM_RESOURCE + '/logo.png'
//     const defaultLogo = require("../../../images/system/" + endPoints.SYSTEM_RESOURCE + "/logo.png");
//     const panelClasses = classNames(
//       'header',
//       { 'custom': logo != null }
//     );
//     debugger;
//     return (
//       <header className={panelClasses}>

//         <div className="logo">
//           <img src={logo || defaultLogo} width="189" height="53"/>
//           <div className="version">{this.context.t('Version')} { misc.versionNumber }</div>
//         </div>

//         <UserBlock
//             user={user}
//             local={local}
//             downloadFile={downloadFile}/>

//         <Menu
//           selectedProject={selectedProject}
//           onPathChange={onPathChange}
//           user={user}/>

//       </header>
//     );
//   }
// }

Header.contextTypes = {
  t: PropTypes.func.isRequired
}

export default Header;
