import React from 'react';
import PropTypes from 'prop-types';

class ManageMenu extends React.Component {

  render() {
    const { addNew, onChange } = this.props;
    return (
      <div className="manage-menu">
        <button
          className="btn-success create-item"
          onClick={() => {
            addNew();
          }
          }
          type="button"
        >
          <i title={this.context.t('Add')} />
        </button>

        <button
          className="btn-menu customers"
          onClick={() => {
            onChange('Customers');
          }
          }
        >
          <i title={this.context.t('Customers')} />
        </button>

        <button
          className="btn-menu projects"
          onClick={() => {
            onChange('Projects');
          }
          }
        >
          <i title={this.context.t('Projects')} />
        </button>

        <button
          className="btn-menu users"
          onClick={() => {
            onChange('Users');
          }
          }
        >
          <i title={this.context.t('Users')} />
        </button>
      </div>
      );
  }
}

ManageMenu.contextTypes = {
  t: PropTypes.func.isRequired
}

export default ManageMenu;
