import * as types from '../constants/ActionTypes';

function local(
  state = {
    units: 'm',
    timeZone: 'local',
    pipesSource: 'materials',
    coverageSource: 'couplesCoverage',
    noiseSamplesColorAttr: 'Nc1',
    mobileNoiseSamplesColorAttr: 'engine_intensity_f1',
    sensorGraphesDisplay: true,
    factor: {
      m: 1,
      ft: 3.280839895,
      bigUnitValue: {
        m: { value: 1000, label: 'km' },
        ft: { value: 5280, label: 'mile' },
      },
    },
  },
  action
) {
  switch (action.type) {
    case types.SET_UNIT:
      return Object.assign({}, state, {
        units: action.unit,
      });
    case types.SET_ZONE:
      return Object.assign({}, state, {
        timeZone: action.zone,
      });
    case types.SET_PIPES_SOURCE:
      return Object.assign({}, state, {
        pipesSource: action.source,
      });
    case types.SET_COVERAGE_SOURCE:
      return Object.assign({}, state, {
        coverageSource: action.source,
      });
    case types.SET_NOISE_SAMPLE_COLOR_SOURCE_ATTR:
      return Object.assign({}, state, {
        noiseSamplesColorAttr: action.colorAttr,
      });
    case types.SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR:
      return Object.assign({}, state, {
        mobileNoiseSamplesColorAttr: action.colorAttr,
      });
    case types.SENSOR_GRAPHS_TOGGLE:
      return Object.assign({}, state, {
        sensorGraphesDisplay: action.display,
      });
    default:
      return state;
  }
}
export default local;
