import fetch from 'isomorphic-fetch';
import { GOOGLE_API_KEY } from '../../constants/Misc';

/**
 * 
 * @param {any} address
 * @param {any} cb
 *    return position as { lat, lng }
 */
export const findPositionByAddress = (address, cb) => {
  findPositionByAddressGoogle(address, (position) => {
    if (position != null) {
      cb(position);
    } else {
      // if failed - get position from OSM:
      console.log('Geocode was not successful for the following reason: ' + position);
      findPositionByAddressOSM(address, cb);
    }
  });
}

export const findAddressByPosition = (coordinate, language, cb) => {
  findAddressByPositionGoogle(coordinate, language, (address) => {
    if (address != null) {
      cb(address);
    } else {
      // if failed - get address from OSM:
      findAddressByPositionOSM(coordinate, language, cb);
    }
  })
}

// ##################################################
// ##########      Private methods:        ##########
// ##################################################

const fetchInternal = (path) => {
  return fetch(path).then(response => {
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  });
}

const findPositionByAddressGoogle = (address, cb) => {
  const request = 'https://maps.googleapis.com/maps/api/geocode/json?address=' + address + '&key=' + GOOGLE_API_KEY;
  fetchInternal(request).then(json => {
    let positionResult = null;
    if (json.status == 'OK') {
      positionResult = json.results[0].geometry.location;
    }
    cb(positionResult);
  });
}

const findPositionByAddressOSM = (address, cb) => {
  let params = [
    {
      key: 'q',
      value: address,
    },
    {
      key: 'format',
      value: 'json',
    },
    {
      key: 'limit',
      value: 1,
    },
    {
      key: 'addressdetails',
      value: 1,
    },
  ];
  let paramsQuery = '';
  params.forEach((param, index) => {
    const firstChar = (index == 0) ? '?' : '&';
    paramsQuery += (firstChar + param.key + '=' + param.value);
  });
  const request = 'https://nominatim.openstreetmap.org/search' + paramsQuery;
  return fetchInternal(request).then(json => {
    let positionResult = null;
    if (json != null && json[0] != null) {
      positionResult = {
        lat: parseFloat(json[0].lat),
        lng: parseFloat(json[0].lon)
      }
    }
    cb(positionResult);
  });
}

const findAddressByPositionGoogle = (coordinates, language, cb) => {
  const latLngStr = coordinates.Latitude + ',' + coordinates.Longitude;
  const request = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + latLngStr + '&language=' + language + '&key=' + GOOGLE_API_KEY;
  return fetchInternal(request).then(json => {
    let address = null;
    if (json.status == 'OK') {

      const { results } = json;
      address = {
        FormattedAddress: results[0].formatted_address
      };

      results.forEach((result) => {
        const addressComponent = result.address_components;
        if (address.City == null) {
          const city = addressComponent.find((item) => { return item.types.indexOf('locality') != -1 });
          address.City = (city == null) ? null : city.long_name;
        }
        if (address.CityDistrict == null) {
          const cityDistrict = addressComponent.find((item) => { return item.types.indexOf('sublocality') != -1 });
          address.cityDistrict = (cityDistrict == null) ? null : cityDistrict.long_name;
        }
        if (address.Country == null || address.CountryCode == null) {
          const country = addressComponent.find((item) => { return item.types.indexOf('country') != -1 });
          address.Country = (country == null) ? null : country.long_name;
          address.CountryCode = (country == null) ? null : country.short_name;
        }
        if (address.HouseNumber == null) {
          const houseNumber = addressComponent.find((item) => { return item.types.indexOf('street_number') != -1 });
          address.HouseNumber = (houseNumber == null) ? null : houseNumber.long_name;
        }
        if (address.Street == null) {
          const street = addressComponent.find((item) => { return item.types.indexOf('route') != -1 });
          address.Street = (street == null) ? null : street.long_name;
        }
        if (address.State == null) {
          const state = addressComponent.find((item) => { return item.types.indexOf('administrative_area_level_1') != -1 });
          address.State = (state == null) ? null : state.long_name;
        }
        if (address.PostalCode == null) {
          const postalCode = addressComponent.find((item) => { return item.types.indexOf('postal_code') != -1 });
          address.PostalCode = (postalCode == null) ? null : postalCode.long_name;
        }
      });

      cb(address);
    } else {
      console.log('Geocode was not successful for the following reason: ' + json.data);
      cb(null);
    }
  });
}

const findAddressByPositionOSM = (coordinates, language, cb) => {
  let params = [
    {
      key: 'format',
      value: 'json',
    },
    {
      key: 'lat',
      value: coordinates.Latitude,
    },
    {
      key: 'lon',
      value: coordinates.Longitude,
    },
    {
      key: 'accept-language',
      value: language,
    },
    {
      key: 'zoom',
      value: 18,
    },
    {
      key: 'addressdetails',
      value: 1,
    },
  ];
  let paramsQuery = '';
  params.forEach((param, index) => {
    const firstChar = (index == 0) ? '?' : '&';
    paramsQuery += (firstChar + param.key + '=' + param.value);
  });
  const request = 'https://nominatim.openstreetmap.org/reverse' + paramsQuery;
  return fetchInternal(request).then(json => {
    let address = null;
    const addressDetails = json.address;
    if (addressDetails != null) {
      address = {
        City: addressDetails.city,
        CityDistrict: addressDetails.city_district || addressDetails.suburb,
        Country: addressDetails.country,
        CountryCode: addressDetails.country_code,
        HouseNumber: addressDetails.house_number,
        Street: addressDetails.road,
        PostalCode: addressDetails.postcode,
        State: addressDetails.state,
        FormattedAddress: json.display_name,
      };
    }

    cb(address);
  });
}