import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import { CommentField, Select, Input } from '../../components';

function NoiseAlertDetails(props, context) {
  const {
    selectedFeature,
    alertStateOptions,
    user,
    timeZone,
    onEdit,
    onCopy,

    handleSubmit,
    error,
    pristine,
    submitting
  } = props;

  return (
    <div className="form-horizontal sticky-actions">
      <form onSubmit={handleSubmit(props.updateNoiseAlert)} style={{ height: '300px' }}>
        <CommentField
          fieldName="Comment"
          featureID={selectedFeature}
          placeholder={context.t('Alert comment')}
          onEdit={() => onEdit('noise-alert-form', 'Comment')}
          onCopy={() => onCopy('noise-alert-form', 'Comment')}
          disabled={!user.editMode}
        />

        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('Event Date')}:</label>
          <div className="col-xs-8 control-input">
            <Field
              className="form-control"
              name="CreateDate"
              component={Input}
              type="date"
              timeZone={timeZone} />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{context.t('End Date')}:</label>
          <div className="col-xs-8 control-input">
            <Field
              className="form-control"
              name="EndDate"
              component={Input}
              type="date"
              timeZone={timeZone}
              disabled />
          </div>
        </div>

        <div className="form-actions">
          <button
            className="btn btn-success btn-submit"
            disabled={pristine || submitting}
            type="submit">{context.t('Save')}</button>
          <div className="form-feedback text-danger">
            {error}
          </div>

        </div>
      </form>
    </div>
  );
}

NoiseAlertDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

export default reduxForm({
  form: 'noise-alert-form', // a unique name for this form
  enableReinitialize: true,
  // validate
})(NoiseAlertDetails);
