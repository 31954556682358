import React from 'react';
import PropTypes from 'prop-types';


const classNames = require('classnames');

export default class FileItem extends React.Component {

  constructor(props) {
    super(props);

    this.onDownloadClick = this.onDownloadClick.bind(this);
  }

  onDownloadClick() {
    const { downloadPath, downloadFile } = this.props;
    downloadFile(downloadPath, 'file');
  }

  render() {
    const { Key, deleteFile, downloadFile, downloadPath } = this.props;

    // Get actual filename
    const filename = decodeURIComponent(Key.replace(/^.*[\\\/]/, ''));

    const imgSrc = require('./file-icon.png');

    return (
      <div className="file-item">
        <button className="icon-delete" onClick={() => deleteFile(Key)}></button>
        <div onClick={this.onDownloadClick} style={{cursor: 'pointer'}}>
          <img src={imgSrc} alt={filename} title={filename} />
          <div className="file-name">{filename}</div>
        </div>
      </div>
    );
  }

}
