import * as endPoints from '../constants/EndPoints';
import { decToHex, formatMobileDeviceID } from '../containers/UIhelper/UIhelper';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import { ACTIONS as taskActions } from './MobileTasksActions';
import { isEqual } from 'lodash';
import * as types from '../constants/ActionTypes';

const ACTIONS = {
  REQUEST_MOBILE_SAMPLES: 'REQUEST_MOBILE_SAMPLES',
  RECEIVE_MOBILE_SAMPLES: 'RECEIVE_MOBILE_SAMPLES',
  SET_SELECTED_MOBILE_SAMPLE: 'SET_SELECTED_MOBILE_SAMPLE',
  SET_CHECKED_SAMPLES: 'SET_CHECKED_SAMPLES',
  UPDATE_SAMPLE: 'UPDATE_SAMPLE',
  SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR: 'SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR',
  REQUEST_MOBILE_ALG_PARAMS: 'REQUEST_MOBILE_ALG_PARAMS',
  RECEIVE_MOBILE_ALG_PARAMS: 'RECEIVE_MOBILE_ALG_PARAMS',
  REQUEST_MOBILE_SAMPLE_PICTURES: 'REQUEST_MOBILE_SAMPLE_PICTURES',
  RECEIVE_MOBILE_SAMPLE_PICTURES: 'RECEIVE_MOBILE_SAMPLE_PICTURES',
  // SET_IMAGES_OF_MOBILE_SAMPLES: 'SET_IMAGES_OF_MOBILE_SAMPLES',
  SET_MOBILE_SAMPLES_FILTERS: 'SET_MOBILE_SAMPLES_FILTERS',
};

export const setMobileNoiseColorByAttribute = (attrName) => ({
  type: ACTIONS.SET_MOBILE_NOISE_SAMPLE_COLOR_SOURCE_ATTR,
  colorAttr: attrName,
});

export const getSamples = (tasksIds, page = 0, count = 30) => (dispatch, getState) => {
  dispatch(getSamplesAction());
  // fetch task samples:
  if (tasksIds && tasksIds.length > 0) {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const filters = state.mobile.samples.filters;
    const qStr = [
      `tasks=${tasksIds.join(',')}`,
      // `page=${page}`,
      // `rows=${count}`
    ];
    if (filters && filters.length > 0) {
      qStr.push(`filters=${JSON.stringify(filters)}`)
    }

    const url = `${endPoints.END_POINT}/mobilesamples/samples/${projectId}?${qStr.join('&')}`;
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        const samples = json.data.map((sample) => {
          const hex = isNaN(sample.device_id) ? '--' : decToHex(Number(sample.device_id));
          const hexFormated = formatMobileDeviceID(hex);
          return { ...sample, device_id_hex: hexFormated};
        });
        console.log({samples});
        const indexMap = setIndexes(samples, 'sample_uid');
        dispatch(getSamplesAction(true, samples, indexMap));
        dispatch(getImagesForSamples(tasksIds, page, count));
      }
    });
  }
};

export const getImagesForSamples = (tasksIds, page = 0, count = 30) => (dispatch, getState) => {
  // dispatch({ type: ACTIONS.SET_IMAGES_OF_MOBILE_SAMPLES, payload: [] });
  if (tasksIds && tasksIds.length > 0) {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const filters = state.mobile.samples.filters;

    const qStr = [
      `tasks=${tasksIds.join(',')}`,
      // `page=${page}`,
      // `rows=${count}`
    ];
    if (filters && filters.length > 0) {
      qStr.push(`filters=${JSON.stringify(filters)}`)
    }

    const url = `${endPoints.END_POINT}/mobilesamples/samples/${projectId}/images?${qStr.join('&')}`;
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status) {
        const state = getState();
        const samples = state.mobile.samples;
        const items = [...samples.items].map((item) => ({ ...item, sample_images: '' }));
        json.data.forEach(({ id, images }) => {
          console.log({id, images, fromMap: samples.indexMap[id]});
          if (samples.indexMap[id] !== undefined) {
            items[samples.indexMap[id]].sample_images = images;
          }
        });

        dispatch(getSamplesAction(true, items, samples.indexMap));
      }
    });
  }
}

export const updateSampleState = (sampleId, values, successCallback) => {
  console.log();
  return (dispatch, getState) => {
    const state = getState();
    const url = `${endPoints.END_POINT}/mobilesamples/${sampleId}`;
    return fetchMiddleware(
      url,
      {
        method: 'PUT',
        body: JSON.stringify(values),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      },
      getState
    ).then((json) => {
      console.log(json);
      if (json.status === false) {
        successCallback(false);
      } else {
        const { items, indexMap } = state.mobile.samples;
        const sample = items[indexMap[sampleId]];
        const updatedSample = { ...sample, ...values };
        dispatch({
          type: ACTIONS.UPDATE_SAMPLE,
          payload: updatedSample,
        });
        successCallback(true);
      }
    });
  };
};

export const getSamplesAction = (
  isReceiveData = false,
  samples = [],
  indexMap = {}
) => {
  return {
    type: isReceiveData
      ? ACTIONS.RECEIVE_MOBILE_SAMPLES
      : ACTIONS.REQUEST_MOBILE_SAMPLES,
    payload: {
      samples,
      indexMap,
    },
  };
};

const getSamplePictures = (taskId, sampleId) => (dispatch, getState) => {
  const state = getState();
  const projectId = state.leaksList.selectedProject;
  const url = `${endPoints.END_POINT}/mobilesamples/samplepics/${projectId}/${taskId}/${sampleId}`;
  dispatch({ type: ACTIONS.REQUEST_MOBILE_SAMPLE_PICTURES });
  return fetchMiddleware(url, {}, getState).then((json) => {
    if (json.status) {
      dispatch({ type: ACTIONS.RECEIVE_MOBILE_SAMPLE_PICTURES, payload: json.data });
    }
  });
}

export const fetchProjectAlgParams = (projectId) => (dispatch, getState) => {
  const url = `${endPoints.END_POINT}/options/${projectId}`;
  dispatch(requestMobileAlgParams());
  return fetchMiddleware(url, {}, getState).then((json) => {
    if (json.status) {
      dispatch(receiveMobileAlgParams(json.data));
    }
  });
}

const requestMobileAlgParams = () => ({
  type: ACTIONS.REQUEST_MOBILE_ALG_PARAMS,
});
const receiveMobileAlgParams = (params) => ({
  type: ACTIONS.RECEIVE_MOBILE_ALG_PARAMS,
  payload: params
});

export const setSelectedSample = (sample) => (dispatch) => {
  dispatch(getSamplePictures(sample.task_uuid, sample.sample_uid));
  return dispatch({
    type: ACTIONS.SET_SELECTED_MOBILE_SAMPLE,
    payload: sample.sample_uid,
  });
};
export const setCheckedSamples = (samplesIds) => {
  return {
    type: ACTIONS.SET_CHECKED_SAMPLES,
    payload: samplesIds,
  };
};

export const setSamplesFilters = (filters) => (dispatch, getState) => {
  const state = getState();
  const currentFilters = state.mobile.samples.filters;
  dispatch(setFiltersAction(filters));

  if (!isEqual(filters, currentFilters)) {
    const selectedTasks = state.mobile.tasks.selectedTask;
    dispatch(getSamples(selectedTasks.join(',')));
  }
}

const setFiltersAction = (filters) => ({
  type: ACTIONS.SET_MOBILE_SAMPLES_FILTERS,
  payload: filters,
});

/* REDUCER */

const initialState = {
  items: [],
  indexMap: {},
  imagesOfItems: [],
  selectedSampleId: null,
  checkedSamples: [],
  algParams: {},
  samplePictures: [],
  filters: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SELECT_PROJECT:
      return initialState;

    case ACTIONS.REQUEST_MOBILE_SAMPLES:
    case ACTIONS.RECEIVE_MOBILE_SAMPLES:
      return {
        ...state,
        items: action.payload.samples,
        indexMap: action.payload.indexMap,
      };

    case ACTIONS.SET_SELECTED_MOBILE_SAMPLE:
      return { ...state, selectedSampleId: action.payload };

    case ACTIONS.SET_CHECKED_SAMPLES:
      return { ...state, checkedSamples: action.payload };
    case ACTIONS.UPDATE_SAMPLE:
      return {
        ...state,
        items: [...state.items.map((item) => item.sample_uid === action.payload.sample_uid
          ? action.payload
          : item
        )],
      };

    case taskActions.SET_SELECTED_TASK: {
      return { ...state, selectedSampleId: null };
    }

    case ACTIONS.REQUEST_MOBILE_ALG_PARAMS:
      return { ...state, algParams: {} };

    case ACTIONS.RECEIVE_MOBILE_ALG_PARAMS:
      return { ...state, algParams: action.payload };

    case ACTIONS.REQUEST_MOBILE_SAMPLE_PICTURES:
      return { ...state, samplePictures: [] };
    case ACTIONS.RECEIVE_MOBILE_SAMPLE_PICTURES:
      return { ...state, samplePictures: action.payload };
    // case ACTIONS.SET_IMAGES_OF_MOBILE_SAMPLES:
    //   return { ...state, imagesOfItems: action.payload };
    case ACTIONS.SET_MOBILE_SAMPLES_FILTERS:
      return { ...state, filters: action.payload };


    default:
      return state;
  }
};

export const convertIntensityToDb = (value, noiseRefDeltaDb, calibration_factor) => {
  const calibratedValue = calibration_factor ? calibration_factor * value : value;

  const dbValue = (20 * Math.log10(calibratedValue || 1)) + (noiseRefDeltaDb || 0);
  const fixedValue = Math.round(dbValue * 10) / 10; // to return 1 decimal digit.
  return fixedValue;
}

export default reducer;
