import * as types from '../constants/ActionTypes';

export function samples(state = {}, action) {
    switch (action.type) {
        case types.REQUEST_SAMPLES:
            return Object.assign({}, state, {
                [action.leak]: { isFetching: true, data: [], total: 0 }
            });
        case types.RECEIVE_SAMPLES:
            return Object.assign({}, state, {
                [action.leak]: { isFetching: false, data: action.samples.data, total: action.samples.total, pageIndex: action.pageIndex+1 }
            });
        case types.REQUEST_SAMPLE_RESOURCES:
          return state;
        case types.RECEIVE_SAMPLE_RESOURCES:
          //const newData = Object.assign({}, state[action.leak].data);
          //// set the resources data for the selected sample:
          //Object.assign(newData[action.index], action.resources); 
          return Object.assign({}, state, {
            [action.leak]: Object.assign({}, state[action.leak], {
              tempResources: {
                id: action.selectedSample,
                res: action.resources,
              }
            })
          });
        default:
            return state;
    }
}
