import React from 'react';
import PropTypes from 'prop-types';

import CTableActions from '../../../containers/CTableActions/CTableActions';
import UIDateFormater from '../../../containers/UIhelper/UIDateFormater';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

require('./HistoryTab.scss');

export default class HistoryTab extends React.Component {

  constructor(props) {
    super(props);
    this.historyItemRender = this.historyItemRender.bind(this);
  }

  historyItemRender(item, key) {
    const { local, alertTypesOpitons, alertStatesOpitons } = this.props;

    let changeNote;
    let userRole;

    // Change notice
    switch (item.ChangesType) {
      case "Comment":
        changeNote = (
          <span>
            <span className="value comment-value">{this.context.t('commented')}:  {item.Value}</span>
          </span>
        );
        break;

      case "Creation":
        changeNote = (
          <span>
            <span className="value comment-value">{this.context.t('created new Alert')}</span>
          </span>
        );
        break;
      case "Type":
        const changedType = alertTypesOpitons == null ? "" : alertTypesOpitons.find((option) => option.value == item.Value);
        changeNote = (
          <span>
            {this.context.t('changed')} <span className= "type">{ item.ChangesType }</span > {this.context.t('to')} <span className="value">{changedType.DisplayName}</span>
          </span>
        );
        break;
      case "State":
        const changedState = alertStatesOpitons == null ? "" : alertStatesOpitons.find((option) => option.value == item.Value);
        changeNote = (
          <span>
            {this.context.t('changed')} <span className= "type">{ item.ChangesType }</span > {this.context.t('to')} <span className="value">{changedState.DisplayName}</span>
          </span>
        );
        break;
      case "Mobile":
        changeNote = (
          <span>
            {this.context.t('update from AQS Mobile. ({details})', { details: item.Value })}
          </span>
        );
        break;

      default:
      changeNote = (
          <span>
            {this.context.t('changed')} <span className= "type">{ item.ChangesType }</span > {this.context.t('to')} <span className="value">{item.Value}</span>
          </span>
        );
        break;
    }

    // User role
    if (item.isSupervisor) {
      userRole = <span className="user-role user-super" title="Supervisor User">S</span>
    }
    else {
      userRole = <span className="user-role user-normal" title="User">U</span>
    }

    return (
      <li key={key} className="history-item">
        {userRole}
        <span className="name">{item.UserName}</span>
        {changeNote}
        <span className="time"> { UIDateFormater(item.TimeOfChange, DateType.DATE_AND_TIME, local) } </span>
      </li>
    );
  }

  render() {
    const { history, uiInfo } = this.props;
    let pHeight = 0.436 * uiInfo.pageHeight;
    return (
      <div
        className='history-tab'
        style={{
          overflow: 'auto',
          maxHeight: `${pHeight}px`
        }}
      >
        <ul>
          {history.map(this.historyItemRender)}
        </ul>

        <CTableActions
          type='history'
        />

      </div>
    );
  }
}

HistoryTab.contextTypes = {
  t: PropTypes.func.isRequired
}
