import * as React from 'react';
import PropTypes from 'prop-types';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { useDispatch } from 'react-redux';
import { sortG5Devices, storeG5DevicesAction } from '../../../actions/DeviceConfigurationAction';
import SortableHeaderCell from '../../SortableHeaderCell/SortableHeaderCell';

import IconButton from '@mui/material/IconButton';
import RefreshIcon from '@mui/icons-material/Refresh';

const CustomeCell = (props) => (
  <Cell>{props.items[props.rowIndex][props.columnKey]}</Cell>
)

const ServerFieldCell = (props) => {
  const val = props.items[props.rowIndex][props.columnKey];
  const valDisplay = val ? val.split('.')[0] : '--';
  return (<Cell>{valDisplay}</Cell>);
}

function StatusesTable(props, context) {
  const { items, sort, local, uiInfo } = props;

  const [selectedItemIndex, setSelectedItemIndex] = React.useState(-1);

  const dispatch = useDispatch();

  const [preFilterItems, setPreFilterItems] = React.useState([]);
  const lastSelectedIndex = React.useRef();

  function renderBatteryField(value) {
    return (value) ? value.toFixed(3) : '--';
  }

  const getMultiRowsBySelection = (index) => {
    const start = Math.min(index, lastSelectedIndex.current);
    const end = Math.max(index, lastSelectedIndex.current);
    const multiSelect = items
    .filter((_item, itemIndex) => {
      if (itemIndex >= start && itemIndex <= end) {
        console.log({d: _item.DeviceID, itemIndex});
      }
      return itemIndex >= start && itemIndex <= end
    })
    .map((item) => item.DeviceID);
    return multiSelect;
  }

  const filterCheckboxRender = (item, index) => (
    <Cell>
      <input
        type='checkbox'
        checked={preFilterItems.indexOf(item.DeviceID) !== -1}
        onChange={(event) => {
          const { checked } = event.target;
          const { shiftKey } = event.nativeEvent;
          console.log({ checked, index });
          let preFiltersArr = [...preFilterItems];
          if (checked) {
            if (!shiftKey) {
              preFiltersArr.push(item.DeviceID);
            } else {
              const multiSelect = getMultiRowsBySelection(index);
              preFiltersArr.push(...multiSelect)
            }
          } else {
            if (!shiftKey) {
              // unchecked selected
              preFiltersArr = preFiltersArr.filter((itemDeviceId) => itemDeviceId !== item.DeviceID)
            } else {
              const multiSelect = getMultiRowsBySelection(index);
              preFiltersArr = preFiltersArr.filter((itemDeviceId) => !multiSelect.includes(itemDeviceId));
            }
          }
          setPreFilterItems(preFiltersArr);
          lastSelectedIndex.current = index;
        }}
      />
    </Cell>
  )

  const handleRowClick = (event, rowIndex) => {
    setSelectedItemIndex(rowIndex);
  }

  const rowClassNameGetter = (rowIndex) => {
    return rowIndex === selectedItemIndex ? 'active-row' : null;
  }

  const sortTable = {
    sortDir: sort.direction,
    sortBy: sort.field,
    sort: (columnKey) => {
      dispatch(sortG5Devices(columnKey))
      setSelectedItemIndex(-1);
    },
  }

  return (
    <section>
      <Table
        rowsCount={items.length}
        width={uiInfo.pageWidth / 1.1635}
        maxHeight={uiInfo.pageHeight / 1.0909}
        headerHeight={50}
        rowHeight={40}
        onRowClick={handleRowClick}
        rowClassNameGetter={rowClassNameGetter}
      >
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey=''
          width={60}
          header={<Cell><button onClick={() => {
            props.handleSelection(preFilterItems);
            setPreFilterItems([])
          }}>Filter</button></Cell>}
          cell={(props) => filterCheckboxRender(items[props.rowIndex], props.rowIndex)}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='#'
          width={40}
          header={<Cell>#</Cell>}
          cell={(props) => <Cell>{props.rowIndex + 1}</Cell>}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='ID'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='DeviceID'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column fixed
          cellClassName='fixed-table-cell-class'
          align='center'
          columnKey='LastStatusReportTime'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = items[props.rowIndex][props.columnKey]
            const time = UIDateFormater(value, DateType.DATE_AND_TIME, local);
            return <Cell>{time}</Cell>
          }
          }
        />
        <Column
          align='center'
          columnKey='BatteryLevel'
          width={75}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = items[props.rowIndex][props.columnKey];
            return <Cell>{renderBatteryField(value)}</Cell>
          }}
        />
        <Column
          align='center'
          columnKey='BatteryLevelPost'
          width={75}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => {
            const value = items[props.rowIndex][props.columnKey];
            return <Cell>{renderBatteryField(value)}</Cell>
          }}
        />
        <Column
          align='center'
          columnKey='AccessTechnologyName'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={(props) => <Cell>{items[props.rowIndex][props.columnKey]}</Cell>}
        />
        <Column
          align='center'
          columnKey='IEMI'
          width={140}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='ICCID'
          width={180}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          align='center'
          columnKey='StatusTimeZone'
          width={160}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='HardwareRevision'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='FirmwareRevision'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='RSRQ_db'
          width={60}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='RSRP_dbm'
          width={60}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='StatusFmReceiverChannel_10k'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='APN'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='Server'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<ServerFieldCell items={items} />}
        />
        <Column
          columnKey='FallbackServer'
          width={100}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<ServerFieldCell items={items} />}
        />
        <Column
          columnKey='modem_fw_rev'
          width={120}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='dfu_fw_rev'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='dfu_type'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='dfu_block_num'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
        <Column
          columnKey='random_backoff_connect'
          width={80}
          header={<SortableHeaderCell sortTable={sortTable} />}
          cell={<CustomeCell items={items} />}
        />
      </Table>
      <footer style={{ backgroundColor: 'white' }}>
        <IconButton onClick={props.reloadDevices}>
          <RefreshIcon fontSize='small'/>
        </IconButton>

        <p style={{ float: 'right', margin: '5px 10px' }}>
        <span>
          Selected: {selectedItemIndex + 1}.
        </span>
        &emsp;
        <span>
          {items.length} Items
        </span>
        </p>
      </footer>
    </section>
  );
}

StatusesTable.contextTypes = {
  t: PropTypes.func.isRequired
};

StatusesTable.propTypes = {
  items: PropTypes.array.isRequired,
  local: PropTypes.string,
  reloadDevices: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
  workingMode: PropTypes.string.isRequired,
};

export default StatusesTable;
