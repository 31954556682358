import * as types from '../constants/ActionTypes';

const initialState = {
    isFetching: false,
    items: [],
    project: [],
    projectIndexMap: {},
    calculatedTotalLengthTable: {
      materialsArray: [],
      diametersArray: [],
    },
};

export function selectedProject(state = '0', action) {
    switch (action.type) {
        case types.SELECT_PROJECT:
            return action.project;
        default:
            return state;
    }
}

export function projectsList(state = initialState, action) {
    switch (action.type) {
        case types.REQUEST_PROJECTS:
            return Object.assign({}, state, {
                isFetching: true,
            });
        case types.RECEIVE_PROJECTS:
            return Object.assign({}, state, {
                items: action.projects || [],
                projectIndexMap: action.projectIndexMap,
                isFetching: false,
            });
        case types.SORT_PROJECTS_LOCALY:
          return Object.assign({}, state, {
            items: action.projects || [],
            projectIndexMap: action.indexMap,
          });
        case types.SET_PROJECT_CENTER_RES:
          const items = Object.assign([], state.items);
          items[action.index] = Object.assign({}, items[action.index], {
            RefPointLat: action.center[1],
            RefPointLng: action.center[0],
          });
          return Object.assign({}, state, {
            items: items,
          });
        case types.REQUEST_TOTAL_PIPES_LENGTH:
          return Object.assign({}, state, {
            calculatedTotalLengthTable: {
              materialsArray: [],
              diametersArray: [],
            }
          });
        case types.RECEIVE_TOTAL_PIPES_LENGTH:
          return Object.assign({}, state, {
            calculatedTotalLengthTable: action.calculatedTotalLengthTable
          });

        default:
            return state;
    }
}
