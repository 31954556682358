import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, DeleteModal, FieldTimezonePicker, ProjectAdvancedForm } from '../../components';
import { Field, reduxForm } from 'redux-form';
import { SYSTEM_RESOURCE } from '../../constants/EndPoints';

require('./Projects.scss');

class ProjectDetails extends React.Component {
  constructor(props) {
        super(props);

        this.toggleDrawMode = this.toggleDrawMode.bind(this);
        this.renderBasicParams = this.renderBasicParams.bind(this);
        this.nextPage = this.nextPage.bind(this);
        this.previousPage = this.previousPage.bind(this);

        this.state = {
          page: 1,
          advanced: false,
        };
    }

    toggleDrawMode() {
      this.props.changeDrawMode(this.props);
    }

    nextPage() {
      this.setState({ page: this.state.page + 1 });
    }

    previousPage() {
      this.setState({ page: this.state.page - 1 });
    }

    required = (value) => {
      const error = (value) ? undefined : this.context.t('Required');
      return error;
    }

  renderBasicParams() {
    const { handleSubmit, user, customerOptions, pristine, submitting, isNew, projectOptions, mapDrawMode, projectCurrentBounding } = this.props;
    const labelClassName = 'col-xs-4 control-label';
    const lsbelClassNameLeftColumn = 'col-xs-6 control-label';
    const controlClassName = 'col-xs-6 control-input';
    const fieldsClass = 'col-xs-6 control-input';
    const fieldsClassLeftColumn = 'col-xs-6 control-input';
    const divStyle = { float: 'left', width: '100%' };
    const imperialStyle = {width: '20% !important', height: '20px !important'};
    const buttonTitle = (isNew) ? this.context.t('Create New Project') : this.context.t('Update Project Details');
    const drawTitle = this.props.projectCurrentBounding ? "Edit": "Draw";

    return (
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <div style={{ display: 'flex' }}>
          <div style={divStyle}>
            <div className="form-group">
              <label className={lsbelClassNameLeftColumn}>{this.context.t('Project Name')}:</label>
              <div className={fieldsClassLeftColumn}>
                <Field
                  name="ShortName"
                  component={Input}
                  type="text"
                  className="form-control"
                  validate={this.required}
                  disabled={!user.editMode}
                />
              </div>
            </div>

            <div className="form-group">
              <label className={lsbelClassNameLeftColumn}>{this.context.t('EPSG')}:</label>
              <div className={fieldsClassLeftColumn}>
                <Field
                  name="EPSG"
                  placeholder={this.context.t('Meteric / Feet EPSG Only')}
                  component={Input}
                  type="text"
                  className="form-control"
                  validate={this.required}
                  disabled={!isNew} />
              </div>
            </div>
            <div className="form-group">
              <label className={lsbelClassNameLeftColumn} title="Sensor Activity Threshold">{this.context.t('Sensor Activity Threshold')}:</label>
              <div className={fieldsClassLeftColumn}>
                <Field name="SensorActivityRatioDaysBack" component={Input} type="number" className="form-control" disabled={!user.editMode} />
              </div>
            </div>

        {/* Draw Mode Button */}
            { !isNew &&
          <div className="form-group">
            <label className={lsbelClassNameLeftColumn}>{this.context.t(`Click to ${drawTitle} project area`)}:</label>
            <div className={controlClassName}>
              <a onClick={this.toggleDrawMode} className="btn btn-info"> {drawTitle} </a>
            </div>
          </div>
        }

        {!isNew &&
          <div className="form-group">
            <label className="col-xs-10 control-label">{this.context.t('Click to set current map position as project center')}:</label>
            <div className="col-xs-2 control-input">
              <img
                src={require('./../../../images/ic_center_of_project.png')}
                className="btn btn-info center"
                onClick={() => {
                  this.props.setProjectCenter();
                }
                }
              />
            </div>
          </div>
        }

        {SYSTEM_RESOURCE != 'trimble' &&
          <div className="form-group">
            <label className="col-xs-6 control-label"> {this.context.t('Use Imperial units in Graphs')}:</label>
            <div className={controlClassName}>
              <Field name="UseFeetInMatlabGraphs" component={Input} type="checkbox" className={imperialStyle} disabled={!user.editMode} />
            </div>
          </div>
        }
          </div>

          <div style={divStyle}>
            <div className="form-group">
              <label className={labelClassName}>{this.context.t('Customer')}:</label>
              <div className={fieldsClass}>
                <Field
                  name="CustomerID"
                  component={(props) => (
                    <Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={false}
                      backspaceRemoves={false}
                      multi={false}
                      options={customerOptions}
                    />
                  )}
                />
              </div>
            </div>

            <div className="form-group">
              <label className={labelClassName}>{this.context.t('Mode')}:</label>
              <div className={fieldsClass}>
                <Field
                  name="PilotMode"
                  component={(props) => (
                    <Select {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={false}
                      backspaceRemoves={false}
                      multi={false}
                      options={projectOptions}
                    />
                  )}
                />
              </div>
            </div>

            {false &&
              <div className="form-group">
                <label className={labelClassName}>{this.context.t('Timezone')}:</label>
                <div className={fieldsClass}>
                  <Field
                    name="Timezone"
                    component={FieldTimezonePicker}
                  />
                </div>
              </div>
            }
          </div>
        </div>

        {false &&
          <div className="form-group">
            <label className={labelClassName}>{this.context.t('AllowProd2Run')}:</label>
            <div className={controlClassName}>
              <Field name="AllowProd2Run" component={Input} type="checkbox" className="checkbox-control" disabled={!user.editMode} />
            </div>
          </div>
        }

        {user.isAQS &&
          <a
            style={{right: '6px', position: 'absolute'}}
            className="btn btn-info"
            onClick={this.nextPage}>

            {this.context.t('Advanced')}

          </a>
        }

        <div className="form-actions">
          {!isNew &&
            <a style={{ float: 'left', marginRight: '2px' }} className="btn btn-info" onClick={() => {
              this.props.updateProjectPipes();
            }
            }>
              {this.context.t('Update Pipes')}
            </a>
          }

          { !isNew && user.isAQS &&
            <a style={{ float: 'left', marginRight: '2px' }} className="btn btn-info" onClick={() => { this.props.UpdateIquariusPipes(this.props.initialValues.ID) }}>
              {this.context.t('Update iQuarius')}
            </a>
          }

          {!isNew &&
            <DeleteModal
              id={this.props.initialValues.ID}
              name={this.props.initialValues.Name}
              type={'project'}
              onDelete={this.props.onDelete}
            />
          }
          <button
            className="btn btn-success btn-submit"
            disabled={(this.props.projectEditedPoints ? submitting : pristine || submitting )}
            type="submit">
            {buttonTitle}
          </button>
        </div>
      </form>
    );
  }

  render() {
    const { user } = this.props;
    const { page } = this.state;
    return (
      <div>
        {page === 1 && this.renderBasicParams()}
        {user.isAQS && page === 2 && <ProjectAdvancedForm previousPage={this.previousPage}/>}
      </div>
    );
  }
}

ProjectDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

const projectDetails = reduxForm({
  form: 'projectDetails', // a unique name for this form
  enableReinitialize: true,
})(ProjectDetails);

export default projectDetails;
