/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Plotly from 'plotly.js';

import Plot from 'react-plotly.js';

import { PuffLoader } from 'react-spinners';

import groupBy from 'json-groupby';

import AppModal from './AppModal';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import { makeStyles } from '@mui/styles';

import { jsPDF } from "jspdf";
import 'jspdf-autotable';

import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const graphMargin = 200;

function getMonthDays(year, month) {
  const now = new Date();
  const date = new Date(year, month, 0); // trd' param: 0 = the last day of the month
  const daysOnMonth = (date.getFullYear() === now.getFullYear() && date.getMonth() === now.getMonth()) ? now.getDate() : date.getDate();
  const days = [];
  for (let i = 0; i < daysOnMonth; i++) {
    const day = new Date(year, month - 1, i + 1, 0, 0, 0, 0);
    days.push(day);
  }
  return (days);
}

function generateTabelBodyForPdf(activityData, data, days) {
  const result = [];
  let totalActive = 0;
  let totalNonActive = 0;
  const totalDayActive = new Array(days.length).fill(0);

  data.forEach((sensorData) => {
    const activeDays = sensorData.dates;//.map((activeDayMillis) => new Date(activeDayMillis));

    let activeDaysCount = 0;
    let nonActiveDaysCount = 0;
    const daysActiveRow = new Array(days.length).fill('X');

    const deviceActivityData = activityData.find((deviceData) => deviceData.DeviceID === sensorData.deviceId);
    let installDate;
    if (deviceActivityData.DeviceInstallationDate) {
      installDate = new Date(deviceActivityData.DeviceInstallationDate);
    }

    days.forEach((day, index) => {
      if (activeDays.indexOf(day.getDate()) !== -1) {
        daysActiveRow[index] = 'V';
        totalDayActive[index] += 1;
        activeDaysCount += 1;
      } else if (!installDate || days.find(findItemInSameDayCallback(installDate)) > day) {
        daysActiveRow[index] = '-';
      } else {
        daysActiveRow[index] = 'X';
        nonActiveDaysCount += 1;
      }

      // if (activeDays.indexOf(day.getTime()) !== -1) {
      //   daysActiveRow[index] = 'V';
      //   totalDayActive[index] += 1;
      // }
    });

    const row = [
      sensorData.deviceId,  /* Device ID*/
      sensorData.sensorId,  /* Sensor ID*/
      activeDaysCount,      /* Active Days */
      nonActiveDaysCount,   /* Non Active Days */
      ...daysActiveRow,
      // ...days.map((day) => {
      //   if (activeDays.indexOf(day.getTime()) === -1) return 'X';
      //   else return 'V';
      // })

    ];

    totalActive += activeDaysCount;
    totalNonActive += nonActiveDaysCount;

    result.push(row);
  });
  result.unshift([
    '',
    'Total',
    totalActive,
    totalNonActive,
    ...totalDayActive
  ]);
  return result;
}

const plotRenderContainerId = 'plot-render-container';

const xlsColumns = [
  {title: "Installation Date", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//pixels width
  {title: "Device ID", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//char width
  {title: "Sensor ID", width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },//char width
  {title: "SIM", width: {wch: 25}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Installation Point ID", width: {wch: 22}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Days Worked", width: {wpx: 100}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Days not Worked", width: {wpx: 100}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "Potential Active Days", width: {wch: 23}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
  {title: "%", width: {wpx: 70}, style: { alignment: { horizontal: 'center', vertical: 'center'}} },
];

export default function SensorsActivityModal(props, context) {

  const [selectedMonth, setSelectedMonth] = React.useState(new Date().toISOString().substring(0,'YYYY-MM'.length));
  const [xlsData, setXlsData] = React.useState({});

  React.useEffect(() => {
    // set the data for export to xlsx:
    if (props.data && props.data.activityData && props.data.daysReport) {
      const currentDate = new Date();
      const [sYear, sMonth] = selectedMonth.split('-').map(Number);
      const reportDate = new Date(sYear, sMonth, 0);
      let totalDaysInMonth;
      if (reportDate.getFullYear() === currentDate.getFullYear() && reportDate.getMonth() === currentDate.getMonth()) {
        totalDaysInMonth = currentDate.getDate();
      } else {
        totalDaysInMonth = reportDate.getDate();
      }

      const data = [
        {
          columns: xlsColumns,
          data: props.data.activityData.map((item) => {
            const installDate = new Date(item.DeviceInstallationDate);

            const totalActiveDays = item.DaysCount;
            let calculatedDays;

            if (installDate.getFullYear() !== reportDate.getFullYear() || installDate.getMonth() !== reportDate.getMonth()) {
              calculatedDays = totalDaysInMonth;
            } else {
              calculatedDays = totalDaysInMonth - (installDate.getDate() - 1);
            }

            const unWorkedDaysInMonth = calculatedDays - totalActiveDays;
            const activePrecentage = ((totalActiveDays / calculatedDays) * 100).toFixed();

            // if (installDate.getFullYear() === reportDate.getFullYear() && installDate.getMonth() === reportDate.getMonth()) {
            //   totalActiveDays = ((item.DaysCount / (totalDaysInMonth - installDate.getDate())) * 100).toFixed();
            //   unWorkedDaysInMonth = totalDaysInMonth - installDate.getDate() - item.DaysCount;
            // } else {
            //   totalActiveDays = ((item.DaysCount / totalDaysInMonth) * 100).toFixed();
            //   unWorkedDaysInMonth = totalDaysInMonth - item.DaysCount;
            // }

            return [
              { value: new Date(item.DeviceInstallationDate).toLocaleDateString(), style: {font: {sz: "12"}} },
              { value: Number(item.DeviceID), style: { font: {sz: "12"}, numFmt: '0'} },
              { value: Number(item.SensorID), style: { font: {sz: "12"}, numFmt: '0'} },
              { value: item.SIMnumber || '', style: { font: {sz: "12"}} },
              { value: Number(item.SOPointID), style: { font: {sz: "12"} } },
              { value: Number(totalActiveDays), style: { font: {sz: "12"} } },
              { value: Number(unWorkedDaysInMonth), style: { font: {sz: "12"} } },
              { value: calculatedDays, style: { font: {sz: "12"} } },
              { value: Number(activePrecentage), style: { font: {sz: "12", numFmt: '0%'} } },
            ];
          })
        }
      ];
      setXlsData(data);
    }
  }, [props.data]);

  const { month, year } = props;
  let pieData = [];

  let activityData = [];
  let daysActivityReport = [];

  if (props.data && props.data.activityData) {
    activityData = props.data.activityData;
  }
  if (props.data && props.data.daysReport) {
    daysActivityReport = props.data.daysReport;
  }

  if (activityData.length > 0) {
    const attrGroup = groupBy(activityData, ['DaysCount']);
    const keys = Object.keys(attrGroup).map(Number);
    const values = Object.values(attrGroup).map((arr) => arr.length);

    pieData.push({
      type: 'pie',
      labels: keys,
      values: values,
      textinfo: "label+percent",
      texttemplate: `%{label} days (%{percent}, %{value}/${activityData.length})`,
      textposition: 'outside',
    });
  }

  const pieLayout = {
    showlegend: false,
    height: 500,
    width: 600,
    margin: {
      t: graphMargin - (graphMargin * 0.8),
      b: graphMargin,
      l: graphMargin,
      r: graphMargin
    },
  };

  async function exportPdf() {
    const plotElement = await Plotly.plot(plotRenderContainerId, pieData, pieLayout);
    const plotImage = await Plotly.toImage(plotElement, { format: 'png' });

    const monthDays = getMonthDays(year, month);
    const tableBody = generateTabelBodyForPdf(activityData, daysActivityReport, monthDays);
    const tableHeader = [ 'Device ID', 'Sensor ID', 'Active', 'Non Active', ...monthDays.map((d) => d.getDate())];

    const doc = new jsPDF({ orientation: 'landscape' });
    const horizontalPadding = 10;
    doc.setFontSize(16);
    doc.text(`Sensors Activity Report`, horizontalPadding, 10);
    doc.setFontSize(12);
    doc.text(`generate for ${month}/${year}`, horizontalPadding, 25);

    doc.addImage(plotImage, 'PNG', horizontalPadding, 30, 120, 100, 'pie graph', 'NONE', 0);
    doc.autoTable({
      styles: {
        valign: 'center',
        fontSize: 5,
      },
      startX: 0,
      startY: 110,
      columns: tableHeader,
      body: tableBody,
      minCellWidth: 5,
      cellPadding: 2,
      didParseCell: (data) => {
        if (data.section === 'body') {

          data.cell.cellPadding = 5;

          switch (data.cell.raw) {
            case 'V':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'green';
              break;
            case 'X':
              data.cell.styles.font = 'courier';
              data.cell.styles.fontStyle = 'bolditalic';
              data.cell.styles.textColor = 'red';
              break;

            default:
              break;
          }
        }
      }
    });

    doc.save('Sensors Activity Report');
  }

  function selectMonth(e) {
    console.log(e.target.value);
    setSelectedMonth(e.target.value);
  }

  function loadData() {
    const [sYear, sMonth] = selectedMonth.split('-').map(Number);
    props.loadData(sYear, sMonth);
  }

  const loadingView = (
    <div style={{ margin: 'auto', width: 'fit-content' }}>
      <PuffLoader
        size={100}
        loading
      />
    </div>
  );

  return (
    <AppModal
      closeIcon
      fullWidth
      maxWidth='xl'
      open={props.isVisible}
      title={context.t('Sensors Activity')}
      onClose={props.close}
      content={(
        <div style={{ height: '75vh' }}>
          { props.isFetching && loadingView}
          { !props.isFetching && props.selectedProject &&
            <div style={{
              display: 'flex',
              flexDirection: 'column'
            }}>
            {/* <Button variant='contained' color='primary' onClick={loadData}>{context.t('IQ100B Reports')}</Button> */}
              <div>
                <Typography component='p' variant='p'>{context.t('Select Month')}</Typography>
                <input type="month" min="2016-01" onChange={selectMonth} value={selectedMonth} />
                <Button variant='contained' color='primary' onClick={loadData}>{context.t('Generate Report')}</Button>
              </div>

              <div style={{ display: 'inline-flex' }}>
                <div id={plotRenderContainerId} style={{ display:'none' }}>HIDDEN DIV FOR GENERATE PLOTLY IMAGE</div>
                <Plot data={pieData}
                  layout={pieLayout} />
                <SensorsActivityReportTable
                  data={daysActivityReport}
                  activityData={activityData}
                  date={new Date(year, month, 0)} // trd' param: 0 = the last day of the month
                  year={year}
                  month={month}
                />
              </div>

            <div>
              <Button
                style={{ width: 'max-content' }}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
                onClick={exportPdf}
              >
                {context.t('Save as PDF')}
              </Button>

              <ExcelFile
                filename={`Sensors Activity - ${props.selectedProject.Name} ${month}-${year}`}
                element={
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                  >{context.t('Save as Excel')}</Button>
              }>
                <ExcelSheet dataSet={xlsData} name={'Sensors Activity'}/>
              </ExcelFile>
            </div>
          </div>
          }

        </div>
      )}
    />
  );
}

SensorsActivityModal.contextTypes = {
  t: PropTypes.func.isRequired
};

SensorsActivityModal.propTypes = {
  close: PropTypes.func,
  data: PropTypes.objectOf({
    activityData: PropTypes.arrayOf({
      DaysCount: PropTypes.number,
      DeviceID: PropTypes.string,
    }),
    daysReport: PropTypes.arrayOf({
      deviceId: PropTypes.string,
      dates: PropTypes.arrayOf(PropTypes.number)
    })
  }),
  isFetching: PropTypes.bool,
  isVisible: PropTypes.bool,
  loadData: PropTypes.func,
  month: PropTypes.number,
  selectedProject: PropTypes.object,
  year: PropTypes.number,
};

SensorsActivityModal.defaultProps = {
  month: new Date().getMonth(),
  year: new Date().getFullYear(),
};

/**
 *
 * @param {*} props
 * @returns {React.FC}
 */

 const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: '600px',
  },
  cell: {
    borderLeft: '1px solid #eee',
    '&.$nth-of-type(odd)': {
      background: '#aaa'
    }
  }
});

function findItemInSameDayCallback(checkedDay) {
  return (sensorActiveDay) => {
    let isSensorWorked = false;
    if (checkedDay.getFullYear() === sensorActiveDay.getFullYear() &&
      checkedDay.getMonth() === sensorActiveDay.getMonth() &&
      (checkedDay.getDate() === sensorActiveDay.getDate())
    ) {
      isSensorWorked = true;
    }
    return isSensorWorked;
  };
}

function SensorsActivityReportTable(props) {
  const classes = useStyles();
  const { year, month, activityData } = props;

  const days = getMonthDays(year, month);

  return (
    <TableContainer component={Paper} className={classes.container}>
      <Table stickyHeader size='small'>
        <TableHead>
          <TableRow>
            <TableCell className={classes.cell}>Device ID</TableCell>
            <TableCell className={classes.cell}>Sensor ID</TableCell>
            <TableCell className={classes.cell}>Total</TableCell>
            {
              days.map((day) => <TableCell className={classes.cell} padding='none' key={day.getDate()}>{day.getDate()}</TableCell>)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.data.map((sensorData, index) => {
              const activeDays = sensorData.dates; //.map((activeDayMillis) => new Date(activeDayMillis));

              const deviceActivityData = activityData.find((deviceData) => deviceData.DeviceID === sensorData.deviceId);
              let installDate;
              if (deviceActivityData.DeviceInstallationDate) {
                installDate = new Date(deviceActivityData.DeviceInstallationDate);
              }

              return (
                <TableRow hover key={index}>
                  <TableCell className={classes.cell}>{sensorData.deviceId}</TableCell>
                  <TableCell className={classes.cell}>{sensorData.sensorId}</TableCell>
                  <TableCell className={classes.cell}>{activeDays.length}</TableCell>
                  {
                    days.map((day) => {
                      let char;
                      let style;

                      if (activeDays.indexOf(day.getDate()) !== -1) {
                        char = <span>&#x2714;</span>; // V
                        style = {color: 'green'};
                      } else if (!installDate || days.find(findItemInSameDayCallback(installDate)) > day) {
                        char = <span>-</span>; // -
                        style = {color: 'gray'};
                      } else {
                        char = <span>&#x2718;</span>; // X
                        style = {color: 'red'};
                      }

                      return (<TableCell className={classes.cell} style={style} padding='none' key={day.getTime()}>{char}</TableCell>);
                    })
                  }
                </TableRow>);
            })
          }
        </TableBody>
      </Table>
    </TableContainer>

  );
}

SensorsActivityReportTable.propTypes = {
  activityData: PropTypes.array,
  data: PropTypes.arrayOf(PropTypes.objectOf({
    deviceId: PropTypes.number,
    dates: PropTypes.arrayOf(PropTypes.number)
  })),
  date: PropTypes.objectOf(Date),
  month: PropTypes.number,
  year: PropTypes.number,
};


