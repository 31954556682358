import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Select, Input } from '../../components';
import { Field, reduxForm } from 'redux-form';

const classNames = require('classnames');

class PCAModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      advancedOpen: false,
    };
    this.advancedToggle = this.advancedToggle.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSave = this.onSave.bind(this);
  }

  advancedToggle() {
    this.setState({ advancedOpen: !this.state.advancedOpen });
  }

  // Close dialog
  onClose() {
    this.props.closePCADialog();
    this.setState({ advancedOpen: false });
  }

  onSave() {
    this.props.closePCADialog();
    this.props.submitAction('pipeAssessment');
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isOpen != this.state.open) {
      this.setState({
        open: nextProps.isOpen
      });
    }
  }

  render() {
    const { options } = this.props;

    const panelClasses = classNames(
      'noShow',
      { 'show': this.state.advancedOpen }
    );
    const checkBoxFieldStyle = {
      display: 'inline-flex'
    };


    return (
      <AppModal
        closeIcon
        open={this.state.open}
        onClose={this.onClose}
        title={this.context.t('Pipe Condition Assessment Details')}
        content={(
          <form className="form-horizontal sticky-actions" onSubmit={this.props.handleSubmit}>
            <fieldset>
              <legend>{this.context.t('Event Information')}</legend>
              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('Pipe Material')}:</label>
                    <Field name="PipeMaterialCode"
                      component={(props) => (
                        <Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PipeMaterialEnum} />
                      )}
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>

                      <span>{this.context.t('Pipe Diameter')}:</span>


                    </label>

                    <Field
                      name="PipeDiameterInch"
                      // component={Input} type="number" className="form-control"/>
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={[
                            { label: '0.75 "', value: 0.75 },
                            { label: '1.00 "', value: 1.00 },
                            { label: '1.25 "', value: 1.25 },
                            { label: '2.00 "', value: 2.00 },
                            { label: '2.50 "', value: 2.50 },
                            { label: '3.00 "', value: 3.00 },
                            { label: '4.00 "', value: 4.00 },
                            { label: '5.00 "', value: 5.00 },
                            { label: '6.00 "', value: 6.00 },
                            { label: '8.00 "', value: 8.00 },
                            { label: '10.00 "', value: 10.00 },
                            { label: '12.00 "', value: 12.00 },
                            { label: '16.00 "', value: 16.00 },
                            { label: '20.00 "', value: 20.00 },
                            { label: '24.00 "', value: 24.00 },
                            { label: '28.00 "', value: 28.00 },
                            { label: '32.00 "', value: 32.00 },
                            { label: '36.00 "', value: 36.00 },
                            { label: '40.00 "', value: 40.00 }
                          ]}
                        />)
                      }
                    />
                  </div>

                  {/* <div className="col-xs-3">
                  <label>{this.context.t('Pipe Coating')}:</label>
                  <Field name="PipeCoating" component={Input} type="number" className="form-control" />
              </div> */}

                  <div className="col-xs-3">
                    <label>{this.context.t('Pipe Coating')}:</label>
                    <Field name="PipeCoating"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PipeCoatingCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Pipe Install Year')}:</label>
                    <Field name="PlacementYear" component={Input} type="number" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('Pipe Thickness (at break)')}:</label>
                    <Field name="PipeThicknessInch_BreakLocation" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Crack Diameter (inches)')}:</label>
                    <Field name="PipeCrackDiameterInch" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Water Loss ({unit}/h)', { unit: this.props.units === 'm' ? 'l' : 'g' })}:</label>
                    <Field name="WaterLossLH" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Pipe ID')}:</label>
                    <Field name="PipeID" component={Input} type="text" className="form-control" />
                  </div>

                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">
                  <div className="col-xs-3">
                    <label>{this.context.t('Event Date')}:</label>
                    <Field name="EventDate" component={Input} type="date" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Event Nature')}:</label>
                    <Field name="EventNatureOfBreakCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCANatureOfBreakCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Event Cause')}:</label>
                    <Field name="EventCauseOfBreakCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCACauseOfBreakCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Corrosion Type')}:</label>
                    <Field name="EventCorrosionTypeCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCACorrosionTypeCodesEnum} />)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('Repair Date')}:</label>
                    <Field name="RepairDate" component={Input} type="date" className="form-control" />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Repair Type')}:</label>
                    <Field name="RepairType"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCARepairCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-6">
                    <label>{this.context.t('Nature of Break Comment')}:</label>
                    <Field name="EventNatureOfBreakComment" component={Input} type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset>
              <legend>{this.context.t('Location Information')}</legend>
              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3">
                    <label>{this.context.t('Ground Type')}:</label>
                    <Field name="LocationGroundTypeCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCAGroundTypeCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Traffic')}:</label>
                    <Field name="LocationTrafficCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCATrafficCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Street Surface')}:</label>
                    <Field name="LocationStreetSurfaceCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCAStreetSurfaceCodesEnum} />)
                      }
                    />
                  </div>

                  <div className="col-xs-3">
                    <label>{this.context.t('Weather Conditions')}:</label>
                    <Field name="LocationWeatherConditionCode"
                      component={(props) =>
                        (<Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCAWeatherConditionsCodesEnum} />)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12">

                  <div className="col-xs-3" style={{ display: 'grid' }}>
                    <label style={checkBoxFieldStyle}><Field name="LocationIsElectrolysis" component={Input} type="checkbox" className="checkbox-control pca" />
                      {this.context.t('Is Elecrolysis')}
                    </label>
                    <label style={checkBoxFieldStyle}><Field name="LocationIsRocks" component={Input} type="checkbox" className="checkbox-control pca" />
                      {this.context.t('Is Rocks')}
                    </label>
                    <label style={checkBoxFieldStyle}><Field name="LocationIsVoids" component={Input} type="checkbox" className="checkbox-control pca" />
                      {this.context.t('Is Voids')}
                    </label>
                  </div>

                  <div className="col-xs-3 text-dir-button">
                    <label>{this.context.t('Resistivity')}:</label>
                    <Field name="LocationResistivityOh_MsCm" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3 text-dir-button">
                    <label>{this.context.t('Frost Depth')}:</label>
                    <Field name="LocationFrostDepthInch" component={Input} type="number" className="form-control" />
                  </div>

                  <div className="col-xs-3 text-dir-button">
                    <label>{this.context.t('Snow Depth')}:</label>
                    <Field name="LocationSnowDepthInch" component={Input} type="number" className="form-control" />
                  </div>
                </div>
              </div>

              <div className="form-group">
                <div className="col-xs-12 text-dir-button">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('Proximity to other utilities')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="LocationProximityOtherUtilities" component={Input} type="text" className="form-control" />
                  </div>
                </div>
              </div>

            </fieldset>

            <fieldset>
              <legend>{this.context.t('General Information')}</legend>
              <div className="form-group">
                <div className="col-xs-12">
                  <div className="col-xs-4 text-dir-button">
                    <label>{this.context.t('Reported By')}:</label>
                    <Field name="GeneralReportedBy" component={Input} type="text" className="form-control" />
                  </div>

                  <div className="col-xs-8 text-dir-button">
                    <label>{this.context.t('Comments')}:</label>
                    <Field name="GeneralComment" component={Input} type="text" className="form-control" />
                  </div>
                </div>
              </div>
            </fieldset>

            <fieldset className={panelClasses}>
              <legend>{this.context.t('Historical Information')}</legend>
              <div className="form-group">
                <div className="col-xs-3">
                  <label style={checkBoxFieldStyle}><Field name="HistoricalIsAirTempSuddenChange" component={Input} type="checkbox" className="checkbox-control pca" />
                    {this.context.t('Sudden Air Temp Change')}
                  </label>
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('Temp Avg')}:</label>
                  <Field name="HistoricalAirTempChangeAvgClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('Temp Rise')}:</label>
                  <Field name="HistoricalAirTempChangeRiseClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('Temp Fail')}:</label>
                  <Field name="HistoricalAirTempChangeFailClesius" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-3">
                  <label style={checkBoxFieldStyle}><Field name="HistoricalIsWaterTempSuddenChange" component={Input} type="checkbox" className="checkbox-control pca" />
                    {this.context.t('Sudden Water Temp Change')}
                  </label>
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('Temp Avg')}:</label>
                  <Field name="HistoricalWaterTempChangeAvgClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('Temp Rise')}:</label>
                  <Field name="HistoricalWaterTempChangeRiseClesius" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-3 text-dir-button">
                  <label>{this.context.t('Temp Fail')}:</label>
                  <Field name="HistoricalWaterTempChangeFailClesius" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('Bedding Type')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="HistoricalInstalltionBeddingCode"
                      component={(props) =>
                        <Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCAGroundTypeCodesEnum} />
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-8 text-dir-button">
                  <label>{this.context.t('Bedding Comment')}:</label>
                  <Field name="HistoricalInstalltionBeddingComment" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('Backfill')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="HistoricalInstalltionBackfillCode"
                      component={(props) =>
                        <Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCAGroundTypeCodesEnum} />
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-8 text-dir-button">
                  <label>{this.context.t('Backfill Comment')}:</label>
                  <Field name="HistoricalInstalltionBackfillComment" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('Prior 2 Weeks Weather')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="Historical2WeeksPriorWeatherCode"
                      component={(props) =>
                        <Select {...props}
                          placeholderTitle={this.context.t('Select...')}
                          options={options.PCAWeatherConditionsCodesEnum} />
                      }
                    />
                  </div>
                </div>

                <div className="col-xs-4">
                  <label>{this.context.t('Pipe Type')}:</label>
                  <Field name="HistoricalPipeTypeCode"
                    component={(props) =>
                      <Select {...props}
                        placeholderTitle={this.context.t('Select...')}
                        options={options.PipeMaterialEnum} />
                    }
                  />
                </div>

                <div className="col-xs-4 text-dir-button">
                  <label>{this.context.t('Pipe Thickness')}:</label>
                  <Field name="HistoricalPipeThicknessInch" component={Input} type="text" className="form-control" />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4 text-dir-button">
                  <label style={{ paddingLeft: '15px' }}>{this.context.t('Pipe Length')}:</label>
                  <div className="col-xs-12 control-input">
                    <Field name="HistoricalPipeLayingLengthMeter" component={Input} type="text" className="form-control" />
                  </div>
                </div>

                <div className="col-xs-4 text-dir-button">
                  <label>{this.context.t('Design Operating Press')}:</label>
                  <Field name="HistoricalPipeDesignOperatingPressurePSI" component={Input} type="text" className="form-control" />
                </div>

                <div className="col-xs-4">
                  <label>{this.context.t('Compaction')}:</label>
                  <Field name="HistoricalCompactionCode"
                    component={(props) =>
                      <Select {...props}
                        placeholderTitle={this.context.t('Select...')}
                        options={options.PCACompactionCodesEnum} />
                    }
                  />
                </div>

              </div>

              <div className="form-group">

                <div className="col-xs-4">
                  <label style={checkBoxFieldStyle}><Field name="HistoricalIsPreviousBreakReported" component={Input} type="checkbox" className="checkbox-control pca" />
                    {this.context.t('Is Previous Break Reported')}
                  </label>
                </div>

                <div className="col-xs-4 text-dir-button">
                  <label>{this.context.t('Previous Break Operating Press')}:</label>
                  <Field name="HistoricalIsPreviousBreakPressurePSI" component={Input} type="text" className="form-control" />
                </div>

              </div>

            </fieldset>
            <div>
              <a
                className='btn btn-info'
                onClick={this.advancedToggle}>
                {this.context.t('Advanced')}
              </a>
              <a
                className='btn btn-success'
                onClick={this.onSave}>
                {this.context.t('Save')}
              </a>
              <a
                className='btn btn-danger'
                onClick={this.onClose}>
                {this.context.t('Close')}
              </a>
            </div>
          </form>
        )}
      />
    );
  }
}

PCAModal.contextTypes = {
  t: PropTypes.func.isRequired
};

const pcaModal = reduxForm({
  form: 'pipeAssessment',
  enableReinitialize: true,
})(PCAModal);

export default pcaModal;
