import React from 'react';
import PropTypes from 'prop-types';

import { reduxForm } from 'redux-form';
import { BarLoader } from 'react-spinners';
import { DeleteModal, CoupleView, CouplePathView, CoupleGenerationControlPanel, CoupleFields } from '../../../components';

class CoupleDetailsTab extends React.Component {

static get propTypes() {
  return {
    isGenerateMode: PropTypes.bool,
    isOnEditMode: PropTypes.bool,
    initialValues: PropTypes.object,
    pristine: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    user: PropTypes.object,
    handleSubmit: PropTypes.func,
    onDelete: PropTypes.func,
    setEditPathMode: PropTypes.func,
    clearCouplePath: PropTypes.func,
    couplePath: PropTypes.array,
    validateNewCouple: PropTypes.bool,
    materials: PropTypes.array,
    distanceFactor: PropTypes.number,
    resetSensors: PropTypes.func,
    generateAutoCouple: PropTypes.func,
    setEditMode: PropTypes.func,
    onMaterialChange: PropTypes.func
  };
}

  constructor(props) {
    super(props);

    this.setDrawMode = this.setDrawMode.bind(this);
    this.reset = this.reset.bind(this);

    this.state = {
      drawMode: 'manual'
    };
  }

  setDrawMode(mode) {
    this.setState({
      drawMode: mode
    });

    this.props.setEditPathMode(mode);
  }

  reset() {
    const { resetSensors } = this.props;
    resetSensors();
  }
  
  render() {
    const {
      isGenerateMode,
      isOnEditMode,
      initialValues,
      pristine,
      isSubmitting,
      user,
      handleSubmit,
      onDelete,
      setEditPathMode,
      clearCouplePath,
      couplePath,
      validateNewCouple,
      materials,
      distanceFactor,
      onMaterialChange
    } = this.props;

    const sensorsHasSet = initialValues.Sensor1 != null && initialValues.Sensor2 != null;
    const buttonDisable = (isGenerateMode) ? (!validateNewCouple) : validateNewCouple && pristine;// for edit couple details switch the 'true' with: 'pristine'
    return (
      <form className="sticky-actions" onSubmit={handleSubmit}>
        <div style={{
          position: 'absolute',
          top: '0px',
          width: '100%' }}>
          <BarLoader
            color="#4cae4c"
            width="110%"
            loading={isSubmitting}
          />
        </div>

        <CoupleView
          distanceFactor={distanceFactor}
          initialValues={initialValues}
          resetSensors={this.reset}
          materials={materials}
          user={user}
          editMode={isOnEditMode}
          buttonTitle={this.context.t('Reset')}
          isGenerateMode={isGenerateMode}
          onMaterialChange={onMaterialChange}
          requiredValitate={requiredValitate}
        />

        {((isGenerateMode && sensorsHasSet) || isOnEditMode) &&
          <CoupleGenerationControlPanel
          setDrawMode={this.setDrawMode}
          generateAutoCouple={this.props.generateAutoCouple}
          materials={materials}
          />
        }

        {((isGenerateMode && sensorsHasSet) || isOnEditMode) &&
          <CouplePathView
            editCouplePathToggle={setEditPathMode}
            clearCouplePath={clearCouplePath}
            couplePath={couplePath}
            generateMode={isGenerateMode}
          />
        }

        {user.analysesExpert && ((isGenerateMode && sensorsHasSet) || isOnEditMode) &&
          <CoupleFields
          setDrawMode={this.setDrawMode}
          generateAutoCouple={this.props.generateAutoCouple}
          />
        }

        <div className="form-actions">
          <button
            className="btn btn-success btn-submit"
            disabled={buttonDisable}
            type="submit">{this.context.t('Save')}</button>
          {!isGenerateMode &&
            <div>
              <a 
                className='btn btn-info'
                onClick={() => {this.props.setEditMode(!isOnEditMode);}}>
                {
                  isOnEditMode ? this.context.t('Discard') : this.context.t('Edit')
                }
              </a>

              <DeleteModal
                id={this.props.initialValues.id}
                name={this.props.initialValues.id}
                type={'couple'}
                disabled={!user.installerPlanner}
                onDelete={onDelete}
              />
            </div>
          }
        </div>
      </form>
    );
  }
}

CoupleDetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

const requiredValitate = (value) => value || value == 0 ? undefined : 'Required';

const coupleDetailsTab = reduxForm({
  form: 'coupleDetails', // a unique name for this form
  enableReinitialize: true,
})(CoupleDetailsTab);
export default coupleDetailsTab;
