import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import * as actions from '../../actions/ManageActions';

import UserDetailsTab from '../../components/UsersTabs/UserDetailsTab';
const diff = require('deep-diff');

const mapStateToProps = (state, ownProps) => {
  let initialValues;
  let bIsSelectedIsDistributor = false;
  const user = state.leaksList.user;
  const editedUser = state.manage.selectedManageItem;
  const authorizations = state.leaksList.optionList.options.allowedAuth;
  const isCreatingUser = editedUser.id === -1;
  let customers;

  if (user.isAQS) {
    customers = state.leaksList.customers.optionsList;
  } else {
    customers = [state.leaksList.customers.optionsList.find((itrCustomer) => itrCustomer.value === user.customerId)];
  }

  if (editedUser != null && editedUser.id != -1) {
    const editedUserObj = editedUser.item;
    initialValues = Object.assign({}, editedUserObj);
    initialValues.allowedAuth = initialValues.allowedAuth.map((auth) => auth.value);
    bIsSelectedIsDistributor = editedUserObj.allowedAuth.some(role => {
      return role.value == 8; // 8 is distributor
    });

  }

  return Object.assign({}, {
    initialValues: initialValues,
    customerOptions: customers,
    authOptions: authorizations,
    newUser: isCreatingUser,
    user,
    bIsSelectedIsDistributor,
  });
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const data = Object.assign({}, values, {
        newUser: props.newUser,
      })
      return dispatch(actions.updateUser(data)).then((json) => {
        if (json.status) {
          props.notify('Changes saved', 'success');
        } else {
          let message = null;
          const error = json.err;
          if (error) {
            message = error.message;
          }
          props.notify(message, 'error');
        }
      });
    },
    openManageDistributorModal: () => {
      dispatch(actions.setOpenDistributorProjects(true));
      dispatch(actions.fetchDistributorProjects());
    },

    delete: () => {
      dispatch(actions.deleteUser((status) => {
        let message;
        let level;
        if (status) {
          message = 'User deleted successfully';
          level = 'success';
        } else {
          message = 'User deletion failed';
          level = 'error';
        }
        ownProps.notify(message, level);
      }));
    }
  };
};

const CUserDetailsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetailsTab);

export default CUserDetailsTab;

