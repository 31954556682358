/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

import { makeStyles } from '@mui/styles';

import './style.scss';

const fontSizeStyle = '1.3rem';
const labelShift = '4px';

const useStyles = makeStyles({
  root: {
    margin: '40px 50px',
  },
  margin: {
    margin: 0.75,
    display: 'block',
    fontSize: fontSizeStyle,
    padding: '4px'
  },
  label: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'teal !important',
    top: labelShift,
    left: labelShift,
  },
  input: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available',
    color: 'black !important'
  },
  fields: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    width: 'fit-content',
    margin: '20px auto',
  }
});

function G5Status(props, context) {
  const classes = useStyles();
  const { data } = props;

  const renderField = (key, label) => {
    const value = data[key] || '';
    return (
      <TextField
        className={classes.margin}
        InputProps={{ className: classes.input }}
        InputLabelProps={{ className: classes.label }}
        variant="outlined"
        type={'text'}
        name={key}
        value={value}
        label={label}
        disabled
      />
    );
  };

  return (
      <Box className={classes.fields}>
        {renderField('BatteryLevel', context.t('Battery Level'))}
        {renderField('FmReceiverChannel_10k', context.t('Radio'))}
        {renderField('IMSI', context.t('IMSI'))}
        {renderField('RSRQ_db', context.t('RSRQ [db]'))}
        {renderField('RSRP_dbm', context.t('RSRP [dbm]'))}
        {renderField('IEMI', context.t('IEMI'))}
        {renderField('HardwareRevision', context.t('HW Rev.'))}
        {renderField('FirmwareRevision', context.t('FW Rev.'))}
      </Box>
  );
}

G5Status.contextTypes = {
  t: PropTypes.func.isRequired
};

G5Status.propTypes = {
  data: PropTypes.object
};

export default G5Status;
