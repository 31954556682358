import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Select, Input, AddressField } from '../../components';
const classNames = require('classnames');

const AddAlertForm = (props, context) => {

  const { isOpen, doClose, user, options, onCreateNewValue, couplesIdsData, submitting, handleSubmit, getAddressByLatLong } = props;

  const panelClasses = classNames(
    'add-alert-form',
    { 'show': isOpen }
  );

  const formClass = 'form-group auto-center';
  const labelsClass = 'col-xs-3 control-label';
  const fieldsClass = 'col-xs-8 control-input';
  const divStyle = { float: 'left', width: '100%' };

  return (
    <div className={panelClasses}>
      <div className="new-alert-header">
      <div className="actions">
          <i className="icon-close" onClick={doClose} />
        </div>
        <h2>{context.t('Add New Alert')}</h2>
      </div>
      <form
        className="form-horizontal sticky-actions"
        onSubmit={(e) => {
          handleSubmit(e)
        }}>
        <div style={{ display: 'flex' }}>
          <div style={divStyle}>
            {/* Intensity */}
            {/*
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Intensity')}</label>
              <div className={fieldsClass}>
                <Field name="Intensity"
                  component="input"
                  type="number"
                  className="form-control" />
              </div>
            </div>
            */}

            {/* Priority */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Priority')}</label>
              <div className={fieldsClass}>
                <Field name="ABC"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={context.t('Select...')}
                      options={options.ABC}
                    />)
                  }
                />
              </div>
            </div>

            {/* Detection Date */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Detection date')}:</label>
              <div className={fieldsClass}>
                <Field name="DetectionDateTime"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Repair Date */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Repair date')}:</label>
              <div className={fieldsClass}>
                <Field name="fixDate"
                  component={Input}
                  type="date"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Type */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Type')}</label>
              <div className={fieldsClass}>
                <Field name="AlertType"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={context.t('Select...')}
                      options={options.AlertType}
                    />)
                  }
                />
              </div>
            </div>

            {/* Status */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Status')}</label>
              <div className={fieldsClass}>
                <Field name="AlertState"
                  component={(props) =>
                    (<Select {...props}
                      placeholderTitle={context.t('Select...')}
                      options={options.AlertState}
                    />)
                  }
                />
              </div>
            </div>
          </div>

          {/* RIGHT COLUMN */}
          <div style={divStyle}>

            {/* Repair Details */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Repair Details')}:</label>
              <div className={fieldsClass}>
                <Field name="RepairCode"
                  disabled={!user.editMode}
                  component={(props) =>
                    (<Select {...props}
                      key={"RepairCode"}
                      placeholderTitle={context.t('Select...')}
                      options={options.PCARepairCodesEnum}
                      onCreateNewValue={onCreateNewValue}
                    />)
                  }
                />
              </div>
            </div>

            {/* Work Area */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Work area')}:</label>
              <div className={fieldsClass}>
                <Field name="WorkArea"
                  disabled={!user.editMode}
                  creatable={true}
                  component={(props) =>
                    (<Select {...props}
                      key={"WorkArea"}
                      placeholderTitle={context.t('Select...')}
                      options={options.WorkAreas}
                    />)
                  }
                />
              </div>
            </div>

            {/* Asset ID */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Asset ID')}:</label>
              <div className={fieldsClass}>
                <Field name="KavMaim"
                  component={Input}
                  type="number"
                  className="form-control"
                  disabled={!user.editMode} />
              </div>
            </div>

            {/* Couple ID */}
            <div className={formClass}>
              <label className={labelsClass}>{context.t('Couple ID')}:</label>
              <div className={fieldsClass}>
                <Field name="CoupleID"
                  disabled={!user.editMode}
                  component={(props) =>
                    (<Select {...props}
                      key={"CoupleID"}
                      placeholderTitle={context.t('Select...')}
                      options={couplesIdsData}
                    />)
                  }
                />
              </div>
            </div>
          </div>
        </div>

        {/* X, Y */}
        {/* Latitude, Longitude */}
        <div className="form-group">
          <label className={'col-xs-2 control-label'}>{context.t('Coordinate')}:</label>
          <div className={fieldsClass}>
            <Field name="Coordinate"
              placeholder={context.t('Click on Map to set coordinate')}
              component={Input}
              type="text"
              className="form-control" />
          </div>
        </div>

        {/* Address */}
        <AddressField
          className="form-group"
          fieldClassName={fieldsClass}
          user={user}
          fieldName="StreetAddress"
          onGetAddress={getAddressByLatLong} />

        {/* Comment */}
        <div className="form-group">
          <label className={'col-xs-2 control-label'}>{context.t('Comment')}:</label>
          <div className={fieldsClass}>
            <Field name="Comment"
              component={Input}
              type="text"
              className="form-control"
              disabled={!user.editMode} />
          </div>
        </div>

        <div className="form-actions text-right">
          <a
            className="btn btn-info"
            onClick={doClose}>{context.t('Cancel')}</a>
          <button
            className="btn btn-success"
            type="submit"
            disabled={submitting}>{context.t('Save')}</button>
        </div>
      </form>
    </div>
  );
}

// validators:
const validate = (values) => {
  const errors = {};

  // check Required fields:
  const requiredFields = ['Priority', 'DetectionDateTime', 'AlertType', 'AlertState', 'Coordinate', 'fixDate'];
  requiredFields.forEach((fieldKey) => {
    if (values[fieldKey] == null || values[fieldKey] === '') {
      errors[fieldKey] = 'Required';
    }
  });

  if (values.Intensity && values.Intensity <= 0 || values.Intensity > 32767) {
    errors.Intensity = 'Illegal Value';
  }

  return (errors);
};

AddAlertForm.contextTypes = {
  t: PropTypes.func.isRequired
};

const addAlertForm = reduxForm({
  form: 'add-alert', // a unique name for this form
  enableReinitialize: true,
  validate,
})(AddAlertForm);

export default addAlertForm;
