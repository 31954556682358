import { connect } from 'react-redux';
import * as geolib from 'geolib';
import { DRAW_TYPES } from "../../constants/Misc";
import InterferenceDetailsTab from '../../components/Interferences/InterferenceDetailsTab';
import * as actionsInterferences from '../../actions/InterferencesActions';
import * as actionsMapHandler from '../../actions/MapActionsHandler';
import { editComment, copyComment } from '../../actions/GlobalsActions';
import { downloadSensorsReportFromPolygon } from '../../actions/ReportsActions';
// import moment from 'moment';

// const diff = require('deep-diff');

const mapStateToProps = (state, ownProps) => {
  const projectId = state.leaksList.selectedProject;
  const projectState = state.leaksList.leaksByProject[projectId];
  const selectedFeatureId = state.leaksList.leaksByProject.selectedFeature;
  const interferencesTypes = state.leaksList.optionList.options.InterferenceTypes;
  const timeZone = state.local.timeZone;
  const user = state.leaksList.user;
  let drawMode = state.mapState.drawMode.type;
  const bMapDrawMode = state.mapState.drawMode.isActive;
  const editedPolygon = state.install.editedInterference && bMapDrawMode ? true : false;

  const newInstallItem = state.install.newInstallItem;
  const selectedFeature = getSelectedFeature(selectedFeatureId, projectState);
  const hasPolygon = selectedFeature != null ? true : checkIfHasPolygon(state.install.newInstallItem);
  const sopsOptions = getSopsOptions(projectState);
  const couplesOptions = getCouplesOptions(projectState);

  const initialValues = Object.assign({}, selectedFeature);
  let uiDrawMode = DRAW_TYPES.CIRCLE;
  if (selectedFeature != null) {
    if (selectedFeature.GeometryType === 'POLYGON') {
      uiDrawMode = DRAW_TYPES.POLYGON;
      drawMode = DRAW_TYPES.POLYGON;
    }
  }

  initialValues.DrawMode = uiDrawMode;

  return Object.assign({}, {
    interferencesTypes,
    sopsOptions,
    couplesOptions,
    timeZone,
    selectedFeature,
    //interferencesTypes,
    user,
    drawMode,
    newInstallItem,
    selectedFeatureId,
    hasPolygon,
    initialValues,
    editedPolygon,
    bMapDrawMode,
    //submitting,
  }, ownProps);
};

const getSelectedFeature = (selectedFeatureId, projectState) => {
  let selectedFeature = null;

  if (selectedFeatureId) {
    const interferencesList = projectState.interferences.interferences;
    const indexMap = projectState.interferences.indexMap;
    if (interferencesList && indexMap) {
      selectedFeature = interferencesList[indexMap[selectedFeatureId]];
    }
  }

  return (selectedFeature);
};

const getSopsOptions = (projectState) => {
  const sops = projectState.sops.sops;
  const sopsOptions = sops.map((sop) => {
    const label = sop.id + ': ' + sop.StreetAddress;
    return { value: sop.id, label: label };
  });

  return (sopsOptions);
};

const getCouplesOptions = (projectState) => {
  const couples = projectState.couples.couples;
  const couplesOptions = couples.map((couple) => {
    const label = couple.id + ': ' + couple.Address1 + ' / ' + couple.Address2;
    return { value: couple.id, label };
  });

  return (couplesOptions);
};

const checkIfHasPolygon = (installItem) => {
  let hasPolygone = false;
  if (installItem != null) {
    if (installItem.item != null) {
      const polygon = installItem.item.polygon;
      hasPolygone = (polygon != null && polygon.length >= 2); // Roni will add a comment here...)
    }
  }
  return (hasPolygone);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      dispatch((dispatch, getState) => {
        const state = getState();
        if (state.install.newInstallItem != null) {
          dispatch(actionsInterferences.createInterference(values));
          console.log("CInterferenceDetails >> mapDispatchToProps");
        }
        else {
          // const m = moment(values.StartTime);
          // debugger;
          // const differences = diff.default.diff(values, props.initialValues);
          dispatch(actionsInterferences.updateInterference(values));
          // if (differences != null) {
          //   let res = {};
          //   differences.forEach((diff) => {
          //     res[diff.path[0]] = diff.lhs;
          //     // res.push({ field: diff.path[0], value: diff.lhs });
          //   });
          // }
        }
        // send values to server for save the new interference in DB:

        // exit from map draw mode:
        dispatch(actionsMapHandler.setMapDrawMode(false));

        // clear the bottom details tab: (InterferencesTabs component)
        dispatch(actionsInterferences.selectInterference(null));
      });
    },

    changeDrawMode: (drawModeValue) => {
      dispatch(actionsMapHandler.setMapDrawMode(true, drawModeValue));
    },
    onDelete: () => {
      dispatch(actionsInterferences.deleteInterference());
      return true;
    },

    onEdit: (proxi, object, event) => {
      dispatch(editComment('interferenceForm', 'Comment'));
      return true;
    },

    onCopy: (proxi, object, event) => {
      dispatch(copyComment('interferenceForm', 'Comment'));
      return true;
    },
    editDrawMode: (props) => {
      // OPTION: toggle editing polygon mode
      // if(props.bMapDrawMode){
      //   dispatch(actionsMapHandler.setMapDrawMode(false));
      // } else {
      // Get the current status of selected interference "POLYGON" or "LINESTRNIG"
      let InterferenceDrawType = props.selectedFeature.GeometryType == "POLYGON" ? DRAW_TYPES.POLYGON : DRAW_TYPES.CIRCLE;
      dispatch(actionsMapHandler.setMapDrawMode(true, InterferenceDrawType, props.selectedFeature.Boundary));
      // }
    },
    generateSensorsReport: (item) => {
      dispatch((dispatch, getState) => {
        console.log(item);

        const state = getState();

        const selectedProjectId = state.leaksList.selectedProject;
        const sensors = state.leaksList.leaksByProject[selectedProjectId].sensors.sensors;

        let interferenceSensors;

        if (item.Boundary && item.Boundary.length === 2) {
          // Circle case. first coord = center, second coord = point for radius.
          const [lngCenter, latCenter] = item.Boundary[0];
          const [lngCircumference, latCircumference] = item.Boundary[1];
          const radius = geolib.getDistance(
            { latitude: latCenter, longitude: lngCenter },
            { latitude: latCircumference, longitude: lngCircumference }
          );

          interferenceSensors = sensors.filter((sensor) => geolib.isPointWithinRadius(
            { latitude: sensor.Latitude, longitude: sensor.Longitude },
            { latitude: latCenter, longitude: lngCenter },
            radius))
        } else {
          // Polygon case:
          const poligonPoints = item.Boundary.map(([longitude, latitude]) => ({ latitude, longitude }));
          interferenceSensors = sensors.filter((sensor) => geolib.isPointInPolygon(
            { latitude: sensor.Latitude, longitude: sensor.Longitude },
            poligonPoints
          ));
        }

        if (interferenceSensors.length > 0) {
          dispatch(downloadSensorsReportFromPolygon(interferenceSensors));
        }
        console.log(interferenceSensors);
      });
    }
  };
};

const CInterferenceDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(InterferenceDetailsTab);

export default CInterferenceDetails;
