import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
//import CCoupleAssessmentReportForm from '../../containers/CCoupleAssessmentReportForm/CCoupleAssessementReportForm';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import CanvasDraw from "react-canvas-draw";
import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';

// var $ = require('jquery');

require('./CoupleAssessmentReportModal.scss');

class CoupleAssessmentReportModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      processing: false,
      imgPng: null,
    };

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.coupleAssessmentReportPDF = this.coupleAssessmentReportPDF.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
    this.printCoupleAssessmentReport = this.printCoupleAssessmentReport.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);
    this.animateEllipsis = this.animateEllipsis.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.processingInterval);
  }

  openModal() {
    this.setState({ open: true });
    this.props.getMapCapture().then((url) => {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = url;

      this.setState({
        imgPng: url
      });
    });
  }

  closeModal() {
    this.setState({ open: false });
  }

  coupleAssessmentReportPDF(output) {

    const self = this;
    self.setState({ processing: true });
    this.animateEllipsis();

    html2canvas(document.getElementById('couple-assessment-report-body'),
    {
      useCORS: true,
      allowTaint: false,
      logging: true
    })
    .then((canvas) => {
      let pdf = new jsPDF({
        orientation: 'landscape'
      });
      //pdf.addImage(require('../../../../images/system/' + SYSTEM_RESOURCE +'/logo.png'), 'PNG', 8, 8)
      pdf.addImage(canvas, 'JPEG', 8, 30);

      switch (output) {
        case 'export':
          // Local save to PDF
          pdf.save('couple-assessment-report.pdf');
          break;
        case 'print':
          // Print PDF
          pdf.autoPrint();
          pdf.output('dataurlnewwindow');
          break;
        case 'save':
          // ToDo: save to
          break;
        default:
          pdf.output('dataurlnewwindow');
          self.setState({ open: false })
          break;
      }
      // See http://rawgit.com/MrRio/jsPDF/master/docs/jspdf.js.html#line981
      // for output types.

      self.setState({ processing: false });
      clearInterval(this.processingInterval);

    })
    .catch(e => {
      console.log(e);
      self.setState({ processing: false });
      clearInterval(this.processingInterval);
    });

  }

  saveAndClose() {
    // ToDo
    this.coupleAssessmentReportPDF('save');
  }

  printCoupleAssessmentReport() {
    this.coupleAssessmentReportPDF('print');
  }

  exportToPDF() {
    this.coupleAssessmentReportPDF('export');
  }

  animateEllipsis() {
    setTimeout(() => {
      const ellipsis = this.ellipsis;
      this.processingInterval = setInterval(function () {
        if (ellipsis.innerHTML.length > 3)
          ellipsis.innerHTML = "";
        else
          ellipsis.innerHTML += ".";
      }, 500);
    });
  }

  handleSubmit(event) {
    debugger;
  }

  render() {
    const { isAqsAdmin, timeZone, coupleId } = this.props;
    if (!isAqsAdmin) {
      return null;
    } else {
      return (
        <div className="table-actions assessments"
          style={{ float: 'right' }}>
          <button
            className="btn btn-link couple-assessment-report"
            onClick={this.openModal}
            type="button"
          >
            {this.context.t('Report')}
          </button>

          {this.state.open == true &&
          <AppModal
            closeIcon
            open={this.state.open}
            onClose={this.closeModal}
            title={this.context.t('Couple Assessment Report')}
            content={(
                <div id="couple-assessment-report-body">
                  <h2>{this.context.t('Couple {coupleId}', { coupleId: coupleId })}</h2>
                  <form className="form-horizontal form-couple-assessment-report" onSubmit={this.handleSubmit}>
                    <div className="helfWidth">
                      <img src={this.state.imgPng} className="coupleAssessmentReportImage" id="coupleAssessmentReportMap" />

                      {false && <div className="coupleAssessmentReportCanvas">
                        <CanvasDraw style={{ width: '400px', heigh: '300px' }} />
                      </div>}

                      <div className="inline-group" style={{ display: 'inline-block', width: '97%' }}>
                        <label className="col-xs-2 control-label">{this.context.t('Guidelines')}:</label>
                        <div className="col-xs-8 control-input">
                          <Field name="guidelines" component="textarea" className="form-control" />
                        </div>
                      </div>

                    </div>

                    <div className="rightSideGuidlinesInCoupleAssessmentReport">

                      <table>
                        <thead>
                          <tr>
                            <th>{this.context.t('ID')}</th>
                            <th>{this.context.t('Date')}</th>
                            <th>{this.context.t('Intensity')}</th>
                            <th>{this.context.t('Priority')}</th>
                            <th>{this.context.t('Address')}</th>
                            <th>{this.context.t('Status')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            this.props.assessments.map((sample) => (
                              <tr key={sample.DetectedAt}>
                                <td>{sample.id}</td>
                                <td>{UIDateFormater(sample.DetectedAt, DateType.DATE, timeZone)}</td>
                                <td>{sample.Intensity}</td>
                                <td>{sample.Priority}</td>
                                <td>{sample.StreetAddress}</td>
                                <td>{sample.AlertStateMeaning}</td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </form>
                </div>
            )}
            actions={(
              <button className='btn btn-info' onClick={this.exportToPDF} title={this.context.t('Export the view to PDF')}>
                {this.context.t('Export')}
              </button>
            )}
          />
          }
        </div>
      );
    }
  }
}

////@TODO write props types...
//CoupleAssessmentReportModal.propTypes = {
//  handleRowClick: PropTypes.func.isRequired
//};

CoupleAssessmentReportModal.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const coupleAssessmentReportModal = reduxForm({
  form: 'CoupleAssessmentReportModal', // a unique name for this form
  enableReinitialize: true,
})(CoupleAssessmentReportModal);

export default coupleAssessmentReportModal;
