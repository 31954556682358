import { createStore, applyMiddleware, compose } from 'redux';
import { browserHistory } from 'react-router';
import { routerMiddleware } from 'react-router-redux'
import rootReducer from '../reducer';
import thunkMiddleware from 'redux-thunk';
import reduxClipboardCopy from 'redux-clipboard-copy';


const routingMiddleware = routerMiddleware(browserHistory);
const middlewares = [thunkMiddleware, routingMiddleware];

const enhancer = compose(
  applyMiddleware(...middlewares, reduxClipboardCopy),
)(createStore);

export default function configureStore(initialState) {
  return enhancer(rootReducer, initialState);
}
