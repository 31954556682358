import {
  connect
} from 'react-redux';
import SOPsDetailsTab from '../../components/SOP/SOPsDetailsTab';
import * as actionsSops from '../../actions/SOPsActions';
import * as actionsInstall from '../../actions/InstallActions';

import {
  editComment,
  copyComment,
  getAddressByLatLong
} from '../../actions/GlobalsActions';
import {
  InstallOperationMode,
  UninstallMode,
  DEVICE_DIGITS
} from '../../constants/Misc';

const diff = require('deep-diff').diff;

const mapStateToProps = (state, ownProps) => {
  let initialValues;
  let requiresAreComplete = true;
  const user = state.leaksList.user;
  const installState = state.install;
  const selectedSOP = (installState == null || installState.sensorDetails == null) ? null : installState.sensorDetails.sopID;
  const projectId = state.leaksList.selectedProject;
  const projectState = state.leaksList.leaksByProject[projectId];

  if (projectState == null) {
    return ownProps;
  }

  const sops = projectState.sops;
  const SopStatuses = state.leaksList.optionList.options.SopStatuses;
  const SopTypes = state.leaksList.optionList.options.AqsCode;
  if (selectedSOP != null && selectedSOP != '') {
    initialValues = sops.sops[sops.indexMap[selectedSOP]];
  } else {
    const formValues = state.form.sopForm == null ? null : state.form.sopForm.values;

    initialValues = Object.assign({}, state.install.newInstallItemDefaults);

    if (formValues == null || installState.newInstallItem == null) {
      requiresAreComplete = false;
    } else {
      requiresAreComplete = (installState.newInstallItem.position != null) && (formValues.AvizarCode != null && formValues.State != null);
    }
  }

  let mode;
  let isDeviceFieldEnable = true;
  const modeStatus = state.install.sensorDetails;
  let formInput = null;
  let deviceIdInput = null;
  if (state.form.sopOperationsForm != null && state.form.sopOperationsForm.values != null) {
    formInput = state.form.sopOperationsForm.values;
    deviceIdInput = formInput.DeviceID;
  }

  if (state.install.sensorDetails == null || state.install.sensorDetails.ID == null) {
    if (deviceIdInput == null || deviceIdInput.length == 0) {
      mode = InstallOperationMode.SOP_ONLY;
    } else {
      mode = InstallOperationMode.SOP_AND_SENSOR;
    }
  } else if (state.install.sensorDetails.DeviceID == null) {
    mode = InstallOperationMode.SOP_AND_SENSOR;
  } else {
    mode = InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE;
    isDeviceFieldEnable = false;
  }

  return Object.assign({}, {
    mode,
    user,
    selectedSOP,
    SopStatuses,
    SopTypes,
    initialValues,
    installState,
    requiresAreComplete,
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      if (props.installState.newInstallItem != null) {
        const position = props.installState.newInstallItem.position;
        values.lat = position[1];
        values.lng = position[0];
        dispatch(actionsSops.createSOP(values, (data) => {
          console.log('create sop finish: ' + data);
          let message = 'Installation Point created ' + ((data.status) ? 'successfully' : 'failed');
          let level = data.status ? 'success' : 'error';
          if (data.status) {
            level = 'success';
          } else {
            level = 'error';
            const { err } = data;
            if (err.code === 403) {
              message = 'You are not authorized to perform the requsted operation.';
            }
          }
          ownProps.notify(message, level);
        }));
      } else {
        const differences = diff(values, props.initialValues);
        if (differences != null) {
          let res = [];
          differences.forEach((diff) => {
            res.push({
              field: diff.path[0],
              value: diff.lhs
            })
          })
          dispatch(actionsSops.updateSOP(res, (data) => {
            console.log('update sop finish: ' + data);
            const message = data.status ? 'Changes saved' : 'Failed. Changes not saved. Please try again later';
            const level = data.status ? 'success' : 'error';
            ownProps.notify(message, level);
          }));
        }
      }
    },
    onEdit: (proxi, object, event) => {
      dispatch(editComment('sopForm', 'Comment'));
      return true;
    },

    onCopy: (proxi, object, event) => {
      dispatch(copyComment('sopForm', 'Comment'));
      return true;
    },

    getAddressByLatLong: (proxi, object, event) => {
      dispatch(getAddressByLatLong('sopForm', 'StreetAddress'));
      return true;
    },

    onSelectChange: (name, value) => {
      dispatch(actionsSops.setDefaulFroNewSop(name, value));
    },
    setDeleteMode: () => {
      dispatch(actionsInstall.setUninstallMode(UninstallMode.JUST_REMOVE_SENSOR));
      dispatch(actionsSops.updateSOP([{
        field: 'EndDate',
        value: new Date().toISOString().slice(0, 19).replace('T', ' ')
      }], (data) => {
        console.log('update sop finish: ' + data);
        const message = data.status ? 'Changes saved' : 'Failed. Changes not saved. Please try again later';
        const level = data.status ? 'success' : 'error';
        ownProps.notify(message, level);
      }));
    }
  };
};

const CSOPsTab = connect(
  mapStateToProps,
  mapDispatchToProps
)(SOPsDetailsTab);

export default CSOPsTab;
