import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import * as actionsGlobals from './GlobalsActions';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import * as actionsPressure from './SensorsPressureActions';

export function selectPressureAlert(prsAlert) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    let selectedPrsAlertId;

    let daysBack = 0;

    if (prsAlert == null) {
      selectedPrsAlertId = '';
    } else {
      selectedPrsAlertId = prsAlert.id;
      daysBack = calcDaysBack(prsAlert.EndDate);
    }

    dispatch(actionsGlobals.selectFeature(selectedProject, selectedPrsAlertId, 'prsAlert'));
    dispatch(actionsPressure.fetchPressure(prsAlert, prsAlert.DeviceID, daysBack));
    dispatch(actionsPressure.fetchPressureTransient(prsAlert, prsAlert.DeviceID, daysBack));
    dispatch(selectTransientAlertSample({}));
    if (prsAlert != null) {
      dispatch(fetchPrsTransientAlertResults(prsAlert));
    }
  };
}

function calcDaysBack(refDate) {
  let days;
  if (! refDate) {
    days = 0;
  } else {
    const now = new Date();
    const alertTime = new Date(refDate);

    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);

    alertTime.setHours(0);
    alertTime.setMinutes(0);
    alertTime.setSeconds(0);

    const diffMillis = now.getTime() - alertTime.getTime();
    days = Math.round(diffMillis / (1000 * 60 * 60 * 24));
  }

  return days;
}

export function sortAlerts(field, dir) {
  return (dispatch, getState) => {
    const state = getState();
    const prsAlerts = state.leaksList.pressure.transientAlerts.alerts;

    prsAlerts.sort((a, b) => {
      let nRc = 0;

      const firstObj = (dir === 'asc') ? a : b;
      const secondObj = (dir === 'asc') ? b : a;

      if (firstObj[field] > secondObj[field] || secondObj[field] == null) nRc = 1;
      if (firstObj[field] < secondObj[field] || firstObj[field] == null) nRc = -1;

      return (nRc);
    });

    const newIndexMap = setIndexes(prsAlerts, 'id');

    dispatch(sortLocaly(prsAlerts, newIndexMap, field, dir));
  };
}

const sortLocaly = (alerts, indexMap, field, dir) => {
  return {
    type: types.SORT_PRESSURE_ALERTS_LOCALY,
    alerts,
    indexMap,
    field,
    dir
  };
};

export const fetchPrsTransientAlerts = (from, to) => {
  return (dispatch, getState) => {
    const state = getState();
    if (to == null) {
      to = state.leaksList.pressure.transientAlerts.datesRange.to;
    }
    if (from == null) {
      from =  state.leaksList.pressure.transientAlerts.datesRange.from;
    }
    const projectId = state.leaksList.selectedProject;
    const path = `${endPoints.PRESSURE_TRANSIENT_ALERTS_ENDPOINT}/${projectId}?from=${from}&to=${to}`;
    dispatch(setTransientsDatesRange(from, to));
    dispatch(requestPrsTransientAlerts(projectId))
    return fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        // getProjectDetails:
        const state = getState();
        const projectBundle = state.leaksList.projectsList;
        const projectObj = projectBundle.items[projectBundle.projectIndexMap[projectId]];

        const prsAlerts = json.data;
        const indexMap = setIndexes(prsAlerts, 'id');
        if (projectObj) {
          prsAlerts.forEach((pAlert) => {
            const factorCoeff = pAlert.pressure_calib_coeff || 1;
            const yAxisCoeff = pAlert.pressure_calib_coeff_y_axis || 0;

            const transientData = actionsPressure.parseTransient(pAlert.LastEventData, factorCoeff, yAxisCoeff);
            pAlert.transient = transientData;
          });
        }

        dispatch(receivePrsTransientAlerts(projectId, prsAlerts, indexMap));
      }
    });
  }
}

const setTransientsDatesRange = (from, to) => {
  return {
    type: types.SET_TRANSIENTS_ALERTS_DATES_RANGE,
    from,
    to
  }
}

const requestPrsTransientAlerts = (projectId) => {
  return {
    type: types.REQUEST_PRESSURE_TRANSIENT_ALERTS,
    projectId
  }
}

const receivePrsTransientAlerts = (projectId, prsAlerts, indexMap) => {
  return {
    type: types.RECEIVE_PRESSURE_TRANSIENT_ALERTS,
    projectId,
    prsAlerts,
    indexMap
  }
}

export function changeAlertsContext(context) {
  return {
    type: types.CHANGE_ALERTS_CONTEXT,
    context
  };
}

export function fetchPrsTransientAlertResults(transientAlert, toExcel) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const daysToStart = calcDaysBack(transientAlert.CreateDate) + 1;
    const daysToEnd = calcDaysBack(transientAlert.EndDate);
    let path = `${endPoints.PRESSURE_TRANSIENT_ALERTS_ENDPOINT}/alert/${projectId}/${transientAlert.SensorID}?from=${daysToStart}&to=${daysToEnd}`;
    if (toExcel) {
      const state = getState();
      const { user } = state.user;

      user.getIdToken().then((token) => {
        path += `&export=1&token=${token}`;
        actionsGlobals.downloadFileFromURl(path, 'default.xlsx');
      });
    } else {
      dispatch(requestAlertSamples());
      fetchMiddleware(path, {}, getState).then((json) => {
        if (json.status) {
          const rowData = json.data;
          const projectBundle = state.leaksList.projectsList;
          const projectObj = projectBundle.items[projectBundle.projectIndexMap[projectId]];

          parseTransientData(rowData, projectObj).then((tranData) => {
            dispatch(receiveAlertSamples(tranData));
          });

        } else {
          console.log(json.err);
        }
      });
    }
  };
}

function getDaysBackToDate(date) {
  if (date === undefined) {
    return -1;
  }

  const now = new Date();

}

function requestAlertSamples() {
  return { type: types.REQUEST_TRANSIENTS_ALERT_SAMPLES };
}

function receiveAlertSamples(data) {
  return {
    type: types.RECEIVE_TRANSIENTS_ALERT_SAMPLES,
    data
  };
}

export function selectTransientAlertSample(sample) {
  return {
    type: types.SELECT_TRANSIENT_ALERT_SAMPLE,
    payload: sample
  };
}

function parseTransientData(rowData, projectObj) {
  return new Promise((resolve) => {
    const samplesData = [];
    rowData.forEach((rootItem) => {
      if (rootItem && rootItem.Data) {
        const arr = rootItem.Data.split('~');
        arr.forEach((item) => {
          const recordData = item.split('-');

          const dateAndTime = recordData[0].split(' ');
          const values = recordData[3].split(',');

          const dateAsDayMonthYear = dateAndTime[0].split(':');
          const timeAsHourMinutsSeconds = dateAndTime[1].split(':');

          const time = new Date(Date.UTC(
            dateAsDayMonthYear[2],          // Year
            (dateAsDayMonthYear[1] - 1),    // Month
            dateAsDayMonthYear[0],          // Day
            timeAsHourMinutsSeconds[0],     // Hour
            timeAsHourMinutsSeconds[1],     // Minute
            timeAsHourMinutsSeconds[2])     // Seconds
          );
          if (!samplesData.some((item) => item.Time === time.getTime())) {
            const factorCoeff = rootItem.pressure_calib_coeff || 1;
            const yAxisCoeff = rootItem.pressure_calib_coeff_y_axis || 0;

            const transientValues = values.map((val) => parseInt(val) * factorCoeff + yAxisCoeff);
            const origValues = [...transientValues];

            // calculate MIN, MAX, MEDIAN values

            const sortedValues = transientValues.sort((a, b) => a - b);
            const length = sortedValues.length;
            const minVal = sortedValues[0];
            const maxVal = sortedValues[length - 1];
            const medianVal = sortedValues[Math.round(length / 2)];

            const transientData = {
                Time: time.getTime(),
                MinValue: minVal.toFixed(1),
                MaxValue: maxVal.toFixed(1),
                MedValue: medianVal.toFixed(1),
                transientValues: origValues
            };

            console.log({raw: timeAsHourMinutsSeconds.toString(), dte: time.toLocaleString(), time: transientData.Time});
            samplesData.push(transientData);
          }
        });
      }
    });
    samplesData.sort((a, b) => (b.Time - a.Time));
    resolve(samplesData);
  });
}
