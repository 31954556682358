import React from 'react';
import PropTypes from 'prop-types';


const classNames = require('classnames');

require('./Legend.scss');

export default class Legend extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            showLegend: false
        };
        this.toggleManagerDisplay = this.toggleManagerDisplay.bind(this);
    }

    toggleManagerDisplay() {
        this.setState({ showLegend: !this.state.showLegend });
    }

    render() {
        const { selectedProject, pipeLayerType } = this.props;
        const panelClasses = classNames(
            'map-legend',
            { 'show': this.state.showLegend }
        );
        return (
            <div className={panelClasses}>
                <div className="actions">
                    <i className="icon-toggle" onClick={this.toggleManagerDisplay} />
                </div>
                <h2>{this.context.t('Legend')}</h2>
                {pipeLayerType == 0 && <table>
                    <tbody>
                        <tr >
                            <td ><h2 id="legendPipesMaterials">Pipes materials:</h2></td>
                        </tr>
                        <tr>
                            <td >Metal</td>
                            <td ><div className="pipe-color pipe-color-metal"></div></td>
                        </tr><tr >
                            <td >PVC</td>
                            <td ><div className="pipe-color pipe-color-pvc"></div></td>
                        </tr><tr >
                            <td >AzbestCement</td>
                            <td ><div className="pipe-color pipe-color-azbest-cement"></div></td>
                        </tr><tr >
                            <td >PolyEthelen</td>
                            <td ><div className="pipe-color pipe-color-poly-ethelen"></div></td>
                        </tr><tr >
                            <td >MDPE</td>
                            <td ><div className="pipe-color pipe-color-mdpe"></div></td>
                        </tr><tr >
                            <td >Concrete</td>
                            <td ><div className="pipe-color pipe-color-concrete"></div></td>
                        </tr><tr >
                            <td >CastIron</td>
                            <td ><div className="pipe-color pipe-color-cast-iron"></div></td>
                        </tr><tr >
                            <td >Brass</td>
                            <td ><div className="pipe-color pipe-color-brass"></div></td>
                        </tr><tr >
                            <td >Wood</td>
                            <td ><div className="pipe-color pipe-color-wood"></div></td>
                        </tr><tr >
                            <td >Wall</td>
                            <td ><div className="pipe-color pipe-color-wall"></div></td>
                        </tr><tr >
                            <td >Ductile Iron</td>
                            <td ><div className="pipe-color pipe-color-ductile-iron"></div></td>
                        </tr><tr >
                            <td >Copper</td>
                            <td ><div className="pipe-color pipe-color-copper"></div></td>
                        </tr><tr >
                            <td >Lead</td>
                            <td ><div className="pipe-color pipe-color-lead"></div></td>
                        </tr><tr >
                            <td >Large Diameter</td>
                            <td ><div className="pipe-color pipe-color-large-diameter"></div></td>
                        </tr><tr >
                            <td >Other</td>
                            <td ><div className="pipe-color pipe-color-other"></div></td>
                        </tr>
                    </tbody>
                </table>}
                
            </div>
        );
    }
}

Legend.contextTypes = {
    t: PropTypes.func.isRequired
}


/*

<table>
                    <tbody>
                        <tr  >
                          <td >Unknown</td>
                          <td><div className="sop-marker sop-marker-unknown" style="background: url(images/aqsSprites.png);background-position-x:-32px;background-position-y:-48px;"></div></td>
                        </tr>
                        <tr  >
                            <td >Hydrant</td>
                            <td><div className="sop-marker sop-marker-hydrant" style="background: url(images/aqsSprites.png);background-position-x:-21px;background-position-y:-79px;"></div></td>
                        </tr><tr  >
                            <td >Underground Hydrant</td>
                            <td><div className="sop-marker sop-marker-underground-hydrant" style="background: url(images/aqsSprites.png);background-position-x:-101px;background-position-y:-58px;"></div></td>
                        </tr><tr  >
                            <td >Valve</td>
                            <td><div className="sop-marker sop-marker-valve" style="background: url(images/aqsSprites.png);background-position-x:-1px;background-position-y:-79px;"></div></td>
                        </tr><tr  >
                            <td >Service Valve</td>
                            <td><div className="sop-marker sop-marker-service-valve" style="background: url(images/aqsSprites.png);background-position-x:-41px;background-position-y:-79px;"></div></td>
                        </tr><tr  >
                            <td >Gate Valve</td>
                            <td><div className="sop-marker sop-marker-gate-valve" style="background: url(images/aqsSprites.png);background-position-x:-61px;background-position-y:-79px;"></div></td>
                        </tr><tr  >
                            <td >Control Valve</td>
                            <td><div className="sop-marker sop-marker-control-valve" style="background: url(images/aqsSprites.png);background-position-x:-81px;background-position-y:-79px;"></div></td>
                        </tr><tr  >
                            <td >Manhole</td>
                            <td><div className="sop-marker sop-marker-manhole" style="background: url(images/aqsSprites.png);background-position-x:-101px;background-position-y:-78px;"></div></td>
                        </tr><tr  >
                            <td >System Valve</td>
                            <td><div className="sop-marker sop-marker-system-valve" style="background: url(images/aqsSprites.png);background-position-x:-1px;background-position-y:-79px;"></div></td>
                        </tr>
			              </tbody>
                </table>


*/
