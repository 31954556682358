import { PRESSURE_DISPLAY_MODE } from "../constants/Misc";
import * as types from '../constants/ActionTypes';
const initialState = {
  transientAlerts: {
    alerts: [],
    indexMap: {},
    datesRange: {
      from: new Date(new Date().getTime() - (1000 * 60 * 60 * 24 * 7)).toJSON().substring(0, 10),
      to: new Date().toJSON().substring(0, 10),
    },
    alertSamples: [],
    selectedAlertSample: {},
  },
  trendData: {
    isFetching: false,
    data: null,
    avgData: null,
  },
  transientData: {
    isFetching: false,
    data: null,
  },
  sensorPrsDaysBack: 0,
  mode: PRESSURE_DISPLAY_MODE.DAY,
  context: 'alerts'
};

export const pressure = (state = initialState, action) => {
  switch (action.type) {
    case types.REQUEST_SENSOR_PRS:
      return Object.assign({}, state, {
        trendData: {
          isFetching: true,
          data: null,
        },
        sensorPrsDaysBack: action.daysBack
      });
    case types.RECEIVE_SENSOR_PRS:
      return Object.assign({}, state, {
        trendData: {
          isFetching: false,
          data: action.data,
          avgData: action.avgData
        }
      });
    case types.REQUEST_SENSOR_TRAN:
      return Object.assign({}, state, {
        transientData: {
          isFetching: true,
          data: null,
        }
      });
    case types.RECEIVE_SENSOR_TRAN:
      return Object.assign({}, state, {
        transientData: {
          isFetching: false,
          data: action.data,
        }
      });
    case types.CHANGE_PRESSURE_DISPLAY_MODE:
      return Object.assign({}, state, {
        mode: action.mode
      });

    case types.REQUEST_PRESSURE_TRANSIENT_ALERTS:
      return {...state,
        transientAlerts: {
          ...state.transientAlerts,
          alerts: [],
          indexMap: {}
        },
      }
    case types.RECEIVE_PRESSURE_TRANSIENT_ALERTS:
      return {...state,
        transientAlerts: {
          ...state.transientAlerts,
          alerts: action.prsAlerts,
          indexMap: action.indexMap
        },
      }

    case types.SORT_PRESSURE_ALERTS_LOCALY:
      return {
        ...state,
        transientAlerts: {
          ...state.transientAlerts,
          alerts: action.alerts,
          indexMap: action.indexMap
        }
      };

    case types.SET_TRANSIENTS_ALERTS_DATES_RANGE:
      return {
        ...state,
        transientAlerts: {
          ...state.transientAlerts,
          datesRange: {
            from: action.from,
            to: action.to
          }

        }
      }

    case types.REQUEST_TRANSIENTS_ALERT_SAMPLES:
      return {
        ...state,
        transientAlerts: {
          ...state.transientAlerts,
          alertSamples: []
        }
      }

    case types.RECEIVE_TRANSIENTS_ALERT_SAMPLES:
      return {
        ...state,
        transientAlerts: {
          ...state.transientAlerts,
          alertSamples: action.data
        }
      }

    case types.CHANGE_ALERTS_CONTEXT:
      return {
        ...state,
        transientAlerts: {
          ...state.transientAlerts,
          selectedAlertSample: {}
        },
        context: action.context
      }

    case types.SELECT_TRANSIENT_ALERT_SAMPLE:
      return {
        ...state,
        transientAlerts: {
          ...state.transientAlerts,
          selectedAlertSample: action.payload
        }
      }

    default:
      return state;
  }
};

export default pressure;
