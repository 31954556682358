import React from 'react';
import PropTypes from 'prop-types';

import { Input, CustomerDistributionListsModal, PhoneInputComp } from '../../components';
import { Field, reduxForm } from 'redux-form';

class CustomerDetails extends React.Component {

  constructor(props) {
    super(props);

    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.state = {
      distributionListManageOpen: false
    };
  }

  openModal() {
    this.setState({
      distributionListManageOpen: true
    });
  }

  closeModal() {
    this.setState({
      distributionListManageOpen: false
    });
  }

  render() {
    const { handleSubmit, user, pristine, submitting, isNew, userEditMode } = this.props;
    const labelClassName = 'col-xs-4 control-label';
    const controlClassName = 'col-xs-6 control-input';
    const buttonTitle = (isNew) ? this.context.t('Create New Customer') : this.context.t('Update Customer Details');
    return (
      <div>
        <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>

          <div className="form-group">
            <label className={ labelClassName }>{this.context.t('Customer Name')}:</label>
            <div className={ controlClassName }>
              <Field name="Name" component={Input} type="text" className="form-control" disabled={!isNew} />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('Contact Person')}:</label>
            <div className={controlClassName}>
              <Field name="ContactPerson" component={Input} type="text" className="form-control"
                // disabled={!isNew}
              />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('Contact Email')}:</label>
            <div className={controlClassName}>
              <Field name="ContactMail" component={Input} type="email" className="form-control"
                // disabled={!isNew}
              />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('Contact Phone 1')}:</label>
            <div className={controlClassName}>
              <Field name="ContactPhone1" component={PhoneInputComp} type="tel" className="tel-input" />
            </div>
          </div>

          <div className="form-group">
            <label className={labelClassName}>{this.context.t('Contact Phone 2')}:</label>
            <div className={controlClassName}>
              <Field name="ContactPhone2" component={PhoneInputComp} type="tel" className="tel-input" />
            </div>
          </div>

          <div className="form-actions">
            <button
              className="btn btn-success btn-submit"
              disabled={pristine || submitting}
              type="submit">
              {buttonTitle}
            </button>

            <a
              style={{float: 'left'}}
              className="btn btn-info btn-submit"
              onClick={this.openModal}
              disabled={!userEditMode}
            >
              {this.context.t('Manage Distribution Lists')}
            </a>
          </div>
        </form>

        <CustomerDistributionListsModal
          open={this.state.distributionListManageOpen}
          closeFunction={this.closeModal}
          notify={this.props.notify}
        />
      </div>
    );
  }
}

const validate = (values, props) => {
  const errors = {};
  const requireFields = ['Name', 'ContactPerson', 'ContactMail', 'ContactPhone1'];

  requireFields.forEach((key) => {
    if (values[key] == null) {
      errors[key] = 'Can\'t be empty';
    }
  });

  return errors;
};

CustomerDetails.contextTypes = {
  t: PropTypes.func.isRequired
};

const customerDetails = reduxForm({
  form: 'customerDetails', // a unique name for this form
  enableReinitialize: true,
  validate,
})(CustomerDetails);

export default customerDetails;
