
import { SYSTEM_RESOURCE, ENV, ENVIRONMENTS } from './../constants/EndPoints';

const sysTranslations = {
    aqs: {
        mobileModuleName: 'iQuarius',
        legendMobileSamples: 'Show iQuarius Samples',
        copyright: '© 2021 by Aquarius Spectrum Ltd. All rights reserved',
        design: 'Design by Imaginet',
        updateMobileSysDB: 'Update iQuarius',
    },
    singapore: {
        mobileModuleName: 'iQuarius',
        legendMobileSamples: 'Show iQuarius Samples',
        copyright: '© 2021 by Aquarius Spectrum Ltd. All rights reserved',
        design: 'Design by Imaginet',
        updateMobileSysDB: 'Update iQuarius',
    },
    trimble: {
        mobileModuleName: 'LeakLocator',
        legendMobileSamples: 'Show LeakLocator Samples',
        copyright: '© Copyright 2018, Trimble Inc.',
        design: ' ',
        updateMobileSysDB: 'Update LeakLocator',
    }
};

export const translations = {
  en: {
    ' ': ' ',
    ' Intensity/[{units}]': ' Intensity/[{units}]',
    ' Magnitude/[{units}]': ' Magnitude/[{units}]',
    '': '',
    'A Valid Segment': 'A Valid Segment',
    'A': 'A',
    'ASC': 'ASC',
    'AVG Last Correlation': 'AVG Last Correlation',
    'AVG Last spectrogram': 'AVG Last spectrogram',
    'Above / Under': 'Above / Under',
    'Above': 'Above',
    'Action': 'Action',
    'Active': 'Active',
    'Add New Alert': 'Add New Alert',
    'Add Sensor': 'Add Sensor',
    'Add': 'Add',
    'Address': 'Address',
    'Address: ': 'Address: ',
    'Addresses: {address1}, {address2}': 'Addresses: {address1}, {address2}',
    'Advance Alerts': 'Advance Alerts',
    'Advance Pipes': 'Advance Pipes',
    'Advanced': 'Advanced',
    'Alert Date: ': 'Alert Date: ',
    'Alert ID Filter': 'Alert ID Filter',
    'Alert ID {id}': 'Alert ID {id}',
    'Alert ID': 'Alert ID',
    'Alert ID: ': 'Alert ID: ',
    'Alert ID: {id}': 'Alert ID: {id}',
    'Alert Information': 'Alert Information',
    'Alert Status': 'Alert Status',
    'Alert Type': 'Alert Type',
    'Alert comment': 'Alert comment',
    'Alert {alertId}': 'Alert {alertId}',
    'Alert': 'Alert',
    'Alerts Options': 'Alerts Options',
    'Alerts': 'Alerts',
    'Allow False Positive': 'Allow False Positive',
    'Allow Prod2 Run': 'Allow Prod2 Run',
    'AllowProd2Run': 'AllowProd2Run',
    'STD AGC': 'STD AGC',
    'STD AGC 2': 'STD AGC 2',
    'Analyzer': 'Analyzer',
    'Apply changes': 'Apply changes',
    'Are you sure you want to Uninstall the Device And remove this Sensor?': 'Are you sure you want to Uninstall the Device And remove this Sensor?',
    'Are you sure you want to Uninstall the Device?': 'Are you sure you want to Uninstall the Device?',
    'Are you sure you want to delete this {type}?': 'Are you sure you want to delete this {type}?',
    'Are you sure you want to remove this Sensor?': 'Are you sure you want to remove this Sensor?',
    'Assessment ID: {id}': 'Assessment ID: {id}',
    'Asset ID': 'Asset ID',
    'Asset ID: ': 'Asset ID: ',
    'AssetID: ': 'AssetID: ',
    'AssetID: {asset}': 'AssetID: {asset}',
    'Attenuation': 'Attenuation',
    'Audio Data Size': 'Audio Data Size',
    'Audio recording intensity calculated with electrical interference filltering': 'Audio recording intensity calculated with electrical interference filltering',
    'Audio': 'Audio',
    'Authorization': 'Authorization',
    'Auto Reset At {lastAutoResetDate}': 'Auto Reset At {lastAutoResetDate}',
    'Auto Reset Count {autoResetCount}': 'Auto Reset Count {autoResetCount}',
    'Auto': 'Auto',
    'AutoCloseDate': 'AutoCloseDate',
    'Average Correlation Contrast': 'Average Correlation Contrast',
    'Average Correlation': 'Average Correlation',
    'Average Spectrogram': 'Average Spectrogram',
    'AzbestCement': 'AzbestCement',
    'B': 'B',
    'BIT Information': 'BIT Information',
    'BIT Messages': 'BIT Messages',
    'BIT Response Value': 'BIT Response Value',
    'BIT Type': 'BIT Type',
    'BIT': 'BIT',
    'Back': 'Back',
    'Backfill Comment': 'Backfill Comment',
    'Backfill': 'Backfill',
    'Bad status': 'Bad status',
    'Bands': 'Bands',
    'Battery Level': 'Battery Level',
    'Battery Voltage Post': 'Battery Voltage Post',
    'Battery Voltage Pre': 'Battery Voltage Pre',
    'Battery': 'Battery',
    'Bedding Comment': 'Bedding Comment',
    'Bedding Type': 'Bedding Type',
    'Bounding': 'Bounding',
    'Brass': 'Brass',
    'By clicking "Login", you agree to our': 'By clicking "Login", you agree to our',
    'C': 'C',
    'CSQ': 'CSQ',
    'Can\'t find your search': 'Can\'t find your search',
    'Cancel': 'Cancel',
    'CastIron': 'CastIron',
    'Change {stateName} of leak {id} from {prevState} to {nextState}': 'Change {stateName} of leak {id} from {prevState} to {nextState}',
    'Choose location for new Installation Point': 'Choose location for new Installation Point',
    'Circle': 'Circle',
    'City': 'City',
    'Clarity: ': 'Clarity: ',
    'Clean': 'Clean',
    'Click on Map to set coordinate': 'Click on Map to set coordinate',
    'Click to set current map position as project center': 'Click to set current map position as project center',
    'Close Aleart': 'Close Aleart',
    'Close Alert': 'Close Alert',
    'Close': 'Close',
    'Closure date': 'Closure date',
    'Comm Ratio': 'Comm Ratio',
    'Comment': 'Comment',
    'Comment: ': 'Comment: ',
    'Comments': 'Comments',
    'Communication Problem': 'Communication Problem',
    'Compaction': 'Compaction',
    'Compression Enabled': 'Compression Enabled',
    'Compression': 'Compression',
    'Concrete': 'Concrete',
    'PCA': 'PCA',
    'Config Duration': 'Config Duration',
    'Config Record Duration': 'Config Record Duration',
    'Config Status': 'Config Status',
    'Config': 'Config',
    'Consistency': 'Consistency',
    'Consumption Time': 'Consumption Time',
    'Consumption Time: ': 'Consumption Time: ',
    'Consumtion': 'Consumtion',
    'Consumtion: ': 'Consumtion: ',
    'Contact Email': 'Contact Email',
    'Contact Person': 'Contact Person',
    'Contact Phone 1': 'Contact Phone 1',
    'Contact Phone 2': 'Contact Phone 2',
    'Contrast': 'Contrast',
    'Coordinate': 'Coordinate',
    'Copper': 'Copper',
    'Corr Interval': 'Corr Interval',
    'Corr Num Samples': 'Corr Num Samples',
    'Correlation Audio': 'Correlation Audio',
    'Correlation Radio': 'Correlation Radio',
    'Correlation': 'Correlation',
    'Corrosion Type': 'Corrosion Type',
    'Cost: ': 'Cost: ',
    'Count': 'Count',
    'Couple Assessment Report': 'Couple Assessment Report',
    'Couple Coverage': 'Couple Coverage',
    'Couple Deleted Successfuly': 'Couple Deleted Successfuly',
    'Couple ID': 'Couple ID',
    'Couple ID: ': 'Couple ID: ',
    'Couple ID: {CoupleID},   Material: {material},   {Count} Alerts': 'Couple ID: {CoupleID},   Material: {material},   {Count} Alerts',
    'Couple ID: {id}': 'Couple ID: {id}',
    'Couple Length': 'Couple Length',
    'Couple Path:': 'Couple Path:',
    'Couple {coupleId}': 'Couple {coupleId}',
    'Couple {id}': 'Couple {id}',
    'Couples Coverage': 'Couples Coverage',
    'Couples Days Back For Only Updated': 'Couples Days Back For Only Updated',
    'Couples Options': 'Couples Options',
    'Couples': 'Couples',
    'Crack Diameter (inches)': 'Crack Diameter (inches)',
    'Create Couple': 'Create Couple',
    'Create Date': 'Create Date',
    'Create New Customer': 'Create New Customer',
    'Create New Project': 'Create New Project',
    'Create New SOP': 'Create New SOP',
    'Create New User': 'Create New User',
    'Create new Installation Point': 'Create new Installation Point',
    'Create new Area Of Interest': 'Create new Area Of Interest',
    'Create': 'Create',
    'Created Date': 'Created Date',
    'Creation Time': 'Creation Time',
    'Customer ID': 'Customer ID',
    'Customer ID: ': 'Customer ID: ',
    'Customer Name': 'Customer Name',
    'Customer': 'Customer',
    'Customer: {customer}': 'Customer: {customer}',
    'Customer: {name}': 'Customer: {name}',
    'Customers': 'Customers',
    'D': 'D',
    'DESC': 'DESC',
    'DMAs Layer': 'DMAs Layer',
    'Dashboard': 'Dashboard',
    'Date': 'Date',
    'Dates': 'Dates',
    'Day': 'Day',
    'Days Correlation Incative': 'Days Correlation Incative',
    'Days Inactive': 'Days Inactive',
    'Days detected': 'Days detected',
    'Days': 'Days',
    'Decive Type': 'Decive Type',
    'Delete Failed': 'Delete Failed',
    'Delete {type} {id}': 'Delete {type} {id}',
    'Delete': 'Delete',
    'Design Operating Press': 'Design Operating Press',
    'Details': 'Details',
    'Detected': 'Detected',
    'Detection date': 'Detection date',
    'Device ID': 'Device ID',
    'Device ID: ': 'Device ID: ',
    'Device ID: {device}': 'Device ID: {device}',
    'Device Installation': 'Device Installation',
    'Device is already installed in Project {project}': 'Device is already installed in Project {project}',
    'Device type': 'Device type',
    'Device': 'Device',
    'Diameter / Placement Year': 'Diameter / Placement Year',
    'Diameter': 'Diameter',
    'Discard': 'Discard',
    'Dist[m]': 'Dist[m]',
    'Distance Between Sensors': 'Distance Between Sensors',
    'Distance Calculation:': 'Distance Calculation:',
    'Distance From Sensor1': 'Distance From Sensor1',
    'Distance': 'Distance',
    'Distances': 'Distances',
    'Distribution List:': 'Distribution List:',
    'Do Not Take 0 samples In Average': 'Do Not Take 0 samples In Average',
    'Done': 'Done',
    'Download audio': 'Download audio',
    'Download failed - file not found': 'Download failed - file not found',
    'Download log file': 'Download log file',
    'Download sensor audio': 'Download sensor audio',
    'Download': 'Download',
    'Draw Mode': 'Draw Mode',
    'Dual Signal': 'Dual Signal',
    'Ductile Iron': 'Ductile Iron',
    'Duration [sec]': 'Duration [sec]',
    'EPSG': 'EPSG',
    'Edit {distributorName} Projects:': 'Edit {distributorName} Projects:',
    'Edit': 'Edit',
    'Email': 'Email',
    'End Date': 'End Date',
    'End Frequency': 'End Frequency',
    'End Frequency(Hz)': 'End Frequency(Hz)',
    'End Frequncy': 'End Frequncy',
    'End Record Time': 'End Record Time',
    'End Time': 'End Time',
    'English': 'English',
    'Estimated Address': 'Estimated Address',
    'Event Cause': 'Event Cause',
    'Event Date': 'Event Date',
    'Event Information': 'Event Information',
    'Event Max Value': 'Event Max Value',
    'Event Min Value': 'Event Min Value',
    'Event Nature': 'Event Nature',
    'Exclude Sample 1 by 4 flag': 'Exclude Sample 1 by 4 flag',
    'Exclude Sample 1 by 4 number': 'Exclude Sample 1 by 4 number',
    'Exclude Sample 1 by 4 radius': 'Exclude Sample 1 by 4 radius',
    'Export Alerts History': 'Export Alerts History',
    'Export Alerts To Excel': 'Export Alerts To Excel',
    'Export All Projects To Excel': 'Export All Projects To Excel',
    'Export To Excel': 'Export To Excel',
    'Export all transients to Excel': 'Export all transients to Excel',
    'IQ100B Reports': 'IQ100B Reports',
    'Export the view to PDF': 'Export the view to PDF',
    'Export': 'Export',
    'FM Receiver': 'FM Receiver',
    'FTPEndSendTime': 'FTPEndSendTime',
    'FTPFileSize': (ENV !== ENVIRONMENTS.SINGAPORE) ? 'FTPFileSize' : 'File Size',
    'FTPSendDurationSec': 'FTPSendDurationSec',
    'FTPStartSendTime': 'FTPStartSendTime',
    'FW Rev.': 'FW Rev.',
    'Fading alert': 'Fading alert',
    'Failed': 'Failed',
    'False Path': 'False Path',
    'Fast Development': 'Fast Development',
    'File Name': 'File Name',
    'File': 'File',
    'Files': 'Files',
    'Filtered Avg.': 'Filtered Avg.',
    'Filtered Intensity Avg': 'Filtered Intensity Avg',
    'Filtered Intensity': 'Filtered Intensity',
    'Filtered Noise Intensity': 'Filtered Noise Intensity',
    'Filtered Noise': 'Filtered Noise',
    'Filtered audio recordings intensity calculations avarage': 'Filtered audio recordings intensity calculations avarage',
    'Filters': 'Filters',
    'Filters:': 'Filters:',
    'Firmware Version': 'Firmware Version',
    'First Installation Date': 'First Installation Date',
    'First Name': 'First Name',
    'Fixed Leaks': 'Fixed Leaks',
    'Flow: {flow}': 'Flow: {flow}',
    'Fmax': 'Fmax',
    'Fmin': 'Fmin',
    'Forgot password': 'Forgot password',
    'Fragmented File': 'Fragmented File',
    'Freq': 'Freq',
    'Frequency Histogram': 'Frequency Histogram',
    'Frequency Hz': 'Frequency Hz',
    'Frequency': 'Frequency',
    'From Date: {date}': 'From Date: {date}',
    'Frost Depth': 'Frost Depth',
    'G5 Devices': 'G5 Devices',
    'G5 Intensity': 'G5 Intensity',
    'G5 Status': 'G5 Status',
    'GMT': 'GMT',
    'GO': 'GO',
    'GPS Ratio': 'GPS Ratio',
    'Gain': 'Gain',
    'General Information': 'General Information',
    'General': 'General',
    'Generate': 'Generate',
    'Generation': 'Generation',
    'Good status': 'Good status',
    'Graph': 'Graph',
    'Ground Type': 'Ground Type',
    'Fast growing alert': 'Fast growing alert',
    'Slow growing alert': 'Slow growing alert',
    'Guidelines': 'Guidelines',
    'Guidelines:': 'Guidelines:',
    'HW Rev.': 'HW Rev.',
    'Hardware Version': 'Hardware Version',
    'Hebrew': 'Hebrew',
    'Hello {userName}': 'Hello {userName}',
    'Help': 'Help',
    'High Severity (more than 4 leaks)': 'High Severity (more than 4 leaks)',
    'Historical Information': 'Historical Information',
    'History': 'History',
    'ID (default)': 'ID (default)',
    'ID': 'ID',
    'IEMI': 'IEMI',
    'IMSI': 'IMSI',
    'Inactive': 'Inactive',
    'Include map': 'Include map',
    'Index Band To Send': 'Index Band To Send',
    'Index: ': 'Index: ',
    'Infrastructure': 'Infrastructure',
    'Install Date': 'Install Date',
    'Install Device': 'Install Device',
    'Install': 'Install',
    'Installation Point Comment': 'Installation Point Comment',
    'Installation Point ID': 'Installation Point ID',
    'Installation Point': 'Installation Point',
    'Installation Points': 'Installation Points',
    'Intensities': 'Intensities',
    'Intensity (Not Filtered)': 'Intensity (Not Filtered)',
    'Intensity': 'Intensity',
    'Intensity: ': 'Intensity: ',
    'Intensity: {intensity}': 'Intensity: {intensity}',
    'Area Of Interest Comment': 'Area Of Interest Comment',
    'Area Of Interest ID: {id}': 'Area Of Interest ID: {id}',
    'Area Of Interest Type': 'Area Of Interest Type',
    'Area Of Interest {id}': 'Area Of Interest {id}',
    'Area Of Interest': 'Area Of Interest',
    'Areas Of Interest': 'Areas Of Interest',
    'Edit Area Of Interest': 'Edit Area Of Interest',
    'Consumption': 'Consumption',
    'Is Csq Ok': 'Is Csq Ok',
    'Is Display City Column': 'Is Display City Column',
    'Is Elecrolysis': 'Is Elecrolysis',
    'Is Ftp Ok': (ENV !== ENVIRONMENTS.SINGAPORE) ? 'Is Ftp Ok' : 'Is File Size OK',
    'Is Modem Closed Ok': 'Is Modem Closed Ok',
    'Is OK': 'Is OK',
    'Is Pressure': 'Is Pressure',
    'Is Previous Break Reported': 'Is Previous Break Reported',
    'Is Rocks': 'Is Rocks',
    'Is Voids': 'Is Voids',
    'LOG': 'LOG',
    'LOGIN': 'LOGIN',
    'Large Diameter': 'Large Diameter',
    'Last Comment': 'Last Comment',
    'Last Communication': 'Last Communication',
    'Last Event Date': 'Last Event Date',
    'Last Event Time': 'Last Event Time',
    'Last Installation Date': 'Last Installation Date',
    'Last Name': 'Last Name',
    'Last Run (days)': 'Last Run (days)',
    'Last Sample: ': 'Last Sample: ',
    'Last Uninstall Reason': 'Last Uninstall Reason',
    'LastEventFrequency': 'LastEventFrequency',
    'Latitude': 'Latitude',
    'Launch Street View for selected item': 'Launch Street View for selected item',
    'Layer Manager': 'Layer Manager',
    'Lead': 'Lead',
    'Leak Growth': 'Leak Growth',
    'Leak ID': 'Leak ID',
    'Leak per {units}': 'Leak per {units}',
    'LeakGrowth': 'LeakGrowth',
    'LeakPerKm': 'LeakPerKm',
    'Leaks / Infrastructure': 'Leaks / Infrastructure',
    'Leaks by Diameter': 'Leaks by Diameter',
    'Leaks by Materials & Diameter for Pipe Placement Year': 'Leaks by Materials & Diameter for Pipe Placement Year',
    'Leaks by Materials & Diameter vs Pipe Placement Year': 'Leaks by Materials & Diameter vs Pipe Placement Year',
    'Leaks by Materials': 'Leaks by Materials',
    'Leaks by Pipe Placement Year': 'Leaks by Pipe Placement Year',
    'Leaks by Years': 'Leaks by Years',
    'Leaks': 'Leaks',
    'Legend': 'Legend',
    'Length': 'Length',
    'Linked Couples': 'Linked Couples',
    'List Name': 'List Name',
    'Load': 'Load',
    'Local': 'Local',
    'Location Information': 'Location Information',
    'Log Information. Device: {deviceId}': 'Log Information. Device: {deviceId}',
    'Logout': 'Logout',
    'Logs': 'Logs',
    'Longitude': 'Longitude',
    'Longitude, Latitude': 'Longitude, Latitude',
    'Low Severity (1 leak)': 'Low Severity (1 leak)',
    'Low Severity with Fast Development Alert': 'Low Severity with Fast Development Alert',
    'MDPE': 'MDPE',
    'Mail / SMS Notification': 'Mail / SMS Notification',
    'Mail': 'Mail',
    'Main': 'Main',
    'Manage Distribution Lists': 'Manage Distribution Lists',
    'Manage Distributor': 'Manage Distributor',
    'Management': 'Management',
    'Manual Reset At {lastManualResetDate}': 'Manual Reset At {lastManualResetDate}',
    'Manual Reset Count {manualResetCount}': 'Manual Reset Count {manualResetCount}',
    'Manual': 'Manual',
    'Map Alerts': 'Map Alerts',
    'Map Pipes Diameter Type': 'Map Pipes Diameter Type',
    'Material & Diameter / Placement Year': 'Material & Diameter / Placement Year',
    'Material / Placement Year': 'Material / Placement Year',
    'Material Name': 'Material Name',
    'Material': 'Material',
    'Materials': 'Materials',
    'Max Value': 'Max Value',
    'Median Value': 'Median Value',
    'Medium Severity (2 - 4 leaks)': 'Medium Severity (2 - 4 leaks)',
    'Metal': 'Metal',
    'Meteric / Feet EPSG Only': 'Meteric / Feet EPSG Only',
    'Min Nc1 Days': 'Min Nc1 Days',
    'Min Noise': 'Min Noise',
    'Min RMS NC1': 'Min RMS NC1',
    'Min RMS': 'Min RMS',
    'Min Value': 'Min Value',
    'Minimal Intensity': 'Minimal Intensity',
    'Minimal Noise Intensity': 'Minimal Noise Intensity',
    'Minimal noise sampling taken by the sensor prior to audio recording': 'Minimal noise sampling taken by the sensor prior to audio recording',
    'Mobile': 'Mobile',
    'Mode': 'Mode',
    'Model': 'Model',
    'Modem Atenna Signal Strength': 'Modem Atenna Signal Strength',
    'Modem Usage': 'Modem Usage',
    'More Samples': 'More Samples',
    'Most Problematic Pipes Based on Leaks Count': 'Most Problematic Pipes Based on Leaks Count',
    'Most Problematic Pipes Based on Leaks per {units}': 'Most Problematic Pipes Based on Leaks per {units}',
    'Multi Diameter': 'Multi Diameter',
    'Multi Material': 'Multi Material',
    'N': 'N',
    'NC3 Std Mean Ratio': 'NC3 Std Mean Ratio',
    'NC3 proj Mean': 'NC3 proj Mean',
    'Name': 'Name',
    'Name: ': 'Name: ',
    'Nature of Break Comment': 'Nature of Break Comment',
    'New Couple': 'New Couple',
    'New Customer': 'New Customer',
    'New List Name': 'New List Name',
    'New Project': 'New Project',
    'No Leaks': 'No Leaks',
    'No files uploaded yet...': 'No files uploaded yet...',
    'No leak': 'No leak',
    'Nois eAudio Duration MS': 'Nois eAudio Duration MS',
    'Noise Alert Const Prob Thr': 'Noise Alert Const Prob Thr',
    'Noise Alert Step Prob Thr': 'Noise Alert Step Prob Thr',
    'Noise Audio FS': 'Noise Audio FS',
    'Noise Audio Gain': 'Noise Audio Gain',
    'Noise Intensity Gain': 'Noise Intensity Gain',
    'Noise Intensity Min Band Power': 'Noise Intensity Min Band Power',
    'Noise Interval': 'Noise Interval',
    'Noise Level': 'Noise Level',
    'Noise Num Samples': 'Noise Num Samples',
    'Noise Ok Threshold': 'Noise Ok Threshold',
    'Noise Sample': 'Noise Sample',
    'Noise Samples': 'Noise Samples',
    'Noise Sync': 'Noise Sync',
    'Noise Tx Time': 'Noise Tx Time',
    'Noise level: ': 'Noise level: ',
    'Noise sampling taken by the sensor during audio recording': 'Noise sampling taken by the sensor during audio recording',
    'Noise value: {val}': 'Noise value: {val}',
    'Noise': 'Noise',
    'Non associated': 'Non associated',
    'Not Active': 'Not Active',
    'Not Found': 'Not Found',
    'Not Installed': 'Not Installed',
    'Notch Filter': 'Notch Filter',
    'Num Coefficients': 'Num Coefficients',
    'Num Frequency Bands': 'Num Frequency Bands',
    'NumBadBurstSamples_thr': 'NumBadBurstSamples_thr',
    'Number of Samples': 'Number of Samples',
    'Number of Samples:': 'Number of Samples:',
    'Number of transients': 'Number of transients',
    'Open Assessment': 'Open Assessment',
    'Open New Leak On 0 sample': 'Open New Leak On 0 sample',
    'Open Projects': 'Open Projects',
    'Open': 'Open',
    'Original audio': 'Original audio',
    'Original': 'Original',
    'Other': 'Other',
    'Overground Acc GPS': 'Overground Acc GPS',
    'Overground Acc RF': 'Overground Acc RF',
    'Overground Hyd GPS': 'Overground Hyd GPS',
    'Overground Hyd RF': 'Overground Hyd RF',
    'Overground': 'Overground',
    'PVC': 'PVC',
    'Password': 'Password',
    'Phone Number': 'Phone Number',
    'Phone': 'Phone',
    'Pipe Accuracy': 'Pipe Accuracy',
    'Pipe Assessment Report': 'Pipe Assessment Report',
    'Pipe Assessment': 'Pipe Assessment',
    'Pipe Assessments Report. {projectName} [EPSG: {epsg}]': 'Pipe Assessments Report. {projectName} [EPSG: {epsg}]',
    'Pipe Assessments': 'Pipe Assessments',
    'Pipe Coating': 'Pipe Coating',
    'Pipe Condition Analyzer': 'Pipe Condition Analyzer',
    'Pipe Condition Assessment Details': 'Pipe Condition Assessment Details',
    'Pipe Condition Details': 'Pipe Condition Details',
    'Pipe Diameter': 'Pipe Diameter',
    'Pipe ID': 'Pipe ID',
    'Pipe Install Year': 'Pipe Install Year',
    'Pipe Install': 'Pipe Install',
    'Pipe Length': 'Pipe Length',
    'Pipe Material': 'Pipe Material',
    'Pipe Thickness (at break)': 'Pipe Thickness (at break)',
    'Pipe Thickness': 'Pipe Thickness',
    'Pipe Type': 'Pipe Type',
    'Pipe length': 'Pipe length',
    'Pipe: ': 'Pipe: ',
    'Pipes By Assessment': 'Pipes By Assessment',
    'Pipes By Diameter': 'Pipes By Diameter',
    'Pipes By Material': 'Pipes By Material',
    'Pipes By Placement Year': 'Pipes By Placement Year',
    'Pipes By Presure': 'Pipes By Presure',
    'Pipes High Severity analysis:': 'Pipes High Severity analysis:',
    'Pipes by Placement Year': 'Pipes by Placement Year',
    'Pipes by diameter': 'Pipes by diameter',
    'Pipes by materials': 'Pipes by materials',
    'Pipes by presure': 'Pipes by presure',
    'Pipes severity score': 'Pipes severity score',
    'Pipes': 'Pipes',
    'Placement Year': 'Placement Year',
    'PolyEthelen': 'PolyEthelen',
    'Polygon': 'Polygon',
    'Poor Condition Pipe': 'Poor Condition Pipe',
    'Position: ': 'Position: ',
    'Possible Pumps': 'Possible Pumps',
    'Possible UFR': 'Possible UFR',
    'Power': 'Power',
    'Preparing to download. If downlod does not commence, please allow popups for this site': 'Preparing to download. If downlod does not commence, please allow popups for this site',
    'Pressure Value Coefficient': 'Pressure Value Coefficient',
    'Pressure Y Value Coefficient': 'Pressure Y Value Coefficient',
    'Pressure': 'Pressure',
    'Previous Break Operating Press': 'Previous Break Operating Press',
    'Previous Samples': 'Previous Samples',
    'Print': 'Print',
    'Prior 2 Weeks Weather': 'Prior 2 Weeks Weather',
    'Priority (default)': 'Priority (default)',
    'Priority': 'Priority',
    'Prob': 'Prob',
    'Prob Irrig': 'P.I.',
    'Prob4 Burst': 'Prob4 Burst',
    'Probability': 'Probability',
    'Probability Consumption': 'Probability Consumption',
    'Processing': 'Processing',
    'Profiler': 'Profiler',
    'Proj Int Factor': 'Proj Int Factor',
    'Proj Int Len Factor': 'Proj Int Len Factor',
    'Project Boundaries': 'Project Boundaries',
    'Project Name': 'Project Name',
    'Project': 'Project',
    'Project: {name}': 'Project: {name}',
    'Projects Coverage': 'Projects Coverage',
    'Projects': 'Projects',
    'Proximity to other utilities': 'Proximity to other utilities',
    'Quality 1': 'Quality 1',
    'Quality 2': 'Quality 2',
    'Quality': 'Quality',
    'Quality: ': 'Quality: ',
    'Quiality 1': 'Quiality 1',
    'Quiality 2': 'Quiality 2',
    'Quiality': 'Quiality',
    'RESET PASSWORD': 'RESET PASSWORD',
    'RF Sync Problem, ': 'RF Sync Problem, ',
    'RSRP [dbm]': 'RSRP [dbm]',
    'RSRQ [db]': 'RSRQ [db]',
    'RSSI': 'RSSI',
    'Radio ': 'Radio ',
    'Radio And Audio Window Sec': 'Radio And Audio Window Sec',
    'Radio Frequency data': 'Radio Frequency data',
    'Radio Frequency': 'Radio Frequency',
    'Radio [dt: {dt}, quality: {quality}]': 'Radio [dt: {dt}, quality: {quality}]',
    'Radio': 'Radio',
    'Raw Intensity': 'Raw Intensity',
    'Ready': 'Ready',
    'Record Duration': 'Record Duration',
    'Recording Duration [Sec]': 'Recording Duration [Sec]',
    'Recording DurationSec': 'Recording DurationSec',
    'RecordingTime 1': 'RecordingTime 1',
    'RecordingTime 2': 'RecordingTime 2',
    'RecordingTime 3': 'RecordingTime 3',
    'Ref Time': 'Ref Time',
    'Registered Microsoft Partner': 'Registered Microsoft Partner',
    'Relative sensor': 'Relative sensor',
    'Reload data': 'Reload data',
    'Remember me': 'Remember me',
    'Remove Filters To View {item} Location': 'Remove Filters To View {item} Location',
    'Remove Sensor {sensorID}': 'Remove Sensor {sensorID}',
    'Remove Sensor': 'Remove Sensor',
    'Remove': 'Remove',
    'Repair Date': 'Repair Date',
    'Repair Details': 'Repair Details',
    'Repair Type': 'Repair Type',
    'Repair date': 'Repair date',
    'Report Enabled': 'Report Enabled',
    'Report': 'Report',
    'Reported By': 'Reported By',
    'Reports Settings': 'Reports Settings',
    'Reports': 'Reports',
    'Request Time': 'Request Time',
    'Reset': 'Reset',
    'Resets': 'Resets',
    'Resistivity': 'Resistivity',
    'Response Time': 'Response Time',
    'SAP Number': 'SAP Number',
    'SMS': 'SMS',
    'SNR': 'SNR',
    'SOP ID: ': 'SOP ID: ',
    'SOP Status': 'SOP Status',
    'SOP Type': 'SOP Type',
    'SOPs Filters': 'SOPs Filters',
    'SOPs': 'SOPs',
    'Sample Correlation Contrast': 'Sample Correlation Contrast',
    'Sample Correlation': 'Sample Correlation',
    'Sample Distance': 'Sample Distance',
    'Sample ID: ': 'Sample ID: ',
    'Sample Intensity': 'Sample Intensity',
    'Sample Noise': 'Sample Noise',
    'Sample Size': 'Sample Size',
    'Sample Spectrogram': 'Sample Spectrogram',
    'Sample Time 1': 'Sample Time 1',
    'Sample Time 2': 'Sample Time 2',
    'Sample Time 3': 'Sample Time 3',
    'Sample Time': 'Sample Time',
    'Sample Time: ': 'Sample Time: ',
    'Sample': 'Sample',
    'Sampled Intensity': 'Sampled Intensity',
    'Samples Count': 'Samples Count',
    'Samples': 'Samples',
    'Samples2': 'Samples2',
    'Sampling Frequency': 'Sampling Frequency',
    'Satellite View': 'Satellite View',
    'Save & Apply': 'Save & Apply',
    'Save Changes': 'Save Changes',
    'Save this couple-assessment-report': 'Save this couple-assessment-report',
    'Save this work-order': 'Save this work-order',
    'Save': 'Save',
    'Scheduler': 'Scheduler',
    'Search Alert': 'Search Alert',
    'Search Box': 'Search Box',
    'Search Couple': 'Search Couple',
    'Search Customer': 'Search Customer',
    'Search Installation Point': 'Search Installation Point',
    'Search Project': 'Search Project',
    'Search Sensor': 'Search Sensor',
    'Search User': 'Search User',
    'Second sensor': 'Second sensor',
    'Select Projects...': 'Select Projects...',
    'Select Report...': 'Select Report...',
    'Select draw mode and draw Area Of Interest': 'Select draw mode and draw Area Of Interest',
    'Select project': 'Select project',
    'Select...': 'Select...',
    'Selected FM Channel': 'Selected FM Channel',
    'Selected: {selectedIndex}. Total: {total}': 'Selected: {selectedIndex}. Total: {total}',
    'Send to printer': 'Send to printer',
    'Send': 'Send',
    'Sensitivity Level': 'Sensitivity Level',
    'Sensor 1': 'Sensor 1',
    'Sensor 2': 'Sensor 2',
    'Sensor Activity Threshold': 'Sensor Activity Threshold',
    'Sensor Comment': 'Sensor Comment',
    'Sensor ID': 'Sensor ID',
    'Sensor ID: ': 'Sensor ID: ',
    'Sensor ID: {id}': 'Sensor ID: {id}',
    'Sensor Install': 'Sensor Install',
    'Sensor Type': 'Sensor Type',
    'Sensor comment': 'Sensor comment',
    'Sensor id (default)': 'Sensor id (default)',
    'Sensor is not found. check filters and try again': 'Sensor is not found. check filters and try again',
    'Sensor': 'Sensor',
    'Sensor1 Address': 'Sensor1 Address',
    'Sensor2 Address': 'Sensor2 Address',
    'Sensors Filters': 'Sensors Filters',
    'Sensors Maintenance': 'Sensors Maintenance',
    'Sensors Options': 'Sensors Options',
    'Sensors': 'Sensors',
    'Service Provider': 'Service Provider',
    'Service provider': 'Service provider',
    'Session Max Value': 'Session Max Value',
    'Session Min Value': 'Session Min Value',
    'Session Num Events': 'Session Num Events',
    'Set Actual Leak Position': 'Set Actual Leak Position',
    'Set actual leak position on Map:': 'Set actual leak position on Map:',
    'Settings': 'Settings',
    'Severity': 'Severity',
    'Shadow': 'Shadow',
    'Show / Hide': 'Show / Hide',
    'Show Alerts': 'Show Alerts',
    'Show All': 'Show All',
    'Show Couples Path': 'Show Couples Path',
    'Show Installation Points': 'Show Installation Points',
    'Show Areas Of Interest': 'Show Areas Of Interest',
    'Show Sensors': 'Show Sensors',
    'Show Water Meter Layer': 'Show Water Meter Layer',
    'Show iQuarius Samples': sysTranslations[SYSTEM_RESOURCE].legendMobileSamples,
    'Signals': 'Signals',
    'Sim Number': 'Sim Number',
    'Snow Depth': 'Snow Depth',
    'SopID': 'SopID',
    'Sort by': 'Sort by',
    'Source Alert': 'Source Alert',
    'Start Frequency': 'Start Frequency',
    'Start Frequency(Hz)': 'Start Frequency(Hz)',
    'Start Time': 'Start Time',
    'State': 'State',
    'Status (default)': 'Status (default)',
    'Status': 'Status',
    'Status: ': 'Status: ',
    'Stolen': 'Stolen',
    'Street Surface': 'Street Surface',
    'Submit': 'Submit',
    'Success': 'Success',
    'Sudden Air Temp Change': 'Sudden Air Temp Change',
    'Sudden Water Temp Change': 'Sudden Water Temp Change',
    'Suspected fast developing leak': 'Suspected fast developing leak',
    'Suspected leak': 'Suspected leak',
    'Switch to {project}': 'Switch to {project}',
    'Sync Method': 'Sync Method',
    'Sync Problem': 'Sync Problem',
    'Sync': 'Sync',
    'Task Id': 'Task Id',
    'Task Name': 'Task Name',
    'Temp Avg': 'Temp Avg',
    'Temp Fail': 'Temp Fail',
    'Temp Rise': 'Temp Rise',
    'Terms And Conditions': 'Terms And Conditions',
    'Time': 'Time',
    'Time: ': 'Time: ',
    'Timezone': 'Timezone',
    'To': 'To',
    'TP Avg': 'TP Avg',
    'TP RMS': 'TP RMS',
    'Total length [{unitsLable}]': 'Total length [{unitsLable}]',
    'Traffic': 'Traffic',
    'Transient': 'Transient',
    'Trend': 'Trend',
    'Type': 'Type',
    'Type: ': 'Type: ',
    'Type: {type}': 'Type: {type}',
    'Uncertain': 'Uncertain',
    'Undefined': 'Undefined',
    'Under / Over': 'Under / Over',
    'Under': 'Under',
    'Underground Acc GPS': 'Underground Acc GPS',
    'Underground Acc RF': 'Underground Acc RF',
    'Underground Hyd GPS': 'Underground Hyd GPS',
    'Underground Hyd RF': 'Underground Hyd RF',
    'Underground': 'Underground',
    'Uninstall Device And Remove Sensor': 'Uninstall Device And Remove Sensor',
    'Uninstall Device and Install it on the new Sensor': 'Uninstall Device and Install it on the new Sensor',
    'Uninstall Device': 'Uninstall Device',
    'Uninstall comment': 'Uninstall comment',
    'Uninstall': 'Uninstall',
    'Uninstalled': 'Uninstalled',
    'Update Customer Details': 'Update Customer Details',
    'Update Pipes': 'Update Pipes',
    'Update Project Details': 'Update Project Details',
    'Update User Details': 'Update User Details',
    'Update iQuarius': sysTranslations[SYSTEM_RESOURCE].updateMobileSysDB,
    'Update': 'Update',
    'Updated': 'Updated',
    'Upload Files': 'Upload Files',
    'Use Feet In Matlab Graphs': 'Use Feet In Matlab Graphs',
    'Use Imperial units in Graphs': 'Use Imperial units in Graphs',
    'User Name': 'User Name',
    'User': 'User',
    'User: {name}': 'User: {name}',
    'Users': 'Users',
    'Velocity': 'Velocity',
    'Version': 'Version',
    'W': 'W',
    'Wall': 'Wall',
    'Water Loss ({unit}/h)': 'Water Loss ({unit}/h)',
    'We\'ve sent a message to {email} \n Please check your inbox.': 'We\'ve sent a message to {email} \n Please check your inbox.',
    'Weather Conditions': 'Weather Conditions',
    'Week': 'Week',
    'Weighted Distance': 'Weighted Distance',
    'Weighted Intensity': 'Weighted Intensity',
    'Wood': 'Wood',
    'Work Area': 'Work Area',
    'Work area': 'Work area',
    'X , Y': 'X , Y',
    'Your {item} Located in project {project}.': 'Your {item} Located in project {project}.',
    '[Hz]': '[Hz]',
    '[{units}/Hz]': ' [{units}/Hz]',
    'alert': 'alert',
    'balagan_dist_ratio_thr': 'balagan_dist_ratio_thr',
    'changed': 'changed',
    'commented': 'commented',
    'copyright text': sysTranslations[SYSTEM_RESOURCE].copyright,
    'couple': 'couple',
    'created new Alert': 'created new Alert',
    'date': 'date',
    'design text': sysTranslations[SYSTEM_RESOURCE].design,
    'false': 'false',
    'first': 'first',
    'ft': 'ft',
    'iQuarius': sysTranslations[SYSTEM_RESOURCE].mobileModuleName,
    'inactive couple - no sensors - planning': 'inactive couple - no sensors - planning',
    'inactive couple - no sensors': 'inactive couple - no sensors',
    'inactive couple': 'inactive couple',
    'installation point': 'installation point',
    'intensity: ': 'intensity: ',
    'irrig_dist': 'irrig_dist',
    'last': 'last',
    'length: {length}[{units}]': 'length: {length}[{units}]',
    'next': 'next',
    'no correlations warning': 'no correlations warning',
    'original audio': 'original audio',
    'prev': 'prev',
    'sensor': 'sensor',
    'slope: {slope}, std: {std}, r2: {r2}': 'slope: {slope}, std: {std}, r2: {r2}',
    'status': 'status',
    'sum_included_thr': 'sum_included_thr',
    'to': 'to',
    'true': 'true',
    'update from AQS Mobile. ({details})': 'update from AQS Mobile. ({details})',
    '{name} Correlation': '{name} Correlation',
    '{name} Spectrogram': '{name} Spectrogram',
    'Prs Calib Multiply': 'Prs Calib Multiply',
    'Prs Calib Shift': 'Prs Calib Shift',
    'low RM: [{rm}]': 'low RM: [{rm}]',
  },
  he: {
    'Email': 'אימייל',
    'Password': 'סיסמה',
    'Forgot password': 'שכחתי את הסיסמה',
    'Remember me': 'זכור אותי',
    'LOGIN': 'התחברות',
    'Version': 'גרסה',
    'Alerts': 'התראות',
    'Sensors': 'חיישנים',
    'iQuarius': 'iQuarius',
    'Management': 'ניהול',
    'Filters:': 'מסננים:',
    'Table Options': 'אפשרויות טבלה',
    'Sort by': 'מיון לפי',
    'Priority (default)': 'עדיפות (ברירת מחדל)',
    'ID': 'מזהה',
    'Detected': 'אותר',
    'Type': 'סוג',
    'Status': 'סטטוס',
    'Intensity': 'עוצמה',
    'Work Area': 'אזור עבודה',
    'Repair date': 'תאריך תיקון',
    'Closure date': 'תאריך סגירה',
    'Service provider': 'ספק שירות',
    'Days detected': 'ימים מאז האיתור',
    'ASC': 'סדר עולה',
    'DESC': 'סדר יורד',
    'Filters': 'מסננים',
    'Priority': 'עדיפות',
    'to': 'ל',
    'Save & Apply': 'שמור ויישם',
    'Alert': 'התראה',
    'Details': 'פרטים',
    'History': 'היסטוריה',
    'Files': 'קבצים',
    'Samples': 'דגימות',
    'Comment': 'הערה',
    'Address': 'כתובת',
    'City': 'עיר',
    'Repair Details': 'פרטי תיקון',
    'Work area': 'אזור עבודה',
    'SAP Number': 'מספר SAP',
    'Pipe ID': 'מזהה צינור',
    'Couple ID': 'מזהה צמד',
    'Distance': 'מרחק',
    'X': 'X',
    'Y': 'Y',
    'Save': 'שמור',
    'Date': 'תאריך',
    'Device ID': 'מזהה התקן',
    'Device type': 'סוג התקן',
    'User': 'משתמש',
    'Action': 'פעולה',
    'No files uploaded yet...': 'לא הועלו קבצים',
    'Upload Files': 'העלה קבצים',
    'commented': 'הגיב/ה',
    'changed {type} to {value}': 'שינה/שינתה את {type} ל{value}',
    'changed': 'שונה',
    'English': 'אנגלית',
    'Hebrew': 'עברית',
    'Search Box': 'תיבת חיפוש',
    'Layer Manager': 'מנהל שכבות',
    'Satellite View': 'תצוגת לווין',
    'Show Leaks': 'הצג נזילות',
    'Show Sensors': 'הצג חיישנים',
    'Show Pipes': 'הצג צנרת',
    'Settings': 'הגדרות',
    'Reports': 'דוחות',
    'Select...': 'בחר...',
    'Create \\ Select...': 'צור \\ בחר...',
    'Sensor id (default)': 'מזהה סנסור (ברירת מחדל)',
    'Status (default)': 'סטטוס (ברירת מחדל)',
    'Days Inactive': 'מספר ימים בלי פעילות',
    'Uninstalled': 'הוסר',
    'Ready': 'מוכן',
    'Active': 'פעיל',
    'Inactive': 'לא פעיל',
    'Sensor': 'חיישן',
    'Device': 'התקן',
    'First Installation Date': 'תאריך התקנה ראשון',
    'Last Installation Date': 'תאריך התקנה אחרון',
    'Last Communication': 'תקשורת אחרונה',
    'Sample Time': 'זמן דגימה',
    'Days I/A': 'ימים בלי פעילות',
    'Sensor ID': 'מזהה סנסור',
    'Hello {userName}': 'שלום {userName}',
    'Help': 'עזרה',
    'Logout': 'התנתקות',
    'Registered Microsoft Partner': 'Registered Microsoft Partner',
    'Design by Imaginet': 'Design by Imaginet',
    'Above / Under': 'תת/על קרקעי',
    'Above': 'על קרקעי',
    'Under': 'תת קרקעי',
    'Sync Method': 'שיטת סינכרון',
    'Alerts Options': 'מסנן התרעות',
    'Alert comment': 'הערה',
    'Asset ID': 'מזהה פריט',
    'Pipe length': 'אורך צינור',
    'Longitude, Latitude': 'קו אורך, קו רוחב',
    'prev': 'קודם',
    'next': 'הבא',
    'first': 'ראשון',
    'last': 'אחרון',
    'Couples Options': 'הגדרות צמדים',
    'ID (default)': 'מזהה (בררת מחדל)',
    'Length': 'אורך',
    'Material': 'חומר',
    'Updated': 'מעודכן',
    'Materials': 'חומרים',
    'Open Assessment': 'פתח חקירת צנרת',
    'Selected: {selectedIndex}. Total: {total}': 'נבחר: {selectedIndex}. סה"כ: {total}',
    'No alerts': 'אין התרעות',
    'Create Couple': 'צור צמד',
    'Couple {id}': 'צמד {id}',
    'New Couple': 'צמד חדש',
    'Setup': 'יצירה',
    'Reset': 'איפוס',
    'Delete {type} {id}': 'מחק {type} {id}',
    'Are you sure you want to delete this {type }?': 'האם ברצונך למחוק את {type}',
    'Processing': 'מעבד',
    'Delete': 'מחק',
    'Cancel': 'בטל',
    'Dual Signal': 'אות כפול',
    'Frequency Histogram': 'היסטוגרמת תדרים',
    'Average Correlation Contrast': 'קונטרסט ממוצע של קורלציה',
    'Average Correlation': 'קורלציה ממוצעת',
  },
  options: {
    suppress_warnings: true
  }
};
