import { browserHistory } from 'react-router';
import { copy } from 'redux-clipboard-copy';

import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware } from './MiddlewareActions';
import * as geocoder from './Utilities/geocoder';
import * as actionsAlerts from './AlertsActions';
import * as actionsNoiseAlerts from './NoiseAlertsActions';
import * as actionsPressureAlerts from './PrsTransientAlertsActions';
import * as actionsSensors from './SensorsActions';
import { sortSamples } from './SamplesActions';
import * as actionsSOPs from './SOPsActions';
import * as actionsCouples from './CouplesActions';
import * as actionsUsers from './ManageActions';
import * as actionsProjects from './ProjectsActions';
import * as actionsCustomers from './CustomersActions';
import * as actionsiQuarius from './iQuariusActions';
import * as actionsMobileTasks from './MobileTasksActions';
import * as actionsMobileAlerts from './MobileAlertsActions';
import * as actionsInterferences from './InterferencesActions';
import * as actionsMapHandler from './MapActionsHandler';
import * as actionsInspect from './InspectActions';
import * as reduxFormActions from 'redux-form';
import { featuresTypes } from '../components/Map/Layers/Options';
import {
  FILTERS_ALERTS,
  FILTERS_SENSORS,
  FILTERS_SOPS,
  FILTERS_COUPLES,
} from '../constants/SettingsKeys';
import { handleLayerChange } from './MapActionsHandler';

/**
 * Globals actions here:
 */
export const handlePathChangeEvent = (selectedProject) => (dispatch) => {
  dispatch(selectFeature(selectedProject, '', ''));
  dispatch(actionsMapHandler.setMapDrawMode(false));
  dispatch(actionsPressureAlerts.selectTransientAlertSample({}));
  dispatch(actionsSensors.selectSensorNoiseMsg(selectedProject, {}));
};

export const onPathChange = (prevPath, nextPath) => (dispatch, getState) => {
  const state = getState();
  const selectedProject = state.leaksList.selectedProject;
  const selectedFeature = state.leaksList.leaksByProject.selectedFeature;

  dispatch(handlePathChangeEvent(selectedProject));

  if (
    ['alerts', 'couples', 'inspect'].indexOf(prevPath) > -1 &&
    ['alerts', 'couples'].indexOf(nextPath) > -1
  ) {
    if (selectedFeature != null && selectedFeature !== '') {
      const alertsItems = state.leaksList.leaksByProject[selectedProject].items;
      const alertsIndexMap =
        state.leaksList.leaksByProject[selectedProject].indexMap;
      const couplesItems =
        state.leaksList.leaksByProject[selectedProject].couples.couples;
      const couplesIndexMap =
        state.leaksList.leaksByProject[selectedProject].couples.indexMap;
      const alertsContext = state.leaksList.pressure.context;

      if (prevPath === 'alerts' && nextPath === 'couples') {
        if (alertsContext === 'alerts') {
          const selectedAlert = alertsItems[alertsIndexMap[selectedFeature]];
          if (
            selectedAlert != null &&
            couplesIndexMap[selectedAlert.CoupleID] != null
          ) {
            const relatedCouple =
              couplesItems[couplesIndexMap[selectedAlert.CoupleID]];
            dispatch(actionsCouples.selectCoupleOrAssessment(relatedCouple));
          }
        }
      } else if (prevPath === 'couples' && nextPath === 'alerts') {
        if (alertsContext === 'alerts') {
          let relatedAlert = null;
          for (let i = 0; i < alertsItems.length; i++) {
            const itrAlert = alertsItems[i];
            if (itrAlert.CoupleID === selectedFeature) {
              relatedAlert = itrAlert;
              break;
            }
          }
          if (relatedAlert != null) {
            dispatch(actionsAlerts.selectAlert(relatedAlert));
          }
        }
      } else if (prevPath === 'inspect' && nextPath === 'alerts') {
        // select the alert for view its details:
        const selectedAlert = alertsItems[alertsIndexMap[selectedFeature]];
        if (selectedAlert != null) {
          dispatch(actionsAlerts.selectAlert(selectedAlert));
        }
      }
    }
  }

  if (nextPath === 'inspect') {
    dispatch(actionsInspect.investigateAlerts(selectedProject));
    dispatch(actionsInspect.investigatePipes(selectedProject));
    // dispatch(actionsMapHandler.handleLayerChange(featuresTypes.PIPES_CONDITION_ASSESSMENTS, true));
    //dispatch(actionsMapHandler.handleLayerChange(featuresTypes.INSPECT_ALERTS, true));
  }
  // else if (prevPath == 'inspect') {
  // 	dispatch(actionsMapHandler.handleLayerChange(featuresTypes.PIPES_CONDITION_ASSESSMENTS, false));
  // }
  //else if (prevPath == 'inspect') {
  //   dispatch(actionsMapHandler.handleLayerChange(featuresTypes.INSPECT_ALERTS, false));
  // }
};

export function setSelectedItem(item, onFail, additionalData) {
  return (dispatch, getState) => {
    const state = getState();
    if (item && item.ID) {
      item.id = item.ID;
    }
    const selectedFeatureID = state.leaksList.leaksByProject.selectedFeature;
    if (
      (item == null && selectedFeatureID !== '') ||
      (item != null && selectedFeatureID === '') ||
      (item != null && item.id != selectedFeatureID)
    ) {
      const path =
        state.routing.locationBeforeTransitions.pathname.replace('/', '') ||
        'alerts';
      switch (path) {
        case 'alerts': {
          const alertsContextMenu = state.leaksList.pressure.context;
          switch (alertsContextMenu) {
            case 'alerts':
              dispatch(actionsAlerts.selectAlert(item));
              break;

            case 'prsAlerts':
              dispatch(actionsPressureAlerts.selectPressureAlert(item));
              break;

            case 'noise':
              dispatch(actionsNoiseAlerts.selectNoiseAlert(item));
              break;

            default:
              break;
          }
          break;
        }
        case 'sensors':
          dispatch(actionsSensors.selectSensor(item));
          break;
        case 'couples':
          if (item.type == 'sensor') {
            const selectedProjectId = state.leaksList.selectedProject;
            const sensorsBundle =
              state.leaksList.leaksByProject[selectedProjectId].sensors;
            const selectedSensor =
              sensorsBundle.sensors[sensorsBundle.indexMap[item.id]];
            if (selectedSensor == null) {
              // notify Filters...
              if (onFail != null) {
                onFail();
              }
            } else {
              const pathParams = {
                pathname: 'sensors',
                query: { project: selectedProjectId },
                state: { fromMap: true }, // flag for not clear the selected item on the reducer.
              };
              browserHistory.push(pathParams);

              dispatch(actionsSensors.selectSensor(selectedSensor));
            }
          } else {
            dispatch(actionsCouples.selectCoupleOrAssessment(item));
          }
          break;
        case 'manage':
          dispatch(selectManageItem(item));
          break;
        case 'install': {
          const installContext = state.install.installContext;
          switch (installContext) {
            case 'SOPs':
              dispatch(actionsSOPs.selectSOP(item));
              break;
            case 'Sensors':
              dispatch(actionsSensors.selectSensor(item));
              break;
            case 'Interferences':
              dispatch(actionsInterferences.selectInterference(item));
              dispatch(actionsMapHandler.setMapDrawMode(false));
              break;
            default:
              break;
          }
          break;
        }
        case 'mobile': {
          const mobileDisplayMode = state.mobile.modeState.mode;
          switch (mobileDisplayMode) {
            case 'Tasks':
              if (additionalData === 'multi') {

              }
              dispatch(
                actionsMobileTasks.setSelectedTask(
                  Array.isArray(item)
                    ? item.map((it) => it.task_uuid)
                    : item.task_uuid
                )
              );
              dispatch(handleLayerChange(featuresTypes.MOBILE_SAMPLES, true));

              break;
            case 'Alerts':
              dispatch(actionsMobileAlerts.setSelectedMobileAlert(item));
              break;
          }
          break;
        }
        default:
          break;
      }
    }
  };
}

export function selectFeature(
  selectedProject,
  selectedFeature,
  featureType,
  leak
) {
  return {
    type: types.SELECT_LEAK,
    selectedFeature,
    featureType,
    selectedProject,
    leak,
  };
}

export const selectManageItem = (item) => {
  return (dispatch, getState) => {
    const state = getState();
    const manageContext = state.manage.managementContext;

    switch (manageContext) {
      case 'Customers':
        dispatch(actionsCustomers.selectCustomer(item.ID));
        dispatch(actionsCustomers.loadDistributionLists(item.ID));
        // dispatch(actionsCustomers.loadUsersOfCustomer(item.ID));
        break;
      case 'Projects':
        dispatch(actionsProjects.selectProject(item.ID, true));
        dispatch(actionsProjects.viewProjectDetails(item.ID));
        //const projectCenter = this.props.selectedManageItem.editedCenter;
        break;
      case 'Users':
        dispatch(actionsUsers.viewUserDetails(item.UID));
        break;

      default:
        break;
    }
  };
};

export function addSingleFeature(project, feature, icon, type) {
  return (dispatch) => dispatch(addFeature(project, feature, icon, type));
}

function addFeature(project, feature, icon, featureType) {
  return {
    type: types.ADD_SINGLE_FEATURE,
    project,
    feature,
    icon,
    featureType,
  };
}
//--------------------------------
// Date
//--------------------------------
export function dateFields() {
  return [
    'TimeOfMaintenance',
    'detected',
    'DetectedAt',
    'fixDate',
    'ClosureDate',
    'DeviceInstallationDate',
    'LastActiveTime',
    'LastCommunicationTime',
    'SensorInstallationDate',
    'sampleTime',
  ];
}

export function isDateField(field) {
  return dateFields().indexOf(field) != -1;
}

// function formatDate(timestamp) {
//     var date = new Date(timestamp * 1000);
//     //return date.toLocaleDateString();
//     return date.toISOString().substr(0, 10);

// }

// function formatObjectsDate(objects) {
//     var df = dateFields();
//     objects.forEach(function (object) {
//         df.forEach(function (fieldKey) {
//             if (object && object.hasOwnProperty(fieldKey) && object[fieldKey] != null) {
//                 if (fieldKey == 'TimeOfMaintenance') {
//                     //debugger;
//                 }
//                 object[fieldKey] = formatDate(object[fieldKey]);
//             }
//         });
//     });
//     return objects;
// }

//--------------------------------
// Options
//--------------------------------

function receiveOptions(options) {
  return {
    type: types.RECEIVE_OPTIONS,
    options,
  };
}

function requestOptions() {
  return {
    type: types.REQUEST_OPTIONS,
  };
}

export function fetchOptionsIfNeeded() {
  return (dispatch) => {
    return dispatch(fetchOptions());
  };
}

function fetchOptions() {
  return (dispatch, getState) => {
    const path = endPoints.OPTIONS_ENDPOINT;
    dispatch(requestOptions());
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        const state = getState();
        const urlPath =
          (state.routing.locationBeforeTransitions &&
            state.routing.locationBeforeTransitions.pathname.replace(
              '/',
              ''
            )) ||
          'alerts';
        let defaultFilters = [];
        let alertTypeDefaultFilters = [];
        json.data.AlertState.forEach((row) => {
          if (row.isDefault) {
            defaultFilters.push(row.value);
          }
        });
        json.data.AlertType.forEach((row) => {
          if (row.isDefault) {
            alertTypeDefaultFilters.push(row.value);
          }
        });
        dispatch(receiveOptions(json.data));
        //Set sort and filters
        if (urlPath == 'install') {
          dispatch(actionsSensors.setSensorsFilters([]));
          dispatch(
            actionsSensors.setSensorsDefFilters({
              sortBy: 'id',
              sortByDir: 'asc',
              SensorStatus: [],
            })
          );
        } else {
          let defSensorsStatusFilters = ['Ready', 'Active', 'Non Active'];
          let sensorsDefFilters = {
            sortBy: 'id',
            sortByDir: 'asc',
            SensorStatus: defSensorsStatusFilters,
          };
          let sensorsFilters = [
            { field: 'SensorStatus', value: defSensorsStatusFilters, op: '=' },
          ];
          if (localStorage[FILTERS_SENSORS]) {
            sensorsDefFilters = JSON.parse(
              localStorage.getItem(FILTERS_SENSORS)
            );
            sensorsFilters = [];
            Object.entries(sensorsDefFilters).forEach((filterEntry) => {
              if (
                [
                  'SensorStatus',
                  'SensorTypeValue',
                  'isUnderground',
                  'SyncMethodValue',
                  'DeviceGenerationValue',
                  'LastUninstallReason',
                  'Fragmented',
                  'Resets',
                ].indexOf(filterEntry[0]) !== -1
              ) {
                const filterObj = {
                  field: filterEntry[0],
                  value: filterEntry[1],
                };
                if (filterObj.field === 'LastUninstallReason') {
                  filterObj.avoidNulls = true;
                }
                sensorsFilters.push(filterObj);
              }
            });
          }
          dispatch(actionsSensors.setSensorsFilters(sensorsFilters));
          dispatch(actionsSensors.setSensorsDefFilters(sensorsDefFilters));
        }

        let sopsDefFilters = {};
        let sopsFilters = [];
        if (localStorage[FILTERS_SOPS]) {
          sopsDefFilters = JSON.parse(localStorage.getItem(FILTERS_SOPS));
          sopsFilters = [];
          Object.entries(sopsDefFilters).forEach((filterEntry) => {
            if (['sortBy', 'sortByDir'].indexOf(filterEntry[0]) == -1) {
              sopsFilters.push({
                field: filterEntry[0],
                value: filterEntry[1],
              });
            }
          });
        }
        dispatch(actionsSOPs.setSopsDefFilters(sopsDefFilters));
        dispatch(actionsSOPs.setSopsFilters(sopsFilters));

        // dispatch(actionsSensors.sortSensors('id', 'asc'));
        // dispatch(actionsCouples.sortCouples('ID', 'asc'));
        let couplesDefFilters = { sortBy: 'id', sortByDir: 'asc' };
        let couplesFilters = [];
        if (localStorage[FILTERS_COUPLES]) {
          couplesDefFilters = JSON.parse(localStorage.getItem(FILTERS_COUPLES));
          couplesFilters = [];
          Object.entries(couplesDefFilters).forEach((filterEntry) => {
            if (['sortBy', 'sortByDir'].indexOf(filterEntry[0]) == -1) {
              couplesFilters.push({
                field: filterEntry[0],
                value: filterEntry[1],
              });
            }
          });
        }
        dispatch(actionsCouples.setCouplesDefFilters(couplesDefFilters));
        dispatch(actionsCouples.setCouplesFilters(couplesFilters));

        dispatch(sortSamples('SampleDateAndTime', 'desc'));
        dispatch(actionsCouples.sortCoupleSamples('SampleDateAndTime', 'desc'));

        let alertsDefFilters = {
          AlertState: defaultFilters,
          AlertType: alertTypeDefaultFilters,
          IntensityOpr: '=',
          ProbabilityOpr: '=',
          DetectedAtOpr: '=',
          sortBy: 'Priority',
          sortByDir: 'asc',
        };
        let filters = [
          { field: 'AlertState', value: defaultFilters, op: '=' },
          { field: 'AlertType', value: [0, 1] },
        ];
        if (localStorage[FILTERS_ALERTS]) {
          alertsDefFilters = JSON.parse(localStorage.getItem(FILTERS_ALERTS));
          filters = [];
          Object.entries(alertsDefFilters).forEach((filterEntry) => {
            if (
              [
                'IntensityOpr',
                'ProbabilityOpr',
                'DetectedAtOpr',
                'sortBy',
                'sortByDir',
              ].indexOf(filterEntry[0]) == -1
            ) {
              switch (filterEntry[0]) {
                case 'Intensity': {
                  filters.push({
                    field: filterEntry[0],
                    value: [filterEntry[1]],
                    op: alertsDefFilters.IntensityOpr || '=',
                  });
                  break;
                }
                case 'Probability': {
                  filters.push({
                    field: filterEntry[0],
                    value: [filterEntry[1]],
                    op: alertsDefFilters.ProbabilityOpr || '=',
                  });
                  break;
                }
                case 'DetectedAt-To':
                case 'DetectedAt-From': {
                  let detectedFilterIndex = filters
                    .map((item) => item.field)
                    .indexOf('DetectedAt');
                  let detectedFilter = {};
                  if (detectedFilterIndex != -1) {
                    detectedFilter = filters[detectedFilterIndex];
                  } else {
                    detectedFilter = {
                      field: 'DetectedAt',
                      op: 'range',
                      from: false,
                      to: false,
                    };
                  }

                  if (filterEntry[0] == 'DetectedAt-To') {
                    detectedFilter.to = filterEntry[1];
                  }

                  if (filterEntry[0] == 'DetectedAt-From') {
                    detectedFilter.from = filterEntry[1];
                  }

                  if (detectedFilterIndex == -1) {
                    filters.push(detectedFilter);
                  } else {
                    filters[detectedFilterIndex] = detectedFilter;
                  }
                  break;
                }
                default: {
                  filters.push({
                    field: filterEntry[0],
                    value: filterEntry[1],
                  });
                  break;
                }
              }
            }
          });
        }
        dispatch(actionsAlerts.setFilters(filters));
        return dispatch(actionsAlerts.setDefFilters(alertsDefFilters));
      })
      .catch((e) => {
        console.log(e);
      });
  };
}

//--------------------------------
// Settings
//--------------------------------
export const fetchCustomDetails = () => {
  return (dispatch, getState) => {
    const path = endPoints.END_POINT + '/customerDetails';
    return fetchMiddleware(path, {}, getState).then((json) => {
      console.log(json);
      return dispatch(receiveCustomDetails(json.data));
    });
  };
};

export const fetchImages = (getState, projectId, itemId, itemType) => {
  const path =
    endPoints.END_POINT +
    `/images/${projectId}?itemId=${itemId}&itemType=${itemType}`;
  return fetchMiddleware(path, {}, getState);
};

export function setEditMode(userData) {
  return {
    type: types.SET_EDIT_MODE,
    editMode: userData.canEdit,
    userEditMode: userData.canEditUsers,
    canAddAlert: userData.canAddAlert,
    isAQS: userData.isAQS,
    viewWaterMeter: userData.waterMeterAccess,
    analysesExpert: userData.graphsLables,
    installerPlanner: userData.installerPlanner,
    advancedMapIndications: userData.advancedMapIndications,
    uid: userData.userID,
    customerId: userData.customerId,
    isPCA: userData.isPCA,
    operatePcaModule: userData.operatePcaModule,
    technicianAdvanced: userData.technicianAdvanced,
    g5Operator: userData.g5Operator,
    g5Admin: userData.g5Admin,
    g5AqsAdmin: userData.g5AqsAdmin,
    mobile: userData.mobile,
  };
}

export function setUnit(unit) {
  return {
    type: types.SET_UNIT,
    unit,
  };
}

export function setZone(zone) {
  return {
    type: types.SET_ZONE,
    zone,
  };
}

export const editComment = (form, field) => {
  // TODO: REMOVE IF NOT USE
  return (dispatch) => {
    //dispatch(focusToCommentField(form, field));
    return dispatch(clearCommentField(form, field));
  };
};

export const copyComment = (form, field) => {
  // TODO: REMOVE IF NOT USE
  return (dispatch, getState) => {
    const state = getState();
    if (state.form[form] != null) {
      const value = state.form[form].values[field];
      dispatch(copy({ payload: { value } }));
      return true;
    }
    return true;
  };
};

const receiveCustomDetails = (logo) => {
  return {
    type: types.RECEIVE_CUSTOM_DETAILS,
    logo,
  };
};

const clearCommentField = (form, field) => {
  return {
    type: '@@redux-form/CHANGE',
    meta: {
      form: form,
      field: field,
      touch: false,
      persistentSubmitErrors: false,
    },
    payload: '',
  };
};

const focusToCommentField = (form, field) => {
  return {
    type: '@@redux-form/FOCUS',
    meta: { form: form, field: field },
  };
};
//--------------------------------
// Notification
//--------------------------------

export function clearNotification() {
  return {
    type: types.CLEAR_NOTIFICATION,
  };
}

//--------------------------------
// Intervaled Actions
//--------------------------------

export function updateState(time) {
  return {
    type: types.REFRESH_STATE,
    time,
  };
}

//--------------------------------
// Management Actions
//--------------------------------
export const changeManagementContext = (context) => {
  const action = {
    type: types.CHANGE_MANAGE_CONTEXT,
    context,
  };

  if (context != 'Projects') {
    return action;
  } else {
    // select the current project by default:
    return (dispatch, getState) => {
      const state = getState();
      const selectedProjectId = state.leaksList.selectedProject;
      const projectBundle = state.leaksList.projectsList;
      const selectedProject =
        projectBundle.items[projectBundle.projectIndexMap[selectedProjectId]];
      dispatch(action);
      //return dispatch(selectManageItem(selectedProject))
    };
  }
};

//export function searchItemByID(itemID, type, cb) {
//    return (dispatch, getState) => {
//        const path = endPoints.END_POINT + '/id/' + itemID + '/type/' + type;
//        return fetchMiddleware(path, {}, getState).then(json => {
//            let iconType = 'leaks';
//            switch (type) {
//                case 'alert':
//                    iconType = 'leaks';
//                    break;
//                case 'sensor':
//                    iconType = 'sensors';
//                    break;
//                default:
//                    break;
//            }

//            const icon = getIconFeatures(iconType, json.data);
//            cb(json.status, json.data[0], icon);
//        });
//    }
//}

export const clearManageItem = () => {
  return {
    type: types.CLEAR_MANAGE_ITEM,
  };
};

export const addNewManageItem = () => {
  return {
    type: types.ADD_NEW_MANAGE_ITEM,
  };
};

// ------------------------------------
// Google's Address
// ------------------------------------

export const resetForm = (form) => {
  return reduxFormActions.reset(form);
};

export const initializeForm = (form, data) => {
  return reduxFormActions.initialize(form, data, false);
};

export const setFieldText = (form, field, text) => {
  return {
    type: '@@redux-form/CHANGE',
    meta: {
      form: form,
      field: field,
      touch: false,
      persistentSubmitErrors: false,
    },
    payload: text,
  };
};

const saveTempAddressForItem = (
  selectedFeatureType,
  selectedFeatureID,
  address
) => {
  return {
    type: types.SAVE_TEMP_ADDRESS_FOR_ITEM,
    selectedFeatureType,
    selectedFeatureID,
    address,
  };
};

const getItemPositionForAddress = (state) => {
  let position = {};

  if (state.mapState.drawMode.isActive) {
    if (state.mapState.drawMode.data != null) {
      const points = state.mapState.drawMode.data[0];
      position.lat = points[1];
      position.lng = points[0];
    }
  } else if (
    state.install.newInstallItem != null &&
    state.install.newInstallItem.id == -1
  ) {
    // in add SOP etc.
    const point = state.install.newInstallItem.position;
    if (point != null) {
      position.lat = point[1];
      position.lng = point[0];
    }
  } else {
    const selectedFeatureID = state.leaksList.leaksByProject.selectedFeature;
    const selectedFeatureType =
      state.leaksList.leaksByProject.selectedFeatureType;
    const selectedProjectID = state.leaksList.selectedProject;
    let item = null;
    switch (selectedFeatureType) {
      case featuresTypes.ALERT: {
        const projectAlerts =
          state.leaksList.leaksByProject[selectedProjectID].items;
        const projectAlertsIndexMap =
          state.leaksList.leaksByProject[selectedProjectID].indexMap;
        item = projectAlerts[projectAlertsIndexMap[selectedFeatureID]];
        break;
      }
      case featuresTypes.SENSOR: {
        const projectSenosors =
          state.leaksList.leaksByProject[selectedProjectID].sensors.sensors;
        const projectSensorsIndexMap =
          state.leaksList.leaksByProject[selectedProjectID].sensors.indexMap;
        item = projectSenosors[projectSensorsIndexMap[selectedFeatureID]];
        break;
      }
      case featuresTypes.SOP: {
        const projectSOPs =
          state.leaksList.leaksByProject[selectedProjectID].sops.sops;
        const projectSOPsIndexMap =
          state.leaksList.leaksByProject[selectedProjectID].sops.indexMap;
        item = projectSOPs[projectSOPsIndexMap[selectedFeatureID]];
        break;
      }
      default:
        break;
    }
    position.lat = item.Latitude;
    position.lng = item.Longitude;
  }

  return position;
};

export const getAddressByLatLong = (form, field) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedFeatureID = state.leaksList.leaksByProject.selectedFeature;
    const selectedFeatureType =
      state.leaksList.leaksByProject.selectedFeatureType;
    let languageKey = state.i18nState.lang;

    const position = getItemPositionForAddress(state);

    if (position != null && position.lat != null && position.lng != null) {
      if (languageKey == null) {
        languageKey = 'en';
      }
      return dispatch(
        coordinatesToAddress(
          { Latitude: position.lat, Longitude: position.lng },
          (address) => {
            dispatch(
              saveTempAddressForItem(
                selectedFeatureType,
                selectedFeatureID,
                address
              )
            );
            return dispatch(
              setFieldText(form, field, address.FormattedAddress)
            );
          }
        )
      );
      //const path = endPoints.END_POINT + '/lat/' + latitude + '/lng/' + longitude + '/lang/' + languageKey + '/getAddress';
      //return fetchMiddleware(path, {}, getState).then(json => {
      //  return dispatch(setFieldText(form, field, json.data.formattedAddress));
      //});
    }
  };
};

export const searchAddress = (addressToSearch, callback) => {
  return () => {
    const Coordinates = require('coordinate-parser');
    try {
      // for case that addressToSearch input is already coordinate:
      const position = new Coordinates(addressToSearch);
      callback({ lat: position.getLatitude(), lng: position.getLongitude() });
      // return plane action-object for the rudux documentation of dispatched function
      return { type: 'search success by coordinate' };
    } catch (error) {
      geocoder.findPositionByAddress(addressToSearch, callback);
    }
  };
};

//https://nominatim.openstreetmap.org/reverse?format=xml&lat=32.293197&lon=34.863215&zoom=14&addressdetails=0

const coordinatesToAddress = (coordinates, cb) => {
  return (dispatch, getState) => {
    const language = getState().i18nState.lang;
    return geocoder.findAddressByPosition(coordinates, language, cb);
  };
};

export const downloadFileFromURl = (url) => {
  // script that download file from url:
  const tempElement = document.createElement('a');
  tempElement.href = url;
  tempElement.target = '_blank';
  tempElement.download = true; // fileName || url.substring((url.lastIndexOf('/') + 1), url.lastIndexOf('?'));
  document.body.appendChild(tempElement);
  tempElement.click();
  document.body.removeChild(tempElement);
};

export function parsePolygonFromDB(rawPolygon) {
  let polygon = null;

  if (rawPolygon != null) {
    // #1: cut the prefix by find the first '(' in the string:
    let firsCharIndex = rawPolygon.indexOf('(');
    let rawPoints = rawPolygon.substring(firsCharIndex);

    const loopFunction = (data) => {
      let polygonDataArray = [];

      if (data.charAt(0) == '(') {
        // we have an array.
        // cut the parenthesis:
        let innerData = data.substring(1, data.length - 1);
        let splitedData = innerData.split('), (');
        splitedData.forEach((arrayItem) => {
          let polygonData = loopFunction(arrayItem);
          polygonDataArray.push(polygonData);
        });
      } else {
        let rawPointsArray = data.split(', ');
        let pointsArray = [];
        rawPointsArray.forEach((point) => {
          let pointItem = point.split(' ');
          pointsArray.push([
            parseFloat(pointItem[0]),
            parseFloat(pointItem[1]),
          ]);
        });
        polygonDataArray.push(pointsArray);
      }

      return polygonDataArray;
    };

    polygon = loopFunction(rawPoints);

    // const startIndex = rawPolygon.indexOf('((') + '(('.length;
    // const endIndex = rawPolygon.indexOf('))');
    // // cut the coordinate part:
    // let rowData = rawPolygon.substring(startIndex, endIndex);

    // let rowArray = rowData.split(',');

    // polygon = [];

    // rowArray.forEach((item) => {
    //   const point = item.trim().split(' ');
    //   polygon.push([
    //     parseFloat(point[0]),
    //     parseFloat(point[1])
    //   ]);
    // });
  }

  return polygon;
}

export const getSignedUrlForFile = (bucket, key, cb) => (dispatch, getState) => {
  const path = `${endPoints.END_POINT}/signedfile?bucket=${bucket}&key=${key}`;
  return fetchMiddleware(path, {}, getState).then((json) => cb(json));
}
