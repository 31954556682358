/* eslint-disable */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';
import TabsContainer from '../Custom/Tabs/TabsContainer';
import { HistoryTab, FilesTab, LeakGrowth, CoupleSamplesTable } from '../../components';
import CDetailsTab from '../../containers/CDetailsTab/CDetailsTab';

import Paper from '@mui/material/Paper';
import './AlertsTabs.scss';
const classNames = require('classnames');

export default class AlertsTabs extends React.Component {

  constructor(props) {
    super(props);
    this.closePanel = this.closePanel.bind(this);
    this.sortSamples = this.sortSamples.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.featureType == 'alert') {
      if (nextProps.selectedFeature !== "") {
        if (!nextProps.showDetails) {
          this.props.toggleDetailsVisibility(true);
        }
      }
    } else {
      if (nextProps.showDetails) {
        this.props.toggleDetailsVisibility(false);
      }
    }
  }

  closePanel() {
    this.props.toggleDetailsVisibility(false);
    this.props.selectSample({});
    this.props.clearSelectedFeature();
  }

  sortTable(field, dir) {
    this.props.sortSamples(field, dir);
  }

  sortSamples() {
    const { filters } = this.props;
    if(filters.samplesSort) {
      return {
        sort: this.props.sortSamples,
        sortBy: filters.samplesSort.field,
        sortDir: filters.samplesSort.dir
      }
    }
  }

  onTabChangeFunc = (index, lastIndex, event) => {
    if (lastIndex === 3 && index === 0) {
      // change from 'Samples' to 'Details':
      this.props.selectSample({});
    }
  }

  render() {
      const {
        user,
        details,
        distanceFactor,
        timeZone,
        alertItem,
        selectedProject,
        selectedFeature,
        history,
        uiInfo,
        showDetails,
        files,
        loadFiles,
        uploadFile,
        downloadFile,
        deleteFile,
        samples,
        selectSample,
        selectedSample,
        coupleSamples,
        projectName,
        navigateToFeature,
        onPageIndexChange,
        samplesPage,
        alertStatesOpitons,
        alertTypesOpitons,
        onCreateNewValue,
        leakGrowth,
        algParams
      } = this.props;

    if (!selectedFeature) {
      return null;
    }

    const isFilesFetching = files[selectedFeature] != null && files[selectedFeature].isFetching;

    const panelClasses = classNames(
      'alert-details',
      { 'show': showDetails },
      (isFilesFetching ? 'fetching' : '')
    );
    const panelStyle = { height: 0.493 * uiInfo.pageHeight };
    const leakSamples = (samples[selectedFeature] != null) ? samples[selectedFeature].data : [];
    const samplesCount = (samples[selectedFeature] != null) ? samples[selectedFeature].total : 0;
    let leakHistory = [];
    if (history && history[selectedFeature]) {
      leakHistory = history[selectedFeature].data;
    }

    const coupleSamplesItems = alertItem ? (coupleSamples[alertItem.CoupleID] || {}) : {};

    const tabs = [
      {
        label: this.context.t('Details'),
        component: (
          <CDetailsTab
            projectName={projectName}
            onCreateNewValue={onCreateNewValue}
            pageNotification={this.props.notify}
            getMapCapture={this.props.getMapCapture}
          />
        )
      },
      {
        label: this.context.t('History'),
        component: (
          <HistoryTab
            alertTypesOpitons={alertTypesOpitons}
            alertStatesOpitons={alertStatesOpitons}
            history={leakHistory}
            uiInfo={uiInfo}
            local={timeZone}
          />
        )
      }
    ];

    if (ENV !== ENVIRONMENTS.SINGAPORE) {
      tabs.push(
        {
          label: this.context.t('Files'),
          component: (
            <FilesTab
              files={files[selectedFeature]}
              uploadFile={uploadFile}
              selectedProject={selectedProject}
              selectedFeature={selectedFeature}
              loadFiles={loadFiles}
              deleteFile={deleteFile}
              downloadFile={downloadFile}
            />
          )
        }
      );
    }

    tabs.push(...[
      {
        label: this.context.t('Samples'),
        style: {},
        component: (
          <CoupleSamplesTable
            selectedFeature={selectedFeature}
            coupleSamples={coupleSamplesItems}
            selectCoupleSample={selectSample}
            selectedSample={selectedSample}
            navigateToFeature={navigateToFeature}
            onPageIndexChange={onPageIndexChange}
            timeZone={timeZone}
            distanceFactor={distanceFactor}
            algParams={algParams}
            deviceSync={details.DeviceSync}
            showOnlyRelatedProbability
          />
        )
      },
      {
        label: this.context.t('Leak Growth'),
        component: (
          <LeakGrowth
            data={leakGrowth}
          />
        )
      },
    ]);

    return (
        <Paper className={panelClasses} style={panelStyle}>
          <TabsContainer
            title={this.context.t('Alert {alertId}', { alertId: selectedFeature })}
            handleClose={this.closePanel}
            tabs={tabs}
          />
        </Paper>
    );
  }
}

AlertsTabs.contextTypes = {
  t: PropTypes.func.isRequired
};
