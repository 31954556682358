import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Select, Input, CommentField, AddressField, DeleteModal} from '../../components';
import { InstallOperationMode, UninstallMode } from '../../constants/Misc';



class SOPsDetailsTab extends React.Component {
  render() {
      const { mode, selectedSOP, user, pristine, submitting, handleSubmit, requiresAreComplete, installState } = this.props;

      if (installState == null || user == null) {
        return null;
      }

      const buttonTitle = (installState.newInstallItem == null) ? this.context.t('Save') : this.context.t('Create New SOP');

    return (
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <CommentField
          fieldName="Comment"
          featureID={selectedSOP}
          placeholder={this.context.t('Installation Point Comment')}
          onEdit={this.props.onEdit}
          onCopy={this.props.onCopy}
          disabled={!user.editMode}
        />
        <div className="form-group">
          <AddressField user={this.props.user} fieldName="StreetAddress" onGetAddress={this.props.getAddressByLatLong} />
        </div>
        {this.props.selectedSOP === null &&
          <div className="form-group">
            <label className="col-xs-2 control-label">{this.context.t('Coordinate')}:</label>
            <div className="col-xs-10 control-input">
              <Field
                className="form-control"
                name="Coordinate"
                placeholder={this.context.t('Click on Map to set coordinate')}
                component={Input}
                type="text"
                disabled={!user.editMode} />
            </div>
          </div>
        }
        <div className="form-group">
          <label className="col-xs-2 control-label">{this.context.t('Asset ID')}:</label>
          <div className="col-xs-10 control-input">
            <Field name="AssetID" component={Input} type="text" className="form-control" disabled={!user.editMode} />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('Type')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="AvizarCode"
              disabled={!user.editMode}
              component={(props) =>
                <Select {...props}
                  key={"AvizarCode"}
                  clearable={false}
                  placeholderTitle={this.context.t('Select...')}
                  options={this.props.SopTypes}
                />
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('State')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="State"
              disabled={!user.editMode}
              component={(props) =>
                <Select {...props}
                  key={"State"}
                  clearable={false}
                  placeholderTitle={this.context.t('Select...')}
                  options={this.props.SopStatuses}
                />
              }
            />
          </div>
        </div>


        <div className="form-actions">
          <button className="btn btn-success btn-submit" disabled={pristine || submitting || !requiresAreComplete} type="submit">{buttonTitle}</button>

          {mode == InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE || mode == InstallOperationMode.SOP_AND_SENSOR ?
          (
            <DeleteModal
            className="btn btn-danger"
            data-toggle="tooltip"
            data-placement="top"
            title="Please Uninstall Device"
            id={this.props.selectedSOP}
            name={this.props.selectedSOP}
            type={'sop'}
            disabled={true}
          />
          ) : (
            <DeleteModal
            className="btn btn-danger"
            data-toggle="tooltip"
            data-placement="top"
            title="Please Uninstall Device"
            id={this.props.selectedSOP ? this.props.selectedSOP : ''}
            name={this.props.selectedSOP ? this.props.selectedSOP : ''}
            type={'sop'}
            disabled={!user.installerPlanner}
            onDelete={this.props.setDeleteMode}
          />
          )
          }

        </div>
      </form>
      );
  }
}

const validate = (values, props) => {
  const errors = {}

  if (values.AvizarCode == null || values.AvizarCode.toString() == '') {
    errors.AvizarCode = 'Required!';
  }

  if (values.State == null || values.State.toString() == '') {
    errors.State = 'Required!';
  }

  return (errors);
}

SOPsDetailsTab.contextTypes = {
  t: PropTypes.func.isRequired
}

// Decorate the form component
SOPsDetailsTab = reduxForm({
  form: 'sopForm', // a unique name for this form
  validate,
  touchOnBlur: false,
  enableReinitialize: true,
})(SOPsDetailsTab);
export default SOPsDetailsTab;
