import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';

export default class CustomerDistributionListsTable extends React.Component {

  constructor(props) {
    super(props);

    this.handleSelectList = this.handleSelectList.bind(this);
    this.getClassName = this.getClassName.bind(this);
  }

  handleSelectList(_event, rowIndex) {
    const selectedList = this.props.items[rowIndex]
    this.props.selectLisHandler(selectedList)
  }

  getClassName(rowIndex) {
    const { selectedList } = this.props;
    const { items } = this.props;
    let className = '';
    if (selectedList) {
      if (selectedList.ID == items[rowIndex].ID) {
        className = 'active-row';
      }
    }
    return className;
  }

  render() {
    const { items } = this.props;

    return (
      <Table
        maxHeight={500}
        width={220}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={this.handleSelectList}
        rowClassNameGetter={this.getClassName}
      >
        <Column
          columnKey="ListName"
          width={190}
          header={<Cell>{this.context.t('List Name')}</Cell>}
          cell={(data) => (<Cell>{items[data.rowIndex]["Name"]}</Cell>)}
        />
        <Column
          columnKey="AnnouncesStatusChange"
          width={30}
          header={<Cell/>}
          cell={(data) => (
          <input
            type='checkbox'
            checked={items[data.rowIndex].AnnouncesStatusChange}
            onChange={(event) => {
              const list = items[data.rowIndex];
              const customerId = this.props.selectedCustomer.ID;
              this.props.setDistributionListAsAnnouncesStatusChange(customerId, list, event.target.checked);
            }} />
          )}
        />
      </Table>
    );
  }
}

CustomerDistributionListsTable.contextTypes = {
  t: PropTypes.func.isRequired
};
