import * as types from '../constants/ActionTypes';

const initialState = {
  managementContext: 'Users',
  selectedManageItem: null,
  isManageDistributorProjects: false,
  polygon: null
};

const manage = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case types.CHANGE_MANAGE_CONTEXT:
      newState = Object.assign({}, state, {
        managementContext: action.context,
        selectedManageItem: null,
      });
      break;
    case types.ADD_NEW_MANAGE_ITEM:
      newState = Object.assign({}, state, {
        selectedManageItem: {
          id: -1,
          item: {},
        }
      });
      break;
    case types.CLEAR_MANAGE_ITEM:
      newState = Object.assign({}, state, {
        selectedManageItem: null,
      });
      break;

    case types.VIEW_PROJECT:
      newState = Object.assign({}, state, {
        selectedManageItem: {
          id: action.project.ID,
          item: action.project,
        }
      });
      break;
    case types.SELECT_USER:
      newState = Object.assign({}, state, {
        selectedManageItem: {
          id: action.user.UID,
          item: action.user,
        }
      });
      break;

    case types.REQUEST_PROJECT_ATTR:
      newState = Object.assign({}, state, {
        selectedManageItem: null,
      });
      break;

    case types.RECEIVE_PROJECT_ATTR:
      newState = Object.assign({}, state, {
        selectedManageItem: {
          id: action.project.ID,
          item: action.project,
        }
        });
      break;

    case types.SELECT_CUSTOMER:
      newState = Object.assign({}, state, {
        selectedManageItem: {
          id: action.customer.ID,
          item: action.customer,
        }
      });
      break;

    case types.GET_CENTER_FROM_MAP:
      newState = Object.assign({}, state, {
        selectedManageItem: Object.assign({}, state.selectedManageItem, {
          editedCenter: action.center,
        })
      });
      break;

    case types.SET_OPEN_DISTRIBUTOR_PROJECTS_MODAL:
      newState = Object.assign({}, state, {
        isManageDistributorProjects: action.isOpen,
      });
      break;

    case types.RECEIVE_DISTRIBUTOR_PROJECTS:
      newState = Object.assign({}, state, {
        selectedManageItem: Object.assign({}, state.selectedManageItem, {
          item: Object.assign({}, state.selectedManageItem.item, {
            distributorProjects: action.projectsIds,
          })
        })
      });
      break;

    case types.SET_PROJECT_POLYGON_POINTS:
      newState = Object.assign({}, state, {
        polygon: action.points,
      });
      break;

    default:
      break;
  }

  if (newState != null) {
    return newState;
  } else {
    return state;
  }

}

export default manage;
