import React from 'react';
import { Field, reduxForm } from 'redux-form';
import ReactSelect from 'react-select';
import { Select, Input, CommentField, AddressField, DeleteModal } from '../../components';
import { DRAW_TYPES } from '../../constants/Misc';
import PropTypes from 'prop-types';

class InterferencesDetailsTab extends React.Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.toggleDrawMode = this.toggleDrawMode.bind(this);
        this.generateSensorsReport = this.generateSensorsReport.bind(this);
    }

    handleChange(selectedOption) {
        this.props.changeDrawMode(selectedOption);
    }

    handleDelete() {
        console.log("Entered handleDelete method");
        this.props.onDelete();
    }

    getFeatureCoordinates() {
        let selectedFeature = this.props.selectedFeatureId;

    }

    toggleDrawMode() {
        this.props.editDrawMode(this.props);
    }

    generateSensorsReport() {
      this.props.generateSensorsReport(this.props.initialValues);
    }

    render() {
        const {
            selectedFeature,
            interferencesTypes,
            sopsOptions,
            couplesOptions,
            hasPolygon,
            user,
            timeZone,
            newInstallItem,
            handleSubmit,
            pristine,
            drawMode,
            editedPolygon,
            bMapDrawMode,
            submitting } = this.props;
        const labelsClass = 'col-xs-4 control-label';
        const fieldsClass = 'col-xs-8 control-input';
        const divStyle = { float: 'left', width: '100%' };

        return (
            <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
                <CommentField
                    fieldName="Comment"
                    featureID={selectedFeature}
                    placeholder={this.context.t('Area Of Interest Comment')}
                    onEdit={this.props.onEdit}
                    onCopy={this.props.onCopy}
                    disabled={!user.editMode} />

                <div style={{ display: 'flex' }}>
                    <div style={divStyle}>
                        {/* display only if in Adding mode */}
                        <div className="form-group">
                            <label className={labelsClass}>{this.context.t('Draw Mode')}:</label>
                            <div className={fieldsClass}>
                                <Field name="DrawMode"
                                    disabled={!user.editMode || newInstallItem == null}
                                    component={(props) =>
                                        (<Select {...props}
                                            key={"DrawMode"}
                                            placeholderTitle={this.context.t('Select...')}
                                            clearable={false}
                                            value={{ label: drawMode, value: drawMode }}
                                            options={[
                                                { value: DRAW_TYPES.CIRCLE, label: this.context.t('Circle') },
                                                { value: DRAW_TYPES.POLYGON, label: this.context.t('Polygon') },
                                            ]}
                                            onChangeHandler={this.handleChange}
                                        />)
                                    }
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className={labelsClass}>{this.context.t('Start Frequency(Hz)')}:</label>
                            <div className={fieldsClass}>
                                <Field
                                    name="StartFrequency"
                                    component={Input}
                                    className="form-control"
                                    type="number"
                                    timeZone={timeZone}
                                    disabled={!user.editMode} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className={labelsClass}>{this.context.t('Start Time')}:</label>
                            <div className={fieldsClass}>
                                <Field
                                    name="StartTime"
                                    component={Input}
                                    className="form-control"
                                    type="time"
                                    timeZone={timeZone}
                                    disabled={!user.editMode} />
                            </div>
                        </div>
                    </div>

                    <div style={divStyle}>
                        <div className="form-group">
                            <label className={labelsClass}>{this.context.t('Type')}:</label>
                            <div className={fieldsClass}>
                                <Field name="Type"
                                  disabled={!user.editMode}
                                  component={(props) => (
                                    <Select {...props}
                                      key={"Type"}
                                      placeholderTitle={this.context.t('Select...')}
                                      options={interferencesTypes} />
                                    )
                                  }
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <label className={labelsClass}>{this.context.t('End Frequency(Hz)')}:</label>
                            <div className={fieldsClass}>
                                <Field
                                    name="EndFrequency"
                                    component={Input}
                                    className="form-control"
                                    type="number"
                                    timeZone={timeZone}
                                    disabled={!user.editMode} />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className={labelsClass}>{this.context.t('End Time')}:</label>
                            <div className={fieldsClass}>
                                <Field
                                    name="EndTime"
                                    component={Input}
                                    className="form-control"
                                    type="time"
                                    timeZone={timeZone}
                                    disabled={!user.editMode} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-xs-2 control-label">{this.context.t('Installation Point ID')}:</label>
                    <div className="col-xs-10 control-input">
                        <Field
                          clearable
                          name="SopID"
                          disabled={!user.editMode}
                          component={(props) => (
                            <Select {...props}
                              key={"SopID"}
                              placeholderTitle={this.context.t('Select...')}
                              options={sopsOptions} />
                            )
                          } />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-xs-2 control-label">{this.context.t('Linked Couples')}:</label>
                    <div className="col-xs-10 control-input">
                        <Field
                            name="LinkedCouples"
                            disabled={!user.editMode}
                            component={props =>
                                <Select {...props}
                                    key={"LinkedCouples"}
                                    placeholderTitle={this.context.t('Select...')}
                                    clearable={true}
                                    multi={true}
                                    closeMenuOnSelect={false}
                                    options={couplesOptions} />
                            } />
                    </div>
                </div>
                <div className="form-actions">
                    <div className="col-xs-10 control-input">
                        {/* Show if we are not in "Create Mode". If will I define the newInstallItem in render (this.props) I should use it without "props" */}
                        {!this.props.newInstallItem &&
                            <DeleteModal
                                id={this.props.selectedFeatureId}
                                name={this.props.selectedFeatureId}
                                type={'interference'}
                                disabled={!user.editMode}
                                onDelete={this.handleDelete}
                            />
                        }
                        {!this.props.newInstallItem &&
                            <div className="form-group" style={{ float: 'left' }}>
                                <a onClick={this.toggleDrawMode}
                                    className="btn btn-info">
                                        {this.context.t(`Edit Area Of Interest`)}
                                </a>
                            </div>
                        }
                        {!this.props.newInstallItem &&
                            <div className="form-group" style={{ float: 'left', marginLeft: '5px' }}>
                                <a onClick={this.generateSensorsReport}
                                    className="btn btn-info">
                                        {this.context.t(`Sensors Report`)}
                                </a>
                            </div>
                        }

                    </div>
                    <button className="btn btn-success btn-submit"
                        disabled={(this.props.editedPolygon ? false : (pristine || submitting) || !hasPolygon)}
                        type="submit">{this.context.t('Save')}
                    </button>
                </div>
            </form>
        );
    }
}

const validate = (values, props) => {
    const errors = {}
    // force the user to set the Interference Type:
    if (values.Type == null) {
        errors.Type = 'Required!';
    }
    return (errors);
};

InterferencesDetailsTab.contextTypes = {
    t: PropTypes.func.isRequired
};

// Decorate the form component
InterferencesDetailsTab = reduxForm({
    form: 'interferenceForm', // a unique name for this form
    validate,
    enableReinitialize: true,
})(InterferencesDetailsTab);
export default InterferencesDetailsTab;

