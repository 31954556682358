import React from 'react';
import PropTypes from 'prop-types';

import InspectTabs from './InspectTabs';

import './inspectStyle.scss';

import { PivotTableComponent, FloatingDataComponent } from '../../components';

const _ = require('lodash');

export default class InspectDataTabs extends React.Component {

   static get propTypes() {
      return {
         importAlertInvestigation: PropTypes.func,
         selectAlertsByFilters: PropTypes.func,
         selectedProject: PropTypes.number,
         selectedFeature: PropTypes.number,
         sortedAlertsDataSet: PropTypes.array,
         selectedAlerts: PropTypes.array,
         uiInfo: PropTypes.object,
         alertsState: PropTypes.array,
         alertsType: PropTypes.array,
         isDisplayCityColumn: PropTypes.bool,
         openPCA: PropTypes.func,
         onAlertRowClick: PropTypes.func,
         showAll: PropTypes.func,
      };
   }

   constructor(props) {
      super(props);

      this.state = {
        isModalOpen: false,
        filterInput: "",
        filteredData: null,
        alertsSort: {
          sort: this.sortTable,
          sortBy: '',
          sortDir: ''
        }
      };

      this.showModal = this.showModal.bind(this);
      this.closeModal = this.closeModal.bind(this);
   }

   showModal(isOpen) {
      this.setState({
         isModalOpen: isOpen
      });
   }

   closeModal() {
      this.setState({
         isModalOpen: false
      });
   }

  sortTable = (key, dir) => {
    this.props.sortLocaly(key, dir);
    this.setState({
      alertsSort: {
        ...this.state.alertsSort,
        sortBy: key,
        sortDir: dir
      }
    });
  }

  onFilterChange = (event) => {
    const { selectedAlerts } = this.props;
    const textValue = event.target.value;
    let filteredAlerts;
    if (textValue == '') {
      filteredAlerts = null;
    } else {
      filteredAlerts = selectedAlerts.filter((alert) => alert.id.toString().indexOf(textValue) != -1);
    }

    this.setState({
      filterInput: textValue,
      filteredData: filteredAlerts,
    })
  }

  onShowAlertsChange = (event) => {
    this.props.showHideAlerts(event.target.checked);
  }

  render () {
   // Props Parameters From <CInspectDataTabs></CInspectDataTabs>
   const {
      sortedAlertsDataSet,
      selectAlertsByFilters,
      selectedProject,
      selectedFeature,
      importAlertInvestigation,
      selectedAlerts,
      isDisplayCityColumn,
      uiInfo,
      alertsState,
      alertsType,
      openPCA,
      openLeakGrowth,
      onAlertRowClick,
      showAll } = this.props;
      const { filterInput, filteredData, alertsSort } = this.state;

      let alertsList = (filteredData != null) ? filteredData : selectedAlerts;

      // if (! _.isEmpty(alertsSort.sortBy) && ! _.isEmpty(alertsSort.sortDir)) {
      //   const { sortBy, sortDir } = alertsSort;
      //   alertsList = alertsList.sort((a, b) => {
      //     let sortResult = 0;

      //     const firstAttr = (sortDir === 'asc') ? a[sortBy] : b[sortBy];
      //     const secondAttr = (sortDir === 'asc') ? b[sortBy] : a[sortBy];

      //     if (firstAttr > secondAttr) sortResult = 1;
      //     if (firstAttr < secondAttr) sortResult = -1;

      //     return (sortResult);
      //   })
      // }

      const buttonsDivStyle = {
         position: 'absolute',
         top: 0,
         right: 0,
         margin: '2px'
      };
      const buttonStyle = {
         padding: '2px 12px',
         margin: '0 2px',
      };
      const filterStyle = {
        padding: '2px',
         margin: '0 2px',
      };

    return (
      <div>
        <div className='main-data-area'>
          <div
            style={{
              position: 'absolute',
              left: '100px',
              top: '3px'
            }}
          >
            <label>{this.context.t('Map Alerts')}&nbsp;</label>
            <input
              type='button'
              value={this.context.t('Show / Hide')}
              onClick={this.onShowAlertsChange}
            />
          </div>

          <div style={buttonsDivStyle}>

            <input
              style={filterStyle}
              type='text'
              value={filterInput}
              onChange={this.onFilterChange}
              placeholder={this.context.t('Alert ID Filter')} />

            <button
              style={buttonStyle}
              className='btn btn-info'
              onClick={() => {
                showAll();
              }}
            >{this.context.t('Show All')}</button>

            <button
              style={buttonStyle}
              className='btn btn-primary'
              onClick={() => {
                this.showModal(true);
              }}
            >{this.context.t('Analyzer')}</button>

          </div>

          <InspectTabs
            items={alertsList}
            sortProp={alertsSort}
            sortLocaly={this.props.sortLocaly}
            selectedFeature={selectedFeature}
            uiInfo={uiInfo}
            alertsState={alertsState}
            alertsType={alertsType}
            isDisplayCityColumn={isDisplayCityColumn}
            openPCA={openPCA}
            openLeakGrowth={openLeakGrowth}
            onAlertRowClick={onAlertRowClick}
            t={this.context}
          />

          {this.state.isModalOpen &&
            <FloatingDataComponent
              isOpen={this.state.isModalOpen}
              onClose={this.closeModal}
              offsetParent={document.body}
              sortedAlertsDataSet={sortedAlertsDataSet}
              selectAlertsByFilters={selectAlertsByFilters}
            />
          }
        </div>

        {false &&
          <div className="main-data-area">
            {selectedAlerts.length > 0 &&
              <InspectTabs
                items={selectedAlerts}
                selectedFeature={selectedFeature}
                uiInfo={uiInfo}
                alertsState={alertsState}
                alertsType={alertsType}
                isDisplayCityColumn={isDisplayCityColumn}
                openPCA={openPCA}
                onAlertRowClick={onAlertRowClick}
                t={this.context}
              />}
          </div>
        }
      </div>

    );
  }
}

InspectDataTabs.contextTypes = {
   t: PropTypes.func.isRequired
};
