import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');

const classNames = require('classnames');

// Alerts layer style
const style = new Style({
    image: new Icon({
        // See dynamic styles in getAlertStyle()
        src: require('../../../../images/map/alerts/alert-inspect.png'), anchor: [0.5, 1]
    })
});

const source = new VectorSource({
    features: [],
});

// Alerts layer
export const layer = new VectorLayer({
    name: 'layerAlerts',
    style: style,
    source: source,
    zIndex: zIndexs.alerts,
});

export const setSource = (nextIconFeatures, props) => {
    let iconFeatures = [];
    Object.keys(nextIconFeatures).forEach((key) => {
        const itrFeature = nextIconFeatures[key];
        const position = [itrFeature.Longitude, itrFeature.Latitude];
        const iconFeature = new Feature({
            geometry: new Point(
                proj.transform(
                    position,
                    'EPSG:4326',
                    'EPSG:3857'
                )
            ),
            type: featuresTypes.INSPECT_ALERTS,
            id: itrFeature.ID,
            creationDate: itrFeature.DetectionDateTime,
            closureDate: itrFeature.ClosureDate,
            coupleId: itrFeature.CoupleID,
            material: itrFeature.Material,
            diameterInch: itrFeature.PipeDiameterInch,
            comment: itrFeature.Comment,
						placementYear: itrFeature.PlacementYear,
						isManual: (itrFeature.ManualLeakProjectID != null),
        });
        iconFeature.setStyle(getStyle(iconFeature));
        iconFeatures.push(iconFeature);
    });
    source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected) => {
    let alertStyle = false;
		const attr = feature.getProperties();

		bSelected = bSelected ? '-selected' : '';

		const isManual = ''; // attr.isManual ? '-manual' : '';

    alertStyle = new Style({
        image: new Icon({
            src: require(`../../../../images/map/alerts/alert-inspect${isManual}${bSelected}.png`),
            anchor: [0.5, 1],
        }),
    });

    return alertStyle;
};

export const getInfoTooltip = (feature) => {
    const attr = feature.getProperties();
    return (attr);
};

export const tooltipRender = (itemInfo, context) => {
    const tooltipClasses = classNames(
        'tooltip',
        'priority-' + itemInfo.priority
    );

    return (
        <span className={tooltipClasses}>
            <div className="tooltip-row">
                <h3>{context.t('Alert ID: {id}', {id: itemInfo.id})}</h3>

                { itemInfo.coupleId != null &&
                    <h3>{context.t('Couple ID: {id}', {id: itemInfo.coupleId})}</h3>
                }

                <p>{UIDateFormater(itemInfo.creationDate, DateType.DATE, context.t.timeZone)} - {UIDateFormater(itemInfo.closureDate, DateType.DATE, context.t.timeZone)}</p>

                <table>
                    <tbody>
                        {itemInfo.material != null && itemInfo.material != 'undefined' &&
                            <tr>
                                <th>{context.t('Material')}</th>
                                <td>{itemInfo.material}</td>
                            </tr>

                        }
                        {itemInfo.diameterInch != null && itemInfo.diameterInch > 0 &&
                            <tr>
                                <th>{context.t('Diameter')}</th>
                                <td>{itemInfo.diameterInch}</td>
                            </tr>
                        }
                        {itemInfo.placementYear != null && itemInfo.placementYear > 0 &&
                            <tr>
                                <th>{context.t('Pipe Install')}</th>
                                <td>{itemInfo.placementYear}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </span>
    );
};
