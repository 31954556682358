import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import {
  fetchMiddleware
} from './MiddlewareActions';
// import fetch from 'isomorphic-fetch';

//--------------------------------
// Receive & Request
//--------------------------------
function requestSensorHistory(project, id) {
  return {
    type: types.REQUEST_SENSOR_HISTORY,
    project,
    id
  };
}

function receiveSensorHistory(project, id, history) {
  //log.data = formatObjectsDate(log.data);
  return {
    type: types.RECEIVE_SENSOR_HISTORY,
    project,
    id,
    history
  };
}

//--------------------------------
// Fetch
//--------------------------------
export function fetchSensorHistoryIfNeeded(project, id, force = false) {
  return (dispatch, getState) => {
    if (force || shouldFetchSensorHistory(getState(), project, id)) {
      return dispatch(fetchSensorHistory(project, id));
    }
  };
}

function shouldFetchSensorHistory(state, project, id) {
  //const history = state.leaksList.leaksByProject[project].sensors.history;
  //if (!history) {
  //    return true;
  //}
  //else if (history.isFetching) {
  //    return false;
  //}
  //else if (history.data.length) {
  //    return false;
  //}
  return true;
}

function fetchSensorHistory(project, id) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/sensor/" + id + "/history";

  return (dispatch, getState) => {
    //@TODO: Check errors.
    dispatch(requestSensorHistory(project, id));
    return fetchMiddleware(path, {}, getState).then((json) => {
      dispatch(receiveSensorHistory(project, id, json));
    });

  };
}
