import React from 'react';
import { zIndexs, featuresTypes } from './Options';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');

const itemStyle = new Style({
    image: new Icon({
        src: require('../../../../images/map/sensors/sensor-installed-accmtr-above-gps.png'), anchor: [0.5, 1]
    })
});

const source = new VectorSource({
    features: [],
});

// Sensors layer
export const layer = new VectorLayer({
    name: 'layerSensors',
    style: itemStyle,
    source: source,
    zIndex: zIndexs.sensors,
});

// Sensors source
export const setSource = (nextIconFeatures, props) => {
    let iconFeatures = [];
    Object.keys(nextIconFeatures).forEach((key) => {
        const sensor = nextIconFeatures[key];
        const iconFeature = new Feature({
            geometry: new Point(
                proj.transform(
                    sensor.position,
                    'EPSG:4326',
                    'EPSG:3857'
                )
            ),
            type: featuresTypes.SENSOR,
            id: sensor.id,
            status: sensor.status,
            isUnderGround: sensor.isUnderGround,
            sensorType: sensor.sensorType,
            syncType: sensor.syncType,
            assetID: sensor.assetID,
            address: sensor.address,
            regularNoise: sensor.regularNoise,
            generationType: sensor.generationType,
            isPressure: sensor.isPressure,
            isActualTransient: sensor.actualTransient,
            resetResently: sensor.resetResently,
            bitResetCount: sensor.bitResetCount,
            bitResetDate: sensor.bitResetDate,
            autoResetCount: sensor.autoResetCount,
            autoResetDate: sensor.autoResetDate,
            fragmented: sensor.fragmented,
            sopState: sensor.sopState,
            lastUninstallReason: sensor.lastUninstallReason,
        });
        iconFeature.setStyle(getStyle(iconFeature, false, props));
        iconFeatures.push(iconFeature);
    });

    layer.getSource().addFeatures(iconFeatures);
};

// get sensors style
export const getStyle = (feature, selected, props) => {
    let sensorStyle = false;
    let iconStatus = false;

    const attr = feature.getProperties();
    selected = selected ? '-selected' : '';

    const statusMap = {
        'Non Active': 'inactive',
        'Active': 'installed',
        'Ready': 'ready',
        'Not Installed': 'uninstalled'
    };

    if (statusMap[attr.status] == 'uninstalled') {
      iconStatus = 'uninstalled_' + attr.isUnderGround;
    } else if (statusMap[attr.status] && attr.sensorType && attr.isUnderGround && attr.syncType) {
      let statusKey;
      if (attr.status != 'Active' ||
        attr.regularNoise == null ||
        attr.regularNoise ||
        attr.generationType === 1 ||
        !props.user.advancedMapIndications) {
        statusKey = statusMap[attr.status];
      } else {
        statusKey = 'malfunction';
      }
      iconStatus = statusKey + '-' + attr.sensorType + '-' + attr.isUnderGround + '-' + attr.syncType;
    }

    if (iconStatus) {
        sensorStyle = [
          new Style({
            image: new Icon({
                src: require('../../../../images/map/sensors/sensor-' + iconStatus + selected + '.png'),
                anchor: [0.5, 1]
            })
          })
        ];

        if (attr.isPressure) {
          // set main Sensor icon:
          const isActualTransientSufix = (attr.isActualTransient == true) ? '-transient' : '';
          sensorStyle.push(new Style({
            image: new Icon({
                src: require(`../../../../images/map/sensors/indications/indication${isActualTransientSufix}-pressure.png`),
                scale: 0.3,
                anchor: [0, 1],
            })
          }));
        }

        // add G5 star icon
        if (attr.generationType === 1) {
          sensorStyle.push(new Style({
            image: new Icon({
                src: require('../../../../images/map/sensors/indications/star.png'),
                scale: 0.075,
                anchor: [0.5, 3.3],
            })
          }));
        }

        // add 'X' for install on SOP with Stolen state:
        if (attr.status == 'Not Installed') {
          switch (attr.lastUninstallReason) {
            case 3: // 'Stolen'
              sensorStyle.push(new Style({
                image: new Icon({
                  src: require('../../../../images/map/sensors/errors/error-stolen.png'),
                  scale: 0.4,
                  anchor: [0, 1],
                  opacity: 0.75,
                })
              }));
              break;
            case 10: // 'Vandalized'
              sensorStyle.push(new Style({
                image: new Icon({
                  src: require('../../../../images/map/sensors/errors/error-damaged.png'),
                  scale: 0.4,
                  anchor: [0, 1],
                  opacity: 0.75,
                })
              }));
              break;
          }
        }

        // if (props.user.advancedMapIndications) {
        //   // Indication icons: iregular noise:
        //   // NOISE:
        //   if (!attr.regularNoise && attr.status == 'Active') {
        //     sensorStyle.push(new Style({
        //       image: new Icon({
        //           src: require('../../../../images/map/sensors/indications/indication-noise.png'),
        //           scale: 0.3,
        //           anchor: [1, 1],
        //       })
        //     }));
        //   }
        // }

        if (props.user.technicianAdvanced) {
          // Indication icons: reset resently / fragmented file:
          // RESETS:
          if (attr.resetResently) {
            sensorStyle.push(new Style({
              image: new Icon({
                  src: require('../../../../images/map/sensors/indications/indication-resets.png'),
                  scale: 0.3,
                  anchor: [0, 2.5],
              })
            }));
          }

          // FRAGMENTED:
          if (attr.fragmented) {
            sensorStyle.push(new Style({
              image: new Icon({
                  src: require('../../../../images/map/sensors/indications/indication-fragmented.png'),
                  scale: 0.3,
                  anchor: [1, 2.5],
              })
            }));
          }
        }
    }

    return sensorStyle;
};

export const getLayers = () => {
  return [layer];
}

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getInfoTooltip = (feature) => {
    const attr = feature.getProperties();
    let infoTooltip = {
        type: attr.type,
        id: attr.id,
        assetID: attr.assetID || false,
        intensity: false,
        priority: false,
        resetResently: attr.resetResently,
        bitResetCount: attr.bitResetCount,
        bitResetDate: attr.bitResetDate,
        autoResetCount: attr.autoResetCount,
        autoResetDate: attr.autoResetDate,
        fragmented: attr.fragmented
    };
    return (infoTooltip);
  };

export const tooltipRender = (itemInfo, context, props) => {
  const { id, assetID, resetResently, bitResetDate, autoResetDate } = itemInfo;
  return (
    <span className="tooltip">
        <div className="tooltip-row">{context.t('Sensor ID: {id}', {id: id})}</div>
        { assetID && assetID !== false &&
            <div className="tooltip-row">{context.t('AssetID: {asset}', {asset: assetID})}</div>}
        { props.user.advancedMapIndications && resetResently && bitResetDate &&
          <div>
            <div className="tooltip-row">{context.t('Manual Reset At {lastManualResetDate}', {lastManualResetDate: UIDateFormater(bitResetDate, DateType.DATE_AND_TIME, props.timeZone)})}</div>
            <div className="tooltip-row">{context.t('Manual Reset Count {manualResetCount}', {manualResetCount: itemInfo.bitResetCount})}</div>
          </div>
        }

        { props.user.advancedMapIndications && resetResently && autoResetDate &&
          <div>
            <div className="tooltip-row">{context.t('Auto Reset At {lastAutoResetDate}', {lastAutoResetDate: UIDateFormater(autoResetDate, DateType.DATE_AND_TIME, props.timeZone)})}</div>
            <div className="tooltip-row">{context.t('Auto Reset Count {autoResetCount}', {autoResetCount: itemInfo.autoResetCount})}</div>
          </div>
        }
    </span>
    );
  };
