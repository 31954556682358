import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import { Select, Input } from '../../components';
import { REPORTS_TYPE_OPTIONS } from "../../constants/Misc";
import { REPORTS_TYPE } from "../../constants/Misc";
require('./Reports.scss');

class ReportsHeader extends React.Component {
    render() {
        const { handleSubmit, showSettings, pristine, reset, submitting, projects, formValues } = this.props;
        const headerClasses = classNames(
            'reports-header',
            { 'settings-show': showSettings }
        );
        const projectsList = projects.map(project => {
            return {
                value: project.ID,
                label: project.ShortName ,
            }
        });
        return (
            <div className={headerClasses}>
                <div className="actions">
                    <i className="icon-settings" onClick={this.props.toggleSettingsDisplay} />
                </div>

                <h2>{this.context.t('Reports')}</h2>

                <form className="form-horizontal" onSubmit={handleSubmit}>
                    <Field name="reportType"
                        component={props =>
                            <Select {...props}
                                placeholderTitle={this.context.t('Select Report...')}
                                clearable={false}
                                backspaceRemoves={false}
                                options={REPORTS_TYPE_OPTIONS.map((item) => { return { value: item.value, label: this.context.t(item.label) } })}
                            />
                        }
                    />
                    {formValues && formValues.reportType && formValues.reportType !== REPORTS_TYPE.SENSORS_STATUS &&
                        <Field name="projects"
                            component={props =>
                                <Select {...props}
                                    placeholderTitle={this.context.t('Select Projects...')}
                                    clearable={true}
                                    multi={true}
                                    closeMenuOnSelect={false}
                                    options={projectsList}
                                />
                            }
                        />
                    }
                    {false && formValues && formValues.reportType && formValues.reportType !== REPORTS_TYPE.SENSORS_STATUS &&
                        <button className="btn btn-success btn-block"
                        >{this.context.t('date')}</button>
                    }
                    <button
                        className="btn btn-success"
                        type="submit"
                    >{this.context.t('GO')}</button>
                </form>
            </div>
        );
    }

}

ReportsHeader.contextTypes = {
    t: PropTypes.func.isRequired
}

// Decorate the form component
ReportsHeader = reduxForm({
    form: 'reportsHeader', // a unique name for this form
    enableReinitialize: true,
})(ReportsHeader);

export default ReportsHeader;
