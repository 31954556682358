/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

import { SYSTEM_RESOURCE, ENVIRONMENTS, ENV } from '../../constants/EndPoints';

import { browserHistory } from 'react-router';
import { connect } from 'react-redux';

require('./Menu.scss');

const classNames = require('classnames');

const mobileModuleUrl = {
  aqs: 'http://iquarius.com/login.html',
  trimble: 'https://leaklocator.trimbleunity.com',
};

class Menu extends React.Component {

  constructor(props) {
    super(props);
    this.menuItemRender = this.menuItemRender.bind(this);
  }

  menuItemRender(item, key) {
    if (item.external) {
      return (
        <li key={key}>
          <a className={item.className} href={item.href} target="_blank">
            <i/>
            <div>{item.title}</div>
          </a>
        </li>
      );
    }
    else {
      const itemCls = classNames(
        item.className,
        // react-router activeClassName didn't work well when
        // navigating between alets/ sensors
        {'active': this.props.path == item.href}
      );
      return (
        <li key={key}>
          <a
            className={itemCls}
            onClick={() => {
              this.onChange({
                pathname: item.href,
                query: item.query
              });
            }}
          >
            <i></i>
            <div>{item.title}</div>
          </a>
        </li>
      );
    }
  }

  onChange(data) {
    this.props.onPathChange(data.pathname);
    browserHistory.push(data);
  }

  render() {

    const { selectedProject, editMode, user } = this.props;

    const menuItems =
    [
      {
        title: this.context.t('Alerts'),
        className: 'leaks',
        href: 'alerts',
        query: {project: selectedProject}
      },
      {
        title: this.context.t('Sensors'),
        className: 'sensors',
        href: 'sensors',
        query: {project: selectedProject}
      }
    ];

    if (editMode) {
      menuItems.push({ title: this.context.t('Couples'), className: 'couples', href: 'couples', query: { project: selectedProject } });
    }

    if (user.isAQS || user.mobile) {
      menuItems.push({ title: this.context.t('Mobile'), className: 'leak-locator', href: 'mobile' });
    }
    if (ENV !== ENVIRONMENTS.SINGAPORE) {
      menuItems.push({ title: this.context.t('iQuarius'), className: 'leak-locator', href: mobileModuleUrl[SYSTEM_RESOURCE], external: true });
    }

    if (editMode) {
      menuItems.push({ title: this.context.t('Management'), className: 'manage', href: 'manage', query: {project: selectedProject}});
    }

    if (editMode) {
      menuItems.push({ title: this.context.t('Install'), className: 'install', href: 'install', query: { project: selectedProject } });
    }

    if (user.g5Operator) {
      menuItems.push({ title: this.context.t('G5 Devices'), className: 'g5Devices', href: 'g5Devices', query: { project: selectedProject } });
    }

    if (ENV !== ENVIRONMENTS.SINGAPORE && user.operatePcaModule) {
      menuItems.push({ title: this.context.t('PCA'), className: 'inspect', href: 'inspect', query: { project: selectedProject } });
    }

    // menuItems.push({ title: this.context.t('Settings'), className: 'settings', href: 'settings' });

    // menuItems.push({title: this.context.t('Reports'), className: 'reports', href: 'reports'});

    // The map method will loop over the array of menu entries,
    // and will return a new array with <li> elements
    return (
      <nav className="menu">
        <ul>
        {
          menuItems.map(this.menuItemRender)
        }
        </ul>
      </nav>
    );
  }
}

Menu.contextTypes = {
  t: PropTypes.func.isRequired
}

function mapStateToProps(state) {
  return {
    editMode: state.leaksList.user.editMode,
    path: state.routing.locationBeforeTransitions.pathname.replace("/", "")
  };
}

export default connect(mapStateToProps)(Menu);
