import React from 'react';
import PropTypes from 'prop-types';

import { ENVIRONMENTS, ENV } from '../../constants/EndPoints';
import { Table, Column } from 'fixed-data-table-2';
import { SortableHeaderCell, TextCell, TableMixin } from '../../components';
import CTableActions from '../../containers/CTableActions/CTableActions';
import CSensorsSettings from '../../containers/CSensorsSettings/CSensorsSettings';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

const ARKADI_MODE = (ENV !== ENVIRONMENTS.PROD && ENV !== ENVIRONMENTS.TRIMBLE);

const SensorsTable = (props) => {

  const { items, indexMap, selectedFeature, pHeight, pWidth, sortProp, localSortProp, rowClickEvent, rowClassNameGetter, goToRow, user, t } = props;
  const totalItemsStyle = {
    float: 'right',
    padding: '2px 8px',
  };

  //document.title = 'AQS • Sensors';

  return (
    <div>
      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
        scrollToRow={goToRow}
        {...props}
      >
        <Column columnKey="warningEnum" width={30}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field="warningEnum" path={props.path} />} />
        <Column
          columnKey="id"
          width={40}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="id" />} />
        <Column
          columnKey="SensorStatus"
          width={65}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="SensorStatus" />} />
        <Column
          columnKey="DaysInactive"
          width={55} flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="DaysInactive" />} />
        {ARKADI_MODE && user.isAQS &&
          <Column
            columnKey="Nc1"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Nc1" user={user} />} />
        }
        <Column
          columnKey="Nc2"
          width={45} flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="Nc2" user={user} />} />
        {ARKADI_MODE && user.isAQS &&
          <Column
            columnKey="Nc3"
            width={45} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Nc3" user={user} />} />
        }
        <Column
          columnKey="DeviceID"
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="DeviceID" />} />
        <Column
          columnKey="DeviceInstallationDate"
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="DeviceInstallationDate" dateType={DateType.DATE} />} />
        {!ARKADI_MODE &&
          <Column
            columnKey="StreetAddress"
            width={50}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="StreetAddress" />} />
        }
      </Table>

      {items && items.length > 0 &&
        <CTableActions
          type="sensors"
          notify={props.notify}
        />
      }
      {items && items.length > 0 &&
        <span style={totalItemsStyle}>{t('Selected: {selectedIndex}. Total: {total}', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
      }

    </div>
  );
}


const sensorsTable = TableMixin(SensorsTable, CSensorsSettings);
export default sensorsTable;
