import * as types from '../constants/ActionTypes';

export function assessments(state = {}, action) {
    switch (action.type) {
        case types.REQUEST_COUPLES_ALERTS:
            return Object.assign({}, state, {
                assessments: Object.assign({}, state.assessments, {
                    [action.couple]: {
                        isFetching: true,
                        assessments: [],
                        indexMap: {}
                    }
                })
            });
        case types.RECEIVE_COUPLES_ALERTS:
            return Object.assign({}, state, {
                assessments: Object.assign({}, state.assessments, {
                    [action.couple]: {
                        isFetching: false,
                        assessments: action.assessments,
                        icons: action.icons,
                        indexMap: action.indexMap
                    }
                })
            });
        case types.REQUEST_COUPLE_SAMPLES_FOR_ALERT:
            return Object.assign({}, state, {
                coupleSamples: Object.assign({}, state.coupleSamples, {
                    [action.couple]: {
                        data: [],
                        isFetching: true,
                        pageIndex: 0,
                    }
                })
            });
        case types.RECEIVE_COUPLE_SAMPLES_FOR_ALERT:
            return Object.assign({}, state, {
                coupleSamples: Object.assign({}, state.coupleSamples, {
                    [action.couple]: {
                        data: action.data,
                        isFetching: false,
                        pageIndex: action.data.pageNumber,
                    }
                })
            });
        default:
            return state;
    }
}
