import * as types from '../constants/ActionTypes';

export function reports(state = { isFetching: false, report: null }, action) {
    if (!action) {
        return state;
    } else {
        switch (action.type) {
            case types.REQUEST_REPORT:
                return Object.assign({}, state, {
                    isFetching: true,
                });
            case types.RECEIVE_REPORT:
                return Object.assign({}, state, {
                    isFetching: false,
                    type: action.reportType,
                    report: action.report,
                });
            default:
                return state;
        }
    }
}