import * as types from '../constants/ActionTypes';
const initialState = {
  editMode: false,
  logo: null,
  name: undefined,
};

export function user(state = initialState, action) {
    switch (action.type) {
        case types.USER_LOGGED_OUT:
            return initialState;
        case types.SET_EDIT_MODE:
            return Object.assign({}, state, action);
        case types.RECEIVE_USER_DATA:
            return Object.assign({}, state, {
                name: action.userName,
            });
        case types.RECEIVE_CUSTOM_DETAILS:
            return Object.assign({}, state, action);

        default:
          return state;
    }
}
