import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
import * as actionsDevice from './DevicesActions';
import * as actionsHistory from './HistoryActions';
import * as actionsCouples from './CouplesActions';
import * as actionsSOPs from './SOPsActions';
import * as actionsGlobals from './GlobalsActions';
import * as actionsPressure from "./SensorsPressureActions";

import { daysBetween } from "./../constants/DatesHelpers";

import { SENSOR_LOG_COUNT_FOR_PAGE } from '../constants/Misc';

export function selectSensor(sensor) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    const path = state.routing.locationBeforeTransitions.pathname.replace("/", "") || 'alerts';

    let selectedSensorId;
    if (sensor == null) {
      selectedSensorId = '';
    } else {
      const sensorTypeEnum = sensor.SensorTypeValue;
      selectedSensorId = sensor.id;
      dispatch(actionsHistory.fetchSensorHistoryIfNeeded(selectedProject, selectedSensorId));
      dispatch(actionsCouples.fetchLinkedCouplesIdNeeded(selectedProject, sensor.SOPointID));
      dispatch(actionsDevice.fetchDeviceLogIfNeeded(selectedProject, sensor.DeviceID));
      dispatch(fetchSensorDetails(selectedProject, selectedSensorId));
      dispatch(fetchSensorNC(selectedProject, selectedSensorId, sensorTypeEnum, 0));
      dispatch(fetchSensorNoiseAlerts(selectedProject, selectedSensorId));
      dispatch(fetchSensorLogs(selectedProject, sensor.DeviceID, 0));
      dispatch(selectSensorLog({}));//Clear the selected log
      dispatch(selectSensorBit({}));//Clear the selected log
      dispatch(selectG5Message(selectedProject, {}));//Clear the selected log
      dispatch(selectSensorNoiseMsg(selectedProject, {}));//Clear the selected log
      dispatch(fetchSensorBits(selectedProject, sensor.DeviceID, 0));
      dispatch(fetchSensorImages(selectedProject, selectedSensorId));
      dispatch(receiveSensorSampleWaves(selectedProject, {}));

      if (path == 'install') {
        dispatch(actionsSOPs.fetchSensorDetailsBySOP(sensor.SOPointID));
      }

      if (sensor.IsPressure) {
        dispatch(actionsPressure.fetchPressure(sensor, sensor.DeviceID, 0));
        dispatch(actionsPressure.fetchPressureTransient(sensor, sensor.DeviceID, 0));
      }
      if (sensor.DeviceGenerationValue === 1) {
        dispatch(fetchG5Status(selectedProject, sensor.DeviceID));
        dispatch(fetchG5BitMessages(selectedProject, sensor.DeviceID));
        dispatch(fetchG5NoiseMessages(selectedProject, sensor));
      }
    }

    dispatch(actionsGlobals.selectFeature(selectedProject, selectedSensorId, 'sensor'));
  };
}

function fetchG5Status(project, deviceId) {
  return function (dispatch, getState) {
    const url = `${endPoints.END_POINT}/g5sensors/statusmessages/${deviceId}`;
    dispatch({ type: types.REQUEST_SENSOR_G5_STATUS, project });
    return fetchMiddleware(url, {}, getState).then(function(json) {
      if (! json.status) {
        console.log(json);
      } else {
        dispatch({ type: types.RECEIVE_SENSOR_G5_STATUS, project, payload: json.data });
      }
    });
  };
}

function fetchG5BitMessages(project, deviceId) {
  return function (dispatch, getState) {
    const url = `${endPoints.END_POINT}/g5sensors/bit/${deviceId}`;
    dispatch({ type: types.REQUEST_SENSOR_G5_BIT, project });
    return fetchMiddleware(url, {}, getState).then(function(json) {
      if (! json.status) {
        console.log(json);
        return null;
      } else {
        const data = json.data;
        const channelsCount = 5;
        const intensityBandsCount = 10;
        data.forEach((item) => {
          item.channels = [];
          item.bandsData = {
            x: [],
            y: []
          };

          for (let i = 0; i < channelsCount; i++) {
            const keyFreq = `FMChannel${i + 1}_10k`;
            const keyRSSI = `FMChannel${i + 1}_RSSI_dBi`;
            const keySNR = `FMChannel${i + 1}_SNR_dBi`;
            if (item[keyFreq] != null && item[keyRSSI] != null && item[keySNR] != null) {
              item.channels.push({
                freq: item[keyFreq],
                RSSI: item[keyRSSI],
                SNR: item[keySNR],
              });
              delete item[keyFreq];
              delete item[keyRSSI];
              delete item[keySNR];
            }
          }

          for (let i = 1; i <= intensityBandsCount; i++) {
            const key = `NoiseIntensityMinBandPower${i}`;
            if (item[key]) {
              item.bandsData.x.push(i);
              item.bandsData.y.push(item[key]);

              delete item[key];
            }
          }
        });
        return (data);
      }
    })
    .then((data) => {
      dispatch({ type: types.RECEIVE_SENSOR_G5_BIT, project, payload: data });
    });
  };
}

export function selectG5Message(project, messageObj) {
  return {
    type: types.SELECT_SENSOR_G5_MESSAGE,
    project,
    payload: messageObj
  };
}

export function fetchG5NoiseMessages(project, sensor) {
  return (dispatch, getState) => {
    const url = `${endPoints.END_POINT}/g5sensors/noise/${sensor.DeviceID}/${sensor.id}`;
    dispatch({ type: types.REQUEST_SENSOR_G5_NOISE, project });
    return fetchMiddleware(url, {}, getState).then((json) => {
      if (json.status == false) {
        console.log(json);
        return null;
      } else {
        const data = json.data;
        const intensityBandsCount = 10;
        data.forEach((item) => {
          item.bandsData = {
            x: [],
            y: []
          };

          for (let i = 1; i <= intensityBandsCount; i++) {
            const key = `NoiseIntensityMinBandPower${i}`;
            if (item[key]) {
              item.bandsData.x.push(i);
              item.bandsData.y.push(item[key]);

              delete item[key];
            }
          }
        });
        return (data);
      }
    })
    .then((data) => {
      // dispatch({ type: types.RECEIVE_SENSOR_G5_BIT, project, payload: data });
      dispatch({ type: types.RECEIVE_SENSOR_G5_NOISE, project, payload: data});
      if (data && data.length > 0) {
        dispatch(fetchG5NoiseGraph(project, data[0]));
      }
    });

    //     dispatch({ type: types.RECEIVE_SENSOR_G5_NOISE, project, payload: json.data});
    //   }
    // })
  };
}

export const selectSensorNoiseMsg = (project, selectedSensorNoiseMsg) => (dispatch, getState) => {
  dispatch({
    type: types.SELECT_SENSOR_G5_NOISE_MESSAGE,
    project,
    payload: selectedSensorNoiseMsg,
  });

  dispatch(fetchG5NoiseGraph(project, selectedSensorNoiseMsg));
}

const fetchG5NoiseGraph = (project, selectedSensorNoiseMsg) => (dispatch, getState) => {
  // clean old graph data:
  dispatch(storeG5NoiseGraph(project, []));

  // load noiseMsgs if G5 sensor has selected:
  if (selectedSensorNoiseMsg.DeviceID && selectedSensorNoiseMsg.DeviceID.startsWith('000')) {
    const state = getState();
    const project = state.leaksList.selectedProject;
    const url = `${endPoints.END_POINT}/g5sensors/noiseGraph/${selectedSensorNoiseMsg.DeviceID}/${selectedSensorNoiseMsg.TimeStamp}`;
    fetchMiddleware(url, {}, getState).then((json) => {
      console.log({ json });
      if (json.data) {
        dispatch(storeG5NoiseGraph(project, json.data));
      }
    })
  }
}

const storeG5NoiseGraph = (project, data) => ({
  type: types.SET_G5_NOISE_GRAPH,
  payload: data,
  project
});

//--------------------------------
// Receive & Request
//--------------------------------
function receiveSensors(project, sensors, sensorsIcons, indexMap) {
    return {
        type: types.RECEIVE_SENSORS,
        project,
        sensors,
        sensorsIcons,
        indexMap
    };
}

function requestSensors(project, filters, sort) {
    return {
        type: types.REQUEST_SENSORS,
        project,
        filters,
        sort
    };
}

function receiveNonAssociatedSensors(project, data) {
    return {
        type: types.RECEIVE_SENSORS_NON_ASSOCIATED,
        project,
        data
    };
}

//--------------------------------
// Filters & Sort
//--------------------------------

export function setSensorsFilters(filters) {
  return {
    type: types.SET_SENSORS_FILTERS,
    filters
  };
}

export function setSensorsDefFilters(filters) {
    return {
        type: types.SET_SENSORS_DEF_FILTERS,
        filters
    };
}

export function sortSensors(field, dir) {
  return (dispatch, getState) => {
    const state = getState();
    const defFilters = state.leaksList.filters.sensorsDefFilters;
    const selectedProject = state.leaksList.selectedProject;

    dispatch(setSortDetails(field, dir));
    dispatch(setSensorsDefFilters(Object.assign({}, defFilters, {
          sortBy: field,
          sortByDir: dir,
        })
      ));
    dispatch(fetchSensorsIfNeeded(selectedProject));
  };
}

const setSortDetails = (field, dir) => {
  return {
    type: types.SORT_SENSORS,
    field,
    dir
  };
};

export const sortSensorsLocaly = (project, field, dir) => {
    return (dispatch, getState) => {
        const state = getState();
        const sensors = Object.assign({}, state.leaksList.leaksByProject[project].sensors.sensors);
        const orderedSensorsKeys = Object.keys(sensors).sort((a, b) => {
            //let nRc = sensors[a][field] > sensors[b][field] ? 1 : (sensors[b][field] > sensors[a][field] ? -1 : 0);
            let nRc = 0;
            if (dir === 'asc') {
                nRc = sensors[a][field] - sensors[b][field];
            } else {
                nRc = sensors[b][field] - sensors[a][field];
            }
            return (nRc);
        });
        let orderedSensors = [];
        let indexMap = {};
        orderedSensorsKeys.forEach((indexId, index) => {
            indexMap[sensors[indexId].id] = index;
            orderedSensors.push(sensors[indexId]);
        });
        dispatch(sortLocaly(project, orderedSensors, indexMap, field, dir));
    };
};

const sortLocaly = (project, sensors, indexMap, field, dir) => {
    return {
        type: types.SORT_SENSORS_LOCALY,
        project,
        sensors,
        indexMap,
        field,
        dir
    };
};

//--------------------------------
// Fetch
//--------------------------------

export function fetchSensorsIfNeeded(project, force = false) {
  return (dispatch, getState) => {
    if (force || shouldFetchSensors(getState(), project)) {
      const state = getState();
      const filters = state.leaksList.filters.sensorsFilters;
      const sort = state.leaksList.filters.sensorsSort;
      return dispatch(fetchSensors(project, filters, sort));
    }
  };
}

function shouldFetchSensors(state, project) {
  let sensors = false;
  if (state.leaksList.leaksByProject[project] != null) {
    sensors = state.leaksList.leaksByProject[project].sensors;
  }
  if (!sensors) {
    return true;
  }
  return (!sensors.isFetching);
}

function fetchSensors(project, filters, sort) {
  return (dispatch, getState) => {
    const state = getState();
    const fields = ['id', 'SensorStatus', 'DaysInactive', 'Nc1', 'Nc2', 'Nc3', 'DeviceID', 'DeviceInstallationDate', 'StreetAddress'];
    if (sort != null) {
      if (fields.indexOf(sort.field) == -1) {
        sort.field = 'id';
        sort.dir = 'asc';
        dispatch(setSortDetails(sort.field, sort.dir));
      }
    }
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.SENSORS_ENDPOINT + "?filters=" + JSON.stringify(filters) + "&sort=" + JSON.stringify(sort);
    dispatch(requestSensors(project, filters, sort));
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        if (json.status) {
          const projectsBundle = state.leaksList.projectsList;
          const selectedProject = projectsBundle.items[projectsBundle.projectIndexMap[project]];

          if (selectedProject) {
            calculateAdditionalSensorInfo(json.data, selectedProject);
          }
          return [json.data, getIconFeatures('sensors', json.data), setIndexes(json.data, 'id')];
        } else {
          return [[], {}, {}];
        }
      })
      .then(args => {
        dispatch(receiveSensors(project, ...args));
        //dispatch(fetchNonAssociatedSensors(project, filters));
        const userHaveAdvancedMapIndicationsPermission = state.leaksList.user.advancedMapIndications;
        if (userHaveAdvancedMapIndicationsPermission) {
          dispatch(fetchSensorsWithActualPressureTransient(project, filters));
        }
      });
  };
}

export function fetchG5BitInfo() {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    if (!projectId || projectId === '0') {
      return null;
    } else {
      const url = `${endPoints.END_POINT}/g5sensors/bitInfo/${projectId}`;
      dispatch({ type: types.DEVICE_CONFIGURATION.REQUEST_DEVICE_BIT_INFO });

      return fetchMiddleware(url, {}, getState)
        .then((json) => {
          if (!json.status) {
            console.log(json);
          } else {
            const { data } = json;
            const { bitInfo, thresholds } = data;
            bitInfo.forEach((item) => {
              const radioGrades = [];
              item.bestRssi = 0;
              for (let i = 1; i <= 5; i++) {
                const radioData = {
                  channel: item[`FMChannel${i}_10k`],
                  rssi: item[`FMChannel${i}_RSSI_dBi`],
                  snr: item[`FMChannel${i}_SNR_dBi`],
                };

                radioGrades.push(radioData);

                if (item.bestRssi === undefined || item.bestRssi < radioData.rssi) {
                  item.bestChannel = radioData.channel;
                  item.bestRssi = radioData.rssi;
                  item.bestSnr = radioData.snr;
                }

                item.radioGrades = radioGrades;

                delete item[`FMChannel${i}_10k`];
                delete item[`FMChannel${i}_RSSI_dBi`];
                delete item[`FMChannel${i}_SNR_dBi`];

                if (item.BatteryLevel) item.BatteryLevelOK = (item.BatteryLevel > thresholds.pre_battery_threshold);
                if (item.BatteryLevelPost) item.BatteryLevelPostOK = (item.BatteryLevelPost > thresholds.post_battery_threhsold);
                if (item.RSRQ_db) item.RSRQ_dbOK = (item.RSRQ_db > thresholds.cellular_RSRQ_threshold);
                if (item.RSRP_dbm) item.RSRP_dbmOK = (item.RSRP_dbm > thresholds.cellular_RSRP_threhsold);
                if (item.bestRssi) item.bestRssiOK = (item.bestRssi > thresholds.fm_radio_RSSI_threshold);
                if (item.bestSnr) item.bestSnrOK = (item.bestSnr > thresholds.fm_radio_SNR_threshold);
                if (item.NoiseIntensityMinRMS) item.NoiseIntensityMinRmsNormalize = item.NoiseIntensityMinRMS * Math.pow(4, (4 - item.NoiseIntensityGain));
                // if (item.BatteryLevel) item.BatteryLevelOK = (item.BatteryLevel > thresholds.normalized_noise_threshold);

              }
            });

            dispatch({
              type: types.DEVICE_CONFIGURATION.RECEIVE_DEVICE_BIT_INFO,
              payload: bitInfo
            });
          }
        });
    }
  };
}

const fetchSensorsWithActualPressureTransient = (project, filters) => {
  return (dispatch, getState) => {
    const path = `${endPoints.SENSOR_PRS_ENDPOINT}/prsTransient/${project}?filters=${JSON.stringify(filters)}`;
    dispatch(rquestSensorsWithActualPressureTransient(project));
    return fetchMiddleware(path, {}, getState)
    .then((json) => {
      if (json.status) {
        const state = getState();
        const sensorsProjectData = state.leaksList.leaksByProject[project].sensors;
        const { sensors: sensorsList, indexMap, sensorsIcons } = sensorsProjectData;
        const { data } = json;

        data.forEach((sensorIdWithTransient) => {
          sensorsList[indexMap[sensorIdWithTransient.ID]].actualTransient = true;
          sensorsIcons[sensorIdWithTransient.ID].actualTransient = true;
        });
        dispatch(receiveSensorsWithActualPressureTransient(project, sensorsList, sensorsIcons));
      }
    });
  };
};

function fetchNonAssociatedSensors(project, filters) {
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/sensorsNA?filters=" + JSON.stringify(filters);
    return (dispatch, getState) => {
        return fetchMiddleware(path, {}, getState)
            .then(json => {
                if (!json.status) {

                } else {
                    dispatch(receiveNonAssociatedSensors(project, json.data));
                }
            });
    };
}

function calculateAdditionalSensorInfo(data, project) {
  if (data) {
    const nNowMillis = new Date().getTime();
    const nMillisToDaysFactor = 1000 * 60 * 60 * 24;
    let gpsCounter = 0;
    let commCounter = 0;
    data.forEach((sensor) => {
      sensor.warningEnum = 0;

      // ## set values for errors icons in map:
      const noiseThreshold = project.NoiseOkThreshold;
      // if all Nc values are under 'noiseThreshold' there is a problem. else - it's regular:
      sensor.regularNoiseValue = Math.max(sensor.Nc1, sensor.Nc2, sensor.Nc3) >= noiseThreshold;

      // set value for warnning icon in table:
      if (sensor.Noise != null) {
        sensor.warningEnum = 4;
      } else if (sensor.Associated === false) {
        sensor.warningEnum = 3;
      }
        // if (sensor.LastGpsSampleTime == undefined ||
        //   ((nNowMillis - sensor.LastGpsSampleTime) / nMillisToDaysFactor) >= sensor.SensorActivityRatioDaysBack) {
        //   sensor.isGpsWarning = true;
        //   gpsCounter++;
        // } else {
        //   sensor.isGpsWarning = false;
        // }

      if (
        sensor.LastCommunicationTime == undefined ||
        sensor.LastCommunicationTime < sensor.DeviceInstallationDate ||
        sensor.LastActiveTime == undefined ||
        sensor.LastActiveTime < sensor.DeviceInstallationDate ||
        ((nNowMillis - sensor.LastActiveTime) / nMillisToDaysFactor) >= sensor.SensorActivityRatioDaysBack
      ) {
        sensor.isCommWarning = true;
        commCounter++;
      } else {
        sensor.isCommWarning = false;
      }

      // sensor.warningEnum = 0;

      // if (sensor.isGpsWarning) {
      //   sensor.warningEnum = 2;
      // }
      if (sensor.SensorStatus !== 'Not Installed' && sensor.isCommWarning) {
        sensor.warningEnum = 1;
      }

      // if (sensor.LastFTPFileSize != null && sensor.LastRecvAudioFileSize != null) {
      //   // check fragmented file:
      //   sensor.isFragmented = (sensor.LastFTPFileSize != sensor.LastRecvAudioFileSize);
      // }

      // check if was RESET resently:
      if (sensor.BITResetDate == null && sensor.AutoResetDate == null) {
        sensor.resetResently = false;
      } else {
        const now = new Date();
        const dateToCompare = new Date(Math.max(sensor.BITResetDate, sensor.AutoResetDate));
        const daysDiff = daysBetween(dateToCompare, now);

        sensor.resetResently = daysDiff <= 2;
      }

      // check fragmented files:
      if (sensor.LastRecvAudioFileSize != null && sensor.LastSentFTPFileSize != null) {
        sensor.Fragmented = (Math.abs(sensor.LastRecvAudioFileSize - sensor.LastSentFTPFileSize) > 100);
      }

      // if ((sensor.Nc1 != null && sensor.Nc1 < 10)
      //   && (sensor.Nc2 != null && sensor.Nc2 < 10)
      //   && (sensor.Nc3 != null && sensor.Nc3 < 10)) {
      //   sensor.NcNoiseWarnning = true;
      // }
    });
  }
}

//--------------------------------
// Update
//--------------------------------

export function updateSensor(data) {

  return (dispatch, getState) => {
    //@TODO: Check errors.
    const state = getState();
    const project = state.leaksList.selectedProject;
    const sensorId = state.leaksList.leaksByProject.selectedFeature;
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.SENSORS_ENDPOINT + "/" + sensorId;

    dispatch(updateSensorReq(project, sensorId, data));
    return fetchMiddleware(path, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: data })
    }, getState).then(json => {
      if (json.status == true) {
        dispatch(actionsGlobals.selectFeature(project, '', ''));
        dispatch(updateSensorRes(project, sensorId));
        dispatch(fetchSensorsIfNeeded(project));
        return dispatch(fetchSensors(project, state.leaksList.filters.sensorsFilters, getState().leaksList.filters.sensorsSort));
      }
      else {
        //@TODO: Error handle
      }
    }).then((args) => {
      const sensorsData = state.leaksList.leaksByProject[project];
      const selectedSensorObj = sensorsData.sensors[sensorsData.indexMap[sensorId]];
      return (dispatch(selectSensor(selectedSensorObj)));
    });
  };
}

const rquestSensorsWithActualPressureTransient = (project) => {
  return {
    type: types.REQUEST_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT,
    project,
  };
};

const receiveSensorsWithActualPressureTransient = (project, sensors, sensorsIcons) => {
  return {
    type: types.RECEIVE_SENSORS_WITH_ACTUAL_PRESSURE_TRANSIENT,
    project,
    sensors,
    sensorsIcons,
  };
};

function updateSensorReq(project, sensor, data) {
    return {
        type: types.UPDATE_SENSOR_REQ,
        project,
        sensor,
        data
    };

}

function updateSensorRes(project, sensor) {
    return {
        type: types.UPDATE_SENSOR_RES,
        project,
        sensor
    };
}

//--------------------------------
// Sensor Details
//--------------------------------
export function fetchSensorDetails(project, sensorID) {
    const path = endPoints.END_POINT + "/sensor/" + sensorID + "/details";

    return (dispatch, getState) => {
        dispatch(requestSensorDetails(project, sensorID));
        return fetchMiddleware(path, {}, getState).then(json => {
            return [json.data];
        })
            .then(args => {
                dispatch(receiveSensorDetails(project, ...args[0]));
            });
    };
}

function requestSensorDetails(project, sensorID) {
    return {
        type: types.REQUEST_SENSOR_DETAILS,
        project,
        sensorID,
    };
}

function receiveSensorDetails(project, details) {
    return {
        type: types.RECEIVE_SENSOR_DETAILS,
        project,
        details,
    };
}

//--------------------------------
// Sensor Logs
//--------------------------------
export const fetchSensorLogs = (project, deviceID, page, sort) => {
  return (dispatch, getState) => {
    if (!deviceID) {
      return;
    }

    // const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/page/" + page + "/rows/" + SENSOR_LOG_COUNT_FOR_PAGE + "/device/" + deviceID + "/logs?sort=" + JSON.stringify(sortObj);
    const path = `${endPoints.END_POINT}/sensorsLogs/${deviceID}?count=${SENSOR_LOG_COUNT_FOR_PAGE}&page=${page}`;

    dispatch(requestSensorLogs(project, deviceID));

    fetchMiddleware(path, {}, getState).then((json) => {
      if (json.status) {
        dispatch(receiveSensorLogs(project, json.data));
      }
    });
  };
};

function requestSensorLogs(project, deviceID) {
  return {
    type: types.REQUEST_SENSOR_LOGS,
    project,
    deviceID,
  };
}

function receiveSensorLogs(project, logs) {
  return {
    type: types.RECEIVE_SENSOR_LOGS,
    project,
    logs,
  };
}

export function selectSensorLog(selectedSensorLog) {
  return {
    type: types.SELECT_SENSOR_LOG,
    selectedSensorLog,
  };
}

export const selectSensorSample = (project, selectedSensorSample) => {
  return (dispatch, getState) => {
    // set selected sample action:
    dispatch({
      type: types.SELECT_SENSOR_SAMPLE,
      project,
      selectedSensorSample,
    });

    const wavesUrl = `${endPoints.END_POINT}/sensorsamples/waves/${selectedSensorSample.ID}`;
    return fetchMiddleware(wavesUrl, {}, getState).then(json => {
      if (json.status) {
        return (json.data);
      }
    }).then(data => {
      dispatch(receiveSensorSampleWaves(project, data));
    });
  };
};

function receiveSensorSampleWaves(project, data) {
  return {
    type: types.RECEIVE_SENSOR_SAMPLE_WAVS_PATHS,
    payload: data,
    project
  };
}

//--------------------------------
// Sensor Noise Analisys Data
//--------------------------------
export function fetchSensorNC(project, sensorID, sensorTypeEnum, pageNumber) {
  return (dispatch, getState) => {
    const state = getState();
    const numberOfSample = state.leaksList.leaksByProject[project].sensors.noiseAlertCount;

    const path = endPoints.END_POINT + "/sensor/" + sensorID + "/samples/" + numberOfSample + "/nc/" + pageNumber;

    dispatch(requestSensorNC(project, sensorID));
    return fetchMiddleware(path, {}, getState).then((json) => {
      let noiseSamples = [];
      let ncCount = 0;
      let nc1Array = [];
      let nc2Array = [];
      let nc3Array = [];
      let nc3aveArray = [];
      let nc1nc2Max = 0;
      let nc3nc3AveMax = 0;
      let barsData = [];
      if (json.status) {
        noiseSamples = json.data.rows;
        if (noiseSamples != null && noiseSamples.length > 0) {
          const samplesLength = noiseSamples.length;
          noiseSamples.forEach((sample, index) => {
            const nc1 = sample.Nc1;
            const nc2 = sample.Nc2;
            const nc3 = sample.Nc3;
            const nc3Ave = sample.NC3ave;
            const time = sample.SampleTime;
            // const fileSize = sample.FileSize;
            // const sampleFrequency = sample.fs || 4096;

            // if (fileSize != null) {
            //   const duration = fileSize / (sampleFrequency * 2);
            //   sample.Duration = duration.toFixed(1);
            // }
            if (nc1 != null) {
              nc1Array.push({
                x: samplesLength - index,
                y: nc1,
                time: time,
              });
            }
            if (nc2 != null) {
              nc2Array.push({
                x: samplesLength - index,
                y: nc2,
                time: time,
              });
            }
            if (nc3 != null) {
              nc3Array.push({
                x: samplesLength - index,
                y: nc3,
                time: time,
              });
            }
            if (nc3Ave != null) {
              nc3aveArray.push({
                x: samplesLength - index,
                y: nc3Ave,
                time: time,
              });
            }

            const maxNc1_2 = Math.max(nc1, nc2);
            const maxNc3_3ave = Math.max(nc3, nc3Ave);
            if (maxNc1_2 > nc1nc2Max) {
              nc1nc2Max = maxNc1_2;
            }
            if (maxNc3_3ave > nc3nc3AveMax) {
              nc3nc3AveMax = maxNc3_3ave;
            }
          });

          barsData = [
            noiseSamples[0].Nc3f1 || 0,
            noiseSamples[0].Nc3f2 || 0,
            noiseSamples[0].Nc3f3 || 0,
          ];
          ncCount = Math.max(nc1Array.length, nc3Array.length);
        }
      }

      let totalRows = json.data.totalRows;

      if (noiseSamples.length > 0) {
        dispatch(selectSensorSample(project, noiseSamples[0]));
      }

      const projectState = state.leaksList.leaksByProject[project];
      const projectSensors = projectState.sensors.sensors;
      const projectSensorsIndexMap = projectState.sensors.indexMap;
      const selectedSensor = projectSensors[projectSensorsIndexMap[sensorID]];
      const deviceTypeValue = (selectedSensor) ? selectedSensor.DeviceTypeValue : null;

      dispatch(receiveSensorNC(project, nc1Array, nc2Array, nc3Array, nc3aveArray, barsData,
        ncCount, nc1nc2Max, nc3nc3AveMax, sensorTypeEnum, deviceTypeValue, noiseSamples, totalRows));
    });
  };
}

function requestSensorNC(project, sensorID) {
  return {
    type: types.REQUEST_SENSOR_NC,
    project,
    sensorID,
  };
}

function receiveSensorNC(project, nc1Array, nc2Array, nc3Array, nc3aveArray, barsData, ncCount, maxNc1_2, maxNc3, sensorTypeEnum, deviceTypeValue, details, totalRows) {
  return {
    type: types.RECEIVE_SENSOR_NC,
    project,
    nc1Array,
    nc2Array,
    nc3Array,
    nc3aveArray,
    barsData,
    ncCount,
    maxNc1_2,
    maxNc3,
    sensorTypeEnum,
    deviceTypeValue,
    details,
    totalRows
  };
}

//--------------------------------
// Sensor Noise Alerts Data
//--------------------------------
export function fetchSensorNoiseAlerts(project, sensorID) {
    const path = endPoints.END_POINT + "/sensor/" + sensorID + "/noiseAlert";

    return (dispatch, getState) => {
        dispatch(requestSensorNoiseAlerts(project, sensorID));
        return fetchMiddleware(path, {}, getState).then(json => {
            return [json.data];
        })
            .then(args => {
                dispatch(receiveSensorNoiseAlerts(project, args[0]));
            });
    };
}

function requestSensorNoiseAlerts(project, sensorID) {
    return {
        type: types.REQUEST_SENSOR_NOISE_ALERT,
        project,
        sensorID,
    };
}

function receiveSensorNoiseAlerts(project, details) {
    return {
        type: types.RECEIVE_SENSOR_NOISE_ALERT,
        project,
        details,
    };
}

export const setNoiseSamplesCount = (project, count) => {
    return {
        type: types.SET_NOISE_SAMPLES_COUNT,
        project,
        count,
    };
};

//--------------------------------
// Sensor Bits
//--------------------------------
export const fetchSensorBits = (project, deviceID, page, sort) => {
  let sortObj;
  if (!sort) {
    sortObj = {
      orderBy: 'Time',
      dir: 'DESC'
    };
  } else {
    sortObj = {
      orderBy: sort.field,
      dir: sort.dir
    };
  }

  //const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/page/" + page + "/rows/" + SENSOR_LOG_COUNT_FOR_PAGE + "/device/" + deviceID + "/bits?sort=" + JSON.stringify(sortObj);
  const path = endPoints.END_POINT + "/device/" + deviceID + "/assembly?sort=" + JSON.stringify(sortObj);

  return (dispatch, getState) => {
    dispatch(requestSensorBits(project, deviceID));
    return fetchMiddleware(path, {}, getState).then(json => {
      return [json.data];
    })
      .then(args => {
        dispatch(receiveSensorBits(project, args[0]));
      });
  };
};

function requestSensorBits(project, deviceID) {
  return {
    type: types.REQUEST_SENSOR_BITS,
    project,
    deviceID,
  };
}

function receiveSensorBits(project, bits) {
  return {
    type: types.RECEIVE_SENSOR_BITS,
    project,
    bits,
  };
}

export function selectSensorBit(selectedSensorBit) {
  return {
    type: types.SELECT_SENSOR_BIT,
    selectedSensorBit,
  };
}

const fetchSensorImages = (projectId, sensorId) =>
  (dispatch, getState) => {
    dispatch(requestSensorImages(projectId, sensorId));

    return actionsGlobals.fetchImages(getState, projectId, sensorId, "sensor").then((json) => {
      if (json.status == false) {
        console.log(json);
      } else {
        dispatch(receiveSensorImages(projectId, sensorId, json.data));
      }
    });
  };

const requestSensorImages = (project, sensorId) => (
  {
    type: types.REQUEST_SENSOR_IMAGES,
    project,
    sensorId,
  }
);

const receiveSensorImages = (project, sensorId, images) => (
  {
    type: types.RECEIVE_SENSOR_IMAGES,
    project,
    sensorId,
    images,
  }
);

export function getSensorsActivityData(year, month) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const date = new Date();
    const yearVal = year || date.getFullYear();
    const monthVal = month || date.getMonth();

    const url = `${endPoints.END_POINT}/${endPoints.SENSORS_ENDPOINT}/activity/${projectId}?year=${yearVal}&month=${monthVal}`;

    dispatch(requestSensorsActivityData(projectId, year, month));
    return fetchMiddleware(url, {}, getState)
    .then((json) => {
      if (json.status) {

        dispatch(receiveSensorsActivityData(projectId, json.data));
      }
    });
  };
}

function requestSensorsActivityData(project, year, month) {
  return {
    type: types.REQUEST_SENSORS_ACTIVITY_DATA,
    project,
    payload: {
      year,
      month
    }
  };
}

function receiveSensorsActivityData(project, data) {
  return {
    type: types.RECEIVE_SENSORS_ACTIVITY_DATA,
    project,
    payload: data
  };
}

export function setSensorsActivityModalVisibility(visible) {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    dispatch({
      type: types.SENSORS_ACTIVITY_DATA_SET_VISIBILITY,
      project: projectId,
      payload: visible
    });
  };
}
