import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import Img from 'react-fix-image-orientation';

import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImagesTab = ({ images }) => {
  return (
    <Carousel
      showThumbs={false}
      verticalSwipe='natural'>
      {
        images && images.map((image, index) => (
          <div key={index}>
            <Img src={image} alt="img" style={{ objectFit: 'contain' }} />
            {/* <p className='legend'>{index}</p> */}
          </div>
        ))
      }
    </Carousel>
  );
}

export default ImagesTab;
