import React from 'react';
import PropTypes from 'prop-types';
import { Input, Select, FieldTimezonePicker } from '../../components';
import { Field, reduxForm } from 'redux-form';

class ProjectAdvancedForm extends React.Component {

   constructor(props) {
      super(props);
      this.state = {
         fields: []
      };
   }

   componentDidMount() {
      this.setState({
         fields: [
            {
               type: 'number',
               name: 'CouplesDaysBackForOnlyUpdated',
               label: this.context.t('Couples Days Back For Only Updated')
            },
            {
               type: 'number',
               name: 'SensitivityLevel',
               label: this.context.t('Sensitivity Level')
            },
            {
               type: 'checkbox',
               name: 'PossibleUFR',
               label: this.context.t('Possible UFR')
            },
            {
               type: 'checkbox',
               name: 'AllowProd2Run',
               label: this.context.t('Allow Prod2 Run')
            },
            {
               type: 'checkbox',
               name: 'PossiblePumps',
               label: this.context.t('Possible Pumps')
            },
            {
               type: 'checkbox',
               name: 'AllowFalsePositive',
               label: this.context.t('Allow False Positive')
            },
            {
               type: 'number',
               name: 'Prob4Burst',
               label: this.context.t('Prob4 Burst')
            },
            {
               type: 'number',
               name: 'Prob4Irrigation',
               label: this.context.t('Prob4 Consumption')
            },
            {
               type: 'checkbox',
               name: 'UseFeetInMatlabGraphs',
               label: this.context.t('Use Feet In Matlab Graphs')
            },
            {
               type: 'number',
               name: 'MultiMaterial',
               label: this.context.t('Multi Material')
            },
            {
               type: 'number',
               name: 'MultiDiameter',
               label: this.context.t('Multi Diameter')
            },
            {
               type: 'number',
               name: 'balagan_dist_ratio_thr',
               label: this.context.t('balagan_dist_ratio_thr')
            },
            {
               type: 'number',
               name: 'irrig_dist',
               label: this.context.t('irrig_dist')
            },
            {
               type: 'checkbox',
               name: 'MinNc1Days',
               label: this.context.t('Min Nc1 Days')
            },
            {
               type: 'number',
               name: 'Fmin',
               label: this.context.t('Fmin')
            },
            {
               type: 'number',
               name: 'Fmax',
               label: this.context.t('Fmax')
            },
            {
               type: 'number',
               name: 'NC3projMean',
               label: this.context.t('NC3 proj Mean')
            },
            {
               type: 'number',
               name: 'NC3StdMeanRatio',
               label: this.context.t('NC3 Std Mean Ratio')
            },
            {
               type: 'number',
               name: 'NoiseAlertStepProbThr',
               label: this.context.t('Noise Alert Step Prob Thr')
            },
            {
               type: 'number',
               name: 'NoiseAlertConstProbThr',
               label: this.context.t('Noise Alert Const Prob Thr')
            },
            {
               type: 'number',
               name: 'sum_included_thr',
               label: this.context.t('sum_included_thr')
            },
            {
               type: 'number',
               name: 'NumBadBurstSamples_thr',
               label: this.context.t('NumBadBurstSamples_thr')
            },
            {
               type: 'checkbox',
               name: 'IsDisplayCityColumn',
               label: this.context.t('Is Display City Column')
            },
            {
               type: 'number',
               name: 'DaysCorrelationIncative',
               label: this.context.t('Days Correlation Incative')
            },
            {
               type: 'number',
               name: 'NoiseOkThreshold',
               label: this.context.t('Noise Ok Threshold')
            },
            {
               type: 'checkbox',
               name: 'ExcludeSample1by4flag',
               label: this.context.t('Exclude Sample 1 by 4 flag')
            },
            {
               type: 'number',
               name: 'ExcludeSample1by4radius',
               label: this.context.t('Exclude Sample 1 by 4 radius')
            },
            {
               type: 'number',
               name: 'ExcludeSample1by4number',
               label: this.context.t('Exclude Sample 1 by 4 number')
            },
            {
               type: 'checkbox',
               name: 'DoNotTake0samplesInAverage',
               label: this.context.t('Do Not Take 0 samples In Average')
            },
            {
               type: 'checkbox',
               name: 'OpenNewLeakOn0sample',
               label: this.context.t('Open New Leak On 0 sample')
            },
            {
               type: 'number',
               name: 'ProjIntFactor',
               label: this.context.t('Proj Int Factor')
            },
            {
               type: 'number',
               name: 'ProjIntLenFactor',
               label: this.context.t('Proj Int Len Factor')
            },
            {
               type: 'number',
               name: 'PressureValueCoefficient',
               label: this.context.t('Prs Calib Multiply')
            },
            {
               type: 'number',
               name: 'pressure_calib_coeff_y_axis',
               label: this.context.t('Prs Calib Shift')
            },
            {
               type: 'select',
               name: 'MapPipesDiameterType',
               label: this.context.t('Map Pipes Diameter Type'),
               componemt: (props) => (
                  <Select {...props}
                    placeholderTitle={this.context.t('Select...')}
                    options={[
                      {value: 0, label: 'Inch'},
                      {value: 1, label: 'Millimeter'},
                    ]}
                  />
               )
            },
         ],
      });
   }

   render() {
      const divStyle = { float: 'left', width: '100%' };
      const lsbelClassNameLeftColumn = 'col-xs-4 control-label';
      const fieldsClassLeftColumn = 'col-xs-6 control-input';

      return (
         <div>
            <form
            style={{height: '366px', overflow: 'auto'}}
            className="form-horizontal sticky-actions">
               <div
                  style={{ display: 'flex' }}
               >
                  <div style={divStyle}>
                     {this.state.fields.map((field, index) => {
                        let className;
                        switch (field.type) {
                          case 'select':
                            className = 'col-xs-8 control-input'
                            break;
                          case 'checkbox':
                            className = 'checkbox-control'
                            break;
                          default:
                            className = 'form-control'
                            break;
                        }
                        return (
                           <div
                              key={index}
                              className="form-group">
                              <label
                                 className={lsbelClassNameLeftColumn}>{field.label}:</label>

                              <div className={fieldsClassLeftColumn}>
                                 <Field
                                    name={field.name}
                                    component={field.componemt || Input}
                                    type={field.type}
                                    className={className}
                                 />
                              </div>
                           </div>
                        );
                     })}
                  </div>
               </div>
            </form>
            <a
               style={{left: '6px', bottom: '6px', position: 'absolute'}}
               className="btn btn-info"
               onClick={this.props.previousPage}>

               {this.context.t('Back')}

            </a>
         </div>
      );
   }
}

ProjectAdvancedForm.contextTypes = {
   t: PropTypes.func.isRequired
};

const projectAdvancedForm = reduxForm({
   form: 'projectDetails', // a unique name for this form
   destroyOnUnmount: false, // <------ preserve form data
   forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
   enableReinitialize: true,
 })(ProjectAdvancedForm);

 export default projectAdvancedForm;
