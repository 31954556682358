import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

import UIDateFormater from '../../containers/UIhelper/UIDateFormater';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

//require('./ModalsStyle.scss');
require('./PipeAssessmentReportModal.scss');

class PipeAssessmentReportModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      imgPng: null,
      processing: false
    };

    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.pipeAssessmentReport = this.pipeAssessmentReportPDF.bind(this);
    this.saveAndClose = this.saveAndClose.bind(this);
    this.printPipeAssessmentReport = this.printPipeAssessmentReport.bind(this);
    this.exportToPDF = this.exportToPDF.bind(this);
    this.animateEllipsis = this.animateEllipsis.bind(this);

    this.recordsRender = this.recordsRender.bind(this);
  }

  componentWillUnmount() {
    clearInterval(this.processingInterval);
  }

  // Open Dialog - Get map canvas
  onOpen() {
    this.setState({
      open: true
    });
    this.props.loadReport();

    this.props.getMapCapture().then((url) => {
      let image = new Image();
      image.crossOrigin = 'Anonymous';
      image.src = url;

      this.setState({
        imgPng: url,
        menuAnchorEl: null,
      });
    });
  }

  // Close dialog
  onClose() {
    this.setState({
      open: false,
    });
  }

  // Dialog buttons events
  saveAndClose() {
    // ToDo
    this.pipeAssessmentReportPDF('save');
  }

  printPipeAssessmentReport() {
    this.pipeAssessmentReportPDF('print');
  }

  exportToPDF() {
    this.pipeAssessmentReportPDF('export');
  }

  // PDF Operations
  pipeAssessmentReportPDF(output) {

    const self = this;
    self.setState({ processing: true });
    this.animateEllipsis();

    var divToPrint = document.getElementById('pipe-assessment-body');

    html2canvas(divToPrint, {
      useCORS: true,
      allowTaint: false,
      logging: true,
    }).then(canvas => {
      let pdf = new jsPDF({
        orientation: 'l'
      });
      pdf.addImage(canvas, 'JPEG', 8, 30)

      switch (output) {
        case 'export':
          // Local save to PDF
          pdf.save('pipe-assessment-report.pdf');
          break;
        case 'print':
          // Print PDF
          pdf.autoPrint();
          pdf.output('dataurlnewwindow');
          break;
        case 'save':
          // ToDo: save to
          break;
        default:
          pdf.output('dataurlnewwindow');
          self.setState({ open: false })
          break;
      }
      // See http://rawgit.com/MrRio/jsPDF/master/docs/jspdf.js.html#line981

      self.setState({ processing: false });
      clearInterval(this.processingInterval);
    }).catch(error => {
      console.log(error);
    });

  }

  animateEllipsis() {
    setTimeout(() => {
      const ellipsis = this.ellipsis;
      this.processingInterval = setInterval(function () {
        if (ellipsis.innerHTML.length > 3)
          ellipsis.innerHTML = "";
        else
          ellipsis.innerHTML += ".";
      }, 500);
    });
  }

  handleSubmit(event) {
    debugger;
  }

  recordsRender() {
    const { records, timeZone, alertStateOptions } = this.props;

    return (
      <div className="records-container">
        {records != null &&
          records.map((item, index, array) => {
            return (
              <div
                key={index}
                className="pipe-assessment-record">
                <div>
                  <p>{this.context.t('Couple ID: {CoupleID},   Material: {material},   {Count} Alerts', { CoupleID: item.ID, material: item.Material, Count: item.Leaks })}</p>
                  <span>{this.context.t('Addresses: {address1}, {address2}', { address1: item.Address1, address2: item.Address2 })}</span>
                </div>
                <div>
                  <table>
                  <tr>
                    <th>{this.context.t('Alert ID')}</th>
                    <th>{this.context.t('Intensity')}</th>
                    <th>{this.context.t('State')}</th>
                    <th>{this.context.t('Detected')}</th>
                    <th>{this.context.t('Latitude')}</th>
                    <th>{this.context.t('Longitude')}</th>
                    <th>{this.context.t('X')}</th>
                    <th>{this.context.t('Y')}</th>
                  </tr>
                {
                  item.alerts.map((alert, sampleIndex) => {
                        const alertState = alert.AlertState;
                        const state = alertStateOptions.find(option => {
                          return (option.value == alertState);
                        });
                        return (
                          <tr key={sampleIndex}>
                        <td>{alert.id}</td>
                        <td>{alert.Intensity}</td>
                        <td>{state.label}</td>
                        <td>{UIDateFormater(alert.DetectedAt, DateType.DATE, timeZone)}</td>
                        <td>{alert.Latitude.toFixed(7)}</td>
                        <td>{alert.Longitude.toFixed(7)}</td>
                        <td>{alert.X}</td>
                        <td>{alert.Y}</td>
                      </tr>
                      );
                  })
                }
                  </table>
                </div>
              </div>
              );
          })
        }
      </div>
      );
  }

  render() {
    const { isAqsAdmin, coverageSource, coverageLayerSelected, project } = this.props;

    return (
      <div>
        { isAqsAdmin && (coverageSource == 'pipeAssessment' && coverageLayerSelected) &&
        <button
          type="button"
          className='btn btn-success btn-assessments-report'
          onClick={this.onOpen}
          title={this.context.t('Pipe Assessment Report')}/>
        }

        <AppModal
          open={this.state.open}
          onClose={this.onClose}
          title={this.context.t('Pipe Assessments Report. {projectName} [EPSG: {epsg}]', { projectName: project.ShortName, epsg: project.EPSG })}
          content={(
            <div>
              <div className="map-container">
                <img src={this.state.imgPng} className="pipeAssessmentImage" id="pipeAssessmentMap" />
                <div className="form-group rightSideGuidlinesInPipeAssessmentReport">
                  <div>{this.context.t('Guidelines:')}</div>
                  <div className="col-xs-8 control-input">
                    <Field name="guidelines" component="textarea" className="form-control textAreaGuidline" />
                  </div>
                </div>
              </div>
              {
                this.recordsRender()
              }
            </div>
          )}
          actions={(
            <button className='btn btn-info' onClick={this.exportToPDF} title={this.context.t('Export the view to PDF')}>
              {this.context.t('Export')}
            </button>
          )}
        />

        {/* <Modal
          className="pipe-assessment"
          style={{ display: this.state.open ? 'block' : 'none' }}
          show={this.state.open}
          onHide={this.onClose}
          aria-labelledby="ModalHeader"
        >
          <Modal.Header closeButton>
          </Modal.Header>
          <Modal.Body id="pipe-assessment-body">
            <div>
              <form className="form-horizontal form-work-order" onSubmit={this.handleSubmit}>
                <h2>{this.context.t('Pipe Assessments Report. {projectName} [EPSG: {epsg}]', { projectName: project.ShortName, epsg: project.EPSG })}</h2>
                <div className="map-container">
                  <img src='' className="pipeAssessmentImage" id="pipeAssessmentMap" />
                  <div className="form-group rightSideGuidlinesInPipeAssessmentReport">
                    <div>{this.context.t('Guidelines:')}</div>
                    <div className="col-xs-8 control-input">
                      <Field name="guidelines" component="textarea" className="form-control textAreaGuidline" />
                    </div>
                  </div>
                </div>
                {
                  this.recordsRender()
                }
              </form>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div style={{
              position: 'absolute',
              top: '18%',
              left: 0,
              width: '100%'
            }}>
              <BarLoader
                color="#4cae4c"
                width="110%"
                loading={false}
              />
            </div>
            {this.state.processing == true &&
              <div className="processing">
                {this.context.t('Processing')}
                <span ref={(span) => { this.ellipsis = span; }} />
              </div>
            }
            {false &&
              <button className='btn btn-primary' onClick={this.printPipeAssessmentReport} title={this.context.t('Send to printer')}>
                {this.context.t('Print')}
              </button>
            }
            <button className='btn btn-info' onClick={this.exportToPDF} title={this.context.t('Export the view to PDF')}>
              {this.context.t('Export')}
            </button>
            {false &&
              <button className='btn btn-success' onClick={this.saveAndClose} title={this.context.t('Save this work-order')}>
                {this.context.t('Save')}
              </button>
            }
          </Modal.Footer>
        </Modal> */}
      </div>
    );
  }
}

PipeAssessmentReportModal.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const pipeAssessmentReportModal = reduxForm({
  form: 'PipeAssessmentReportModal', // a unique name for this form
  enableReinitialize: true,
})(PipeAssessmentReportModal);

export default pipeAssessmentReportModal;
