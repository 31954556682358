import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
import { selectFeature } from './GlobalsActions';
import * as endPoints from '../constants/EndPoints';
import * as types from '../constants/ActionTypes'; //Need add mine types means that I received the data from the server

export const selectInterference = (interference) => {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;

    // if the method get null interference item - clear the selection (by send EMPTY STRING to 'selectFeature' method):
    let interferenceId;
    let type;
    if (interference == null) {
      interferenceId = '';
      type = '';
    } else {
      interferenceId = interference.id;
      type = 'interference';
    }
    dispatch(selectFeature(selectedProject, interferenceId, type));
  };
};

//like project = project
const requestInterferences = (project) => {
    return {
        type: types.REQUEST_INTERFERENCES,
        project
    };
};

const receiveInterferences = (project, interferences, interferencesIcons, indexMap) => {
    return {
        type: types.RECEIVE_INTERFERENCES,
        project,
        interferences,
        interferencesIcons,
        indexMap
    };
};

// Fetching the interferences
export const fetchInterferences = (project) => {
    return (dispatch, getState) => {
      const state = getState();
      const sort = state.leaksList.filters.interferencesSort;
      const filters = state.leaksList.filters.interferencesFilters;

      const path = endPoints.INTERFERENCES_ENDPOINT + "/" + project + "?filters=" + JSON.stringify(filters) + "&sort=" + JSON.stringify(sort);

      dispatch(requestInterferences(project));
        return fetchMiddleware(path, {}, getState).then((json) => {
          if (!json.status) {
            return [ [], {}, {} ];
          } else {
            const interferencesList = json.data || []; // the interferences array from server response.
            const interferenceTypes = getState().leaksList.optionList.options.InterferenceTypes; // the InterferencesTypes Enum.

            interferencesList.forEach(interference => {
              // cast couples from concatinated string to array
              if (interference.LinkedCouples != null) {
                const idsTextArray = interference.LinkedCouples.split(',');
                interference.LinkedCouples = idsTextArray.map((coupleIdAsText) => {
                  return parseInt(coupleIdAsText);
                });
                interference.LinkedCouples = interference.LinkedCouples;
              }

              // for each interference - we add attribute 'typeName' with the 'Type Name' according to the type code (int value):
              interferenceTypes.forEach(type => {
                if (interference.Type == type.value) {
                  interference.typeName = type.label;
                }
              });
            });

            // modification the Boundary to [[lng, lat], [lng, lat], ...] :
            boundingShapesForInterferences(interferencesList);

            const iconsFeatures = getIconFeatures('interferences', interferencesList, interferenceTypes);

            return [interferencesList, iconsFeatures, setIndexes(interferencesList, 'id')];
          }
        }).then((args) => dispatch(receiveInterferences(project, ...args)));
    };
};

export const createInterference = (values) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const shape = state.install.newInstallItem.item.polygon;
    const path = endPoints.INTERFERENCES_ENDPOINT + '/' + projectId;

    values.polygonPoints = shape;
    const geometryType = state.mapState.drawMode.type;

      return fetchMiddleware(path, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: values, type: geometryType })
     }, getState).then((json) => {
      console.log(json);

      // reload interferences:
      dispatch(fetchInterferences(projectId));

    });
  };
};

export const updateInterference = (values) => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const selectedInterferenceId = state.leaksList.leaksByProject.selectedFeature;
    const path = endPoints.INTERFERENCES_ENDPOINT + "/" + projectId + "/" + selectedInterferenceId;
    values.type = state.mapState.drawMode.type;

    // Edited Polygon
    values.polygonPoints = state.install.editedInterference;

    dispatch(selectFeature(projectId, '', ''));
    // dispatch(updateInterferenceReq(projectId, values));

    return fetchMiddleware(path, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ data: values })
     }, getState).then((json) => {
      console.log(json);

      // reload interferences:
      dispatch(fetchInterferences(projectId));
      // select the current interference
      dispatch(selectInterference({id: selectedInterferenceId}));
    });
  };
};

// After user clicked on DELETE button, we need to process the request
export const deleteInterference = () => {
  return (dispatch, getState) => {
    const state = getState();
    const projectId = state.leaksList.selectedProject;
    const selectedInterferenceId = state.leaksList.leaksByProject.selectedFeature;
    const path = endPoints.INTERFERENCES_ENDPOINT + "/" + projectId + "/" + selectedInterferenceId;
    return fetchMiddleware(path, {
      method: 'DELETE',     // TRY TO DELETE HEADERS
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    }, getState).then((json) => {
      console.log(json);

      // select the current interference
      dispatch(selectInterference(null));
      // reload interferences:
      dispatch(fetchInterferences(projectId));

    });
  };
};

function boundingShapesForInterferences(Interferences) {
  for (var i = 0; i < Interferences.length; i++) {
    if (Interferences[i].Boundary != null) {

      var numbers = [];
      let geometryType;

      if(Interferences[i].Boundary.startsWith("POLYGON")){
        geometryType = "POLYGON";
      }

      else if(Interferences[i].Boundary.startsWith("LINESTRING")){
        geometryType = "LINESTRING";
      }

      numbers = Interferences[i].Boundary.replace(")", "").replace(",", "").replace(geometryType + " ", "").replace("(", "").split(' ');

      var res = Create2DArray(parseFloat(numbers.length / 2));

      for (var p = 0; p < numbers.length / 2; p++) {
        res[p][0] = parseFloat(numbers[2 * p].replace('(', ''));
        res[p][1] = parseFloat(numbers[2 * p + 1].replace(')', ''));
      }

      Interferences[i].Boundary = res;
      Interferences[i].GeometryType = geometryType;
    }
  }
}

function Create2DArray(rows) {
  var arr = [];
  for (var i = 0; i < rows; i++) {
    arr[i] = [];
  }
  return arr;
}

export const setInterfernecesSettings = (sortParams, filters) => {
  const action =  {
    type: types.SET_INTERFERENCE_SETTINGS,
    filters,              // Like filters: filters,
    sortParams,           // Like sortParams: sortParams
  };

  return action;
};

export function sortInterferencesLocaly(field, dir) {
  return (dispatch, getState) => {
    const state = getState();
    const selectedProject = state.leaksList.selectedProject;
    const interferencesState = state.leaksList.leaksByProject[selectedProject].interferences;
    const { interferences, interferencesIcons } = interferencesState;
    const sortedInterferences = interferences.sort((a, b) => {
      let sortResult = 0;
      if (a[field] > b[field]) {
        sortResult = (dir === 'asc') ? 1 : -1;
      } else if (a[field] < b[field]) {
        sortResult = (dir === 'asc') ? -1 : 1;
      }
      return sortResult;
    });

    const newIndexMap = setIndexes(sortedInterferences, 'id');

    dispatch(receiveInterferences(selectedProject, sortedInterferences, interferencesIcons, newIndexMap));
    dispatch({ type: types.SORT_INTERFERENCES_LOCALY, field, dir });
    // dispatch(setSortDetails(field, dir));
  };
}
