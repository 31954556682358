import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { SortableHeaderCell, TextCell } from '../../components';
import TableMixin from '../../components/TableMixin/TableMixin';
import CTableActions from '../../containers/CTableActions/CTableActions';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

class ProjectsTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      goToRow: 0,
    };
    //this.goToRow = this.goToRow.bind(this);
  }
  componentDidUpdate(prevProps) {
    const { manageItem, items, showDetails } = this.props;
    if (manageItem != null && manageItem.item != null) {
      if (prevProps.manageItem == null || prevProps.manageItem.item == null || manageItem.item.ID !== prevProps.manageItem.item.ID || showDetails !== prevProps.showDetails || items.length !== prevProps.items.length) {
        const selectedRow = items.filter(function (item) {
          return item.ID == manageItem.item.ID;
        })[0];
        this.setState({
          goToRow: items.indexOf(selectedRow),
        });
      }
    }
  }

  render() {
    const { items, pHeight, pWidth, rowClickEvent, sortProp, rowClassNameGetter, t } = this.props;
    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          rowClassNameGetter={rowClassNameGetter}
          scrollToRow={this.state.goToRow}
          {...this.props}
        >
          <Column
            columnKey="ID"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('ID')}
            cell={<TextCell data={items} field="ID" />} />
          <Column
            columnKey="Name"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('Name')}
            cell={<TextCell data={items} field="ShortName" />} />
          <Column
            columnKey="CustomerName"
            width={30}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}//{t('Customer Name')}
            cell={<TextCell data={items} field="CustomerName" />} />
        </Table>

        {false && items && items.length > 0 &&
          <CTableActions
            type="alerts"
          />
        }
        {items && items.length > 0 &&
          <span style={totalItemsStyle}>Total: {items.length}</span>
        }
      </div>
    );
  }
}

ProjectsTable = TableMixin(ProjectsTable, null);
export default ProjectsTable;
