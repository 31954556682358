import React from 'react';

import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
// import { Select } from 'react-select';
import { Select, OprSelect } from '../../components';
import { positiveNum } from '../../validators/formValidators';
const ReactSelect = require('react-select');
const classNames = require('classnames');

require('./AlertsSettings.scss');

class AlertsSettings extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      handleSubmit,
      submitting,
      uiInfo,
      optionList,
      toggleSettingsDisplay,
    } = this.props;
    const panelClasses = classNames('alerts-settings', {
      show: this.props.showSettings,
    });

    const panelStyle = {
      height: uiInfo.pageHeight,
      width: 0.395 * (uiInfo.pageWidth - 10),
    };

    return (
      <div className={panelClasses} style={panelStyle}>
        <div className="alerts-header">
          <div className="actions">
            <i className="icon-close" onClick={toggleSettingsDisplay} />
          </div>
          <h2>{this.context.t('Alerts Options')}</h2>
        </div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <fieldset>
            <legend>{this.context.t('Sort by')}</legend>
            <div className="form-group">
              <div className="col-xs-8">
                <Field
                  name="sortBy"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={false}
                      options={[
                        {
                          value: 'Priority',
                          label: this.context.t('Priority (default)'),
                        },
                        { value: 'ID', label: this.context.t('ID') },
                        {
                          value: 'DetectedAt',
                          label: this.context.t('Detected'),
                        },
                        { value: 'AlertType', label: this.context.t('Type') },
                        {
                          value: 'AlertState',
                          label: this.context.t('Status'),
                        },
                        {
                          value: 'Intensity',
                          label: this.context.t('Intensity'),
                        },
                        {
                          value: 'WorkArea',
                          label: this.context.t('Work Area'),
                        },
                        {
                          value: 'fixDate',
                          label: this.context.t('Repair date'),
                        },
                        {
                          value: 'RepairCode',
                          label: this.context.t('Repair Details'),
                        },
                        {
                          value: 'ClosureDate',
                          label: this.context.t('Closure date'),
                        },
                        {
                          value: '10',
                          label: this.context.t('Service provider'),
                        },
                        //{value: '11', label: this.context.t('Days detected')},
                      ]}
                    />
                  )}
                />
              </div>
              <div className="col-xs-4">
                <Field
                  name="sortByDir"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={false}
                      backspaceRemoves={false}
                      options={[
                        { value: 'asc', label: this.context.t('ASC') },
                        { value: 'desc', label: this.context.t('DESC') },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>{this.context.t('Filters')}</legend>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Priority')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="Priority"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={[
                        { value: 'A', label: this.context.t('A') },
                        { value: 'B', label: this.context.t('B') },
                        { value: 'C', label: this.context.t('C') },
                        { value: 'D', label: this.context.t('D') },
                      ]}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Type')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="AlertType"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={optionList.AlertType}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Status')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="AlertState"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={optionList.AlertState}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Intensity')}
              </label>
              <div className="col-xs-2 control-numeric-opr">
                <Field name="IntensityOpr" component={OprSelect} />
              </div>
              <div className="col-xs-6">
                <Field
                  name="Intensity"
                  component="input"
                  type="number"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Probability')}
              </label>
              <div className="col-xs-2 control-numeric-opr">
                <Field name="ProbabilityOpr" component={OprSelect} />
              </div>
              <div className="col-xs-6">
                <Field
                  name="Probability"
                  component="input"
                  type="number"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Work Area')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="WorkArea"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={optionList.WorkAreas}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Repair Details')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="RepairCode"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={optionList.PCARepairCodesEnum}
                    />
                  )}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Detected')}
              </label>
              <div className="col-xs-3 control-date-form">
                <Field
                  name="DetectedAt-From"
                  component="input"
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="col-xs-1 control-label"> to</div>
              <div className="col-xs-3 control-date-to">
                <Field
                  name="DetectedAt-To"
                  component="input"
                  type="date"
                  className="form-control"
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Repair date')}
              </label>
              <div className="col-xs-3 control-date-form">
                <Field
                  name="fixDate-From"
                  component="input"
                  type="date"
                  className="form-control"
                />
              </div>
              <div className="col-xs-1 control-label">
                {' '}
                {this.context.t('to')}
              </div>
              <div className="col-xs-3 control-date-to">
                <Field
                  name="fixDate-To"
                  component="input"
                  type="date"
                  className="form-control"
                />
              </div>
            </div>
            {false && (
              <div className="form-group">
                <label className="col-xs-4 control-label">
                  {this.context.t('Closure date')}
                </label>
                <div className="col-xs-3 control-date-form">
                  <Field
                    name="ClosureDate-From"
                    component="input"
                    type="date"
                    className="form-control"
                  />
                </div>
                <div className="col-xs-1 control-label">
                  {' '}
                  {this.context.t('to')}
                </div>
                <div className="col-xs-3 control-date-to">
                  <Field
                    name="ClosureDate-To"
                    component="input"
                    type="date"
                    className="form-control"
                  />
                </div>
              </div>
            )}
            <div className="form-group">
              <label className="col-xs-4 control-label">
                {this.context.t('Service provider')}
              </label>
              <div className="col-xs-8">
                <Field
                  name="ContractorID"
                  component={(props) => (
                    <Select
                      {...props}
                      placeholderTitle={this.context.t('Select...')}
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={optionList.ContractorID}
                    />
                  )}
                />
              </div>
            </div>
            {false && (
              <div className="form-group">
                <label className="col-xs-4 control-label">
                  {this.context.t('Days detected')}
                </label>
                <div className="col-xs-2 control-numeric-opr">
                  <Field name="DetectedAtOpr" component={OprSelect} />
                </div>
                <div className="col-xs-6">
                  <Field
                    name="DetectedAt"
                    component="input"
                    type="number"
                    validate={positiveNum}
                    className="form-control"
                  />
                </div>
              </div>
            )}
            <div className="text-right">
              <button
                className="btn btn-success"
                type="submit"
                disabled={submitting}
                onClick={toggleSettingsDisplay}
              >
                {this.context.t('Save & Apply')}
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

function validate(formProps) {
  const errors = {};
  if (positiveNum(formProps.DaysDetected)) {
    errors.DaysDetected = 'must be positive';
  }
  return errors;
}

AlertsSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

// Decorate the form component
AlertsSettings = reduxForm({
  form: 'alerts-settings', // a unique name for this form
  enableReinitialize: true,
})(AlertsSettings);

export default AlertsSettings;
