import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { Field, reduxForm } from 'redux-form';
import { Select, Input, TextCell } from '../../../components';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

require('fixed-data-table-2/dist/fixed-data-table.css');
require('./DeviceTab.scss');

const classNames = require('classnames');

// class DateCell extends React.Component {
//     render() {
//         console.log(this.props)
//         const { rowIndex, field, data, ...props } = this.props;
//         const value = data[rowIndex][field];
//         let formattedValue;
//         // Timestamp
//         if (value) {
//             const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
//             formattedValue = new Date(value).toLocaleString([], options);
//         }
//         const cellClasses = classNames(
//             'cell-' + field,
//             field == 'priority' ? 'priority priority-' + value : ''
//         );
//         return (
//             <Cell title={formattedValue} className={cellClasses} >
//                 {formattedValue}
//             </Cell>
//         );
//     }
// }

class DeviceTab extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { error, handleSubmit, pristine, reset, submitting, user, log, uiInfo, timeZone } = this.props;
    const initialValues = this.props.initialValues ? this.props.initialValues : {}; // Prevent 'undefined' errors.
    const tabClasses = classNames(
      'device-tab',
      { 'empty-table': log.data.length == 0 }
    );

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight;
    return (
      <div className={tabClasses}>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('Device ID')}:</label>
            <div className="col-xs-8 control-input">
              <Field name="DeviceID" component={Input} type="text" className="form-control" disabled={true} />
            </div>
          </div>
          <div className="inline-group">
            <label className="col-xs-4 control-label">{this.context.t('Device type')}:</label>

            <div className="col-xs-8 control-input">
              <Field name="DeviceTypeValue"
                disabled
                component={(props) => (
                  <Select {...props}
                    placeholderTitle={this.context.t('Select...')}
                    clearable={false}
                    backspaceRemoves={false}
                    multi={false}
                    options={this.props.deviceTypeOptions}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-xs-12 form-feedback text-center text-danger">
            {error && { error }}
          </div>
          {false &&
            <div className="form-actions text-right">
              <button className="btn btn-success" disabled={pristine || submitting} type="submit">Save</button>
            </div>
          }
        </form>

        <Table
          rowsCount={log.data.length}
          width={pWidth}
          maxHeight={pHeight - 137}
          headerHeight={30}
          rowHeight={30}>
          <Column
            header={<Cell>{this.context.t('Date')}</Cell>}
            cell={<TextCell data={log.data} field="TimeOfMaintenance" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
            width={100}
            flexGrow={1}
          />
          <Column
            header={<Cell>{this.context.t('Project')}</Cell>}
            cell={<TextCell data={log.data} field="Name" />}
            width={50}
            flexGrow={1}
          />
          <Column
            header={<Cell>{this.context.t('Sensor ID')}</Cell>}
            cell={<TextCell data={log.data} field="SensorID" />}
            width={50}
            flexGrow={1}
          />
          {false && <Column
            header={<Cell>{this.context.t('Device ID')}</Cell>}
            cell={<TextCell data={log.data} field="DeviceID" />}
            width={140}
            flexGrow={1}
          />}
          <Column
            header={<Cell>{this.context.t('User')}</Cell>}
            cell={<TextCell data={log.data} field="UserName" />}
            width={10}
            flexGrow={1}
          />
          <Column
            header={<Cell>{this.context.t('Action')}</Cell>}
            cell={<TextCell data={log.data} field="Action" />}
            width={110}
            flexGrow={1}
          />
          <Column
            header={<Cell>{this.context.t('Comment')}</Cell>}
            cell={<TextCell data={log.data} field="Comment" />}
            width={120}
            flexGrow={2}
          />
        </Table>
      </div>
    );
  }
}

DeviceTab.contextTypes = {
  t: PropTypes.func.isRequired
}

// Decorate the form component
DeviceTab = reduxForm({
  form: 'device', // a unique name for this form
  enableReinitialize: true
})(DeviceTab);

export default DeviceTab;
