import * as types from '../constants/ActionTypes';

const initialState = {};

const pipeAssessments = (state = initialState, action) => {
  const actionType = action.type || "";

  switch (actionType) {
    case types.ASSESSMENTS_REPORT_REQUEST:
      return Object.assign({}, state, {
        records: []
      });
    case types.ASSESSMENTS_REPORT_RECEIVE:
      return Object.assign({}, state, {
        records: action.records
      });
    default:
      return state;
  }
}

export default pipeAssessments;
