import React from 'react';
import PropTypes from 'prop-types';

import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';

import IconButton from '@mui/material/IconButton';
import MapIcon from '@mui/icons-material/Map';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';

import CCoupleAssessmentReportModal from '../../containers/CCoupleAssessmentReportForm/CCoupleAssessmentReportForm';
export default function TableActions(props, context) {
    const { type, exportProject, exportAll, exportAllHistory, exportSensorsMaintenance, customOnClick, user } = props;
    const className = "table-actions " + type;
    return (
      <div className={className} >
        { type !== 'iQuarius' && type !== 'pressure' &&
          <button onClick={exportProject} className={"btn btn-link table-actions-export " + type} title={context.t('Export To Excel')} />
        }

        { false && ENV !== ENVIRONMENTS.SINGAPORE &&
          type !== 'assessments' &&
          type !== 'iQuarius' &&
          type !== 'history' &&
          type !== 'pressure' &&
          <button onClick={exportAll} className="btn btn-link table-actions-export-all" title={context.t('Export All Projects To Excel')} />
        }

        {
          type === 'alerts' &&
          <button onClick={exportAllHistory} className="btn btn-link table-actions-export-alerts-history" title={context.t('Export Alerts History')} />
        }
        {
          type === 'sensors' &&
          <button onClick={exportSensorsMaintenance} className='btn btn-link table-actions-export-sensors-maintenance' title={context.t('Sensors Maintenance')}/>
        }
        {
          type === 'sensors' &&
          <IconButton
            title={context.t('Sensors Activity Report')}
            onClick={() => props.loadSensorsActivityData()}
          >
            <PermDataSettingIcon/>
          </IconButton>
        }
        {
          type === 'couples' &&
          <IconButton
            title={context.t('Export shp file')}
            onClick={() => props.exportCouplesShapeFile()}
          >
            <MapIcon/>
          </IconButton>

        }
        {type === 'assessments' &&
          <CCoupleAssessmentReportModal
            getMapCapture={props.getMapCapture}
          />
        }
        {type === 'pressure' &&
          <button
            onClick={exportProject}
            className={"btn btn-link table-actions-export " + type}
            title={context.t('Export Alerts To Excel')} />
        }
        {type === 'mobile' &&
        <IconButton
          title={context.t('IQ100B Reports')}
          onClick={() => props.loadIQ100BReportsData()}
        >
          <PermDataSettingIcon/>
        </IconButton>
        }
        { false && type === 'pressure' &&
          <button
            onClick={exportAll}
            className='btn btn-link table-actions-export-alerts-history'
            title={context.t('Export all transients to Excel')} />
        }
      </div>
    );

}

//@TODO write props types...
//TableActions.propTypes = {
//  handleRowClick: PropTypes.func.isRequired
//};
TableActions.contextTypes = {
  t: PropTypes.func.isRequired
};

TableActions.propTypes = {
  exportAll: PropTypes.func.isRequired,
  exportProject: PropTypes.func.isRequired
};


