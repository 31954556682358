import { connect } from 'react-redux';
import * as actions from '../../actions/CustomersActions';

import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';

import {
  CustomerDistributionListsTable,
  CustomerDistributionUsersTable,
  CustomerDistributionAllCustomerUsersTable
} from '../../components';

class CustomerDistributionListsModal extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      newListName: undefined,
    };

    this.onChangeNewListName = this.onChangeNewListName.bind(this);
    this.createNewList = this.createNewList.bind(this);
    this.getDivStyle = this.getDivStyle.bind(this);
  }

  onChangeNewListName(event) {
    this.setState({
      newListName: event.target.value
    });
  }

  createNewList() {
    const customerId = this.props.selectedCustomer.ID;
    this.props.postNewList(customerId, this.state.newListName);
  }

  getDivStyle(borderColor) {
    return {
      border: 'solid 1px ' + borderColor,
      borderRadius: '5px',
      margin: '3px'
    };
  }

  handleOnHide = () => {
    this.props.selectList({});
    this.props.closeFunction();
  }

  render() {
    const { open, lists, listUsers, customerUsersList, selectedList,
      selectList, handleUserClick, handleUserAttributeChange, updateList } = this.props;
    const { isLoading, options, value } = this.state;

    return (
      <AppModal
        closeIcon
        open={open}
        onClose={this.handleOnHide}
        title={this.context.t('Manage Distribution Lists')}
        content={(
            <div style={{display: 'inline-flex'}}>

                <div style={this.getDivStyle('cadetblue')}>
                  <div style={{ width: 'fit-content' }}>
                    <input
                      type="text"
                      placeholder={this.context.t('New List Name')}
                      value={this.state.newListName}
                      onChange={this.onChangeNewListName}/>
                    <button onClick={this.createNewList}>{this.context.t('Create')}</button>
                  </div>
                  <CustomerDistributionListsTable
                    items={lists}
                    selectedList={selectedList}
                    selectLisHandler={selectList}
                    selectedCustomer={this.props.selectedCustomer}
                    setDistributionListAsAnnouncesStatusChange={this.props.setDistributionListAsAnnouncesStatusChange}
                  />
                </div>

                <div style={this.getDivStyle('cornflowerblue')}>
                  <CustomerDistributionUsersTable
                    items={listUsers}
                    onUserClick={handleUserClick}
                    onUserAttrChange={handleUserAttributeChange}
                  />
                </div>

                <div style={this.getDivStyle('lightskyblue')}>
                  <CustomerDistributionAllCustomerUsersTable
                    items={customerUsersList}
                    onUserClick={handleUserClick}
                    isDisabled={selectedList.ID == undefined}
                    user={this.props.currentUser}
                  />
                </div>
              </div>
        )}
        actions={(
          <input
            type='button'
            className='btn btn-info'
            disabled={selectedList.ID == undefined}
            value={this.context.t('Save Changes')}
            onClick={updateList} />
        )}
      />
    );
  }
}

CustomerDistributionListsModal.contextTypes = {
  t: PropTypes.func.isRequired
};

function mapStateToProps(state, ownProps) {
  // find only users of the same customer:
  const currentUser = state.leaksList.user;
  const selectedCustomer = state.manage.selectedManageItem.item;

  const lists = Object.assign([], state.distributionLists.lists);
  const selectedList = Object.assign({}, state.distributionLists.selectedList);
  // const customerUsers = [...state.distributionLists.customerUsers];
  const currentUsers = [...state.management.users];
  let allUsers = currentUsers;
  if (currentUser.isAQS) {
    allUsers = currentUsers.filter((user) => user.CustomerID === currentUser.customerId || user.CustomerID === selectedCustomer.ID);
  }
  const listUsers = Object.assign([], state.distributionLists.usersInEdit);

  const customerUsersList = allUsers.filter((user) => {
    const includeUser = listUsers.find((userItr) => userItr.UserID === user.UID);
    return includeUser == null;
  });

  listUsers.forEach((user) => {
    const userDetails = allUsers.find((itrUser) => (itrUser.UID == user.UserID));
    if (userDetails != null) {
      user.FirstName = userDetails.FirstName;
      user.LastName = userDetails.LastName;
    }
  });

  return {
    ...ownProps,
    lists,
    selectedList,
    listUsers,
    customerUsersList,
    selectedCustomer,
    currentUser,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    selectList: (list) => {
      dispatch(actions.selectList(list));
    },
    postNewList: (customerId, name) => {
      dispatch(actions.postNewDistributionList(customerId, name));
    },
    handleUserClick: (user, action) => {
      switch (action) {
        case 'add':
          dispatch(actions.addUserToList(user.UID));
          break;

        case 'remove':
          dispatch(actions.removeUserFromList(user.UserID));
          break;

        default:
          break;
      }
    },
    handleUserAttributeChange: (user, type, value) => {
      dispatch(actions.setUserAttribute(user.UserID, type, value));
    },
    updateList: () => {
      const callback = (status) => {
        const message = status ? 'Changes saved' : 'Changes Not Saved';
        const level = status ? 'success' : 'error';
        ownProps.notify(message, level);
      };

      dispatch(actions.updateUsersList(callback));
    },

    setDistributionListAsAnnouncesStatusChange: (customerId, listObj, checked) => {
      dispatch(actions.setListAnnouncesStatusChange(customerId, listObj.ID, checked, (isSuccess) => {
        const notifyMessage = isSuccess ? 'Change saved' : 'Faild';
        const level = isSuccess ? 'success' : 'error';
        ownProps.notify(notifyMessage, level);
      }));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerDistributionListsModal);
