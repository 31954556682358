/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import InputField from './InputField';

import Alert from '@mui/lab/Alert';

import auth from '../../services/appAuth';
import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';

const PASSWORD_MIN_LENGTH = 12;

function validPasswordLength(password) {
  const pattern = /^(?=.{12})/;
  return pattern.test(password);
}
function validPasswordLowercase(password) {
  const pattern = /^(?=.*[a-z])/;
  return pattern.test(password);
}
function validPasswordUppercase(password) {
  const pattern = /^(?=.*[A-Z])/;
  return pattern.test(password);
}
function validPasswordContainNumber(password) {
  const pattern = /^(?=.*[0-9])/;
  return pattern.test(password);
}

export default function ResetPassword(props, context) {
  const [error, setError] = React.useState(null);
  const [stage, setStage] = React.useState('send-mail');
  const [authResponse, setAuthResponse] = React.useState({});
  const [email, setEmail] = React.useState('');
  const [code, setCode] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [passwordAgain, setPasswordAgain] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(null);
  const [passwordAgainError, setPasswordAgainError] = React.useState(null);

  function handleInputChange(setter) {
    return (event) => {
      setter(event.target.value);
    };
  }

  React.useEffect(() => {
    let errorMessage = null;
    if (!validPasswordLength(password)) {
      errorMessage = context.t('At least {length} characters are required', {length: PASSWORD_MIN_LENGTH});
    } else if (!validPasswordLowercase(password)) {
      errorMessage = context.t('Require Lowercase Letters');
    } else if (!validPasswordUppercase(password)) {
      errorMessage = context.t('Require Uppercase Letters');
    } else if (!validPasswordContainNumber(password)) {
      errorMessage = context.t('Require numbers');
    }
    setPasswordError(errorMessage);

    if (passwordAgain.length > 0) {
      checkSamePasswordValue();
    }
  }, [password]);

  React.useEffect(() => {
    checkSamePasswordValue();
  }, [passwordAgain]);

  function checkSamePasswordValue() {
    const error = (password !== passwordAgain) ? context.t('The passwords are not the same') : null;
    setPasswordAgainError(error);
  }

  function handleButtonClick() {
    setError(null);

    if (stage === 'send-mail') {
      props.setLoading(true);
      auth.sendPasswordResetEmail(email)
      .then(() => {
        setAuthResponse({ Destination: email });
        if (ENV[ENVIRONMENTS] === ENVIRONMENTS.SINGAPORE) {
          setStage('change-password');
        } else {
          props.backTo('signIn')
        }
      })
      .catch((err) => setError(err))
      .finally(() => props.setLoading(false));
    }

    if (stage === 'change-password') {
      if (
        !validPasswordLength(password) ||
        !validPasswordLowercase(password) ||
        !validPasswordUppercase(password) ||
        !validPasswordUppercase(password)
      ) {
        setError({message: context.t('Invalid password')});
      } else if (password !== passwordAgain) {
        setError({message: context.t('The passwords are not the same')});
      } else {
        props.setLoading(true);
        auth.setNewPassword(email, code, password)
        .then((data) => {
          console.log(data);
          props.signInFunc(email, password);
        })
        .catch((err) => {
          props.setLoading(false);
          setError(err);
        });
      }
    }
  }

  return (
    <div>
      { error && <Alert style={{position: 'absolute', top: '-35%', fontSize: 'large'}} severity="error">{error.message}</Alert>}

      <a href='#' onClick={() => props.backTo('signIn')}>{context.t('Back to sign in page')}</a>
      {stage === 'send-mail' &&
        <SendEmail
          email={email}
          handleInputChange={handleInputChange(setEmail)}
          loading={props.loading}
          handleButtonClick={handleButtonClick}
        />
      }
      { (stage === 'change-password' && authResponse.Destination) &&
        <ChangePassword
          authResponse={authResponse}
          code={code}
          password={password}
          passwordError={passwordError}
          passwordAgain={passwordAgain}
          passwordAgainError={passwordAgainError}
          handleCodeChange={handleInputChange(setCode)}
          handlePasswordChange={handleInputChange(setPassword)}
          handlePasswordAgainChange={handleInputChange(setPasswordAgain)}
          handleButtonClick={handleButtonClick}
          loading={props.loading}
        />
      }
    </div>
  );
}

ResetPassword.contextTypes = {
  t: PropTypes.func.isRequired
};

ResetPassword.propTypes = {
  backTo: PropTypes.func,
  loading: PropTypes.bool,
  setLoading: PropTypes.func.isRequired,
  signInFunc: PropTypes.func.isRequired
};

function SendEmail(props, context) {
  return (
    <div>
      <h2>{context.t('Forgot your password?')}</h2>
      <p>{context.t('Enter your Email below and we will send a message to reset your password')}</p>
      <InputField
        label={context.t('Email')}
        name='email'
        type='email'
        value={props.email}
        onChange={props.handleInputChange}
        disabled={props.loading}
      />

      <div className="form-group form-actions">
        <div className="col-xs-6 col-xs-offset-3 login-field">
          <button
            className="login-submit btn btn-success btn-block"
            type="submit"
            onClick={props.handleButtonClick}>
            {context.t('Reset my password')}
          </button>
        </div>
      </div>
    </div>
  );
}
SendEmail.contextTypes = {
  t: PropTypes.func.isRequired
};
SendEmail.propTypes = {
  email: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleInputChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

function ChangePassword(props, context) {
  return (
    <div>
      <p>{context.t('We have sent a password reset code by Email to {mail}. Enter it below to reset your password.', { mail: props.authResponse.Destination })}</p>
      <InputField
        label={context.t('Code')}
        name='code'
        type='text'
        value={props.code}
        onChange={props.handleCodeChange}
        disabled={props.loading}
      />
      <InputField
        label={context.t('New Password')}
        name='newPassword'
        type='password'
        value={props.password}
        onChange={props.handlePasswordChange}
        disabled={props.loading}
        error={props.passwordError}
      />
      <InputField
        label={context.t('Enter New Password Again')}
        name='newPasswordAgain'
        type='password'
        value={props.passwordAgain}
        onChange={props.handlePasswordAgainChange}
        disabled={props.loading}
        error={props.passwordAgainError}
      />

      <div className="form-group form-actions">
        <div className="col-xs-6 col-xs-offset-3 login-field">
          <button
            className="login-submit btn btn-success btn-block"
            type="submit"
            onClick={props.handleButtonClick}>
            {context.t('Change Password')}
          </button>
        </div>
      </div>
    </div>
  );
}
ChangePassword.contextTypes = {
  t: PropTypes.func.isRequired
};
ChangePassword.propTypes = {
  authResponse: PropTypes.object.isRequired,
  code: PropTypes.string.isRequired,
  handleButtonClick: PropTypes.func.isRequired,
  handleCodeChange: PropTypes.func.isRequired,
  handlePasswordAgainChange: PropTypes.func.isRequired,
  handlePasswordChange: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired,
  passwordAgain: PropTypes.string.isRequired,
  passwordAgainError: PropTypes.string.isRequired,
  passwordError: PropTypes.string.isRequired,
};
