import React from 'react';
import { zIndexs, featuresTypes } from './Options';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer} from 'ol/layer';
import { Vector as VectorSource} from 'ol/source';
import Point from 'ol/geom/Point';
const proj = require('ol/proj');

const classNames = require('classnames');

// Alerts layer style
const style = new Style({
    image: new Icon({
        // See dynamic styles in getAlertStyle()
        src: require('../../../../images/map/sensors/indications/indication-transient-pressure.png'),
        opacity: 0.5,
        scale: 0.5,
        anchor: [0.5, 0.5],
  })
});

const source = new VectorSource({
    features: [],
});

// Alerts layer
export const layer = new VectorLayer({
    name: 'layerPressureAlerts',
    style: style,
    source: source,
    zIndex: zIndexs.alerts,
});

export const setSource = (nextIconFeatures, props) => {
    let iconFeatures = [];
    Object.keys(nextIconFeatures).forEach((key) => {
        const itrFeature = nextIconFeatures[key];
        const iconFeature = new Feature({
            geometry: new Point(
                proj.transform(
                    [itrFeature.Longitude, itrFeature.Latitude],
                    'EPSG:4326',
                    'EPSG:3857'
                )
            ),
            type: featuresTypes.PRESSURE_ALERT,
            id: itrFeature.id,
            deviceId: itrFeature.DeviceID,
        });
        iconFeature.setStyle(getStyle(iconFeature));
        iconFeatures.push(iconFeature);
    });
    source.addFeatures(iconFeatures);
};

export const getLayers = () => {
  return [layer];
};

export const hasFeatures = () => {
  return source.getFeatures().length > 0;
};

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected) => {
     let alertStyle = false;

    alertStyle = [
      new Style({
        image: new Icon({
          src: require('../../../../images/map/sensors/indications/indication-transient-pressure.png'),
          opacity: 0.6,
          scale: 0.5,
          anchor: [0.5, 0.5],
        }),
      })
    ];

    if (bSelected) {
      alertStyle.push(new Style({
        image: new Icon({
          src: require('../../../../images/map/sensors/indications/selected-background.png'),
          opacity: 0.75,
          scale: 0.5,
          anchor: [0.5, 0.5],
        }),
      }));
    }

    return alertStyle;
};

export const getInfoTooltip = (feature) => {
    const attr = feature.getProperties();
    let infoTooltip = {
        type: attr.type,
        id: attr.id,
        deviceId: attr.deviceId,
    };
    return (infoTooltip);
};

export const tooltipRender = (itemInfo, context) => {
    const tooltipClasses = classNames(
        'tooltip',
        'priority-' + itemInfo.priority
    );

    return (
        <span className={tooltipClasses}>
            <div className="tooltip-row">{context.t('Alert ID: {id}', {id: itemInfo.id})}</div>
            <div className="tooltip-row">{context.t('Device ID: {device}', {device: itemInfo.deviceId})}</div>
        </span>
    );
};
