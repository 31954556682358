import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../../components';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

import Pagination from 'react-js-pagination';
import { SENSOR_LOG_COUNT_FOR_PAGE } from '../../../constants/Misc';

const _ = require('lodash');

class SensorLogsTab extends React.Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.rowClassNameGetter = this.rowClassNameGetter.bind(this);

    this.state = {
      currentPage: 1,
    }
  }

  onRowClick(event, rowIndex) {
    this.props.selectSensorLog(this.props.sensorsLogs[rowIndex]);
  }

  rowClassNameGetter(rowIndex) {
    const { selectedSensorLog, sensorsLogs } = this.props;
    return (selectedSensorLog && sensorsLogs && sensorsLogs[rowIndex]
         && selectedSensorLog.ID == sensorsLogs[rowIndex].ID) ? 'active-row' : '';
  }

  handlePageClick(pageNumber) {
    this.setState({
      currentPage: pageNumber
    });
    this.props.onPageIndexChange(pageNumber - 1, 'SensorLogsTab');
  }

  render() {
    const { sensorsLogs, sensorsLogsCount, downloadFile, uiInfo, timeZone } = this.props;

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight;

    const data = (sensorsLogs != null && !_.isEmpty(sensorsLogs)) ? sensorsLogs : [];

    return (
      <div>
        <Table
          rowsCount={data.length}
          width={pWidth}
          onRowClick={this.onRowClick}
          rowClassNameGetter={this.rowClassNameGetter}
          maxHeight={pHeight - 117}
          headerHeight={46}
          rowHeight={30}>

          <Column
            columnKey="TimeAnalyze"
            header={<Cell>{this.context.t('Date')}</Cell>}
            cell={<TextCell data={data} field="TimeAnalyze" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
            width={30}
            flexGrow={1}
          />

          <Column
            columnKey="BatteryVoltage"
            header={<Cell>{this.context.t('Battery')}</Cell>}
            width={20}
            flexGrow={1}
            cell={(props) =>
              (<Cell {...props} >
                <div style={{
                  textAlign: 'center',
                  margin: 'auto 5px'}}
                >
                  { (data[props.rowIndex].BatteryVoltagePreRec.toFixed(2) || '?') + ' - ' + (data[props.rowIndex].BatteryVoltage.toFixed(2) || '?') }

                  { data[props.rowIndex].FileName.indexOf('LLauto') != -1 &&
                    <img
                      src={require('../../../../images/map/sensors/indications/indication-resets.png')}
                      style={{
                        height: '17px',
                        width: '17px',
                        position: 'absolute',
                        right: '5px'
                      }}
                    />
                  }
                </div>
              </Cell>)
            } />

          <Column
            columnKey="ModemAtennaSignalStrength"
            header={<Cell>{this.context.t('CSQ')}</Cell>}
            cell={<TextCell data={data} field="ModemAtennaSignalStrength" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="RadioFrequency"
            header={<Cell>{this.context.t('Radio Frequency')}</Cell>}
            cell={<TextCell data={data} field="RadioFrequency" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="FirmwareVersion"
            header={<Cell>{this.context.t('Firmware Version')}</Cell>}
            cell={<TextCell data={data} field="FirmwareVersion" />}
            width={10}
            flexGrow={1} />

          <Column
            columnKey="Download"
            width={10}
            flexGrow={1}
            header={<Cell>{this.context.t('Download')}</Cell>}
            cell={(props) =>
              (<Cell {...props} >
                <a
                  key='Audio'
                  className="btn-wav-download"
                  title={data[props.rowIndex].FileName}
                  onClick={() => {
                    const item = data[props.rowIndex];
                    downloadFile(item.FileName, 'file');
                  }}
                  href='javascript:void(0);'>
                  { (data[props.rowIndex].IsLog == null) ? this.context.t('Download') : (data[props.rowIndex].IsLog ? this.context.t('LOG') : this.context.t('Audio'))}
                </a>
              </Cell>)
            }
          />
        </Table>

        <div className="center">
          <Pagination
            prevPageText={this.context.t('prev')}
            nextPageText={this.context.t('next')}
            firstPageText={this.context.t('first')}
            lastPageText={this.context.t('last')}
            activePage={this.state.currentPage}
            itemsCountPerPage={SENSOR_LOG_COUNT_FOR_PAGE}
            totalItemsCount={sensorsLogsCount || 0}
            pageRangeDisplayed={5}
            onChange={this.handlePageClick}
          />
        </div>
      </div>
    );
  }
}

SensorLogsTab.contextTypes = {
  t: PropTypes.func.isRequired
};

export default SensorLogsTab;
