import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import { Select, Input, OprSelect } from '../../components';
const classNames = require('classnames');

require('./MobileTasksSettings.scss');

const MobileTasksSettings = (props, context) => {

  const {
    handleSubmit,
    pristine,
    submitting,
    uiInfo,
    toggleSettingsDisplay,
    sampleStateOptions,
  } = props;
  const panelClasses = classNames('sensors-settings', 'alerts-settings', {
    show: props.showSettings,
  });

  const panelStyle = {
    height: uiInfo.pageHeight,
    width: 0.395 * (uiInfo.pageWidth - 10),
  };

  return (
    <div className={panelClasses} style={panelStyle}>
      <div className="sensors-header alerts-header">
        <div className="actions">
          <i className="icon-close" onClick={toggleSettingsDisplay} />
        </div>
        <h2>{context.t('Tasks Options')}</h2>
      </div>
      <form className="form-horizontal" onSubmit={handleSubmit}>
        <fieldset>
          <legend>{context.t('Sort by')}</legend>
          <div className="form-group">
            <div className="col-xs-8">
              <Field
                name="Tasks.sortBy"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('Select...')}
                    clearable={false}
                    options={[
                      {
                        value: 'CreationDate',
                        label: context.t('Creation Date (default)'),
                      },
                      {
                        value: 'TaskName',
                        label: context.t('Task Name'),
                      },
                      {
                        value: 'SamplesCount',
                        label: context.t('Samples Count'),
                      },
                    ]}
                  />
                )}
              />
            </div>
            <div className="col-xs-4">
              <Field
                name="Tasks.sortByDir"
                component={(props) => (
                  <Select
                    {...props}
                    placeholderTitle={context.t('Select...')}
                    clearable={false}
                    backspaceRemoves={false}
                    options={[
                      { value: 'asc', label: context.t('ASC') },
                      { value: 'desc', label: context.t('DESC') },
                    ]}
                  />
                )}
              />
            </div>
          </div>
          <legend>{context.t('Tasks Filters')}</legend>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('Creation Date')}
            </label>
            <div className="col-xs-3 control-date-form">
              <Field
                name="Tasks.CreationDate-From"
                component="input"
                type="date"
                className="form-control"
              />
            </div>
            <div className="col-xs-1 control-label"> to</div>
            <div className="col-xs-3 control-date-to">
              <Field
                name="Tasks.CreationDate-To"
                component="input"
                type="date"
                className="form-control"
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('Task Name')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Tasks.TaskName"
                component="input"
                type="text"
                className="form-control"
              />
            </div>
          </div>

          <h2>Samples Options</h2>
          <legend>{context.t('Samples Filters')}</legend>
          <div className="form-group">
            <label className="col-xs-4 control-label">
              {context.t('Sample State')}
            </label>
            <div className="col-xs-6">
              <Field
                name="Samples.sample_state"
                className="form-control"
                component={(props) => (
                  <Select
                    multi
                    placeholderTitle={context.t('Select...')}
                    clearable={false}
                    options={sampleStateOptions}
                    {...props}
                  />
                )}
              />
            </div>
          </div>
          <div className="text-right">
            <button
              className="btn btn-success"
              type="submit"
              disabled={pristine || submitting}
              onClick={toggleSettingsDisplay}
            >
              {context.t('Save & Apply')}
            </button>
          </div>
        </fieldset>
      </form>
    </div>
  );
}

MobileTasksSettings.contextTypes = {
  t: PropTypes.func.isRequired,
};

// Decorate the form component
const mobileTasksSettings = reduxForm({
  form: 'mobile-tasks-settings', // a unique name for this form
  enableReinitialize: true,
})(MobileTasksSettings);

export default mobileTasksSettings;
