import React from 'react';
// import ReactDOM from 'react-dom';

import Root from './client/assets/javascripts/app/Root';

// const appComponent = <Root/>;

// ReactDOM.render(appComponent, document.getElementById('app'));


import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Root/>);
