import React from 'react';
import PropTypes from 'prop-types';

const env = require('../../constants/EndPoints');

export default class ImageGraph extends React.Component {

  render() {
    const { titleName, titleUnits, graphClassName, src } = this.props;
    const noDataImg = require('./../../../images/system/' + env.SYSTEM_RESOURCE + '/GraphNotAvailable.png');

    return (
      <div className="graph-div">
        <div style={{ display: 'inline-flex' }}>
          <p>{titleName}</p>
          <span style={{ marginLeft: '10px' }}>{titleUnits}</span>
        </div>
        <img
          className={graphClassName}
          src={src || noDataImg} />
      </div>
    );
  }
}
