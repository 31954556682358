// import { assign } from 'lodash';
import * as types from '../constants/ActionTypes';
const initialState = {
  address: null,
  newManualAlert: null,
  noiseIdForNewAlert: null,
  newManualNoiseAlert: null,
  pcaOpen: false,
  pcaLeakGrowthOpen: false,
  isSetAsFixed: false,
  statusOpen: false,
  leaksIds: null,
  id: null,
  stateName: null,
  value: null,
  prevState: null,
  nextState: null,
  rowIndex: null,
  field: null,
  alertType: null,
  tempPCA: {},
  actualLeakPosition: {
    isOpen: false,
  }
};

const temp = (state = Object.assign({}, initialState, { pcaOpen: false }), action) => {
  switch (action.type) {
    case types.SAVE_TEMP_ADDRESS_FOR_ITEM:
      return Object.assign({}, state, {
        featureType: action.selectedFeatureType,
        featureId: action.selectedFeatureID,
        address: action.address,
      });

      // clean on select another item:
    case types.SELECT_LEAK:
      return Object.assign({}, initialState);

    case types.SET_ADDING_MANUAL_ALERTS_MODE:
      return Object.assign({}, state, {
        newManualAlert: action.bIsInMode ? {} : null,
        noiseIdForNewAlert: action.noiseId || null,
      });

    case types.SET_ADDING_MANUAL_NOISE_ALERTS_MODE:
      return Object.assign({}, state, {
        newManualNoiseAlert: action.bIsInMode ? {} : null
      });

    case types.SELECT_SENSOR_FOR_NEW_NOISE_ALERT:
      return { ...state, newManualNoiseAlert: { sensorId: action.payload } };

    case types.SET_PCA_OPEN:
      return Object.assign({}, state, {
        pcaOpen: action.bIsOpen,
        isSetAsFixed: action.bIsSetAsFixed
      });

    case types.SET_PCA_LEAK_GROWTH_OPEN:
      return Object.assign({}, state, {
        pcaLeakGrowthOpen: action.bIsOpen,
        id: action.id
      });

    case types.CLEAR_TEMP_PCA:
      return Object.assign({}, state, {
        tempPCA: {}
      });

    case types.SET_STATUS_OPEN:
      return Object.assign({}, state, {
        statusOpen: action.bIsOpen,
        id: action.id,
        stateName: action.stateName,
        value: action.value,
        prevState: action.prevState,
        nextState: action.nextState,
        rowIndex: action.rowIndex,
        field: action.field
      });

    case types.SET_ALERT_TYPE:
      return Object.assign({}, state, {
        alertType: action.value
      });

    case types.CLEAR_TEMP_STATUS:
      return Object.assign({}, state, {
        tempSTATUS: {}
      });

    case types.RECEIVE_PCA_DATA_FOR_ALERT:
      return Object.assign({}, state, {
        tempPCA: {
          id: action.alertId,
          data: action.data,
        }
      });

    case types.SET_ACTUAL_LEAK_POSITION_MODE:
      return Object.assign({}, state, {
        actualLeakPosition: {
          isOpen: action.isOpen
        }
      });
    default:
      return state;
  }
};

export default temp;
