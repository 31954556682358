import React from 'react';
import PropTypes from 'prop-types';

import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';

import { featuresTypes } from '../Map/Layers/Options';

import { PIPES_DISPLAY_TYPE } from '../../constants/SettingsKeys';
import { COVERAGE_DISPLAY_TYPE } from '../../constants/SettingsKeys';

const classNames = require('classnames');

require('./LayerManager.scss');

const LayerManager = (props, context) => {

  const { layers, pipesSource, pipesSourceChange, viewWaterMeterLayer, noiseSamplesColorAttr, mobileNoiseSamplesColorAttr } = props;

  const [showManager, setShowManager] = React.useState(false)

  const onLayerChange = (event, layerName) => {
    props.layersHandler(event, layerName);
    props.onLayerChange(event, layerName);
  }

  const panelClasses = classNames('layer-manager', {
    show: showManager,
  });

  return (
    <div className={panelClasses}>
      <div className="actions">
        <i className="icon-toggle" onClick={() => setShowManager(!showManager)} />
      </div>
      <h2>{context.t('Layer Manager')}</h2>
      <form>
        <LayerChecbox
          id="form-satellite"
          name="satellite"
          checked={layers.satelliteLayer}
          onChange={(e) => onLayerChange(e, 'satelliteLayer')}
          label={context.t('Satellite View')}
        />
        <LayerChecbox
          id="form-leaks"
          name="leaks"
          checked={layers[featuresTypes.ALERT]}
          onChange={(e) => onLayerChange(e, featuresTypes.ALERT)}
          label={context.t('Show Alerts')}
        />
        <LayerSelect
          id="form-noise-samples"
          name="noiseSamples"
          checked={layers[featuresTypes.NOISE]}
          onChange={(e) => onLayerChange(e, featuresTypes.NOISE)}
          selection={noiseSamplesColorAttr}
          onSelect={(e) => {
            const value = e.target.value;
            props.noiseSamplesSourceChange(e, value);
          }}
          options={[
            { value: "Nc1", label: context.t('Minimal Noise Intensity') },
            { value: "Nc3", label: context.t('Filtered Noise Intensity') },
          ]}
        />
        {ENV !== ENVIRONMENTS.SINGAPORE &&
          <LayerChecbox
            id="form-mobile-noise-samples"
            name="mobileNoiseSamples"
            checked={layers[featuresTypes.MOBILE_SAMPLES]}
            onChange={(e) => onLayerChange(e, featuresTypes.MOBILE_SAMPLES)}
            label={context.t('IQ100B Noise Intensity')}
          />
          /*
          <LayerSelect
            id="form-mobile-noise-samples"
            name="mobileNoiseSamples"
            checked={layers[featuresTypes.MOBILE_SAMPLES]}
            onChange={(e) => onLayerChange(e, featuresTypes.MOBILE_SAMPLES)}
            selection={mobileNoiseSamplesColorAttr}
            onSelect={(e) => {
              const value = e.target.value;
              props.mobileNoiseSamplesSourceChange(e, value);
            }}
            options={[
              { value: "engine_intensity_f1", label: context.t('F1 Noise Intensity NC3F1') },
              { value: "engine_intensity_f2", label: context.t('F2 Noise Intensity NC3F2') },
              { value: "engine_intensity_f3", label: context.t('F3 Noise Intensity NC3F3') },
              { value: "engine_nc3", label: context.t('Filtered Noise Intensity NC3') },
            ]}
          />
        */
        }

        {ENV !== ENVIRONMENTS.SINGAPORE && (
          <LayerChecbox
            id="form-iQuarius"
            name="iQuarius"
            checked={layers[featuresTypes.IQUARIUS_PUBLISHED_SAMPLES]}
            onChange={(e) =>
              onLayerChange(
                e,
                featuresTypes.IQUARIUS_PUBLISHED_SAMPLES
              )
            }
            label={context.t('Show iQuarius Samples')}
          />
        )}
        <LayerChecbox
          id="form-sensors"
          name="sensors"
          checked={layers[featuresTypes.SENSOR]}
          onChange={(e) => onLayerChange(e, featuresTypes.SENSOR)}
          label={context.t('Show Sensors')}
        />
        <LayerChecbox
          id="form-sops"
          name="sops"
          checked={layers[featuresTypes.SOP]}
          onChange={(e) => onLayerChange(e, featuresTypes.SOP)}
          label={context.t('Show Installation Points')}
        />
        <LayerChecbox
          id="form-interferences"
          name={featuresTypes.INTERFERENCE}
          checked={layers[featuresTypes.INTERFERENCE]}
          onChange={(e) => onLayerChange(e, featuresTypes.INTERFERENCE)}
          label={context.t('Show Areas Of Interest')}
        />
        <LayerSelect
          id="form-pipes"
          name="pipes"
          checked={layers[featuresTypes.PIPES]}
          onChange={(e) => onLayerChange(e, featuresTypes.PIPES)}
          selection={pipesSource}
          onSelect={(e) => {
            const value = e.target.value;
            pipesSourceChange(e, value);
            localStorage.setItem(PIPES_DISPLAY_TYPE, value);
          }}
          options={[
            { value: "materials", label: context.t('Pipes By Material') },
            { value: "presure", label: context.t('Pipes By Presure') },
            { value: "diameter", label: context.t('Pipes By Diameter') },
            { value: "year", label: context.t('Pipes By Placement Year') },
          ]}
        />

        <LayerChecbox
          id="form-couples-coverage"
          name="couplesCoverage"
          checked={layers[featuresTypes.COUPLES_COVERAGE]}
          onChange={(e) => onLayerChange(e, featuresTypes.COUPLES_COVERAGE)}
          label={context.t('Couple Coverage')}
        />

        {false && (
          <LayerChecbox
            id="form-coupels-path"
            name="couplesPath"
            checked={layers.couplePathLayer}
            onChange={(e) => onLayerChange(e, 'couplePathLayer')}
            label={context.t('Show Couples Path')}
          />
        )}
        {false && (
          <LayerChecbox
            id="form-couples-coverage"
            name="couplesCoverage"
            checked={layers.couplesCoverageLayer}
            onChange={(e) => onLayerChange(e, 'couplesCoverageLayer')}
            label={context.t('Couples Coverage')}
          />
        )}
        {false && (
          <LayerChecbox
            id="form-project-boundaries"
            name="projectBoundaries"
            checked={layers.projectBoundariesLayer}
            onChange={(e) => onLayerChange(e, 'projectBoundariesLayer')}
            label={context.t('Project Boundaries')}
          />
        )}
        {ENV !== ENVIRONMENTS.SINGAPORE && viewWaterMeterLayer && (
          <LayerChecbox
            id="form-water-meter"
            name={featuresTypes.WATER_METER}
            checked={layers[featuresTypes.WATER_METER]}
            onChange={(e) => onLayerChange(e, featuresTypes.WATER_METER)}
            label={context.t('Show Water Meter Layer')}
          />
        )}
        <LayerChecbox
          id="form-projects-coverage"
          name={featuresTypes.PROJECTS_COVERAGE}
          checked={layers[featuresTypes.PROJECTS_COVERAGE]}
          onChange={(e) => onLayerChange(e, featuresTypes.PROJECTS_COVERAGE)}
          label={context.t('Projects Coverage')}
        />
        {ENV !== ENVIRONMENTS.SINGAPORE && (
          <LayerChecbox
            id="form-dmas"
            name={featuresTypes.DMAS}
            checked={layers[featuresTypes.DMAS]}
            onChange={(e) => onLayerChange(e, featuresTypes.DMAS)}
            label={context.t('DMAs Layer')}
          />
        )}
      </form>
    </div>
  );
}

LayerManager.contextTypes = {
  t: PropTypes.func.isRequired,
};

const LayerChecbox = ({ id, name, checked, onChange, label }) => (
  <div className="form-group">
    <input
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <label htmlFor={id}>{label}</label>
  </div>
)

const LayerSelect = ({ id, name, checked, onChange, selection, onSelect, options }) => (
  <div className="form-group">
    <input
      id={id}
      name={name}
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <select
      style={{ marginLeft: '5px' }}
      onChange={onSelect}
      value={selection}
    >
      {options.map(({ value, label }) => (
        <option key={value} value={value}>{label}</option>
      ))}
    </select>
  </div>
)

export default LayerManager;
