import React from 'react';
import PropTypes from 'prop-types';

import { Cell } from 'fixed-data-table-2';

class InputCell extends React.Component {

  constructor(props) {
    super(props);
    this.init = false;
    this.handleCommentChange = this.handleCommentChange.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {

    if (!this.init) {
      this.init = true;
      return true;
    }
    if (nextProps.selectedProject != this.props.selectedProject) {
      return true;
    }
    else {
      if (nextProps.data[nextProps.rowIndex][nextProps.field] !== this.props.data[this.props.rowIndex][this.props.field]) {
        return true;
      }
    }
    return false;
  }

  handleCommentChange = function(e) {
    const {value} = e.target;
    const {rowIndex, field, onCommentChange, selectedProject} = this.props;
    onCommentChange(rowIndex, field, value, selectedProject);
  };
  render () {
    const {rowIndex, field, onCommentChange, data, selectedProject} = this.props;
    return (
      <Cell className="input-cell">
        <input type="text"
               value={data[rowIndex][field] || ''}
               onChange ={this.handleCommentChange}/>
      </Cell>
    );
  }

}

InputCell.propTypes = {
  //@todo: add alerts prop.
  onCommentChange: PropTypes.func.isRequired,
};

export default InputCell
