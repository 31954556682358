/* eslint-disable react/no-multi-comp */
/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import UIDateFormater, { DateType } from '../../containers/UIhelper/UIDateFormater';
import SpectrogramGraph from './SpectrogramGraph';
import SensorsNoiseGraph from './SensorsNoiseGraph';
import BIAS from './BIAS';

function PreviousGraps(props, context) {
  const getTitle = (index) => {
    const relatedItem = props.historyNoiseData[index];
    let strValue = '--';
    if (relatedItem != null) {
      const date = UIDateFormater(new Date(relatedItem.sample_time).getTime(), DateType.SHORT_DATE, props.timeZone);
      const sensor1Nc1 = relatedItem.nc1_1;
      const sensor1Nc2 = relatedItem.nc2_1;
      const sensor2Nc1 = relatedItem.nc1_2;
      const sensor2Nc2 = relatedItem.nc2_2;

      strValue = `#${index} ${date}, Nc1/Nc2: ${sensor1Nc1}/${sensor1Nc2}, ${sensor2Nc1}/${sensor2Nc2}`;
    }
    return strValue;
  };

  const getHistoryParams = (index) => {
    const relatedItem = props.historyNoiseData[index];
    const data = {
      date: '--',
      s1Nc1: '--',
      s1Nc2: '--',
      s2Nc1: '--',
      s2Nc2: '--',
    };
    if (relatedItem != null) {
      data.date = UIDateFormater(new Date(relatedItem.sample_time).getTime(), DateType.SHORT_DATE, props.timeZone);
      data.s1Nc1 = relatedItem.nc1_1;
      data.s2Nc1 = relatedItem.nc1_2;
      data.s1Nc2 = relatedItem.nc2_1;
      data.s2Nc2 = relatedItem.nc2_2;
    }
    return data;
  };

  const Cell = (props) => (
    <TableCell
      style={{ color: 'white' }}
      align='center'
      {...props}
    >
      {props.value}
    </TableCell>
  );

  return (
    <div>
      <div className='history-graphs'>
        {props.displayType === 'instance' &&
          <SensorsNoiseGraph
            title={context.t('Noise NC1/NC2')}
            titleY={context.t('Intensity')}
            titleX={context.t('Date')}
            historyNoiseData={props.historyNoiseData}
            timeZone={props.timeZone}
            small
          />
        }

        <SpectrogramGraph
          graphTitle={context.t('Average Last')}
          titleSize={11}
          data={props.histogramAve}
          xValues={{
            start: props.graphParams.freq.start,
            step: props.graphParams.freq.step
          }}
          yAxisLabels={props.graphsDistancesForAxis}
          xTitle={context.t('Frequency')}
          yTitle={context.t('Distance')}
          small
        />

        {props.bias && props.bias[0] &&
          <BIAS
            title={'bias'}
            data={props.bias}
            xTitle={context.t('Bands')}
            yTitle={context.t('Days')}
            small
            hoverMode
          />
        }
        {props.historyData && props.historyData.map((itrData, index) => {
          let date = '';
          if (props.historyNoiseData[index]) {
            date = UIDateFormater(new Date(props.historyNoiseData[index].sample_time).getTime(), DateType.DATE_AND_TIME_WITH_SECONDS, props.timeZone);
          }
          const params = getHistoryParams(index);
          const tooltipContent = (
            <Box>
              <Typography variant='h4' component='h4' align='center'>{date}</Typography>
              <Divider style={{ background: 'white', opacity: 0.4 }} variant='middle' light />
              <Table aria-label="sensors nc table" size='small'>
                <TableHead>
                  <TableRow>
                    <Cell />
                    <Cell value='Sensor 1' />
                    <Cell value='Sensor 2' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <Cell component="th" scope="row" value='Nc1' />
                    <Cell value={params.s1Nc1} />
                    <Cell value={params.s2Nc1} />
                  </TableRow>
                  <TableRow>
                    <Cell component="th" scope="row" value='Nc2' />
                    <Cell value={params.s1Nc2} />
                    <Cell value={params.s2Nc2} />
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          );

          return (
            <Tooltip
              key={index}
              className='prev-graphs-with-tooltip'
              placement='top'
              enterDelay={1000}
              title={tooltipContent}
            >
              <div>
                <SpectrogramGraph
                  graphTitle={getTitle(index)}
                  titleSize={11}
                  data={itrData}
                  xValues={{
                    start: props.graphParams.freq.start,
                    step: props.graphParams.freq.step
                  }}
                  yAxisLabels={props.graphsDistancesForAxis}
                  xTitle={context.t('Frequency')}
                  yTitle={context.t('Distance')}
                  small
                />
              </div>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
}

PreviousGraps.contextTypes = {
  t: PropTypes.func.isRequired
};

export default PreviousGraps;
