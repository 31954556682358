import { getDistanceFromLatLonInMeter } from './geoFunctions';

function sqr(x) { return x * x }
function dist(v, w) { return getDistanceFromLatLonInMeter(v.x, v.y, w.x, w.y) }
function dist2(v, w) { return sqr(v.x - w.x) + sqr(v.y - w.y) }
function distToSegment(p, v, w) {
    var l2 = dist(v, w);
    if (l2 == 0) return dist2(p, v);
    var t = ((p.x - v.x) * (w.x - v.x) + (p.y - v.y) * (w.y - v.y)) / l2;
    t = Math.max(0, Math.min(1, t));
    return dist(p, {
        x: v.x + t * (w.x - v.x),
        y: v.y + t * (w.y - v.y)
    });
}


/**
 * Created by User on 13/07/2016.
 *
 */
export function PipeGraph(acPipeLine, cStart, cEnd, m_nFactor) {

    this.pipeIDToPipe = [];

    this.m_acLocation = [];

    this.m_acLocation.push(cStart);
    this.m_acLocation.push(cEnd);

    this.AirDis = getDistanceFromLatLonInMeter(cStart[1][0], cStart[1][1], cEnd[1][0], cEnd[1][1]);

    this.add = function (node) {
        var nRc = 0;

        var dis = getDistanceFromLatLonInMeter(node[1][0], node[1][1], cEnd[1][0], cEnd[1][1]);

        if (dis < m_nFactor * this.AirDis) {
            this.m_acLocation.push(node);
        }

        return nRc;
    }

    for (var i = 0; i < acPipeLine.length; i++) {
        var cPipeLine = acPipeLine[i];
        if ((cPipeLine.X1 != cPipeLine.X2 || cPipeLine.Y1 != cPipeLine.Y2) &&
                cPipeLine.EndDate == null) {

            if (distToSegment({ x: cEnd[1][0], y: cEnd[1][1] },
                { x: cPipeLine.X1, y: cPipeLine.Y1 }, { x: cPipeLine.X2, y: cPipeLine.Y2 }) > m_nFactor *  this.AirDis) {
                continue;
            }

            var nDis = getDistanceFromLatLonInMeter(cPipeLine.X1, cPipeLine.Y1, cPipeLine.X2, cPipeLine.Y2);
            var parts = (nDis / 6.5) | 0;

            this.pipeIDToPipe[cPipeLine.PipeID] = cPipeLine;

            this.add([cPipeLine.PipeID, [cPipeLine.X1, cPipeLine.Y1], 0, cPipeLine.Material]);

            for (var j = 1; j < parts - 1; j++) {
                var nLng = (cPipeLine.X2 * j + cPipeLine.X1 * (parts - j)) / parts;
                var nLat = (cPipeLine.Y2 * j + cPipeLine.Y1 * (parts - j)) / parts;

                this.add([cPipeLine.PipeID, [nLng, nLat], 1.0 * j / parts, cPipeLine.Material]);
            }

            this.add([cPipeLine.PipeID, [cPipeLine.X2, cPipeLine.Y2], 1, cPipeLine.Material]);
        }
    }

    this.edgesFrom = function (value) {
        var acEdges = [];

        for (var i = 0; i < this.m_acLocation.length; i++) {
            var cPoint = this.m_acLocation[i];

            if (value[0] - cPoint[0] != 0) {
                var nDis = getDistanceFromLatLonInMeter(cPoint[1][0], cPoint[1][1], value[1][0], value[1][1]);
                acEdges.push([cPoint, nDis * m_nFactor]);
            }
            else if (cPoint[1][0] != value[1][0] ||
                    cPoint[1][1] != value[1][1]) {
                var nDis = getDistanceFromLatLonInMeter(cPoint[1][0], cPoint[1][1], value[1][0], value[1][1]);
                acEdges.push([cPoint, nDis + 0.0001]);
            }
        }

        return acEdges;
    }

    this.CreatePath = function (paths, insertPoint) {
        var res = [];

        for (var i = 1; i < paths.length - 1; i++) {
            var path = paths[i];

            var pipe = this.pipeIDToPipe[path[0]];

            if (pipe.X1 == path[1][0] && pipe.Y1 == path[1][1]) {
                if (res.indexOf(pipe.ID1) == -1) {
                    res.push(pipe.ID1);
                }
            }
            else if (pipe.X2 == path[1][0] && pipe.Y2 == path[1][1]) {
                if (res.indexOf(pipe.ID2) == -1) {
                    res.push(pipe.ID2);
                }
            }
            else {
                res.push(insertPoint(path));
            }

        }

        return res;
    }
}
