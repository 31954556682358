import React from 'react';
import PropTypes from 'prop-types';
import { Table, Column, Cell } from 'fixed-data-table-2';

const columnWidth = 120;

export default class CustomerDistributionAllCustomerUsersTable extends React.Component {

  constructor(props) {
    super(props);

    this.renderCell = this.renderCell.bind(this);
    this.renderCellButton = this.renderCellButton.bind(this);
    this.getClassName = this.getClassName.bind(this);

    this.state = {
      selectedList: undefined
    }
  }

  getClassName(rowIndex) {
    let className = '';
    if (this.state.selectedList) {
      if (this.state.selectedList.index == rowIndex) {
        className = 'active-row';
      }
    }
    return className;
  }

  renderCell(data) {
    const { items } = this.props;
    const item = items[data.rowIndex];

    return (
      <Cell>{item[data.columnKey]}</Cell>
    );
  }

  renderCellButton(data) {
    return (
      <button
        onClick={() => {
          const { items } = this.props;
          const user = items[data.rowIndex];
          this.props.onUserClick(user, 'add');
        }
      }
      disabled={this.props.isDisabled}
      >+</button>
    )
  }

  render() {
    const { items } = this.props;
    const tableWidth = this.props.user.isAQS ? columnWidth * 5 : columnWidth * 4;

    return (
      <Table
        maxHeight={500}
        width={tableWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        rowClassNameGetter={() => 'choose-user'}
      >
        <Column
          width={columnWidth / 2}
          cell={this.renderCellButton}/>

        <Column
          columnKey="FirstName"
          width={columnWidth}
          header={<Cell>{this.context.t('First Name')}</Cell>}
          cell={this.renderCell}/>

        <Column
          columnKey="LastName"
          width={columnWidth}
          header={<Cell>{this.context.t('Last Name')}</Cell>}
          cell={this.renderCell}/>

        <Column
          columnKey="PhoneNumber"
          width={(columnWidth * 1.5) - 25}
          header={<Cell>{this.context.t('Phone')}</Cell>}
          cell={this.renderCell}/>
        { this.props.user.isAQS &&
          <Column
            columnKey="CustomerName"
            width={columnWidth}
            header={<Cell>{this.context.t('Customer')}</Cell>}
            cell={this.renderCell}/>
        }
      </Table>
    );
  }
}

CustomerDistributionAllCustomerUsersTable.contextTypes = {
  t: PropTypes.func.isRequired
}
