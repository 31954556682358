import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import { Input, Select, SensorDetailsView } from '../../components';

require('./style.scss');

export default class CoupleFields extends React.Component {

  static get propTypes() {
    return {
      buttonTitle: PropTypes.string,
      initialValues: PropTypes.object,
      isGenerateMode: PropTypes.bool,
      editMode: PropTypes.bool,
      resetSensors: PropTypes.func,
      materials: PropTypes.array,
      user: PropTypes.object,
      distanceFactor: PropTypes.number
    };
  }

  render() {
    const {distanceFactor} = this.props;
    return (
      <div className="couple-fields">
            <div className="path-details">
                <div>
                    {/* #INPUT VALIDATED WITH NUMBERS ONLY */}
                    {/* Attenuation - NUMBER FLOAT */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Attenuation')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Attenuation"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* NoiseLevel - NUMBER INT */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Noise Level')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="NoiseLevel"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* Fmin - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Fmin')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Fmin"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* Fmax -NUMBER INT   */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Fmax')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Fmax"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* SensitivityLevel - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Sensitivity Level')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="SensitivityLevel"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* AllowFalsePositive - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Allow False Positive')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="AllowFalsePositive"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* Prob4Burst - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Prob4 Burst')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Prob4Burst"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* Prob4Irrigation - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Prob4 Consumption')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="Prob4Irrigation"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* PipeAccuracy - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Pipe Accuracy')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="PipeAccuracy"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* MultiDiameter - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Multi Diameter')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="MultiDiameter"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* MultiMaterial - NUMBER INT  */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Multi Material')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field
                                className="form-control"
                                name="MultiMaterial"
                                component={Input}
                                type="number"
                                disabled={false}
                                distanceFactor={distanceFactor} />
                        </div>
                    </div>

                    {/* #SELECT WITH 0\1 ONLY (TRUE\FALSE) */}
                    {/* PossibleUFR - BOOLEAN 0\1 */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Possible UFR')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field name="PossibleUFR"
                                disabled={false}
                                component={props =>
                                    <Select {...props}
                                        key={"PossibleUFR"}
                                        placeholderTitle={this.context.t('Select...')}
                                        clearable={false}
                                        options={[
                                            { value: false, label: this.context.t('false') },
                                            { value: true, label: this.context.t('true') }]}
                                    />
                                }
                            />
                        </div>
                    </div>

                    {/* PossiblePumps - BOOLEAN 0\1 */}
                    <div className="inline-group">
                        <label className="col-xs-6 control-label">{this.context.t('Possible Pumps')}:</label>
                        <div className="col-xs-6 control-input">
                            <Field name="PossiblePumps"
                                disabled={false}
                                component={props =>
                                    <Select {...props}
                                        key={"PossiblePumps"}
                                        placeholderTitle={this.context.t('Select...')}
                                        clearable={false}
                                        options={[
                                            { value: false, label: this.context.t('false') },
                                            { value: true, label: this.context.t('true') }]}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

CoupleFields.contextTypes = {
  t: PropTypes.func.isRequired
};
