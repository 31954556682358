import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes } from './MiddlewareActions';

/* REDUCER */

const initialState = {
  items: [],
  indexMap: {},
  selectedDeviceId: null,
  selectedDeviceBist: [],
};

const ACTIONS = {
  REQUEST_MOBILE_DEVICES: 'REQUEST_MOBILE_DEVICES',
  RECEIVE_MOBILE_DEVICES: 'RECEIVE_MOBILE_DEVICES',

  REQUEST_MOBILE_DEVICES_BISTS: 'REQUEST_MOBILE_DEVICES_BISTS',
  RECEIVE_MOBILE_DEVICES_BISTS: 'RECEIVE_MOBILE_DEVICES_BISTS',

  SET_SELECTED_MOBILE_DEVICE: 'SET_SELECTED_MOBILE_DEVICE',
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.REQUEST_MOBILE_DEVICES:
      return { ...state, items: [] };
    case ACTIONS.RECEIVE_MOBILE_DEVICES:
      return { ...state, items: action.payload.devices, indexMap: action.payload.indexMap };
    case ACTIONS.SET_SELECTED_MOBILE_DEVICE:
      return { ...state, selectedDeviceId: action.payload }
    case ACTIONS.REQUEST_MOBILE_DEVICES_BISTS:
      return { ...state, selectedDeviceBist: [] }
    case ACTIONS.RECEIVE_MOBILE_DEVICES_BISTS:
      return { ...state, selectedDeviceBist: action.payload }
    default:
      return state;
  }
};

/* ACTIONS */
const getDevicesAction = (devices, indexMap) => ({
  type: devices ? ACTIONS.RECEIVE_MOBILE_DEVICES : ACTIONS.REQUEST_MOBILE_DEVICES,
  payload: { devices, indexMap }
});

export const getDeviceBistAction = (bistData) => ({
  type: bistData ? ACTIONS.RECEIVE_MOBILE_DEVICES_BISTS : ACTIONS.REQUEST_MOBILE_DEVICES_BISTS,
  payload: bistData
})

export const selectMobileDevice = (device) => (dispatch) => {
  const deviceId = device['device_id']
  dispatch({
    type: ACTIONS.SET_SELECTED_MOBILE_DEVICE,
    payload: deviceId
  });

  dispatch(getDeviceBist(deviceId));
}

/* API */
export const getDevices = () => (dispatch, getState) => {
  dispatch(getDevicesAction());
  // fetch devices:
  const url = `${endPoints.END_POINT}/mobiledevices`;
  return fetchMiddleware(url, {}, getState).then((json) => {
    if (json.status) {
      const devices = json.data;
      console.log({ devices });
      const indexMap = setIndexes(devices, 'device_id');
      dispatch(getDevicesAction(devices, indexMap));
    }
  });
};

const getDeviceBist = (deviceId) => (dispatch, getState) => {
  dispatch(getDeviceBistAction());
  const url = `${endPoints.END_POINT}/mobiledevices/bist/${deviceId}`;
  return fetchMiddleware(url, {}, getState).then((json) => {
    if (json.status) {
      dispatch(getDeviceBistAction(json.data));
    }
  });
}

export const getIQ100BReport = ({body}) => (dispatch, getState) => {
  const url = `${endPoints.END_POINT}/mobilereport/iq100b`;
  console.log('getIQ100BReport',url)
  return fetchMiddleware(`${url}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }, getState).then((json) => {
    // console.log(`fetchIQ100BReport`, json)
    return json
  });
}

export const getIQ100BReportProjectUsers = ({body}) => (dispatch, getState) => {
  const url = `${endPoints.END_POINT}/mobilereport/iq100b-project-users`;
  console.log('getIQ100BReportProjectUsers',url)
  return fetchMiddleware(`${url}`, {
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }, getState).then((json) => {
    // console.log(`fetchIQ100BReport`, json)
    return json
  });
}

export default reducer;
