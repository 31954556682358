import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import { Select, OprSelect } from '../../components';
import { positiveNum } from '../../validators/formValidators';
const ReactSelect = require('react-select');
const classNames = require('classnames');

class ReportsSettings extends React.Component {
  render() {
    const { showSettings, handleSubmit, pristine, reset, submitting, pageHeight, pageWidth, toggleSettingsDisplay } = this.props;
    const panelClasses = classNames(
      'reports-settings',
      'alerts-settings',
      {'show': showSettings}
    );

    return (
      <div className={panelClasses}>
        <div className="alerts-header">
          <div className="actions">
            <i className="icon-close" onClick={toggleSettingsDisplay}/>
          </div>
          <h2>{this.context.t('Reports Settings')}</h2>
        </div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <fieldset>
            <legend>{this.context.t('Sort by')}</legend>
            <div className="form-group">
              <div className="col-xs-8">
              <Field name="sortBy"
                component={props =>
                  <Select { ...props }
                    placeholderTitle={ this.context.t('Select...') }
                    clearable={false}
                    options={[
                      {value: 'ABC', label: this.context.t('Priority (default)')},
                      {value: 'ID', label: this.context.t('ID')},
                      { value: 'DetectedAt', label: this.context.t('Detected')},
                      {value: 'AlertType', label: this.context.t('Type')},
                      {value: 'AlertState', label: this.context.t('Status')},
                      {value: 'Intensity', label: this.context.t('Intensity')},
                      {value: 'WorkArea', label: this.context.t('Work Area')},
                      {value: 'fixDate', label: this.context.t('Repair date')},
                      {value: 'ClosureDate', label: this.context.t('Closure date')},
                      {value: '10', label: this.context.t('Service provider')},
                      //{value: '11', label: this.context.t('Days detected')},
                    ]}
                  />
                }
              />
              </div>
              <div className="col-xs-4">
              <Field name="sortByDir"
                component={props =>
                  <Select {...props}
                    placeholderTitle={ this.context.t('Select...') }
                    clearable={false}
                    backspaceRemoves={false}
                    options={[
                      {value: 'asc', label: this.context.t('ASC')},
                      {value: 'desc', label: this.context.t('DESC')},
                    ]}
                  />
                }
              />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>{this.context.t('Filters')}</legend>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Priority')}</label>
              <div className="col-xs-8">
                <Field name="ABC"
                       component={props =>
                    <Select {...props}
                      placeholderTitle={ this.context.t('Select...') }
                      clearable={true}
                      multi={true}
                      closeMenuOnSelect={false}
                      options={[
                      {value: 'a', label: this.context.t('A')},
                      {value: 'b', label: this.context.t('B')},
                      {value: 'c', label: this.context.t('C')},
                    ]}
                    />
                  }
                />
              </div>
            </div>
            <div className="text-right">
              <button className="btn btn-success" type="submit" disabled={submitting} onClick={toggleSettingsDisplay} >{this.context.t('Save & Apply')}</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

}

function validate(formProps) {
  const errors = {}
  // if (positiveNum(formProps.DaysDetected)) {
  //   errors.DaysDetected = 'must be positive';
  // }
  return errors;
}


ReportsSettings.contextTypes = {
  t: PropTypes.func.isRequired
}

// Decorate the form component
ReportsSettings = reduxForm({
  form: 'reports-settings', // a unique name for this form
  enableReinitialize: true,
})(ReportsSettings);

export default ReportsSettings;
