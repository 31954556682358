import React from 'react';
import { ChooseProjectModal } from '../../components';

import PropTypes from 'prop-types';

require('./Picker.scss');
const Picker = (props) => {
    const { value, onChange, options } = props;
    return (
      <div className="project-picker">
        <ChooseProjectModal />
        <select onChange={e => onChange(e.target.value)}
                value={value}>
          {options.map(option =>
            <option key={option.ID} value={option.ID}>
              {option.ShortName}
            </option>)
          }
        </select>
      </div>
    );
}

Picker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.object.isRequired
  ).isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default Picker;
