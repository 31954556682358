import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { BarLoader } from 'react-spinners';
import { InstallOperationMode, UninstallMode } from '../../constants/Misc';
import { Select, Input, CommentField, UninstallSensorModal, AddressField } from '../../components';

class SOPsOperationsTab extends React.Component {
  render() {
    const {
      mode,
      uninstallMode,
      uninstallDetails,
      isButtonEnabled,
      isFetchingDetails,
      isDeviceFieldEnable,
      isWorking,
      initialValues,
      selectedFeature,
      user,
      sensorTypes,
      syncTypes,
      deviceGenerationType,
      configs,
      pristine,
      submitting,
      handleSubmit } = this.props;
    let buttonTitle = '';
    const bIsFieldsEnable = mode != InstallOperationMode.SOP_ONLY;
    let bButtonDisable = true;
    if (!isFetchingDetails) {
      if (mode == InstallOperationMode.SOP_ONLY) {
        bButtonDisable = !isButtonEnabled;
      } else {
        bButtonDisable = !isButtonEnabled || (pristine || submitting);
      }
    }

    if (!isFetchingDetails) {
      switch (mode) {
        case InstallOperationMode.SOP_ONLY:
          buttonTitle = this.context.t('Add Sensor');
          break;
        case InstallOperationMode.SOP_AND_SENSOR:
          buttonTitle = this.context.t('Install Device');
          break;
        case InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE:
          buttonTitle = this.context.t('Update');
          break;
      }
    }

    return (
      <form className="form-horizontal sticky-actions" onSubmit={handleSubmit}>
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%'
        }}>
          <BarLoader
            color="#4cae4c"
            width="110%"
            loading={isWorking}
          />
        </div>
        <CommentField
          fieldName="Comment"
          featureID={selectedFeature}
          placeholder={this.context.t('Sensor comment')}
          onEdit={this.props.onEdit}
          onCopy={this.props.onCopy}
          disabled={!user.editMode}
        />
        <div className="form-group">
          <label className="col-xs-2 control-label">{this.context.t('Device ID')}:</label>
          <div className="col-xs-10">
            <Field
              className="form-control"
              name="DeviceID"
              component={Input}
              type="number"
              maxlength={15}
              size={15}
              disabled={!user.editMode || !isDeviceFieldEnable } />
          </div>
        </div>
        <div className="form-group">
          <AddressField user={this.props.user} fieldName="StreetAddress" onGetAddress={this.props.getAddressByLatLong} />
        </div>
        <div className="form-group">
          <label className="col-xs-2 control-label">{this.context.t('Config')}:</label>
          <div className="col-xs-10 control-input">
            <Field name="Template"
              disabled={!user.editMode}
              component={props =>
                <Select {...props}
                  key={"Template"}
                  placeholderTitle={this.context.t('Select...')}
                  options={configs}
                />
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('Under / Over')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="isUnderground"
              disabled={!user.editMode}
              component={props =>
                <Select {...props}
                  key={"isUnderground"}
                  placeholderTitle={this.context.t('Select...')}
                  options={[
                    { value: 0, label: this.context.t('Overground') },
                    { value: 1, label: this.context.t('Underground') }
                  ]}
                />
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('Type')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="SensorType"
              disabled={!user.editMode}
              component={props =>
                <Select {...props}
                  key={"SensorType"}
                  placeholderTitle={this.context.t('Select...')}
                  options={sensorTypes}
                />
              }
            />
          </div>
        </div>
        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('Sync')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="SyncMethod"
              disabled={!user.editMode}
              component={props =>
                <Select {...props}
                  key={"SyncMethod"}
                  placeholderTitle={this.context.t('Select...')}
                  options={syncTypes}
                />
              }
            />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('Generation')}:</label>
          <div className="col-xs-8 control-input">
            <Field name="DeviceGenerationValue"
              disabled={!user.editMode}
              component={(props) =>
                (<Select {...props}
                  disabled
                  value={this.props.selectedGenerationType}
                  key={"DeviceGenerationValue"}
                  placeholderTitle={this.context.t('Select...')}
                  options={deviceGenerationType}
                />)
              }
            />
          </div>
        </div>

        <div className="inline-group">
          <label className="col-xs-4 control-label">{this.context.t('Is Pressure')}:</label>
          <div className="col-xs-8 control-input">
            <Field
              style={{width:'30px'}}
              className="checkbox-control"
              name="IsPressure"
              component={Input}
              type="checkbox"
              maxlength={15}
              size={15}
              disabled={!user.editMode} />
          </div>
        </div>

        <div className="form-actions">
          {buttonTitle != '' &&
            <button
              className="btn btn-success btn-submit"
              type="submit">{buttonTitle}</button>
          }
          {mode == InstallOperationMode.SOP_AND_SENSOR_AND_DEVICE &&
            <a
            className="btn btn-warning"
            onClick={() => {
              this.props.setUninstallMode(UninstallMode.NORMAL);
            }}
          >{this.context.t('Uninstall Device')}</a>
          }
          {mode == InstallOperationMode.SOP_AND_SENSOR &&
            <a
              className="btn btn-danger"
              onClick={() => {
                this.props.setUninstallMode(UninstallMode.JUST_REMOVE_SENSOR);
              }}
            >{this.context.t('Remove Sensor')}</a>
          }
        </div>

        <UninstallSensorModal
          onClose={() => {
            this.props.setUninstallMode(UninstallMode.NONE);
          }}
          mode={uninstallMode}
          details={uninstallDetails}
          notify={this.props.notify}
        />

      </form>
    );
  }
}

SOPsOperationsTab.contextTypes = {
  t: PropTypes.func.isRequired
}

const validate = (values, props) => {
  const errors = {}
  if (props.mode != InstallOperationMode.SOP_ONLY) {
    if (values.DeviceID == null) {
      errors.DeviceID = 'Required! should contains 15 digits';
    }
    else if(values.DeviceID.length != 15){
      errors.DeviceID = 'Device ID should contains 15 digits';
    }

    if (values.Template == null || values.Template.toString() == '') {
      if (values.DeviceID && !values.DeviceID.startsWith('000')) {
        errors.Template = 'Required!';
      }
    }
    if (values.isUnderground == null || values.isUnderground.toString() == '') {
      errors.isUnderground = 'Required!';
    }
    if (values.SensorType == null || values.SensorType.toString() == '') {
      errors.SensorType = 'Required!';
    }
    if (values.SyncMethod == null || values.SyncMethod.toString() == '') {
      errors.SyncMethod = 'Required!';
    }
  }
  return (errors);
}

// Decorate the form component
SOPsOperationsTab = reduxForm({
  form: 'sopOperationsForm', // a unique name for this form
  enableReinitialize: true,
  touchOnBlur: false,
  validate,
})(SOPsOperationsTab);
export default SOPsOperationsTab;
