import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../../components';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

import Pagination from 'react-js-pagination';

const _ = require('lodash');

require('../SensorInfo/SensorInfo.scss');

class SensorSamplesTab extends React.Component {
  constructor(props) {
    super(props);

    this.handlePageClick = this.handlePageClick.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.rowClassNameGetter = this.rowClassNameGetter.bind(this);

    this.state = {
      currentPage: 1,
      rowForScrolling: null,
    };
  }

  onRowClick(event, rowIndex) {
    this.props.selectSensorSample(this.props.sensorsSamples[rowIndex]);
  }

  rowClassNameGetter(rowIndex) {
    const { selectedSensorSample, sensorsSamples } = this.props;
    return (selectedSensorSample != null && sensorsSamples[rowIndex] && selectedSensorSample.ID == sensorsSamples[rowIndex].ID) ? 'active-row' : '';
  }

  handlePageClick(pageNumber) {
    const self = this;
    this.setState({
      currentPage: pageNumber,
      rowForScrolling: 0, // scroll to top on changing pager number
    }, () => {
      self.setState({
        rowForScrolling: null, // release the scrolling...
      });
    });
    this.props.onPageIndexChange(pageNumber - 1, 'SensorSamplesTab');
  }

  render() {
    const {
      sensorsSamples,
      totalNoiseSamplesCount,
      noiseSamplesRequestedCount,
      uiInfo,
      timeZone,
      downloadFile
    } = this.props;

    let pWidth = 0.395 * (uiInfo.pageWidth - 10);
    let pHeight = 0.494 * uiInfo.pageHeight - 8;

    const data = (sensorsSamples != null && !_.isEmpty(sensorsSamples)) ? sensorsSamples : [];

    return (
      <div>
        <Table
          rowsCount={data.length}
          width={pWidth}
          scrollToRow={this.state.rowForScrolling}
          onRowClick={this.onRowClick}
          rowClassNameGetter={this.rowClassNameGetter}
          maxHeight={pHeight - 117}
          headerHeight={46}
          rowHeight={30}>
          <Column
            columnKey="SampleTime"
            header={<Cell>{this.context.t('Sample Time')}</Cell>}
            cell={<TextCell data={data} field="SampleTime" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
            width={60}
            flexGrow={3}
          />
          <Column
            columnKey="Nc1"
            header={<Cell title={this.context.t("Minimal noise sampling taken by the sensor prior to audio recording")}>{this.context.t('Min Noise')}</Cell>}
            cell={<TextCell data={data} field="Nc1" />}
            width={30}
            flexGrow={2}
            align='center'
          />
          <Column
            columnKey="Nc2"
            header={<Cell title={this.context.t("Noise sampling taken by the sensor during audio recording")}>{this.context.t('Sample Noise')}</Cell>}
            cell={<TextCell data={data} field="Nc2" />}
            width={30}
            flexGrow={2}
            align='center'
          />
          <Column
            columnKey="Nc3"
            header={<Cell title={this.context.t("Audio recording intensity calculated with electrical interference filltering")}>{this.context.t('Filtered Noise')}</Cell>}
            cell={<TextCell data={data} field="Nc3" />}
            width={30}
            flexGrow={2}
            align='center'
          />
          {/* <Column
            columnKey="NC3ave"
            header={<Cell title={this.context.t("Filtered audio recordings intensity calculations avarage")}>{this.context.t('Filtered Avg.')}</Cell>}
            cell={<TextCell data={data} field="NC3ave" />}
            width={30}
            flexGrow={2}
            align='center'
          /> */}
          <Column
            columnKey="FileSize"
            header={<Cell title={this.context.t("")}>{this.context.t('Duration [sec]')}</Cell>}
            cell={<TextCell data={data} field="Duration" />}
            width={30}
            flexGrow={2}
            align='center'
          />

          <Column
            columnKey="Audio"
            header={<Cell>{this.context.t('Download')}</Cell>}
            cell={props => (<Cell {...props} >
              {data[props.rowIndex].FileName &&
                <a
                  key='Audio'
                  className="btn-wav-download"
                  onClick={() => {
                    downloadFile(data[props.rowIndex].FileName, 'file');
                  }}
                  href='javascript:void(0);'>
                  {this.context.t('Audio')}
                </a>
              }
            </Cell>)}
            width={30}
            flexGrow={2}
          />




        </Table>

        <div className="center">
          <Pagination
            prevPageText={this.context.t('prev')}
            nextPageText={this.context.t('next')}
            firstPageText={this.context.t('first')}
            lastPageText={this.context.t('last')}
            activePage={this.state.currentPage}
            itemsCountPerPage={noiseSamplesRequestedCount || 0}
            totalItemsCount={totalNoiseSamplesCount || 0}
            pageRangeDisplayed={5}
            onChange={this.handlePageClick}
          />
        </div>
      </div>
    );
  }
}

SensorSamplesTab.contextTypes = {
  t: PropTypes.func.isRequired
}

export default SensorSamplesTab;
