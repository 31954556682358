/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { commands } from '../../../constants/G5MessagesTypes';

export default function ControlButtons(props, context) {

  const ButtonComp = (compProps) => (
    <Button
      style={{
        width: '-webkit-fill-available',
        fontSize: '1.5rem'
      }}
      variant='contained'
      color={compProps.sensitive ? 'error' : 'primary'}
      // onClick={(compProps.handleClick) || (() => props.sendCommand(compProps.actionKey))}
      onClick={(e) => {
        if (compProps.handleClick) {
          compProps.handleClick(e, compProps.actionkey);
        } else {
          props.sendCommand(compProps.actionkey)
        }
      }}
      disabled={!props.enable}
      {...compProps}>
        {compProps.children}
    </Button>
  );
  ButtonComp.propTypes = {
    actionkey: PropTypes.string,
    children: PropTypes.node,
    handleClick: PropTypes.func,
    user: PropTypes.object,
  };

  const [open, setOpen] = React.useState(false);
  const [significantActionKey, setSignificantKey] = React.useState(null);

  function askBeforSubmit(event, actionKey) {
    event.preventDefault();

    setOpen(true);
    setSignificantKey(actionKey);
    debugger;
  }

  function handleClose() {
    setOpen(false);
  }

  function submitValue() {
    handleClose();
    props.sendCommand(significantActionKey);
    setSignificantKey(null);
  }


  return (
    <React.Fragment>
      <Grid container direction='column' spacing={1}>
        {/* <Grid item ><ButtonComp actionkey={commands.RESET}>{context.t('Reset Command')}</ButtonComp></Grid> */}
        <Grid item ><ButtonComp actionkey={commands.GET_SCHEDULER_CONFIG}>{context.t('Get Scheduler')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_AUDIO_CONFIG}>{context.t('Get Audio Config')}</ButtonComp></Grid>
        {/* <Grid item ><ButtonComp actionkey={commands.GET_CORRELATION_CONFIG}>{context.t('Get Correlation Config')}</ButtonComp></Grid> */}
        <Grid item ><ButtonComp actionkey={commands.GET_TIME_ZOME}>{context.t('Get Time Zone')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_LTE_MODE}>{context.t('Get LTE Mode')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.START_FM_SCAN}>{context.t('Start FM Scan')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_FM_CHANNEL}>{context.t('Get FM Channel')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_SERVER}>{context.t('Get Server')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_APN}>{context.t('Get APN')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_AGC}>{context.t('Get AGC Conf')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_FALLBACK_SERVER}>{context.t('Get Fallback Server')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.GET_CAPABILITY_REGISTER}>{context.t('Get Capability')}</ButtonComp></Grid>

        <Grid item ><ButtonComp actionkey={commands.RESET}>{context.t('Reset')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.ACTIVATE_TOOLKIT}>{context.t('Activate Toolkit')}</ButtonComp></Grid>
        <Grid item ><ButtonComp actionkey={commands.DEACTIVATE} handleClick={askBeforSubmit} disabled={!props.enable || ! props.user.g5AqsAdmin} sensitive>{context.t('Deactivate')}</ButtonComp></Grid>
      </Grid>

      <Dialog
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>{context.t('You are about to take a significant action')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{context.t('Sure you want to deactivate sensors?')}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={handleClose} autoFocus>{context.t('Cancel')}</Button>
          <Button color="primary" onClick={submitValue} autoFocus>{context.t('Continue')}</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

ControlButtons.contextTypes = {
  t: PropTypes.func.isRequired
};

ControlButtons.propTypes = {
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  sendCommand: PropTypes.func,
};
