import * as types from '../constants/ActionTypes';

const initialState = {
  layers: {},
  drawMode: {
    isActive: false,
    type: null,
    data: null,
  },
  isInDrawMode: false,
};

const mapState = (state = initialState, action) => {
  const actionType = action.type || '';

  switch (actionType) {
    case types.ON_MAP_LAYER_CHANGE: {
      return Object.assign({}, state, {
        layers: Object.assign({}, state.layers, {
          [action.layerName]: action.isSelected,
        }),
      });
    }
    case types.SET_MAP_DRAW_MODE: {
      return Object.assign({}, state, {
        drawMode: {
          isActive: action.isDraw,
          type: action.drawType,
          data: action.points,
        },
      });
    }
    case types.SAVE_DRAWN_FEATURE:
      return Object.assign({}, state, {
        drawMode: Object.assign({}, state.drawMode, {
          data: action.points,
        }),
      });
    // when select any item - set draw mode to false:
    case types.SELECT_LEAK:
      return Object.assign({}, state, {
        isInDrawMode: false,
      });
    default:
      return state;
  }
};

export default mapState;
