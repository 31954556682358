import React from 'react';
import { zIndexs, featuresTypes } from './Options';
import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';

import { Style, Icon } from 'ol/style';
import Feature from 'ol/Feature';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import Point from 'ol/geom/Point';

import * as alertsPressure from './AlertsPressure';
import * as noiseAlert from './NoiseAlert';

const proj = require('ol/proj');

const classNames = require('classnames');

// Alerts layer style
const style = new Style({
  image: new Icon({
    // See dynamic styles in getAlertStyle()
    src: require('../../../../images/map/alerts/alert-c-open.png'), anchor: [0.5, 1]
  })
});

const source = new VectorSource({
  features: [],
});

// Alerts layer
export const layer = new VectorLayer({
  name: 'layerAlerts',
  style: style,
  source: source,
  zIndex: zIndexs.alerts,
});

export const setSource = (nextIconFeatures) => {
  let iconFeatures = [];
  Object.keys(nextIconFeatures).forEach((key) => {
    const itrFeature = nextIconFeatures[key];
    const iconFeature = new Feature({
      geometry: new Point(
        proj.transform(
          nextIconFeatures[key].position,
          'EPSG:4326',
          'EPSG:3857'
        )
      ),
      type: featuresTypes.ALERT,
      id: itrFeature.id,
      priority: itrFeature.priority,
      state: itrFeature.state,
      stateNum: itrFeature.stateNum,
      alertTypeNum: itrFeature.alertTypeNum,
      intensity: itrFeature.intensity,
      isManual: itrFeature.isManual,
      leakBoss: itrFeature.LeakBoss,
      closureDate: itrFeature.closureDateMillis,
      fixDate: itrFeature.fixDate,
      detectedAt: itrFeature.detectedAt,
      noiseAlert: itrFeature.noiseAlert,
    });
    iconFeature.setStyle(getStyle(iconFeature));
    iconFeatures.push(iconFeature);
  });
  source.addFeatures(iconFeatures);
};

export const getLayers = () =>
  [layer, ...alertsPressure.getLayers(), ...noiseAlert.getLayers()];

export const hasFeatures = () =>
  source.getFeatures().length > 0;

// Clean the layer
export const clear = () => {
  if (source.getFeatures().length) {
    source.clear();
  }
};

export const getStyle = (feature, bSelected) => {
  let alertStyle = false;
  let iconStatus = false;

  const attr = feature.getProperties();
  bSelected = bSelected ? '-selected' : '';

  if (attr.alertTypeNum == 2 &&
    ([0, 1, 12, 13].indexOf(attr.stateNum) !== -1) // statuses: [Suspected, New, Reopen, Out Of Bounds]
  ) {
    iconStatus = 'irrigation';
  } else if (attr.stateNum == 10) { // TO_BE_FIXED
    iconStatus = `to-be-fixed-${attr.isManual ? 'manual' : 'auto'}`;
  } else if (attr.stateNum == 6) {
    iconStatus = `fixed-${attr.isManual ? 'manual' : 'auto'}`;
  } else if (attr.stateNum == 8) {
    iconStatus = 'shadow';
  }
  else if (attr.stateNum == 9) {
    iconStatus = 'falsepath';
  }
  else if (attr.stateNum == 4 && attr.alertTypeNum == 1) { // external && interference
    iconStatus = 'interference';
  }
  else if (attr.priority && attr.state) {
    iconStatus = attr.priority + '-' + attr.state;
  }

  if (iconStatus) {
    alertStyle = [new Style({
      image: new Icon({
        src: require('../../../../images/map/alerts/alert-' + iconStatus + bSelected + '.png'),
        anchor: [0.5, 1],
      }),
      zIndex: (bSelected) ? 999 : zIndexs.alerts,
    })];

    if (attr.stateNum == 11) { // 11 = 'Duplicate'
      alertStyle.push(new Style({
        image: new Icon({
          src: require('../../../../images/map/alerts/indicators/indication-duplicate.png'),
          scale: 0.3,
          anchor: [1, 1],
        })
      }));
    }
    // if (attr.stateNum == 2) { // 2 = 'To Be Located'
    //   let anchorIconV;
    //   switch (attr.priority) {
    //     case 'a': anchorIconV = [-0.1, 2.35]; break;
    //     case 'b': anchorIconV = [0.1, 1.95]; break;
    //     case 'c':
    //     case 'd':
    //     default: anchorIconV = [0.15, 1.75]; break;
    //   }

    //   alertStyle.push(new Style({
    //     image: new Icon({
    //       src: require('../../../../images/magnifying-glass.png'),
    //       scale: 0.18,
    //       anchor: anchorIconV,
    //       opacity: 0.85,
    //     }),
    //     zIndex: (((bSelected) ? 999 : zIndexs.alerts) + 10),
    //   }));
    // }
    if (attr.stateNum == 3) { // 3 = 'Located'
      let anchorIconV;
      switch (attr.priority) {
        case 'a': anchorIconV = [-0.1, 2.35]; break;
        case 'b': anchorIconV = [0.1, 1.95]; break;
        case 'c':
        case 'd':
        default: anchorIconV = [0.15, 1.75]; break;
      }
      alertStyle.push(new Style({
        image: new Icon({
          src: require('../../../../images/ic_circle_v.png'),
          scale: 0.1,
          anchor: anchorIconV,
          opacity: 0.9,
        }),
        zIndex: (((bSelected) ? 999 : zIndexs.alerts) + 10),
      }));
    }
  }
  return alertStyle;
};

export const getInfoTooltip = (feature) => {
  const attr = feature.getProperties();
  let infoTooltip = {
    type: attr.type,
    id: attr.id,
    intensity: attr.intensity || (false || 1),
    priority: attr.priority || false,
    stateNum: attr.stateNum,
    leakBoss: attr.leakBoss,
    closureDate: attr.closureDate,
    fixDate: attr.fixDate,
    detectedAt: attr.detectedAt,
    noiseAlert: attr.noiseAlert,
  };
  return (infoTooltip);
};

export const tooltipRender = (itemInfo, context, props) => {
  const tooltipClasses = classNames(
    'tooltip',
    'priority-' + itemInfo.priority
  );
  const alertState = props.alertStateOptions.find((aState) => aState.value === itemInfo.stateNum);

  return (
    <span className={tooltipClasses}>
      <div className="tooltip-row">{context.t('Alert ID: {id}', { id: itemInfo.id })}</div>
      <div className="tooltip-row">{context.t('Intensity: {intensity}', { intensity: itemInfo.intensity })}</div>
      { alertState &&
        <div className="tooltip-row">{context.t('Status: {state}', { state: alertState.DisplayName })}</div>
      }
      {
        itemInfo.stateNum === 8 && // 8 == Shadow
        <div className="tooltip-row">{context.t('Leak Boss: {boss}', { boss: itemInfo.leakBoss })}</div>
      }

      <div className="tooltip-row">{context.t('Detected Date: {date}', { date: itemInfo.closureDate ? UIDateFormater(itemInfo.detectedAt, DateType.DATE, props.timeZone) : '--' })}</div>

      {
        itemInfo.stateNum === 5 && // 5 == Closed
        <div className="tooltip-row">{context.t('Closure Date: {date}', { date: itemInfo.closureDate ? UIDateFormater(itemInfo.closureDate, DateType.DATE, props.timeZone) : '--' })}</div>
      }
      {
        itemInfo.stateNum === 6 && // 6 == Fixed
        <div className="tooltip-row">{context.t('Fixed Date: {date}', { date: itemInfo.fixDate ? UIDateFormater(itemInfo.fixDate, DateType.DATE, props.timeZone) : '--' })}</div>
      }
      {
        itemInfo.noiseAlert &&
        <div className="tooltip-row">{context.t('Noise Alert ID: {noiseId}', { noiseId: itemInfo.noiseAlert })}</div>
      }
    </span>
  );
};
