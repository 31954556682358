import React from 'react';
import PropTypes from 'prop-types';
import { Cell } from 'fixed-data-table-2';

const classNames = require('classnames');

export default class SortableHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortDir: this.props.sortTable?.sortDir || 'asc',
    };
    this.onSortChange = this.onSortChange.bind(this);
  }
  onSortChange(e) {
    const sortDir = this.state.sortDir === 'desc' ? 'asc' : 'desc';
    this.setState({ sortDir });
    this.props.sortTable.sort(this.props.columnKey, sortDir);
  }
  render() {
    const { columnKey, sortTable, title } = this.props;
    const { sortDir } = this.state;
    const activeSortDir = sortTable.sortDir || sortDir;
    const titles = {
      Priority: this.context.t(' '),
      id: this.context.t('ID'),
      DetectedAt: this.context.t('Detected'),
      AlertType: this.context.t('Type'),
      AlertTypeMeaning: this.context.t('Type'),
      AlertState: this.context.t('Status'),
      AlertStateMeaning: this.context.t('Status'),
      SensorStatus: this.context.t('Status'),
      comment: this.context.t('Comment'),
      DaysInactive: this.context.t('Last Run (days)'),
      DeviceID: this.context.t('Device ID'),
      SensorID: this.context.t('Sensor ID'),
      Address: this.context.t('Address'),
      StreetAddress: this.context.t('Address'),
      City: this.context.t('City'),

      Intensity: this.context.t('Intensity'),
      Probability: this.context.t('Prob'),
      probability_irrigation: this.context.t('Prob Irrig'),
      Distance: this.context.t('Distance'),
      // Sensors
      warning: this.context.t('W'),
      DeviceInstallationDate: this.context.t('Install Date'),
      Nc2: this.context.t('Noise'),
      Nc1: this.context.t('Nc 1'),
      // 'Nc2': this.context.t('2'),
      Nc3: this.context.t('Nc 3'),

      // Samples
      sampleTime: this.context.t('Sample Time'),
      weightedDistance: this.context.t('Weighted Distance'),
      instanceIntensity: this.context.t('Intensity'),
      weightedIntensity: this.context.t('Weighted Intensity'),
      instanceDistance: this.context.t('Distance'),
      LeakID: this.context.t('Leak ID'),
      // Couples
      warningEnum: this.context.t(' '),
      PathLengthM: this.context.t('Pipe Length'),
      Material: this.context.t('Material'),
      Sensor1: this.context.t('Sensor 1'),
      Sensor2: this.context.t('Sensor 2'),
      LastDaemonActivity: this.context.t('Updated'),
      // Couples Samples
      SampleDateAndTime: this.context.t('Sample Time'),
      IntensityA: this.context.t('Weighted Intensity'),
      IntensityI: this.context.t('Sample Intensity'),
      DistanceAM: this.context.t('Weighted Distance'),
      DistanceIM: this.context.t('Sample Distance'),
      // Users
      UserName: this.context.t('User Name'),
      Email: this.context.t('Email'),
      FirstName: this.context.t('First Name'),
      LastName: this.context.t('Last Name'),
      CustomerName: this.context.t('Customer Name'),
      // SOPs
      Type: this.context.t('Type'),
      State: this.context.t('State'),
      AssetID: this.context.t('Asset ID'),
      Model: this.context.t('Model'),

      // Projects
      // Customers
      ID: this.context.t('ID'),
      Name: this.context.t('Name'),
      CustomerName: this.context.t('Customer Name'),
      ContactPerson: this.context.t('Contact Name'),

      // iQuarius
      TaskName: this.context.t('Task Name'),
      CreationTime: this.context.t('Creation Time'),
      CreationDate: this.context.t('Creation Date'),
      SamplesCount: this.context.t('Samples Count'),
      TaskID: this.context.t('Task Id'),
      SampleTime: this.context.t('Sample Time'),
      Status: this.context.t('Status'),
      Quality: this.context.t('Quality'),
      Comments: this.context.t('Comments'),
      DistanceOfLeakFromDevice1: this.context.t('Dist[m]'),

      // Interferences
      StartTime: this.context.t('Start Time'),
      EndTime: this.context.t('End Time'),
      StartFrequency: this.context.t('Start Frequency'),
      EndFrequency: this.context.t('End Frequncy'),

      // PCA
      DetectionDateTime: this.context.t('Detected'),
      AlertTypeName: this.context.t('Type'),
      AlertStateName: this.context.t('Status'),

      // PrsTransientAlerts:
      SessionNumEvents: this.context.t('N'),
      CreateDate: this.context.t('Create Date'),
      EndDate: this.context.t('End Date'),
      LastEventTime: this.context.t('Last Event Date'),
      LastEventMinValue: this.context.t('Min Value'),
      LastEventMaxValue: this.context.t('Max Value'),

      // NoiseAlerts:
      InsertDate: this.context.t('Alert Date'),

      // MobileSamples:
      sample_time: this.context.t('Sample Time'),
      device_id: this.context.t('Device ID'),
      sample_state: this.context.t('Status'),
      engine_intensity: this.context.t('_Intensity [dB]'),
      mobile_intensity: this.context.t('Intensity [dB]'),
      mobile_quality: this.context.t('Quality'),
      engine_quality: this.context.t('Clarity'),
      user_sop_classify: this.context.t('Access Point'),
      user_sample_classify: this.context.t('Classify'),
      Probability: this.context.t('Probability'),
      sample_images: <>📷</>, // <>&#10045;</>, <>&#128247;</>

      timestamp: this.context.t('Time'),
      user_name: this.context.t('User'),
      is_assambly: this.context.t('Assambly'),

      // G5 tables
      LastStatusReportTime: this.context.t('Last Updated'),
      BatteryLevel: this.context.t('Battery Level'),
      BatteryLevelPost: this.context.t('Battery Post'),
      AccessTechnologyName: this.context.t('Tech'),
      IEMI: this.context.t('IEMI'),
      ICCID: this.context.t('ICCID'),
      StatusTimeZone: this.context.t('Time Zone'),
      HardwareRevision: this.context.t('HW V.'),
      FirmwareRevision: this.context.t('FW V.'),
      RSRQ_db: this.context.t('RSRQ'),
      RSRP_dbm: this.context.t('RSRP'),
      StatusFmReceiverChannel_10k: this.context.t('FM Channel'),
      APN: this.context.t('APN'),
      Server: this.context.t('Server'),
      FallbackServer: this.context.t('Secondary'),
      modem_fw_rev: this.context.t('Modem FW'),
      dfu_fw_rev: this.context.t('DFU FW'),
      dfu_type: this.context.t('DFU Type'),
      dfu_block_num: this.context.t('DFU Block'),
      random_backoff_connect: this.context.t('Random Backoff Connect'),

      SchedulerRefTime: this.context.t('Sample Time'),
      ConfigTimeZone: this.context.t('Time Zone'),
      SchedulerCorrInterval: this.context.t('Corr Interval'),
      SchedulerCorrNumSamples: this.context.t('Corr Num Samples'),
      SchedulerNoiseInterval: this.context.t('Noise Interval'),
      SchedulerNoiseNumSamples: this.context.t('Noise Num Samples'),
      SchedulerNoiseTxTime: this.context.t('Noise Tx Time'),
      SchedulerWakeupBefor: this.context.t('Wakeup Befor'),
      SchedulerRandomDelay: this.context.t('Random Delay'),
      AudioRecordingDurationSec: this.context.t('Noise Audio Duration'),
      CorrelationAudioRecordingDurationSec: this.context.t('Corr Audio Duration'),
      CorrelationRadioRecordingDurationSec: this.context.t('Corr Radio Duration'),
      AudioReportEnabled: this.context.t('Audio Report Enabled'),
      CorrelationAudioSamplingFrequency: this.context.t('Corr Sample Freq'),
      FmReceiverChannel_10k: this.context.t('FM Channel'),
      LTEMode: this.context.t('LTE Mode'),
      APN: this.context.t('APN'),
      Server: this.context.t('Server'),
      FallbackServer: this.context.t('Secondary'),
      catm_packet_size: this.context.t('CatM Packet'),
      catm_rx_timeout: this.context.t('CatM RX TOUT'),
      catm_tx_timeout: this.context.t('CatM TX TOUT'),
      catm_flags: this.context.t('CatM flags'),
      nbiot_packet_size: this.context.t('NBIOT Packet'),
      nbiot_rx_timeout: this.context.t('NBIOT RX TOUT'),
      nbiot_tx_timeout: this.context.t('NBIOT TX TOUT'),
      nbiot_flags: this.context.t('NBIOT flags'),
    };

    if (this.props.tableName && this.props.tableName === 'AlertsTableNoise') {
      switch (this.props.tableName) {
        case 'AlertsTableNoise': {
          titles.CreateDate = this.context.t('Event Date');
        }
      }
    }
    const active = sortTable.sortBy === columnKey;
    let columnKeyClass;
    switch (columnKey) {
      case 'Priority':
        columnKeyClass = 'priority';
        break;
      case 'Warning':
        columnKeyClass = 'warning';
        break;
      case 'IsLeak':
        columnKeyClass = 'drop';
        break;
      default:
        columnKeyClass = '';
        break;
    }
    const cellClasses = classNames(
      columnKey === 'Priority' ? 'priority' : columnKey,
      'sortable',
      'sort-' + activeSortDir,
      { 'sort-active': active },
      columnKeyClass
    );
    if (columnKey === 'SessionNumEvents') {
      return (
        <Cell
          onClick={this.onSortChange}
          className={cellClasses}
          title={this.context.t('Number of transients')}
        >
          {titles[columnKey]}
        </Cell>
      );
    } else {
      return (
        <Cell
          onClick={this.onSortChange}
          className={cellClasses}
          title={title || titles[columnKey]}
        >
          {titles[columnKey]}
        </Cell>
      );
    }
  }
}

SortableHeaderCell.contextTypes = {
  t: PropTypes.func.isRequired,
};
