import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware } from './MiddlewareActions';
import { downloadFileFromURl } from './GlobalsActions';
// import fetch from 'isomorphic-fetch';


//--------------------------------
// Receive & Request
//--------------------------------
function requestDeviceLog(project, id) {
    return {
        type: types.REQUEST_DEVICE_LOG,
        project,
        id
    };
}

function receiveDeviceLog(project, id, log) {
    //log.data = formatObjectsDate(log.data);
    return {
        type: types.RECEIVE_DEVICE_LOG,
        project,
        id,
        log
    };
}

//--------------------------------
// Fetch
//--------------------------------
export function fetchDeviceLogIfNeeded(project, id, force = false) {
    return (dispatch, getState) => {
        if (force || shouldFetchDeviceLog(getState(), project, id)) {
            return dispatch(fetchDeviceLog(project, id));
        }
    };
}

function shouldFetchDeviceLog(state, project, id) {
    const log = state.leaksList.leaksByProject[project].log[id]
    if (!log) {
        return true;
    }
    else if (log.isFetching) {
        return false;
    }
    else if (log.data.length) {
        return false;
    }
    return true;
}

export function fetchDeviceLog(project, id) {
  const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.DEVICES_ENDPOINT + "/" + id + "/log";

  return (dispatch, getState) => {
    //@TODO: Check errors.
    dispatch(requestDeviceLog(project, id));
    return fetchMiddleware(path, {}, getState).then((json) => {
      dispatch(receiveDeviceLog(project, id, json));
    }), (error) => {
      console.log(error);
    };
  };
}

export function getG5DeviceStatusMessagesReport(deviceId) {
  return (_dispatch, getState) => {
    const state = getState();
    const { user } = state.user;
    user.getIdToken().then((token) => {
      const url = endPoints.END_POINT + `/g5sensors/report/statusMessages/${deviceId}?token=${token}`;
      downloadFileFromURl(url);
   });
  }
}

export const getG5StatusMessages = (deviceId) => (dispatch, getState) => {
  const url = `${endPoints.END_POINT}/deviceCommStatus/${deviceId}`;
  return fetchMiddleware(url, {}, getState);
}

export const getG5StatusMessagesEntries = (msgId) => (dispatch, getState) => {
  const url = `${endPoints.END_POINT}/deviceCommStatus/entries/${msgId}`;
  return fetchMiddleware(url, {}, getState);
}
