import { connect } from 'react-redux';
import PCAModal from '../../components/Modals/PCAModal';
import { savePCA } from '../../actions/AlertsActions';

const LITER_TO_GALLONS_FACTOR = 0.264172052;

const dateToDefaultValue = (dateInMillis) => {
  let dateValue;

  if (dateInMillis) {
    const date = new Date(dateInMillis);
    let day = date.getDate();
    let month = (date.getMonth() + 1);
    if (day.toString().length == 1) { day = "0" + day; }
    if (month.toString().length == 1) { month = "0" + month; }

    dateValue = date.getFullYear() + "-" + month + "-" + day;
  }

  return (dateValue);
};

const mapStateToProps = (state, ownProps) => {
  const path = state.routing.locationBeforeTransitions.pathname.replace("/", "");
  const options = state.leaksList.optionList.options;

  const alertId = state.leaksList.leaksByProject.selectedFeature;
  const projectId = state.leaksList.selectedProject;
  const projectState = state.leaksList.leaksByProject[projectId];
  const isSetStatusAsFixed = state.temp.isSetAsFixed;
  const units = state.local.units;

  let alertObject = {};
  if (path == 'inspect') {
    const inspectState = state.leaksList.inspect;
    alertObject = inspectState.alerts.data[inspectState.alerts.indexMap[alertId]];
  } else {
    if (projectState && projectState.items) {
      alertObject = projectState.items[projectState.indexMap[alertId]];
    }
    // if (alertObject == null) {
    //   console.error("AlertObject Is Null:");
		// 	console.dir(projectState);
    // }
  }

  let alertLatLng = null;
  let selectedCouple;
  let repairDate = null;
  let repairType = null;
  if (alertObject != null) {
    alertLatLng = {Latitude: alertObject.Latitude, Longitude: alertObject.Longitude};
    repairDate = alertObject.fixDate;
    repairType = alertObject.RepairCode;
    if (projectState && projectState.couples.indexMap) {
      selectedCouple = projectState.couples.couples[projectState.couples.indexMap[alertObject.CoupleID]];
    }
  }

  let initialValues = {
    GeneralReportedBy: state.leaksList.user.name
  };

  if (state.temp.tempPCA != undefined && state.temp.tempPCA != null) {
    const pcaData = state.temp.tempPCA.data;
    if (pcaData) {
      if (pcaData && pcaData.fixDate) {
        pcaData.EventDate = dateToDefaultValue(pcaData.fixDate);
      } else {
        const dateToday = new Date();

        let day = dateToday.getDate();
        let month = (dateToday.getMonth() + 1);
        if (day.toString().length == 1) { day = "0" + day; }
        if (month.toString().length == 1) { month = "0" + month; }

        pcaData.EventDate = dateToday.getFullYear() + "-" + month + "-" + day; // yyyy-MM-dd format
      }

      initialValues = {
        ...pcaData,
        RepairDate: pcaData.fixDate,
        RepairType: pcaData.RepairCode,
        //HistoricalPipeLaidDate: dateToDefaultValue(pcaData.HistoricalPipeLaidDate)
      };
      if (initialValues.WaterLossLH) {
        if (units !== 'm') {
          initialValues.WaterLossLH = (initialValues.WaterLossLH * LITER_TO_GALLONS_FACTOR).toFixed();
        }
      }
    } else {
      let materilaCode = 1;
      let defaultDiameter = 6;

      if (selectedCouple != null) {
        materilaCode = selectedCouple.MaterialCode;
        defaultDiameter = selectedCouple.DefaultDiameter;
      }

      initialValues = {
        PipeMaterialCode: materilaCode,
        PipeDiameterInch: defaultDiameter,
        EventNatureOfBreakCode: 2,
        EventCauseOfBreakCode: 0,
        EventCorrosionTypeCode: 2,
        LocationGroundTypeCode: 1,
        LocationTrafficCode: 2,
        LocationStreetSurfaceCode: 0,
        LocationWeatherConditionCode: 0,
        GeneralReportedBy: state.leaksList.user.name,
        HistoricalInstalltionBeddingCode: 1,
        HistoricalInstalltionBackfillCode: 1,
        Historical2WeeksPriorWeatherCode: 0,
        HistoricalPipeTypeCode: materilaCode,
        HistoricalCompactionCode: 0,
        RepairDate: repairDate,
        RepairType: repairType
      };
    }
  }

  return Object.assign({}, {
    options,
    initialValues,
    alertId,
    alertLatLng,
    projectId,
    isSetStatusAsFixed,
    units
  }, ownProps);
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const { isSetStatusAsFixed } = props;
      const updatedValues = { ...values };

      if (isSetStatusAsFixed) {
        const latLngProjObj = {...props.alertLatLng, projectId: props.projectId};
        updatedValues.latLngProjObj = latLngProjObj;
      } else {
        if (updatedValues.hasOwnProperty('RepairCode') != null) {
          delete updatedValues.RepairCode;
        }
        if (updatedValues.hasOwnProperty('fixDate') != null) {
          delete updatedValues.fixDate;
        }
      }

      if (updatedValues.hasOwnProperty('InsertDate') != null) {
        delete updatedValues.InsertDate;
      }

      if (updatedValues.WaterLossLH != null) {
        if (props.units !== 'm') {
          updatedValues.WaterLossLH = (updatedValues.WaterLossLH / LITER_TO_GALLONS_FACTOR).toFixed();
        }
      }

      dispatch(savePCA(updatedValues, props.alertId, isSetStatusAsFixed, (isOk) => {
        let msgLevel;
        let msgText;
        if (isOk) {
          msgLevel = 'success';
          msgText = 'Pipe assessment form saved';
        } else {
          msgLevel = 'error';
          msgText = 'Error. Pipe assessment not saved';
        }
        ownProps.notify(msgText, msgLevel);
      }));
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PCAModal);
