import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { SortableHeaderCell, TextCell, TableMixin } from '../../components';
import CTableActions from '../../containers/CTableActions/CTableActions';
import CSensorsSettings from '../../containers/CSensorsSettings/CSensorsSettings';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

class SOPsTable extends React.Component {

  render() {

    const { items, indexMap, selectedFeature, user, optionList, pHeight, pWidth, sortProp, rowClickEvent, goToRow, rowClassNameGetter, t } = this.props;
    let sopStateOptions = {};
    if (optionList.options.SopStatuses != null) {
      optionList.options.SopStatuses.forEach(state => {
        sopStateOptions[state.value] = state.label ;
      });
    }
    //document.title = 'AQS • Alerts';

    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          onRowClick={rowClickEvent}
          rowClassNameGetter={this.props.rowClassNameGetter}
          scrollToRow={goToRow}
          {...this.props}
        >
          <Column
            columnKey="id"
            width={100}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="id" />} />
          <Column
            columnKey="Type"
            width={100}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Meaning" />} />
          <Column
            columnKey="State"
            width={100}
            flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="State" options={sopStateOptions}/>} />
          <Column
            columnKey="StreetAddress"
            width={100}
            flexGrow={3}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="StreetAddress" />} />
          <Column
            columnKey="AssetID"
            width={100}
            flexGrow={3}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="AssetID" />} />
          <Column
            columnKey="Model"
            width={100}
            flexGrow={3}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Model" />} />
        </Table>

        {items && items.length > 0 &&
          <CTableActions
            type="sops"
          />
        }
        {items && items.length > 0 &&
          <span style={totalItemsStyle}>{t('Selected: {selectedIndex}. Total: {total}', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
        }

      </div>
    );
  }
}

SOPsTable = TableMixin(SOPsTable, CSensorsSettings);
export default SOPsTable;
