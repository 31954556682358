/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import ConfigurationForm from '../Operations/ConfigurationForm';
import ConfCorrelation from '../Operations/ConfCorrelation';
import ConfSetLTE from '../Operations/ConfSetLTE';
import ConfUpdateVersion from '../Operations/ConfUpdateVersion';
import ConfSetAPN from '../Operations/ConfSetAPN';
import ControlButtons from './ControlButtons';

import { types } from '../../../constants/G5MessagesTypes';

import UIDateFormater, { DateType } from '../../../containers/UIhelper/UIDateFormater';
import ConfSetServer from '../Operations/ConfSetServer';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { DEVICE_CONFIGURATION } from '../../../constants/ActionTypes';

const MIN_FM_CHANNEL = 8800;
const MAX_FM_CHANNEL = 10800;

const useStyles = makeStyles({
  root: {
    // display: 'flex',
    flexGrow: 1,
    // flexWrap: 'nowrap'
  },
  section: {
    backgroundColor: '#fafafa',
    padding: '4px',
    border: '1px solid black',
    borderRadius: '5px',
    margin: '2px',
    maxHeight: '82vh',
  }
});

function createFieldDefaultValue(defaults, defaultKey, field) {
  let result;
  if (defaults[defaultKey] != null) {
    if (defaultKey !== 'SchedulerRefTime') {
      result = { ...field, attr: { ...field.attr, defaultValue: defaults[defaultKey]} };
    } else {
      const refTimeMinutes = (defaults[defaultKey] * 10 * 60 * 1000) || 1;
      const refTimeSeconds = (defaults.SchedulerRef5SecResolutionTime || 0) * (5 * 1000)
      const displayValue = UIDateFormater(refTimeMinutes + refTimeSeconds, DateType.TIME, 'gmt');
      result = { ...field, attr: { defaultValue: displayValue} };
    }
  } else {
    result = { ...field };
  }
  return result;
}

const tcpIpFlags = [
  { optionKey: 'MSG_DONTROUTE', label: 'DONTROUTE' },
  { optionKey: 'MSG_DONTWAIT', label: 'DONTWAIT' },
  { optionKey: 'MSG_WAITFORONE', label: 'WAITFORONE' },
  { optionKey: 'MSG_BATCH', label: 'BATCH' },
  { optionKey: 'MSG_FASTOPEN', label: 'FASTOPEN' },
]

const correlationFieldsDisabled = false;

export default function CommandsLayout(props, context) {

  const g5Servers = useSelector((state) => state.leaksList.optionList.options.g5Servers);

  const initialFields = {
    Scheduler: [
      {
        id: 'refTime',
        title: context.t('Ref Time'),
        type: 'time',
        attr: {
          defaultValue: '00:00',
          timeformat: '24h'
        }
      },
      {
        id: 'corrNumSamples',
        title: context.t('Corr Num Samples'),
        type: 'number',
        attr: {
          defaultValue: 5
        }
      },
      {
        id: 'corrInterval',
        title: context.t('Corr Interval'),
        type: 'number'
      },
      {
        id: 'noiseNumSamples',
        title: context.t('Noise Num Samples'),
        type: 'number'
      },
      {
        id: 'noiseInterval',
        title: context.t('Noise Interval'),
        type: 'number'
      },
      {
        id: 'noiseTxTime',
        title: context.t('Noise Tx Time'),
        type: 'number'
      },
      {
        id: 'wakeUpBeforRadio',
        title: context.t('Wakeup Befor Radio'),
        type: 'number'
      },
      {
        id: 'randomDelay',
        title: context.t('Random Delay'),
        type: 'number',
        attr: {
          defaultValue: 0,
          min: 0,
          max: 5
        }
      },
    ],
    Audio: [
      {
        id: 'samplingFreq',
        title: context.t('Sampling Freq'),
        type: 'select',
        options: [
          { optionKey: 2000, label: '2000', disable: false},
          { optionKey: 4000, label: '4000', disable: false},
        ],
        attr: {
          defaultValue: 2000
        }
      },
      {
        id: 'sampleDuration',
        title: context.t('Sample Duratin'),
        type: 'number'
      },
      // {
      //   id: 'reserved',
      //   title: context.t('Reserved'),
      //   type: 'number'
      // },
      {
        id: 'notchFilter',
        title: context.t('Notch Filter'),
        type: 'number'
      },
      {
        id: 'compressEnabled',
        title: context.t('Compress Enabled'),
        type: 'number'
      },
      {
        id: 'audioEnable',
        title: context.t('Audio Enabled'),
        type: 'number'
      },
    ],
    FmReceiver: [
      {
        id: 'channel10k',
        title: context.t('Channel 10k'),
        type: 'number',
        attr: {
          defaultValue: MIN_FM_CHANNEL,
          min: MIN_FM_CHANNEL,
          max: MAX_FM_CHANNEL
        }
      },
    // {
    //   id: 'reginalTime',
    //   title: context.t('Reginal Time'),
    //   type: 'number'
    // },
    // {
    //   id: 'muteThreshold',
    //   title: context.t('Mute Threshold'),
    //   type: 'number'
    // },
    // {
    //   id: 'compressMode',
    //   title: context.t('Compress Mode'),
    //   type: 'number'
    // },
    ],
    Correlation: [
      {
        id: 'audioDuration',
        title: context.t('Audio Duration'),
        type: 'number'
      },
      {
        id: 'radioDuration',
        title: context.t('Radio Duration'),
        type: 'number'
      },
      {
        id: 'audioFS2N',
        title: context.t('Audio FS'),
        type: 'select',
        options: [
          { optionKey: 5, label: '5' },
          { optionKey: 10, label: '10' },
          { optionKey: 20, label: '20' },
          { optionKey: 40, label: '40' },
        ]
      },
      {
        id: 'samplingWindow',
        title: context.t('Sampling Window'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'audioNumBands',
        title: context.t('Audio Num Bands'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'audioNumCoff',
        title: context.t('Audio Num Coff'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'audioBandMask1',
        title: context.t('Audio Band Mask 1'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'audioBandMask2',
        title: context.t('Audio Band Mask 2'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'audioBandMask3',
        title: context.t('Audio Band Mask 3'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'audioBandMask4',
        title: context.t('Audio Band Mask 4'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'radioNumBands',
        title: context.t('Radio Num Bands'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'radioNumCoff',
        title: context.t('Radio Num Coff'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'radioBandMask1',
        title: context.t('Radio Band Mask 1'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'radioBandMask2',
        title: context.t('Radio Band Mask 2'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'radioBandMask3',
        title: context.t('Radio Band Mask 3'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'radioBandMask4',
        title: context.t('Radio Band Mask 4'),
        type: 'text',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 1,
        }
      },
      {
        id: 'compressEnabled',
        title: context.t('Compress Enabled'),
        type: 'number',
        disabled: correlationFieldsDisabled,
        attr: {
          defaultValue: 0,
        }
      },
    ],
    FmScan: [1,2,3,4,5].map((x) => ({
      id: `chnl-${x}`,
      title: context.t('Channel {num}', {num: x}),
      type: 'number',
      attr: {
        defaultValue: MIN_FM_CHANNEL,
        min: MIN_FM_CHANNEL,
        max: MAX_FM_CHANNEL,
      }
    })),
    UpdateVersion: [
      {
        id: 'fwVersion',
        title: context.t('Version'),
        type: 'select',
        disabled: props.user.g5AqsAdmin,
        options: []
      }
    ],
    UpdateModemVersion: [
      {
        id: 'fwVersion',
        title: context.t('Version'),
        type: 'select',
        disabled: props.user.g5AqsAdmin,
        options: []
      }
    ],
    LteMode: [
      {
        id: 'lteMode',
        title: context.t('Mode'),
        type: 'select',
        disabled: props.user.g5AqsAdmin,
        options: [
          { optionKey: 0, label: context.t('CAT-M preferred'), disable: false},
          { optionKey: 1, label: context.t('NB-IOT preferred'), disable: false},
          { optionKey: 2, label: context.t('CAT-M Only'), disable: !props.advancedOptions},
          { optionKey: 3, label: context.t('NB-IOT Only'), disable: !props.advancedOptions},
        ]
      }
    ],
    TimeZone: [
      {
        id: 'timeZone',
        title: context.t('Zone'),
        type: 'select',
        options: [
          { optionKey: "Dateline Standard Time", label: " (UTC-12:00) International Date Line West" },
          { optionKey: "UTC-11", label: " (UTC-11:00) Coordinated Universal Time-11" },
          { optionKey: "Hawaiian Standard Time", label: " (UTC-10:00) Hawaii" },
          { optionKey: "Alaskan Standard Time", label: " (UTC-09:00) Alaska" },
          { optionKey: "Pacific Standard Time (Mexico)", label: " (UTC-08:00) Baja California" },
          { optionKey: "Pacific Standard Time", label: " (UTC-08:00) Pacific Time (US & Canada)" },
          { optionKey: "US Mountain Standard Time", label: " (UTC-07:00) Arizona" },
          { optionKey: "Mountain Standard Time (Mexico)", label: " (UTC-07:00) Chihuahua, La Paz, Mazatlan" },
          { optionKey: "Mountain Standard Time", label: " (UTC-07:00) Mountain Time (US & Canada)" },
          { optionKey: "Central America Standard Time", label: " (UTC-06:00) Central America" },
          { optionKey: "Central Standard Time", label: " (UTC-06:00) Central Time (US & Canada)" },
          { optionKey: "Central Standard Time (Mexico)", label: " (UTC-06:00) Guadalajara, Mexico City, Monterrey" },
          { optionKey: "Canada Central Standard Time", label: " (UTC-06:00) Saskatchewan" },
          { optionKey: "SA Pacific Standard Time", label: " (UTC-05:00) Bogota, Lima, Quito, Rio Branco" },
          { optionKey: "Eastern Standard Time (Mexico)", label: " (UTC-05:00) Chetumal" },
          { optionKey: "Eastern Standard Time", label: " (UTC-05:00) Eastern Time (US & Canada)" },
          { optionKey: "US Eastern Standard Time", label: " (UTC-05:00) Indiana (East)" },
          { optionKey: "Venezuela Standard Time", label: " (UTC-04:30) Caracas" },
          { optionKey: "Paraguay Standard Time", label: " (UTC-04:00) Asuncion" },
          { optionKey: "Atlantic Standard Time", label: " (UTC-04:00) Atlantic Time (Canada)" },
          { optionKey: "Central Brazilian Standard Time", label: " (UTC-04:00) Cuiaba" },
          { optionKey: "SA Western Standard Time", label: " (UTC-04:00) Georgetown, La Paz, Manaus, San Juan" },
          { optionKey: "Newfoundland Standard Time", label: " (UTC-03:30) Newfoundland" },
          { optionKey: "E. South America Standard Time", label: " (UTC-03:00) Brasilia" },
          { optionKey: "SA Eastern Standard Time", label: " (UTC-03:00) Cayenne, Fortaleza" },
          { optionKey: "Argentina Standard Time", label: " (UTC-03:00) City of Buenos Aires" },
          { optionKey: "Greenland Standard Time", label: " (UTC-03:00) Greenland" },
          { optionKey: "Montevideo Standard Time", label: " (UTC-03:00) Montevideo" },
          { optionKey: "Bahia Standard Time", label: " (UTC-03:00) Salvador" },
          { optionKey: "Pacific SA Standard Time", label: " (UTC-03:00) Santiago" },
          { optionKey: "UTC-02", label: " (UTC-02:00) Coordinated Universal Time-02" },
          { optionKey: "Mid-Atlantic Standard Time", label: " (UTC-02:00) Mid-Atlantic - Old" },
          { optionKey: "Azores Standard Time", label: " (UTC-01:00) Azores" },
          { optionKey: "Cape Verde Standard Time", label: " (UTC-01:00) Cabo Verde Is." },
          { optionKey: "Morocco Standard Time", label: " (UTC) Casablanca" },
          { optionKey: "UTC", label: " (UTC) Coordinated Universal Time" },
          { optionKey: "GMT Standard Time", label: " (UTC) Dublin, Edinburgh, Lisbon, London" },
          { optionKey: "Greenwich Standard Time", label: " (UTC) Monrovia, Reykjavik" },
          { optionKey: "W. Europe Standard Time", label: " (UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
          { optionKey: "Central Europe Standard Time", label: " (UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
          { optionKey: "Romance Standard Time", label: " (UTC+01:00) Brussels, Copenhagen, Madrid, Paris" },
          { optionKey: "Central European Standard Time", label: " (UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
          { optionKey: "W. Central Africa Standard Time", label: " (UTC+01:00) West Central Africa" },
          { optionKey: "Namibia Standard Time", label: " (UTC+01:00) Windhoek" },
          { optionKey: "Jordan Standard Time", label: " (UTC+02:00) Amman" },
          { optionKey: "GTB Standard Time", label: " (UTC+02:00) Athens, Bucharest" },
          { optionKey: "Middle East Standard Time", label: " (UTC+02:00) Beirut" },
          { optionKey: "Egypt Standard Time", label: " (UTC+02:00) Cairo" },
          { optionKey: "Syria Standard Time", label: " (UTC+02:00) Damascus" },
          { optionKey: "E. Europe Standard Time", label: " (UTC+02:00) E. Europe" },
          { optionKey: "South Africa Standard Time", label: " (UTC+02:00) Harare, Pretoria" },
          { optionKey: "FLE Standard Time", label: " (UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
          { optionKey: "Turkey Standard Time", label: " (UTC+02:00) Istanbul" },
          { optionKey: "Israel Standard Time", label: " (UTC+02:00) Jerusalem" },
          { optionKey: "Kaliningrad Standard Time", label: " (UTC+02:00) Kaliningrad (RTZ 1)" },
          { optionKey: "Libya Standard Time", label: " (UTC+02:00) Tripoli" },
          { optionKey: "Arabic Standard Time", label: " (UTC+03:00) Baghdad" },
          { optionKey: "Arab Standard Time", label: " (UTC+03:00) Kuwait, Riyadh" },
          { optionKey: "Belarus Standard Time", label: " (UTC+03:00) Minsk" },
          { optionKey: "Russian Standard Time", label: " (UTC+03:00) Moscow, St. Petersburg, Volgograd (RTZ 2)" },
          { optionKey: "E. Africa Standard Time", label: " (UTC+03:00) Nairobi" },
          { optionKey: "Iran Standard Time", label: " (UTC+03:30) Tehran" },
          { optionKey: "Arabian Standard Time", label: " (UTC+04:00) Abu Dhabi, Muscat" },
          { optionKey: "Azerbaijan Standard Time", label: " (UTC+04:00) Baku" },
          { optionKey: "Russia Time Zone 3", label: " (UTC+04:00) Izhevsk, Samara (RTZ 3)" },
          { optionKey: "Mauritius Standard Time", label: " (UTC+04:00) Port Louis" },
          { optionKey: "Georgian Standard Time", label: " (UTC+04:00) Tbilisi" },
          { optionKey: "Caucasus Standard Time", label: " (UTC+04:00) Yerevan" },
          { optionKey: "Afghanistan Standard Time", label: " (UTC+04:30) Kabul" },
          { optionKey: "West Asia Standard Time", label: " (UTC+05:00) Ashgabat, Tashkent" },
          { optionKey: "Ekaterinburg Standard Time", label: " (UTC+05:00) Ekaterinburg (RTZ 4)" },
          { optionKey: "Pakistan Standard Time", label: " (UTC+05:00) Islamabad, Karachi" },
          { optionKey: "India Standard Time", label: " (UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
          { optionKey: "Sri Lanka Standard Time", label: " (UTC+05:30) Sri Jayawardenepura" },
          { optionKey: "Nepal Standard Time", label: " (UTC+05:45) Kathmandu" },
          { optionKey: "Central Asia Standard Time", label: " (UTC+06:00) Astana" },
          { optionKey: "Bangladesh Standard Time", label: " (UTC+06:00) Dhaka" },
          { optionKey: "N. Central Asia Standard Time", label: " (UTC+06:00) Novosibirsk (RTZ 5)" },
          { optionKey: "Myanmar Standard Time", label: " (UTC+06:30) Yangon (Rangoon)" },
          { optionKey: "SE Asia Standard Time", label: " (UTC+07:00) Bangkok, Hanoi, Jakarta" },
          { optionKey: "North Asia Standard Time", label: " (UTC+07:00) Krasnoyarsk (RTZ 6)" },
          { optionKey: "China Standard Time", label: " (UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
          { optionKey: "North Asia East Standard Time", label: " (UTC+08:00) Irkutsk (RTZ 7)" },
          { optionKey: "Singapore Standard Time", label: " (UTC+08:00) Kuala Lumpur, Singapore" },
          { optionKey: "W. Australia Standard Time", label: " (UTC+08:00) Perth" },
          { optionKey: "Taipei Standard Time", label: " (UTC+08:00) Taipei" },
          { optionKey: "Ulaanbaatar Standard Time", label: " (UTC+08:00) Ulaanbaatar" },
          { optionKey: "North Korea Standard Time", label: " (UTC+08:30) Pyongyang" },
          { optionKey: "Tokyo Standard Time", label: " (UTC+09:00) Osaka, Sapporo, Tokyo" },
          { optionKey: "Korea Standard Time", label: " (UTC+09:00) Seoul" },
          { optionKey: "Yakutsk Standard Time", label: " (UTC+09:00) Yakutsk (RTZ 8)" },
          { optionKey: "Cen. Australia Standard Time", label: " (UTC+09:30) Adelaide" },
          { optionKey: "AUS Central Standard Time", label: " (UTC+09:30) Darwin" },
          { optionKey: "E. Australia Standard Time", label: " (UTC+10:00) Brisbane" },
          { optionKey: "AUS Eastern Standard Time", label: " (UTC+10:00) Canberra, Melbourne, Sydney" },
          { optionKey: "West Pacific Standard Time", label: " (UTC+10:00) Guam, Port Moresby" },
          { optionKey: "Tasmania Standard Time", label: " (UTC+10:00) Hobart" },
          { optionKey: "Magadan Standard Time", label: " (UTC+10:00) Magadan" },
          { optionKey: "Vladivostok Standard Time", label: " (UTC+10:00) Vladivostok, Magadan (RTZ 9)" },
          { optionKey: "Russia Time Zone 10", label: " (UTC+11:00) Chokurdakh (RTZ 10)" },
          { optionKey: "Central Pacific Standard Time", label: " (UTC+11:00) Solomon Is., New Caledonia" },
          { optionKey: "Russia Time Zone 11", label: " (UTC+12:00) Anadyr, Petropavlovsk-Kamchatsky (RTZ 11)" },
          { optionKey: "New Zealand Standard Time", label: " (UTC+12:00) Auckland, Wellington" },
          { optionKey: "UTC+12", label: " (UTC+12:00) Coordinated Universal Time+12" },
          { optionKey: "Fiji Standard Time", label: " (UTC+12:00) Fiji" },
          { optionKey: "Kamchatka Standard Time", label: " (UTC+12:00) Petropavlovsk-Kamchatsky - Old" },
          { optionKey: "Tonga Standard Time", label: " (UTC+13:00) Nuku'alofa" },
          { optionKey: "Samoa Standard Time", label: " (UTC+13:00) Samoa" },
          { optionKey: "Line Islands Standard Time", label: " (UTC+14:00) Kiritimati Island" },
        ]
      }
    ],
    AgcConfig: [
      {
        id: 'agcConfigThreshold1',
        title: context.t('Threshold 1'),
        type: 'number',
      },
      {
        id: 'agcConfigThreshold2',
        title: context.t('Threshold 2'),
        type: 'number'
      },
      {
        id: 'agcConfigThreshold3',
        title: context.t('Threshold 3'),
        type: 'number'
      },
      {
        id: 'agcConfigConf',
        title: context.t('AGC Config'),
        type: 'select',
        options: [
          { optionKey: 0, label: context.t('Auto Gain') },
          { optionKey: 1, label: context.t('Gain 1') },
          { optionKey: 2, label: context.t('Gain 2') },
          { optionKey: 3, label: context.t('Gain 3') },
          { optionKey: 4, label: context.t('Gain 4') },
        ]
      }
    ],
    TimeSrc: [
      {
        id: 'timeSrc',
        title: context.t('Set Time Source'),
        type: 'select',
        options: [
          { optionKey: 0, label: context.t('TLV Server Time'), disable: false},
          { optionKey: 1, label: context.t('Cellular Time'), disable: false},
        ]
      }
    ],
    SetAPN: [
      {
        id: 'setApn',
        title: context.t('APN'),
        type: 'select',
        disabled: !props.user.g5AqsAdmin,
        options: []
      }
    ],
    SetServer: [
      {
        id: 'server',
        title: context.t('Domain'),
        type: 'select',
        disabled: !props.user.g5AqsAdmin,
        options: g5Servers.map(({ id, display_name }) => ({
          optionKey: id,
          label: display_name,
          disable: false
        }))
        // options: [
        //   { optionKey: 'tlv.aqs-sys.com', label: context.t('TLV'), disable: false },
        //   { optionKey: 'tlv1.aqs-sys.com', label: context.t('TLV 1'), disable: false },
        //   { optionKey: 'tlv2.aqs-sys.com', label: context.t('TLV 2'), disable: false },
        // ]
      }
    ],
    SetFallbackServer: [
      {
        id: 'server',
        title: context.t('Domain'),
        type: 'select',
        disabled: !props.user.g5AqsAdmin,
        options: g5Servers.filter((item) => item.is_fallback).map(({ id, display_name }) => ({
          optionKey: id,
          label: display_name,
          disable: false
        }))
      }
    ],
    Capabilities: [
      ...[
        'adhoc_cor',
        'second_keepalive',
        'replace_lost_sample',
        'active_eDRX',
        'secure_BLE',
        'random_backoff_connect',
        'send_high_proirity_cor',
        'simulation_mode',
        'block_ota',
        'Comm_Process',
        'Clock_PPS',
      ].map((key) => ({
        id: key,
        title: key.replaceAll('_', ' '),
        type: 'checkbox'
      })),
      {
        id: 'minimal_noise',
        title: 'minimal noise',
        type: 'select',
        options: [
          {
            optionKey: 'rms_quiet',
            label: 'rms quiet',
          },
          {
            optionKey: 'total_std',
            label: 'total std',
          },
          {
            optionKey: 'total_rms',
            label: 'total rms',
          }
        ],
        attr: {
          defaultValue: 'rms_quiet'
        }
      }
    ],
    TcpIpConfiguration: [
      {
        id: 'catm_packet_size',
        title: 'catm_packet_size',
        type: 'number',
        attr: {
          min: 250,
          max: 4000,
          defaultValue: 1024,
          placeholder: 'optimal 708, maximum 4000'
        }
      },
      {
        id: 'catm_tx_timeout',
        title: 'catm_tx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 60,
        }
      },
      {
        id: 'catm_rx_timeout',
        title: 'catm_rx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 30,
        }
      },
      ...tcpIpFlags.map((flag) => ({
        id: `catm_${flag.optionKey}`,
        title: `catm ${flag.label}`,
        type: 'checkbox'
      })),
      {
        id: 'nbiot_packet_size',
        title: 'nbiot_packet_size',
        type: 'number',
        attr: {
          min: 250,
          max: 4000,
          defaultValue: 1024,
          placeholder: 'optimal 708, maximum 4000'
        }
      },
      {
        id: 'nbiot_tx_timeout',
        title: 'nbiot_tx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 60,
        }
      },
      {
        id: 'nbiot_rx_timeout',
        title: 'nbiot_rx_timeout',
        type: 'number',
        attr: {
          min: 5,
          max: 60,
          defaultValue: 30,
        }
      },
      ...tcpIpFlags.map((flag) => ({
        id: `nbiot_${flag.optionKey}`,
        title: `nbiot ${flag.label}`,
        type: 'checkbox'
      })),
    ]
  };

  React.useEffect(() => {
    if (props.user.g5Admin) {
      initialFields.UpdateVersion = [
        {
          id: 'fwVersion',
          title: context.t('Version'),
          type: 'select',
          options: props.fwVersions && props.fwVersions.map((versionName) => ({ optionKey: versionName, label: versionName }))
        }
      ];

      setVersion(initialFields.UpdateVersion);
    }
  }, [props.fwVersions]);

  React.useEffect(() => {
    if (props.user.g5Admin) {
      initialFields.UpdateModemVersion = [
        {
          id: 'fwModemVersion',
          title: context.t('Modem Version'),
          type: 'select',
          options: props.fwModemVersions && props.fwModemVersions.map((versionName) => ({ optionKey: versionName, label: versionName }))
        }
      ];

      setModemVersion(initialFields.UpdateModemVersion);
    }
  }, [props.fwModemVersions]);

  const [fieldsScheduler, setSchedulerFields] = React.useState(initialFields.Scheduler);
  const [fieldsAudio, setAudioFields] = React.useState(initialFields.Audio);
  const [fieldsFmReceiver, setFmReceiverFields] = React.useState(initialFields.FmReceiver);
  const [fieldsCorrelation, setCorrelationFields] = React.useState(initialFields.Correlation);
  const [fieldsFmScan, setFmScanFields] = React.useState(initialFields.FmScan);
  const [fieldsVersion, setVersion] = React.useState([]);
  const [fieldsModemVersion, setModemVersion] = React.useState([]);
  const [fieldsTimeZone, setTimeZone] = React.useState(initialFields.TimeZone);

  const [fieldsSetApn, setSetApn] = React.useState(initialFields.SetAPN);
  const [fieldsSetServer, setSetServer] = React.useState(initialFields.SetServer);
  const [fieldsSetTimeSrc, setSetTimeSrc] = React.useState(initialFields.TimeSrc);
  const [fieldsSetAgcConfig, setSetAgcConfig] = React.useState(initialFields.AgcConfig);
  const [fieldsSetFallbackServer, setSetFallbackServer] = React.useState(initialFields.SetFallbackServer);
  // const [fieldsCapabiliies, setCapabiliies] = React.useState(initialFields.Capabilities);

  const [defaultCorr, setDefaultCorr] = React.useState(null);

  const appDispatch = useDispatch();
  const defaultValues = useSelector((state) => state.devcieConfigurations.defaultProjectsValues);


  React.useEffect(() => {
    const schedulerValues = [];
    const audioValues = [];
    const fmReceiverValues = [];
    const correlationValues = [];
    const fmScanValues = [];
    const timeZoneValues = [];
    const lteModeValues = [];
    const agcConfig = [];
    const timeSource = [];
    const apn = [];
    const fallbackServer = [];
    // const fallbackServer = [
    //   {
    //     id: 'server',
    //     title: context.t('Domain'),
    //     type: 'select',
    //     disabled: !props.user.g5AqsAdmin,
    //     options: g5Servers.map(({ id, display_name }) => ({
    //       optionKey: id,
    //       label: display_name,
    //       disable: false
    //     }))
    //   }
    // ];

    const correlationDefaultValues = {};

    Object.entries(initialFields).forEach((confFieldsEntry) => {
      const [type, fields] = confFieldsEntry;
      switch (type) {
        case 'Scheduler':
          fields.forEach((field) => {
            switch (field.id) {
              case 'refTime': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerRefTime', field)); break;
              case 'corrNumSamples': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerCorrNumSamples', field)); break;
              case 'corrInterval': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerCorrInterval', field)); break;
              case 'noiseNumSamples': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerNoiseNumSamples', field)); break;
              case 'noiseInterval': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerNoiseInterval', field)); break;
              case 'noiseTxTime': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerNoiseTxTime', field)); break;
              case 'wakeUpBeforRadio': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchdulerWakeUpBeforeRadio_sec', field)); break;
              case 'randomDelay': schedulerValues.push(createFieldDefaultValue(defaultValues, 'SchedulerRandomDelay', field)); break;
              default: schedulerValues.push(field); break;
            }
          });
          break;

        case 'Audio':
          fields.forEach((field) => {
            switch (field.id) {
              case 'samplingFreq': audioValues.push(createFieldDefaultValue(defaultValues, 'AudioSamplingFrequency', field)); break;
              case 'sampleDuration': audioValues.push(createFieldDefaultValue(defaultValues, 'AudioRecordingDurationSec', field)); break;
              case 'notchFilter': audioValues.push(createFieldDefaultValue(defaultValues, 'AudioNotchFilter', field)); break;
              case 'compressEnabled': audioValues.push(createFieldDefaultValue(defaultValues, 'AudioCompressionEnabled', field)); break;
              case 'audioEnable': audioValues.push(createFieldDefaultValue(defaultValues, 'AudioReportEnabled', field)); break;
              default: audioValues.push(field); break;
            }
          });
          break;

        case 'FmReceiver':
          fields.forEach((field) => {
            switch (field.id) {
              case 'channel10k': fmReceiverValues.push(createFieldDefaultValue(defaultValues, 'FmReceiverChannel10k', field)); break;
              default: fmReceiverValues.push(field); break;
            }
          });
          break;
        case 'Correlation':
          fields.forEach((field) => {
            switch (field.id) {
              case 'audioDuration':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationAudioRecordingDurationSec', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationAudioRecordingDurationSec']
                break;
              case 'radioDuration':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationRadioRecordingDurationSec', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationRadioRecordingDurationSec']
                break;
              case 'audioFS2N':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationAudioSamplingFrequency', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationAudioSamplingFrequency']
                break;
              case 'samplingWindow':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationRadioAndAudioWindowSec', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationRadioAndAudioWindowSec']
                break;
              case 'audioNumBands':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationAudioNumFrequencyBands', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationAudioNumFrequencyBands']
                break;
              case 'audioNumCoff':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationAudioNumCoefficients', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationAudioNumCoefficients']
                break;
              // case 'audioBandMask1': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'audioBandMask2': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'audioBandMask3': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'audioBandMask4': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              case 'radioNumBands':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationRadioNumFrequencyBands', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationRadioNumFrequencyBands']
                break;
              case 'radioNumCoff':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationRadioNumCoefficients', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationRadioNumCoefficients']
                break;
              // case 'radioBandMask1': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'radioBandMask2': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'radioBandMask3': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              // case 'radioBandMask4': schedulerValues.push(createFieldDefaultValue(defaultValues, '', field)); break;
              case 'compressEnabled':
                correlationValues.push(createFieldDefaultValue(defaultValues, 'CorrelationCompressionEnabled', field));
                correlationDefaultValues[field.id] = defaultValues['CorrelationCompressionEnabled']
                break;
              default: correlationValues.push(field); break;
            }
          });
          break;
        case 'FmScan':
          fields.forEach((field) => {
            switch (field.id) {
              case 'chnl-1': fmScanValues.push(createFieldDefaultValue(defaultValues, 'FmScanChannel1_10k', field)); break;
              case 'chnl-2': fmScanValues.push(createFieldDefaultValue(defaultValues, 'FmScanChannel2_10k', field)); break;
              case 'chnl-3': fmScanValues.push(createFieldDefaultValue(defaultValues, 'FmScanChannel3_10k', field)); break;
              case 'chnl-4': fmScanValues.push(createFieldDefaultValue(defaultValues, 'FmScanChannel4_10k', field)); break;
              case 'chnl-5': fmScanValues.push(createFieldDefaultValue(defaultValues, 'FmScanChannel5_10k', field)); break;
              default: fmScanValues.push(field); break;
            }
          });
          break;
        case 'TimeZone':
          fields.forEach((field) => {
            switch (field.id) {
              case 'timeZone': timeZoneValues.push(createFieldDefaultValue(defaultValues, 'TimeZone', field)); break;
              default: timeZoneValues.push(field); break;
            }
          });
          break;
        case 'AgcConfig':
          fields.forEach((field) => {
            switch (field.id) {
              case 'agcConfigThreshold1': agcConfig.push(createFieldDefaultValue(defaultValues, 'AGCThreshold1', field)); break;
              case 'agcConfigThreshold2': agcConfig.push(createFieldDefaultValue(defaultValues, 'AGCThreshold2', field)); break;
              case 'agcConfigThreshold3': agcConfig.push(createFieldDefaultValue(defaultValues, 'AGCThreshold3', field)); break;
              case 'agcConfigConf': agcConfig.push(createFieldDefaultValue(defaultValues, 'AGC_Config', field)); break;
            }
          });
          break;
        case 'TimeSrc':
          fields.forEach((field) => {
            switch (field.id) {
              case 'timeSrc': timeSource.push(createFieldDefaultValue(defaultValues, 'AGC_Config', field)); break;
            }
          });
          break;
        case 'SetAPN':
          const apnField = createFieldDefaultValue(defaultValues, 'APN', initialFields['SetAPN'][0]);
          apnField.options = [{ optionKey: defaultValues['APN'], label: defaultValues['APN'], disable: false}];
          apn.push(apnField);
          break;
        case 'SetFallbackServer':
          fallbackServer.push(createFieldDefaultValue(defaultValues, 'FallbackServer', initialFields.SetFallbackServer[0]));
          break;

        default:
          break;
      }
    });
    // Object.entries(defaultValues).forEach((entry) => {
    //   const [key, value] = entry;
    //   switch (key) {
    //     case "SchedulerRefTime": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'refTime', value)); break;
    //     case "SchedulerCorrNumSamples": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'corrNumSamples',value)); break;
    //     case "SchedulerCorrInterval": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'corrInterval',value)); break;
    //     case "SchedulerNoiseNumSamples": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'noiseNumSamples',value)); break;
    //     case "SchedulerNoiseInterval": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'noiseInterval',value)); break;
    //     case "SchedulerNoiseTxTime": schedulerValues.push(createFieldDefaultValue(initialFields.Scheduler, 'noiseTxTime',value)); break;

    //     case "AudioSamplingFrequency": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'samplingFreq', value)); break;
    //     case "AudioRecordingDurationSec": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'sampleDuration', value)); break;
    //     case "AudioNotchFilter": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'notchFilter', value)); break;
    //     case "AudioCompressionEnabled": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'compressEnabled', value)); break;
    //     case "AudioReportEnabled": audioValues.push(createFieldDefaultValue(initialFields.Audio, 'audioEnable', value)); break;

    //     case "CorrelationAudioRecordingDurationSec": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioDuration', value)); break;
    //     case "CorrelationRadioRecordingDurationSec": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'radioDuration', value)); break;
    //     case "CorrelationAudioSamplingFrequency": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioFS2N', value)); break;
    //     case "CorrelationAudioNumFrequencyBands": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioNumBands', value)); break;
    //     case "CorrelationRadioAndAudioWindowSec": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'samplingWindow', value)); break;
    //     case "CorrelationAudioNumCoefficients": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'audioNumCoff', value)); break;
    //     // case "CorrelationAudioIndexBandToSend": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, '', value)); break;
    //     case "CorrelationRadioNumFrequencyBands": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'radioNumBands', value)); break;
    //     case "CorrelationRadioNumCoefficients": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'radioNumCoff', value)); break;
    //     // case "CorrelationRadioIndexBandToSend": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, '', value)); break;
    //     case "CorrelationCompressionEnabled": correlationValues.push(createFieldDefaultValue(initialFields.Correlation, 'compressEnabled', value)); break;

    //     case "FmReceiverChannel10k": fmReceiverValues.push(createFieldDefaultValue(initialFields.FmReceiver, 'channel10k', value)); break;
    //     // case "FmReceiverRegionallyConstTime": break;
    //     // case "FmReceiverMuteSNRThreshold": break;
    //     // case "FmReceiverCompressionModeStatus": break;
    //     case "TimeZone": break;

    //     case "FmScanChannel1_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-1', value)); break;
    //     case "FmScanChannel2_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-2', value)); break;
    //     case "FmScanChannel3_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-3', value)); break;
    //     case "FmScanChannel4_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-4', value)); break;
    //     case "FmScanChannel5_10k": fmScanValues.push(createFieldDefaultValue(initialFields.FmScan, 'chnl-5', value)); break;
    //   }
    // });

    setSchedulerFields(schedulerValues);
    setAudioFields(audioValues);
    setFmReceiverFields(fmReceiverValues);
    setCorrelationFields(correlationValues);
    setFmScanFields(fmScanValues);
    setTimeZone(timeZoneValues);
    setSetAgcConfig(agcConfig);
    setSetTimeSrc(timeSource);
    setSetApn(apn);
    setSetFallbackServer(fallbackServer);

    setDefaultCorr(correlationDefaultValues);
  }, [defaultValues]);

  function handleGetProjectDefaults() {
    props.getDefault().then((json) => {
      if (json.status) {
        const defaultValues = json.data[0] || {};

        appDispatch({ type: DEVICE_CONFIGURATION.SET_DEFAULT_PROJECT_VALUES, payload: defaultValues });
      }
    });
  }

  const classes = useStyles();

  const TypographyTitle = (p) => (
    <Typography
      component='h1'
      // align='center'
      style={{ margin: 0, fontSize: 'large' }}>
      {p.children}
    </Typography>
  );

  return (
    <Grid
      container
      className={classes.root}
      justify="center"
      direction={'row'}
      wrap='nowrap'
    >
      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>Reset</TypographyTitle>
            <button onClick={handleGetProjectDefaults}>Get Project Defaults</button>
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>Scheduler</TypographyTitle>
            <ConfigurationForm
              fields={fieldsScheduler}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.Scheduler, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.Scheduler, props.notify)}
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>Audio</TypographyTitle>
            <ConfigurationForm
              fields={fieldsAudio}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.Audio, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.Audio, props.notify)}
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>FM Receiver</TypographyTitle>
            <ConfigurationForm
              fields={fieldsFmReceiver}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.FmReceiver, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.FmReceiver, props.notify)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>Correlation</TypographyTitle>
            <ConfCorrelation
              // fields={fieldsCorrelation}
              defaultsValues={defaultCorr}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.Correlation, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.Correlation, props.notify)}
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>Time Zone</TypographyTitle>
            <ConfigurationForm
              fields={fieldsTimeZone}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.TimeZone, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.TimeZone, props.notify)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>Control Buttons</TypographyTitle>
            <ControlButtons
              user={props.user}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5Admin}
              sendCommand={(command) => props.updateConfig(null, command, props.notify)}/>
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>Set LTE Mode</TypographyTitle>
            <ConfSetLTE
              countSelected={props.selectedItems.length}
              enable={props.enable && props.user.g5Admin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.SetLTEMode, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.SetLTEMode, props.notify)}
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>Set FM Scan</TypographyTitle>
            <ConfigurationForm
              fields={fieldsFmScan}
              enable={props.enable && props.user.g5AqsAdmin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.SetFmScan, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.SetFmScan, props.notify)}
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>Update Version</TypographyTitle>
            <ConfUpdateVersion
              type='fwVersion'
              countSelected={props.selectedItems.length}
              fields={fieldsVersion}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.FirmwareUpdate, props.notify)}
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>Update Modem Version</TypographyTitle>
            <ConfUpdateVersion
              type='fwModemVersion'
              countSelected={props.selectedItems.length}
              fields={fieldsModemVersion}
              enable={props.enable && props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.ModemFirmwareUpdate, props.notify)}
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>Set APN</TypographyTitle>
            <ConfSetAPN
              countSelected={props.selectedItems.length}
              fields={fieldsSetApn}
              enable={props.enable && props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.Cmd_SetApn, props.notify)}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item>
        <Grid container direction='column'>
          <Grid item className={classes.section}>
            <TypographyTitle>Set Time Source</TypographyTitle>
            <ConfigurationForm
              fields={fieldsSetTimeSrc}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.Cmd_SetTimeSourcePref, props.notify)}
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>AGC Config</TypographyTitle>
            <ConfigurationForm
              fields={fieldsSetAgcConfig}
              enable={props.enable && props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.Cmd_SetAGCConfig, props.notify)}
            />
          </Grid>

          <Grid item className={classes.section}>
            <TypographyTitle>Set Server</TypographyTitle>
            <ConfSetServer
              countSelected={props.selectedItems.length}
              fields={fieldsSetServer}
              enable={props.enable && props.user.g5Admin}
              updateConfig={(values) => props.updateConfig(values, types.SetServer, props.notify)}
            />
          </Grid>
          <Grid item className={classes.section}>
            <TypographyTitle>Set Secondary Server</TypographyTitle>
            {/* <ConfSetServer
              countSelected={props.selectedItems.length}
              fields={fieldsSetFallbackServer}
              enable={props.enable && props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.SET_FALLBACK_SERVER, props.notify)}
            /> */}
            <ConfigurationForm
              fields={fieldsSetFallbackServer}
              enable={props.enable && props.user.g5AqsAdmin}
              advancedOptions={props.user.g5AqsAdmin}
              updateConfig={(values) => props.updateConfig(values, types.SET_FALLBACK_SERVER, props.notify)}
              onSaveDefault={(values) => props.updateDefaultConfig(values, types.SET_FALLBACK_SERVER, props.notify)}
            />
          </Grid>
        </Grid>
      </Grid>

      {props.user.g5AqsAdmin &&
        <Grid item>
          <Grid container direction='column'>
            <Grid item className={classes.section}>
              <TypographyTitle>Set Capability</TypographyTitle>
              <ConfigurationForm
                fields={initialFields.Capabilities}
                enable={props.enable && props.user.g5AqsAdmin}
                // advancedOptions={props.user.g5Admin}
                // onSaveDefault={(values) => props.updateDefaultConfig(values, types.SetFmScan, props.notify)}
                updateConfig={(values) => {
                  console.log(values);
                  const finalValues = _.pickBy(values, (val) => val === true);
                  const selections = Object.keys(finalValues);
                  const minNoise = values['minimal_noise'];
                  props.updateConfig({ selections, minNoise }, types.SET_CAPABILITY_REGISTER, props.notify);
                }}
              />
            </Grid>

            <Grid item className={classes.section}>
              <TypographyTitle>Set TPC-IP Config</TypographyTitle>
              <ConfigurationForm
                fields={initialFields.TcpIpConfiguration}
                enable={props.enable && props.user.g5AqsAdmin}
                // advancedOptions={props.user.g5Admin}
                // onSaveDefault={(values) => props.updateDefaultConfig(values, types.SetFmScan, props.notify)}
                // updateConfig={(values) => console.log(values)}
                updateConfig={(values) => props.updateConfig(values, types.TPC_IP_PACKET_CONFIGURATION, props.notify)}
              />
            </Grid>
          </Grid>
        </Grid>
      }
    </Grid>
  );
}

CommandsLayout.contextTypes = {
  t: PropTypes.func.isRequired
};

CommandsLayout.propTypes = {
  enable: PropTypes.bool,
  fwVersions: PropTypes.arrayOf('string').isRequired,
  fwModemVersions: PropTypes.arrayOf('string').isRequired,
  getDefault: PropTypes.func.isRequired,
  notify: PropTypes.func,
  sendActionCommand: PropTypes.func,
  timeZone: PropTypes.object,
  updateConfig: PropTypes.func.isRequired,
  updateDefaultConfig: PropTypes.func.isRequired,
  user: PropTypes.object,
};
