import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';

import { connect } from 'react-redux';

import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

import './style.scss';
import { Header, Picker, SearchItem } from '../../components';
import ConfigurationLogsModal from './ConfigurationLogsModal';
import TabsContainer from '../Custom/Tabs/TabsContainer';
import PopupAlert from '../../components/Notifications/PopupAlert';
import { getUIinfo } from '../../containers/UIhelper/UIhelper';

import _ from 'lodash';

import ConfigsTable from './TabsTables/ConfigsTable';
import StatusesTable from './TabsTables/StatusesTable';
import CommandsLayout from './TabsTables/CommandsLayout';
import AssamblyLayout from './TabsTables/AssamblyLayout';

import DevicesSelection from './DevicesSelection';

import * as devicesActions from '../../actions/DeviceConfigurationAction';
import * as actionsProjects from '../../actions/ProjectsActions';
import * as actionsSearch from '../../actions/SearchActions';

import ReactExport from 'react-data-export';

/* ========================================================================== */
/* ===========================  Redux ======================================= */
/* ========================================================================== */
import * as actionsDevices from '../../actions/DeviceConfigurationAction';
import * as actionsSensors from '../../actions/SensorsActions';

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

function createXlsDataFormate(title, value, numFormat, titleWidthType, titleWidthValue ) {
  return {
    title, titleWidthType, titleWidthValue, value, numFormat
  };
}

class MainPage extends Component {

  constructor(props) {
    super(props);

    this.state = {
      mode: 'status',
      // mode: 'config',
      popupAlert: {
        popupKey: -1,
        message: '',
        severity: 'info',
        action: '',
        logsModalOpen: false
      },
      xlsDevicesData: null,
      selectedProjectItem: null,
      uiInfo: getUIinfo({ minWidth: 1400 }),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateUIinfo);
    this.props.fetchDevices();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateUIinfo);
  }

  componentWillReceiveProps(nextProps) {
    const { projectsList, selectedProject, fetchDevices } = nextProps;

    if (selectedProject !== this.props.selectedProject || this.props.selectedProject == null) {
      const { selectedProject } = nextProps;
      fetchDevices();
      //this.toggleDetailsVisibility(false);
      browserHistory.push({
        pathname: this.props.location.pathname,
        query: { project: selectedProject }
      });

      const prj = projectsList.items[projectsList.projectIndexMap[selectedProject]];
      this.setState({
        selectedProjectItem: prj
      });
    }

    if (!_.isEqual(this.props.deviceList, nextProps.deviceList)) {
      const devicesDataSet = this.setDevicesData(nextProps.deviceList);
      this.setState({
        xlsDevicesData: devicesDataSet
      });
    }
  }

  updateUIinfo = () => {
    this.setState({
      uiInfo: getUIinfo({ minWidth: 1400 }),
    });
  }

  setDevicesData = (devices) => {
    const columns = [
      this.setDeviceDataTitle('Device ID', 'wch', 15),
      this.setDeviceDataTitle('Last Status Report Time'),
      this.setDeviceDataTitle('Last Config Report Time'),
      this.setDeviceDataTitle('Battery'),
      this.setDeviceDataTitle('Battery Post'),
      this.setDeviceDataTitle('Technology'),
      this.setDeviceDataTitle('IEMI', 'wch', 15),
      this.setDeviceDataTitle('ICCID', 'wch', 20),
      this.setDeviceDataTitle('Status Time Zone'),
      this.setDeviceDataTitle('HW Revision'),
      this.setDeviceDataTitle('FW Revision'),
      this.setDeviceDataTitle('RSRQ'),
      this.setDeviceDataTitle('RSRQ OK'),
      this.setDeviceDataTitle('RSRP'),
      this.setDeviceDataTitle('RSRP OK'),
      this.setDeviceDataTitle('Status Receiver Channel'),
      this.setDeviceDataTitle('Sample Time'), // SchedulerRefTime
      this.setDeviceDataTitle('Scheduler Cor Num Samples'),
      this.setDeviceDataTitle('Scheduler Cor Interval'),
      this.setDeviceDataTitle('Scheduler Noise Num Sample'),
      this.setDeviceDataTitle('Scheduler Noise Interval'),
      this.setDeviceDataTitle('Scheduler Noise Tx Time'),
      this.setDeviceDataTitle('Audio Duration'),
      this.setDeviceDataTitle('Audio Report Enabled'),
      this.setDeviceDataTitle('Corr Audio Sampling Frequency'),
      this.setDeviceDataTitle('Corr Audio Recording Duration'),
      this.setDeviceDataTitle('Corr Radio Recording Duration'),
      this.setDeviceDataTitle('Config Receiver Channel'),
      this.setDeviceDataTitle('Config Time Zone'),
      this.setDeviceDataTitle('Noise Intensity Min RMS'),
      this.setDeviceDataTitle('Noise Intensity Gain'),
      // ...[1,2,3,4,5].map((i) => [
      //   this.setDeviceDataTitle(`FMChannel${i}_10k`),
      //   this.setDeviceDataTitle(`FMChannel${i}_RSSI_dBi`),
      //   this.setDeviceDataTitle(`FMChannel${i}_SNR_dBi`),
      // ])
    ];

    const xlsData = [
      {
        columns: columns,
        data: devices.map((device) => ([
          { value: device.DeviceID, style: { font: {sz: "12"}, numFmt: '0'} },
          { value: new Date(device.LastStatusReportTime).toLocaleDateString() || '', style: { font: {sz: "12"} } },
          { value: new Date(device.LastConfigReportTime).toLocaleDateString() || '', style: { font: {sz: "12"} } },
          { value: (device.BatteryLevel && device.BatteryLevel.toFixed(3)) || '', style: { font: {sz: "12"}, numFmt: '0'} },
          { value: (device.BatteryLevelPost && device.BatteryLevelPost.toFixed(3)) || '', style: { font: {sz: "12"}, numFmt: '0'} },
          { value: device.AccessTechnologyName || '', style: { font: {sz: "12"} } },
          { value: device.IEMI || '', style: { font: {sz: "12"}, numFmt: '0'} },
          { value: device.ICCID || '', style: { font: {sz: "12"}, numFmt: '0'} },
          { value: device.StatusTimeZone || '', style: { font: {sz: "12"} } },
          { value: device.HardwareRevision || '', style: { font: {sz: "12"} } },
          { value: device.FirmwareRevision || '', style: { font: {sz: "12"} } },
          { value: device.RSRQ_db || '', style: { font: {sz: "12"} } },
          { value: device.RSRQ_dbOK || '' && device.RSRQ_dbOK ? 'V' : 'X', style: { font: {sz: "12", color: device.RSRQ_dbOK ? {rgb: '#00FF00'} : {rgb: '#FF0000'} } } },
          { value: device.RSRP_dbm || '', style: { font: {sz: "12"} } },
          { value: device.RSRP_dbmOK || '' && device.RSRP_dbmOK ? 'V' : 'X', style: { font: {sz: "12", color: device.RSRP_dbmOK ? {rgb: '#00FF00'} : {rgb: '#FF0000'} } } },
          { value: (device.StatusFmReceiverChannel_10k && (device.StatusFmReceiverChannel_10k / 100).toFixed(2)) || '', style: { font: {sz: "12"} } },
          { value: (device.SchedulerRefTime && new Date(device.SchedulerRefTime * 10 * 60 * 1000).toTimeString()) || '', style: { font: {sz: "12"} } },
          { value: device.SchedulerCorrNumSamples || '', style: { font: {sz: "12"} } },
          { value: device.SchedulerCorrInterval || '', style: { font: {sz: "12"} } },
          { value: device.SchedulerNoiseNumSamples || '', style: { font: {sz: "12"} } },
          { value: device.SchedulerNoiseInterval || '', style: { font: {sz: "12"} } },
          { value: device.SchedulerNoiseTxTime || '', style: { font: {sz: "12"} } },
          { value: device.AudioRecordingDurationSec || '', style: { font: {sz: "12"} } },
          { value: (device.AudioReportEnabled != null && device.AudioReportEnabled ? 'Yes' : 'No') || '', style: { font: {sz: "12"} } },
          { value: device.CorrelationAudioSamplingFrequency || '' && device.CorrelationAudioSamplingFrequency * 100, style: { font: {sz: "12"} } },
          { value: device.CorrelationAudioRecordingDurationSec || '', style: { font: {sz: "12"} } },
          { value: device.CorrelationRadioRecordingDurationSec || '', style: { font: {sz: "12"} } },
          { value: (device.FmReceiverChannel_10k && (device.FmReceiverChannel_10k / 100).toFixed(2)) || '' , style: { font: {sz: "12"} } },
          { value: device.ConfigTimeZone || '', style: { font: {sz: "12"} } },
          { value: device.NoiseIntensityMinRMS || '', style: { font: {sz: "12"} } },
          { value: device.NoiseIntensityGain || '', style: { font: {sz: "12"} } },
        ]))
      }
    ];

    return xlsData;
  }
  setDeviceDataTitle = (title, widthType, widthValue) => (
    {
      title,
      width: { [widthType || 'wch']: widthValue || (title.length + 2) },
      style: { alignment: { horizontal: 'center', vertical: 'center'} }
    }
  )

  onPathChange = () => {

  }

  handleDownloadFile = () => {

  }

  notify = (message, level, action) => {
    this.setState({
      popupAlert: {
        popupKey: new Date().getTime(),
        message: message,
        severity: level,
        action: action
      }
    });
  }

  render() {
    const { user, selectedProject, timeZone, deviceList, sort, devicesForDisplay, bitsInfo, selection, projectsList, fwVersions, fwModemVersions } = this.props;

    console.log('selection', selection.length);

    return (
      <main>
        <div
          style={{ position: 'absolute', top: '90px', right: '0px' }}>
          <Picker
            value={selectedProject}
            onChange={this.props.handleProjectChange}
            options={projectsList.items}
          />
        </div>
        <Header
          selectedProject={selectedProject}
          onPathChange={this.onPathChange}
          user={user}
          local={timeZone}
          downloadFile={this.handleDownloadFile}
          logo={user.logo}
        />

        <Grid container direction='row' style={{flexWrap: 'nowrap'}}>
          <Grid item style={{ width: '14vw' }}>
            <DevicesSelection
              devicesList={deviceList}
              selectedDevices={this.props.selection}
              handleSelection={this.props.selectDevice} />
          </Grid>
          <Grid item style={{ width: '86vw' }}>
            <div style={{ position: 'absolute', margin: '16px'}}>
              <SearchItem
                onSearchItem={(value, type) => {
                  this.props.searchDevice(value, type).then((messageData) => {
                    if (messageData) {
                      let message;
                      let action;
                      const { projectID, projectName } = messageData;
                      if (projectID == null) {
                        message = this.context.t('Not Found');
                      } else {
                        if (projectID === selectedProject) {
                          message = this.context.t('Remove Filters To view device');
                        } else {
                          message = this.context.t('Your Device located in project {projectName}', { projectName });
                          action = {
                            label: this.context.t('Switch to {projectName}', { projectName }),
                            callback: () => {
                              this.props.handleProjectChange(projectID);
                            }
                          };
                        }
                      }

                      this.notify(message, 'info', action);
                    }
                  });
                }}
                path='g5Devices'
                user={user}
              />
            </div>
            <div style={{ position: 'absolute', right: '0px', margin: '16px'}}>
            <ExcelFile
                filename={`G5 Devices`}
                element={
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<SaveIcon />}
                  >{this.context.t('Save as Excel')}</Button>
              }>
                <ExcelSheet dataSet={this.state.xlsDevicesData} name="Devices"/>
              </ExcelFile>
              { user.isAQS &&
                <button onClick={() => {
                  this.setState({
                    logsModalOpen: true
                  })
                }}>logs</button>
              }
            </div>
            <TabsContainer
              tabsContainerStyle={{ backgroundColor: 'aliceblue', margin: '0 0 4px 0' }}
              tabsButtonsStyle={{ margin: 'auto' }}
              tabs={[
                {
                  label: this.context.t('Status'),
                  style: {
                    maxHeight: 'inherit',
                    margin: 'auto',
                    width: 'fit-content',
                  },
                  component: (
                    <StatusesTable
                      items={devicesForDisplay}
                      sort={sort}
                      selectedItems={this.props.selection}
                      workingMode={this.state.mode}
                      handleSelection={this.props.selectDevice}
                      reloadDevices={this.props.fetchDevices}
                      local={timeZone}
                      uiInfo={this.state.uiInfo}
                    />
                  )
                },
                {
                  label: this.context.t('Configuration'),
                  style: {
                    maxHeight: 'inherit',
                    margin: 'auto',
                    width: 'fit-content',
                  },
                  component: (
                    <ConfigsTable
                      items={devicesForDisplay}
                      sort={sort}
                      selectedItems={this.props.selection}
                      workingMode={this.state.mode}
                      handleSelection={this.props.selectDevice}
                      reloadDevices={this.props.fetchDevices}
                      local={timeZone}
                      options={this.props.options}
                      uiInfo={this.state.uiInfo}
                    />
                  )
                },
                {
                  label: this.context.t('Assembly'),
                  style: {
                    maxHeight: 'inherit',
                    margin: 'auto',
                    width: 'fit-content',
                  },
                  component: (
                    <AssamblyLayout
                      bitsInfo={deviceList}
                      timeZone={timeZone}
                      selectMultipleDevices={this.props.selectMultipleDevices}
                      updateConfig={this.props.updateConfig}
                      reloadDevices={this.props.fetchDevices}
                      notify={this.notify}
                      selectedItems={this.props.selection}
                    />
                  )
                },
                {
                  label: this.context.t('Commands'),
                  style: {
                    maxHeight: 'inherit',
                    width: 'fit-content',
                    height: '84vh',
                    overflowY: 'auto',
                  },
                  component: (
                    <CommandsLayout
                      getDefault={this.props.fetchProjectDefaultConfiguration}
                      updateConfig={this.props.updateConfig}
                      updateDefaultConfig={this.props.updateDefaultConfig}
                      notify={this.notify}
                      selectedItems={this.props.selection}
                      sendActionCommand={this.props.sendActionCommand}
                      timeZone={this.props.timeZone}
                      enable={selection.length > 0}
                      fwVersions={fwVersions}
                      fwModemVersions={fwModemVersions}
                      user={user}
                    />
                  )
                },
              ]}
            />
          </Grid>
        </Grid>
        { user.isAQS &&
          <ConfigurationLogsModal
            open={this.state.logsModalOpen}
            closeLogsModal={() => this.setState({ logsModalOpen: false })}
            data={this.props.messagesLogs}
            timeZone={this.props.timeZone}
          />
        }

        {this.state.popupAlert &&
          <PopupAlert
            {...this.state.popupAlert}
          />
        }
      </main>
    );
  }
}

MainPage.propTypes = {
  deviceList: PropTypes.array.isRequired,
  fetchDevices: PropTypes.func.isRequired,
  fetchProjectDefaultConfiguration: PropTypes.func.isRequired,
  fwVersions: PropTypes.arrayOf('string').isRequired,
  handleProjectChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selectDevice: PropTypes.func.isRequired,
  selectMultipleDevices: PropTypes.func,
  selectedProject: PropTypes.string.isRequired,
  selection: PropTypes.array.isRequired,
  sendActionCommand: PropTypes.func,
  timeZone: PropTypes.string.isRequired,
  updateConfig: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  messagesLogs: PropTypes.array,
};

MainPage.contextTypes = {
  t: PropTypes.func.isRequired
};

/* ========================================================================== */
/* ===========================  Redux ======================================= */
/* ========================================================================== */

const mapStateToProps = (state, props) => {
  const { user, projectsList, selectedProject, optionList } = state.leaksList;
  const timeZone = state.local.timeZone;
  const { deviceList, devicesForDisplay, sort, bitsInfo, selection, fwVersions, fwModemVersions, messagesLogs } = state.devcieConfigurations;
  const { options } = optionList;

  return {
    ...props,
    timeZone,
    user: user,
    deviceList,
    devicesForDisplay,
    sort,
    bitsInfo,
    selection,
    projectsList,
    selectedProject,
    options,
    fwVersions,
    fwModemVersions,
    messagesLogs
  };
};

function mapDispatchToProps(dispatch, props) {
  return {
    fetchProjectDefaultConfiguration: function() {
      return new Promise((resolve, reject) => {
        dispatch(actionsDevices.fetchProjectDefaultConfiguration()).then((json) => {
          resolve(json);
        });
      });
    },
    fetchDevices: function () {
      dispatch(actionsDevices.fetchDevices());
      dispatch(actionsDevices.fetchFwVersions());
      dispatch(actionsDevices.fetchFwModemVersions());
      // dispatch(actionsSensors.fetchG5SensorsStatuses());
      dispatch(actionsSensors.fetchG5BitInfo());
    },
    selectDevice: function (selection, mode) {
      dispatch(actionsDevices.selectDevices(selection));
    },
    selectMultipleDevices: function (devices) {
      dispatch(actionsDevices.selectDevices(devices));
    },
    updateConfig: function (values, type, notifyFunc) {
      dispatch(devicesActions.setDeviceConfiguration(type, values))
        .then((data) => {
          console.log(data);
          if (data.err) {
            notifyFunc(JSON.stringify(data.err), 'error');
          } else {
            notifyFunc(`${type} message was sent successfully`, 'success');
          }
        })
        .catch((err) => {
          notifyFunc(JSON.stringify(err), 'error');
        });
    },
    updateDefaultConfig: function(values, type, notifyFunc) {
      dispatch(devicesActions.setProjectDefaultConfiguration(type, values))
        .then((data) => {
          if (data.err) {
            notifyFunc(JSON.stringify(data.err), 'error');
          } else {
            notifyFunc(`${type} defaults was set successfully`, 'success');
          }
        })
        .catch((err) => {
          notifyFunc(JSON.stringify(err), 'error');
        });
    },
    sendActionCommand: function (action) {
      dispatch(devicesActions.setActionCommand(action));
    },
    handleProjectChange: function (nextProject) {
      dispatch(actionsProjects.selectProjectAction(nextProject));
    },
    searchDevice: function (value, type) {
      return new Promise((resolve, reject) => {
        dispatch(actionsSearch.searchItem(value, type, 'g5Devices', (featureIndex, serverResult) => {
          if (featureIndex != null) {
            resolve();
          } else {
            if (serverResult == null || serverResult.status == false) {
              reject(new Error('search failed. result: ' + serverResult));
            } else {
              resolve(serverResult);
              // msgType = 'info';
              // if (serverResult.projectID == null) {
              //   message = "Not Found";
              // } else {

              //   if (serverResult.projectID == selectedProject) {
              //     message = `Remove Filters To View ${itemType} Location`;
              //   } else {
              //     //const projectName =
              //     message = `Your ${itemType} Located in project ${serverResult.projectName}.`;
              //     action = {
              //       label: `Switch to ${serverResult.projectName}`,
              //       callback: () => {
              //         dispatch(actionsProjects.selectProject(serverResult.projectID.toString()));
              //       }
              //     };
              //   }
              // }
            }
            // resolve(message, msgType, action);
          }
        }));
      });
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainPage);
