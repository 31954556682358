import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import  { setZone } from '../../actions/GlobalsActions'
import { ZONE } from '../../constants/SettingsKeys';

require('./UserSettings.scss');

class TimezoneSelect extends React.Component {
  constructor(props, context) {
    super(props);

    // Moved hhere since we need context.
    this.options = [
      {
        ID: 'local',
        Name: context.t('Local')
      },
      {
        ID: 'gmt',
        Name: context.t('GMT')
      },
    ];
  }

  componentWillReceiveProps(nextProps) {
      if (false) {
          // NOT IMPLEMENTED YET.
          if (nextProps.user.isAQS && this.options.find(op => op.ID == 'project') == null) {
              this.options.push({
                  ID: 'project',
                  Name: this.context.t('Project')
              });
          }
      }
  }

  onChange(zone) {
      this.props.dispatch(setZone(zone));
      localStorage.setItem(ZONE, zone);
  }

  render() {

    const { lang, local } = this.props;
    return (
      <div className="lang-select">
            <select onChange={e => this.onChange(e.target.value)}
                value={local}
                lang={lang || 'en'}>
          {this.options.map(option =>
            <option key={option.ID} value={option.ID}>
              {option.Name}
            </option>)
          }
        </select>
      </div>
    );
  }
}

TimezoneSelect.contextTypes = {
  t: PropTypes.func.isRequired
}

export default connect(state => ({
  timezone: state.local.timezone,
}))(TimezoneSelect)
