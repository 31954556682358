/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  SearchItem,
  AlertsMenu,
  ManageMenu,
  InstallMenu,
  ReportForDates,
} from '../../components';
import './TableMixin.scss';
import MobileMenu from '../Menu/MobileMenu';

const _ = require('lodash');

const TableMixin = (InnerComponent, SettingsComponent) =>
  class extends React.Component {
    constructor(props) {
      super(props);
      this.toggleSettingsDisplay = this.toggleSettingsDisplay.bind(this);
      this.getStateFromProps = this.getStateFromProps.bind(this);
      this.rowClassNameGetter = this.rowClassNameGetter.bind(this);
      this.onRowClick = this.onRowClick.bind(this);
      this.renderFilters = this.renderFilters.bind(this);
      this.sortTable = this.sortTable.bind(this);
      this.sortTableLocaly = this.sortTableLocaly.bind(this);
      this.processFilters = this.processFilters.bind(this);
      this.cleanEmptyFilters = this.cleanEmptyFilters.bind(this);
      this.state = Object.assign(this.getStateFromProps(props), {
        showSettings: false,
        goToRow: null,
      });
    }

    cleanEmptyFilters(filters) {
      const returnFilters = filters.filter(function (f) {
        if (f.op) {
          if (Array.isArray(f.value) && f.value.length > 0) {
            // op field
            return f.value[0].length > 0;
          } else {
            // date field
            return f.to || f.from;
          }
        }
        const isInFilter = Array.isArray(f.value) && f.value.length > 0;
        return isInFilter;
      });
      return returnFilters;
    }

    processFilters(valuesCopy) {
      let filters = [];
      let defFilters = {};
      let sort = { field: valuesCopy['sortBy'], dir: valuesCopy['sortByDir'] };
      if (valuesCopy != null) {
        defFilters['sortBy'] = valuesCopy['sortBy'];
        defFilters['sortByDir'] = valuesCopy['sortByDir'];
        delete valuesCopy['sortBy'];
        delete valuesCopy['sortByDir'];
        let oprFields = [
          { field: 'Intensity', opr: 'IntensityOpr' },
          { field: 'Probability', opr: 'ProbabilityOpr' },
          { field: 'DetectedAt', opr: 'DetectedAtOpr' },
          { field: 'gPSstatusRatio', opr: 'gPSstatusRatioOpr' },
          { field: 'CommunicationOKratio', opr: 'CommunicationOKratioOpr' },
        ];
        let datesFields = [
          'DetectedAt',
          'fixDate',
          'ClosureDate',
          'SensorInstallationDate',
          'InsertDate',
          'CreateDate',
          'CreationDate',
        ];

        datesFields.forEach((field) => {
          var filter = { empty: true, to: false, from: false };
          var toKey = field + '-To';
          var fromKey = field + '-From';
          if (toKey in valuesCopy) {
            filter.to = valuesCopy[toKey];
            filter.empty = false;
            defFilters[toKey] = valuesCopy[toKey];
            delete valuesCopy[toKey];
          }
          if (fromKey in valuesCopy) {
            filter.from = valuesCopy[fromKey];
            filter.empty = false;
            defFilters[fromKey] = valuesCopy[fromKey];
            delete valuesCopy[fromKey];
          }
          if (!filter.empty) {
            delete filter.empty;
            filter.op = 'range';
            filter.field = field;
            filters.push(filter);
          }
        });

        oprFields.forEach((fieldData) => {
          if (fieldData.opr in valuesCopy) {
            var opValue = valuesCopy[fieldData.opr];
            if (typeof opValue === 'object') {
              opValue = opValue.value;
            }
            if (fieldData.field in valuesCopy) {
              const filterObj = {
                field: fieldData.field,
                value: [valuesCopy[fieldData.field]],
                op: opValue,
              };
              filters.push(filterObj);
              defFilters[fieldData.field] = valuesCopy[fieldData.field];
              delete valuesCopy[fieldData.field];
            }
            delete valuesCopy[fieldData.opr];
            defFilters[fieldData.opr] = opValue;
          }
        });

        Object.keys(valuesCopy).forEach((key) => {
          const values = valuesCopy[key];
          if (values) {
            const filterObj = { field: key, value: values };
            if (key === 'LastUninstallReason') {
              filterObj.avoidNulls = true;
            }
            filters.push(filterObj);
            defFilters[key] = values;
          }
        });
      }
      return { filters, defFilters, sort };
    }

    toggleSettingsDisplay() {
      this.setState({ showSettings: !this.state.showSettings });
    }

    rowClassNameGetter(rowIndex) {
      const {
        selectedFeature,
        indexMap,
        displayAssessments,
        selectedAssessment,
        path,
      } = this.props;
      const feature = displayAssessments ? selectedAssessment : selectedFeature;
      let className = '';

      switch (path) {
        case 'alerts':
          if (this.props.alertsContextMenu === 'noise') {
            if (
              feature !== '' &&
              rowIndex !== '' &&
              indexMap &&
              rowIndex == indexMap[feature.ID]
            ) {
              className = 'active-row';
            }
          } else {
            if (
              feature !== '' &&
              rowIndex !== '' &&
              rowIndex == indexMap[feature]
            ) {
              className = 'active-row';
            } else if (this.props.alertsContextMenu === 'alerts') {
              const itrAlert = this.props.items[rowIndex];
              if (itrAlert && itrAlert.Burst === 2) {
                className = 'pink-row';
              }
            }
          }
          break;
        case 'couples': {
          const coupleItem = this.props.items[rowIndex];
          if (
            feature !== '' &&
            rowIndex !== '' &&
            indexMap &&
            rowIndex == indexMap[feature]
          ) {
            className = 'active-row';
          }
          if (coupleItem && coupleItem.MissingDevices) {
            className += ' deleted';
          }

          break;
        }
        case 'manage':
          const { manageItem } = this.props;
          if (manageItem != null && indexMap[manageItem.id] == rowIndex) {
            className = 'active-row';
          }
          break;
        default:
          if (
            feature !== '' &&
            rowIndex !== '' &&
            indexMap &&
            rowIndex == indexMap[feature]
          ) {
            className = 'active-row';
          }
          break;
      }

      return className;
    }

    onRowClick(event, rowIndex, onFailCallBack, additionalData) {
      if (event === 'sensorLink') {
        this.props.onItemClick(rowIndex, onFailCallBack);
      } else {
        const feature = this.props.items[rowIndex];
        this.props.onItemClick(feature, onFailCallBack, additionalData);
      }
    }

    removeFilter(filter) {
      this.props.removeFilter(filter);
    }

    renderFilters(props) {
      const {
        path,
        alertsContextMenu,
        displayAssessments,
        installContext,
        filters,
        mobileTasksFilters,
      } = props;
      let newFilters;
      switch (path) {
        case 'alerts':
          if (alertsContextMenu === 'noise') {
            newFilters = filters.noiseAlerts.filters;
          } else {
            newFilters = filters.filters;
          }
          break;
        case 'sensors':
          newFilters = [...filters.sensorsFilters, ...filters.sops.filters];
          break;
        case 'couples':
          if (displayAssessments == false) {
            newFilters = filters.couples.filters;
          }
          break;
        case 'install':
          if (installContext == 'SOPs') {
            newFilters = filters.sops.filters;
          } else if (installContext == 'Sensors') {
            newFilters = [...filters.sensorsFilters, ...filters.sops.filters];
          }
          break;
        case 'mobile':
          newFilters = mobileTasksFilters.filters;
          break;
        default:
          break;
      }

      let output = dispatchEvent ? null : (
        <span className="filter">No filters applyed</span>
      );

      const filterNames = {
        AlertState: 'Status',
        Priority: 'Priority',
        AlertType: 'Type',
      };
      if (newFilters && newFilters.length) {
        newFilters = newFilters.filter(function (filter) {
          if (filter.value && filter.value.length === 0) {
            return false;
          } else if (filter.field === 'sortBy') {
            return false;
          }
          return true;
        });

        output = newFilters.map((filter, index) => (
          <span className="filter" key={index}>
            {this.props.t(filterNames[filter.field] || filter.field)}
            <span
              className="remove-filter"
              onClick={() => this.removeFilter(filter.field)}
            >
              x
            </span>
          </span>
        ));
      }
      return output;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.setState(this.getStateFromProps(nextProps));
    }

    shouldComponentUpdate(nextProps, nextState) {
      return true;
    }

    componentDidUpdate(prevProps) {
      const { selectedFeature, showDetails, items, path, installMode } =
        this.props;
      switch (path) {
        case 'manage':
          break;
        default:
          if (
            selectedFeature !== prevProps.selectedFeature ||
            showDetails !== prevProps.showDetails ||
            items.length !== prevProps.items.length ||
            path !== prevProps.path ||
            (installMode &&
              installMode.installContext !==
                prevProps.installMode.installContext)
          ) {
            const selectedRow = this.props.items.filter(function (item) {
              return item.id == selectedFeature;
            })[0];
            setTimeout(() => {
              this.setState({
                goToRow: this.props.items.indexOf(selectedRow),
              });
            }, 500)
          }
          break;
      }
    }

    sortTable(field, dir) {
      this.props.sortTable(field, dir);
    }

    sortTableLocaly(field, dir) {
      this.props.sortTableLocaly(field, dir);
      //const { items } = this.props;
      //items.sort((a, b) => {
      //    let bRc = a[field] > b[field] ? 1 : (b[field] > a[field] ? -1 : 0);
      //    return (bRc);
      //});
    }

    getStateFromProps(props) {
      const { uiInfo, showDetails, filters, mobileTasksSort } = props;

      let field;
      let dir;

      switch (props.path) {
        case 'alerts':
          switch (props.alertsContextMenu) {
            case 'alerts':
              field = filters.sort.field;
              dir = filters.sort.dir;
              break;

            case 'noise':
              field = props.noiseAlertsSortParams.field;
              dir = props.noiseAlertsSortParams.dir;
              break;

            default:
              break;
          }
          break;
        case 'sensors':
          field = filters.sensorsSort.field;
          dir = filters.sensorsSort.dir;
          break;
        case 'couples':
          field = filters.couples.sort.field;
          dir = filters.couples.sort.dir;
          break;
        case 'manage':
          switch (this.props.managementContext) {
            case 'Customers':
              field = filters.customers.sort.field;
              dir = filters.customers.sort.dir;
              break;
            case 'Projects':
              field = filters.projects.sort.field;
              dir = filters.projects.sort.dir;
              break;
            case 'Users':
              field = filters.users.sort.field;
              dir = filters.users.sort.dir;
              break;
          }
          break;
        case 'mobile': {
          field = _.upperFirst(_.camelCase(mobileTasksSort.field));
          dir = mobileTasksSort.dir;
          break;
        }
        case 'install': {
          switch (this.props.installContext) {
            case 'SOPs': {
              const sopsSort = filters.sops.sort;
              if (sopsSort) {
                field = sopsSort.field;
                dir = sopsSort.dir;
              }
              break;
            }
            case 'Interferences': {
              const interferencesSort = filters.interferencesSort;
              if (interferencesSort) {
                field = interferencesSort.field;
                dir = interferencesSort.dir;
              }
              break;
            }
            default:
              break;
          }

          break;
        }
        default:
          break;
      }

      const localSortProp = {
        sort: this.sortTableLocaly,
        sortBy: field,
        sortDir: dir,
      };
      const sortProp = {
        sort: this.sortTable,
        sortBy: field,
        sortDir: dir,
      };

      let pWidth = 0.395 * (uiInfo.pageWidth - 10);
      let pHeight = uiInfo.pageHeight;
      if (showDetails) {
        pHeight = 0.494 * uiInfo.pageHeight;
      }

      const panelStyle = { minHeight: 0.507 * uiInfo.pageHeight };

      const renderedFilters = this.renderFilters(props);
      return {
        renderedFilters,
        panelStyle,
        pHeight,
        pWidth,
        sortProp,
        localSortProp,
      };
    }

    render() {
      const { renderedFilters, panelStyle } = this.state;
      const {
        uiInfo,
        title,
        items,
        displayAssessments,
        refreshData,
        onSearchItem,
        t,
      } = this.props;
      return (
        <div className="alerts" style={panelStyle}>
          <div className="alerts-wrp">
            <div className="alerts-header">
              {(this.props.path !== 'alerts' ||
                (this.props.path === 'alerts' &&
                  this.props.alertsContextMenu !== 'prsAlerts')) && (
                <div className="actions">
                  <i
                    className="icon-settings"
                    title={t('Filters')}
                    onClick={this.toggleSettingsDisplay}
                  />
                </div>
              )}
              <div className="actions">
                <i
                  className="icon-refresh"
                  title={t('Reload data')}
                  onClick={refreshData}
                />
              </div>
              <div className="actions">
                <SearchItem
                  onSearchItem={onSearchItem}
                  path={this.props.path}
                  user={this.props.user}
                  managementContext={this.props.managementContext}
                  installContext={this.props.installContext}
                />
              </div>
              <h3 style={{ margin: '10px' }}>{t(title)}</h3>
            </div>
            <div>
              <div className="alerts-filters">
                {displayAssessments === false &&
                  (this.props.path === 'alerts' &&
                    this.props.alertsContextMenu === 'prsAlerts') === false && (
                    <label>{t('Filters:')}</label>
                  )}
                {displayAssessments === false &&
                  (this.props.path === 'alerts' &&
                    this.props.alertsContextMenu === 'prsAlerts') === false &&
                  renderedFilters}
                {this.props.path === 'alerts' &&
                  this.props.alertsContextMenu === 'prsAlerts' && (
                    <ReportForDates done={this.props.loadPressureAlerts} />
                  )}
              </div>
              <InnerComponent
                {...this.state}
                {...this.props}
                rowClassNameGetter={this.rowClassNameGetter}
                rowClickEvent={this.onRowClick}
              />
              {this.props.path === 'alerts' && (
                <AlertsMenu onChange={this.props.onChangeAlertsContext} />
              )}
              {this.props.path === 'manage' && (
                <ManageMenu
                  addNew={this.props.addNewManageItem}
                  onChange={this.props.onChangeManageContext}
                />
              )}
              {
                // the path is like a "mode" that part of the URI like "install"
                this.props.path === 'install' && (
                  <InstallMenu
                    addNewInstallItem={this.props.addNewInstallItem}
                    installContext={this.props.installContext}
                    onChange={this.props.onChangeInstallContext}
                  />
                )
              }
              {
                // the path is like a "mode" that part of the URI like "mobile"
                this.props.path === 'mobile' && (
                  <MobileMenu
                    mobileContext={this.props.mobileContext}
                    onChange={this.props.onChangeMobileDisplayMode}
                  />
                )
              }
              {SettingsComponent != null && (
                <SettingsComponent
                  cleanEmptyFilters={this.cleanEmptyFilters}
                  processFilters={this.processFilters}
                  showSettings={this.state.showSettings}
                  toggleSettingsDisplay={this.toggleSettingsDisplay}
                  uiInfo={uiInfo}
                />
              )}
            </div>
          </div>
        </div>
      );
    }
  };

TableMixin.contextTypes = {
  t: PropTypes.func.isRequired,
};

TableMixin.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  uiInfo: PropTypes.object.isRequired,
};

export default TableMixin;
