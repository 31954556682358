import React from 'react';
import PropTypes from 'prop-types';

import LinearProgress from '@mui/material/LinearProgress';

import { Field, reduxForm } from 'redux-form';
import { FileItem } from '../../../components';
// import { END_POINT } from './../../../constants/EndPoints';

const ReactS3Uploader = require('react-s3-uploader');

require('./FilesTab.scss');

class FilesTab extends React.Component {

    constructor(props) {
        super(props);

        this.getSignedUrl = this.getSignedUrl.bind(this);
        this.onUploadStart = this.onUploadStart.bind(this);
        this.onUploadProgress = this.onUploadProgress.bind(this);
        this.onUploadError = this.onUploadError.bind(this);
        this.onUploadFinish = this.onUploadFinish.bind(this);

        this.state = {
            uploading: false,
            progress: 0,
        }
    }

    getSignedUrl(file, callback) {
        this.props.uploadFile(file, (result) => {
          // const urlParts = result.data.split('/');
          // const fileName = urlParts[8].split('?');
          // urlParts.splice(8, 1); // remove this item
          // fileName[0] = encodeURI(fileName[0]);
          // const url = `${urlParts.join('/')}/${fileName.join('?')}`;
          // debugger;
          callback({ signedUrl: result.data });
        });
    }

    onUploadStart(file, callback) {
        console.log('onUploadStart');
        callback(file);

        this.setState({
            uploading: true,
            progress: 0,
        });
    }

    onUploadProgress(percentLoaded, status, file) {
        console.log('onUploadProgress. [' + percentLoaded + ']');
        this.setState({
            progress: percentLoaded,
        });
    }

    onUploadError(error, file) {
      console.log(error);
      console.log(file);
      setTimeout(() => {
        this.setState({
          uploading: false,
          progress: 0,
        });
      }, 100);
    }

    onUploadFinish(file) {
        console.log('onUploadFinish');
        const self = this;
        this.setState({
            progress: 100,
            uploading: false,
        });
        setTimeout(() => {
            self.uploadInput.value = '';
            self.props.loadFiles();
            self.setState({
                progress: 0,
            });
        }, 500);
    }

  render() {
    const { files, error, submitting, uploadFile, downloadFile, deleteFile } = this.props;
    const headers = '';
    const params = '';
    const style = { visibility: this.state.uploading ? 'visible' : 'hidden' };
    const progressOptions = {
        color: "#0f0",
        strokeWidth: 1,
        fillOpacity: 0.3,
        text: {
            style: {
                color: 'black',
                position: 'absolute',
                left: '50%',
                top: '50%',
                padding: 0,
                margin: 0,
                // transform: {
                //     prefix: true,
                //     value: 'translate(-50%, -50%)'
                // },
            }
        },
    };
    return (
      <div className="files-tab">

        <form className="form-file-upload" onSubmit={uploadFile}>
                <ReactS3Uploader
                    className="uploader"
                    getSignedUrl={this.getSignedUrl}
                    signingUrlMethod="GET"
                    accept="*//*"
                    preprocess={this.onUploadStart}
                    onProgress={this.onUploadProgress}
                    onError={this.onUploadError}
                    onFinish={this.onUploadFinish}
                    signingUrlWithCredentials           // in case when need to pass authentication credentials via CORS
                    uploadRequestHeaders={{
                        'Content-Type': 'application/octet-stream',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Methods': 'GET,PUT'
                    }}  // this is the default
                    contentDisposition="auto"
                    scrubFilename={(filename) => {//filename.replace(/[^\w\d_\-.]+/ig, '_')}
                      return encodeURI(filename);
                    }}
                    // server={END_POINT}
                    inputRef={cmp => this.uploadInput = cmp}
                    autoUpload
                />
                {
                    <div style={{visibility: this.state.progress > 0 ? 'visible' : 'hidden'}}>
                      <LinearProgress value={this.state.progress} />
                      {/* <ProgressBar
                        active
                        now={this.state.progress}
                      /> */}
                    </div>
                }
                {false && <div>
                    <div className="col-sm-9">
                        <Field
                            name="files"
                            component="input"
                            type="file"
                            className="form-control"
                        />
                    </div>
                    <div className="col-sm-3">
                        <button className="btn btn-success btn-submit btn-block" disabled={submitting} type="submit">{this.context.t('Upload Files')}</button>
                    </div>
                    <div className="form-feedback text-danger">
                        {error}
                    </div>
                </div>
                }
        </form>

        <div className="files-tab-files">
          {files && files.data && files.data.length > 0 &&
            files.data.map((file, key) => (
              <FileItem
                  {...file}
                  key={key}
                  deleteFile={deleteFile}
                  downloadFile={downloadFile}
                  />
            ))
          }
          {!files || !files.data || files.data.length == 0 &&
            <div className="files-tab-empty">
              {this.context.t('No files uploaded yet...')}
            </div>
          }
        </div>
      </div>
    );
  }
}

FilesTab.contextTypes = {
  t: PropTypes.func.isRequired
}

// Decorate the form component
FilesTab = reduxForm({
  form: 'files' // a unique name for this form
})(FilesTab);

export default FilesTab;
