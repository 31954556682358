import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import { Select } from '../../components';
const classNames = require('classnames');

require('./CouplesSettings.scss');

class CouplesSettings extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { handleSubmit, pristine, reset, submitting, uiInfo, materialsList, optionList, toggleSettingsDisplay } = this.props;
    const panelClasses = classNames(
      'couples-settings',
      { 'show': this.props.showSettings }
    );

    const panelStyle = { height: uiInfo.pageHeight, width: 0.395 * (uiInfo.pageWidth - 10) };

    return (
      <div className={panelClasses} style={panelStyle}>
        <div className="couples-header">
          <div className="actions">
            <i className="icon-close" onClick={toggleSettingsDisplay} />
          </div>
          <h2>{this.context.t('Couples Options')}</h2>
        </div>
        <form className="form-horizontal" onSubmit={handleSubmit}>
          <fieldset>
            <legend>{this.context.t('Sort by')}</legend>
            <div className="form-group">
              <div className="col-xs-8">
                <Field name="sortBy"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('Select...')}
                    clearable={false}
                    options={[
                      { value: 'ID', label: this.context.t('ID (default)') },
                      { value: 'PathLengthM', label: this.context.t('Length') },
                      { value: 'Material', label: this.context.t('Material') },
                      { value: 'LastDaemonActivity', label: this.context.t('Updated') },
                    ]}
                  />)
                  }
                />
              </div>
              <div className="col-xs-4">
                <Field name="sortByDir"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('Select...')}
                    clearable={false}
                    backspaceRemoves={false}
                    options={[
                      { value: 'asc', label: this.context.t('ASC') },
                      { value: 'desc', label: this.context.t('DESC') },
                    ]}
                  />)
                  }
                />
              </div>
            </div>
          </fieldset>
          <fieldset>
            <legend>{this.context.t('Filters')}</legend>
            <div className="form-group">
              <label className="col-xs-4 control-label">{this.context.t('Materials')}</label>
              <div className="col-xs-8">
                <Field name="MaterialCode"
                  component={(props) =>
                  (<Select {...props}
                    placeholderTitle={this.context.t('Select...')}
                    clearable
                    multi
                    closeMenuOnSelect={false}
                    options={optionList.Materials}
                  />)
                  }
                />
              </div>
            </div>

            <div className="text-right">
              <button className="btn btn-success" type="submit" disabled={submitting} onClick={toggleSettingsDisplay} >{this.context.t('Save & Apply')}</button>
            </div>
          </fieldset>
        </form>
      </div>
    );
  }

}

CouplesSettings.contextTypes = {
  t: PropTypes.func.isRequired
};

// Decorate the form component
const couplesSettings = reduxForm({
  form: 'couples-settings', // a unique name for this form
  enableReinitialize: true,
})(CouplesSettings);

export default couplesSettings;
