import { connect } from 'react-redux';
import LayerManager from '../../components/LayerManager/LayerManager';
import * as actionsCoupleCoverage from '../../actions/CouplesCoverageActions';
import * as actionsMapHandler from '../../actions/MapActionsHandler';
import * as actionsNoiseSamples from '../../actions/NoiseSamplesActions';
import * as mobileSamplesActions from '../../actions/MobileSamplesActions';

const mapStateToProps = (state, ownProps) => {
  const projectId = state.leaksList.selectedProject;
  const coupleCoverageData =
    state.leaksList.leaksByProject[projectId] &&
    state.leaksList.leaksByProject[projectId].couplesCoveragePaths;
  const bHaveCoupleCoverageData =
    coupleCoverageData &&
    coupleCoverageData.data &&
    coupleCoverageData.paths.length > 0;
  const mapLayers = state.mapState.layers;
  let newProps = Object.assign({}, ownProps, {
    layers: Object.assign(mapLayers, ownProps.layers),
  });
  const noiseSamplesColorAttr = state.local.noiseSamplesColorAttr;
  const mobileNoiseSamplesColorAttr = state.local.mobileNoiseSamplesColorAttr;

  return Object.assign(
    {},
    {
      noiseSamplesColorAttr,
      mobileNoiseSamplesColorAttr,
      coupleCoverage: {
        hasData: bHaveCoupleCoverageData,
        isFetching: coupleCoverageData && coupleCoverageData.isFetching,
      },
    },
    newProps
  );
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onLayerChange: (event, layerName) => {
      dispatch(
        actionsMapHandler.handleLayerChange(layerName, event.target.checked)
      );
    },
    reloadCoupleCoverage: (event) => {
      dispatch(actionsCoupleCoverage.FetchCouplesCoveragePathsIfNeeded());
    },
    noiseSamplesSourceChange: (event, value) => {
      dispatch(actionsNoiseSamples.setNoiseColorByAttribute(value));
    },
    mobileNoiseSamplesSourceChange: (event, value) => {
      dispatch(mobileSamplesActions.setMobileNoiseColorByAttribute(value));
    },
  };
};

const CLayersManager = connect(
  mapStateToProps,
  mapDispatchToProps
)(LayerManager);

export default CLayersManager;
