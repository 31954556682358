import React from 'react';
import PropTypes from 'prop-types';

import OperationForm from './OperationForm';

export default function ConfigurationForm(props) {

  return (
    <OperationForm
      advancedOptions={props.advancedOptions}
      enable={props.enable}
      fields={props.fields}
      onSubmit={props.updateConfig}
      onSaveDefault={props.onSaveDefault}
    />
  );
}

ConfigurationForm.propTypes = {
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  fields: PropTypes.array.isRequired,
  onSaveDefault: PropTypes.func,
  updateConfig: PropTypes.func
};

ConfigurationForm.contextTypes = {
  t: PropTypes.func.isRequired
};
