import React from 'react';

const AddressInput = (props) => {
  const cloneProps = { ...props };
  const inputChildren = cloneProps.children;
  const inputRef = cloneProps.inputRef;

  delete cloneProps.children;
  delete cloneProps.inputRef;

  return (
    <div style={{ display: 'grid', border: '1px inset #ccc', gridTemplateColumns: 'auto min-content', cursor: 'pointer' }}>
      <input
        ref={inputRef}
        style={{ border: 'none', padding: 0 }}
        {...cloneProps}
      />
      <div style={{ border: 'none', background: 'none' }}>{inputChildren}</div>
    </div>
  );
}

export default AddressInput;
