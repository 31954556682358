import React from 'react';
import PropTypes from 'prop-types';

import { getG5StatusMessages, getG5StatusMessagesEntries } from '../../actions/DevicesActions';
import { useDispatch } from 'react-redux';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell } from '../../components';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
import { Box, Modal, Paper, TableContainer, Table as MuiTable, TableHead, TableRow, TableCell, TableBody, Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';


import ReactExport from 'react-data-export';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const createXlsColumnHeader = (title) => ({
  title, width: {wch: 20}, style: { alignment: { horizontal: 'center', vertical: 'center'}}
})

function G5CommStatusMessages({ deviceId, timeZone, uiInfo: { pageHeight, pageWidth } }, context) {
  const dispatch = useDispatch();
  const [commMessages, setCommMessages] = React.useState([]);
  const [messageEntries, setMessageEntries] = React.useState();
  const [xlsData, setXlsData] = React.useState();

  React.useEffect(() => {
    dispatch(getG5StatusMessages(deviceId)).then((response) => {
      if (response.status) {
        setCommMessages(response.data);
      }
    })
  }, [deviceId]);

  React.useEffect(() => {
    if (messageEntries?.length > 0) {
      setXlsData([{
        columns: Object.keys(messageEntries[0]).map(createXlsColumnHeader),
        data: messageEntries.map((row) => Object.values((row)).map((rowValue) => ({ value: rowValue })))
      }]);
    }
  }, [messageEntries])

  const pWidth = 0.395 * (pageWidth - 10);
  const pHeight = 0.494 * pageHeight;

  return (
    <>
      <Table
        rowsCount={commMessages ? commMessages.length : 0}
        width={pWidth}
        onRowClick={(event, rowIndex) => {
          const item = commMessages[rowIndex]
          dispatch(getG5StatusMessagesEntries(item.id)).then((response) => {
            if (response.status) {
              setMessageEntries(response.data);
            }
          })
        }}
        // rowClassNameGetter={rowClassNameGetter}
        maxHeight={pHeight - 49}
        headerHeight={46}
        rowHeight={30}
      >
        <Column
          columnKey="timestamp"
          header={<Cell>{context.t('Date')}</Cell>}
          cell={<TextCell data={commMessages} field="timestamp" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
          width={80}
          flexGrow={2}
        />
        <Column
          columnKey="access_technology"
          header={<Cell>{context.t('Technology')}</Cell>}
          cell={<TextCell data={commMessages} field="access_technology" />}
          width={40}
          flexGrow={1}
        />
        <Column
          columnKey="cell_id"
          header={<Cell>{context.t('Cell id')}</Cell>}
          cell={<TextCell data={commMessages} field="cell_id" />}
          width={40}
          flexGrow={1}
        />
        <Column
          columnKey="comm_msg_type"
          header={<Cell>{context.t('Type')}</Cell>}
          cell={<TextCell data={commMessages} field="comm_msg_type" />}
          width={40}
          flexGrow={1}
        />
        <Column
          columnKey="iccid"
          header={<Cell>{context.t('ICCID')}</Cell>}
          cell={<TextCell data={commMessages} field="iccid" />}
          width={40}
          flexGrow={1}
        />
      </Table>
      {messageEntries && <Modal
        open={messageEntries !== undefined}
        onClose={() => setMessageEntries(undefined)}
      >
        <Box style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          padding: '10px',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: '75%',
          maxHeight: '85%',
          bgcolor: 'background.paper',
          border: '1px solid #000',
          borderRadius: '5px',
          boxShadow: 24,
          pt: 2,
          px: 4,
          pb: 3,
        }}>
          <TableContainer component={Paper}>
            <MuiTable stickyHeader size='small'>
              <TableHead>
                <TableRow>
                  { Object.keys(messageEntries[0]).map((attrKey) =>
                    <TableCell key={`head-${attrKey}`}>{attrKey}</TableCell>
                  ) }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  messageEntries.map((entry) =>
                    <TableRow>
                      {
                        Object.entries(entry).map(([key, value], index) =>
                          <TableCell key={`cell-${key}-${index}`}>{value}</TableCell>
                        )
                      }
                    </TableRow>
                  )
                }
              </TableBody>
            </MuiTable>
          </TableContainer>

          <ExcelFile
            filename={'CommStatusMessage'}
            element={
              <Button
                // disabled={isLoading}
                variant="contained"
                color="primary"
                size="small"
                startIcon={<SaveIcon />}
              >{context.t('Save as Excel')}</Button>
            }>
            <ExcelSheet dataSet={xlsData} name={'Entries'} />
          </ExcelFile>
        </Box>
      </Modal>}
    </>
  );
}

G5CommStatusMessages.contextTypes = {
  t: PropTypes.func.isRequired
}

export default G5CommStatusMessages;
