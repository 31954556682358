import React from "react";

import { Table, Column, Cell } from "fixed-data-table-2";
import { SortableHeaderCell, TextCell, TableMixin } from "../../components";
// import ToggleButton from '@mui/lab/ToggleButton';
// import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';

import CTableActions from "../../containers/CTableActions/CTableActions";
import CSensorsSettings from "../../containers/CSensorsSettings/CSensorsSettings";
import { DateType } from "../../containers/UIhelper/UIDateFormater";
import { decToHex, formatMobileDeviceID } from "../../containers/UIhelper/UIhelper";
import { useDispatch } from "react-redux";
import { selectMobileDevice } from "../../actions/MobileDevicesActions";

const MobileDevicesTable = (props) => {

  const dispatch = useDispatch();

  const {
    items,
    indexMap,
    selectedFeature,
    pHeight,
    pWidth,
    sortProp,
    goToRow,
    t,
  } = props;
  const totalItemsStyle = {
    float: "right",
    padding: "2px 8px",
  };

  const rowClickEvent = (_event, index) => {
    const device = items[index];
    dispatch(selectMobileDevice(device));
  }

  const rowClassNameGetter = (rowIndex) => {
    if (selectedFeature === items[rowIndex]['device_id']) {
      return 'active-row'
    }
  }

  return (
    <div>
      <Table
        maxHeight={pHeight - 93}
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={rowClickEvent}
        rowClassNameGetter={rowClassNameGetter}
        scrollToRow={goToRow}
      >
        <Column
          columnKey="ID"
          width={120}
          flexGrow={2}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={({rowIndex}) => {
            const deviceId = items[rowIndex]['device_id'];
            if (deviceId) {
              return <Cell>{formatMobileDeviceID(decToHex(deviceId))}</Cell>
            }
          }}
        />
        <Column
          columnKey="CreationTime"
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={
            <TextCell
              data={items}
              field="activation_date"
              dateType={DateType.DATE}
            />
          }
        />
        <Column
          columnKey="Type"
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProp} />}
          cell={<TextCell data={items} field="device_type" />}
        />
      </Table>

      {items && items.length > 0 && <CTableActions type="mobile" />}
      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t("Selected: {selectedIndex}. Total: {total}", {
            selectedIndex: indexMap[selectedFeature] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}
    </div>
  );
}

export default TableMixin(MobileDevicesTable, CSensorsSettings);;
