import React from "react";

import { Table, Column, Cell } from "fixed-data-table-2";
import { SortableHeaderCell, TextCell, TableMixin, ButtonCell } from "../../components";
// import ToggleButton from '@mui/lab/ToggleButton';
// import ToggleButtonGroup from '@mui/lab/ToggleButtonGroup';

import CheckBoxCell from '../CheckBoxCell/CheckBoxCell';
import CTableActions from "../../containers/CTableActions/CTableActions";
import CMobileTasksSettings from "../../containers/CMobileTasksSettings/CMobileTasksSettings";
import { DateType } from "../../containers/UIhelper/UIDateFormater";
import CAddTask from "../../containers/CMobileForms/CAddTask";
import Pagination from "react-js-pagination";
import { ALERT_SAMPLES_COUNT_FOR_PAGE } from "../../actions/SamplesActions";

const TasksTable = (props) => {
  const { items, indexMap, selectedFeature, pHeight, pWidth, localSortProp, goToRow, t } = props;

  const [currentPage, setCurrentPage] = React.useState(1);
  const [showAddTaskForm, setShowAddTaskForm] = React.useState(false);

  const handleShowAddTaskForm = (isShown) => {
    if (showAddTaskForm !== isShown) {
      setShowAddTaskForm(isShown);
    }
  }

  // const pageWidth = 0.99 * pWidth;

  const totalItemsStyle = {
    float: "right",
    padding: "2px 8px",
  };
  const handleRowClassNameGetter = (rowIndex) => {
    if ((!rowIndex && rowIndex !== 0) || !indexMap || !selectedFeature.length) {
      return;
    }
    const ids = selectedFeature.map(id => indexMap[id]);
    return ids.includes(rowIndex) ? 'active-row' : ''
  }

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    props.onPageIndexChange(pageNumber - 1)
  }

  const toggleSampleChecked = (value, checked) => {
    props.selectTask(value, true);
  }

  const myHandleRowClick = (event, rowIndex) => {
    if (event.target.type === undefined || event.target.type !== 'checkbox') {
      const task = items[rowIndex];
      props.selectTask(task, false);
    }
  }

  return (
    <div>
      <CAddTask
        isOpen={showAddTaskForm}
        showAddTaskForm={handleShowAddTaskForm}
      />
      <Table
        maxHeight={pHeight - 188} // 715 - 620
        width={pWidth}
        rowsCount={items.length}
        rowHeight={30}
        headerHeight={30}
        onRowClick={myHandleRowClick}
        rowClassNameGetter={handleRowClassNameGetter}
        scrollToRow={goToRow}
      >
        <Column
          columnKey="Selected"
          header={<Cell>{ }</Cell>}
          cell={
            <CheckBoxCell
              data={items}
              onChange={toggleSampleChecked}
              isChecked={(value) => selectedFeature.includes(value.task_uuid)}
            />
          }
          width={20}
          flexGrow={1}
        />

        {/* <Column columnKey="Delete" width={20} flexGrow={1} cell={<ButtonCell onClick={(item) => {
            props.deleteTask(item.task_uuid);
          }} title={t('Delete')} data={items}/>  } /> */}

        <Column
          columnKey="TaskName"
          width={120}
          flexGrow={2}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field="task_name" />}
        />
        <Column
          columnKey="CreationDate"
          width={80}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={
            <TextCell
              data={items}
              field="creation_date"
              dateType={DateType.DATE}
            />
          }
        />
        <Column
          columnKey="SamplesCount"
          width={115}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={localSortProp} />}
          cell={<TextCell data={items} field="samples_count" />}
        />
      </Table>
      <div className="center">
        <Pagination
          prevPageText={t('prev')}
          nextPageText={t('next')}
          firstPageText={t('first')}
          lastPageText={t('last')}
          activePage={currentPage}
          itemsCountPerPage={ALERT_SAMPLES_COUNT_FOR_PAGE}
          totalItemsCount={items.length}
          pageRangeDisplayed={5}
          onChange={handlePageClick}
        />
      </div>
      <button
        type="button"
        className="btn btn-success btn-assessment create-item"
        onClick={() => {
          handleShowAddTaskForm(true);
        }}
      >
        <i
          title={t("create task")} />

      </button>
      {items && items.length > 0 && <CTableActions type="mobile" />}

      {items && items.length > 0 && (
        <span style={totalItemsStyle}>
          {t("Selected: {selectedIndex}. Total: {total}", {
            selectedIndex: indexMap[selectedFeature] + 1 || 0,
            total: items.length,
          })}
        </span>
      )}

      {/* <ToggleButtonGroup
        exclusive
        className="mobile-context-buttons"
        value={displayMode}
        onChange={(_event, value) => {
          onChangeMobileDisplayMode(value);
        }}
      >
        <ToggleButton value="Alerts">{t('Alerts')}</ToggleButton>
        <ToggleButton value="Tasks">{t('Survey')}</ToggleButton>
      </ToggleButtonGroup> */}
    </div>
  );
}



export default TableMixin(TasksTable, CMobileTasksSettings);
