import * as types from '../constants/ActionTypes';
import {fetchMiddleware} from './MiddlewareActions';
import { fetchImages } from './GlobalsActions';
import * as endPoints from '../constants/EndPoints';

//--------------------------------
// Receive & Request
//--------------------------------
function requestLeakFiles(project, leak) {
  return {
    type: types.REQUEST_LEAK_FILES,
    project,
    leak
  };
}

function receiveLeakFiles(project, leak, files) {
  return {
    type: types.RECEIVE_LEAK_FILES,
    project,
    leak,
    files
  };
}

//--------------------------------
// Fetch
//--------------------------------
export function fetchFilesIfNeeded(project, leak, force = false) {
  return (dispatch, getState) => {
    if (force) {
      return dispatch(fetchFiles(project, leak))
    }
    else {
      if (shouldFetchFiles(getState(), project, leak)) {
        return dispatch(fetchFiles(project, leak))
      }
    }
  }
}

function shouldFetchFiles(state, project, leak) {
  const files = state.leaksList.leaksByProject[project].files[leak]
  if (!files) {
    return true
  }
  else if (files.isFetching) {
    return false;
  }
  else if (files.data.length) {
    return false;
  }
  return true;
}

function fetchFiles(project, leak) {
  var path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "/" + leak + "/files";

  return (dispatch, getState) => {
    dispatch(requestLeakFiles(project, leak))
    let state = getState();
    let projectInfo = state.leaksList.projectsList.items.find(element => element.ID.toString() == project);
    return fetchMiddleware(path, {}, getState).then(json => {
      let files = json.data.map(obj => {
        obj.downloadPath = obj.Key;
        return obj
      })
      dispatch(receiveLeakFiles(project, leak, {data: files}))
    })
    // return fetchMiddleware(path, {}, getState).then(json => dispatch(receiveLeakFiles(project, leak, json)))
  }
}

export const fetchAlertImages = (projectId, alertId) => (
  (dispatch, getState) => {
    // const state = getState();
    // const projectId = state.leaksList.selectedProject;
    dispatch(requestLeakImages(projectId, alertId));

    return fetchImages(getState, projectId, alertId, "alert").then((json) => {
      if (json.status == false) {
        console.log(json);
      } else {
        dispatch(receiveLeakImages(projectId, alertId, json.data));
      }
    });

    // const path = endPoints.END_POINT + `/images/${projectId}/${alertId}?itemId=${alertId}&itemType=alert`;
    // return fetchMiddleware(path, {}, getState).then((json) => {
      
    // });
  }
);

const requestLeakImages = (project, alertId) => (
  {
    type: types.REQUEST_LEAK_IMAGES,
    project,
    alertId,
  }
);

const receiveLeakImages = (project, alertId, images) => (
  {
    type: types.RECEIVE_LEAK_IMAGES,
    project,
    alertId,
    images,
  }
);

//--------------------------------
// Delete
//--------------------------------

export function deleteFile(file) {
  return (dispatch, getState) => {
    //@TODO: Check errors.
    var path;
    var state = getState();
    var project = state.leaksList.selectedProject;
    var leak = state.leaksList.leaksByProject.selectedFeature;
    path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "/" + leak + "/files";
    dispatch(deleteFileReq(project, leak, file));
    return fetchMiddleware(path, {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      method: 'DELETE',
      body: JSON.stringify({data: file})
    }, getState).then(json => {
      if (json.status == true) {
        dispatch(deleteFileRes(project, leak, file));
        dispatch(fetchFilesIfNeeded(project, leak, true))
      }
      else {
        console.log(json);
        //@TODO: Handle error.
        // dispatch(uploadFileRes(project,leak));
      }

    })

  }
}

function deleteFileReq(project, leak, file) {
  return {
    type: types.DELETE_FILE_REQ,
    project,
    leak,
    file
  };

}

function deleteFileRes(project, leak, file) {
  return {
    type: types.DELETE_FILE_RES,
    project,
    leak,
    file
  };
}

//--------------------------------
// Upload
//--------------------------------
function uploadFileReq(project, leak, file) {
  return {
    type: types.UPLOAD_FILE_REQ,
    project,
    leak,
    file
  };

}

function uploadFileRes(project, leak, uid) {
  return {
    type: types.UPLOAD_FILE_RES,
    project,
    leak,
    uid,
  };
}

export function uploadFile(file) {
  return (dispatch, getState) => {
    if (validateFileExtension(file) !== true) {
      return false;
    }
    //@TODO: Check errors.
    var path;
    var state = getState();
    var project = state.leaksList.selectedProject;
    var leak = state.leaksList.leaksByProject.selectedFeature;
    path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/" + endPoints.LEAKS_ENDPOINT + "/" + leak + "/files";

    var formData = new FormData();
    formData.append('attachment', file, file.name);
    dispatch(uploadFileReq(project, leak, file))
    return fetchMiddleware(path, {
      method: 'PUT',
      body: formData
    }, getState).then(json => {
      console.log(json);
      if (json.status == true) {
        dispatch(uploadFileRes(project, leak, json.data.uid));
        // ws.send(json.data.uid);
        //ws.on('message', (msg) => {
        //  console.log(msg);
        //  if (msg.data === json.data.uid) {
        //    dispatch(fetchFilesIfNeeded(project, leak, true))}
        //  }
        //);
      }
      else {
        console.log(json);
        //@TODO: Handle error.
        // dispatch(uploadFileRes(project,leak));
      }
    })
  }
}

export function uploadFileNew(project, leak, file, usingType, callback) {
    return (dispatch, getState) => {
        if (!validateFileExtension(file)) {
          // TODO: handle errors
        } else {
            const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/file/" + file.name + '/type/' + usingType +'/item/' + leak;

            return fetchMiddleware(path, {}, getState).then(json => {
                if (!json.status) {
                    // TODO: handle errors. problem to get signed url
                } else {
                    const result = {
                        signedUrl: json.data,
                    }

                    callback(result);
                    //var formData = new FormData();
                    //formData.append('attachment', file, file.name);
                    //const options = {
                    //    method: 'PUT',
                    //    body: formData,
                    //    headers: {
                    //        'Access-Control-Allow-Origin': '*',
                    //    },
                    //}
                    //dispatch(fetchMiddleware(url, options, getState).then((response) => {
                    //    console.log(response);
                    //}));
                }
                //console.log(json);
            });
        }
    }
}

//--------------------------------
// Extensions
//--------------------------------

function validateFileExtension(file) {
  const ext = file.name.match(/\.([^\.]+)$/)[1];
  const notAllowed = ['dll', 'exe', 'gzquar', 'jar', 'swf', 'js', 'sys', 'zix', 'scr', 'lnk', 'ws', 'wmf', 'ozd', 'bin', 'com', 'chm', 'shs', 'xlm', 'ocx', 'bat', 'dev', 'bkd', 'pif', 'class', 'pcx', 'aru', 'drv', 'hlw', 'tps', 'vbs', 'xnxx', 'dxz', 'cih', 'vba', 'vbx', 'vbe', 'exe1', 'cfxxe', 'dbd', 'lik', 'bxz', 'tsa', 'pgm', 'bhx', 'boo', 'vb', 'rsc_tmp', 'dli', 'fnr', 'osa', 'dlb', 'mcq', 'ska', 'mfu', 'rhk', 'xir', 'hlp', 's7p', 'vxd', 'exe_renamed', 'smtmp', 'buk', 'uzy', 'oar', 'php3', 'ssy', 'wlpginstall', 'delf', '9', 'dyz', 'dom', 'mjz', 'tti', 'txs', '386', 'mjg', 'hsq', 'dyv', 'kcd', 'sop', 'upa', 'xdu', 'cla', 'wsh', 'scr', 'ceo', 'fag', 'qrn', 'tko', 'spam', 'cc', 'let', 'xlv', 'bps', 'bup', 'xtbl', 'aepl', 'dllx', 'bll', 'cxq', 'vexe', 'rna', 'iws', 'bmw', 'pr', 'wsc', 'bqf', 'ctbl', 'atm', 'smm', 'plc', 'ce0', 'capxml', 'nls', 'blf', 'lkh', 'qit', 'vzr', 'pid', 'iva', 'aut', 'dx', 'hts', 'cyw', 'ezt', 'fjl', 'fuj', 'zvz', 'lok', 'lpaq5', 'xnt'];
  if (notAllowed.indexOf(ext) === -1) {
    return true;
  }
  return false;
}

export const getUrlForDownloadFile = (project, filePath, usingType, itemId, cb) => {
  return (dispatch, getState) => {
    const data = {
      file: filePath,
      type: usingType,
      item: itemId,
    }
    const path = endPoints.PROJECTS_ENDPOINT + '/' + project + '/sign?data=' + JSON.stringify(data);
    return fetchMiddleware(path, {}, getState).then(json => {
      cb(json);
    });
  }
}
