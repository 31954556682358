import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { SortableHeaderCell, TextCell, TableMixin } from '../../components';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import CCouplesSettings from '../../containers/CCouplesSettings/CCouplesSettings';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
const classNames = require('classnames');

const _ = require('lodash');
require('./Pagination.scss');

class AssessmentTable extends React.Component {

    constructor(props) {
        super(props);

        this.assessmentToggleClick = this.assessmentToggleClick.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
    }

    assessmentToggleClick() {
        this.props.handleAssessmentButtonClick(false);
        this.props.toggleDetailsVisibility(false);
    }

    onCheckboxChange(e) {
        this.props.setCoupleAlertsShowOnlyRealLeaks(e.target.checked);
    }

    render() {

        //const { user, items, pHeight, pWidth, sortProp, goToRow, rowClassNameGetter, distanceFactor, t } = this.props;

        //document.title = 'AQS • Couples';
        //const scrollTo = (goToRow && goToRow > 0) ? goToRow : 1;
        const totalItemsStyle = {
            float: 'right',
            padding: '2px 8px',
        };
        const { showDetails, uiInfo, items, indexMap, selectedFeature, displayAssessmentsOnlyRealLeaks, rowClickEvent, goToRow, rowClassNameGetter, sortTable, t } = this.props;

        let pWidth = 0.395 * (uiInfo.pageWidth - 10);
        let pHeight = uiInfo.pageHeight;
        if (showDetails) {
            pHeight = 0.494 * uiInfo.pageHeight;
        }
        const scrollTo = (goToRow && goToRow > 0) ? goToRow : 1;

        const panelStyle = { height: pHeight-37 };

        const data = items;//this.state.showAll ? items : items.filter((item) => [6, 3].indexOf(item.AlertState) != -1 ); // 6 = Fixed, 3 = Located

        return (
            <div>
                <Table
                    height={pHeight - 93}
                    width={pWidth}
                    rowsCount={data.length}
                    rowHeight={30}
                    headerHeight={30}
                    onRowClick={rowClickEvent}
                    rowClassNameGetter={rowClassNameGetter}
                    scrollToRow={scrollTo}
                    {...this.props}
                >
                    <Column columnKey="Priority" width={30}
                        header={<SortableHeaderCell sortTable={sortTable} />}
                        cell={<TextCell data={data} field="Priority" />} />
                    <Column columnKey="id" width={70} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortTable} />}
                        cell={<TextCell data={data} field="id" />} />
                    <Column columnKey="DetectedAt" width={95} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortTable} />}
                        cell={<TextCell data={data} field="DetectedAt" dateType={DateType.DATE} />} />
                    <Column columnKey="AlertType" width={95} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortTable} />}
                        cell={<TextCell data={data} field="AlertTypeMeaning" />} />
                    <Column columnKey="AlertState" width={95} flexGrow={1}
                        header={<SortableHeaderCell sortTable={sortTable} />}
                        cell={<TextCell data={data} field="AlertStateMeaning" />} />
                    <Column columnKey="StreetAddress" headerClassName="comment" cellClassName="comment" width={120} flexGrow={3}
                        header={<SortableHeaderCell sortTable={sortTable} />}
                        cell={<TextCell data={data} field="StreetAddress" />} />
                    <Column columnKey="Comment" headerClassName="comment" cellClassName="comment" width={120} flexGrow={3}
                        header={t('Comment')}
                        cell={<TextCell data={data} field="Comment" />} />
                </Table>

                <div style={{float: 'left'}}>
                    <CTableActions
                        type="assessments"
                        getMapCapture={this.props.getMapCapture}
                    />

                    <input
                        id="assessments-show-all"
                        type='checkbox'
                        checked={displayAssessmentsOnlyRealLeaks}
                        onChange={this.onCheckboxChange}/>
                    <label htmlFor="assessments-show-all">{t('Only real leaks')}</label>
                </div>

                <button
                    type="button"
                    onClick={this.assessmentToggleClick}
                    className="btn btn-assessment back" >

                    <i title={t('Open Assessment')} />

                </button>

                {data && data.length > 0 &&
                  <span style={totalItemsStyle}>{t('Selected: {selectedIndex}. Total: {total}', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: data.length })}</span>
                }
            </div>
        );
    }
}

AssessmentTable = TableMixin(AssessmentTable, CCouplesSettings);
export default AssessmentTable;
