export function daysBetween(first, second) {

   // Copy date parts of the timestamps, discarding the time parts.
   var one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
   var two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

   // Do the math.
   var millisecondsPerDay = 1000 * 60 * 60 * 24;
   var millisBetween = two.getTime() - one.getTime();
   var days = millisBetween / millisecondsPerDay;

   // Round down.
   return Math.floor(days);
}
