import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { fetchMiddleware, setIndexes, getIconFeatures } from './MiddlewareActions';
import { COUPLE_SAMPLES_COUNT_FOR_PAGE } from '../constants/Misc';

function findAlertInData(outputs, alertId) {
    let isInData = false;
    const rows = outputs.rows;

    if (rows) {
        for (let i = 0; i < rows.length; i++) {
            if (rows[i].LeakID == alertId) {
                isInData = true;
                break;
            }
        }
    }

    return (isInData);
}


//--------------------------------
// Couples Alerts (Pipes Assessment)
//--------------------------------
export function fetchCoupleAlertsIfNeeded(project, coupleId) {
    return (dispatch, getState) => {
        if (shouldFetchCoupleAlerts(getState(), project, coupleId)) {
            return dispatch(fetchCoupleAlerts(project, coupleId));
        }
    }
}

function shouldFetchCoupleAlerts(state, project, coupleId) {
    const assessments = state.leaksList.leaksByProject[project].assessments[coupleId];
    const haveToFetch = (assessments == null);
    return haveToFetch;
}

function fetchCoupleAlerts(project, coupleId) {
    const path = endPoints.PROJECTS_ENDPOINT + "/" + project + "/couple/" + coupleId + "/" + endPoints.LEAKS_ENDPOINT;

    return (dispatch, getState) => {
        dispatch(requestCoupleAssessments(project, coupleId));
        return fetchMiddleware(path, {}, getState)
            .then(json => {
                const options = getState().leaksList.optionList.options;
                return [setEnumsValues(options, json.data), getIconFeatures('assessments', json.data), setIndexes(json.data, 'id')];
            })
            .then(args => {
                dispatch(receiveCoupleAssessments(project, coupleId, ...args))
            });
    }
}

function requestCoupleAssessments(project, couple) {
    return {
        type: types.REQUEST_COUPLES_ALERTS,
        project,
        couple,
    }
}

function receiveCoupleAssessments(project, couple, assessments, icons, indexMap) {
    return {
        type: types.RECEIVE_COUPLES_ALERTS,
        project,
        couple,
        assessments,
        icons,
        indexMap
    }
}

function setEnumsValues(options, data) {
    if (options) {
        const states = options.AlertState;
        const types= options.AlertType;

        data.forEach(item => {
            const alertState = item.AlertState;
            const alertType = item.AlertType;
            for (let i = 0; i < states.length; i++) {
                if (states[i].value == alertState) {
                    item.AlertStateMeaning = states[i].label;
                    break;
                }
            }
            for (let i = 0; i < types.length; i++) {
                if (types[i].value == alertType) {
                    item.AlertTypeMeaning = types[i].label;
                    break;
                }
            }
            return item;
        });
        return (data);
    }
}

export function displayAssessments(display) {
    return {
        type: types.CHANGE_ASSESSMENTS_MODE,
        display,
    }
}

//--------------------------------
// Get CouplesOutputs relevance for AlertID
//--------------------------------
export function fetchCoupleSamplesForAlertIfNeeded(project, alertId) {
    return (dispatch, getState) => {
        const state = getState();
        const projectsState = state.leaksList.leaksByProject;
        const coupleId = projectsState.selectedFeature;

        //const currentOutputs = projectsState[project].coupleSamples[coupleId];
        //if (currentOutputs != null && currentOutputs.data != null) {
        //    const isInCurrentData = findAlertInData(currentOutputs.data, alertId);
        //}

        return dispatch(fetchCoupleSamplesForAlert(project, coupleId, alertId));

    }
}

function fetchCoupleSamplesForAlert(project, couple, alert) {
    const path = endPoints.END_POINT + "/couple/" + couple + "/rows/" + COUPLE_SAMPLES_COUNT_FOR_PAGE + "/alert/" + alert;

    return (dispatch, getState) => {
        dispatch(requestCoupleSamplesForAlert(project, couple, alert));
        return fetchMiddleware(path, {}, getState)
            .then(json => {
                //const state = getState();
                //const projectInfo = state.leaksList.projectsList.items.find(element => element.ID.toString() == project);
                //json.data.rows.map(obj => {
                //    obj.SPInstant = "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/OUTPUT2/" + obj.SPInstant;
                //    obj.CORInstant = "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/OUTPUT2/" + obj.CORInstant;
                //    obj.Trend30 = "https:/\/" + projectInfo.S3Bucket + ".s3.amazonaws.com/OUTPUT2/" + obj.Trend30;
                //});
                dispatch(receiveCoupleSamplesForAlert(project, couple, alert, json.data));
            });
    }
}

const requestCoupleSamplesForAlert = (project, couple, alert) => {
    return {
        type: types.REQUEST_COUPLE_SAMPLES_FOR_ALERT,
        project,
        couple,
        alert,
    };
}

const receiveCoupleSamplesForAlert = (project, couple, alert, data) => {
    return {
        type: types.RECEIVE_COUPLE_SAMPLES_FOR_ALERT,
        project,
        couple,
        alert,
        data,
    };
}

//===================================
// SELECT ASSESSMENT
//===================================
export const selectAssessment = (project, alert) => {
    return {
        type: types.SELECT_COUPLE_ALERT,
        project,
        alert,
    };
};

export const setCoupleAlertsShowOnlyRealLeaks = (show) => (
    {
        type: types.ASSESSMENT_SET_SHOW_ONLY_REAL,
        show,
    }
);
