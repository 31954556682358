import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { CircleLoader } from 'react-spinners';

import { GraphComp, LeaksDataTable } from "../../../components";

const groupBy = require('json-groupby');

const classNames = require('classnames');

const layoutMargin = { t: 30, b: 40, l: 45, r: 20 };
const topChartLayout = {
   width: 450,
   height: 300,
   margin: layoutMargin,
   showlegend: true,
   legend: {
      x: 0.8,
      y: 0.5
   }
};
const pieChartLayout = {
   width: 450,
   height: 300,
   margin: layoutMargin,
};

const MIN_PIPE_LENGTH = 800;

export default class Dashboard extends React.Component {

   static get propTypes() {
      return {
         rawLeaksData: PropTypes.array,
         valuesSets: PropTypes.object,
         pipesList: PropTypes.array,
         pipesValuesSets: PropTypes.object,
         pcaScoreData: PropTypes.object,
         layoutVisibility: PropTypes.string,
         onSelectGroup: PropTypes.func,
         measurmentsDetails: PropTypes.object,
      };
   }

   constructor(props) {
      super(props);

      this.onAbsoluteTableRowClick = this.onAbsoluteTableRowClick.bind(this);
      this.onRelativeTableRowClick = this.onRelativeTableRowClick.bind(this);
      this.renderLoading = this.renderLoading.bind(this);
      this.cellRender = this.cellRender.bind(this);
      this.cellRenderRelative = this.cellRenderRelative.bind(this);
      this.getLeaksByAttributeData = this.getLeaksByAttributeData.bind(this);
      this.getPipesByAttributeData = this.getPipesByAttributeData.bind(this);
      this.getLeaksAsPieForAttribute = this.getLeaksAsPieForAttribute.bind(this);

      this.state = {
         topProblematicsPipes: [],
         topProblematicsPipesRelative: [],
         leaksByEventYear: {
            layout: Object.assign({}, topChartLayout, {
               showlegend: false,
               xaxis: {
                  title: 'Detection Year',
                  type: 'linear',
                  autotick: false,
                  dtick: 1,
               },
               yaxis: {
                  title: 'Leaks',
               },
            }),
            data: []
         },
         pcaScorePlotData: {
          layout: Object.assign({}, topChartLayout, {
            showlegend: false,
            yaxis: {
              type: 'log',
              autorange: true,
              title: `Pipe length [${props.measurmentsDetails.units}]`,
            },
          }),
          data: []
         },
         leaksByMaterials: {
            layout: pieChartLayout,
            data: []
         },
         leaksByDiameter: {
            layout: pieChartLayout,
            data: []
         },
         leaksByPlacementYear: {
            layout: pieChartLayout,
            data: []
         }
      };
   }

   componentWillMount() {
      this.setData(this.props);
   }

   componentWillReceiveProps(nextProps) {
      this.setData(nextProps);
   }

   setData(props) {
      const { rawLeaksData, valuesSets, pipesList, pcaScoreData, pipesValuesSets, measurmentsDetails } = props;

      let leaksByEventYearAsPlotData = this.getLeaksByAttributeData(rawLeaksData, valuesSets, 'Event Year');
      //let pipesByPlacementYearAsPlotData = this.getPipesByAttributeData(pipesList, pipesValuesSets, 'PlacementYear');
      //let topValues = this.getLeaksByMaterialDiameterForPipeYear(rawLeaksData, valuesSets, pipesList, 'PlacementYear', 'MaterialDiameter', 5);
      // let maxLeaksByMaterialDiameterForPipeYear = topValues.absoluteData;
      // let maxLeaksByMaterialDiameterVsPipeYear = topValues.relativeData;
      let topValuesRelative = this.getTopBadSections(rawLeaksData, pipesList, null, measurmentsDetails);
      let topValuesAbsolute = Object.assign([], topValuesRelative);
      topValuesAbsolute = topValuesAbsolute.sort((a, b) => b.Count - a.Count);

      topValuesRelative = topValuesRelative.slice(0, 3);
      topValuesAbsolute = topValuesAbsolute.slice(0, 3);

      let leaksByMaterials = this.getLeaksAsPieForAttribute(rawLeaksData, valuesSets, 'Material');
      let leaksByDiameter = this.getLeaksAsPieForAttribute(rawLeaksData, valuesSets, 'PipeDiameterInch');
      let leaksByPlacementYear = this.getLeaksAsPieForAttribute(rawLeaksData, valuesSets, 'PlacementYear');

      let pcaScorePlotData = this.getPcaScorePlotData(pcaScoreData, props);
      this.setState({
         topProblematicsPipes: topValuesAbsolute,
         topProblematicsPipesRelative: topValuesRelative,
         leaksByEventYear: Object.assign({}, this.state.leaksByEventYear, {
            data: leaksByEventYearAsPlotData
         }),
         // maxMaterialDiameterByPlacementYear: Object.assign({}, this.state.maxMaterialDiameterByPlacementYear, {
         //    data: maxLeaksByMaterialDiameterForPipeYear
         // }),
         // maxMaterialDiameterVsPlacementYear: Object.assign({}, this.state.maxMaterialDiameterVsPlacementYear, {
         //    data: maxLeaksByMaterialDiameterVsPipeYear
         // }),
         leaksByMaterials: Object.assign({}, this.state.leaksByMaterials, {
            data: leaksByMaterials
         }),
         leaksByDiameter: Object.assign({}, this.state.leaksByDiameter, {
            data: leaksByDiameter
         }),
         leaksByPlacementYear: Object.assign({}, this.state.leaksByPlacementYear, {
            data: leaksByPlacementYear
         }),
         pcaScorePlotData: Object.assign({}, this.state.pcaScorePlotData, {
            data: pcaScorePlotData,
            layout: Object.assign({}, this.state.pcaScorePlotData.layout, {
              yaxis: Object.assign({}, this.state.pcaScorePlotData.layout.yaxis, {
                title: `Pipe length [${props.measurmentsDetails.units}]`,
              })
            })
         }),
      });
   }

   getTopBadSections(rawLeaksData, pipesList, size, measurmentsDetails) {
      const { factor, units } = measurmentsDetails;
      const attrGroup = groupBy(rawLeaksData, ['YearMaterialDiameter']);
      const pipesGroup = groupBy(pipesList, ['YearMaterialDiameter']);

      let sorted = Object.entries(attrGroup).sort((a, b) => b[1].length - a[1].length);
      sorted = sorted.filter((item) => (item[1].length >= 1) && item[1][0].PlacementYear != 0 && item[1][0].PlacementYear != null && item[1][0].PlacementYear != 'undefined'); // item[0] = pipe attr key: "<year> <material> <diameter>", item[1] = array of leaks on this pipe group.
      if (size != null) {
        sorted = sorted.slice(0, (Math.min(sorted.length, size)));
      }

      const items = sorted.map((item) => {
         const count = item[1].length;
         const key = item[0];
         const params = key.split(' ');
         return {
            key: key,
            PlacementYear: params[0],
            Material: params[1],
            PipeDiameterInch: params[2],
            PipeLength: 0,
            Count: count,
         };
      });
      const itemsIndexMap = {};
      sorted.forEach((item, index) => {
         itemsIndexMap[item[0]] = index;
      });
      const itemsKeys = sorted.map((item) => item[0]);
      Object.entries(pipesGroup).forEach((pipe) => {
         const key = pipe[0];
         if (itemsKeys.indexOf(key) != -1) {
            pipe[1].forEach((groupPipe) => {
              const pipeLength = items[itemsIndexMap[key]].PipeLength + groupPipe.Length;
              items[itemsIndexMap[key]].PipeLength = pipeLength;
              if (pipeLength > 0) {
                const relativePipeLengthByUnit = (Math.max(MIN_PIPE_LENGTH * factor[units] ,pipeLength * factor[units]) / factor.bigUnitValue[units].value)
                const leakPerKmValue = items[itemsIndexMap[key]].Count / relativePipeLengthByUnit;
                items[itemsIndexMap[key]].LeakPerKm = leakPerKmValue.toFixed(1);
              }
            });
         }
      });

      const relativeSortedItems = items.filter((item) => item.PipeLength != null && item.LeakPerKm != null).sort((a, b) => b.LeakPerKm - a.LeakPerKm);

      return (relativeSortedItems);
   }

   getLeaksByAttributeData(rawLeaksData, valuesSets, attributeName) {
      const currentYear = (new Date()).getFullYear().toString();
      if (valuesSets) {
        const leaksValuesSet = valuesSets[attributeName].filter((year) => year !== currentYear);

        const leaksForYear = [];

        let trace = [];

        if (leaksValuesSet != null) {
          leaksValuesSet.forEach((year) => {
              const filteredArray = rawLeaksData.filter((item) => item[attributeName] == year);
              leaksForYear.push(filteredArray.length);
          });

          trace.push({
              type: 'line',
              name: 'Year',
              x: leaksValuesSet,
              y: leaksForYear,
              mode: 'lines+markers',
              marker: {
              color: '#d01111',
              size: 10
              },
              line: {
              color: '#2aa6ff',
              width: 5
              }
          });
        }

        return trace;
      }
   }

   getPipesByAttributeData(pipesList, valuesSets, attributeName) {
      const pipesValuesSet = valuesSets[attributeName];

      const pipesForYear = [];

      let trace = [];

      if (pipesValuesSet != null) {
         pipesValuesSet.forEach((year, index, valuesArray) => {
            let pipeLength = 0;
            pipesList.forEach((section) => {
               if (section.PlacementYear == year) {
                  pipeLength += section.Length;
               }
            });
            if (pipeLength > 0) {
               pipesForYear.push(pipeLength);
            } else {
               // do not push the value and remove this year-key:
               valuesArray.splice(index, 1);
            }
         });

         trace.push({
            type: 'bar',
            name: 'Year',
            x: pipesValuesSet,
            y: pipesForYear,
         });
      }
      return (trace);
   }

   getLeaksByMaterialDiameterForPipeYear(rawLeaksData, valuesSets, pipesList, xAttrName, yAttrName, size) {
      let xAxisValues = valuesSets[xAttrName];
      let yAxisValues = valuesSets[yAttrName];

      let data = [];
      let data2 = [];
      let xValuesSum = {};

      yAxisValues.forEach((yVal) => {
         xAxisValues.forEach((xVal) => {
            const filteredArray = rawLeaksData.filter((item) => item[xAttrName] == xVal && item[yAttrName] == yVal);
            let cellValue = filteredArray.length;
            if (xValuesSum[xVal] == null) {
               xValuesSum[xVal] = 0;
            }
            xValuesSum[xVal] += cellValue;
         });
      });

      // sort the x axis values:
      let xCalculatedValuesSet = [];
      Object.keys(xValuesSum).forEach((key) => {
         xCalculatedValuesSet.push({x: key, y: xValuesSum[key]});
      });
      xCalculatedValuesSet = xCalculatedValuesSet.sort((a, b) => b.y - a.y);
      // get only the limmites 'SIZE':
      xCalculatedValuesSet = xCalculatedValuesSet.slice(0, (Math.min(xCalculatedValuesSet.length, size)));

      let xCalculatedValues = xCalculatedValuesSet.map((obj) => parseInt(obj.x));

      // calculate pipesLength:
      let pipes = pipesList.filter((pipe) => xCalculatedValues.indexOf(pipe.PlacementYear) != -1);
      let pipesDataObj = {};
      pipes.forEach((section) => {
         if (xCalculatedValues.indexOf(section.PlacementYear) != -1) {
            if (pipesDataObj[section.PlacementYear] == null) {
               pipesDataObj[section.PlacementYear] = 0;
            }
            pipesDataObj[section.PlacementYear] += section.Length;
         }
      });

      yAxisValues.forEach((yVal) => {
         let itrRowValues = [];
         let itrRowValues2 = [];
         xCalculatedValues.forEach((xVal) => {
            const filteredArray = rawLeaksData.filter((item) => item[xAttrName] == xVal && item[yAttrName] == yVal);
            let cellValue = filteredArray.length;
            itrRowValues.push(cellValue);
            itrRowValues2.push(cellValue / (pipesDataObj[xVal] / 1000));
         });
         let trace = {
            type: 'bar',
            name: yVal,
            x: xCalculatedValues,
            y: itrRowValues,
         };

         let trace2 = {
            type: 'bar',
            name: yVal,
            x: xCalculatedValues,
            y: itrRowValues2,
         };

         data.push(trace);
         data2.push(trace2);
      });

      return ({
         absoluteData: data,
         relativeData: data2
      });
   }

   getLeaksAsPieForAttribute(dataList, valuesSets, attributeName) {
      const valuesSet = valuesSets[attributeName];

      const dataValues = [];

      let pieData = [];

      if (valuesSet != null) {
         valuesSet.forEach((val) => {
            const filteredArray = dataList.filter((item) => item[attributeName] == val);
            dataValues.push(filteredArray.length);
         });

         // let pieLabels = valuesSet.map((item) => {
         //    let tRc = '';
         //    if (item > 10) {
         //       tRc = item;
         //    }
         //    return (tRc);
         // });

         pieData.push({
            values: dataValues,
            // labels: pieLabels,
            labels: valuesSet,
            textinfo: 'none',
            type: 'pie'
         });
      }

      return (pieData);
   }

   getPcaScorePlotData(pcaScoreData, props) {
     const { factor, units } = props.measurmentsDetails;
     let pcaData = [];
     // let data2d = Object.entries(pcaScoreData).sort((a, b) => b[1] - a[1]);
     let xAxisValues = ['OK', /*'Low',*/ 'Med', 'High']; // data2d.map((seciton) => seciton[0]); //Object.keys(pcaScoreData);
     // let values = data2d.map((seciton) => seciton[1]); //Object.values(pcaScoreData);
     let values = xAxisValues.map((category) => pcaScoreData[category] * factor[units]);

     let trace = {
      type: 'bar',
      name: 'Pipe Score',
      x: xAxisValues,
      y: values,
      marker:{
        color: ['lightgreen', 'yellow', /*'orange',*/ 'red'],
      },
     };

     pcaData = [trace];

     return (pcaData);
   }

   onAbsoluteTableRowClick(_event, rowIndex) {
     this.onTableRowClick(this.state.topProblematicsPipes, rowIndex);
   }

   onRelativeTableRowClick(_event, rowIndex) {
    this.onTableRowClick(this.state.topProblematicsPipesRelative, rowIndex);
   }

   onScoreTableRowClick = (event, rowIndex) => {
    const { scoreSelectedGroupData } = this.props;
    this.onTableRowClick(scoreSelectedGroupData, rowIndex);
   }

   onTableRowClick(data, rowIndex) {
      const clickedRow = data[rowIndex];

      const filter = {
         'YearMaterialDiameter': clickedRow.key,
      };
      this.props.onSelectGroup(filter);
   }

   cellRender(row, ...props) {
      const { factor, units } = this.props.measurmentsDetails;

      const { topProblematicsPipes } = this.state;

      let cellValue = topProblematicsPipes[row.rowIndex][row.columnKey];


      if (row.columnKey == 'PipeLength') {
        const bigUnitObj = factor.bigUnitValue[units];
        cellValue = (cellValue * factor[units]).toFixed();

         let unit;
         let val;
         if (cellValue < bigUnitObj.value) {
            unit = units;
            val = cellValue.toFixed(1);
         } else {
            unit = bigUnitObj.label;
            val = (cellValue / bigUnitObj.value).toFixed(0);
         }
         cellValue = `${val} ${unit}`;
      }

      return (
         <Cell style={{width: 'inherit', height: 'inherit'}}>{cellValue}</Cell>
      );
   }

   cellRenderRelative(row, ...props) {
    const { factor, units } = this.props.measurmentsDetails;

      const { topProblematicsPipesRelative } = this.state;
      let cellValue = topProblematicsPipesRelative[row.rowIndex][row.columnKey];

      if (row.columnKey == 'PipeLength') {
        const bigUnitObj = factor.bigUnitValue[units];
        cellValue = (cellValue * factor[units]).toFixed();

         let unit;
         let val;
         if (cellValue < bigUnitObj.value) {
            unit = units;
            val = cellValue;
         } else {
            unit = bigUnitObj.label;
            val = (Number(cellValue) / bigUnitObj.value).toFixed(0);
         }
         cellValue = `${val} ${unit}`;
      }

      return (
         <Cell style={{width: 'inherit', height: 'inherit'}}>{cellValue}</Cell>
      );
   }

   renderLoading() {
     return (
      <div style={{
          position: 'relative',
          top: '30%',
          left: '50%'
        }}>
        <CircleLoader
          loading={true}
          color={'#ffffff'}
          size={125}
        />
      </div>
     )
   }

   renderProblematicPipesBasedLeaksCount = () => {
    const { isFetchingPipes } = this.props;
    const {
      topProblematicsPipes,
    } = this.state;
    return <div>
      <p style={{ color: 'white' , margin: '0px' }}><strong>{this.context.t('Most Problematic Pipes Based on Leaks Count')}</strong></p>
      {
        isFetchingPipes && this.renderLoading()
      }
      { isFetchingPipes == false &&
        <Table
          height={340}
          width={930 / 2}
          rowsCount={topProblematicsPipes.length}
          rowHeight={40}
          headerHeight={35}
          onRowClick={this.onAbsoluteTableRowClick}
          // rowClassNameGetter={rowClassNameGetter}
          // scrollToRow={scrollTo}
          {...this.props}
        >
          <Column columnKey="PlacementYear" width={30}
              header={<Cell>{this.context.t('Placement Year')}</Cell>}
              cell={this.cellRender}
              align='center'
              flexGrow={1} />
          <Column columnKey="Material" width={30}
              header={<Cell>{this.context.t('Material')}</Cell>}
              cell={this.cellRender}
              align='center'
              flexGrow={1} />
          <Column columnKey="PipeDiameterInch" width={30}
              header={<Cell>{this.context.t('Pipe Diameter')}</Cell>}
              cell={this.cellRender}
              align='center'
              flexGrow={1} />
          {/* <Column columnKey="PipeLength" width={30}
              header={<Cell>{this.context.t('Pipe Length')}</Cell>}
              cell={this.cellRender}
              align='center'
              flexGrow={1} /> */}
          <Column columnKey="Count" width={30}
              header={<Cell>{this.context.t('Count')}</Cell>}
              cell={this.cellRender}
              align='center'
              flexGrow={1} />
          {/* <Column columnKey="LeakPerKm" width={30}
              header={<Cell>{this.context.t('LeakPerKm')}</Cell>}
              cell={this.cellRender}
              align='center'
              flexGrow={1} /> */}
        </Table>
      }
    </div>
   }

   renderProblematicPipes = () => {
    const { isFetchingPipes, measurmentsDetails: { factor, units } } = this.props;
    const {
      topProblematicsPipesRelative,
    } = this.state;

     return (
      <div style={{
        margin: '8px',
        color: 'black',
     }}>
        <p style={{ color: 'white', margin: '0 0 10px' }}><strong>{this.context.t('Most Problematic Pipes Based on Leaks per {units}', {units: factor.bigUnitValue[units].label})}</strong></p>
        {
          isFetchingPipes && this.renderLoading()
        }
        { isFetchingPipes == false &&
          <Table
            height={340}
            width={930 / 2}
            rowsCount={topProblematicsPipesRelative.length}
            rowHeight={40}
            headerHeight={35}
            onRowClick={this.onRelativeTableRowClick}
            // rowClassNameGetter={rowClassNameGetter}
            // scrollToRow={scrollTo}
            {...this.props}
          >
            <Column columnKey="PlacementYear" width={30}
                header={<Cell>{this.context.t('Placement Year')}</Cell>}
                cell={this.cellRenderRelative}
                align='center'
                flexGrow={1} />
            <Column columnKey="Material" width={30}
                header={<Cell>{this.context.t('Material')}</Cell>}
                cell={this.cellRenderRelative}
                align='center'
                flexGrow={1} />
            <Column columnKey="PipeDiameterInch" width={30}
                header={<Cell>{this.context.t('Pipe Diameter')}</Cell>}
                cell={this.cellRenderRelative}
                align='center'
                flexGrow={1} />
            <Column columnKey="PipeLength" width={30}
                header={<Cell>{this.context.t('Pipe Length')}</Cell>}
                cell={this.cellRenderRelative}
                align='center'
                flexGrow={1} />
            {/* <Column columnKey="Count" width={30}
                header={<Cell>{this.context.t('Count')}</Cell>}
                cell={this.cellRenderRelative}
                align='center'
                flexGrow={1} /> */}
            <Column columnKey="LeakPerKm" width={30}
                header={<Cell>{this.context.t('Leak per {units}', {units: factor.bigUnitValue[units].label})}</Cell>}
                cell={this.cellRenderRelative}
                align='center'
                flexGrow={1} />
          </Table>
        }
     </div>
     );
   }

   render() {
      const { layoutVisibility, onSelectGroup, onSelectScoreGroup, isFetchingPipes, scoreSelectedGroupData, scoreSelectedGroupCategory, measurmentsDetails } = this.props;
      const {
         topProblematicsPipesRelative,
         leaksByEventYear,
         // maxMaterialDiameterByPlacementYear,
         // maxMaterialDiameterVsPlacementYear,
         leaksByMaterials,
         leaksByDiameter,
         leaksByPlacementYear,
         pcaScorePlotData,
      } = this.state;

      const plotClasses = classNames(
         'dashboard-chart',
         layoutVisibility
      );

      return (
        <div  style={{ display: 'flex' }}>
          <div>
            <div style={{ display: 'flex' }}>
              <GraphComp
                title={this.context.t('Leaks by Years')}
                layout={leaksByEventYear.layout}
                data={leaksByEventYear.data}
                filterKey={'Event Year'}
                onSelectGroup={onSelectGroup}
              />
              <GraphComp
                title={this.context.t('Pipes severity score')}
                layout={pcaScorePlotData.layout}
                data={pcaScorePlotData.data}
                isFetching={isFetchingPipes}
                renderLoading={this.renderLoading}
                onSelectGroup={onSelectScoreGroup}
                filterKey={'score'}
              />
            </div>

            <div style={{ display: 'flex'}}>
              <GraphComp
                title={this.context.t('Leaks by Materials')}
                layout={leaksByMaterials.layout}
                data={leaksByMaterials.data}
                onSelectGroup={onSelectGroup}
                filterKey={'Material'}
              />
              <GraphComp
                title={this.context.t('Leaks by Diameter')}
                layout={leaksByDiameter.layout}
                data={leaksByDiameter.data}
                onSelectGroup={onSelectGroup}
                filterKey={'PipeDiameterInch'}
              />
            </div>
          </div>

           <div style={{display: 'grid'}}>

            <LeaksDataTable
                title={this.context.t('Pipes High Severity analysis:')}
                isFetching={isFetchingPipes}
                renderLoading={this.renderLoading}
                data={scoreSelectedGroupData}
                category={scoreSelectedGroupCategory}
                context={this.context.t}
                onSelectGroup={onSelectGroup}
                measurmentsDetails={measurmentsDetails}
                factor={measurmentsDetails.factor}
                units={measurmentsDetails.units}
              />

            { this.renderProblematicPipes() }

           </div>

                { false && this.renderProblematicPipesBasedLeaksCount() }
               {/*

              <GraphComp
                title={this.context.t('Leaks by Materials & Diameter vs Pipe Placement Year')}
                layout={maxMaterialDiameterByPlacementYear.layout}
                data={maxMaterialDiameterByPlacementYear.data}
                onSelectGroup={onSelectGroup}
              />

              <GraphComp
                title={this.context.t('Leaks by Materials & Diameter for Pipe Placement Year')}
                layout={maxMaterialDiameterVsPlacementYear.layout}
                data={maxMaterialDiameterVsPlacementYear.data}
                onSelectGroup={onSelectGroup}
              />


                */}

               {/*
              <GraphComp
                title={this.context.t('Leaks by Pipe Placement Year')}
                layout={leaksByPlacementYear.layout}
                data={leaksByPlacementYear.data}
                onSelectGroup={onSelectGroup}
              />
                */}

         </div>
      );
   }
}

Dashboard.contextTypes = {
   t: PropTypes.func.isRequired
};
