import React from 'react';
import Plot from 'react-plotly.js';

const GraphComp = (props) => {
  const { layout, data, filterKey, onSelectGroup, title, isFetching = false, renderLoading } = props;
  return (
  <div style={{ margin: '8px'}}>
    <p style={{color:'white'}}><strong>{title}</strong></p>
    { isFetching && ( renderLoading && renderLoading() ) }
    { isFetching == false &&
      <Plot
          data={data}
          layout={layout}
          onClick={(event) => {
            if (onSelectGroup) {
              const eventMember = (data[0].type === "pie") ? 'label' : 'x'; // the relevance member in Plot clickEvent
              const value = event.points[0][eventMember];
              const filter = {
                  [filterKey]: value,
              };
              onSelectGroup(filter);
            }
          }}
      />
    }
  </div>
  );
};

export default GraphComp;
