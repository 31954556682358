import React from 'react';
import PropTypes from 'prop-types';
import { useForm /*, Controller */ } from "react-hook-form";

// import TimeField from 'react-simple-timefield';
import Box from '@mui/material/Box';
// import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { makeStyles } from '@mui/styles';

import './style.scss';

const fontSizeStyle = '1.5rem';

const useStyles = makeStyles({
  root: {
    margin: '5px 10px'
  },
  margin: {
    margin: '3px 5px',
    display: 'block',
    fontSize: fontSizeStyle,
    padding: '5px'
  },
  largeFont: {
    fontSize: fontSizeStyle,
    width: '-webkit-fill-available'
  },
  fields: {
    padding: '5px 0',
    display: 'grid',
    gridTemplateColumns: '140px 100px',
    gridRowGap: '3px',
  },
  errorField: {
    backgroundColor: '#ffbbbb'
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '100px 140px',
    gridRowGap: '3px',
    columnGap: '5px'
  },
  labelStyle: {
    minInlineSize: 'max-content'
  }
});
export default function OperationForm(props, context) {
  // const styleClasses = useFieldStyles();
  const { fields } = props;
  const classes = useStyles();
  const { handleSubmit, register, setValue, getValues, setError, errors, clearErrors, watch } = useForm();

  React.useEffect(() => {
    fields.forEach((field) => {
      const val = field.attr && field.attr.defaultValue;
      setValue(field.id, val);
      // if (field.attr && field.attr.defaultValue !== undefined) {
      //   setValue(field.id, field.attr.defaultValue);
      // }
    });
  }, [fields]);

  return (
    <form className={classes.root} onSubmit={handleSubmit(props.onSubmit)}>
        <Box component="fieldset" className={classes.fields}>
        {
          // fields.map((field) => drawFields(field, classes))
          fields.map((field) => {
            switch (field.type) {
              case 'select':
                return (
                  <React.Fragment key={field.id}>
                    <label htmlFor={field.id}>{field.title}</label>
                    <select
                      ref={register}
                      id={field.id}
                      name={field.id}
                      disabled={field.disabled || false}
                      { ...field.attr }
                      >
                        {
                          field.options.map((option) => <option key={option.optionKey} value={option.optionKey}>{option.label}</option>)
                        }
                    </select>
                  </React.Fragment>
                );
              // case 'time':
              //   return (
              //     <React.Fragment key={field.id}>
              //       <label htmlFor={field.id}>{field.title}</label>
              //       <TimeField
              //         inputRef={register}
              //         id={field.id}
              //         name={field.id}
              //         className={errors[field.id] && classes.errorField}
              //         placeholder={errors[field.id] && errors[field.id].message}
              //         onChange={() => errors[field.id] && clearErrors(field.id)}
              //         disabled={field.disabled || false}
              //         {...field.attr || null}
              //       />
              //     </React.Fragment>
              //   );
              default:
                return (
                  <React.Fragment key={field.id}>
                    <label className={classes.labelStyle} htmlFor={field.id}>{field.title}</label>
                    <input
                      ref={register}
                      id={field.id}
                      name={field.id}
                      type={field.type || 'text'}
                      className={errors[field.id] && classes.errorField}
                      placeholder={errors[field.id] && errors[field.id].message}
                      onChange={() => errors[field.id] && clearErrors(field.id)}
                      disabled={field.disabled || false}
                      {...field.attr || null}
                    />
                  </React.Fragment>
                );
            }
          })
        }
        </Box>
        <Box className={classes.buttons}>
          <Button
            className={classes.largeFont}
            variant='contained'
            color='primary'
            type='submit'
            disabled={!props.enable}>{context.t('Submit')}</Button>
          <Button
            className={classes.largeFont}
            variant='contained'
            color='inherit'
            disabled={!props.advancedOptions}
            onClick={() => {
              clearErrors();
              const values = getValues();
              let haveErrors = false;
              Object.entries(values).forEach(([paramKey, value]) => {
                if (!value) {
                  if (!haveErrors) haveErrors = true;

                  setError(paramKey, {
                    type: 'required',
                    message: context.t('Required'),
                  });
                }
              });

              if (!haveErrors) {
                props.onSaveDefault(values)
              }
            }}>{context.t('Set Default')}</Button>
        </Box>
    </form>
  );
}

OperationForm.contextTypes = {
  t: PropTypes.func.isRequired
};

OperationForm.propTypes = {
  advancedOptions: PropTypes.bool,
  enable: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.object).isRequired,
  onSubmit: PropTypes.func.isRequired,
  onSaveDefault: PropTypes.func,
};
