import React from 'react';

import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../components';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
import { ALERT_SAMPLES_COUNT_FOR_PAGE } from '../../actions/SamplesActions';
import Pagination from 'react-js-pagination';
import CheckBoxCell from '../CheckBoxCell/CheckBoxCell';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';

import { useDispatch, useSelector } from 'react-redux';
import { getSamplesAction } from '../../actions/MobileSamplesActions';
import { setIndexes } from '../../actions/MiddlewareActions';

require('fixed-data-table-2/dist/fixed-data-table.css');
require('../AlertsTabs/SamplesTab/SamplesTab.scss');

const MobileDevicesBIST = ({ bist, uiInfo, timeZone }, context) => {
  const { pageWidth, pageHeight } = uiInfo;
  const pWidth = 0.3927 * pageWidth;
  const pHeight = 0.34 * pageHeight;
  const [scrollToIndex, setScrollToIndex] = React.useState();
  const [sortField, setSortField] = React.useState('sample_time');
  const [sortDir, setSortDir] = React.useState('desc');

  const sortBIST = (field, dir) => {
    setSortField(field);
    setSortDir(dir);

    const sorted = bist.sort((a, b) => {
      let sortResult = 0;
      const fieldA = a[field] || 0;
      const fieldB = b[field] || 0;
      if (fieldA > fieldB) {
        sortResult = dir === 'asc' ? 1 : -1;
      } else if (fieldA < fieldB) {
        sortResult = dir === 'asc' ? -1 : 1;
      }

      return sortResult;
    });

    // const indexMap = setIndexes(sorted, 'sample_uid');
    // dispatch(getSamplesAction(true, sorted, indexMap));

    // setUiSamples(sorted);
  };

  const sortProps = () => ({
    sort: sortBIST,
    sortBy: sortField,
    sortDir: sortDir,
  })

  const handleRowClick = (_event, rowIndex) => {
    const selectedSample = bist[rowIndex];
    // props.onSelectMobileSample(selectedSample);
  };

  const handleRowClassNameGetter = (index) => {
    // return bist[index]?.sample_uid === props.selectedSample
    //   ? 'active-row'
    //   : '';
    return '';
  };

  const myHandleRowClick = (event, rowIndex) => {
    if (event.target.type === undefined || event.target.type !== 'checkbox') {
      handleRowClick(event, rowIndex);
    }
  }

  return (
    <div className="samples-table">
      <Table
        rowsCount={bist.length}
        width={pWidth}
        maxHeight={pHeight}
        headerHeight={30}
        onRowClick={myHandleRowClick}
        rowClassNameGetter={handleRowClassNameGetter}
        scrollToRow={scrollToIndex}
        rowHeight={30}
      >
        <Column
          columnKey="timestamp"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={
            <TextCell
              data={bist}
              field="timestamp"
              additional={timeZone}
              dateType={DateType.DATE_AND_TIME}
            />
          }
          width={100}
          flexGrow={5}
        />
        <Column
          columnKey="user_name"
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={<TextCell data={bist} field="user_name" />}
          width={80}
          flexGrow={1}
        />
        <Column
          columnKey="is_assambly"
          width={70}
          flexGrow={1}
          header={<SortableHeaderCell sortTable={sortProps()}/>}
          cell={({ rowIndex }) => {
            const isAssambly = bist[rowIndex]['is_assambly'];
            const char = isAssambly ? <>&#x2714;</> : <>&#x2718;</>;
            return (<Cell>{char}</Cell>);
          }}
        />
        {
          [1,2,3,4,5].map((freq, index) => (
            <Column
              key={index}
              columnKey={`bist_freq_${freq}`}
              header={({columnKey}) => bist[0] && <Cell>{bist[0][columnKey]}</Cell>}
              cell={<TextCell data={bist} field={`bist_rms_${freq}`}/>}
              width={50}
              flexGrow={1}
            />
          ))
        }
      </Table>
    </div>
  );
};

MobileDevicesBIST.contextTypes = {
  t: PropTypes.func.isRequired,
};

export default MobileDevicesBIST;
