import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';

function downloadFileFromURl(url) {
  // script that download file from url:
  if (url) {
    const tempElement = document.createElement('a');
    tempElement.href = url;
    tempElement.target = '_blank';
    tempElement.download = url.substring((url.lastIndexOf('/') + 1), url.lastIndexOf('?'));
    document.body.appendChild(tempElement);
    tempElement.click();
    document.body.removeChild(tempElement);
  }
}

function getUiElement(fs, url) {
  return <audio src={url} controls />;
  // if (fs > 4000) {
  //   return <audio src={url} controls />;
  // } else {
  //   return (
  //     <Button
  //       variant="contained"
  //       size="small"
  //       startIcon={<SaveIcon />}
  //       onClick={() => downloadFileFromURl(url)}
  //     >
  //       Download
  //     </Button>
  //   );
  // }
}

function DebugWaveData(props) {
  const { classificationsOptions: options, user } = props;

  if (props.audioData == null) {
    return null;
  } else {

    const handleChange = (event, itemId) => {
      // setAge(event.target.value);
      props.setClassificationForAudio(event.target.value, itemId);
    };

    const tableBody = props.audioData.map((item) => (
      <tr key={item.DeviceID}>
        <td>{item.SensorIndexName}</td>
        <td>{item.SensorID}</td>
        <td>{item.DeviceID}</td>
        <td>{getUiElement(props.audioFs, item.radioUrl)}</td>
        <td>{getUiElement(props.audioFs, item.waveUrl)}</td>
        <td>{getUiElement(props.audioFs, item.waveUrlClean)}</td>
        { false && user.analysesExpert &&
          <td>
            <Select
              labelId={`audio-${item.ID}-classification-label`}
              id={`audio-${item.ID}-classification`}
              value={item.AudioClassification}
              onChange={(event) => handleChange(event, item.ID)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {
                options.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              }
            </Select>
          </td>
        }
      </tr>
    )
    );

    return (
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Sensor ID</th>
              <th>Device ID</th>
              <th>Radio</th>
              <th>Audio</th>
              <th>Audio Clean</th>
              { false && user.analysesExpert &&
                <th>Classification</th>
              }
            </tr>
          </thead>
          <tbody>
            {tableBody}
          </tbody>
        </table>
    );
  }
}

export default DebugWaveData;
