import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';

const config = {
  displaylogo: false,
  responsive: true,
  displayModeBar: false,
};

const flipMatrix = (matrix) => {
  let result = [];
  if (matrix && matrix[0]) {
    // eslint-disable-next-line no-unused-vars
    result = matrix[0].map((column, index) => (
      matrix.map((row) => row[index])
    ));
  }
  return result;
};

const rotateMatrixCounterClockwise = (matrix) => (
  flipMatrix(matrix)//.reverse()
);

function BIAS(props) {

  const layout = {
    'paper_bgcolor':"#00000000",
    autosize: false,
    hovermode: props.hoverMode || false,
    width: props.small ? 270 : 350,
    height: props.small ? 200 : 240,
    title: { text: props.title },
    margin: { l: 50, r: 10, b: 40, t: 30 },
    xaxis: {
      tickmode: 'auto',
      nticks: 10,
      title: {
        text: props.xTitle,
        font: {
          color: '#8d8d8d'
        }
      }
    },
    yaxis: {
      title: {
        text: props.yTitle,
        font: {
          color: '#8d8d8d'
        }
      }
    }
  };

  let max = 0;
  if (props.data && props.data.length > 0) {
    const allValues = props.data.join().split(',').map((x) => Number(x));
    max = Math.max(...allValues);
  }
  const data = [
    {
      type: 'heatmap',
      showscale: false,
      z: rotateMatrixCounterClockwise(props.data),
      zmin: 0,
      zmax: max,
      // zsmooth: 'best',
    }
  ];

  return (
    <Plot
      layout={layout}
      config={config}
      data={data}
    />
  );
}

BIAS.propTypes = {
  data: PropTypes.array.isRequired,
  hoverMode: PropTypes.bool,
  small: PropTypes.bool,
  title: PropTypes.string,
  xTitle: PropTypes.string,
  yTitle: PropTypes.string,
};

export default BIAS;
