import { connect } from 'react-redux';
import { SampleInfo } from '../../components';
import { setAudioClassification } from '../../actions/SamplesOutputDataActions';

function mapStateToProps(state, props) {
  const units = state.local.units;
  const distanceFactor = state.local.factor[units];
  const user = state.leaksList.user;

  const selectedSample = state.samples.selectedSample;

  const { audioData, probability, tensor, trend, analysisData } = state.samples;

  const graphs = state.graphs;
  const audioClassificationsOptions = state.leaksList.optionList.options.AudioClassificationEnum;

  const distancesForAxis = graphs.distancesData.map((x) => {
    const val = (x === '') ? '' : (x * distanceFactor).toFixed();
    return val;
  });
  const sensorsForAxis = graphs.distancesDataSensorsOnly.map((x) => {
    const val = (x === '') ? '' : (x * distanceFactor).toFixed();
    return val;
  });

  return {
    ...props,
    audioData: audioData,
    selectedSample,
    distanceFactor,
    analysisData,
    probability,
    tensor,
    trend,
    rawData: graphs.rawData,
    tensorData: graphs.tensor,
    graphParams: graphs.params,
    correlationInstance: graphs.correlationInstance,
    correlationAve: graphs.correlationAve,
    histogramInstance: graphs.histogramInstance,
    histogramAve: graphs.histogramAve,
    distancesForAxis: distancesForAxis,
    sensorsForAxis: sensorsForAxis,
    sensorsPositions: graphs.sensorsPositions,
    bias: graphs.bias,
    history: graphs.history.map((data) => data.histogram),
    historyNoiseData: [...graphs.historyNoiseData],
    maxProbabilityPositions: graphs.maxProbabilityPositions,
    maxProbabilityGraphs: graphs.maxProbabilityGraphs,
    audioClassificationsOptions,
    user,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    setClassificationForAudio: (classificationCode, itemId) => {
      dispatch(setAudioClassification(itemId, classificationCode));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SampleInfo);
