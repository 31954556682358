import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import DevicesMultiSelect from './DevicesMultiSelect';

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  root: {
    margin: '5px',
    height: '88vh',
  },
  textArea: {
    width: '100%',
    padding: '0 4px',
    resize: 'none'
  },
  center: {
    display: 'block',
    width: '70%',
    margin: '0 auto'
  }
});

export default function DevicesSelection(props, context) {
  const { devicesList, selectedDevices, handleSelection } = props;
  const classes = useStyles();

  const textAreaRef = React.useRef();

  function parseDevicesFilter() {
    const notFoundDevices = [];
    const devicesMap = {};
    const checkedDevicesList = [...selectedDevices];

    devicesList.forEach((device, index) => devicesMap[device.DeviceID] = index);
    if (textAreaRef.current.value) {
      const devices = textAreaRef.current.value.split('\n');

      devices.forEach((device) => {
        if (devicesMap[device] !== undefined) {
          if (checkedDevicesList.indexOf(device) === -1) {
            /* only if exist in device list and not exist in the new checked devices list */
            checkedDevicesList.push(device);
          }
        } else {
          notFoundDevices.push(device);
        }
      });

      handleSelection(checkedDevicesList);

      textAreaRef.current.value = notFoundDevices.join('\n');
    }
  }
  return (
    // <ClickAwayListener onClickAway={null/*() => setOpen(false)*/}>
    //   <div className={classes.root}>
        <Paper classes={{ root: classes.root }}>
          <DevicesMultiSelect
            items={devicesList}
            selectedItems={selectedDevices}
            workingMode={'config'}
            handleSelection={handleSelection}
          />
          <TextareaAutosize
            className={classes.textArea}
            maxRows={6}
            minRows={6}
            // rowsMin={6}
            // rowsMax={6}
            placeholder={context.t('Copy Device IDs from Excel')}
            ref={textAreaRef}
          />
          <Button
            classes={{ root: classes.center }}
            variant='contained'
            color='primary'
            onClick={parseDevicesFilter}
          >{context.t('Check Devices')}</Button>
        </Paper>
    //   </div>
    // </ClickAwayListener>
  );
}

DevicesSelection.propTypes = {
  devicesList: PropTypes.array,
  handleSelection: PropTypes.func,
  selectedDevices: PropTypes.array,
};

DevicesSelection.contextTypes = {
  t: PropTypes.func.isRequired
};
