import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../../components';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';

class SensorHistoryTab extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { sensorsHistory, uiInfo, timeZone } = this.props;
        let pWidth = 0.395 * (uiInfo.pageWidth - 10);
        let pHeight = 0.494 * uiInfo.pageHeight;

        const data = (sensorsHistory == null || sensorsHistory.data == null) ? [] : sensorsHistory.data;
        return (
            <div>
                <Table
                    rowsCount={data.length}
                    width={pWidth}
                    maxHeight={pHeight - 49}
                    headerHeight={30}
                    rowHeight={30}>
                    <Column
                        header={<Cell>{this.context.t('Date')}</Cell>}
                        cell={<TextCell data={data} field="TimeOfMaintenance" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
                        width={60}
                        flexGrow={1}
                    />
                    <Column
                        header={<Cell>{this.context.t('Device ID')}</Cell>}
                        cell={<TextCell data={data} field="DeviceID" />}
                        width={70}
                        flexGrow={1}
                    />
                    <Column
                        header={<Cell>{this.context.t('User')}</Cell>}
                        cell={<TextCell data={data} field="UserName" />}
                        width={10}
                        flexGrow={1}
                    />
                    <Column
                        header={<Cell>{this.context.t('Action')}</Cell>}
                        cell={<TextCell data={data} field="Action" />}
                        width={50}
                        flexGrow={1}
                    />
                    <Column
                        header={<Cell>{this.context.t('Comment')}</Cell>}
                        cell={<TextCell data={data} field="Comment" />}
                        width={80}
                        flexGrow={2}
                    />
                </Table>
            </div>
        );
    }
}

SensorHistoryTab.contextTypes = {
    t: PropTypes.func.isRequired
}

export default SensorHistoryTab;
