import * as types from '../constants/ActionTypes';

export function setPipesSource(source) {
    return {
        type: types.SET_PIPES_SOURCE,
        source
    };
}

export function setCoverageSource(source) {
  return {
    type: types.SET_COVERAGE_SOURCE,
    source
  };
}

export const sensorGraphesToggle = (display) => {
  return {
    type: types.SENSOR_GRAPHS_TOGGLE,
    display,
  };
}
