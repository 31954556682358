import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell, SortableHeaderCell } from '../../../components';
import { DateType } from '../../../containers/UIhelper/UIDateFormater';
import { ALERT_SAMPLES_COUNT_FOR_PAGE } from '../../../actions/SamplesActions';
import Pagination from 'react-js-pagination';

require('fixed-data-table-2/dist/fixed-data-table.css');
require('./SamplesTab.scss');

class SamplesTab extends React.Component {
    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
        this.rowClassNameGetter = this.rowClassNameGetter.bind(this);
        this.handlePageClick = this.handlePageClick.bind(this);

        this.state = {
            currentPage: 1,
            totalItemsCount: 0
        }
    }

    onRowClick(event, rowIndex) {
        this.props.selectSample(this.props.samples[rowIndex]);
    }

    rowClassNameGetter(rowIndex) {
        const { selectedSample, samples } = this.props;
        return (selectedSample != null && selectedSample.ID == samples[rowIndex].ID) ? 'active-row' : '';
    }

    handlePageClick(pageNumber) {
        this.setState({
            currentPage: pageNumber
        })
        this.props.onPageIndexChange(pageNumber - 1);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.selectedFeature !== nextProps.selectedFeature) {
            this.state = {
                currentPage: 1,
                totalItemsCount: 0
            }
        } else {
            this.state = {
                currentPage: nextProps.page,
                totalItemsCount: this.state.totalItemsCount
            }
        }

        if (nextProps.samplesCount > 0) {
          if (this.state.totalItemsCount === 0) {
            this.setState({
              totalItemsCount: nextProps.samplesCount
            });
          }
        }
    }

    render() {
        const { samplesCount, samples, uiInfo, sortSamples, local, distanceFactor, page } = this.props;
        const { totalItemsCount } = this.state;

        let pWidth = 0.395 * (uiInfo.pageWidth - 10);
        let pHeight = 0.494 * uiInfo.pageHeight;

        return (
            <div className="samples-table">
                <Table
                    rowsCount={samples.length}
                    width={pWidth}
                    maxHeight={pHeight - 117}
                    headerHeight={30}
                    onRowClick={this.onRowClick}
                    rowClassNameGetter={this.rowClassNameGetter}
                    rowHeight={30}>
                    <Column
                        columnKey=""
                        header={""}
                        cell={<TextCell data={samples} field="Priority" />}
                        width={15}
                        flexGrow={1}
                    />
                    <Column
                        columnKey="SampleDateAndTime"
                        header={<SortableHeaderCell sortTable={sortSamples} />}
                        cell={<TextCell data={samples} field="sampleTime" additional={local} dateType={DateType.DATE_AND_TIME} />}
                        width={100}
                        flexGrow={7}
                    />
                    <Column
                        columnKey="DistanceAM"
                        header={<SortableHeaderCell sortTable={sortSamples} />}
                        cell={<TextCell data={samples} field="weightedDistance" distanceFactor={distanceFactor} />}
                        width={100}
                        flexGrow={7}
                    />
                    <Column
                        columnKey="IntensityA"
                        header={<SortableHeaderCell sortTable={sortSamples} />}
                        cell={<TextCell data={samples} field="weightedIntensity" />}
                        width={100}
                        flexGrow={7}
                    />
                </Table>

                <div className="center">
                    <Pagination
                        prevPageText={this.context.t('prev')}
                        nextPageText={this.context.t('next')}
                        firstPageText={this.context.t('first')}
                        lastPageText={this.context.t('last')}
                        activePage={this.state.currentPage}
                        itemsCountPerPage={ALERT_SAMPLES_COUNT_FOR_PAGE}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageClick}
                    />
                </div>
            </div>
        );
    }
}

SamplesTab.contextTypes = {
    t: PropTypes.func.isRequired
}

export default SamplesTab;
