import { connect } from 'react-redux';
import { FILTERS_COUPLES } from '../../constants/SettingsKeys';

import {
    fetchCouplesIfNeeded,
    setCouplesFilters,
    setCouplesDefFilters,
    sortCouples
} from '../../actions/CouplesActions';
import { invalidateProject } from '../../actions/ProjectsActions';

import CouplesSettings from '../../components/CouplesSettings/CouplesSettings';

const mapStateToProps = (state, ownProps) => {
    //const { selectedProject } = state.leaksList;
    const selectedProject = state.leaksList.selectedProject;
    let couples = [];
    if (state.leaksList.leaksByProject[selectedProject]) {
      couples = state.leaksList.leaksByProject[selectedProject].couples.couples;
    }

    // for display only type the exist in the project:
    // ( NOT IN USE. after filtering is display only the filtered items in the select dropdown)
    let tempMaterialsList = [];
    let materialsList = [];
    couples.forEach(couple => {
        const material = couple.Material;
        if (tempMaterialsList.indexOf(material) === -1) {
            tempMaterialsList.push(material);
            materialsList.push({ value: material, label: material });
        }
    });

  return Object.assign({}, {
      selectedProject,
      materialsList,
      initialValues: state.leaksList.filters.couples.defFilters,
      optionList: state.leaksList.optionList.options
  }, ownProps);
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSubmit: (values, dispatch, props) => {
      const {selectedProject, processFilters, cleanEmptyFilters} = props;
      var filters = processFilters(Object.assign({}, values));
      dispatch(invalidateProject(selectedProject));
      dispatch(setCouplesDefFilters(filters.defFilters));
      dispatch(setCouplesFilters(cleanEmptyFilters(filters.filters)));
      dispatch(sortCouples(filters.sort.field, filters.sort.dir));
			dispatch(fetchCouplesIfNeeded(selectedProject));

			localStorage.setItem(FILTERS_COUPLES, JSON.stringify(filters.defFilters));
    }
  };
};

const CCouplesSettings = connect(
  mapStateToProps,
  mapDispatchToProps
)(CouplesSettings);

export default CCouplesSettings;
