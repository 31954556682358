import React from 'react';
import PropTypes from 'prop-types';

import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';

class AlertsMenu extends React.Component {

  render() {
    const { onChange } = this.props;
    return (
      <div className="manage-menu">
        <button
          className="btn-menu alerts"
          title={this.context.t('Alerts')}
          onClick={() => {
            onChange('alerts');
          }
          }
        />

        <button
          className="btn-menu noise"
          title={this.context.t('Noise')}
          onClick={() => {
            onChange('noise');
          }
          }
        >N</button>

        {ENV !== ENVIRONMENTS.SINGAPORE && <button
          className="btn-menu pressure"
          title={this.context.t('Pressure')}
          onClick={() => {
            onChange('prsAlerts');
          }
          }
        >P</button>}

      </div>
      );
  }
}

AlertsMenu.contextTypes = {
  t: PropTypes.func.isRequired
}

export default AlertsMenu;
