// ~~ CTableSwitch Preparing the relevant data before write it on table ~~ //
import { connect } from 'react-redux';
import { reset, initialize, setSubmitSucceeded } from 'redux-form';
import { routes, DRAW_TYPES } from '../../constants/Misc';
import { TableSwitch } from '../../components';
import * as actionsAlerts from '../../actions/AlertsActions';
import * as actionsNoiseAlerts from '../../actions/NoiseAlertsActions';
import * as actionsAssessments from '../../actions/AssessmentsActions';
import * as actionsCouples from '../../actions/CouplesActions';
import * as actionsCustomers from '../../actions/CustomersActions';
import * as actionsGlobals from '../../actions/GlobalsActions';
import * as actionsProjects from '../../actions/ProjectsActions';
import * as actionsSensors from '../../actions/SensorsActions';
import * as actionsSOPs from '../../actions/SOPsActions';
import * as installActions from '../../actions/InstallActions';
import * as actionsMapHandler from '../../actions/MapActionsHandler';
import * as actionsPrsTranAlerts from '../../actions/PrsTransientAlertsActions';
import * as actionsMobileDisplay from '../../actions/MobileStateAction';
import { featuresTypes } from '../../components/Map/Layers/Options';
import * as mobileTasksActions from '../../actions/MobileTasksActions';
// Linking between the ReduxState (store) and UI Components
const mapStateToProps = (state, ownProps) => {
  const { units, timeZone } = state.local;
  const distanceFactor = state.local.factor[units];
  const optionList = state.leaksList.optionList;
  const user = state.leaksList.user;
  const filters = state.leaksList.filters;
  const selectedProjectId = state.leaksList.selectedProject;
  const selectedProjectItem =
    state.leaksList.projectsList.items[
      state.leaksList.projectsList.projectIndexMap[selectedProjectId]
    ];
  const projectState = state.leaksList.leaksByProject[selectedProjectId];
  let selectedFeature = state.leaksList.leaksByProject.selectedFeature;
  const {
    displayAssessments,
    displayAssessmentsOnlyRealLeaks,
    selectedAssessment,
  } = state.leaksList.leaksByProject;
  const alertsContextMenu = state.leaksList.pressure.context;
  const { selectedManageItem, managementContext } = state.manage;
  let assessmentsData = null;
  let hasAssessments = false;
  const path = ownProps.path;
  const installContext = state.install.installContext;
  const planningState = state.planning;
  const isDisplayCityColumn =
    selectedProjectItem != null
      ? selectedProjectItem.IsDisplayCityColumn
      : false;
  const addingManualAlertMode = state.temp.newManualAlert != null;
  const addingManualNoiseAlertMode = state.temp.newManualNoiseAlert != null;
  const noiseAlertsSortParams = state.noiseAlerts.sortBy;
  const mobileTasksSort = state.mobile.tasks.mobileTasksSort;
  const mobileTasksFilters = state.mobile.tasks.mobileTasksFilters;

  const mobileDisplayMode = state.mobile.modeState.mode;
  let items = [];
  let indexMap = {};
  let title = path[0].toUpperCase() + path.slice(1);
  if (displayAssessments) {
    title += ' > Assessments';
  }
  if (path === 'manage') {
    title = 'Management > ';
    title += managementContext;
  }
  if (projectState != null) {
    switch (ownProps.path) {
      case routes.ALERTS:
        switch (alertsContextMenu) {
          case 'alerts':
            items = projectState.items;
            indexMap = projectState.indexMap;
            break;

          case 'prsAlerts':
            title = 'Pressure Alerts';
            items = state.leaksList.pressure.transientAlerts.alerts;
            indexMap = state.leaksList.pressure.transientAlerts.indexMap;
            break;

          case 'noise':
            title = 'Noise Alerts';
            items = state.noiseAlerts.items;
            indexMap = state.noiseAlerts.indexMap;
            selectedFeature = state.noiseAlerts.selected;
            break;

          default:
            break;
        }
        break;
      case routes.SENSORS:
        items = projectState.sensors.sensors;
        indexMap = projectState.sensors.indexMap;
        break;
      case routes.COUPLES:
        const selectedFeatureType =
          state.leaksList.leaksByProject.selectedFeatureType;
        if (selectedFeatureType === 'couple') {
          assessmentsData = projectState.assessments[selectedFeature];
        }
        if (displayAssessments && assessmentsData != null) {
          indexMap = assessmentsData.indexMap;
          if (!displayAssessmentsOnlyRealLeaks) {
            // Show All:
            items = assessmentsData.assessments;
          } else {
            items = assessmentsData.assessments.filter(
              (item) => [6, 3].indexOf(item.AlertState) !== -1
            ); // 6 = Fixed, 3 = Located
            // indexMap = items.map((item, index) => ({[item.id]: index }));
          }
        } else {
          items = projectState.couples.couples;
          indexMap = projectState.couples.indexMap;
        }
        hasAssessments =
          assessmentsData != null &&
          assessmentsData.assessments != null &&
          assessmentsData.assessments.length > 0;
        break;
      case routes.INSTALL:
        if (installContext === 'SOPs') {
          items = projectState.sops.sops;
          indexMap = projectState.sops.indexMap;
          title += ' > Installation Points';
        } else if (installContext === 'Sensors') {
          items = projectState.sensors.sensors;
          indexMap = projectState.sensors.indexMap;
          title += ' > Sensors';
        } else if (installContext === 'Interferences') {
          items = projectState.interferences.interferences;
          indexMap = projectState.interferences.indexMap;
          title += ' > Areas of Interest';
        }

        break;
      case routes.MANAGE:
        switch (managementContext) {
          case 'Customers':
            items = state.leaksList.customers.items;
            indexMap = state.leaksList.customers.indexMap;
            break;
          case 'Projects':
            items = state.leaksList.projectsList.items;
            indexMap = state.leaksList.projectsList.projectIndexMap;
            break;
          case 'Users':
            items = state.management.users;
            indexMap = state.management.usersIndexMap;
            break;
        }
        break;

      case routes.MOBILE: {
        switch (mobileDisplayMode) {
          case 'Tasks': {
            const {
              activeFilter,
              items: tasks,
              indexMap: tasksIndexMap,
              filteredIndexMap: tasksFilteredIndexMap,
              filteredItems: tasksFilterItems,
            } = state.mobile.tasks;
            items = activeFilter ? tasksFilterItems : tasks;
            indexMap = activeFilter ? tasksFilteredIndexMap : tasksIndexMap;
            selectedFeature = state.mobile.tasks.selectedTask;
            break;
          }
          case 'Alerts': {
            items = state.mobile.alerts.items;
            indexMap = state.mobile.alerts.indexMap;
            selectedFeature = state.mobile.alerts.selectedAlert;
            break;
          }

          case 'Devices': {
            items = state.mobile.devices.items;
            indexMap = state.mobile.devices.indexMap;
            selectedFeature = state.mobile.devices.selectedDeviceId;
            break;
          }
        }

        break;
      }
      case routes.INPECT:
        // console.log(`Entered {case ${routes.INPECT}} in <CtableSwitch></CtableSwitch> to load relevant items `);
        // items = state.inspect;
        break;

      default:
        break;
    }
  }

  return {
    ...ownProps,
    title,
    items,
    indexMap,
    user,
    filters,
    selectedProjectId,
    selectedFeature,
    alertsContextMenu,
    displayAssessments,
    displayAssessmentsOnlyRealLeaks,
    managementContext,
    selectedManageItem,
    manageItem: selectedManageItem,
    assessments: assessmentsData,
    hasAssessments,
    selectedAssessment,
    distanceFactor,
    timeZone,
    optionList,
    installContext,
    planningState,
    isDisplayCityColumn,
    addingManualAlertMode,
    addingManualNoiseAlertMode,
    noiseAlertsSortParams,
    mobileDisplayMode,
    mobileTasksSort,
    mobileTasksFilters,
  }
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { path } = ownProps;

  switch (ownProps.path) {
    case routes.INPECT:
      return {
        showHideAlerts: function () {
          //console.log(value);
          // dispatch(actionsMapHandler.handleLayerChange(featuresTypes.INSPECT_ALERTS, value));
          ownProps.setMapLayersDisplay(null, featuresTypes.INSPECT_ALERTS);
        },
      };
      case routes.MOBILE: {
        return {
          selectTask: (item, isMulti) => {
            dispatch(mobileTasksActions.setSelectedTasks(item.task_uuid, isMulti));
          },
          onChangeMobileDisplayMode: (modeContext) => {
            if (modeContext) {
              dispatch(actionsMobileDisplay.setDisplayMode(modeContext));
            }
          },
        }
      }
    default:
      return {
        onItemClick: (item, onFailCallBack, additionalData) => {
          dispatch(actionsGlobals.setSelectedItem(item, onFailCallBack, additionalData));
        },
        sortTable: (field, dir) => {
          const { installMode } = ownProps;

          switch (path) {
            case 'alerts':
              dispatch((dispatch, getState) => {
                const state = getState();
                const alertsContext = state.leaksList.pressure.context;
                if (alertsContext === 'alerts') {
                  dispatch(actionsAlerts.sortLeaks(field, dir));
                } else {
                  dispatch(actionsPrsTranAlerts.sortAlerts(field, dir));
                }
              });
              break;
            case 'sensors':
              dispatch(actionsSensors.sortSensors(field, dir));
              break;
            case 'couples':
              dispatch(actionsCouples.sortCouples(field, dir));
              break;
            case 'install':
              const installContext = installMode.installContext;
              if (installContext === 'SOPs') {
                dispatch(actionsSOPs.sortSOPs(field, dir));
              } else if (installContext === 'Sensors') {
                dispatch(actionsSensors.sortSensors(field, dir));
              }
              // else if(installContext == 'Interference'){
              //   dispatch(actionsInterference.)
              // }
              break;
            case 'manage':
              switch (ownProps.managementContext) {
                case 'Users':
                  break;
                case 'Projects':
                  dispatch(actionsProjects.sortProjects(field, dir));
                  break;
                case 'Customers':
                  dispatch(actionsCustomers.sortCustomers(field, dir));
                  break;
              }
              break;

            default:
              break;
          }
        },
        addNewInstallItem: (key) => {
          const { selectedProject } = ownProps;
          // if (key == 'Interferences'){
          //  alert("INTERFERENCES PLUS BUTTON");
          // }
          // else if(key == 'SOPs'){
          //   alert("SOP'S PLUS BUTTON");
          // }

          dispatch(actionsGlobals.selectFeature(selectedProject, '', '', ''));
          dispatch(installActions.addNewSOP());
          console.log('Add new SOP');

          if (key === 'Interferences') {
            dispatch(actionsMapHandler.setMapDrawMode(true, DRAW_TYPES.CIRCLE));
          }
        },
        onChangeInstallContext: (key) => {
          // Our key that sent from the InstallMenu.js e.g "Interference\Sensors\SOPs"
          dispatch(installActions.changeContext(key));
        },
        // onChangeMobileDisplayMode: (mode) => {
        //   dispatch(actionsMobileDisplay.setDisplayMode(mode));
        // },
        deleteTask: (taskId) => {
          dispatch(mobileTasksActions.deleteTask(taskId));
        },
        addNewManualAlert: (isInMode) => {
          dispatch(actionsGlobals.setSelectedItem(null)); // unselect item.
          dispatch(
            actionsMapHandler.setMapDrawMode(isInMode, DRAW_TYPES.POINT)
          );
          dispatch(actionsAlerts.setAddingNewManualAlertMode(isInMode));
          dispatch(reset('add-alert')); // clear add-alert form fields
        },
        addNewManualNoiseAlert: (isInMode) => {
          dispatch(actionsGlobals.setSelectedItem({})); // unselect item.
          dispatch(
            actionsNoiseAlerts.setAddingNewManualNoiseAlertMode(isInMode)
          );
          dispatch(reset('add-noise-alert')); // clear add-alert form fields
        },
        createAlertFromNoiseAlert: (noiseAlertId) => {
          dispatch(async (dispatch, getState) => {
            const state = getState();
            const noiseAlert = state.noiseAlerts.items[state.noiseAlerts.indexMap[noiseAlertId]];
            dispatch(actionsNoiseAlerts.selectNoiseAlert({}));
            dispatch(actionsPrsTranAlerts.changeAlertsContext('alerts'));

            await dispatch(actionsAlerts.setAddingNewManualAlertMode(true, noiseAlert.ID));
            await dispatch(actionsMapHandler.setMapDrawMode(true, DRAW_TYPES.POINT));
            await dispatch(actionsMapHandler.saveDrawnFeature([[noiseAlert.Longitude, noiseAlert.Latitude]]));

            dispatch(actionsGlobals.setFieldText('add-alert', 'ABC', 1));
            dispatch(actionsGlobals.setFieldText('add-alert', 'DetectionDateTime', new Date().toISOString().split('T')[0]));
            dispatch(actionsGlobals.setFieldText('add-alert', 'fixDate', new Date().toISOString().split('T')[0]));
            dispatch(actionsGlobals.setFieldText('add-alert', 'AlertState', noiseAlert.AlertState));
            dispatch(actionsGlobals.setFieldText('add-alert', 'AlertType', 0));
            dispatch(actionsGlobals.setFieldText('add-alert', 'Coordinate', `${noiseAlert.Latitude}, ${noiseAlert.Longitude}`));

          })
        },
        setCoupleAlertsShowOnlyRealLeaks: (show) => {
          dispatch(actionsAssessments.setCoupleAlertsShowOnlyRealLeaks(show));
        },
        loadPressureAlerts: (from, to) => {
          dispatch(actionsPrsTranAlerts.fetchPrsTransientAlerts(from, to));
        },
      };
  }
};

const CTableSwitch = connect(mapStateToProps, mapDispatchToProps)(TableSwitch);

export { CTableSwitch };
