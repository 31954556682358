import { connect } from 'react-redux';
import { setOpenPCA, setOpenStatus, setAlertType } from '../../actions/AlertsActions';
import SelectCell from '../../components/SelectCell/SelectCell';
import * as mobileSamplesActions from '../../actions/MobileSamplesActions';

const mapStateToProps = (state, ownProps) => {
  const selectedProject = state.leaksList.selectedProject;
  const leaksIds = state.leaksList.leaksByProject[selectedProject].leaksIds;
  return {
    ...ownProps,
    selectedProject: selectedProject,
    user: state.leaksList.user,
    leaksIds,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    openStatusDialog: (isOpen, id, stateName, value, prevState, nextState, rowIndex, field) => {
      dispatch(setOpenStatus(isOpen, id, stateName, value, prevState, nextState, rowIndex, field));
    },
    openPCADialog: (isOpen) => {
      dispatch(setOpenPCA(isOpen, true));
    },
    openAlertType: (value) => {
      dispatch(setAlertType(value));
    },
    updateSampleState: (sampleId, valueObject) => {
      dispatch(mobileSamplesActions.updateSampleState(sampleId, valueObject, (isSuccess) => {
        const message = isSuccess ? 'Changes saved' : 'Failed to save update';
        const level = isSuccess ? 'success' : 'error'
        ownProps.notify(message, level);
      }));
    },
  };
};

const CSelectCell = connect(mapStateToProps, mapDispatchToProps)(SelectCell);

export default CSelectCell;
