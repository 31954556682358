import React from 'react';
import PropTypes from 'prop-types';

import I18n from "redux-i18n";
import { translations } from '../translations/translations';
export default function App(props) {

  return (
    <I18n translations={translations}>
      <div className="page-container">
        {props.children}
      </div>
    </I18n>
  );
}

App.propTypes = {
  children: PropTypes.element.isRequired
};
