import React from 'react';
import PropTypes from 'prop-types';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { browserHistory } from 'react-router';

import { ENV, ENVIRONMENTS } from '../../constants/EndPoints';

import { LangSelect, UnitSelect, TimezoneSelect } from '../../components';
const misc = require('../../constants/Misc');

require('./UserBlock.scss');

export default function UserBlock(props, context) {
  const { user, local, downloadFile } = props;

  const [aboutOpen, setAboutOpen] = React.useState(false);

  return (
    <div className="userblock">
      <span>{context.t("Hello {userName}", { userName: user.name })}</span>
      { (ENV !== ENVIRONMENTS.SINGAPORE) && <span><LangSelect /></span>}
      <span><UnitSelect /></span>
      <span>
        <TimezoneSelect
          user={user}
          local={local}
        />
      </span>
      <span><a onClick={() => { downloadFile(misc.system.userGuide, 'manual'); }} >{context.t('Help')}</a></span>
      <span><a onClick={() => { setAboutOpen(true) }} >{context.t('About')}</a></span>
      { user.isAQS &&
        <span><a onClick={() => {
          browserHistory.push({
            pathname: 'dashboard',
          })}
        }>{context.t('Dashboard')}</a></span>
      }
      <span><a onClick={props.signOutFunc}>{context.t('Logout')}</a></span>

      <Dialog open={aboutOpen} onClose={() => setAboutOpen(false)}>
        <DialogTitle><h1>Aquarius-Spectrum</h1></DialogTitle>
        <DialogContent style={{textAlign: 'center'}}>
          Copyright © 2021 Aquarius Spectrum<br/><br/>
          All rights reserved.<br/><br/>
          Aquarius Spectrum<br/>
          1 HaMahshev, Netanya 4250711, Israel<br/>
          Phone: +972-74-7136644<br/>
          Fax: +972-4-6709014<br/><br/>
          <a href='./certificates/notices.pdf' target='_blank'>{context.t('Licneces')}</a>
        </DialogContent>
      </Dialog>
    </div>
  );
}

UserBlock.contextTypes = {
  t: PropTypes.func.isRequired
};
