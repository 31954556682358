import React from 'react';
import PropTypes from 'prop-types';

String.prototype.splice = function(idx, rem, str) {
  return this.slice(0, idx) + str + this.slice(idx + Math.abs(rem));
};

export default function InputField(props) {
  // const fakeName = props.name.splice((props.name.length/2).toFixed(), 6, '$zwnj;');
  return (
    <div className='login-input-field'>
      <label htmlFor={props.name}>{props.label}</label>
      {/* <input id={fakeName} name={fakeName} style={{display: 'none'}}/> */}
      <input
        id={props.name}
        name={props.name}
        type={props.type}
        disabled={props.disabled}
        value={props.value}
        onChange={props.onChange} />
      { props.error &&
        <p>{props.error}</p>
      }
    </div>
  );
}

InputField.propTypes = {
  disabled: PropTypes.bool.isRequired,
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired,
};
