import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { SortableHeaderCell, TextCell, AssessmentsTable, TableMixin } from '../../components';
import CSelectCell from '../../containers/CSelectCell/CSelectCell';
import CTableActions from '../../containers/CTableActions/CTableActions';
import CCouplesSettings from '../../containers/CCouplesSettings/CCouplesSettings';
import { DateType } from '../../containers/UIhelper/UIDateFormater';
const classNames = require('classnames');

require('./Pagination.scss');

class CoupleTable extends React.Component {

  constructor(props) {
    super(props);

    this.handleCoupleRowClick = this.handleCoupleRowClick.bind(this);
    this.handleSensorCellClick = this.handleSensorCellClick.bind(this);
    this.assessmentToggleClick = this.assessmentToggleClick.bind(this);
    this.couplingToggle = this.couplingToggle.bind(this);
    //this.renderSensorLink = this.renderSensorLink.bind(this);

    this.state = {
      clickOnSensorLink: false,
      showAssessment: false,
    }
  };

  componentDidMount() {
    const { selectedFeature, toggleDetailsVisibility } = this.props;
    const showDetails = (selectedFeature != null || selectedFeature != '');
    toggleDetailsVisibility(showDetails);

  }

  handleCoupleRowClick(event, rowIndex, obj) {
    // implement on setState callback to check the fresh state of 'clickOnSensorLink':
    this.setState({}, () => {
      if (this.state.clickOnSensorLink === false) {
        this.props.rowClickEvent(event, rowIndex);
      }
      this.setState({ clickOnSensorLink: false });
    });
  }

  handleSensorCellClick(sensorId) {
    const { rowClickEvent } = this.props;
    const selectedSensor = {
      type: 'sensor',
      id: sensorId,
    }
    this.setState({
      clickOnSensorLink: true,
    });
    this.props.rowClickEvent('sensorLink', selectedSensor, () => {
      // fail callback
      const message = this.context.t('Sensor is not found. check filters and try again');
      this.props.notify(message, 'info');
    });
  }

  assessmentToggleClick() {
    const { assessments, notificationSystem, t } = this.props;
    if (assessments != null && assessments.assessments.length > 0) {
      this.props.handleAssessmentButtonClick(true);
    } else {
      notificationSystem.addNotification({
        message: t('No alerts'),
        level: 'info',
        position: 'tc',
      });
    }
  }

  couplingToggle() {
    const isInPlanningMode = this.props.planningState.isActive;
    this.props.selectSample({});
    this.props.clearSelectedFeature();
    this.props.toggleDetailsVisibility(!isInPlanningMode);
    this.props.openNewCoupleForm(!isInPlanningMode);
  }

  render() {

    const {
      user,
      items,
      indexMap,
      selectedFeature,
      pHeight,
      pWidth,
      sortProp,
      localSortProp,
      goToRow,
      rowClassNameGetter,
      distanceFactor,
      timeZone,
      assessments,
      hasAssessments,
      displayAssessments,
      t
    } = this.props;

    //document.title = 'AQS • Couples';
    const scrollTo = (goToRow && goToRow > 0) ? goToRow : 1;
    const totalItemsStyle = {
      float: 'right',
      padding: '2px 8px',
    };
    //const emptyAssessments = (assessments == null || assessments.assessments == null || assessments.assessments.length == 0);
    const buttonDisabled = (assessments == null || assessments.isFetching == true);
    const assessmentsItems = assessments == null ? [] : assessments.assessments;

    const buttonClasses = classNames(
      'btn',
      'btn-assessment',
      'assessments',
      { 'success': !buttonDisabled && hasAssessments },
    );

    return (
      <div>
        <Table
          maxHeight={pHeight - 93}
          width={pWidth}
          rowsCount={items.length}
          rowHeight={30}
          headerHeight={30}
          rowClassNameGetter={rowClassNameGetter}
          onRowClick={this.handleCoupleRowClick}
          scrollToRow={scrollTo}
          {...this.props}
        >
          <Column columnKey="warningEnum" width={10} flexGrow={0.8}
            header={<SortableHeaderCell sortTable={localSortProp} />}
            cell={<TextCell data={items} field="warningEnum" path={this.props.path} />} />

          <Column columnKey="id" width={70} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="id" />} />

          <Column columnKey="PathLengthM" width={70} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="PathLengthM" distanceFactor={distanceFactor} />} />

          <Column columnKey="Material" width={70} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="Material" />} />

          <Column columnKey="Sensor1" width={70} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={(props) => (
              <a
                className='sensor-link-cell'
                onClick={() => this.handleSensorCellClick(items[props.rowIndex].Sensor1)}>
                {items[props.rowIndex].Sensor1}
              </a>
            )
          }/>

          <Column columnKey="Sensor2" width={70} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={(props) => (
              <a
                className='sensor-link-cell'
                onClick={() => this.handleSensorCellClick(items[props.rowIndex].Sensor2)}>
                {items[props.rowIndex].Sensor2}
              </a>
            )
          }/>

          <Column columnKey="LastDaemonActivity" width={95} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} />}
            cell={<TextCell data={items} field="LastDaemonActivity" dateType={DateType.DATE_AND_TIME} additional={timeZone} />} />

          <Column columnKey="Probability" width={50} flexGrow={1}
            header={<SortableHeaderCell sortTable={sortProp} title={this.context.t('Probability')} />}
            cell={<TextCell data={items} field="Probability"/>} />

          { user.analysesExpert &&
            <Column columnKey="probability_irrigation" width={50} flexGrow={1}
              header={<SortableHeaderCell sortTable={sortProp} title={this.context.t('Probability Consumption')} />}
              cell={<TextCell data={items} field="probability_irrigation" />} />
          }
        </Table>

        <button
          type="button"
          disabled={!user.installerPlanner}
          onClick={this.couplingToggle}
          className="btn btn-success btn-assessment create-item" >

          <i
            title={t('Create Couple')} />

        </button>

        {items && items.length > 0 &&
          <button
            type="button"
            onClick={this.assessmentToggleClick}
            className={buttonClasses}
            disabled={buttonDisabled || !hasAssessments} >

            <div
              title={t('Open Assessment')} />

          </button>
        }

        {items && items.length > 0 &&
          <CTableActions
            type="couples"
          />
        }
        {items && items.length > 0 &&
          <span style={totalItemsStyle}>{t('Selected: {selectedIndex}. Total: {total}', { selectedIndex: (indexMap[selectedFeature] + 1) || 0, total: items.length })}</span>
        }

      </div>
    );
  }
}

CoupleTable.contextTypes = {
  t: PropTypes.func.isRequired
};

CoupleTable = TableMixin(CoupleTable, CCouplesSettings);
export default CoupleTable;
