// import { getCenterOfBounds } from 'geolib';

import * as types from '../constants/ActionTypes';
import * as endPoints from '../constants/EndPoints';
import { featuresTypes } from "../components/Map/Layers/Options";

import { fetchMiddleware, setIndexes } from './MiddlewareActions';
import * as actionsGlobals from './GlobalsActions';
import * as actionsAlerts from './AlertsActions';
import { reject } from 'q';

export const MIN_PIPE_LENGTH_FOR_ANALYSIS_CALCULATION = 800;

const groupBy = require('json-groupby');
const geolib = require('geolib');


export const selectInspectAlert = (alert) => {
   return (dispatch, getState) => {
      const state = getState();
      const selectedProjectId = state.leaksList.selectedProject;
      const selectedAlertId = (alert == null) ? '' : alert.id;
      const selectedType = (alert == null) ? '' : featuresTypes.INSPECT_ALERTS;

      if (alert != null && alert.AlertState == 6) {
         // fetch PCA for Fixed alerts:
         dispatch(actionsAlerts.getPcaForAlert(selectedAlertId));
      }

      dispatch(actionsGlobals.selectFeature(selectedProjectId, selectedAlertId, selectedType));
   };
};

export const investigateAlerts = (projectId, datasetFormat, force = false) => {
   return (dispatch, getState) => {
     const state = getState();
     const materials = state.leaksList.optionList.options.Materials;

     let path = endPoints.ALERTS_ENDPOINT + '/investigate/' + projectId;

     if(datasetFormat == 'xls'){
       const { user } = state.user;
       user.getIdToken().then((token) => {
        path += "/xls?token=" + token;
        actionsGlobals.downloadFileFromURl(path);
      });
     }
     else{
        let inspectItemsProjectId = state.leaksList.inspect.projectId;

        if (force == false && inspectItemsProjectId == projectId && state.leaksList.inspect.alerts.data.length > 0) {
           console.log('no load needed for project: ' + projectId);
        } else {
          let alertStates = state.leaksList.optionList.options.AlertState;
          let filterState = alertStates.find((item) => item.label == 'Fixed');
          let filters = [{ field: 'AlertState', value: [filterState.value] }];
          path += '/json?filters='+JSON.stringify(filters);
          dispatch(requestAlertsInvestigation(projectId));

          return fetchMiddleware(path, {}, getState).then((json) => {
              if(json.status){

                dispatch(fetchSuspiciousAlerts(projectId, 'inspect'));

                let alertsDataSet = json.data;
                let indexMap = setIndexes(alertsDataSet, 'ID');

                setAlertsAdditionalData(state, alertsDataSet);

                const valuesSets = {
                    PlacementYear: getArrayOfAttribute(alertsDataSet, 'PlacementYear'),
                    Material: getArrayOfAttribute(alertsDataSet, 'Material'),
                    PipeDiameterInch: getArrayOfAttribute(alertsDataSet, 'PipeDiameterInch'),
                    MaterialDiameter: getArrayOfAttribute(alertsDataSet, 'MaterialDiameter'),
                    'Event Year': getArrayOfAttribute(alertsDataSet, 'Event Year'),
                    // MaterialDiameter: getMaterialDiameterSet(alertsDataSet, ['Material', 'PipeDiameterInch']),
                };

                dispatch(receiveAlertsInvestigation(projectId, alertsDataSet, indexMap, valuesSets));
                // dispatch(investigatePipes(projectId, datasetFormat));  // for auto fetch pipes data for pre-process.
                const updatedState = getState();
                const pipesData = updatedState.leaksList.inspect.pipes;
                if (pipesData.isFetching == false && pipesData.data.length > 0) {
                  const alertsData = updatedState.leaksList.inspect.alerts;
                  calculateAnalysis(alertsData, pipesData)
                  .then((data) => {
                    dispatch(saveAnalysisData(data));
                  });
                }
              }
              else{
              // @TODO: Implement error callback handling
              console.error("Bad response from the server");
              }
          });
        }
     }
   };
 };

const setAlertsAdditionalData = (state, alertsDataSet) => {
  const materials = state.leaksList.optionList.options.Materials;

  alertsDataSet.forEach((alert) => {
    alert.id = alert.ID; // we use 'id' and 'ID' in differents places...
    let DetectionDateTime = new Date(parseInt(alert['DetectionDateTime'])).getFullYear();

    Object.assign(alert, { 'Event Year': DetectionDateTime });

    if (alert['PipeMaterialCode'] != null) {
      // eslint-disable-next-line arrow-body-style
      var found = materials.find((item) => {
        return item.value == alert['PipeMaterialCode'];
      });
      alert['Material'] = found['label'];
    }
    if (alert['Material'] == null) {
      alert['Material'] = 'undefined';
    }

    if (alert.PlacementYear == null) {
      alert.PlacementYear = 'undefined';
    }
    if (alert.PipeDiameterInch == null) {
      alert.PipeDiameterInch = 'undefined';
    }

    // create merged key for 'Material' & 'Diameter':
    alert.MaterialDiameter = `${alert.Material} ${alert.PipeDiameterInch}`;
    alert.YearMaterialDiameter = `${alert.PlacementYear} ${alert.MaterialDiameter}`;
    alert.YearMaterial = `${alert.PlacementYear} ${alert.Material}`;
  });
};

const setPipesAdditionalDataAndGetCalcParams = (state, pipesDataSet) => {
  // set the nulls attrubutes with "undefuned"
  const materials = state.leaksList.optionList.options.Materials;
  const fields = ['PlacementYear', 'Material', 'PipeDiameterInch'];

  let sumValue = 0;
  let totalLength = 0;
  let maxValue = 0;

  pipesDataSet.forEach((section) => {
    sumValue += (section.CalcLeakProbPS * section.Length)
    totalLength += section.Length;
    if (section.CalcLeakProbPS > maxValue) {
        maxValue = section.CalcLeakProbPS;
    }

    fields.forEach((field) => {
      if (section[field] == null) {
        if (field != 'Material') {
          section[field] = 'undefined';
        }
      } else if (field == 'Material') {
        let found = materials.find((itrMaterial) => {
          return itrMaterial.value == section['Material'];
        });
        section['Material'] = found['label'];
      }
    });

    // create merged key for 'Material' & 'Diameter':
    section.MaterialDiameter = `${section.Material} ${section.PipeDiameterInch}`;
    section.YearMaterialDiameter = `${section.PlacementYear} ${section.MaterialDiameter}`;
    section.YearMaterial = `${section.PlacementYear} ${section.Material}`;
  });

  const avg = sumValue / totalLength;
  const intervalSize = maxValue / 10;
  const params = {
      avg: avg,
      topSection: maxValue * 0.6, // maxValue * 0.75, // / 2,
      secondSection: maxValue * 0.5, // maxValue * 0.6, // (maxValue / 2) - intervalSize,
      ThirdSection: maxValue * 0.4, // maxValue * 0.4 // (maxValue / 2) - (intervalSize * 2),
  }

  pipesDataSet.forEach((section) => {
    // if (section.CalcLeakProbPS > params.topSection) {
    //   section.PCAScore = "High";
    // } else if (section.CalcLeakProbPS > params.secondSection) {
    //   section.PCAScore = "Med";
    // } else if (section.CalcLeakProbPS > params.ThirdSection) {
    //   section.PCAScore = "Low";
    // } else {
    //   section.PCAScore = "OK";
    // }
    if (section.CalcLeakProbPS > params.topSection) {
      section.PCAScore = "High";
    } else if (section.CalcLeakProbPS > params.ThirdSection) {
      section.PCAScore = "Med";
    } else {
      section.PCAScore = "OK";
    }
  });

  return (params);
};

const calculateAnalysis = (alertsData, pipesData) => {
  const promise = new Promise((resolve, _reject) => {
    const alertsList = alertsData.data;
    const pipesList = pipesData.data;

    const materialsByPlacementYearData = {
      fixedLeaks: {},
      infrastructure: {},
      leaksByInfrastructure: {},
    }

    const diameterByPlacementYearData = {
      fixedLeaks: {},
      infrastructure: {},
      leaksByInfrastructure: {},
    }

    const materialsDiameterByPlacementYearData = {
      fixedLeaks: {},
      infrastructure: {},
      leaksByInfrastructure: {},
    }

    const pcaScoreData = {};

    alertsList.forEach((alert) => {
      if (alert.PlacementYear > 1 && alert.PlacementYear != null) {
        // initiate map for each category (if needed):
        if (materialsByPlacementYearData.fixedLeaks[alert.Material] == null) {
          materialsByPlacementYearData.fixedLeaks[alert.Material] = {};
        }
        if (diameterByPlacementYearData.fixedLeaks[alert.PipeDiameterInch] == null) {
          diameterByPlacementYearData.fixedLeaks[alert.PipeDiameterInch] = {};
        }
        if (materialsDiameterByPlacementYearData.fixedLeaks[alert.MaterialDiameter] == null) {
          materialsDiameterByPlacementYearData.fixedLeaks[alert.MaterialDiameter] = {};
        }

        // count material per placementYaer alerts:
        if (materialsByPlacementYearData.fixedLeaks[alert.Material][alert.PlacementYear] == null) {
          materialsByPlacementYearData.fixedLeaks[alert.Material][alert.PlacementYear] = 1;
        } else {
          materialsByPlacementYearData.fixedLeaks[alert.Material][alert.PlacementYear] += 1;
        }
        // count diameter per placementYaer alerts:
        if (diameterByPlacementYearData.fixedLeaks[alert.PipeDiameterInch][alert.PlacementYear] == null) {
          diameterByPlacementYearData.fixedLeaks[alert.PipeDiameterInch][alert.PlacementYear] = 1;
        } else {
          diameterByPlacementYearData.fixedLeaks[alert.PipeDiameterInch][alert.PlacementYear] += 1;
        }
        // count material & diameter per placementYaer alerts:
        if (materialsDiameterByPlacementYearData.fixedLeaks[alert.MaterialDiameter][alert.PlacementYear] == null) {
          materialsDiameterByPlacementYearData.fixedLeaks[alert.MaterialDiameter][alert.PlacementYear] = 1;
        } else {
          materialsDiameterByPlacementYearData.fixedLeaks[alert.MaterialDiameter][alert.PlacementYear] += 1;
        }
      }
    });

    pipesList.forEach((pipe) => {
      if (pipe.PlacementYear > 1 && pipe.PlacementYear != null) {
        // initiate map for each category (if needed):
        if (materialsByPlacementYearData.infrastructure[pipe.Material] == null) {
          materialsByPlacementYearData.infrastructure[pipe.Material] = {};
        }
        if (diameterByPlacementYearData.infrastructure[pipe.PipeDiameterInch] == null) {
          diameterByPlacementYearData.infrastructure[pipe.PipeDiameterInch] = {};
        }
        if (materialsDiameterByPlacementYearData.infrastructure[pipe.MaterialDiameter] == null) {
          materialsDiameterByPlacementYearData.infrastructure[pipe.MaterialDiameter] = {};
        }

        // count material per placementYaer pipes:
        if (materialsByPlacementYearData.infrastructure[pipe.Material][pipe.PlacementYear] == null) {
          materialsByPlacementYearData.infrastructure[pipe.Material][pipe.PlacementYear] = pipe.Length;
        } else {
          materialsByPlacementYearData.infrastructure[pipe.Material][pipe.PlacementYear] += pipe.Length;
        }
        // count diameter per placementYaer pipes:
        if (diameterByPlacementYearData.infrastructure[pipe.PipeDiameterInch][pipe.PlacementYear] == null) {
          diameterByPlacementYearData.infrastructure[pipe.PipeDiameterInch][pipe.PlacementYear] = pipe.Length;
        } else {
          diameterByPlacementYearData.infrastructure[pipe.PipeDiameterInch][pipe.PlacementYear] += pipe.Length;
        }
        // count material & diameter per placementYaer pipes:
        if (materialsDiameterByPlacementYearData.infrastructure[pipe.MaterialDiameter][pipe.PlacementYear] == null) {
          materialsDiameterByPlacementYearData.infrastructure[pipe.MaterialDiameter][pipe.PlacementYear] = pipe.Length;
        } else {
          materialsDiameterByPlacementYearData.infrastructure[pipe.MaterialDiameter][pipe.PlacementYear] += pipe.Length;
        }

        if (pcaScoreData[pipe.PCAScore] == null) {
          pcaScoreData[pipe.PCAScore] = 0;
        }

        pcaScoreData[pipe.PCAScore] += pipe.Length;
      }
    });

    Object.entries(materialsByPlacementYearData.infrastructure).forEach((material_data) => {
      const itrMaterial = material_data[0];
      Object.entries(material_data[1]).forEach((year_pipeLength) => {
        const subItrYear = year_pipeLength[0];
        if (materialsByPlacementYearData.fixedLeaks[itrMaterial] != null && materialsByPlacementYearData.fixedLeaks[itrMaterial][subItrYear] != null) {
          if (materialsByPlacementYearData.leaksByInfrastructure[itrMaterial] == null) {
            materialsByPlacementYearData.leaksByInfrastructure[itrMaterial] = {};
          }

          const pipeLengthByKm = parseFloat(Math.max(year_pipeLength[1], MIN_PIPE_LENGTH_FOR_ANALYSIS_CALCULATION) / 1000); // not low than MINIMUM meters
          const alertsCount = parseFloat(materialsByPlacementYearData.fixedLeaks[itrMaterial][subItrYear]);
          materialsByPlacementYearData.leaksByInfrastructure[itrMaterial][subItrYear] = alertsCount / pipeLengthByKm;
        }
      });
    });

    Object.entries(diameterByPlacementYearData.infrastructure).forEach((diameter_data) => {
      const itrDiameter = diameter_data[0];
      Object.entries(diameter_data[1]).forEach((year_pipeLength) => {
        const subItrYear = year_pipeLength[0];
        if (diameterByPlacementYearData.fixedLeaks[itrDiameter] != null && diameterByPlacementYearData.fixedLeaks[itrDiameter][subItrYear] != null) {
          if (diameterByPlacementYearData.leaksByInfrastructure[itrDiameter] == null) {
            diameterByPlacementYearData.leaksByInfrastructure[itrDiameter] = {};
          }

          const pipeLengthByKm = parseFloat(Math.max(year_pipeLength[1], MIN_PIPE_LENGTH_FOR_ANALYSIS_CALCULATION) / 1000); // not low than MINIMUM meters
          const alertsCount = parseFloat(diameterByPlacementYearData.fixedLeaks[itrDiameter][subItrYear]);
          diameterByPlacementYearData.leaksByInfrastructure[itrDiameter][subItrYear] = alertsCount / pipeLengthByKm;
        }
      });
    });

    Object.entries(materialsDiameterByPlacementYearData.infrastructure).forEach((materialDiameter_data) => {
      const itrMaterialDiameter = materialDiameter_data[0];
      Object.entries(materialDiameter_data[1]).forEach((materialDiameter_pipeLength) => {
        const subItrYear = materialDiameter_pipeLength[0];
        if (materialsDiameterByPlacementYearData.fixedLeaks[itrMaterialDiameter] != null && materialsDiameterByPlacementYearData.fixedLeaks[itrMaterialDiameter][subItrYear] != null) {
          if (materialsDiameterByPlacementYearData.leaksByInfrastructure[itrMaterialDiameter] == null) {
            materialsDiameterByPlacementYearData.leaksByInfrastructure[itrMaterialDiameter] = {};
          }

          const pipeLengthByKm = parseFloat(Math.max(materialDiameter_pipeLength[1], MIN_PIPE_LENGTH_FOR_ANALYSIS_CALCULATION) / 1000); // not low than MINIMUM meters
          const alertsCount = parseFloat(materialsDiameterByPlacementYearData.fixedLeaks[itrMaterialDiameter][subItrYear]);
          materialsDiameterByPlacementYearData.leaksByInfrastructure[itrMaterialDiameter][subItrYear] = alertsCount / pipeLengthByKm;
        }
      });
    });

    resolve({materialsByPlacementYearData, diameterByPlacementYearData, materialsDiameterByPlacementYearData, pcaScoreData});
  });

  return promise;
};

const getArrayOfAttribute = (data, attributeName) => {
   const attrGroup = groupBy(data, [attributeName]);
   return Object.keys(attrGroup);
};

// const getMaterialDiameterSet = (data, attributes) => {
//    const attrGroup = groupBy(data, attributes);
//    const dataSet = [];

//    Object.keys(attrGroup).forEach((materialName) => {
//       const materialDiameters = attrGroup[materialName];
//       Object.keys(materialDiameters).forEach((diameter) => {
//          const key = `${materialName} ${diameter}`;
//          dataSet.push({
//             [key]: materialDiameters[diameter]
//          });
//       });
//    });

//    console.table(dataSet);
//    return (dataSet);
// };

export function fetchSuspiciousAlerts(project, mode) {
  var path = endPoints.ALERTS_ENDPOINT + "/suspicious/" + project;

  return (dispatch, getState) => {
    //@TODO: Check errors.
    return fetchMiddleware(path, {}, getState)
      .then((json) => {
        dispatch(receiveSuspiciousAlertsIds(project, mode, json.data));
      });
  }
}

const receiveSuspiciousAlertsIds = (project, mode, alertsIds = []) => {
  return {
    type: types.RECEIVE_SUSPICIOUS_ALERTS_IDS,
    project,
    mode,
    alertsIds
  }
}

 export const requestAlertsInvestigation = (projectId) => {
   return {
     type: types.REQUEST_ALERTS_INVESTIGATION,
     projectId
   };

 };

 export const receiveAlertsInvestigation = (projectId, data, indexMap, valuesSets) => {
   return {
      type: types.RECEIVE_ALERTS_INVESTIGATION,
      projectId,
      data,
      indexMap,
      valuesSets
   };
 };

export const setVisibleAlerts = (filters) => {
   return (dispatch, getState) => {
      if (filters != null) {
         const state = getState();
         const inspectAlerts = state.leaksList.inspect.alerts.data;
         let filteredAlerts = inspectAlerts.filter((alert) => {
            let bIsSelected = true;

            Object.keys(filters).forEach((filterName) => {
              if (bIsSelected) {
                if (filterName === 'Category') {
                  const category = filters[filterName]
                  const pipeCalcParams = state.leaksList.inspect.pipeCalcProbParams;
                  switch (category) {
                    case 'High':
                      bIsSelected = (alert.CalcLeakProbPS > pipeCalcParams.topSection)
                      break;
                    case 'Med':
                      bIsSelected = (alert.CalcLeakProbPS > pipeCalcParams.secondSection && alert.CalcLeakProbPS <= pipeCalcParams.topSection)
                      break;
                    case 'Low':
                      bIsSelected = (alert.CalcLeakProbPS > pipeCalcParams.ThirdSection && alert.CalcLeakProbPS <= pipeCalcParams.secondSection)
                      break;
                    default:
                      bIsSelected = (alert.CalcLeakProbPS <= pipeCalcParams.ThirdSection)
                      break;
                  }
                } else {
                  if (filters[filterName] != null && alert[filterName] != filters[filterName]) {
                      bIsSelected = false;
                  }
                }
              }
            });

            return bIsSelected;
         });

         let alertsCoordinates = filteredAlerts.map((alert) => {
            return {
               latitude: alert.Latitude || 0,
               longitude: alert.Longitude || 0
            }
         });
         let centerPosition = null;
         if (alertsCoordinates != null && alertsCoordinates.length > 0) {
          centerPosition = geolib.getCenterOfBounds(alertsCoordinates);
         }

         // clear selected if exist:
         dispatch(selectInspectAlert(null));

         dispatch(setSelectedAlerts(filteredAlerts, centerPosition));
      }
   };
};

const setSelectedAlerts = (alerts, centerPosition) => {
   return {
      type: types.INSPECT_SELECT_FILTERED_ALERTS,
      alerts,
      center: centerPosition
   };
};

export const investigatePipes = (projectId, datasetFormat, force = false) => {
   return (dispatch, getState) => {
     const state = getState();

     let path = endPoints.PIPE_ASSESSMENTS_ENDPOINT + '/export/' + projectId;

     if (datasetFormat == 'xls') {
      const { user } = state.user;
      user.getIdToken().then((token) => {
        path += "/xls?token=" + token;
        actionsGlobals.downloadFileFromURl(path);
      });
     } else {
      let inspectItemsProjectId = state.leaksList.inspect.projectId;
      if (force == false && inspectItemsProjectId == projectId && state.leaksList.inspect.alerts.data.length > 0) {
         console.log('no pipes data load needed for project: ' + projectId);
      } else {
         path += '/json';
         dispatch(requestPipesInvestigation(projectId));
         return fetchMiddleware(path, {}, getState).then((json) => {
            if(!json.status){
               // HANDLE ERROR:
            } else {
              const pipesDataSet = json.data;

               const pipeCalcProbParams = setPipesAdditionalDataAndGetCalcParams(state, pipesDataSet);

               const valuesSets = {
                  PlacementYear: getArrayOfAttribute(pipesDataSet, 'PlacementYear'),
                  Material: getArrayOfAttribute(pipesDataSet, 'Material'),
                  PipeDiameterInch: getArrayOfAttribute(pipesDataSet, 'PipeDiameterInch'),
                  MaterialDiameter: getArrayOfAttribute(pipesDataSet, 'MaterialDiameter'),
               };

               dispatch(receivePipesInvestigation(projectId, pipesDataSet, valuesSets, pipeCalcProbParams));

               const updatedState = getState();
               const alertsData = updatedState.leaksList.inspect.alerts;
               if (alertsData.isFetching == false && alertsData.data.length > 0) {
                const pipesData = updatedState.leaksList.inspect.pipes;
                calculateAnalysis(alertsData, pipesData)
                .then((data) => {
                  dispatch(saveAnalysisData(data));
                  dispatch(setSelectedGrop('High'));
                });
              }
            }
         });
      }
     }
   };
 };

 export const setSelectedGrop = (category) => {
   return (dispatch, getState) => {

    const GROUP_FIELD = 'YearMaterialDiameter';

    const state = getState();
    const calcProbParams = state.leaksList.inspect.pipeCalcProbParams;
    const alertsData = state.leaksList.inspect.alerts.data;
    const pipesData = state.leaksList.inspect.pipes.data;

    const categoryFilterLambda = (item) => {
      let filterResult = false;
      if (item.PlacementYear != null && item.PlacementYear != "undefined") {
        switch (category) {
          case 'High':
            filterResult = (item.CalcLeakProbPS > calcProbParams.topSection);
            break;
          case 'Med':
            filterResult = (item.CalcLeakProbPS > calcProbParams.secondSection && item.CalcLeakProbPS <= calcProbParams.topSection);
            break;
          case 'Low':
            filterResult = (item.CalcLeakProbPS > calcProbParams.ThirdSection && item.CalcLeakProbPS <= calcProbParams.secondSection);
            break;
          default:
            filterResult = (item.CalcLeakProbPS <= calcProbParams.ThirdSection);
            break;
        }
      }
      return filterResult;
    }

    const alerts = alertsData.filter(categoryFilterLambda);

    const groupAlertsMap = {};
    alerts.forEach((alert) => {
      if (groupAlertsMap[alert[GROUP_FIELD]] == null) {
        groupAlertsMap[alert[GROUP_FIELD]] = {
          // initial params:
          alertsCount: 1,
          PipeLength: 0,
          // constatnts params:
          PlacementYear: alert.PlacementYear,
          Material: alert.Material,
          PipeDiameterInch: alert.PipeDiameterInch,
        };
      } else {
        groupAlertsMap[alert[GROUP_FIELD]].alertsCount += 1;
      }
    });

    pipesData.filter(categoryFilterLambda).forEach((pipe) => {
      if (groupAlertsMap.hasOwnProperty(pipe[GROUP_FIELD])) {
        groupAlertsMap[pipe[GROUP_FIELD]].PipeLength += pipe.Length;
      }
    });

    const groupAlertsList = Object.keys(groupAlertsMap)
    .filter((itemKey) => groupAlertsMap[itemKey].alertsCount > 1) // remove items with less than 2 Leaks
    .map((itemKey) => {
      const itrItem = groupAlertsMap[itemKey];
      return {
        key: itemKey,
        // pipeLengthByKm: itrItem.alertsCount / parseFloat(Math.max(itrItem.PipeLength, MIN_PIPE_LENGTH_FOR_ANALYSIS_CALCULATION) / 1000), // not low than MINIMUM meters,
        ...itrItem,
      }
    });

    dispatch(setAlertsDataForCategory(category, groupAlertsList));
   };
 };

 const setAlertsDataForCategory = (category, data) => {
  return {
    type: types.SET_ALERTS_DATA_FOR_CATEGORY,
    category,
    data
  }
 }

 export const requestPipesInvestigation = (projectId) => {
   return {
     type: types.REQUEST_PIPES_INVESTIGATION,
     projectId
   };
 };

 export const receivePipesInvestigation = (projectId, data, valuesSets, pipeCalcProbParams) => {
     return {
     type: types.RECEIVE_PIPES_INVESTIGATION,
     projectId,
     data,
     valuesSets,
     pipeCalcProbParams
   };
 };

 const saveAnalysisData = (data) => {
  return {
    type: types.SAVE_INVESTIGATION_ANALYSIS_DATA,
    data
  }
 };

 export const sortAlertsLocaly = (alerts, indexMap) => {
   return {
     type: types.SORT_INVESTIGATION_ALERTS_LOCALY,
     alerts,
     indexMap
   };
 }
