import React from 'react';
import PropTypes from 'prop-types';


import { SOPS_TYPES } from "../../../constants/Misc";

const types = [
    'undefined',
    'hydrant',
    'valve',
    'gate_valve',
    'control_valve',
    'service_valve',
    'manhole',
    'fitting'
];

class SopTypesLegend extends React.Component {

    render() {
        return (
            <div>
                <table>
                    <thead>
                        <th>
                        </th>
                        <th>
                            {this.context.t('Overground')}
                        </th>
                        <th>
                            {this.context.t('Underground')}
                        </th>

                    </thead>
                    <tbody>
                        {
                            types.map(value => {
                                const className = 'sop marker-' + value;

                                return (
                                    <tr className="table-row">
                                        <th>{value}</th>
                                        <td className={'sop marker-' + value}></td>
                                        <td className={'sop underground ' + value}></td>
                                    </tr>
                                );
                            })
                        }

                        <tr className="table-row">
                            <th>{this.context.t('Good status')}</th>
                            <td className='sop good-background'></td>
                        </tr>
                        <tr className="table-row">
                            <th>{this.context.t('Bad status')}</th>
                            <td className='sop bad-background'></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

SopTypesLegend.contextTypes = {
    t: PropTypes.func.isRequired
}

export default SopTypesLegend;
