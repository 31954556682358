import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { Input } from "../../components";


require('./style.scss');
export default class SensorDetailsView extends React.Component {

  render() {
    const { index, id, address, fieldName, addressFieldName } = this.props;
    let src;
    let addressClassName;

    if (index == 1) {
      src = require('../../../images/map/sensors/couple0.png');
      addressClassName = 'first';
    } else {
      src = require('../../../images/map/sensors/couple1.png');
      addressClassName = 'second';
    }

    return (
      <div className="sensor-details-container">
        <div className="sensor-details">
          <img
            className="center"
            src={src} />
          <Field
            disabled={true}
            name={fieldName}
            component={Input}/>
          <Field
            name={addressFieldName}
            className={addressClassName}
            component={Input}
            />
        </div>
      </div>
    );
  }
}
