import React from 'react';
import PropTypes from 'prop-types';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { TextCell } from '../../components';
import { DateType } from '../../containers/UIhelper/UIDateFormater';

const G5NoiseMessages = (props, context) => {
  const { data, timeZone, uiInfo: { pageHeight, pageWidth } } = props;

  let pWidth = 0.395 * (pageWidth - 10);
  let pHeight = 0.494 * pageHeight;

  function rowClassNameGetter(rowIndex) {
    const { selectedMessage, data } = props;
    return (selectedMessage && data[rowIndex] && selectedMessage.ID == data[rowIndex].ID) ? 'active-row' : '';
  }

  function handleRowClick(_event, rowIndex) {
    const selectedItem = data[rowIndex];
    props.selectMessageFunc(selectedItem);
  }

  return (
    <Table
      rowsCount={data ? data.length : 0}
      width={pWidth}
      onRowClick={handleRowClick}
      rowClassNameGetter={rowClassNameGetter}
      maxHeight={pHeight - 49}
      headerHeight={46}
      rowHeight={30}
    >

      <Column
        columnKey="TimeStamp"
        header={<Cell>{context.t('Date')}</Cell>}
        cell={<TextCell data={data} field="TimeStamp" additional={timeZone} dateType={DateType.DATE_AND_TIME} />}
        width={80}
        flexGrow={2}
      />
      <Column
        columnKey="TotalPowerAvg"
        header={<Cell>{context.t('TP Avg')}</Cell>}
        cell={<TextCell data={data} field="TotalPowerAvg" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="TotalPowerRMS"
        header={<Cell>{context.t('TP RMS')}</Cell>}
        cell={<TextCell data={data} field="TotalPowerRMS" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="stdAGC"
        header={<Cell>{context.t('STD AGC')}</Cell>}
        cell={<TextCell data={data} field="stdAGC" />}
        width={20}
        flexGrow={1}
      />
      <Column
        columnKey="NoiseIntensityGain"
        header={<Cell>{context.t('Gain')}</Cell>}
        cell={<TextCell data={data} field="NoiseIntensityGain" />}
        width={30}
        flexGrow={1}
      />
      <Column
        columnKey="TotalPowerRMS_Last"
        header={<Cell>{context.t('TP RMS 2')}</Cell>}
        cell={<TextCell data={data} field="TotalPowerRMS_Last" />}
        width={40}
        flexGrow={1}
      />
      <Column
        columnKey="stdAGC_Last"
        header={<Cell>{context.t('STD AGC 2')}</Cell>}
        cell={<TextCell data={data} field="stdAGC_Last" />}
        width={20}
        flexGrow={1}
      />
      <Column
        columnKey="IntensityGain_Last"
        header={<Cell>{context.t('Gain 2')}</Cell>}
        cell={<TextCell data={data} field="IntensityGain_Last" />}
        width={30}
        flexGrow={1}
      />

    </Table>
  )
}

G5NoiseMessages.contextTypes = {
  t: PropTypes.func.isRequired
}

export default G5NoiseMessages;
