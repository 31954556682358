import React from 'react';
import { Table, Column, Cell } from 'fixed-data-table-2';

class LeaksDataTable extends React.Component {

  cellScoreSelectedGroupData = (row) => {
    const { data } = this.props;
    const { factor, units } = this.props.measurmentsDetails;

    let cellValue;
    let className = 'table-cell-class';
    switch (row.columnKey) {
      case 'LeakPerKm': {
        const count = data[row.rowIndex].alertsCount;
        const pipeLength = data[row.rowIndex].PipeLength;
        cellValue = (count / (pipeLength / 1000)).toFixed(1);
        className += ' number';
        break;
      }
      case 'PipeLength': {
        const bigUnitObj = factor.bigUnitValue[units];
        cellValue = (data[row.rowIndex].PipeLength * factor[units]).toFixed();

         let unit;
         let val;
         if (cellValue < bigUnitObj.value) {
            unit = units;
            val = cellValue;
         } else {
            unit = bigUnitObj.label;
            val = (Number(cellValue) / bigUnitObj.value).toFixed(1);
         }
         cellValue = `${val} ${unit}`;
         className += ' number';
        break;
      }
      default: {
        cellValue = data[row.rowIndex][row.columnKey]
        break;
      }
    }

    return <Cell className={className}>{cellValue}</Cell>
  }

  onTableRowClick = (event, rowIndex) => {
    const { data, category, onSelectGroup } = this.props;

    const clickedRow = data[rowIndex];

    const filter = {
       'YearMaterialDiameter': clickedRow.key,
       'Category': category
    };

    onSelectGroup(filter);
  }

  render() {
    const { data, title, isFetching = false, renderLoading, context, factor, units } = this.props;
    return (
    <div style={{ margin: '8px'}}>
      <p style={{ color: 'white', margin: '0 0 10px' }}><strong>{title}</strong></p>
      {
        isFetching && renderLoading()
      }
      { isFetching == false &&
        <Table
          height={330}
          width={930 / 2}
          rowsCount={data.length}
          rowHeight={40}
          headerHeight={35}
          onRowClick={this.onTableRowClick}
        >
          <Column columnKey="PlacementYear" width={30}
              header={<Cell className="table-cell-class">{context('Placement Year')}</Cell>}
              cell={this.cellScoreSelectedGroupData}
              align='center'
              flexGrow={1} />
          <Column columnKey="Material" width={30}
              header={<Cell className="table-cell-class">{context('Material')}</Cell>}
              cell={this.cellScoreSelectedGroupData}
              align='center'
              flexGrow={1} />
          <Column columnKey="PipeDiameterInch" width={30}
              header={<Cell className="table-cell-class">{context('Pipe Diameter')}</Cell>}
              cell={this.cellScoreSelectedGroupData}
              align='center'
              flexGrow={1} />
          <Column columnKey="PipeLength" width={30}
              header={<Cell className="table-cell-class">{context('Pipe Length')}</Cell>}
              cell={this.cellScoreSelectedGroupData}
              align='center'
              flexGrow={1} />
          <Column columnKey="alertsCount" width={30}
              header={<Cell className="table-cell-class">{context('Leaks')}</Cell>}
              cell={this.cellScoreSelectedGroupData}
              align='center'
              flexGrow={1} />
          {/* <Column columnKey="pipeLengthByBigUnit" width={30}
              header={<Cell className="table-cell-class">{context('Leak per {unit}', {unit: factor.bigUnitValue[units].label})}</Cell>}
              cell={this.cellScoreSelectedGroupData}
              align='center'
              flexGrow={1} /> */}
        </Table>
      }
    </div>
    );
  }
}
export default LeaksDataTable;
