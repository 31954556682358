import React from 'react';
import PropTypes from 'prop-types';

import { Select } from '../../components';

import timezones from '../../constants/timezones';

const _ = require('lodash');
export default class FieldTimezonePicker extends React.Component {

   static get propTypes() {
      return {
        input: PropTypes.object,
      };
   }

   constructor(props) {
      super(props);

      this.handleTimezoneChange = this.handleTimezoneChange.bind(this);

      this.state = {
         tzOptions: [],
      };
   }

   componentDidMount() {
      const ensure2Digits = num => (num > 9 ? `${num}` : `0${num}`);

      let tzOptions = [];
      timezones.forEach((tz, index) => {
         const str = `(GMT${tz.offset < 0 ? '-' : '+'}${ensure2Digits(Math.floor(Math.abs(tz.offset)))}:${ensure2Digits(Math.abs((tz.offset % 1) * 60))}) ${tz.label}`;

         tzOptions.push({
            label: str,
            value: index
         });
      });
      this.setState({ tzOptions });
   }

   handleTimezoneChange(option) {
      const { input: { onChange } } = this.props;
      const selectedTimezone = timezones[option.value];
      
      onChange(selectedTimezone);
   }

  render() {
   const { input } = this.props;
   const tzValue = (input.value != null && !_.isEmpty(input.value)) ? input.value.name : 'Asia/Yerevan';

   return (
      <Select {...this.props}
         options={this.state.tzOptions}
         onChangeHandler={this.handleTimezoneChange}
      />
   );
  }
}

FieldTimezonePicker.contextTypes = {
  t: PropTypes.func.isRequired
};
