import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {  Input  } from '../../components';
const classNames = require('classnames');

class AddTask extends React.Component {

  static get propTypes() {
    return {
      user: PropTypes.object,
      getAddressByLatLong: PropTypes.func,
      isOpen: PropTypes.bool,
      doClose: PropTypes.func,
      options: PropTypes.object,
      onCreateNewValue: PropTypes.func,
      couplesIdsData: PropTypes.array,
      submitting: PropTypes.bool,
      handleSubmit: PropTypes.func,
      error: PropTypes.string,
    };
  }

  render() {
    const { isOpen, doClose, user, options, onCreateNewValue, couplesIdsData, submitting, handleSubmit, getAddressByLatLong } = this.props;

    const panelClasses = classNames(
        'add-alert-form',
        { 'show': isOpen }
    );
    const formClass = 'form-group auto-center';
    const labelsClass = 'col-xs-3 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    const divStyle = { float: 'left', width: '100%' };
    return (
        <div className={panelClasses}>
          <div className="new-alert-header">
            <div className="actions">
              <i className="icon-close" onClick={doClose} />
            </div>
            <h2>{this.context.t('Add New Task')}</h2>
          </div>
          <form
              className="form-horizontal sticky-actions"
              onSubmit={handleSubmit}>
            <div style={{ display: 'flex' }}>
              <div style={divStyle}>
                {/* Task Name */}
                <div className={formClass}>
                  <label className={labelsClass}>{this.context.t('Task Name')}:</label>
                  <div className={fieldsClass}>
                    <Field name="TaskName"
                           component={Input}
                           type="text"
                           className="form-control"
                          />
                  </div>
                </div>
              </div>
            </div>
            <div className="form-actions text-right">
              <a
                  className="btn btn-info"
                  onClick={doClose}>{this.context.t('Cancel')}</a>
              <button
                  className="btn btn-success"
                  type="submit"
                  disabled={submitting}>{this.context.t('Save')}</button>
            </div>
          </form>
        </div>
    );
  }
}

// validators:
const validate = (values) => {
  const errors = {};

  // check Required fields:
  const requiredFields = ['TaskName'];
  requiredFields.forEach((fieldKey) => {
    if (values[fieldKey] == null || values[fieldKey] === '') {
      errors[fieldKey] = 'Required';
    }
  });

  return (errors);
};

AddTask.contextTypes = {
  t: PropTypes.func.isRequired
};

const addTask = reduxForm({
  form: 'add-mobile-task', // a unique name for this form
  enableReinitialize: true,
  validate,
})(AddTask);

export default addTask;
