import React from 'react';
import PropTypes from 'prop-types';

import { Field, reduxForm } from 'redux-form';
import { Select, Input } from '../../components';

import CanvasDraw from "react-canvas-draw";

class WorkOrderForm extends React.Component {

  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event) {
    debugger;
  }

  render() {

    const { initialValues, distanceFactor, units } = this.props || {}; // Prevent 'undefined' errors.

    return (
      <form className="form-horizontal form-work-order" onSubmit={this.handleSubmit}>
        <div className="helfWidth">
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Service Provider')}:</label>
            <div className="col-xs-8 control-input">
              <Field
                name="serviceProvider"
                component={props =>
                  <Select {...props}
                    placeholderTitle={ this.context.t('Select...') }
                    clearable={false}
                    backspaceRemoves={false}
                    multi={false}
                    options={initialValues.serviceProviderOptions}
                  />
                }
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Project')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.projectName || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Leak ID')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.id || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Alert Type')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.alertTypeName || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Alert Status')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.alertStateName || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Severity')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.Priority || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Intensity')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.Intensity || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Estimated Address')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.StreetAddress || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('X')+" , "+this.context.t('Y')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{(initialValues.X+' , '+initialValues.Y) || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Sensor1 Address')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.Sop1Address || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Sensor2 Address')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.Sop2Address || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Distance Between Sensors')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{((initialValues.PathLengthM * distanceFactor).toFixed() +' '+ units) || '-'}</span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-xs-4 control-label">{this.context.t('Distance From Sensor1')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{((initialValues.Distance * distanceFactor).toFixed() +' '+ units) || '-'}</span>
            </div>
          </div>

        </div>
        <div className="helfWidth">
          <div className="workOrderCanvas">
            <CanvasDraw
                canvasWidth={580}
                canvasHeight={416}
                // imgSrc={this.props.imgSrc || ''}
                lazyRadius={8}
                brushRadius={2}
                hideGrid
                immediateLoading
            />
          </div>

          <img src={this.props.imgSrc} className="workOrderImage" id="workOrderMap" />

          <br /><br />
          <div className="form-group rightSideGuidlinesInWorkOrder">
            <label className="col-xs-4 control-label">{this.context.t('Last Comment')}:</label>
            <div className="col-xs-8 control-input">
              <span className="control-readonly">{initialValues.Comment || '-'}</span>
            </div>
          </div>
          <div className="form-group rightSideGuidlinesInWorkOrder">
            <label className="col-xs-4 control-label">{this.context.t('Guidelines')}:</label>
            <div className="col-xs-8 control-input">
              <Field name="guidelines" component="textarea" className="form-control textAreaGuidline" />
            </div>
          </div>
        </div>
      </form>
    );
  }
}

WorkOrderForm.contextTypes = {
  t: PropTypes.func.isRequired
}

// Decorate the form component
WorkOrderForm = reduxForm({
  form: 'workorder' // a unique name for this form
})(WorkOrderForm);

export default WorkOrderForm;
