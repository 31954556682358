/* eslint-disable react/no-multi-comp */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Alert from "@mui/lab/Alert";
import Typography from '@mui/material/Typography';
import Slide from '@mui/material/Slide';

import { makeStyles } from '@mui/styles';

//.MuiAlert-message {

const useStyles = makeStyles({
  alertText: { margin: 'auto' },
  icon: { margin: 'auto 12px auto 0px' },
});

function TransitionLeft(props) {
  return <Slide {...props} direction="down" />;
}

export default function PopupAlert(props) {
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    if (props.popupKey && props.popupKey !== -1) {
      setOpen(true);
    }
  }, [props.popupKey]);

  // eslint-disable-next-line no-unused-vars
  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar
      key={props.popupKey}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      TransitionComponent={TransitionLeft}
    >
      <Alert
        severity={props.severity}
        onClose={handleClose}
        classes={{message: classes.alertText, icon: classes.icon}}
        action={
          props.action &&
          <Button color='inherit' size='large' onClick={() => {
            if (props.action.callback) {
              props.action.callback();
            }
            handleClose();
          }}>
            {props.action.label}
          </Button>
        }
      >

        <Typography variant="h4" align='center'>
            {props.message}
        </Typography>

        {/* <IconButton aria-label="close" onClick={handleClose}>
          <CloseIcon />
        </IconButton> */}

      </Alert>
    </Snackbar>
  );
}

PopupAlert.propTypes = {
  action: PropTypes.object,
  message: PropTypes.string.isRequired,
  popupKey: PropTypes.number,
  severity: PropTypes.oneOf(['error', 'warning', 'info', 'success']).isRequired,
};

PopupAlert.defaultProps = {
  popupKey: -1,
  message: '',
  severity: 'info',
};
