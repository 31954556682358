import React from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router';
import Plot from 'react-plotly.js';
import { Table, Column, Cell } from 'fixed-data-table-2';
import { useDispatch } from 'react-redux';
import { fetchMiddleware } from '../../actions/MiddlewareActions';
import { END_POINT } from '../../constants/EndPoints';
import { Oval } from 'react-loader-spinner'

import './DashboardPage.scss';
const threeMonthAgo = new Date();
threeMonthAgo.setMonth(threeMonthAgo.getMonth() - 3);

const TableCell = ({ rowIndex, keyName, data }) => (
  <Cell>{data[rowIndex][keyName]}</Cell>
);

const GraphCell = ({ rowIndex, keyName, data}) => (
  <Cell>
    <Plot
      config={{
        modeBarButtonsToRemove: [
          'hoverClosestCartesian',
          'hoverCompareCartesian',
          'lasso2d',
          'select2d',
          'autoScale2d',
          'zoom2d',
          'zoomIn2d',
          'zoomOut2d',
          'pan2d',
          'resetScale2d',
          'toImage',
          'sendDataToCloud'
      ]
    }}
      layout={{
        height: 70,
        width: 325,
        margin: { l: 0, r: 0, b: 0, t: 0, pad: 0 },
        barmode: 'stack',
        xaxis: {
          type: 'category',
          categoryarray: data[rowIndex][keyName][0].x.toLocaleString('en-US', { month: 'numeric', day: 'numeric' }),
          categoryorder: 'array',
          autorange: 'reversed',
        },
        showlegend: false,
      }}
      data={data[rowIndex][keyName]}
    />
  </Cell>
)

function DashboardPage() {
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = React.useState({w: 1000, h: 800});
  const [fromMonth, setFromMonth] = React.useState(threeMonthAgo.toISOString().substring(0, 'YYYY-MM'.length));
  const [mainData, setMainData] = React.useState([]);
  const [selectedProject, setSelectedProject] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const setPageUISize = (window, event) => {
    console.log('resize', {window, event});
    setPageSize({ w: window.innerWidth, h: window.innerHeight});
  }

  React.useEffect(() => {
    // debbug:
    // const data = Object.keys(mockData2).map((projectId) => {
    //   const projectData = mockData2[projectId];
    // });
    // console.log({data});

    // automatic first load:
    generateReport();

    window.addEventListener('resize', setPageUISize);
    return () => window.removeEventListener('resize', setPageUISize);
  }, []);

  const generateReport = () => {
    dispatch((dispatch, getState) => {
      const state = getState();
      const projectId = state.leaksList.selectedProject;

      setSelectedProject(null);
      setIsLoading(true);

      fetchMiddleware(`${END_POINT}/dashboard/sensors/${projectId}?from=${fromMonth}`, {}, getState)
      .then((json) => {
        setIsLoading(false);
        if (!json.status) {
          console.error(json);
        } else {
          const tableData = [];
          Object.entries(json.data).forEach(([projectId, daysData], index) => {
            const days = Object.keys(daysData);
            const projectName = daysData[days[0]][0].project_name;
            const customerName = daysData[days[0]][0].customer_name;
            const totalSensors = daysData[days[0]].reduce((acc, curr) => acc + curr.count, 0);
            const totalActive = daysData[days[0]].reduce((acc, curr) => acc + curr.active, 0);

            const tableRow = {
              project: projectName,
              customer: customerName,
              totalSensors,
              totalActive,
              precent: `${Math.round((totalActive / totalSensors) * 100)}%`,
              graphDetaild: [],
              graphGeneral: [{
                type: 'bar',
                name: 'inactive',
                x: [],
                y: [],
                text: [],
                marker: {
                  color: '#d62728',
                }
              },
              {
                type: 'bar',
                name: 'Active',
                x: [],
                y: [],
                text: [],
                marker: {
                  color: '#1f77b4',
                }
              },
              {
                type: 'scatter',
                name: 'Inactive Trend',
                x: [],
                y: [],
              }
            ],
            }

            Object.entries(daysData).forEach(([day, dayData]) => {
              const graphDate = new Date(parseInt(day)).toLocaleString('en-US', {
                month: 'numeric',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: false
              });
              // const graphCount = dayData.reduce((acc, curr) => acc + curr.count, 0);
              const activeCount = dayData.reduce((acc, curr) => acc + curr.active, 0);
              const inactiveCount = dayData.reduce((acc, curr) => acc + curr.inactive, 0);

              // insert inactive trace data:
              tableRow.graphGeneral[0].x.push(graphDate);
              tableRow.graphGeneral[0].y.push(inactiveCount);
              tableRow.graphGeneral[0].text.push(inactiveCount);

              // insert inactive trend trace data:
              tableRow.graphGeneral[2].x.push(graphDate);
              tableRow.graphGeneral[2].y.push(inactiveCount);

              // insert active trace data:
              tableRow.graphGeneral[1].x.push(graphDate);
              tableRow.graphGeneral[1].y.push(activeCount);
              tableRow.graphGeneral[1].text.push(activeCount);

              dayData.forEach((dataByType) => {
                const totalTraceName = `${dataByType.device_type_name} Total`;
                const inactiveTraceName = `${dataByType.device_type_name} Inactive`;
                const totalTraceIndex = tableRow.graphDetaild.findIndex((trace) => trace.name === totalTraceName);
                const inactiveTraceIndex = tableRow.graphDetaild.findIndex((trace) => trace.name === inactiveTraceName);
                if (totalTraceIndex === -1) {
                  tableRow.graphDetaild.push({
                    type: 'bar',
                    name: totalTraceName,
                    x: [graphDate],
                    y: [dataByType.count],
                    text: [`${dataByType.count} ${dataByType.device_type_name}`],
                    marker: {
                      color: '#1f77b4',
                    }
                  });
                } else {
                  tableRow.graphDetaild[totalTraceIndex].x.push(graphDate);
                  tableRow.graphDetaild[totalTraceIndex].y.push(dataByType.count);
                  tableRow.graphDetaild[totalTraceIndex].text.push(`${dataByType.count} ${dataByType.device_type_name}`);
                }

                if (inactiveTraceIndex === -1) {
                  tableRow.graphDetaild.push({
                    type: 'bar',
                    name: inactiveTraceName,
                    x: [graphDate],
                    y: [dataByType.inactive],
                    text: [dataByType.inactive],
                    marker: {
                      color: '#d62728',
                    }
                  });
                } else {
                  tableRow.graphDetaild[inactiveTraceIndex].x.push(graphDate);
                  tableRow.graphDetaild[inactiveTraceIndex].y.push(dataByType.inactive);
                  tableRow.graphDetaild[inactiveTraceIndex].text.push(dataByType.inactive);
                }
              });

              // console.log({day, dayData});
            });

            tableData.push(tableRow);
          });

          console.log('tableData', tableData);
          setMainData(tableData);
        }
      }).catch((err) => {
        setIsLoading(false);
        console.error(err);
      });
    })
  }

  const handleProjectClick = (event, rowIndex) => {
    setSelectedProject(mainData[rowIndex]);
    console.log(mainData[rowIndex]);
  }

  return (
    <div className='dashboard-page'>
      <header>
        <h1>Dashboard</h1>
        <button onClick={() => browserHistory.push('/alerts')}>Back to <q>Alerts</q></button>
      </header>

      <main>

        <input type='month' value={fromMonth} onChange={(e) => setFromMonth(e.target.value)} />

        <button onClick={generateReport}>Generate Report</button>

        {isLoading &&
          <Oval
            width={30}
            height={30}
            wrapperStyle={{ display: 'inline' }}
          />
        }

        {selectedProject && (<button onClick={() => setSelectedProject(null)}>Clear Selection</button>)}

        <div className='dashboard-container'>
          <aside>
            <Table
              maxHeight={770}
              height={770}
              width={800}
              rowsCount={mainData.length}
              rowHeight={75}
              headerHeight={30}
              onRowClick={handleProjectClick}
              rowClassNameGetter={(rowIndex) => (selectedProject && selectedProject.project === mainData[rowIndex].project) ? 'active-row' : ''}
            >
              <Column
                align='center'
                columnKey="customer"
                width={100}
                flexGrow={1}
                header={<Cell>Customer</Cell>}
                cell={<TableCell data={mainData} keyName='customer' />} />
              <Column
                align='center'
                columnKey="project"
                width={150}
                flexGrow={2}
                header={<Cell>Project</Cell>}
                cell={<TableCell data={mainData} keyName='project' />} />
              <Column
                align='center'
                columnKey="totalSensors"
                width={100}
                flexGrow={1}
                header={<Cell>Count</Cell>}
                cell={<TableCell data={mainData} keyName='totalSensors' />} />
              <Column
                align='center'
                columnKey="precent"
                width={100}
                flexGrow={1}
                header={<Cell>Active</Cell>}
                cell={<TableCell data={mainData} keyName='precent' />} />
              <Column
                align='center'
                columnKey="Graph"
                width={330}
                flexGrow={4}
                header={<Cell>Graph</Cell>}
                cell={<GraphCell data={mainData} keyName='graphGeneral' />} />
            </Table>
          </aside>
          <aside>
            {selectedProject &&
             <Plot
              layout={{
                height: 770,
                width: 1000,
              }}
              data={selectedProject.graphDetaild}
            />}
          </aside>
        </div>
      </main>
    </div>
  );
}

export default DashboardPage;
