import React from 'react';
import PropTypes from 'prop-types';
import AppModal from '../Modals/AppModal';
import { Field, reduxForm } from 'redux-form';
import { Select, Input } from '../../components';
import CommentField from '../../components/CommentField/CommentField';
import { SubmissionError } from 'redux-form';

import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import CancelIcon from '@mui/icons-material/Cancel';
import * as mobileTasksActions from '../../actions/MobileTasksActions';
require('./StatusModalSettings.scss');

const classNames = require('classnames');

class CreateMobileAlertModal extends React.Component {
  constructor(props) {
    super(props);

    // Methods declaration
    this.onClose = this.onClose.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
  }

  // Handling the close dialog action
  onClose() {
    this.props.setOpenStatusDialog(false);
    console.log('onClose function opened');
  }

  // Handling the save dialog data action
  onSaveData(values, dispatch, props) {
    const { alertState, alertType, alertClassification, comment } = values;
    const requiredFields = ['alertState', 'alertType', 'alertClassification'];

    requiredFields.forEach((fieldKey) => {
      if (values[fieldKey] == null || values[fieldKey] === '') {
        throw new SubmissionError({
          [fieldKey]: 'Require',
          _error: fieldKey + ' is Require',
        });
      }
    });
    const { selectedFeature } = props;
    const [taskId] = selectedFeature;
    dispatch(
      props.createNewMobileAlert(taskId, {
        ...values,
        samplesIds: props.checkedSamples,
      })
    );
    this.props.setOpenStatusDialog(false);

    this.props.resetForm();
    // if (this.state.open) {
    //   const data = [{ field: props.field, value: props.value }];
    //
    //   if (props.values.comment) {
    //     data.push({ field: 'Comment', value: props.values.comment });
    //   }
    //   if (props.values.source) {
    //     data.push({ field: 'LeakBoss', value: props.values.source });
    //   }
    //   this.setState({ open: false });
    //   // Update changes
    //   this.props.updateLeak(data);
    //   // Open the status dialog
    //   this.props.setOpenStatusDialog(false);
    //   // Clear form fields
    //   this.props.resetForm();
    // }
  }

  render() {
    const {
      selectedFeature,
      leaksIds,
      handleSubmit,
      onCopy,
      onEdit,
      options,
      isOpen,
    } = this.props;
    const formClass = 'form-group auto-center';
    const labelsClass = 'col-xs-3 control-label';
    const fieldsClass = 'col-xs-8 control-input';
    return (
      <AppModal
        open={isOpen}
        onClose={this.onClose}
        title={this.context.t('Add alert')}
        content={
          <form
            className="form-horizontal sticky-actions"
            onSubmit={handleSubmit((data, d, p) => {
              this.onSaveData(data, d, p);
            })}
          >
            <div style={{ width: '100%' }}>
              {/* Comment Field */}
              <div className="col-xs-12 text-dir-button">
                <Field
                  name="comment"
                  component="textarea"
                  placeholder={this.context.t('Comment')}
                  maxLength={225}
                  className="form-control"
                />
              </div>
              {/* Shadow ('Leak Boss') Field */}
              <div className={formClass}>
                <label className={labelsClass}>
                  {this.context.t('Alert State')}
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="alertState"
                    component={(props) => (
                      <Select {...props} options={options.AlertStateNoise} />
                    )}
                  />
                </div>
              </div>
              <div className={formClass}>
                <label className={labelsClass}>
                  {this.context.t('Alert Type')}
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="alertType"
                    component={(props) => (
                      <Select {...props} options={options.AlertType} />
                    )}
                  />
                </div>
              </div>
              <div className={formClass}>
                <label className={labelsClass}>
                  {this.context.t('Alert Classification')}
                </label>
                <div className={fieldsClass}>
                  <Field
                    name="alertClassification"
                    component={(props) => (
                      <Select
                        {...props}
                        options={options.AudioClassificationEnum}
                      />
                    )}
                  />
                </div>
              </div>
            </div>
            <Button
              style={{ margin: '0 5px' }}
              variant="contained"
              color="primary"
              endIcon={<SendIcon />}
              type="submit"
            >
              {this.context.t('Submit')}
            </Button>
            <Button
              style={{ margin: '0 5px' }}
              variant="contained"
              color="secondary"
              endIcon={<CancelIcon />}
              onClick={this.onClose}
            >
              {this.context.t('Cancel')}
            </Button>
          </form>
        }
      />
    );
  }
}

const validate = (values) => {
  const errors = {};

  return errors;
};

// StatusModal.propTypes = {
//     onValueChange: PropTypes.func.isRequired,
//     options: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
// };

CreateMobileAlertModal.contextTypes = {
  t: PropTypes.func.isRequired,
};

const createMobileAlertModal = reduxForm({
  form: 'add-mobile-alert', // a unique name for this form
  enableReinitialize: true,
  validate,
})(CreateMobileAlertModal);

export default createMobileAlertModal;
